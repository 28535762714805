import React from 'react'
import { CmsCountdown } from '../../../../CmsComponents/CmsCountdown'
import { CmsModuleIcon } from '../../../../CmsComponents/CmsModuleIcon'
import {
  SquareBoardWithSplitArticle,
  SquareBoardWithSplitCTAContainer,
  SquareBoardWithSplitPreTitle,
  SquareBoardWithSplitTextContainer,
  SquareBoardWithSplitTextContent,
  SquareBoardWithSplitTitle,
} from '../SquareBoardWithSplit.style'
import CmsProductTile from '../../../../CmsComponents/CmsProductTile'
import useCmsTeaserBanner from '../../../../../../hooks/useCmsBanner'
import { ILXTeaser } from '../../../../../../types/cmsPlacement/LXTeaser'
import CmsCta from '../../../../CmsComponents/CmsCta'
import { getTeaserOverlayTextAlign } from '@utils/placements'
import { IViewType } from '../../../../../../types/cmsPlacement/ViewType'

export const SquareBoardWithSplitText: React.FC<{
  teaser?: ILXTeaser
  viewType?: IViewType
  bannerIndex?: number
  teaserIndex?: number
}> = ({ teaser, viewType, bannerIndex, teaserIndex }) => {
  const {
    teaserTitle,
    teaserText,
    teaserTitleValue,
    teaserTextValue,
    teaserOverlaySettingsValue,
    teaserOverlayTextAlignValue,
    teaserOverlayStyleValue,
    promoteToH1,
    teaserLXCallToActionSettings,
    teaserPreTitle,
    teaserIcon,
    teaserCountdownStart,
    teaserCountdownUntil,
    teaserHideExpiredCountdown,
    productData,
    isFrameAdvisor,
  } = useCmsTeaserBanner({ item: teaser, viewType: viewType }) || {}

  const content = (
    <>
      {teaserIcon && <CmsModuleIcon teaserIcon={teaserIcon} />}
      {teaserCountdownStart &&
        teaserCountdownUntil &&
        teaserHideExpiredCountdown && (
          <CmsCountdown
            teaserCountdownStart={teaserCountdownStart}
            teaserCountdownUntil={teaserCountdownUntil}
            teaserHideExpiredCountdown={teaserHideExpiredCountdown}
          />
        )}
      <>
        {teaserPreTitle && (
          <>
            <SquareBoardWithSplitPreTitle
              data-cm-metadata={'[{"_":"properties.teaserPreTitle"}]'}
              variant={promoteToH1 ? 'h1' : 'h2'}
              fontWeight={'600'}
            >
              {teaserPreTitle}
            </SquareBoardWithSplitPreTitle>
          </>
        )}
        {teaserTitleValue && (
          <SquareBoardWithSplitTitle
            data-cm-metadata={`[{"_":"properties.${teaserTitle}"}]`}
            variant={promoteToH1 ? 'h1' : 'h2'}
          >
            {teaserTitleValue}
          </SquareBoardWithSplitTitle>
        )}
        {teaserTextValue && (
          <SquareBoardWithSplitTextContainer
            data-cm-metadata={`[{"_":"properties.${teaserText}"}]`}
            dangerouslySetInnerHTML={{ __html: teaserTextValue }}
          />
        )}
      </>
      {productData !== null && (
        <CmsProductTile product={productData} tileIndex={0} />
      )}
    </>
  )

  return (
    <>
      <SquareBoardWithSplitTextContent>
        <SquareBoardWithSplitArticle
          overlaysettings={teaserOverlaySettingsValue}
          overlaytxtalign={getTeaserOverlayTextAlign(
            teaserOverlayTextAlignValue
          )}
          teaseroverlaystyle={teaserOverlayStyleValue}
        >
          {content}
        </SquareBoardWithSplitArticle>
        {!!teaserLXCallToActionSettings &&
          teaserLXCallToActionSettings?.length > 0 && (
            <SquareBoardWithSplitCTAContainer
              data-element-id={isFrameAdvisor && 'Footer_FrameGenius_Open'}
            >
              {teaserLXCallToActionSettings?.map((actionSettings, index) => (
                <CmsCta
                  actionSettings={actionSettings}
                  dataElementId={`${teaserIndex}Placement_Banner${bannerIndex}_CTA${
                    index + 1
                  }`}
                  key={`cms-content__text-module--cta-${index + 1}`}
                />
              ))}
            </SquareBoardWithSplitCTAContainer>
          )}
      </SquareBoardWithSplitTextContent>
    </>
  )
}
