import React from 'react'
import {
  StyledSuggestedProductAnchor,
  StyledSuggestedProductBrandName,
  StyledSuggestedProductName,
  StyledSuggestedProductTileDescription,
  StyledSuggestedProductTileFooter,
  StyledSuggestedProductTileFooterWrapper,
} from '@components/SuggestedProducts/components/SuggestedProductTile/SuggestedProductTile.style'
import { SupplyData } from '../../../../../../types/product'
import { ProductAlgolia } from '../../../../../../types/ProductAlgolia/ProductAlgolia'
import { useStoreIdentity } from '../../../../../../foundation/hooks/useStoreIdentity'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import ProductPriceFinal from '@components/ProductPriceFinal'

export interface ProductTileFooterProps {
  annualSupplyData?: SupplyData
  clusterSelected: ProductAlgolia
  metaData: { [key: string]: string }
}

const SuggestedProductTileFooterAlgolia: React.FC<ProductTileFooterProps> = ({
  clusterSelected,
  metaData,
}) => {
  const { langCode } = useStoreIdentity()
  const { getLxProductTypes, getProductAttributes } = ProductAlgoliaUtils
  const productPrice = clusterSelected?.prices
  const productLinkTo = `/${langCode}${clusterSelected.url || ''}`
  const attributes = clusterSelected?.attributes_translated

  const { modelName, brand, lxSearchPageProductType } =
    getProductAttributes(attributes)

  const { isContactLenses, isContactLensesAccessories } = getLxProductTypes(
    lxSearchPageProductType
  )

  return (
    <StyledSuggestedProductTileFooterWrapper>
      <StyledSuggestedProductAnchor to={productLinkTo} {...metaData}>
        <StyledSuggestedProductTileFooter>
          <StyledSuggestedProductTileDescription>
            <StyledSuggestedProductName productType={lxSearchPageProductType}>
              {modelName ? modelName : '\u00A0'}
            </StyledSuggestedProductName>
            {!(isContactLenses || isContactLensesAccessories) ? (
              <StyledSuggestedProductBrandName>
                {brand || 'Brand name'}
              </StyledSuggestedProductBrandName>
            ) : null}
            {productPrice && (
              <ProductPriceFinal
                pageType={'suggested'}
                productType={lxSearchPageProductType}
                price={productPrice}
              />
            )}
          </StyledSuggestedProductTileDescription>
        </StyledSuggestedProductTileFooter>
      </StyledSuggestedProductAnchor>
    </StyledSuggestedProductTileFooterWrapper>
  )
}

export default SuggestedProductTileFooterAlgolia
