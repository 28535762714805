import React from 'react'
import {
  CustomInput,
  SearchIconWrapper,
  SearchWrapper,
} from './FaqsSearch.style'
import { SearchIcon } from '@components/UI/Icons'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setFaqsSearch } from 'src/features/ui/action'

export const FaqsSearch: React.FC<{}> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const searchValue = (e) => {
    dispatch(setFaqsSearch(e?.target.value))
  }

  return (
    <>
      <SearchWrapper>
        <SearchIconWrapper>
          <SearchIcon />
          <span>{t('FAQs.Labels.FaqsSearch')}</span>
        </SearchIconWrapper>

        <CustomInput onChange={searchValue} />
      </SearchWrapper>
    </>
  )
}
