import { styled } from '@mui/material/styles'

export const Title = styled('h1', {
  name: 'Playground',
  slot: 'Title',
})(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
}))
export const CustomWrapper = styled('div', {
  name: 'Playground',
  slot: 'CustomWrapper',
})(({ theme }) => ({
  display: 'flex',
  width: 'auto',
  padding: theme.spacing(4),
  marginTop: theme.spacing(8),
  gap: theme.spacing(4),
  backgroundColor: theme.palette.color.grey.light,

  svg: {
    width: 150,
  },
}))
