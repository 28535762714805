import { styled } from '@mui/material/styles'
import { Swiper } from 'swiper/react'

export const WrapperWallOfBrands = styled('div', {
  name: 'WallOfBrands',
  slot: 'Wrapper',
})(({ theme }) => ({
  padding: 0,
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 8),
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 10),
  },
  [theme.breakpoints.up('xl')]: {
    padding: theme.spacing(0, 10),
  },
}))

export const ContentWallOfBrands = styled('div', {
  name: 'WallOfBrands',
  slot: 'Content',
})(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    justifyItems: 'center',
    rowGap: theme.spacing(6),
  },
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(6, 1fr)',
  },
}))

export const WallOfBrandsSwiper = styled(Swiper, {
  name: 'WallOfBrands',
  slot: 'Swiper',
})(({ theme }) => ({
  maxWidth: '100vw',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  //paddingBottom: theme.spacing(8),
  '.swiper-wrapper': {
    minWidth: '100%',
  },
  '.custom-progressbar': {
    width: '46%',
    height: '2px',
    position: 'relative',
    backgroundColor: theme.palette.color.grey.light,
    order: '1',
    span: {
      left: '0',
      position: 'absolute',
      width: '100%',
      height: '100%',
      transformOrigin: 'left',
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  '.swiper-scrollbar.swiper-scrollbar-horizontal': {
    position: 'relative',
    order: 1,
    borderRadius: 2,
    height: 2,
    width: 200,
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    background: theme.palette.color.grey.light,

    margin: theme.spacing(5, 0, 10),

    '.swiper-scrollbar-drag': {
      height: 2,
      background: theme.palette.primary.main,
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))
