import Box from '@mui/material/Box/Box'

import { useTheme } from '@mui/material/styles'
import { EffectFade, Navigation } from 'swiper'
import ProductImage, {
  ProductImageProps,
} from '../../../ProductImage/ProductImage'
import React, { FC, useCallback, useRef } from 'react'
import {
  StyledControlsWrapper,
  StyledControlsWrapperColorLabel,
  StyledSwiper,
} from './ProductTileGallery.style'
import { ArrowLeftIcon, ArrowRightIcon } from '@components/UI/Icons'

import { Product } from '../../../../types/product'
import { Swiper as SwiperClass } from 'swiper/types'
import { SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import ProductTileUtils from '@components/ProductTile/ProductTileUtilsOld'

type ProductTileGalleryProps = {
  isDisplayed?: boolean
  tileDataElementId: string
  currentProductIndex: number
  cluster: Product[]
  commonImageProps?: ProductImageProps
  onIndexChange: (ind: number) => void
  isCompact?: boolean
}

const ProductTileGalleryOld: FC<ProductTileGalleryProps> = ({
  isDisplayed = true,
  tileDataElementId,
  cluster,
  currentProductIndex,
  commonImageProps,
  onIndexChange,
  isCompact,
}) => {
  const theme = useTheme()
  const swiperRef = useRef<SwiperClass | null>(null)
  const currentProduct = cluster[currentProductIndex]
  const { name, modelCode, colorLabel } =
    ProductTileUtils.getAttributes(currentProduct)

  const slidePrev = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    const prevSlideIndex = (swiperRef.current?.realIndex || 0) - 1
    const slideTo = prevSlideIndex < 0 ? cluster.length - 1 : prevSlideIndex
    swiperRef.current?.slideTo(slideTo)
    onIndexChange(slideTo)
  }

  const slideNext = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    const nextSlideIndex = (swiperRef.current?.realIndex || 0) + 1
    const slideTo = nextSlideIndex > cluster.length - 1 ? 0 : nextSlideIndex
    swiperRef.current?.slideTo(slideTo)
    onIndexChange(slideTo)
  }

  const onActiveIndexChanged = useCallback(
    (s: SwiperClass) => {
      onIndexChange(s.realIndex)
    },
    [onIndexChange]
  )

  // Prevents the click event from bubbling up to the parent element so it won't be tracked by Analytics
  const handleMouseDownCapture = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  if (!isDisplayed) {
    return null
  }

  return (
    <>
      <StyledSwiper
        isDisplayed={isDisplayed}
        effect="fade"
        touchMoveStopPropagation={true}
        touchStartPreventDefault={false}
        navigation={false}
        modules={[Navigation, EffectFade]}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        onActiveIndexChange={onActiveIndexChanged}
        initialSlide={currentProductIndex}
      >
        {cluster.map((c: Product, i) => (
          <SwiperSlide key={`${tileDataElementId}-${name}_${modelCode}_${i}`}>
            <ProductImage attachments={c.attachments} {...commonImageProps} />
            <div>{`${colorLabel}`}</div>
          </SwiperSlide>
        ))}
      </StyledSwiper>

      <StyledControlsWrapper isDisplayed={isDisplayed} isCompact={isCompact}>
        {cluster.length > 1 ? (
          <>
            <div className="icon-container">
              <ArrowLeftIcon
                htmlColor={theme.palette.text.dark}
                onMouseDownCapture={handleMouseDownCapture}
                onClick={slidePrev}
              />
            </div>
            <StyledControlsWrapperColorLabel>{`${colorLabel}`}</StyledControlsWrapperColorLabel>
            <div className="icon-container">
              <ArrowRightIcon
                htmlColor={theme.palette.text.dark}
                onMouseDownCapture={handleMouseDownCapture}
                onClick={slideNext}
              />
            </div>
          </>
        ) : (
          <Box flex={1} textAlign="center">
            {colorLabel}
          </Box>
        )}
      </StyledControlsWrapper>
    </>
  )
}

export default ProductTileGalleryOld
