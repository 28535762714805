import { useEffect, useState } from 'react'
import { Attachment, Product } from '../../types/product'
import { ProductAlgolia } from '../../types/ProductAlgolia/ProductAlgolia'

/**
 * @param { ServerProduct } product current selected product object
 */
export const useProductImages = (product?: Product | ProductAlgolia) => {
  const [images, setImages] = useState<Attachment[]>([])

  useEffect(() => {
    const attachments = product?.attachments || []
    // filter images array to get array with PDP images
    const PDPImagesArray = attachments.filter((d) => d.usage === 'PDP')
    if (!PDPImagesArray.length) {
      return
    }
    // order images by "sequence" attribute
    PDPImagesArray.sort(
      (a, b) => parseInt(a.sequence, 10) - parseInt(b.sequence, 10)
    )
    // remove first image from array with PDP images
    setImages(PDPImagesArray)
  }, [product?.attachments])

  return {
    images,
  }
}

// plain function instead to perform sync retrieving and to fix issues connected with hook's async flow
export const retrieveProductImages = (product?: Product): Attachment[] => {
  if (!(product && product.attachments)) return []

  // filter images array to get array with PDP images
  const PDPImagesArray = product.attachments.filter((d) => d.usage === 'PDP')
  if (!PDPImagesArray.length) {
    return []
  }

  // order images by "sequence" attribute
  PDPImagesArray.sort(
    (a, b) => parseInt(a.sequence, 10) - parseInt(b.sequence, 10)
  )

  return PDPImagesArray
}
