//Standard libraries
import { createReducer, AnyAction } from '@reduxjs/toolkit'
import { ORDER_STATUS } from '../../constants/order'
//Redux
import initStates from './initStates'
import { OrderDetailsReducerState } from './reducerStateInterface'

import {
  FETCH_ORDER_DETAILS_SUCCESS_ACTION,
  FETCH_ORDER_ITEM_DETAILS_SUCCESS_ACTION,
  FETCH_ORDER_DETAILS_FAIL_ACTION,
  SET_ORDER_DETAILS_LOADINGS_ACTION,
  FETCH_ORDER_ITEM_DETAILS_FAIL_ACTION,
  RESET_ORDER_DETAILS_ACTION,
} from '../actions/orderDetails'
import { fromServerToClientProduct } from '@utils/fromServerToClientEntity'

/**
 * Order details reducer. Order details state store all order details per user session.
 * handles states used by order details components
 * @param state State object managed by OderDetails reducer
 * @param action The dispatched action
 */
const orderDetailsReducer = createReducer(
  initStates.orderDetails,
  (builder) => {
    builder.addCase(
      FETCH_ORDER_DETAILS_SUCCESS_ACTION,
      (state: OrderDetailsReducerState, action: AnyAction) => {
        const { orderId } = action.payload
        state.data[orderId] = action.payload
        state.data[orderId].orderComplete =
          action.payload.orderStatus === ORDER_STATUS.Created
      }
    )

    builder.addCase(
      FETCH_ORDER_ITEM_DETAILS_SUCCESS_ACTION,
      (state: OrderDetailsReducerState, action: AnyAction) => {
        const { orderId, items } = action.payload
        const orderItems = state.data[orderId].orderItem
        if (items.length && items.length > 0) {
          const parseProduct = items.map((item) =>
            fromServerToClientProduct(item)
          )
          const detailedOrderItems = orderItems.map((item) => {
            let obj = {
              ...item,
              name: '',
              thumbnail: '',
              attributes: [],
              productAttributes: {},
              seo: { href: '' },
            }
            const filteredItem =
              (parseProduct as any[]).filter(
                (i) => String(i.id) === String(item.productId)
              )[0] || {}
            const {
              id,
              name,
              thumbnail,
              attributes,
              attachments,
              seo,
              productAttributes,
            } = filteredItem
            Object.assign(obj, {
              id,
              name,
              thumbnail,
              attributes,
              productAttributes,
              attachments,
              seo,
            })
            return obj
          })
          Object.assign(state.data[orderId], { detailedOrderItems })
        }

        state.loadings = state.loadings.filter((id) => id !== orderId)
      }
    )
    builder.addCase(
      FETCH_ORDER_DETAILS_FAIL_ACTION,
      (state: OrderDetailsReducerState, action: AnyAction) => {
        const { orderId } = action.payload
        state.data[orderId] = action.payload
        state.loadings = state.loadings.filter((id) => id !== orderId)
      }
    )
    builder.addCase(
      FETCH_ORDER_ITEM_DETAILS_FAIL_ACTION,
      (state: OrderDetailsReducerState, action: AnyAction) => {
        state.loadings = state.loadings.filter(
          (id) => id !== action.payload.orderId
        )
      }
    )
    builder.addCase(
      SET_ORDER_DETAILS_LOADINGS_ACTION,
      (state: OrderDetailsReducerState, { payload }) => {
        if (typeof payload === 'string' && !state.loadings.includes(payload)) {
          state.loadings = state.loadings.concat(payload)
        } else if (Array.isArray(payload)) {
          state.loadings = payload
        }
      }
    )
    builder.addCase(
      RESET_ORDER_DETAILS_ACTION,
      (state: OrderDetailsReducerState) => {
        state.data = {}
      }
    )
  }
)

export default orderDetailsReducer
