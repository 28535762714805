import React from 'react'
import { css, styled, Theme } from '@mui/material/styles'
import ButtonUnstyled, { ButtonUnstyledProps } from '@mui/base/ButtonUnstyled'
import { StyledLoader } from '@components/UI'

export interface GenericButtonProps {
  labelText?: string
  endIcon?: React.ReactNode
  startIcon?: React.ReactNode
  fillType?: 'fill' | 'outline'
  variant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary'
  fullWidth?: boolean
  size?: 'small' | 'medium' | 'big'
  loading?: boolean
}

export type ButtonProps = GenericButtonProps & ButtonUnstyledProps

export const variantMap = (
  theme: Theme,
  variant: ButtonProps['variant'] = 'primary',
  fillType: ButtonProps['fillType'] = 'fill'
) => {
  const primaryFill = {
    background: theme.palette.primary.main,
    hoverBackground: theme.palette.primary.dark,
    color: theme.palette.text.light,
    colorHover: theme.palette.text.light,
    borderColor: theme.palette.primary.main,
    borderColorhover: theme.palette.primary.dark,
  }
  const secondaryFill = {
    background: theme.palette.secondary.main,
    hoverBackground: theme.palette.secondary.dark,
    color: theme.palette.text.dark,
    colorHover: theme.palette.text.dark,
    borderColor: theme.palette.secondary.main,
    borderColorhover: theme.palette.secondary.dark,
  }
  const tertiaryFill = {
    background: theme.palette.color.black.main,
    hoverBackground: theme.palette.color.grey.dark,
    color: theme.palette.text.light,
    colorHover: theme.palette.text.light,
    borderColor: theme.palette.color.black.main,
    borderColorhover: theme.palette.color.grey.dark,
  }
  const quaternaryFill = {
    background: theme.palette.color.white.main,
    hoverBackground: theme.palette.color.grey.lightest,
    color: theme.palette.text.dark,
    colorHover: theme.palette.text.dark,
    borderColor: theme.palette.color.white.main,
    borderColorhover: theme.palette.color.grey.lightest,
  }

  const primaryOutline = {
    background: 'transparent',
    hoverBackground: 'rgba(3, 84, 166, 0.1)',
    color: theme.palette.primary.main,
    colorHover: theme.palette.primary.dark,
    borderColor: theme.palette.primary.main,
    borderColorhover: theme.palette.primary.dark,
  }
  const secondaryOutline = {
    background: 'transparent',
    hoverBackground: 'rgba(255, 255, 255, 0.25)',
    color: theme.palette.secondary.main,
    colorHover: theme.palette.secondary.dark,
    borderColor: theme.palette.secondary.main,
    borderColorhover: theme.palette.secondary.dark,
  }
  const tertiaryOutline = {
    background: 'transparent',
    hoverBackground: 'rgba(0, 0, 0, 0.2)',
    color: theme.palette.text.dark,
    colorHover: theme.palette.text.dark,
    borderColor: theme.palette.color.black.main,
    borderColorhover: theme.palette.color.black.main,
  }
  const quaternaryOutline = {
    background: 'transparent',
    hoverBackground: 'rgba(255, 255, 255, 0.25)',
    color: theme.palette.text.light,
    colorHover: theme.palette.color.white.main,
    borderColor: theme.palette.text.light,
    borderColorhover: theme.palette.color.white.main,
  }

  return {
    fill: {
      primary: primaryFill,
      secondary: secondaryFill,
      tertiary: tertiaryFill,
      quaternary: quaternaryFill,
    },
    outline: {
      primary: primaryOutline,
      secondary: secondaryOutline,
      tertiary: tertiaryOutline,
      quaternary: quaternaryOutline,
    },
  }[fillType][variant]
}

export const cssButton = ({
  theme,
  variant,
  fillType,
  fullWidth,
  size,
}: ButtonProps & { theme: Theme }) =>
  css({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    appearance: 'none',
    fontFamily: 'inherit',
    fontWeight: 600,
    fontSize: 14,
    padding: theme.spacing(2.5, 6),
    minWidth: 120,
    width: '100%',
    borderRadius: 100,
    lineHeight: 1.43,
    cursor: 'pointer',
    textTransform: 'uppercase',
    textDecoration: 'none',
    letterSpacing: 'normal',
    height: size === 'big' ? 48 : 40,
    color: variantMap(theme, variant, fillType)?.color || 'inherit',
    background: variantMap(theme, variant, fillType)?.background || 'inherit',
    border: `1px solid${
      variantMap(theme, variant, fillType)?.borderColor || 'inherit'
    }`,

    '&:hover': {
      color: variantMap(theme, variant, fillType)?.colorHover || 'inherit',
      background:
        variantMap(theme, variant, fillType)?.hoverBackground || 'inherit',
      border: `1px solid
      ${variantMap(theme, variant, fillType)?.borderColorhover || 'inherit'}`,
    },

    '&:disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
    },

    svg: {
      width: '1rem',
      height: '1rem',
      rect: { fill: variantMap(theme, variant, fillType)?.color || 'inherit' },
    },

    [theme.breakpoints.up('sm')]: {
      width: fullWidth ? '100%' : 'auto',
    },
  })

export const Button = styled(
  ({
    labelText,
    startIcon,
    endIcon,
    children,
    loading,
    disabled,
    ...props
  }: ButtonProps) => (
    <ButtonUnstyled {...props} disabled={loading || disabled}>
      {loading ? <StyledLoader size={16} color="inherit" /> : startIcon}
      {labelText || children}
      {endIcon}
    </ButtonUnstyled>
  ),
  {
    shouldForwardProp: (prop) => prop !== 'fillType' && prop !== 'fullWidth',
  }
)(cssButton)
