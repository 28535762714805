import config from '@configs/index'
import '../index.scss'

import { EffectFade, Lazy, Navigation } from 'swiper'
import ProductImage, {
  ProductImageProps,
} from '../../../../ProductImage/ProductImage'
import React, { memo, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Product } from '../../../../../types/product'
import { Swiper as SwiperClass } from 'swiper/types'
import { Swiper as SwiperDef } from 'swiper/swiper'

import { StyledSuggestedProductAnchor } from '../SuggestedProductTile.style'

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import ProductTileUtils from '@components/ProductTile/ProductTileUtilsOld'

const siteName = config.REACT_APP_STORENAME

type SuggestedProductTileGalleryProps = {
  mocoLink: string
  tileDataElementId: string
  priorityBadgeText: string
  currentProductIndex: number
  cluster: Product[]
  onProductChange: (s: SwiperDef) => void
  commonImageProps?: ProductImageProps
  clusterViewLength?: number
}

const SuggestedProductTileGallery = ({
  mocoLink,
  tileDataElementId,
  priorityBadgeText,
  cluster,
  currentProductIndex,
  onProductChange,
  commonImageProps,
}: // clusterViewLength,
SuggestedProductTileGalleryProps) => {
  const swiperRef = useRef<SwiperClass | null>(null)
  const currentProduct = cluster[currentProductIndex]

  const { name, modelCode } = ProductTileUtils.getAttributes(currentProduct)

  return (
    <Swiper
      onSwiper={(swiper) => (swiperRef.current = swiper)}
      onActiveIndexChange={onProductChange}
      initialSlide={currentProductIndex}
      effect={'fade'}
      className={'product-tile-swiper'}
      touchMoveStopPropagation={true}
      lazy={true}
      loop={true}
      navigation={false}
      modules={[Navigation, EffectFade, Lazy]}
    >
      {cluster.map((c: Product, i) => (
        <SwiperSlide key={`${name}_${modelCode}_${i}`}>
          <StyledSuggestedProductAnchor
            aria-label={priorityBadgeText || `${name}_${modelCode}`}
            to={mocoLink}
            className="product-tile__inner"
            data-element-id={tileDataElementId}
            data-description={encodeURIComponent(
              `${siteName}_${name}_${modelCode}`
            )}
          >
            <ProductImage attachments={c.attachments} {...commonImageProps} />
          </StyledSuggestedProductAnchor>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default memo(SuggestedProductTileGallery)
