import { Button, ButtonAsLink, TextField } from '@components/UI'

import { styled } from '@mui/material/styles'

export const StyledSignInTitle = styled('span', {
  name: 'SignInForm',
  slot: 'Title',
})(({ theme }) => ({
  fontSize: theme.spacing(8),
  lineHeight: 1.4,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(10),
  },
  color: theme.palette.text.dark,
}))

export const StyledSignInSubtitle = styled('span', {
  name: 'SignInForm',
  slot: 'SubTitle',
})(({ theme }) => ({
  fontSize: theme.spacing(4),
  color: theme.palette.text.dark,
}))

export const StyledErrorLogin = styled('span', {
  name: 'SignInForm',
  slot: 'ErrorLogin',
})(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.error.main,
}))

export const StyledSignInContainer = styled('div', {
  name: 'SignInForm',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    padding: 0,
  },
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(6),
  },
}))

export const StyledSingInForm = styled('form', {
  name: 'SingInForm',
  slot: 'Form',
})(({ theme }) => ({
  '& > div:first-of-type': {
    paddingTop: theme.spacing(4),

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(3.75),
    },
  },
}))

export const StyledFYPLinkWrapper = styled('div', {
  name: 'SignInForm',
  slot: 'SignInLinkWrapper',
  shouldForwardProp: (prop) => prop !== 'isPasswordError',
})<{ isPasswordError?: boolean }>(({ theme, isPasswordError }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(isPasswordError ? 3 : 0),

  [theme.breakpoints.up('md')]: {
    marginTop: 0,
  },
}))

export const StyledTextFieldWrapper = styled('div', {
  name: 'SignInForm',
  slot: 'TextFieldWrapper',
})(({ theme }) => ({
  padding: theme.spacing(5.5, 0),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4.5, 0),
  },
}))

export const StyledForgotYourPasswordLink = styled(ButtonAsLink, {
  name: 'SingInForm',
  slot: 'ForgotYourPasswordLink',
})(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 600,
  lineHeight: 1.33,
  color: theme.palette.text.dark,
}))

export const StyledSignInButton = styled(Button, {
  name: 'SingInForm',
  slot: 'Button',
})(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(6),
  color: theme.palette.color.white.main,
  [theme.breakpoints.up('sm')]: {
    width: 144,
  },
}))

export const StyledTextFieldSignIn = styled(TextField, {
  name: 'SingInForm',
  slot: 'TextField',
})(({ theme }) => ({
  backgroundColor: theme.palette.color.white.main,
}))
