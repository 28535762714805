import React from 'react'
import useBreakpoints from '../../../../../hooks/useBreakpoints'

import { FullWidthMobileBanner } from './Mobile'
import { FullWidthDesktopBanner } from './Desktop'
import { IPlacement } from '../../../../../types/cmsPlacement/Placement'
import { ICMCollection } from '../../../../../types/cmsPlacement/CMCollection'

export interface IFullWidthBannerProps {
  placement: IPlacement | ICMCollection
  teaserIndex?: number
  bannerIndex?: number
}

const FullWidthBanner: React.FC<IFullWidthBannerProps> = ({
  placement,
  teaserIndex,
}) => {
  const { isMobile, isTabletPortrait } = useBreakpoints()
  if (!placement) return null

  return (
    <>
      {isMobile || isTabletPortrait ? (
        <FullWidthMobileBanner
          placement={placement}
          teaserIndex={teaserIndex}
          bannerIndex={1}
        />
      ) : (
        <FullWidthDesktopBanner
          placement={placement}
          teaserIndex={teaserIndex}
          bannerIndex={1}
        />
      )}
    </>
  )
}

export default FullWidthBanner
