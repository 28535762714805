import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as userAction from '../../../redux/actions/user'
import { SKIP_WC_TOKEN_HEADER } from '../../../foundation/constants/common'
import { RESET_ERROR_ACTION } from '../../../redux/actions/error'
import { SIGNIN } from '../../../constants/routes'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { useYupValidationResolver } from '../../../utils/validationResolver'
import { Box, Button } from '@mui/material'
import { StyledTypography, TextField } from '../../../components/UI'
import { HeaderPaper } from '../../SignUp/styles/RegistrationLayout.style'
import { CustomInputFieldProps } from 'src/types/inputFields'

interface IProps {
  errorMessageKey: string
  currentEmail: string
  payloadBase: object
}

interface FormData {
  logonPassword: string
  logonPasswordNew: string
  logonPasswordVerify: string
}

const defaultValues: FormData = {
  logonPassword: '',
  logonPasswordNew: '',
  logonPasswordVerify: '',
}

const LogonAndChangePasswordForm: React.FC<IProps> = ({
  errorMessageKey,
  currentEmail,
  payloadBase,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const schema = useMemo(
    () =>
      yup.object().shape({
        logonPassword: yup
          .string()
          .required(t('RegistrationLayout.Msgs.InvalidFormat')),
        logonPasswordNew: yup
          .string()
          .required()
          .matches(
            /.{8,}/,
            t('RegistrationLayout.Msgs.Requirements.MinimumLength')
          )
          .matches(/[A-Z]/, t('RegistrationLayout.Msgs.Requirements.Uppercase'))
          .matches(/\d/, t('RegistrationLayout.Msgs.Requirements.Number'))
          .matches(
            /[!@#$%^&*)(+=._\-£§€:;<>?\[\]`{|}~"'/]/,
            t('RegistrationLayout.Msgs.Requirements.SpecialCharacter')
          ),
        logonPasswordVerify: yup
          .string()
          .oneOf(
            [yup.ref('logonPasswordNew'), null],
            t('Msgs.PasswordNotMatch')
          ),
      }),
    [t]
  )

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues,
    resolver: useYupValidationResolver(schema),
  })

  const handleLogonAndChangePasswordSubmit = (data: FormData) => {
    const { logonPassword, logonPasswordNew, logonPasswordVerify } = data
    dispatch(
      userAction.LOGON_AND_CHANGE_PASSWORD_ACTION({
        body: {
          logonId: currentEmail,
          logonPassword,
          logonPasswordNew,
          logonPasswordVerify,
        },
        [SKIP_WC_TOKEN_HEADER]: true,
        skipErrorSnackbar: true,
        ...payloadBase,
      })
    )
  }

  const goToSignInPage = () => {
    dispatch(RESET_ERROR_ACTION())
    navigate(SIGNIN)
  }

  const renderTextField = (
    name: keyof FormData,
    overrideProps?: Partial<CustomInputFieldProps>
  ) => (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, ref, ...rest }, fieldState: { error } }) => (
        <TextField
          {...rest}
          value={value}
          inputRef={ref}
          variant="outlined"
          margin="normal"
          type="password"
          required
          fullWidth
          error={!!error}
          helperText={errors[name]?.message}
          {...overrideProps}
        />
      )}
    />
  )

  return (
    <>
      <HeaderPaper>
        <StyledTypography
          component="h1"
          variant="h4"
          className="bottom-margin-1"
        >
          {t('SignInPage.ChangePassword')}
        </StyledTypography>
        <StyledTypography component="p" className="error">
          {t(errorMessageKey)}
        </StyledTypography>
      </HeaderPaper>
      <div>
        <form
          noValidate
          name="changePasswordForm"
          onSubmit={handleSubmit(handleLogonAndChangePasswordSubmit)}
        >
          {renderTextField('logonPassword', {
            label: t('SignInPage.Labels.CurrentPassword'),
            autoFocus: true,
          })}

          {renderTextField('logonPasswordNew', {
            label: t('SignInPage.Labels.Password'),
          })}

          {renderTextField('logonPasswordVerify', {
            label: t('SignInPage.Labels.PasswordVerify'),
          })}

          <Box mt={2}>
            <Button color={'primary'} type="submit">
              {t('SignInPage.CTA.Submit')}
            </Button>
            <Button
              color="secondary"
              style={{ float: 'right' }}
              onClick={() => goToSignInPage()}
            >
              {t('SignInPage.CTA.Cancel')}
            </Button>
          </Box>
        </form>
      </div>
    </>
  )
}

export default LogonAndChangePasswordForm
