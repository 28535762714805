import React from 'react'
import { ILXTeaser } from '../../../../../../types/cmsPlacement/LXTeaser'
import useCmsTeaserBanner from '../../../../../../hooks/useCmsBanner'
import {
  ContentGridOfBoards,
  CtaContentGridOfBoard,
  IconContentGridOfBoard,
  ItemGridOfBoards,
  PreTitleGridOfBoard,
  TextGridOfBoard,
  TitleGridOfBoard,
} from '../GridOfBoards.style'
import CmsIcon from '../../../../CmsComponents/CmsIcon'
import CmsCta from '../../../../CmsComponents/CmsCta'
import { getTeaserOverlayTextAlign } from '@utils/placements'
import { IViewType } from '../../../../../../types/cmsPlacement/ViewType'

const GridOfBoardsItem: React.FC<{
  item: ILXTeaser
  viewType: IViewType
  columnAmount: 2 | 3
  placementCenter: boolean
  teaserIndex?: number
  bannerIndex?: number
}> = ({
  item,
  viewType,
  columnAmount,
  placementCenter,
  teaserIndex,
  bannerIndex,
}) => {
  const {
    teaserIcon,
    promoteToH1,
    teaserPreTitle,
    teaserTitleValue,
    teaserTitle,
    teaserOverlaySettingsValue,
    teaserText,
    teaserTextValue,
    teaserLXCallToActionSettings,
    teaserOverlayStyleValue,
    teaserOverlayTextAlignValue,
    isFrameAdvisor,
  } = useCmsTeaserBanner({ item, viewType })

  const overlaySettingsControl = placementCenter
    ? 'block-center-middle'
    : teaserOverlaySettingsValue!

  const overlayTextAlignControl = placementCenter
    ? undefined
    : getTeaserOverlayTextAlign(teaserOverlayTextAlignValue)

  return (
    <ItemGridOfBoards columnAmount={columnAmount}>
      <ContentGridOfBoards
        teaserOverlayStyle={teaserOverlayStyleValue}
        overlaySettings={overlaySettingsControl}
        overlayTextAlign={overlayTextAlignControl}
      >
        {teaserIcon && (
          <IconContentGridOfBoard>
            <CmsIcon teaserIcon={teaserIcon} />
          </IconContentGridOfBoard>
        )}
        {teaserPreTitle && (
          <PreTitleGridOfBoard
            data-cm-metadata={'[{"_":"properties.teaserPreTitle"}]'}
            variant={promoteToH1 ? 'h1' : 'h2'}
            fontWeight={'600'}
          >
            {teaserPreTitle}
          </PreTitleGridOfBoard>
        )}
        {teaserTitleValue && (
          <TitleGridOfBoard
            data-cm-metadata={`[{"_":"properties.${teaserTitle}"}]`}
            variant={promoteToH1 ? 'h1' : 'h2'}
          >
            {teaserTitleValue}
          </TitleGridOfBoard>
        )}
        {teaserTextValue && (
          <TextGridOfBoard
            data-cm-metadata={`[{"_":"properties.${teaserText}"}]`}
            dangerouslySetInnerHTML={{ __html: teaserTextValue }}
          />
        )}
        {teaserLXCallToActionSettings.length > 0 && (
          <CtaContentGridOfBoard
            data-element-id={isFrameAdvisor && 'Footer_FrameGenius_Open'}
          >
            {teaserLXCallToActionSettings.map((actionSettings, index) => (
              <CmsCta
                actionSettings={actionSettings}
                dataElementId={`${teaserIndex}Placement_Banner${bannerIndex}_CTA${
                  index + 1
                }`}
                key={`cms-content__text-module--cta-${index + 1}`}
              />
            ))}
          </CtaContentGridOfBoard>
        )}
      </ContentGridOfBoards>
    </ItemGridOfBoards>
  )
}

export default GridOfBoardsItem
