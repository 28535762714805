//Standard libraries
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { sendAccountInformationEvent } from '../../foundation/analytics/tealium/lib'
//UI
import {
  AccountSummaryWrapper,
  AccountSummaryHeader,
  AccountSummaryTitle,
  AccountSummaryContainerWrapper,
} from './AccountSummary.style'
import PersonalInformationSection from './components/PersonalInformationSection'
import { useAnalyticsData } from 'src/foundation/analytics/hooks/useAnalyticsData'

const AccountSummary: FC = () => {
  const { t } = useTranslation()
  const analyticsData = useAnalyticsData('profile')

  useEffect(() => {
    sendAccountInformationEvent({
      common: analyticsData,
      Page_Section2: 'PersonalInformation',
    })
  }, [])

  return (
    <AccountSummaryWrapper>
      <AccountSummaryHeader>
        <AccountSummaryTitle>{t('AccountSummary.Title')}</AccountSummaryTitle>
      </AccountSummaryHeader>
      <AccountSummaryContainerWrapper>
        <PersonalInformationSection />
      </AccountSummaryContainerWrapper>
    </AccountSummaryWrapper>
  )
}

export default AccountSummary
