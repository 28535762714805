import React from 'react'
import PlacementLayout from '../../../components/Cms/PlacementLayout'
import { styled } from '@mui/material/styles'
import { StyledTypography } from '@components/UI'
import { IPageStaticLanding } from '../../../features/cms/cms.type'
import find from 'lodash/find'
import { setPreFooter } from '../../../features/cms/action'
import { useDispatch } from 'react-redux'

export const WrapperLandingTitle = styled('div', {
  name: 'LandingPage',
  slot: 'WrapperTitle',
})(({ theme }) => ({
  padding: theme.spacing(0, 4),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 24),
  },

  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 44),
  },
}))

export const ContentLandingTitle = styled('div', {
  name: 'LandingPage',
  slot: 'ContentTitle',
})(({ theme }) => ({
  boxShadow: `0px 1px 0px ${theme.palette.color.grey.light}`,
  padding: theme.spacing(4, 0),
}))

export const LandingPageTitle = styled(StyledTypography, {
  name: 'LandingPage',
  slot: 'Title',
})(({ theme }) => ({
  color: theme.palette.text.dark,
  fontSize: 32,
  fontWeight: 600,
}))

const LandingPage: React.FC<{ data: IPageStaticLanding; loading: boolean }> = ({
  data,
  loading,
}) => {
  const dispatch = useDispatch()
  const title = data.title || ''
  const placements = data.contentPlacements || []

  const preFooter = find(placements, {
    name: 'footer_before_footer',
  })
  React.useEffect(() => {
    dispatch(setPreFooter(preFooter!))
  }, [preFooter])
  return (
    <>
      <WrapperLandingTitle>
        <ContentLandingTitle>
          <LandingPageTitle variant={'h1'}>{title}</LandingPageTitle>
        </ContentLandingTitle>
      </WrapperLandingTitle>
      <PlacementLayout placements={placements} loading={loading} />
    </>
  )
}

export default LandingPage
