import { usePageType } from '../../foundation/hooks/usePageType'
import useBreakpoints from '@hooks/useBreakpoints'
import { useMemo, useState } from 'react'
import { ProductAlgolia } from '../../types/ProductAlgolia/ProductAlgolia'
import { useSelector } from 'react-redux'
import { productsMobileListingLayoutSelector } from '../../features/ui/selector'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'

const useProductTile = (
  product: ProductAlgolia,
  isSuggestedProduct = false
) => {
  const { pageType } = usePageType()
  const {
    isViewportWidthUnder426,
    isMobile,
    isTabletPortrait,
    isDesktop,
    isDesktopL,
  } = useBreakpoints()
  const productsMobileListingLayout = useSelector(
    productsMobileListingLayoutSelector
  )
  const productsMobileListingLayoutPages =
    pageType === 'search' ? 'compact' : productsMobileListingLayout
  const [selectedClusterIndex, setSelectedClusterIndex] = useState<number>(0)
  const clusters = product.clusters || []
  const clusterLength = clusters.length || 0

  const clusterSelected = useMemo(
    () =>
      clusters && clusters.length > 0
        ? clusters[selectedClusterIndex]
        : product,
    [clusters, selectedClusterIndex]
  )

  const attributes = clusterSelected.attributes_translated
  const { modelCodeDisplay } =
    ProductAlgoliaUtils.getProductAttributes(attributes)

  const getTileDataElementId = (tileIndex: number) => {
    if (isSuggestedProduct) {
      return `Recommendations_Tile${tileIndex}[${modelCodeDisplay}_${product.partnumberId}]`
    }
    switch (pageType) {
      case 'search':
        return `SearchPanel_Results_Tile${tileIndex}`
      case 'pdp':
        return `X_X_AlsoLike_Tile${tileIndex}`
      default:
        return `X_X_Tiles_Tile${tileIndex}_Img`
    }
  }
  const getProductImageWidth = () => {
    const isCMS = pageType === 'search'
    switch (true) {
      case isCMS:
        return 400
      case isViewportWidthUnder426:
        return 213
      case isMobile:
        return 296
      case isTabletPortrait:
        return 600
      case isDesktopL:
        return 600
      case isDesktop:
        return 600
      default:
        return 260
    }
  }

  return {
    setSelectedClusterIndex,
    getTileDataElementId,
    getProductImageWidth: getProductImageWidth(),
    selectedClusterIndex,
    getClusterData: {
      clusters,
      clusterLength,
      clusterSelected,
    },
    productsMobileListingLayoutPages,
    productsMobileListingLayout,
  }
}

export default useProductTile
