import React, {
  useState,
  useEffect,
  createContext,
  PropsWithChildren,
  useMemo,
  useContext,
} from 'react'
import { useSite } from '../../../../foundation/hooks/useSite'

const SCRIPT_ID = 'facebook-social-oauth'
const useLoadFBAuthScript = () => {
  const { mySite } = useSite()
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const lang = mySite.locale.replace('_', '-') || 'en_US'

  const isScriptLoaded = (scriptId: string) => {
    return !!document.getElementById(scriptId)
  }

  const loadScript = (scriptId: string) => {
    const script = document.createElement('script')
    script.src = `https://connect.facebook.net/${lang}/sdk.js`
    script.id = scriptId
    script.async = true
    script.defer = true
    script.onload = () => {
      setScriptLoaded(true)
    }
    script.onerror = () => {
      setScriptLoaded(false)
    }
    return script
  }

  useEffect(() => {
    if (isScriptLoaded(SCRIPT_ID)) return
    const script = loadScript(SCRIPT_ID)

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return scriptLoaded
}

interface FacebookAuthContext {
  clientId: string
  scriptLoaded: boolean
}

const FacebookOAuthContext = createContext<FacebookAuthContext | undefined>(
  undefined
)

interface FacebookAuthProvider extends PropsWithChildren {
  clientId: string
}

export const FacebookAuthProvider: React.FC<FacebookAuthProvider> = ({
  children,
  clientId,
}) => {
  const scriptLoaded = useLoadFBAuthScript()

  const ctx = useMemo(
    () => ({ scriptLoaded, clientId }),
    [scriptLoaded, clientId]
  )

  return (
    <FacebookOAuthContext.Provider value={ctx}>
      {children}
    </FacebookOAuthContext.Provider>
  )
}

export const useFacebookOAuth = () => {
  const context = useContext(FacebookOAuthContext)

  if (!context) {
    throw new Error(
      'Facebook Auth components should used with FacebookAuthProvider'
    )
  }
  return context
}
