// Main libraries
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

// Components
import AddressForm from '../../../components/AddressForm'

// Constants
import {
  ADDRESSLINE1,
  ADDRESSLINE2,
  ADDRESS_BOOK,
  ADDRESS_LINE,
  ADDRESS_SHIPPING,
  EMPTY_STRING,
} from '../../../constants/common'

// Custom Hooks
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { useCheckoutForm } from '../../../hooks/useCheckoutForm'

// Redux
import { sendSuccessMessage } from '../../../features/success/slice'
import { FETCH_USER_DETAILS_REQUESTED_ACTION } from '../../../redux/actions/user'
import { shippingFormFieldsSelector } from '../../../redux/selectors/site'
import { useAppDispatch } from '../../../hooks/redux'

// Services
import Log from '../../../services/Log'
import personContactService from '../../../foundation/apis/transaction/personContact.service'

// Styles
import {
  AddAddressFormButtonContainer,
  AddressFormWrapper,
  NewAddressTitle,
  StyledAddAddressButton,
} from '../AddressBook.style'

// Types
import { AddressFormData } from '../../../types/form'
import { CheckoutAddressFormField } from '../../../types/checkout'
import { Contact } from '../../../types/user'

// Utils
import addressUtil from '../../../utils/addressUtil'
import { userEmailSelector } from '../../../redux/selectors/user'

interface IProps {
  formData: Contact | null
  payloadBase: any
  onEditEnd: () => void
}

const addressFormDataInit: AddressFormData = {
  firstName: EMPTY_STRING,
  lastName: EMPTY_STRING,
  addressLine1: EMPTY_STRING,
  addressLine2: EMPTY_STRING,
  city: EMPTY_STRING,
  country: EMPTY_STRING,
  state: EMPTY_STRING,
  zipCode: EMPTY_STRING,
  phone1: EMPTY_STRING,
  email1: EMPTY_STRING,
  addressType: ADDRESS_SHIPPING,
}

const AddAddressForm: React.FC<IProps> = ({
  formData,
  payloadBase,
  onEditEnd,
}) => {
  const dispatch = useAppDispatch()
  const userEmail = useSelector(userEmailSelector)
  const { t } = useTranslation()
  const storeCountry = useStoreIdentity().country.toUpperCase()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [newAddressFormData, setNewAddressFormData] = useState<AddressFormData>(
    {
      ...addressFormDataInit,
      ...formData,
      addressLine1: formData?.addressLine?.[0] || '',
      addressLine2: formData?.addressLine?.[1] || '',
      country: storeCountry,
      email1: userEmail,
    }
  )

  const shippingFormFieldsConf = useSelector(shippingFormFieldsSelector)

  const shippingFormFields: CheckoutAddressFormField[] =
    shippingFormFieldsConf?.filter(
      (i) => i.fieldName !== 'email1' && i.fieldName !== 'fiscalCode'
    ) || []

  const form = useCheckoutForm({
    defaultValues: newAddressFormData,
    fields: shippingFormFields,
  })

  const createAddress = async () => {
    const formIsValid = await form.trigger(undefined, { shouldFocus: true })
    if (!formIsValid) {
      return
    }
    // remove leading and trailing white space from all form input fields.
    let newAddressData =
      addressUtil.removeLeadingTrailingWhiteSpace(newAddressFormData)
    newAddressData[ADDRESS_LINE] = [newAddressData[ADDRESSLINE1]]
    if (
      newAddressData[ADDRESSLINE2] &&
      newAddressData[ADDRESSLINE2].trim() !== EMPTY_STRING
    ) {
      newAddressData[ADDRESS_LINE].push(newAddressData[ADDRESSLINE2])
    }
    delete newAddressData[ADDRESSLINE1]
    delete newAddressData[ADDRESSLINE2]

    setIsLoading(true)

    personContactService
      .addPersonContact({
        body: newAddressData,
        ...payloadBase,
      })
      .then((res) => res.data)
      .then((addressData) => {
        if (addressData.addressId) {
          dispatch(FETCH_USER_DETAILS_REQUESTED_ACTION({ ...payloadBase }))
          dispatch(
            sendSuccessMessage({
              key: 'success-message.ADD_ADDRESS_SUCCESS',
              messageParameters: {
                '0': newAddressData.nickName,
              },
            })
          )

          onEditEnd()
          setIsLoading(false)
        }
      })
      .catch((e) => {
        Log.error('Could not create new address', e)
        setIsLoading(false)
      })
  }

  return (
    <AddressFormWrapper>
      <NewAddressTitle>{t('AddressBook.NewAddress')}</NewAddressTitle>

      <AddressForm
        addressFormFields={shippingFormFields}
        addressType={newAddressFormData.addressType}
        cid="newAddress-shipping-and-billing"
        form={form}
        page={ADDRESS_BOOK}
        onFormDataChanged={(_, data) => setNewAddressFormData(data)}
        onFormValidaTionStatusChanged={(_, __) => {}}
      />
      <AddAddressFormButtonContainer>
        <StyledAddAddressButton
          data-element-id={'X_X_AddressBook_Add'}
          variant="primary"
          onClick={createAddress}
          loading={isLoading}
        >
          {t('AddressBook.CreateAddress')}
        </StyledAddAddressButton>
      </AddAddressFormButtonContainer>
    </AddressFormWrapper>
  )
}

export default AddAddressForm
