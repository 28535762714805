import { SiteInfoService } from './SiteInfoService'

//Standard libraries
//Redux
import { SiteInfo } from '../../../redux/reducers'
//Foundation libraries
import { useSite } from './useSite'

/**
 *
 */
function getSite(): SiteInfo | null {
  return SiteInfoService.getSiteInfo().getSiteValue()
}

/**
 *
 */
/*function initSite(
  s: SiteInfoArgs,
  lang: string,
  country: string,
  dispatch: Dispatch<any>
) {
  return SiteInfoService.getSiteInfo().setSite(s, lang, country, dispatch)
}*/

export { useSite, getSite }
