import React, { FC, useEffect, useRef } from 'react'
import { Swiper as SwiperClass } from 'swiper/types'
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'
import {
  SliderContainer,
  SliderNavigationButton,
  SliderNavigationContainer,
  StyledSingleItem,
  StyledSuggestedProductsText,
  StyledSuggestedProductsWrapper,
} from '../SuggestedProducts/SuggestedProducts.style'
import { useTranslation } from 'react-i18next'
import { SearchClient } from 'algoliasearch'
import useBreakpoints from '@hooks/useBreakpoints'
import { ProductAlgolia } from '../../types/ProductAlgolia/ProductAlgolia'
import SuggestedProductTileAlgolia, {
  SuggestedProductTileProps,
} from '@components/SuggestedProductAlgolia/components/SuggestedProductTileAlgolia/SuggestedProductTileAlgolia'

export interface SuggestedProductsProps {
  products?: ProductAlgolia[]
  sliderProps?: SwiperProps
  mainCarouselWidth?: number
  onPrevClick?: () => void
  onNextClick?: () => void
  onSlideChange?: (slideNumber: number | undefined) => void
  currentSlide?: number
  pdpdataloading?: boolean
  centeredSlides?: boolean
  productTileProps?: Partial<SuggestedProductTileProps>
  isImgWithShadow?: boolean
  isImgFront?: boolean
  searchClient?: SearchClient
  categoryFilter?: string
  searchTerm?: string
  isClustered?: boolean
}

const SuggestedProductsAlgolia: FC<SuggestedProductsProps> = ({
  products,
  currentSlide = 0,
  sliderProps,
  onSlideChange,
  productTileProps,
  isImgWithShadow,
  isImgFront,
  isClustered,
  ...rest
}: SuggestedProductsProps) => {
  const { t } = useTranslation()
  const swiperRef = useRef<SwiperClass | null>(null)
  const { isDesktop, isMobile, isTabletPortrait, isTabletLandscape } =
    useBreakpoints()
  const deviceBreakpoint = isMobile ? 2 : isTabletPortrait ? 4 : 3
  const isTabletLandscapeOrDesktop = isDesktop || isTabletLandscape

  useEffect(() => {
    swiperRef.current?.slideToLoop(currentSlide)
  }, [currentSlide])

  if (!products || products?.length < 1) return null

  return (
    <StyledSuggestedProductsWrapper>
      <SliderContainer {...rest}>
        {products.length > deviceBreakpoint ? (
          <>
            {isTabletLandscapeOrDesktop && (
              <SliderNavigationContainer>
                <SliderNavigationButton
                  data-analytics_available_call="0"
                  onClick={() => swiperRef.current?.slidePrev()}
                >
                  <StyledSuggestedProductsText>
                    {t('ProductDetails.Suggested.PrevBtn')}
                  </StyledSuggestedProductsText>
                </SliderNavigationButton>
              </SliderNavigationContainer>
            )}
            <Swiper
              loop={true}
              slidesPerView={2}
              centeredSlides={true}
              //spaceBetween={isDesktop ? 9 : 30}
              simulateTouch={true}
              onSwiper={(swiper) => (swiperRef.current = swiper)}
              onSlideChange={() =>
                onSlideChange && onSlideChange(swiperRef.current?.realIndex)
              }
              {...sliderProps}
              breakpoints={{
                1024: {
                  slidesPerView: 3,
                  simulateTouch: false,
                  centeredSlides: false,
                },
              }}
            >
              {products?.map((product, index) => (
                <SwiperSlide key={`swiper-${index}`}>
                  <SuggestedProductTileAlgolia
                    isClustered={isClustered}
                    product={product}
                    tileIndex={index}
                    isLazyLoadEnabled={true}
                    isImgWithShadow={isImgWithShadow}
                    isImgFront={isImgFront}
                    {...productTileProps}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            {isTabletLandscapeOrDesktop && (
              <SliderNavigationContainer>
                <SliderNavigationButton
                  data-analytics_available_call="0"
                  onClick={() => swiperRef.current?.slideNext()}
                >
                  <StyledSuggestedProductsText>
                    {t('ProductDetails.Suggested.NextBtn')}
                  </StyledSuggestedProductsText>
                </SliderNavigationButton>
              </SliderNavigationContainer>
            )}
          </>
        ) : (
          products.map((product, index) => (
            <StyledSingleItem>
              <SuggestedProductTileAlgolia
                key={`product-${index}`}
                isClustered={isClustered}
                product={product}
                tileIndex={index}
                isImgWithShadow={isImgWithShadow}
                isImgFront={isImgFront}
                {...productTileProps}
              />
            </StyledSingleItem>
          ))
        )}
      </SliderContainer>
    </StyledSuggestedProductsWrapper>
  )
}

export default SuggestedProductsAlgolia
