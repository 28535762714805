//Standard libraries
import React, { FC, useEffect, useState } from 'react'
import Axios, { Canceler } from 'axios'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import getDisplayName from 'react-display-name'
//UI
import UserAddress from './components/UserAddress'
import AddAddressForm from './components/AddAddressForm'
//Types
import { Contact } from '../../types/user'
//Redux
import { userDetailsSelector } from '../../redux/selectors/user'
import { FETCH_USER_DETAILS_REQUESTED_ACTION } from '../../redux/actions/user'
import { sendSuccessMessage } from '../../features/success/slice'
//Foundation libraries
import { sendAccountInformationEvent } from '../../foundation/analytics/tealium/lib'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'

import Log from '../../services/Log'

//Styles
import {
  AddressBookTitle,
  AddAddressButtonContainer,
  AddressBookWrapper,
  UserAddressContainer,
  StyledAddAddressBookButton,
} from './AddressBook.style'
import useScrollTo from '../../hooks/useScrollTo'
import { useAnalyticsData } from 'src/foundation/analytics/hooks/useAnalyticsData'
import {
  useDeletePersonContactMutation,
  useUpdatePersonContactMutation,
} from 'src/features/account/query'

const AddressBook: FC = () => {
  const widgetName = getDisplayName(AddressBook)
  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { country: storeCountry } = useStoreIdentity()
  const userDetails = useSelector(userDetailsSelector)
  const contactList = userDetails?.contact || []
  const scrollTo = useScrollTo()
  const analyticsData = useAnalyticsData('profile')

  const [isAddingNewAddress, setIsAddingNewAddress] = useState(false)
  const payloadBase = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const [updatePersonContact] = useUpdatePersonContactMutation()
  const [deletePersonContact] = useDeletePersonContactMutation()

  const addressList = contactList.filter(
    (contact) =>
      contact.country.toLowerCase() === storeCountry.toLowerCase() &&
      ['shipping'].includes(contact.addressType.toLowerCase())
  )

  const updateAddress = async (c: Contact) => {
    try {
      if (c.nickName) {
        await updatePersonContact({
          nickName: c.nickName,
          addressData: c,
        })
        //this could be removed using tags in queries
        dispatch(
          FETCH_USER_DETAILS_REQUESTED_ACTION({
            widget: 'RemoveAddressDialogLayout',
          })
        )

        dispatch(
          sendSuccessMessage({
            key: 'success-message.EDIT_ADDRESS_SUCCESS',
            messageParameters: {
              '0': c.nickName,
            },
          })
        )
      }
    } catch (e: any) {
      Log.error('Could not update address from addressbook', e)
    }
  }

  const deleteAddress = async (nickName: string | undefined) => {
    try {
      await deletePersonContact({
        nickName: nickName!,
      })

      dispatch(
        sendSuccessMessage({
          key: 'success-message.DELETE_ADDRESS_SUCCESS',
          messageParameters: {
            '0': nickName,
          },
        })
      )
      dispatch(
        FETCH_USER_DETAILS_REQUESTED_ACTION({
          widget: 'RemoveAddressDialogLayout',
        })
      )
    } catch (e: any) {
      Log.error('Could not delete address from addressbook', e)
    }
  }

  useEffect(() => {
    sendAccountInformationEvent({
      common: analyticsData,
      Page_Section2: 'AddressBook',
    })

    if (!userDetails) {
      dispatch(FETCH_USER_DETAILS_REQUESTED_ACTION(payloadBase))
    }

    return () => {
      cancels.forEach((cancel) => cancel())
    }
  }, [userDetails])

  return (
    <AddressBookWrapper>
      <AddressBookTitle>{t('AddressBook.Title')}</AddressBookTitle>
      {!contactList.length ? (
        <AddAddressForm
          formData={null}
          payloadBase={payloadBase}
          onEditEnd={() => setIsAddingNewAddress(false)}
        />
      ) : (
        <>
          <UserAddressContainer>
            {addressList.map((c) => {
              return (
                <UserAddress
                  address={c}
                  updateAddress={(c) => {
                    updateAddress(c)
                  }}
                  deleteAddress={(nickName) => {
                    deleteAddress(nickName)
                  }}
                />
              )
            })}
          </UserAddressContainer>
          {!isAddingNewAddress && (
            <AddAddressButtonContainer>
              <StyledAddAddressBookButton
                onClick={() => setIsAddingNewAddress(true)}
              >
                {t('AddressBook.AddrMsg')}
              </StyledAddAddressBookButton>
            </AddAddressButtonContainer>
          )}
          {isAddingNewAddress && (
            <AddAddressForm
              formData={null}
              payloadBase={payloadBase}
              onEditEnd={() => {
                scrollTo(0, 0)
                setIsAddingNewAddress(false)
              }}
            />
          )}
        </>
      )}
    </AddressBookWrapper>
  )
}

export default AddressBook
