import { styled } from '@mui/material/styles'
import { StyledTypography } from '../../../UI'

const CmsTeaserTitle = styled(StyledTypography, {
  name: 'CmsTeaser',
  slot: 'Title',
})(() => ({
  fontSize: 24,
  fontWeight: 600,
  lineHeight: 1.5,
}))
export default CmsTeaserTitle
