import Log from '../services/Log'
import { Store, Stores } from '../types/Tab/store'
import { Config } from '../types/storeLocator'
import { fetchJsFromCDN } from './fetchFromCdn'
import { Phone, TabStore } from '../types/tabStores'

export const STORE_LOCATOR_SCRIPT_ID = 'store-locator'
export const STORE_LOCATOR_KEY = 'STORE_LOCATOR'

interface IStoreLocatorUtils {
  initStoreLocator: (config: Config, scriptUrl: string) => void
  fetchStores: (storeName: string, scriptUrl: string) => Promise<Stores>
  unmountStoreLocator: () => void
}

interface MapStoresProps {
  stores: TabStore[]
  storeName: string
}

class StoreLocatorUtils implements IStoreLocatorUtils {
  private widget: StoreLocatorWidget | null
  // Array of promises to prevent loading script multiple times
  private promiseArr: Promise<unknown>[]
  constructor() {
    this.widget = null
    this.promiseArr = []
  }
  private isStoreLocatorScriptLoaded = (scriptId: string) => {
    return !!document.getElementById(scriptId)
  }
  private loadStoreLocatorScript = (
    scriptUrl: string,
    key = STORE_LOCATOR_KEY,
    scriptId = STORE_LOCATOR_SCRIPT_ID
  ) => {
    if (this.isStoreLocatorScriptLoaded(scriptId)) {
      return
    }
    this.promiseArr.push(fetchJsFromCDN(scriptUrl, key, { id: scriptId }))
  }
  public initStoreLocator = async (config: Config, scriptUrl: string) => {
    try {
      this.loadStoreLocatorScript(scriptUrl)
      await Promise.race(this.promiseArr)
      this.widget = storeLocator.StoreLocatorWidgetApp.new(config)
      this.widget.init()
    } catch (e) {
      Log.error('Could not init store locator')
    }
  }

  public unmountStoreLocator = () => {
    if (this.widget) {
      this.widget.unmount()
    }
  }

  public fetchStores = async (storeName: string, scriptUrl: string) => {
    this.loadStoreLocatorScript(scriptUrl)
    await Promise.all(this.promiseArr)
    return storeLocator.StoreLocatorWidgetApp.getStores(storeName)
  }

  getMapBookingStoreData = ({
    store,
    storeName,
  }: {
    store: TabStore
    storeName: string
  }): Store => {
    const monday = store.officeHours.find(
      (h) => h.dayOfWeek.toUpperCase() === 'MONDAY'
    )
    const tuesday = store.officeHours.find(
      (h) => h.dayOfWeek.toUpperCase() === 'TUESDAY'
    )
    const wednesday = store.officeHours.find(
      (h) => h.dayOfWeek.toUpperCase() === 'WEDNESDAY'
    )
    const thursday = store.officeHours.find(
      (h) => h.dayOfWeek.toUpperCase() === 'THURSDAY'
    )
    const friday = store.officeHours.find(
      (h) => h.dayOfWeek.toUpperCase() === 'FRIDAY'
    )
    const saturday = store.officeHours.find(
      (h) => h.dayOfWeek.toUpperCase() === 'SATURDAY'
    )
    const sunday = store.officeHours.find(
      (h) => h.dayOfWeek.toUpperCase() === 'SUNDAY'
    )

    return {
      storeId: store.storeNumber,
      brand: storeName,
      country: store.address.country,
      storeName: store.name,
      completeAddress: `${store.address.city}, ${store.address.address}, ${store.address.postalCode}`,
      address: store.address.address,
      city: store.address.city,
      zipcode: store.address.postalCode,
      telephone: (store.phone as Phone)?.number || '',
      storeEmailAddress: store.email,
      storeType: store.officeType,
      mondayOT: monday && !monday.isClosed ? monday.opening : 'closed',
      mondayCT: monday && !monday.isClosed ? monday.closing : 'closed',
      tuesdayOT: tuesday && !tuesday.isClosed ? tuesday.opening : 'closed',
      tuesdayCT: tuesday && !tuesday.isClosed ? tuesday.closing : 'closed',
      wednesdayOT:
        wednesday && !wednesday.isClosed ? wednesday.opening : 'closed',
      wednesdayCT:
        wednesday && !wednesday.isClosed ? wednesday.closing : 'closed',
      thursdayOT: thursday && !thursday.isClosed ? thursday.opening : 'closed',
      thursdayCT: thursday && !thursday.isClosed ? thursday.closing : 'closed',
      fridayOT: friday && !friday.isClosed ? friday.opening : 'closed',
      fridayCT: friday && !friday.isClosed ? friday.closing : 'closed',
      saturdayOT: saturday && !saturday.isClosed ? saturday.opening : 'closed',
      saturdayCT: saturday && !saturday.isClosed ? saturday.closing : 'closed',
      sundayOT: sunday && !sunday.isClosed ? sunday.opening : 'closed',
      sundayCT: sunday && !sunday.isClosed ? sunday.closing : 'closed',
      latitude: '',
      longitude: '',
      officeHours: store.officeHours,
    }
  }
  getMapBookingStoresData = ({
    stores,
    storeName,
  }: MapStoresProps): Store[] => {
    return stores.map((store) => {
      return this.getMapBookingStoreData({ store, storeName })
    })
  }

  getMapStoreLocatorData = ({ stores, storeName }: MapStoresProps): Store[] => {
    return stores.map((storeInfo) => {
      const monday = storeInfo.officeHours.find(
        (h) => h.dayOfWeek.toUpperCase() === 'MONDAY'
      )
      const tuesday = storeInfo.officeHours.find(
        (h) => h.dayOfWeek.toUpperCase() === 'TUESDAY'
      )
      const wednesday = storeInfo.officeHours.find(
        (h) => h.dayOfWeek.toUpperCase() === 'WEDNESDAY'
      )
      const thursday = storeInfo.officeHours.find(
        (h) => h.dayOfWeek.toUpperCase() === 'THURSDAY'
      )
      const friday = storeInfo.officeHours.find(
        (h) => h.dayOfWeek.toUpperCase() === 'FRIDAY'
      )
      const saturday = storeInfo.officeHours.find(
        (h) => h.dayOfWeek.toUpperCase() === 'SATURDAY'
      )
      const sunday = storeInfo.officeHours.find(
        (h) => h.dayOfWeek.toUpperCase() === 'SUNDAY'
      )

      return {
        storeId: storeInfo.storeNumber,
        brand: storeName,
        distance: storeInfo.distance,
        country: storeInfo.address.country,
        storeName: storeInfo.name,
        completeAddress: `${storeInfo.address.city}, ${storeInfo.address.address}, ${storeInfo.address.postalCode}`,
        address: storeInfo.address.address,
        city: storeInfo.address.city,
        zipcode: storeInfo.address.postalCode,
        telephone: storeInfo.phone as string,
        storeEmailAddress: storeInfo.email,
        latitude: storeInfo.latitude?.toString() || '',
        longitude: storeInfo.longitude?.toString() || '',
        storeType: storeInfo.officeType,
        bookAnEyeTest: storeInfo.isBookable ? 'Y' : 'N',
        mondayOT: monday && !monday.isClosed ? monday.opening : 'closed',
        mondayCT: monday && !monday.isClosed ? monday.closing : 'closed',
        tuesdayOT: tuesday && !tuesday.isClosed ? tuesday.opening : 'closed',
        tuesdayCT: tuesday && !tuesday.isClosed ? tuesday.closing : 'closed',
        wednesdayOT:
          wednesday && !wednesday.isClosed ? wednesday.opening : 'closed',
        wednesdayCT:
          wednesday && !wednesday.isClosed ? wednesday.closing : 'closed',
        thursdayOT:
          thursday && !thursday.isClosed ? thursday.opening : 'closed',
        thursdayCT:
          thursday && !thursday.isClosed ? thursday.closing : 'closed',
        fridayOT: friday && !friday.isClosed ? friday.opening : 'closed',
        fridayCT: friday && !friday.isClosed ? friday.closing : 'closed',
        saturdayOT:
          saturday && !saturday.isClosed ? saturday.opening : 'closed',
        saturdayCT:
          saturday && !saturday.isClosed ? saturday.closing : 'closed',
        sundayOT: sunday && !sunday.isClosed ? sunday.opening : 'closed',
        sundayCT: sunday && !sunday.isClosed ? sunday.closing : 'closed',
        storeManager: 'none',
        optometrist: 'none',
        officeHours: storeInfo.officeHours,
      }
    })
  }
}

export const storeLocatorUtils = new StoreLocatorUtils()
