import { styled } from '@mui/material/styles'
import CmsBroadContentWrapper from '../../../CmsComponents/CmsBroadContentWrapper'
import { StyledTypography } from '../../../../UI'
import CmsTeaserTitle from '../../../CmsComponents/CmsTeaserTitle'
import {
  overLaySettingsToVerticalCSS,
  overLayTextAlignToCSS,
  teaserOverlayStyleToColor,
} from '@utils/placements'
import {
  ITeaserOverlayStyle,
  ITeaserOverlaySettings,
  ITeaserOverlayTextAlign,
} from '../../../../../types/cmsPlacement/LXTeaser'
import CmsTeaserDetailText from '../../../CmsComponents/CmsTeaserDetailText'
import { PropsWithChildren } from 'react'

export const WrapperGridOfBoards = styled('div', {
  name: 'GridOfBoards',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
  rowGap: theme.spacing(6),
  //padding: theme.spacing(16, 0),

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },

  [theme.breakpoints.up('lg')]: {
    justifyContent: 'center',
    flexDirection: 'row',
    padding: theme.spacing(0, 44),
  },
}))

export const ItemGridOfBoards = styled('div', {
  name: 'GridOfBoards',
  slot: 'Item',
  shouldForwardProp: (prop) => prop !== 'columnAmount',
})<{ columnAmount: 2 | 3 }>(({ columnAmount }) => ({
  flex: columnAmount === 2 ? '0 0 50%' : '0 0 33.3%',
}))

export const ContentGridOfBoards = styled(CmsBroadContentWrapper, {
  name: 'GridOfBoards',
  slot: 'Content',
  shouldForwardProp: (prop) =>
    prop !== 'overlayTextAlign' &&
    prop !== 'overlaySettings' &&
    prop !== 'teaserOverlayStyle',
})<
  PropsWithChildren<{
    overlaySettings: ITeaserOverlaySettings
    overlayTextAlign?: ITeaserOverlayTextAlign
    teaserOverlayStyle?: ITeaserOverlayStyle
  }>
>(({ overlaySettings, overlayTextAlign, teaserOverlayStyle, theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  padding: theme.spacing(0, 4),
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 8),
  },

  [theme.breakpoints.up('lg')]: {
    height: 'auto',
    ...overLaySettingsToVerticalCSS(overlaySettings || ''),
  },
  '& > *': {
    ...overLayTextAlignToCSS(overlayTextAlign),
    ...teaserOverlayStyleToColor(theme, teaserOverlayStyle),
  },
}))

export const PreTitleGridOfBoard = styled(StyledTypography, {
  name: 'GridOfBoards',
  slot: 'PreTitle',
})(() => ({
  fontSize: 16,
}))

export const TitleGridOfBoard = styled(CmsTeaserTitle, {
  name: 'GridOfBoards',
  slot: 'Title',
})(({}) => ({
  fontSize: 16,
}))

export const TextGridOfBoard = styled(CmsTeaserDetailText, {
  name: 'GridOfBoards',
  slot: 'Text',
})(() => ({
  fontSize: 12,
}))

export const CtaContentGridOfBoard = styled('div', {
  name: 'GridOfBoards',
  slot: 'CtaContent',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(6),
  gap: theme.spacing(4),
  a: {
    width: 'auto',
  },
  [theme.breakpoints.up('sm')]: { justifyContent: 'inherit' },
}))

export const IconContentGridOfBoard = styled('div', {
  name: 'GridOfBoards',
  slot: 'IconContent',
})(({}) => ({
  display: 'flex',

  svg: {
    height: 48,
    width: 48,
  },
}))
