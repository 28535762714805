import React, { useState } from 'react'
import {
  ContentButtonNavigation,
  MiniSliderSwiper,
  ContentCarousel,
  ContentCmsMedia,
  ContentTitle,
  StyledButtonAsLink,
  StyledSlider,
  CollectionTitleMiniSlider,
  ContentCta,
  WrapperMiniSlider,
  MediaOverlapMiniSliderAnchor,
  TextMiniSlider,
  TitleMiniSlider,
} from './MiniSlider.style'
import SwiperCore, { Navigation, Scrollbar } from 'swiper'
import { SwiperSlide } from 'swiper/react'
import { CmsShoppable } from '../../../../CmsComponents/CmsShoppable/CmsShoppableImage'
import { ILXTeaser } from '../../../../../../types/cmsPlacement/LXTeaser'
import useBreakpoints from '../../../../../../hooks/useBreakpoints'
import CmsCta from '../../../../CmsComponents/CmsCta'
import useCmsTeaserBanner from '../../../../../../hooks/useCmsBanner'
import { useStoreIdentity } from '../../../../../../foundation/hooks/useStoreIdentity'
import CMSCommonMedia from '../../../../CmsComponents/CmsCommonMedia'
import { ICMCollection } from '../../../../../../types/cmsPlacement/CMCollection'
import { IViewType } from '../../../../../../types/cmsPlacement/ViewType'
import { isLXTeaser } from '../../../../../../types/cmsPlacement/Placement'
import { useTranslation } from 'react-i18next'
import usePlayerBanner, { TPlayerBannerHook } from '@hooks/useBannerPlayer'
import { IMedia, IVideoMedia } from '../../../../../../types/cmsPlacement/Media'
import CmsVideoController from '@components/Cms/CmsComponents/CmsCommonMedia/CmsVideoController'

const MiniSlider: React.FC<{
  items: ICMCollection
  teaserIndex?: number
  viewType: IViewType
}> = ({ items, teaserIndex, viewType }) => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpoints()
  const [swiper, setSwiper] = useState<SwiperCore>()
  const handleNextSlide = () => swiper?.slideNext()
  const handlePrevSlide = () => swiper?.slidePrev()

  if (!items) return null

  const collectionTitle = items.collectionTitle || ''
  const teasableItems = items.teasableItems.filter(isLXTeaser) || []

  return (
    <WrapperMiniSlider>
      {!isMobile && (
        <ContentButtonNavigation>
          <StyledButtonAsLink
            onClick={handlePrevSlide}
            data-analytics_available_call="0"
          >
            {t('Slider.Previous')}
          </StyledButtonAsLink>
        </ContentButtonNavigation>
      )}

      <ContentTitle>
        {collectionTitle && (
          <CollectionTitleMiniSlider
            variant="h3"
            data-cm-metadata={'[{"_":"properties.teaserTitle3"}]'}
          >
            {collectionTitle}
          </CollectionTitleMiniSlider>
        )}

        <StyledSlider>
          <MiniSliderSwiper
            slidesPerView={isMobile ? 'auto' : 1}
            centeredSlides={true}
            spaceBetween={8}
            modules={[Navigation, Scrollbar]}
            onSwiper={setSwiper}
            loop={!isMobile}
            scrollbar={{
              hide: false,
            }}
          >
            {teasableItems.map((item, slideIndex) => (
              <SwiperSlide key={item?.id}>
                <ContentSlider
                  item={item}
                  teaserIndex={teaserIndex}
                  slideIndex={slideIndex + 1}
                  viewType={viewType || 'default'}
                />
              </SwiperSlide>
            ))}
          </MiniSliderSwiper>
        </StyledSlider>
      </ContentTitle>
      {!isMobile && (
        <ContentButtonNavigation>
          <StyledButtonAsLink
            onClick={handleNextSlide}
            data-analytics_available_call="0"
          >
            {t('Slider.Next')}
          </StyledButtonAsLink>
        </ContentButtonNavigation>
      )}
    </WrapperMiniSlider>
  )
}

const ContentSlider: React.FC<{
  item: ILXTeaser
  slideIndex: number
  viewType?: IViewType
  media?: IMedia
  crop?: string
  playerBannerHook?: TPlayerBannerHook
  teaser?: ILXTeaser
  children?: React.ReactNode
  bannerIndex?: number
  teaserIndex?: number
}> = ({ item, teaserIndex, slideIndex, viewType }) => {
  const { basePath } = useStoreIdentity()

  if (!item) return null

  const {
    teaserTitleValue,
    teaserTextValue,
    teaserLXCallToActionSettings,
    teaserTitle,
    teaserText,
    placementHotZones,
    isShoppableImage,
    placementHotZonesSettings,
    promoteToH1,
    toLink,
    media,
    isVideo,
  } = useCmsTeaserBanner({ item: item, viewType })

  const playerBanner = usePlayerBanner(media! as IVideoMedia)

  const bannerImageCommonProps = {
    'aria-label': `Placement_Banner${slideIndex}_IMG link. Teaser №${teaserIndex}`,
    'data-element-id': `${teaserIndex}Placement_Banner${slideIndex}_IMG`,
  }

  const teaserLXCallToActionSettingsValue = teaserLXCallToActionSettings?.[0]
  return (
    <>
      <ContentCmsMedia>
        {isShoppableImage && (
          <CmsShoppable
            hotZones={placementHotZones}
            hotZonesSettings={placementHotZonesSettings}
          />
        )}
        {toLink ? (
          <MediaOverlapMiniSliderAnchor
            {...bannerImageCommonProps}
            to={`${basePath}${toLink}`}
          >
            <CMSCommonMedia
              isLazy={false}
              viewType={viewType}
              type={'COMBO_MINI_SLIDER_SMALL'}
              media={media!}
              teaser={item}
              playerBannerHook={playerBanner}
              isBackgroundGradientNeeded={false}
              isFetchPriority={teaserIndex === 1}
            />
          </MediaOverlapMiniSliderAnchor>
        ) : (
          <CMSCommonMedia
            isLazy={false}
            viewType={viewType}
            type={'COMBO_MINI_SLIDER_SMALL'}
            media={media!}
            teaser={item}
            playerBannerHook={playerBanner}
            isBackgroundGradientNeeded={false}
            isFetchPriority={teaserIndex === 1}
          />
        )}
      </ContentCmsMedia>
      <ContentCarousel>
        {teaserTextValue && (
          <TitleMiniSlider
            data-cm-metadata={`[{"_":"properties.${teaserTitle}"}]`}
            variant={promoteToH1 ? 'h1' : 'h2'}
          >
            {teaserTitleValue}
          </TitleMiniSlider>
        )}
        {teaserTextValue && (
          <TextMiniSlider
            data-cm-metadata={`[{"_":"properties.${teaserText}"}]`}
            dangerouslySetInnerHTML={{
              __html: teaserTextValue,
            }}
          />
        )}
        {teaserLXCallToActionSettingsValue && (
          <ContentCta>
            <CmsCta
              dataElementId={`${teaserIndex}Placement_Banner${
                slideIndex + 1
              }_CTA${1}`}
              key={`dc-cms-content__cta-${1}`}
              actionSettings={teaserLXCallToActionSettingsValue}
            />
          </ContentCta>
        )}
        {isVideo && <CmsVideoController playerBanner={playerBanner} />}
      </ContentCarousel>
    </>
  )
}
export default MiniSlider
