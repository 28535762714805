import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import {
  getPopperSX,
  StyledCurrentDate,
  StyledDatePickerDays,
} from './StyledDatepickerComponents'
import {
  ArrowLeftIcon,
  ArrowDownIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  WarningIcon,
  CalendarIconSmall,
} from '../Icons'
import { DesktopDatePicker } from '@mui/x-date-pickers'

interface Props extends DatePickerProps<Date, Date> {
  error?: boolean
}

export const StyledDatePicker = (props: Props) => {
  const { value, onChange, views = [] } = props
  const date = new Date(value || '')
  const isValueExists = !!date.getTime()

  const [currentDate, setCurrentDate] = useState(
    isValueExists ? date : new Date()
  )
  const [view, setView] = useState<'day' | 'month' | 'year'>('day')
  const theme = useTheme()

  const controllersColor = theme.palette.primary.main
  const showMonthController = !views.includes('month') || view === 'day'

  const getDatePickerDays = (_day, _selectedDays, pickersDayProps) => {
    const isDateManuallySelected =
      isValueExists &&
      date.getMonth() === new Date(currentDate).getMonth() &&
      date.getFullYear() === new Date(currentDate).getFullYear()

    return (
      <StyledDatePickerDays
        isDateSelected={isDateManuallySelected}
        {...pickersDayProps}
      />
    )
  }

  const getMonthController = (props) => (
    <>
      <ArrowLeftIcon cursor="pointer" htmlColor={controllersColor} {...props} />
      <StyledCurrentDate type="month">
        {currentDate.toLocaleString('default', { month: 'long' })}
      </StyledCurrentDate>
    </>
  )

  const getRightArrowButton = (props) => (
    <ArrowRightIcon cursor="pointer" htmlColor={controllersColor} {...props} />
  )

  const getSwitchViewButton = () => {
    const ArrowIcon = view === 'day' ? ArrowDownIcon : ArrowUpIcon
    const currentYear = new Date(currentDate).getFullYear()

    return (
      <>
        <StyledCurrentDate type="year">{currentYear}</StyledCurrentDate>
        <ArrowIcon htmlColor={controllersColor} />
      </>
    )
  }

  const onCurrentViewDateChange = (date, eventType: 'month' | 'year') => {
    const currentDate = new Date(date)

    setCurrentDate((prevValue) => {
      const previousYear = prevValue.getFullYear()
      const isSameYear = previousYear === currentDate.getFullYear()
      const shouldKeepPreviousDateYear =
        !isSameYear && view === 'year' && eventType !== 'year'

      return shouldKeepPreviousDateYear
        ? new Date(currentDate.setFullYear(previousYear))
        : currentDate
    })
  }

  const onChangeDate = (date, selectionState) => {
    if (view !== 'day') {
      return
    }

    onChange(date, selectionState)
  }

  const onOpenDatepicker = () => {
    setCurrentDate(isValueExists ? (value as Date) : new Date())
  }

  const datePickerProps = {
    ...props,
    onChange: onChangeDate,
    onOpen: onOpenDatepicker,
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        components={{
          OpenPickerIcon: () => (
            <>
              {props.error && (
                <WarningIcon
                  style={{ marginRight: '18px' }}
                  htmlColor={theme.palette.error.main}
                />
              )}
              <CalendarIconSmall htmlColor={theme.palette.text.dark} />
            </>
          ),
          LeftArrowButton: getMonthController,
          RightArrowButton: getRightArrowButton,
          SwitchViewButton: getSwitchViewButton,
        }}
        renderDay={getDatePickerDays}
        PopperProps={{
          sx: getPopperSX({
            theme,
            showMonthController,
          }),
        }}
        onMonthChange={(date) => onCurrentViewDateChange(date, 'month')}
        onYearChange={(date) => {
          const mergedDate = new Date(date.setMonth(currentDate.getMonth()))

          onCurrentViewDateChange(mergedDate, 'year')
        }}
        onViewChange={setView}
        {...datePickerProps}
      />
    </LocalizationProvider>
  )
}
