import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IUiState {
  openDrawerMegaMenu: {
    isOpen: boolean
    id: string
    categoryName?: string
  }
  pdp: {
    preventRedirectToRecommendedSize: boolean
  }
  openDrawerFilters: boolean
  openDrawerSearch: boolean
  openDrawerNewsLetter: boolean
  openDrawerCartSummary: boolean
  hamburgerMenuDrawerOpen: boolean
  openDrawerProductNotificationAvailable: boolean
  lastSelectedProduct: string
  productsMobileListingLayout: 'compact' | 'full'
  openModalSignIn: boolean
  openModalRegistration: boolean
  openModalFindStore: boolean
  isCfInvalid: boolean
  faqsSearch: string
  chatBot: {
    isOpen: boolean
    isReady: boolean
  }
  pageType: string
}

export const initialStateUi: IUiState = {
  openDrawerMegaMenu: {
    isOpen: false,
    id: '',
    categoryName: '',
  },
  pdp: {
    preventRedirectToRecommendedSize: false,
  },
  openDrawerFilters: false,
  openDrawerSearch: false,
  openDrawerNewsLetter: false,
  openDrawerCartSummary: false,
  hamburgerMenuDrawerOpen: false,
  openDrawerProductNotificationAvailable: false,
  lastSelectedProduct: '',
  productsMobileListingLayout: 'compact',
  openModalSignIn: false,
  openModalRegistration: false,
  openModalFindStore: false,
  isCfInvalid: false,
  faqsSearch: '',
  chatBot: {
    isOpen: false,
    isReady: false,
  },
  pageType: '',
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState: initialStateUi,
  reducers: {
    openDrawerMegaMenu: (state: IUiState, action) => {
      state.openDrawerMegaMenu.id = action.payload.id
      state.openDrawerMegaMenu.categoryName = action.payload.name
      state.openDrawerMegaMenu.isOpen = true
    },
    closeDrawerMegaMenu: (state: IUiState) => {
      state.openDrawerMegaMenu.id = ''
      state.openDrawerMegaMenu.categoryName = ''
      state.openDrawerMegaMenu.isOpen = false
    },
    openDrawerFilters: (state: IUiState, action) => {
      state.openDrawerFilters = action.payload
    },
    openDrawerSearch: (state: IUiState, action) => {
      state.openDrawerSearch = action.payload
    },
    openDrawerNewsLetter: (state: IUiState, action) => {
      state.openDrawerNewsLetter = action.payload
    },
    openDrawerCartSummary: (state: IUiState, action) => {
      state.openDrawerCartSummary = action.payload
    },
    openDrawerProductNotificationAvailable: (state: IUiState, action) => {
      state.openDrawerProductNotificationAvailable = action.payload
    },
    toggleHamburgerMenuOpen: (state: IUiState, action) => {
      state.hamburgerMenuDrawerOpen = action.payload
    },
    lastSelectedProduct: (state: IUiState, action) => {
      state.lastSelectedProduct = action.payload
    },
    productsMobileListingLayout: (state: IUiState, action) => {
      state.productsMobileListingLayout = action.payload
    },
    openModalSignIn: (state: IUiState, action) => {
      state.openModalSignIn = action.payload
    },
    openModalRegistration: (state: IUiState, action) => {
      state.openModalRegistration = action.payload
    },
    openModalFindStore: (state: IUiState, action) => {
      state.openModalFindStore = action.payload
    },
    faqsSearch: (state: IUiState, action) => {
      state.faqsSearch = action.payload
    },
    isCfInvalid: (state: IUiState, action) => {
      state.isCfInvalid = action.payload
    },
    setPreventRedirectToRecommendedSize: (
      state: IUiState,
      action: PayloadAction<boolean>
    ) => {
      state.pdp.preventRedirectToRecommendedSize = action.payload
    },
    isOpenChatBot: (state: IUiState, action) => {
      state.chatBot.isOpen = action.payload
    },
    isReadyChatBot: (state: IUiState, action) => {
      state.chatBot.isReady = action.payload
    },
    pageType: (state: IUiState, action: PayloadAction<string>) => {
      state.pageType = action.payload
    },
  },
})

export const {
  openDrawerFilters,
  openDrawerMegaMenu,
  closeDrawerMegaMenu,
  openDrawerSearch,
  openDrawerNewsLetter,
  openDrawerCartSummary,
  openDrawerProductNotificationAvailable,
  toggleHamburgerMenuOpen,
  lastSelectedProduct,
  productsMobileListingLayout,
  openModalSignIn,
  openModalRegistration,
  openModalFindStore,
  faqsSearch,
  isCfInvalid,
  setPreventRedirectToRecommendedSize,
  isOpenChatBot,
  isReadyChatBot,
  pageType,
} = uiSlice.actions

export default uiSlice.reducer
