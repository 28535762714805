import { styled } from '@mui/material/styles'
import { Z_INDEX_LV3 } from '../../../../../constants/ui'
import {
  ITeaserOverlayStyle,
  ITeaserOverlaySettings,
  ITeaserOverlayTextAlign,
} from '../../../../../types/cmsPlacement/LXTeaser'
import {
  getRatioBanner,
  overLayTextAlignToCSS,
  teaserOverlayStyleToColor,
} from '../../../../../utils/placements'
import CmsBroadContentWrapper from '../../../CmsComponents/CmsBroadContentWrapper'
import { CmsBroadContentWrapperProps } from '../../../CmsComponents/CmsBroadContentWrapper/CmsBroadContentWrapper'
import CmsTeaserDetailText from '../../../CmsComponents/CmsTeaserDetailText'
import CmsTeaserTitle from '../../../CmsComponents/CmsTeaserTitle'
import { CmsTermsAndConditionsButton } from '../../../CmsComponents/CmsTermsAndConditions'
import { StyledAnchor, StyledTypography } from '../../../../UI'
import { CmsCountdown } from '../../../CmsComponents/CmsCountdown'
import { IViewType } from '../../../../../types/cmsPlacement/ViewType'
import { PropsWithChildren } from 'react'

export const StyledFullWidthBannerWrapper = styled(CmsBroadContentWrapper, {
  name: 'FullWidthBanner',
  slot: 'Wrapper',
})<PropsWithChildren<CmsBroadContentWrapperProps>>(({ theme }) => ({
  padding: theme.spacing(0, 4),
  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    maxWidth: '36%',
    height: 'auto',
    padding: theme.spacing(16),
  },
}))

export const StyledFullWidthBannerArticle = styled('article', {
  name: 'FullWidthBanner',
  slot: 'Article',
  shouldForwardProp: (prop) =>
    prop !== 'overlaysettings' &&
    prop !== 'overlaytxtalign' &&
    prop !== 'teaseroverlaystyle',
})<{
  overlaysettings?: ITeaserOverlaySettings
  overlaytxtalign?: ITeaserOverlayTextAlign
  teaseroverlaystyle?: ITeaserOverlayStyle
}>(({ theme, overlaytxtalign, teaseroverlaystyle }) => ({
  height: 'calc(100% - 22px)',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  justifyContent: 'flex-end',

  [theme.breakpoints.up('md')]: {
    height: 'unset',
  },
  '& > *': {
    ...overLayTextAlignToCSS(overlaytxtalign),
    ...teaserOverlayStyleToColor(theme, teaseroverlaystyle),
  },
}))

export const StyledFullWidthBannerCountDown = styled(CmsCountdown, {
  name: 'TopPageBanner',
  slot: 'CountDown',
})(({ theme }) => ({
  marginBottom: theme.spacing(6),
}))
export const StyledFullWidthBannerPreTitle = styled(StyledTypography, {
  name: 'FullWidthBanner',
  slot: 'PreTitle',
})(() => ({
  fontSize: 16,
}))

export const StyledFullWidthBannerTitle = styled(CmsTeaserTitle, {
  name: 'FullWidthBanner',
  slot: 'Title',
})(() => ({}))

export const StyledFullWidthBannerTextContainer = styled(CmsTeaserDetailText, {
  name: 'FullWidthBanner',
  slot: 'TextContainer',
})(({ theme }) => ({
  marginBottom: theme.spacing(6),
  width: 'unset',
}))

export const StyledFullWidthBannerCTAContainer = styled('div', {
  name: 'FullWidthBanner',
  slot: 'CTAContainer',
})(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexFlow: 'column wrap',
  gap: theme.spacing(4),
  //ctas are centered on mobile
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
  },
  minHeight: 42,
  marginTop: -22,
  '& > *': {
    height: 'auto',
    maxWidth: 285,
    [theme.breakpoints.up('md')]: {
      maxWidth: 343,
    },
  },
  [theme.breakpoints.up('md')]: {
    marginTop: 'unset',
    flexDirection: 'row',
    '& > *': {
      width: 'auto',
    },
  },
}))

export const MediaOverlapFullWidthContainer = styled('div', {
  name: ' ImageOverlap',
  slot: 'FullWidthContainer',
  shouldForwardProp: (prop) => prop !== 'viewType',
})<{ viewType: IViewType }>(({ viewType, theme }) => ({
  width: '100%',
  position: 'relative',
  ...getRatioBanner(viewType, theme),
}))

export const MediaOverlapFullWidthAnchor = styled(StyledAnchor, {
  name: 'MediaOverlapFullWidth',
  slot: 'Anchor',
})(() => ({
  display: 'block',
  height: '100%',
  fontSize: 0,
  // Setting the implicit height for full width banner on different breakpoints
  /*...(Object.keys(fullWidthBannerSizeHeights) as Breakpoint[]).reduce(
    (acc, cur) => {
      acc[theme.breakpoints.up(cur)] = {
        height: fullWidthBannerSizeHeights[cur],
      }

      return acc
    },
    {}
  ),*/

  '&::after': {
    backgroundColor: 'transparent',
  },
  '& > span': {
    display: 'flex !important',
  },
}))

export const FullWidthTermsConditionButton = styled(
  CmsTermsAndConditionsButton,
  {
    name: ' FullWidth',
    slot: 'TermsConditionButton',
  }
)(() => ({}))

export const FullWidthTermsConditionButtonContainer = styled('div', {
  name: ' FullWidth',
  slot: 'TermsConditionButtonContainer ',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(6),
    zIndex: Z_INDEX_LV3,
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
}))
export const IconContentFullWidthBanner = styled('div', {
  name: 'GridOfBoards',
  slot: 'IconContent',
})(({}) => ({
  display: 'flex',

  svg: {
    height: 48,
    width: 48,
  },
}))

export const FullWidthBannerMobileWrapper = styled('div', {
  name: 'FullWidthBanner',
  slot: 'MobileWrapper',
})(({ theme }) => ({
  marginBottom: theme.spacing(10),
}))
