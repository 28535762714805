import { RootReducerState } from '../../redux/reducers'

export const productTypeSelector = (state: RootReducerState) =>
  state.product.productType
export const productContactLensesDataSelector = (state: RootReducerState) =>
  state.product.contactLensesData
export const fieldsErrorStatusSelector = (state: RootReducerState) =>
  state.product.fieldsHaveErrors
export const productAddToCartEnabledSelector = (state: RootReducerState) =>
  state.product.addToCartEnabled
export const addedToCartSuccessSelector = (state: RootReducerState) =>
  state.success.link
export const addContactLensesToCartErrorSelector = (state: RootReducerState) =>
  state.product.addContactLensesToCartError
export const moCoOrderedIdsFromPlp = (state: RootReducerState) =>
  state.product.moCoOrderedIds
