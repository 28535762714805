import { elasticSearchApi } from '../elasticSearchApi'
import { RootReducerState } from '../../redux/reducers'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'

type NewsletterPayload = {
  id?: string
  firstName?: string
  email: string
  from?: string
}

interface NewsletterResponse {
  code: string
  message: string
}

export const extendedNewsletterApi = elasticSearchApi.injectEndpoints({
  endpoints: (builder) => ({
    subscribeToNewsletter: builder.mutation<
      NewsletterResponse,
      NewsletterPayload
    >({
      async queryFn(args, api, _extraOptions, fetchWithBQ) {
        const state = api.getState() as RootReducerState
        const { newsletterType = '' } = state.site.currentSite!

        const body = {
          newsletter_id: newsletterType,
          ...args,
        }
        const result = await fetchWithBQ({
          url: '/store/{storeId}/newsletter',
          method: 'POST',
          body,
        })

        return result.data
          ? { data: result.data as NewsletterResponse }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    unSubscribeToNewsletter: builder.mutation<
      { status: string },
      { email: string }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: '/store/{storeId}/newsletter/optOut',
          method: 'POST',
          body: {
            email: args.email,
          },
        })

        return result.data
          ? { data: result.data as { status: string } }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
  }),
  overrideExisting: true,
})

export const {
  useSubscribeToNewsletterMutation,
  useUnSubscribeToNewsletterMutation,
} = extendedNewsletterApi
