import { FacebookIcon } from '@components/UI/Icons'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FacebookButton } from '../../styles/SocialLogin.style'
import { useFacebookOAuth } from './index'
import { FBauthResponse, FBstatusResponse, FBauthType } from './types'

interface FacebookLogin {
  onSuccess: (auth: FBauthResponse) => void
  onError?: (err: Pick<FBstatusResponse, 'status'>) => void
  fields: string
  language?: string
  scope?: string
  returnScopes?: boolean
  authType?: FBauthType
}

const useFacebookLogin = ({
  onSuccess,
  onError,
  fields,
  language = 'en_US',
  scope = 'public_profile,email',
  returnScopes = false,
  authType,
}: FacebookLogin) => {
  const { clientId, scriptLoaded } = useFacebookOAuth()
  const [isProcessing, setIsProcessing] = useState(false)

  const responseApi = useCallback(
    (authResponse: FBauthResponse) => {
      window.FB?.api(
        '/me',
        'get',
        { locale: language, fields: fields },
        (me) => {
          const res = { ...me, ...authResponse }
          onSuccess(res)
        }
      )
    },
    [onSuccess, language, fields]
  )

  const checkLoginState = useCallback(
    (response: FBstatusResponse) => {
      if ('authResponse' in response) {
        responseApi(response.authResponse as FBauthResponse)
      } else {
        onError?.({ status: response.status })
      }
      setIsProcessing(false)
    },
    [responseApi, onError]
  )

  useEffect(() => {
    if (!scriptLoaded) return
    window.fbAsyncInit = () => {
      window.FB?.init({
        version: 'v16.0',
        appId: clientId,
      })
    }
  }, [scriptLoaded, clientId])

  const login = useCallback(() => {
    if (isProcessing) return
    setIsProcessing(true)

    window.FB?.getLoginStatus((response: FBstatusResponse) => {
      if (response.status === 'connected') {
        checkLoginState(response)
      } else {
        window.FB?.login(checkLoginState, {
          scope,
          return_scopes: returnScopes,
          auth_type: authType,
        })
      }
    })
  }, [isProcessing, scope, returnScopes, authType])

  return {
    login,
    isProcessing,
  }
}

interface FacebookBtn extends FacebookLogin {}

export const FacebookBtn: React.FC<FacebookBtn> = ({ onSuccess, fields }) => {
  const { t } = useTranslation()
  const { login, isProcessing } = useFacebookLogin({ onSuccess, fields })

  return (
    <FacebookButton disabled={isProcessing} onClick={login}>
      <FacebookIcon />
      <span>{t('SignInPage.Socials.Facebook')}</span>
    </FacebookButton>
  )
}
