import { styled } from '@mui/material/styles'
import { Button } from '@components/UI'

export const StyledSignInCheckoutContainer = styled('div', {
  name: 'SignInFormCheckout',
  slot: 'Container',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  marginBottom: theme.spacing(4),
}))

export const StyledSingInFormCheckout = styled('form', {
  name: 'SignInFormCheckout',
  slot: 'Form',
})(({ theme }) => ({
  '& > div:first-of-type': {
    marginTop: theme.spacing(-4),
  },
}))

export const StyledTextFieldCheckoutWrapper = styled('div', {
  name: 'SignInFormCheckout',
  slot: 'TextFieldCheckoutWrapper',
})(({ theme }) => ({
  padding: theme.spacing(4, 0),
}))

export const StyledSignInCheckoutButtonWrapper = styled('div', {
  name: 'SignInFormCheckout',
  slot: 'ButtonWrapper',
})(({ theme }) => ({
  width: '100%',
  alignContent: 'stretch',
  marginTop: theme.spacing(6),

  '& > button': {
    width: '100%',
  },
}))

export const StyledSignInCheckoutButton = styled(Button, {
  name: 'SignInFormCheckout',
  slot: 'Button',
})(() => ({}))

export const StyledLoginErrorContainer = styled('div', {
  name: 'SignInFormCheckout',
  slot: 'ErrorContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(2),
}))
