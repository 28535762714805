import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import initStates from '../../redux/reducers/initStates'
import { ContextReducerState } from '../../redux/reducers'
import { contextApi, PersonalizationIdResponse } from './query'
import listenerMiddleware from '../../redux/listener'

import { USER_CONTEXT_REQUEST_ACTION } from '../../redux/actions/context'
import {
  GUEST_LOGIN_SUCCESS_ACTION,
  LOGOUT_SUCCESS_ACTION,
  REGISTRATION_SUCCESS_ACTION,
} from '../../redux/actions/user'
import { localStorageUtil } from '../../foundation/utils/storageUtil'
import { PERSONALIZATION_ID } from '../../foundation/constants/user'

const contextSlice = createSlice({
  name: 'context',
  initialState: initStates.context,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      contextApi.endpoints?.getContext.matchFulfilled,
      (state: ContextReducerState | any, { payload }: PayloadAction<any>) => {
        Object.assign(state, payload)
      }
    )
    builder.addMatcher(
      contextApi.endpoints?.getPersonalizationId.matchFulfilled,
      (_, { payload }: PayloadAction<PersonalizationIdResponse>) => {
        const { personalizationID } = payload
        localStorageUtil.set(PERSONALIZATION_ID, personalizationID)
      }
    )
  },
})

export const contextListeners = (
  startListening: typeof listenerMiddleware.startListening
) => {
  startListening({
    matcher: isAnyOf(
      //LOGIN_SUCCESS_ACTION,
      LOGOUT_SUCCESS_ACTION,
      GUEST_LOGIN_SUCCESS_ACTION,
      REGISTRATION_SUCCESS_ACTION,
      USER_CONTEXT_REQUEST_ACTION
    ),
    effect: async (action, listenerApi) => {
      listenerApi.dispatch(
        contextApi.endpoints?.getContext.initiate({
          queryParams: { id: Date.now() },
        })
      )
    },
  })
}

const { reducer } = contextSlice
export default reducer
