import React, { useEffect } from 'react'
import {
  StoreDetailsContainer,
  StoreDetailsTitle,
  StyledSeparator,
} from './StoreDetails.style'
import { useTranslation } from 'react-i18next'
import StoreInfo from './components/StoreInfo/StoreInfo'
import OurServices from './components/OurServices/OurServices'
import OurShops from './components/OurShops'
import { useSearchParams } from 'react-router-dom'
import LoadingSkeleton from '@components/Cms/CmsComponents/CmsCommonMedia/LoadingSkeleton'
import NotFound from '../NotFound/NotFound'
import { useGetBookingStoreQuery } from '../../features/stores/query'
import { Helmet } from 'react-helmet'
import config from '@configs/config.base'
import { capitalizeFirstLetter } from '@utils/common'
import { sendStoreDetailsEvent } from 'src/foundation/analytics/tealium/lib'
import { useAnalyticsData } from 'src/foundation/analytics/hooks/useAnalyticsData'

const StoreDetails = () => {
  const { t } = useTranslation()
  const analyticsData = useAnalyticsData('storeLocator')
  const [searchParams] = useSearchParams()
  const storeNumber = searchParams.get('storeNumber') || ''

  const {
    data: store,
    isLoading,
    isError,
  } = useGetBookingStoreQuery({ storeNumber })

  const storeName = store?.storeName

  useEffect(() => {
    if (!storeName) {
      return
    }
    sendStoreDetailsEvent(storeName, analyticsData)
  }, [storeName])

  if (isLoading) return <LoadingSkeleton height={'calc(100vh - 64px)'} />
  if (isError || !store) return <NotFound />

  const title = `${t('StoreDetails.Labels.StoreBrand')} - ${store.address} ${
    store.city
  }`
  const description = t('StoreDetails.SEO.Description', {
    city: capitalizeFirstLetter(store.city),
  })

  return (
    <>
      <Helmet>
        <title>{t('StoreDetails.SEO.Title', { storeTitle: title })}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={window.location.href} />
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Store',
            '@id': window.location.href,
            url: window.location.href,
            name: `${t('StoreDetails.Labels.Optics')} ${title}`,
            description,
            email: store.storeEmailAddress,
            telephone: store.telephone,
            image: `${config.defaultDamDomain}/sdp/${storeNumber}/store.jpg`,
            address: [
              {
                '@type': 'PostalAddress',
                streetAddress: store.address,
                addressLocality: capitalizeFirstLetter(store.city),
                postalCode: store.zipcode,
                addressCountry: store.country,
              },
            ],
            openingHoursSpecification: [
              store.officeHours.map((officeHours) => ({
                '@type': 'OpeningHoursSpecification',
                dayOfWeek: `https://schema.org/${capitalizeFirstLetter(
                  officeHours.dayOfWeek
                )}`,
                opens: officeHours.opening,
                closes: officeHours.closing,
              })),
            ],
          })}
        </script>
      </Helmet>
      <StoreDetailsTitle variant="h1" fontWeight={'bold'}>
        {title}
      </StoreDetailsTitle>
      <StyledSeparator />
      <StoreDetailsContainer>
        <StoreInfo store={store} />
      </StoreDetailsContainer>
      <OurServices />
      <StoreDetailsContainer>
        <OurShops storeNumber={storeNumber} />
      </StoreDetailsContainer>
    </>
  )
}

export default StoreDetails
