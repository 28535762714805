import React, { useState, useEffect } from 'react'
import { IVideoMedia } from '../../../../../../types/cmsPlacement/Media'
import { ImageOverlapProps } from '@components/Cms/CmsPlacement/CmsBanner/CMBanner.type'
import usePlayerBanner from '../../../../../../hooks/useBannerPlayer'
import CMSCommonMedia from '../../../../CmsComponents/CmsCommonMedia'
import { useStoreIdentity } from '../../../../../../foundation/hooks/useStoreIdentity'
import useBreakpoints from '../../../../../../hooks/useBreakpoints'
import { placementHasTextShadow } from '@utils/placements'

import {
  BoardWithFieldsCmsShoppable,
  MediaOverlapBoardWithFieldsAnchor,
  MediaOverlapBoardWithFieldsContainer,
} from '../BoardWithFieldsBanner.style'
import useCmsTeaserBanner from '../../../../../../hooks/useCmsBanner'
import CmsVideoController from '@components/Cms/CmsComponents/CmsCommonMedia/CmsVideoController'
import { IMedia } from '../../../../../../types/cmsPlacement/Media'
import { isVideoMedia } from '../../../../../../types/cmsPlacement/Placement'

interface ImageOverlapPropsBoardWithField extends ImageOverlapProps {
  countItems: number
}
const BoardWithFieldsMediaOverlap: React.FC<
  ImageOverlapPropsBoardWithField
> = ({
  viewType,
  teaser,
  teaserIndex,
  bannerIndex,
  crop,
  children,
  countItems,
}) => {
  const { basePath } = useStoreIdentity()
  const { isMobile } = useBreakpoints()
  const [media, setMedia] = useState<IMedia>()
  const [video, setVideo] = useState<IVideoMedia>()
  const playerBanner = usePlayerBanner(video)

  const {
    placementHotZones,
    placementHotZonesSettings,
    isShoppableImage,
    isVideo,
    toLink,
  } =
    useCmsTeaserBanner({
      item: teaser,
      viewType,
    }) || {}

  const bannerImageCommonProps = {
    'aria-label': `Placement_Banner${bannerIndex}_IMG link. Teaser №${teaserIndex}`,
    'data-element-id': `${teaserIndex}Placement_Banner${bannerIndex}_IMG`,
  }

  const isHoverOnBoardWithFieldsBelow =
    !isMobile &&
    viewType === 'boards-with-fields-below' &&
    teaser?.media?.length === 3 &&
    video === undefined

  useEffect(() => {
    if (teaser?.type !== 'LXTeaser') return
    if (isVideoMedia(teaser.media?.[0])) {
      setVideo(teaser?.media?.[0])
    }
    if (isMobile && teaser?.media?.[1] && video === undefined) {
      setMedia(teaser?.media?.[1])
    } else {
      setMedia(teaser?.media?.[0])
    }
  }, [teaser])

  const onMediaMouseEnter = () => {
    if (isHoverOnBoardWithFieldsBelow) {
      setMedia(teaser?.media?.[2])
    }
  }

  const onMediaMouseLeave = () => {
    if (isHoverOnBoardWithFieldsBelow) {
      setMedia(teaser?.media?.[0])
    }
  }

  if (teaser?.type !== 'LXTeaser') {
    return null
  }

  return (
    <MediaOverlapBoardWithFieldsContainer
      onMouseEnter={onMediaMouseEnter}
      onMouseLeave={onMediaMouseLeave}
      viewType={viewType!}
      countItems={countItems}
    >
      {children}
      {toLink ? (
        <MediaOverlapBoardWithFieldsAnchor
          {...bannerImageCommonProps}
          to={`${basePath}${toLink}`}
        >
          <CMSCommonMedia
            isLazy={false}
            viewType={viewType!}
            type={crop || ''}
            media={media}
            playerBannerHook={playerBanner}
            teaser={teaser}
            isBackgroundGradientNeeded={placementHasTextShadow({
              teaser,
              viewType,
            })}
            isFetchPriority={teaserIndex === 1}
          />
        </MediaOverlapBoardWithFieldsAnchor>
      ) : (
        <CMSCommonMedia
          isLazy={false}
          viewType={viewType!}
          type={crop || ''}
          media={media}
          playerBannerHook={playerBanner}
          teaser={teaser}
          isBackgroundGradientNeeded={placementHasTextShadow({
            teaser,
            viewType,
          })}
          isFetchPriority={teaserIndex === 1}
        />
      )}
      {isShoppableImage && (
        <BoardWithFieldsCmsShoppable
          hotZones={placementHotZones}
          hotZonesSettings={placementHotZonesSettings}
        />
      )}
      {isVideo && <CmsVideoController playerBanner={playerBanner} />}
    </MediaOverlapBoardWithFieldsContainer>
  )
}

export default BoardWithFieldsMediaOverlap
