import React, { useState } from 'react'
import config from '@configs/index'
import ProductImage, { ProductImageProps } from '../ProductImage/ProductImage'
import {
  StyledImageWrapper,
  StyledNoProductVariants,
  StyledProductAnchor,
  StyledProductSubWrapper,
  StyledProductTileImageContainer,
  StyledProductTileWrapper,
  StyledProductVariants,
  StyledSquare,
} from './ProductTile.style'
import { useTranslation } from 'react-i18next'
import { productsMobileListingLayoutSelector } from '../../features/ui/selector'
import { setLastSelectedProduct } from '../../features/ui/action'
import { useAppDispatch } from '@hooks/redux'
import useBreakpoints from '../../hooks/useBreakpoints'
import { usePageType } from '../../foundation/hooks/usePageType'
import { useSelector } from 'react-redux'
import ProductTileUtils from './ProductTileUtilsOld'
import useProductTileOld from '@components/ProductTile/useProductTileOld'
import { ProductTileProps } from '@components/ProductTile/ProductTileOld.type'
import ProductTileHeaderOld from './components/ProductTileHeader/ProductTileHeaderOld'
import ProductTileFooterOld from './components/ProductTileFooter/ProductTileFooterOld'
import ProductTileGalleryOld from './components/ProductTileGallery/ProductTileGalleryOld'

const siteName = config.REACT_APP_STORENAME

const ProductTileOld: React.FC<ProductTileProps> = ({
  footerElementsDisplay = { colors: true, inWishlistAdded: false },
  product,
  onClick,
  tileIndex,
  variant,
  isPDP,
  isTrendingProduct,
}: ProductTileProps) => {
  if (!product) {
    return <></>
  }
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { pageType } = usePageType()
  const { isDesktop } = useBreakpoints()
  const productsMobileListingLayout = useSelector(
    productsMobileListingLayoutSelector
  )
  const [carouselMode, setCarouselMode] = useState<boolean>(false)

  const {
    getTileDataElementId,
    getProductImageWidth,
    getClustersData,
    selectedClusterIndex,
    setSelectedClusterIndex,
  } = useProductTileOld(product)

  const {
    clusters,
    clusterLength,
    clusterSelected,
    productPrimaryBadge,
    productSecondaryBadges,
    productLinkTo,
  } = getClustersData

  const tileDataElementId = getTileDataElementId(tileIndex)
  const productImageWidth = getProductImageWidth(variant)

  const { name, currency, modelCode, lxProductType, colorLabel, annualSupply } =
    ProductTileUtils.getAttributes(clusterSelected)

  const { isFrames, isAccessories, isContactLenses } =
    ProductTileUtils.getLxProductTypes(lxProductType)

  const isMoCoSliderRendered = isFrames || (isAccessories && clusterLength > 1)
  const isFullListingLayout = productsMobileListingLayout === 'full'
  const mobileListingLayoutBasedOnPages =
    pageType === 'search' ? 'compact' : productsMobileListingLayout
  const isMoCoSliderDisplayed =
    isMoCoSliderRendered &&
    (carouselMode || mobileListingLayoutBasedOnPages === 'full')

  const onProductTileMouseEnter = () => {
    if (
      clusterLength > 0 &&
      (mobileListingLayoutBasedOnPages === 'full' || isDesktop)
    )
      setCarouselMode(true)
  }
  const onProductTileMouseLeave = () => setCarouselMode(!carouselMode)
  const onImageClick = () => dispatch(setLastSelectedProduct(product.id || ''))

  const commonGalleryProductImageProps: ProductImageProps = {
    sequence: isAccessories ? '1.0' : '2.0',
    usage: 'PLP',
  }

  const commonProductImageProps = {
    alt: t('ProductTile.Labels.AltPrefix', { name }),
    draggable: false,
    sequence: '1.0',
    usage: 'PLP' as 'PLP',
    width: productImageWidth,
    onClick: onImageClick,
  }

  return (
    product && (
      <StyledProductTileWrapper
        onMouseEnter={onProductTileMouseEnter}
        onMouseLeave={onProductTileMouseLeave}
      >
        <StyledProductSubWrapper
          fullLayout={
            carouselMode || mobileListingLayoutBasedOnPages === 'full'
          }
        >
          <StyledSquare onClick={onClick}>
            <StyledProductAnchor
              aria-label={productPrimaryBadge || `${name}_${modelCode}`}
              to={productLinkTo}
              data-element-id={tileDataElementId}
              data-description={encodeURIComponent(
                `${siteName}_${name}_${modelCode}`
              )}
              isClRelatedTile={!isFrames}
            >
              <ProductTileHeaderOld
                isContactLenses={isContactLenses}
                annualSupply={annualSupply}
                isFullListeningLayout={isFullListingLayout}
                currency={currency}
                primaryBadge={productPrimaryBadge}
                secondaryBadge={productSecondaryBadges}
              />
              {isMoCoSliderRendered && clusters && (
                <ProductTileGalleryOld
                  isDisplayed={isMoCoSliderDisplayed}
                  tileDataElementId={tileDataElementId}
                  commonImageProps={commonGalleryProductImageProps}
                  currentProductIndex={selectedClusterIndex}
                  onIndexChange={setSelectedClusterIndex}
                  cluster={clusters}
                />
              )}
              <StyledProductTileImageContainer
                isFull={isFullListingLayout}
                isDisplayed={!isMoCoSliderDisplayed}
                isClRelatedTile={!isFrames}
              >
                <StyledImageWrapper shouldZoomOnHover={!isFrames}>
                  <ProductImage
                    attachments={clusterSelected.attachments}
                    {...commonProductImageProps}
                  />
                </StyledImageWrapper>
              </StyledProductTileImageContainer>

              {footerElementsDisplay?.colors && (
                <StyledProductVariants isDisplayed={!isMoCoSliderDisplayed}>
                  {clusterLength > 1 ? (
                    `${clusterLength} ${t(
                      'ProductTile.Labels.colors'
                    ).toUpperCase()}`
                  ) : colorLabel ? (
                    colorLabel
                  ) : (
                    <StyledNoProductVariants />
                  )}
                </StyledProductVariants>
              )}
            </StyledProductAnchor>
          </StyledSquare>
        </StyledProductSubWrapper>
        <ProductTileFooterOld
          isPDP={isPDP}
          tileDataElementId={tileDataElementId}
          mobileListingLayoutBasedOnPages={mobileListingLayoutBasedOnPages}
          annualSupplyData={annualSupply}
          clusterSelected={clusterSelected}
          productLinkTo={productLinkTo}
          productPrimaryBadge={productPrimaryBadge}
          isTrendingProduct={isTrendingProduct}
        />
      </StyledProductTileWrapper>
    )
  )
}

export { ProductTileOld }
