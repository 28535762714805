export function loadAsyncScript(
  src: string,
  id: string,
  onLoad?: () => void,
  onError?: (e: unknown) => void
) {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = src
  script.onload = onLoad || null
  script.onerror = onError || null
  document.head.appendChild(script)
}
