import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { cmsApi } from './query'
import { IPlacement } from '../../types/cmsPlacement/Placement'

export interface ICmsState {
  preFooter: IPlacement | null
  menuLoaded: boolean
  menuLoading: boolean
  headerLoading: boolean
  headerLoaded: boolean
  footerLoading: boolean
  footerLoaded: boolean
}
export const initialCmsState: ICmsState = {
  preFooter: null,
  menuLoaded: false,
  menuLoading: false,
  headerLoading: false,
  headerLoaded: false,
  footerLoading: false,
  footerLoaded: false,
}
export const cmsSlice = createSlice({
  name: 'cms',
  initialState: initialCmsState,
  reducers: {
    preFooter: (state: ICmsState, action) => {
      state.preFooter = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(cmsApi.endpoints.getMegaMenu.matchPending, (state) => {
        state.menuLoading = true
      })
      .addMatcher(cmsApi.endpoints.getMegaMenu.matchFulfilled, (state) => {
        state.menuLoaded = true
      })
      .addMatcher(
        isAnyOf(
          cmsApi.endpoints.getMegaMenu.matchFulfilled,
          cmsApi.endpoints.getMegaMenu.matchRejected
        ),
        (state) => {
          state.menuLoading = false
        }
      )
      .addMatcher(cmsApi.endpoints.getHeader.matchPending, (state) => {
        state.headerLoading = true
      })
      .addMatcher(cmsApi.endpoints.getHeader.matchFulfilled, (state) => {
        state.headerLoaded = true
      })
      .addMatcher(
        isAnyOf(
          cmsApi.endpoints.getHeader.matchRejected,
          cmsApi.endpoints.getHeader.matchFulfilled
        ),
        (state) => {
          state.headerLoading = false
        }
      )
      .addMatcher(cmsApi.endpoints.getFooter.matchPending, (state) => {
        state.footerLoading = true
      })
      .addMatcher(cmsApi.endpoints.getFooter.matchFulfilled, (state) => {
        state.footerLoaded = true
      })
      .addMatcher(
        isAnyOf(
          cmsApi.endpoints.getFooter.matchRejected,
          cmsApi.endpoints.getFooter.matchFulfilled
        ),
        (state) => {
          state.footerLoading = false
        }
      )
  },
})

export const { preFooter } = cmsSlice.actions
export default cmsSlice.reducer
