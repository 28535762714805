import { ProductForMonetate } from './lib'
import {
  ADD_BREADCRUMBS,
  ADD_CART_ROWS,
  ADD_CATEGORIES,
  ADD_PRODUCT_DETAILS,
  ADD_PRODUCTS,
  ADD_PURCHASE_ROWS,
  BILLING,
  CATEGORY,
  CUSTOM_PRODUCT,
  PRODUCT,
  PURCHASE,
  SEARCH,
  SET_PAGE_TYPE,
  TRACK_DATA,
} from './pageTypes'
import { OrderItem, OrderItemWithRoxProps } from '../../types/order'
import unionBy from 'lodash/unionBy'

export interface MonetateReTrackProps {
  setPage?: string
  addProducts?: string[]
  addPurchaseRows?: ProductForMonetate[]
  addCartRows?: ProductForMonetate[]
  addProductDetails?: ProductForMonetate
  addCategories?: string[]
  addBreadcrumbs?: string[]
}

class MonetateUtils {
  private monetateQ() {
    return window.monetateQ || []
  }

  setTrackData() {
    const monetateQ = this.monetateQ()
    monetateQ.push([TRACK_DATA])
  }
  setPage(pageType?: string) {
    const mappedPageType = pageType ? this.mapPageType(pageType) : 'unavailable'
    const monetateQ = this.monetateQ()
    monetateQ.push([SET_PAGE_TYPE, mappedPageType])
  }
  addProducts(productsIds?: string[]) {
    const monetateQ = this.monetateQ()
    monetateQ.push([ADD_PRODUCTS, productsIds || ['unavailable']])
  }
  addProductDetails(productDetails?: ProductForMonetate) {
    const monetateQ = this.monetateQ()
    monetateQ.push([ADD_PRODUCT_DETAILS, [productDetails || 'unavailable']])
  }
  addCartRows(cartRows?: ProductForMonetate[]) {
    //cartRows = this.mergeCartRows(cartRows!)
    const monetateQ = this.monetateQ()
    monetateQ.push([ADD_CART_ROWS, cartRows])
  }
  addPurchaseRows(purchaseRows?: ProductForMonetate[]) {
    //purchaseRows = this.mergeCartRows(purchaseRows!)
    const monetateQ = this.monetateQ()
    monetateQ.push([ADD_PURCHASE_ROWS, purchaseRows])
  }
  addCategories(categories?: string[]) {
    const monetateQ = this.monetateQ()
    monetateQ.push([ADD_CATEGORIES, categories || ['unavailable']])
  }
  addBreadcrumbs(breadcrumbs?: string[]) {
    const monetateQ = this.monetateQ()
    monetateQ.push([ADD_BREADCRUMBS, breadcrumbs || ['unavailable']])
  }
  reTrack({
    setPage,
    addProducts,
    addCartRows,
    addProductDetails,
    addPurchaseRows,
    addCategories,
    addBreadcrumbs,
  }: MonetateReTrackProps) {
    this.setPage(setPage)
    this.addProducts(addProducts)
    this.addProductDetails(addProductDetails)
    this.addCartRows(addCartRows)
    this.addPurchaseRows(addPurchaseRows)
    this.addCategories(addCategories)
    this.addBreadcrumbs(addBreadcrumbs)
    this.setTrackData()
  }

  //map methods
  mapPageType(pageType) {
    switch (pageType) {
      case 'HomePage':
      case 'home':
        return 'main'
      case 'pdp':
        return PRODUCT
      case 'plp':
        return CATEGORY
      case 'cart':
        return 'cart'
      case 'spedizione':
        return 'shipping'
      case 'pagamento':
        return BILLING
      case 'order-confirmation':
      case 'checkout':
        return PURCHASE
      case 'RemixProductDisplay':
        return CUSTOM_PRODUCT
      case 'SearchDisplay':
      case 'searchResults':
        return SEARCH
      case 'page404':
        return '404'
      default:
        return 'other_page'
    }
  }
  mergeCartRows(productsInCart: ProductForMonetate[]): ProductForMonetate[] {
    const mergedProducts = unionBy(productsInCart, 'productId').map((item) => {
      const found = productsInCart.filter((temp) => {
        return temp.productId === item.productId
      })?.length
      item.quantity = String(found)
      return item
    })
    return mergedProducts
  }

  sanitizeSku(sku): string {
    if (sku === undefined) {
      return ''
    }
    return sku.replace(/ /g, '_')
  }

  mapProductDetails({
    partNumber,
    productId,
  }: {
    partNumber: string
    productId: string
  }) {
    return {
      sku: this.sanitizeSku(partNumber) || 'unavailable',
      productId: productId || 'unavailable',
    }
  }

  mapCartRows(productsInCart: OrderItem[] | OrderItemWithRoxProps[]) {
    let mappedProducts = productsInCart?.map((item) => {
      return {
        productId: item.partNumber,
        quantity: item.quantity,
        unitPrice: item.orderItemPrice,
        currency: item.currency,
      }
    })
    return this.mergeCartRows(mappedProducts)
  }
  mapPurchaseRows(orderId, cartRows: ProductForMonetate[]) {
    const purchaseRows = cartRows.map((product) => {
      return {
        ...product,
        purchaseId: orderId,
      }
    })
    return this.mergeCartRows(purchaseRows)
  }
}

export default new MonetateUtils()
