import { RootReducerState } from 'src/redux/reducers'
import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'
import { RequestProps } from '../../services/RequestService'
import jsonBig from 'json-bigint'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'

export type EligibleContractResponse = {
  contracts: Record<string, string>
}

export interface PersonalizationIdResponse {
  personalizationID: string
}
export const contextApi = createApi({
  reducerPath: 'contextApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '',
  }),

  endpoints: (build) => ({
    getContext: build.query<
      any,
      {
        queryParams?: RequestProps['queryParams']
      }
    >({
      async queryFn(args, queryApi, _extraOptions, fetchWithBQ) {
        try {
          const {
            site: { currentSite },
          } = queryApi.getState() as RootReducerState
          const { queryParams } = args
          const params = { ...queryParams }
          delete params.WCToken
          delete params.WCTrustedToken
          const result = await fetchWithBQ({
            url: `/store/${currentSite?.storeID}/usercontext/@self/contextdata`,
            method: 'get',
            queryParams: params,
          })
          let userPayload = jsonBig.parse(result?.request.response || '{}')
          userPayload.entitlement.currentTradingAgreementIds[0] =
            userPayload.entitlement.currentTradingAgreementIds[0].toString()
          return {
            data: userPayload,
          }
        } catch (error) {
          return { error: error }
        }
      },
    }),
    getPersonalizationId: build.query<
      any,
      {
        queryParams?: RequestProps['queryParams']
      }
    >({
      async queryFn(args, queryApi, _extraOptions, fetchWithBQ) {
        try {
          const {
            site: { currentSite },
          } = queryApi.getState() as RootReducerState
          const { queryParams } = args
          const params = { ...queryParams }
          delete params.WCToken
          delete params.WCTrustedToken
          const result = await fetchWithBQ({
            url: `/store/${currentSite?.storeID}/usercontext/personalizationId`,
            method: 'get',
            queryParams: params,
          })

          return result
            ? {
                data: JSON.parse(result.request.response),
              }
            : { error: result.error as FetchBaseQueryError }
        } catch (error) {
          return { error: error }
        }
      },
    }),
  }),
})

export const { useGetContextQuery } = contextApi
