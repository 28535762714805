import config from '@configs/index'

import ProductImage, {
  ProductImageProps,
} from '../../../ProductImage/ProductImage'
import React, { useState } from 'react'
import {
  StyledProductAnchor,
  StyledProductBrandName,
  StyledProductName,
  StyledProductSubWrapper,
  CmsProductTileSecondaryBadge,
  StyledProductTileDescription,
  StyledProductTileFooter,
  StyledProductTileHeader,
  StyledProductTileImageContainer,
  CmsProductTilePrimaryBadge,
  StyledProductTileWrapper,
  StyledSquare,
} from './CmsProductTile.style'
import { useTranslation } from 'react-i18next'
import { ServerProduct } from '../../../../types/product'
import { setLastSelectedProduct } from '../../../../features/ui/action'
import { useAppDispatch } from '@hooks/redux'
import { getFormattedPlpProduct } from '../../../../features/plp/utils'
import useProductTileOld from '@components/ProductTile/useProductTileOld'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import ProductPriceAlgoliaUtils from '@utils/ProductAlgolia/ProductPriceAlgoliaUtils'
import ProductPriceFinal from '@components/ProductPriceFinal'
import ProductTileAlgoliaUtils from '@utils/ProductAlgolia/ProductTileAlgoliaUtils'
import { SmartGlassesIcon } from '@components/UI/Icons'

export interface CmsProductTileProps {
  footerElementsDisplay?: {
    colors: boolean
    inWishlistAdded: boolean
  }
  product: ServerProduct
  onClick?: () => void
  tileIndex: number
  variant?: string
  onWishlistButtonClick?: (productId: string) => void
  isPDP?: boolean
  isBoxWMargin?: boolean
  teaserIndex?: number
  backgroundColor?: string
  isLazyLoadEnabled?: boolean
}

const siteName = config.REACT_APP_STORENAME

const CmsProductTile: React.FC<CmsProductTileProps> = ({
  product,
  onClick,
  tileIndex,
  variant,
  isBoxWMargin,
  backgroundColor,
  isLazyLoadEnabled,
  ...rest
}) => {
  const { country } = useStoreIdentity()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [hoverImage, setHoverImage] = useState<ProductImageProps>({
    sequence: '1.0',
    usage: 'PLP',
  })
  const formattedProduct = getFormattedPlpProduct(product)
  const { getTileDataElementId, getProductImageWidth, getClustersData } =
    useProductTileOld(formattedProduct)

  const { clusterSelected, productLinkTo } = getClustersData

  const { modelName, brand, lxSearchPageProductType, displaySku } =
    ProductAlgoliaUtils.getProductAttributes(clusterSelected.productAttributes)

  const { badges } = ProductTileAlgoliaUtils.getProductTileAttributes(
    clusterSelected.productAttributes
  )
  const { primaryBadge, secondaryBadge } = badges

  const { productType } = ProductAlgoliaUtils.getProductAttributes(
    clusterSelected.productAttributes
  )
  const { isElectronics } = ProductAlgoliaUtils.getLxProductTypes(productType) //meta sunglasses

  const tileDataElementId = getTileDataElementId(tileIndex)
  const productImageWidth = getProductImageWidth(variant)

  const onImageClick = () => dispatch(setLastSelectedProduct(product.id || ''))

  const onProductTileMouseEnter = () =>
    setHoverImage({ sequence: '2.0', usage: 'PLP' })

  const onProductTileMouseLeave = () =>
    setHoverImage({ sequence: '1.0', usage: 'PLP' })

  const commonProductImageProps = {
    alt: t('ProductTile.AltPrefix', { name: modelName }),
    draggable: false,
    sequence: '1.0',
    usage: 'PLP' as 'PLP',
    width: productImageWidth,
    onClick: onImageClick,
  }

  return (
    product && (
      <StyledProductTileWrapper
        onMouseEnter={onProductTileMouseEnter}
        onMouseLeave={onProductTileMouseLeave}
        aria-label={primaryBadge || `${modelName}_${displaySku}`}
        data-element-id={tileDataElementId}
        data-description={encodeURIComponent(
          `${siteName}_${modelName}_${displaySku}`
        )}
        {...rest}
      >
        <StyledProductSubWrapper isBoxWMargin={isBoxWMargin}>
          <StyledSquare onClick={onClick}>
            <>
              <StyledProductTileHeader>
                <CmsProductTilePrimaryBadge>
                  {isElectronics ? (
                    <>
                      <SmartGlassesIcon />
                      <>{t('ProductTile.Labels.SmartGlasses')}</>
                    </>
                  ) : (
                    <>
                      {primaryBadge
                        ? t(`ProductTile.Labels.${primaryBadge}`)
                        : '\u00A0'}
                    </>
                  )}
                </CmsProductTilePrimaryBadge>
                <CmsProductTileSecondaryBadge>
                  {secondaryBadge
                    ? t(`ProductTile.Labels.${secondaryBadge}`)
                    : '\u00A0'}
                </CmsProductTileSecondaryBadge>
              </StyledProductTileHeader>
              <>
                <StyledProductTileImageContainer>
                  <StyledProductAnchor
                    aria-label={primaryBadge || `${modelName}_${displaySku}`}
                    to={productLinkTo}
                    data-element-id={tileDataElementId}
                    data-description={encodeURIComponent(
                      `${siteName}_${modelName}_${displaySku}`
                    )}
                  >
                    <ProductImage
                      isLazyLoadEnabled={isLazyLoadEnabled}
                      attachments={clusterSelected.attachments}
                      {...commonProductImageProps}
                      {...hoverImage}
                      backgroundColor={backgroundColor}
                    />
                  </StyledProductAnchor>
                </StyledProductTileImageContainer>
              </>
            </>
            <StyledProductTileFooter>
              <StyledProductTileDescription>
                <StyledProductName>{modelName}</StyledProductName>
                <StyledProductBrandName>
                  {brand || 'Brand name'}
                </StyledProductBrandName>
                {clusterSelected?.x_price && (
                  <ProductPriceFinal
                    pageType={'plp'}
                    productType={lxSearchPageProductType}
                    price={
                      ProductPriceAlgoliaUtils.getDeterminatePrice(
                        clusterSelected?.x_price,
                        country
                      )!
                    }
                  />
                )}
              </StyledProductTileDescription>
            </StyledProductTileFooter>
          </StyledSquare>
        </StyledProductSubWrapper>
      </StyledProductTileWrapper>
    )
  )
}

export default CmsProductTile
