import { styled } from '@mui/material/styles'

export const OurServicesContainer = styled('div', {
  name: 'StoreDetails',
  slot: 'OurServicesContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.color.grey.lightest,
  padding: theme.spacing(8),
}))

export const OurServicesTitle = styled('h1', {
  name: 'StoreDetails',
  slot: 'OurServicesContainer',
})(({ theme }) => ({
  color: theme.palette.text.dark,
  fontSize: 28,
  textAlign: 'center',
}))

export const ServicesContainer = styled('div', {
  name: 'StoreDetails',
  slot: 'ServicesContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    paddingBottom: theme.spacing(4),
  },
}))
