import Drawer from '@mui/material/Drawer'
import { StyledAnchor } from '@components/UI'
import { styled } from '@mui/material/styles'
import { Z_INDEX_LV4 } from '../../../../constants/ui'

export const SearchLayerWrapper = styled(Drawer, {
  name: 'SearchLayer',
  slot: 'Wrapper',
  shouldForwardProp: (prop) => prop !== 'isScrolled',
})<{ isScrolled: boolean | null }>(({ theme, isScrolled }) => ({
  top: isScrolled === null ? theme.spacing(8) : 0,
  zIndex: Z_INDEX_LV4,
  [theme.breakpoints.up('md')]: {
    top: isScrolled === null ? theme.spacing(8) : theme.spacing(2),
  },

  '.MuiPaper-root': {
    position: 'static',
    backgroundColor: 'transparent',
    width: '100vw',
    height: '100%',
    overflowY: 'hidden',
    zIndex: Z_INDEX_LV4,
  },
}))

export const SearchLayerContentWrapper = styled('div', {
  name: 'SearchLayer',
  slot: 'ContentWrapper',
  shouldForwardProp: (prop) => prop !== 'isScrolled',
})<{ isScrolled?: boolean | null }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  backgroundColor: theme.palette.color.white.main,
  height: '100%',

  [theme.breakpoints.up('xl')]: {
    flexDirection: 'row',
    overflowY: 'hidden',
  },
}))

export const SearchLayerSection = styled('div', {
  name: 'SearchLayer',
  slot: 'Section',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  marginRight: theme.spacing(4),
  marginLeft: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    marginRight: theme.spacing(8),
    marginLeft: theme.spacing(8),
  },

  [theme.breakpoints.up('xl')]: {
    marginRight: theme.spacing(16),
    marginLeft: theme.spacing(16),
  },
}))

export const SearchLayerSectionLeft = styled(SearchLayerSection)(
  ({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      paddingRight: 0,
      width: '60%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '50%',
      paddingTop: theme.spacing(6),
    },
  })
)
export const SearchLayerSectionRight = styled(SearchLayerSection)(
  ({ theme }) => ({
    flexGrow: 1,
    borderTop: `1px solid ${theme.palette.color.grey.main}`,
    paddingTop: theme.spacing(6),

    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
    [theme.breakpoints.up('xl')]: {
      borderTop: 'none',
      paddingTop: theme.spacing(6),
      width: '60%',
      overflowY: 'auto',
    },
  })
)

export const SearchInputWrapper = styled('div', {
  name: 'Searchinput',
  slot: 'Wrapper',
})(({ theme }) => ({
  marginTop: theme.spacing(6),
  backgroundColor: theme.palette.color.grey.lightest,
  height: 40,
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  borderRadius: theme.spacing(6),
}))

export const SearchInput = styled('input', {
  name: 'Searchinput',
  slot: 'SearchLayer',
  shouldForwardProp: () => true,
})(({ theme }) => ({
  fontSize: 14,
  backgroundColor: 'transparent',
  color: theme.palette.text.dark,
  paddingLeft: theme.spacing(3),
  border: 'none',
  flex: 1,
  ':focus': {
    outline: 'none',
  },
  '&::-webkit-search-decoration': { display: 'none' },
  '&::-webkit-search-cancel-button': { display: 'none' },
  '&::-webkit-search-results-button': { display: 'none' },
  '&::-webkit-search-results-decoration': { display: 'none' },
}))

export const SearchInputIconWrapper = styled('div', {
  name: 'Searchinput',
  slot: 'IconWrapper',
  shouldForwardProp: () => true,
})<{ type: string; children: any }>(({ type, theme }) => ({
  marginLeft: type === 'left' ? theme.spacing(5) : 0,
  marginRight: type === 'right' ? theme.spacing(5) : 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const SearchInputDisclaimer = styled('div', {
  name: 'Searchinput',
  slot: 'Disclaimer',
})(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: 14,
  color: theme.palette.text.dark,
  paddingTop: theme.spacing(2),
}))

export const SearchInputDisclaimerWrapper = styled('div', {
  name: 'Searchinput',
  slot: 'DisclaimerWrapper',
})(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(2),
  },
}))

export const ViewAllResult = styled(StyledAnchor, {
  name: 'ViewAllResult',
  slot: 'Link',
})(({ theme }) => ({
  fontWeight: 600,
  marginLeft: theme.spacing(2),
}))

export const SearchResultRow = styled('div', {
  name: 'SearchResult',
  slot: 'Row',
})(({ theme }) => ({
  display: 'flex',
  columnGap: 15,
  justifyContent: 'space-between',

  [theme.breakpoints.up('md')]: {
    justifyContent: 'initial',
    marginBottom: theme.spacing(2),
  },
}))

export const NoResult = styled('div', {
  name: 'NoResult',
  slot: 'Container',
})(({ theme }) => ({
  width: '100%',
  height: '100%',
  maxHeight: 506,
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  backgroundColor: theme.palette.color.grey.lightest,
  color: theme.palette.text.dark,

  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(12),
  },
}))

export const NoResultText = styled('div', {
  name: 'NoResult',
  slot: 'Text',
})(({ theme }) => ({
  fontSize: 24,
  display: 'inline-block',
  wordBreak: 'break-word',
  padding: theme.spacing(4),

  span: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },

  [theme.breakpoints.up('sm')]: {
    fontSize: 32,
    padding: theme.spacing(21, 41, 0),
  },
}))

export const LeftSideMenuContainer = styled('div', {
  name: 'LeftSideMenu',
  slot: 'Container',
})(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: theme.spacing(6),
  marginTop: theme.spacing(8.5),
  color: theme.palette.text.dark,
  overflowY: 'auto',

  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },

  [theme.breakpoints.up('xl')]: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(10),
    gap: theme.spacing(10),
  },
}))
export const KeywordsContainer = styled('div', {
  name: 'Keywords',
  slot: 'Container',
})(({ theme }) => ({
  marginTop: theme.spacing(4),
  color: theme.palette.text.dark,
  fontSize: 14,

  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(10),
  },
}))

export const SideMenuContainer = styled('div', {
  name: 'SideMenu',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}))

export const KeywordItem = styled('div', {
  name: 'Keyword',
  slot: 'Item',
})(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  cursor: 'pointer',
  fontSize: 14,
}))

export const KeywordItemLink = styled(StyledAnchor, {
  name: 'Keyword',
  slot: 'ItemLink',
})(({ theme }) => ({
  color: theme.palette.text.dark,
  ':after': {
    height: 0,
  },
}))

export const KeywordMobileContainer = styled('div', {
  name: 'KeywordMobile',
  slot: 'Container',
})(() => ({
  overflowWrap: 'break-word',
  lineHeight: 3,
}))

export const KeywordItemMobile = styled('span', {
  name: 'KeywordI',
  slot: 'ItemMobile',
})(({ theme }) => ({
  paddingRight: theme.spacing(4),
  ':not(:nth-of-type(1))': {
    paddingLeft: theme.spacing(4),
    borderLeft: '2px solid black',
  },
}))

export const SearchLayerHeader = styled('div', {
  name: 'SearchLayer',
  slot: 'Header',
})(({ theme }) => ({
  backgroundColor: 'transparent',
  display: 'flex',
  height: '48px',
  justifyContent: 'flex-end',
  maxHeight: theme.spacing(12),
  padding: theme.spacing(4),
  opacity: '0',
  [theme.breakpoints.up('md')]: {
    height: 64,
    padding: theme.spacing(0, 16),
  },

  button: {
    padding: 0,
  },
}))

export const ClearButton = styled('button', {
  name: 'CloseButton',
})(() => ({
  border: 'none',
  backgroundColor: 'transparent',
}))
