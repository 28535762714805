import React from 'react'
import ButtonUnstyled, { ButtonUnstyledProps } from '@mui/base/ButtonUnstyled'
import { styled } from '@mui/material/styles'

export interface PillProps extends ButtonUnstyledProps {
  labelText?: string
  endIcon?: React.ReactNode
  startIcon?: React.ReactNode
  variant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'filters'
  fillType?: 'fill' | 'outline'
  size?: 'small' | 'medium' | 'big'
}

export const variantMap = (
  theme,
  variant: PillProps['variant'] = 'primary',
  fillType: PillProps['fillType'] = 'fill'
) => {
  const primaryFill = {
    background: theme.palette.primary.main,
    hoverBackground: theme.palette.primary.dark,
    color: theme.palette.text.light,
    colorHover: theme.palette.text.light,
    borderColor: theme.palette.primary.main,
    borderColorhover: theme.palette.primary.dark,
  }
  const secondaryFill = {
    background: theme.palette.secondary.main,
    hoverBackground: theme.palette.secondary.dark,
    color: theme.palette.text.dark,
    colorHover: theme.palette.text.dark,
    borderColor: theme.palette.secondary.main,
    borderColorhover: theme.palette.secondary.dark,
  }
  const tertiaryFill = {
    background: theme.palette.color.black.main,
    hoverBackground: theme.palette.color.grey.dark,
    color: theme.palette.text.light,
    colorHover: theme.palette.text.light,
    borderColor: theme.palette.color.black.main,
    borderColorhover: theme.palette.color.grey.dark,
  }
  const quaternaryFill = {
    background: theme.palette.color.white.main,
    hoverBackground: theme.palette.color.grey.lightest,
    color: theme.palette.text.dark,
    colorHover: theme.palette.text.dark,
    borderColor: theme.palette.color.white.main,
    borderColorhover: theme.palette.color.grey.lightest,
  }
  const filtersFill = {
    background: theme.palette.color.grey.lightest,
    hoverBackground: theme.palette.primary.light,
    color: theme.palette.text.dark,
    colorHover: theme.palette.text.dark,
    borderColor: theme.palette.color.grey.light,
    borderColorhover: theme.palette.color.grey.light,
  }

  const primaryOutline = {
    background: 'transparent',
    hoverBackground: 'rgba(3, 84, 166, 0.1)',
    color: theme.palette.primary.main,
    colorHover: theme.palette.primary.dark,
    borderColor: theme.palette.primary.main,
    borderColorhover: theme.palette.primary.dark,
  }
  const secondaryOutline = {
    background: 'transparent',
    hoverBackground: 'rgba(255, 255, 255, 0.25)',
    color: theme.palette.secondary.main,
    colorHover: theme.palette.secondary.dark,
    borderColor: theme.palette.secondary.main,
    borderColorhover: theme.palette.secondary.dark,
  }
  const tertiaryOutline = {
    background: 'transparent',
    hoverBackground: 'rgba(0, 0, 0, 0.2)',
    color: theme.palette.text.dark,
    colorHover: theme.palette.text.dark,
    borderColor: theme.palette.color.black.main,
    borderColorhover: theme.palette.color.black.main,
  }
  const quaternaryOutline = {
    background: 'transparent',
    hoverBackground: 'rgba(255, 255, 255, 0.25)',
    color: theme.palette.text.light,
    colorHover: theme.palette.color.white.main,
    borderColor: theme.palette.text.light,
    borderColorhover: theme.palette.color.white.main,
  }

  return {
    fill: {
      primary: primaryFill,
      secondary: secondaryFill,
      tertiary: tertiaryFill,
      quaternary: quaternaryFill,
      filters: filtersFill,
    },
    outline: {
      primary: primaryOutline,
      secondary: secondaryOutline,
      tertiary: tertiaryOutline,
      quaternary: quaternaryOutline,
    },
  }[fillType][variant]
}

export const Pill = styled(
  ({ labelText, startIcon, endIcon, ...props }: PillProps) => (
    <ButtonUnstyled {...props}>
      {startIcon}
      {labelText}
      {endIcon}
    </ButtonUnstyled>
  ),
  {
    shouldForwardProp: (prop) => prop !== 'fillType',
  }
)(({ theme, variant, fillType, size = 'medium' }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  alignItems: 'center',
  borderRadius: 100,
  appearance: 'none',
  fontFamily: 'inherit',
  fontWeight: 600,
  lineHeight: 1.6,
  cursor: 'pointer',
  padding: theme.spacing(size === 'big' ? 3 : size === 'medium' ? 2.5 : 2, 4),
  textTransform: 'uppercase',
  height: size === 'big' ? 48 : size === 'medium' ? 40 : 32,
  fontSize: size === 'big' ? 16 : size === 'medium' ? 14 : 10,

  color: variantMap(theme, variant, fillType)?.color || 'inherit',
  background: variantMap(theme, variant, fillType)?.background || 'inherit',
  border: `1px solid${
    variantMap(theme, variant, fillType)?.borderColor || 'inherit'
  }`,

  '&:hover': {
    color: variantMap(theme, variant, fillType)?.colorHover || 'inherit',
    background:
      variantMap(theme, variant, fillType)?.hoverBackground || 'inherit',
    border: `1px solid${
      variantMap(theme, variant, fillType)?.borderColorhover || 'inherit'
    }`,
  },

  '&:disabled': {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  svg: {
    width: '1rem',
    height: '1rem',
  },
}))
