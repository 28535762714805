import React from 'react'
import { styled } from '@mui/material/styles'
import { CartIcon } from '../../../../../UI/Icons'
import { Z_INDEX_LV1 } from '../../../../../../constants/ui'

export const ShoppableIconContainer = styled('div', {
  name: 'Shoppable',
  slot: 'IconContainer',
})(() => ({
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  width: 32,
  height: 32,
  display: 'flex',
  zIndex: Z_INDEX_LV1,
}))

export const ShoppableIcon = styled(CartIcon, {
  name: 'Shoppable',
  slot: 'Icon',
  shouldForwardProp: (prop) => prop !== 'isLightContrast',
})<{ isLightContrast?: boolean }>(({ theme, isLightContrast }) => ({
  width: '1rem',
  position: 'absolute',
  fill: isLightContrast
    ? theme.palette.primary.main
    : theme.palette.color.white.main,
  zIndex: Z_INDEX_LV1,
}))

const CmsShoppableImageIcon: React.FC<{
  onIconHover?: (e: React.MouseEvent<HTMLDivElement>) => void
  onIconOut?: (e: React.MouseEvent<HTMLDivElement>) => void
  visible?: boolean
  isLightContrast?: boolean
}> = ({ onIconHover, onIconOut, isLightContrast }) => {
  return (
    <ShoppableIconContainer
      onMouseEnter={(e) => onIconHover && onIconHover(e)}
      onMouseLeave={(e) => onIconOut && onIconOut(e)}
    >
      <ShoppableIcon isLightContrast={isLightContrast} />
    </ShoppableIconContainer>
  )
}

export default CmsShoppableImageIcon
