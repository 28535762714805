import { styled } from '@mui/material/styles'
import { TextField } from '@components/UI'

export const EmailFormContent = styled('div', {
  name: 'ForgotPassword',
  slot: 'EmailFormContent',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  fontSize: 14,
  color: theme.palette.text.dark,
  [theme.breakpoints.up('sm')]: {
    width: '50%',
    gap: theme.spacing(4),
  },
}))

export const CustomTextField = styled(TextField, {
  name: 'ForgotPassword',
  slot: 'CustomTextField',
})(() => ({
  '& .MuiFormHelperText-root.Mui-error': {
    position: 'relative',
    top: 0,
  },
}))

export const TextFieldWrapper = styled('div', {
  name: 'ForgotPassword',
  slot: 'TextFieldWrapper',
})(({ theme }) => ({
  marginTop: theme.spacing(2.5),
}))

export const ButtonSubmitContent = styled('div', {
  name: 'ForgotPassword',
  slot: 'ButtonSubmitContent',
})(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2.25),

  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(14),
  },
}))

export const WarningIconWrapper = styled('div', {
  name: 'ForgotPassword',
  slot: 'IconWrapper',
})(() => ({
  width: 16,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))
