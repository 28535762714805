import { RootReducerState } from 'src/redux/reducers'

import { createApi } from '@reduxjs/toolkit/query/react'
import { UserSegmentsState } from './slice'
import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import { RequestProps } from '../../services/RequestService'

export type UserSegmentsResponse = {
  customerSegments: string[]
}
export type UserSegmentsApiState = Partial<UserSegmentsState>
export const userSegmentsApi = createApi({
  reducerPath: 'userSegmentsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '',
  }),
  endpoints: (build) => ({
    getCustomerSegments: build.query<UserSegmentsResponse, void>({
      async queryFn(_args, queryApi, _extraOptions, fetchWithBQ) {
        try {
          const {
            site: { currentSite },
          } = queryApi.getState() as RootReducerState
          const result = await fetchWithBQ({
            url: `/store/${currentSite?.storeID}/customersegments`,
            method: 'get',
          })
          return {
            data: result.data,
          }
        } catch (error) {
          return { error: error }
        }
      },
      forceRefetch: () => true,
    }),
    getWebActivity: build.query<
      any,
      { espotName: string; queryParams?: RequestProps['queryParams'] }
    >({
      async queryFn(args, queryApi, _extraOptions, fetchWithBQ) {
        try {
          const {
            site: { currentSite },
          } = queryApi.getState() as RootReducerState
          const { espotName } = args
          const result = await fetchWithBQ({
            url: `/store/${currentSite?.storeID}/espot/${espotName}`,
            method: 'get',
          })
          return {
            data: result,
          }
        } catch (error) {
          return { error: error }
        }
      },
    }),
  }),
})

export const {
  useGetCustomerSegmentsQuery,
  useLazyGetCustomerSegmentsQuery,
  useLazyGetWebActivityQuery,
  useGetWebActivityQuery,
} = userSegmentsApi
