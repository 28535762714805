import Axios, { Canceler } from 'axios'
//Standard libraries
import React, { ChangeEvent, useEffect } from 'react'
//UI
import { EMPTY_STRING } from '../../constants/common'
//Custom libraries
import addressUtil from '../../utils/addressUtil'
import getDisplayName from 'react-display-name'
import personService from '../../foundation/apis/transaction/person.service'
import { useDispatch } from 'react-redux'
//Foundation libraries
import { useSite } from '../../foundation/hooks/useSite'
import { useTranslation } from 'react-i18next'

import { useLocation } from 'react-router-dom'
import { sendSuccessMessage } from '../../features/success/slice'
import { ModalDialogTitle } from '../UI'
import { WrapperForgotPassword } from './ForgotPassword.style'
import { ResetPassword } from '../ResetPassword'
import ForgotPasswordEmail from './components/ForgotPasswordEmail'
import ForgotPasswordTemporary from './components/ForgotPasswordTemporary'

const ForgotPasswordLayout: React.FC<{
  handleClose: () => void
  openStepResetPassword: boolean
}> = ({ openStepResetPassword }) => {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const urlValidationCode = queryParams.get('validation')
  const urlEmail = queryParams.get('email')

  const widgetName = getDisplayName(ForgotPasswordLayout)
  const [email, setEmail] = React.useState<string>(urlEmail ?? EMPTY_STRING)
  const [isEmailError, setIsEmailError] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isError, setIsError] = React.useState<boolean>(false)
  const [forgotPasswordState, setForgotPasswordState] =
    React.useState<boolean>(true)
  const [resetPasswordState, setResetPasswordState] =
    React.useState<boolean>(false)
  const dispatch = useDispatch()
  const { mySite: site } = useSite()
  const { t } = useTranslation()

  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const handleEmail = (evt: ChangeEvent<HTMLInputElement>) => {
    if (isError) setIsError(false)
    if (isEmailError) setIsEmailError(false)
    setEmail(evt.target.value)
  }

  const handleForgotPasswordState = () => setForgotPasswordState(false)
  const handleResetPasswordState = () => {
    setForgotPasswordState(false)
    setResetPasswordState(true)
  }

  // const canContinue = () => email !== EMPTY_STRING

  const handleSubmit = () => {
    if (!addressUtil.validateEmail(email)) {
      setIsEmailError(true)
      return
    }
    setIsLoading(true)
    const storeID = site.storeID
    const parameters = {
      responseFormat: 'application/json',
      storeId: storeID,
      body: {
        logonId: email,
        resetPassword: 'true',
        challengeAnswer: '-',
      },
      ...payloadBase,
    }
    personService
      .updatePerson(parameters)
      .then(() => {
        handleForgotPasswordState()

        dispatch(
          sendSuccessMessage({
            key: 'success-message.RESEND_VERIFICATION_CODE',
          })
        )
      })
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    if (urlValidationCode && urlEmail) handleForgotPasswordState()
  }, [])

  useEffect(() => {
    openStepResetPassword && handleResetPasswordState()
  }, [openStepResetPassword])

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel())
    }
  }, [])

  return (
    <>
      {!resetPasswordState ? (
        forgotPasswordState ? (
          <>
            <ModalDialogTitle>{t('ForgotPassword.Title')}</ModalDialogTitle>
            <WrapperForgotPassword>
              <ForgotPasswordEmail
                email={email}
                isError={isError}
                isEmailError={isEmailError}
                isLoading={isLoading}
                handleEmail={handleEmail}
                handleSubmit={handleSubmit}
              />
            </WrapperForgotPassword>
          </>
        ) : (
          <>
            <ModalDialogTitle divider={false}>
              {t('ForgotPassword.TemporaryPasswordTitle')}
            </ModalDialogTitle>
            <WrapperForgotPassword>
              <ForgotPasswordTemporary
                email={email}
                isLoading={isLoading}
                setResetPasswordState={setResetPasswordState}
                handleSubmit={handleSubmit}
              />
            </WrapperForgotPassword>
          </>
        )
      ) : (
        <>
          <ModalDialogTitle>
            {t('ForgotPassword.ResetPasswordButton')}
          </ModalDialogTitle>
          <WrapperForgotPassword>
            <ResetPassword
              email={email}
              urlValidationCode={urlValidationCode ?? ''}
            />
          </WrapperForgotPassword>
        </>
      )}
    </>
  )
}

export { ForgotPasswordLayout }
