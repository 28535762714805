import { styled } from '@mui/material/styles'

export const StyledPlacementsWrapper = styled('div', {
  name: 'Placements',
  slot: 'Wrapper',
})(() => ({
  position: 'relative',
}))
export const StyledPlacementsLoaderWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}))
