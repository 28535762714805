import { styled } from '@mui/material/styles'

export const SingleServiceContainer = styled('div', {
  name: 'StoreDetails',
  slot: 'SingleServiceContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(8),
  color: theme.palette.text.dark,

  [theme.breakpoints.up('sm')]: {
    marginBottom: 0,
  },
}))

export const SingleServiceIconWrapper = styled('div', {
  name: 'StoreDetails',
  slot: 'IconWrapper',
})(({ theme }) => ({
  svg: {
    width: 48,
    htmlColor: theme.palette.text.dark,
  },
}))

export const SingleServiceName = styled('h3', {
  name: 'StoreDetails',
  slot: 'ServiceName',
})(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  textAlign: 'center',
  margin: theme.spacing(2, 0),
}))

export const SingleServiceDescription = styled('span', {
  name: 'StoreDetails',
  slot: 'ServiceDescription',
})(() => ({
  fontSize: 12,
  fontWeight: 300,
  textAlign: 'center',
}))
