import queryString from 'query-string'
import config from '@configs/config.base'

import { SiteInfo } from '../redux/reducers'
import { Attachment } from '../types/product'

export const sortImageByConf = (
  images: Attachment[],
  sortArray: number[] | undefined
) => {
  if (!Array.isArray(sortArray)) return images
  return images.sort((a, b) => {
    return (
      sortArray.indexOf(parseInt(a.sequence)) -
      sortArray.indexOf(parseInt(b.sequence))
    )
  })
}

export const parseAttachmentUrl = (
  site: SiteInfo,
  attachments: Attachment[]
) => {
  const damDomain: string = site.xStoreCfg
    ? site.xStoreCfg['damDomain'] || config.defaultDamDomain
    : config.defaultDamDomain

  const attachment = attachments[0]

  const url404 = config.PUBLIC_URL + '/images/common/404.svg'
  const imagePath = attachment
    ? damDomain + attachment.attachmentAssetPathRaw
    : url404

  const imageUrl = queryString.stringifyUrl({
    url: imagePath.toString(),
  })

  return imageUrl
}
