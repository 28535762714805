class CurrencyPriceUtils {
  static SYMBOLS = {
    EUR: '€',
    GBP: '£',
    USD: '$',
    CAD: '$',
    AUD: '$',
    NZD: '$',
  } as const

  getSymbolByName(currencyName: string): string {
    return CurrencyPriceUtils.SYMBOLS[currencyName] || ''
  }
}

export default new CurrencyPriceUtils()
