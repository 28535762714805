import React from 'react'
import {
  StyledSuggestedProductTileHeader,
  SuggestedProductTilePrimaryBadge,
  SuggestedProductTileSecondaryBadge,
} from '@components/SuggestedProducts/components/SuggestedProductTile/SuggestedProductTile.style'
import { useTranslation } from 'react-i18next'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import { Product } from '../../../../../../types/product'
import ProductTileUtils from '@utils/ProductAlgolia/ProductTileAlgoliaUtils'
import { SmartGlassesIcon } from '@components/UI/Icons'

export interface ProductTileHeaderProps {
  clusterSelected: Product
}

const SuggestedProductTileHeader: React.FC<ProductTileHeaderProps> = ({
  clusterSelected,
}) => {
  const { t } = useTranslation()
  const attributes = clusterSelected.productAttributes

  const { badges } = ProductTileUtils.getProductTileAttributes(attributes)
  const { primaryBadge, secondaryBadge } = badges

  const { productType } = ProductAlgoliaUtils.getProductAttributes(attributes)
  const { isElectronics } = ProductAlgoliaUtils.getLxProductTypes(productType) //meta sunglasses

  return (
    <StyledSuggestedProductTileHeader>
      <SuggestedProductTilePrimaryBadge>
        {isElectronics ? (
          <>
            <SmartGlassesIcon />
            <>{t('ProductTile.Labels.SmartGlasses')}</>
          </>
        ) : (
          <>
            {primaryBadge ? t(`ProductTile.Labels.${primaryBadge}`) : '\u00A0'}
          </>
        )}
      </SuggestedProductTilePrimaryBadge>
      <SuggestedProductTileSecondaryBadge>
        {secondaryBadge ? t(`ProductTile.Labels.${secondaryBadge}`) : '\u00A0'}
      </SuggestedProductTileSecondaryBadge>
    </StyledSuggestedProductTileHeader>
  )
}

export default SuggestedProductTileHeader
