import { createSlice } from '@reduxjs/toolkit'
import omit from 'lodash/omit'
import { orderApi } from '../order/query'
import { defaultStates } from 'src/redux/reducers/initStates'
import { shipToStoreUtils } from '@utils/shipToStoreUtils'

const physicalStoreSlice = createSlice({
  name: 'physicalStore',
  initialState: defaultStates.physicalStores,
  reducers: {
    setPhysicalStore: (state, action) => {
      return { ...state, ...action.payload }
    },
    removePhysicalStore: (state) => {
      return {
        ...state,
        ...omit(defaultStates.physicalStores, 'enabledSearch'),
      }
    },
    setEnabledSearch: (state, action) => {
      return { ...state, enabledSearch: action.payload }
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      orderApi.endpoints.getCart.matchFulfilled,
      (state, action) => {
        const orderItem = action.payload.cart.orderItem[0]
        if (!orderItem || !orderItem.physicalStoreId) {
          return
        }

        Object.assign(
          state,
          shipToStoreUtils.getPhysicalStoreSelectedInfo(orderItem)
        )
      }
    )
  },
})

export const { setPhysicalStore, setEnabledSearch, removePhysicalStore } =
  physicalStoreSlice.actions
export default physicalStoreSlice.reducer
