import { useRef, useState } from 'react'

/**
 * useConfirm hook to use whhen a confirmation dialog before any action
 * @returns handleCancel: `() => void` handler in case of cancel click
 * @returns handleOk: `() => void` handler in case of confirmation click
 * @returns handleShow: `() => void` handler in case of modal toggle click
 * @returns showConfirmationModal: `boolean` value to show or hide modal
 */
const useConfirm = (): {
  handleCancel: () => void
  handleOk: () => void
  handleShow: () => Promise<boolean>
  showConfirmationModal: boolean
} => {
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false)
  const resolver = useRef<Function>()

  const handleShow = (): Promise<boolean> => {
    setShowConfirmationModal(true)
    return new Promise(function (resolve) {
      resolver.current = resolve
    })
  }

  const handleOk = () => {
    resolver.current && resolver.current(true)
    setShowConfirmationModal(false)
  }

  const handleCancel = () => {
    resolver.current && resolver.current(false)
    setShowConfirmationModal(false)
  }

  return { handleCancel, handleOk, handleShow, showConfirmationModal }
}

export default useConfirm
