import { ServerProduct } from '../../types/product'
import {
  IHotzone,
  IHotzoneSettings,
  ITeaserCallToAction,
  ILXTeaser,
  ITeaserOverlayStyle,
  ITeaserOverlaySettings,
  ITeaserOverlayTextAlign,
} from '../../types/cmsPlacement/LXTeaser'
import { replaceTextMediaCmsUrl, teaserPropsByView } from '@utils/placements'
import useMediaByDeviceType from '../useMediaByDeviceType'
import { IPlacement, isVideoMedia } from '../../types/cmsPlacement/Placement'
import { IMedia } from '../../types/cmsPlacement/Media'
import { ICMProductTeaser } from '../../types/cmsPlacement/CMProductTeaser'
import { ICMExternalProduct } from '../../types/cmsPlacement/CMExternalProduct'
import { ICMCollection } from '../../types/cmsPlacement/CMCollection'
import { IViewType } from '../../types/cmsPlacement/ViewType'

const useCmsTeaserBanner = ({
  item,
  placement,
  viewType,
}: {
  item?: ILXTeaser
  placement?: IPlacement | ICMCollection
  viewType?: IViewType
}): {
  teaserText: string
  teaserTitle: string
  teaserTitleValue: string
  teaserTextValue: string
  teaserOverlaySettingsValue: ITeaserOverlaySettings | undefined
  teaserOverlayTextAlignValue: ITeaserOverlayTextAlign | undefined
  teaserOverlayStyleValue: ITeaserOverlayStyle | undefined
  promoteToH1: boolean
  teaserLXCallToActionSettings: ITeaserCallToAction[]
  teaserPreTitle: string
  teaserIcon: string
  teaserCountdownStart: string
  teaserCountdownUntil: string
  teaserHideExpiredCountdown: boolean
  placementHotZones: [IHotzone] | undefined
  placementHotZonesSettings: IHotzoneSettings | undefined
  isShoppableImage: boolean
  toLink: string
  filterRelatedProduct: ICMExternalProduct | ICMProductTeaser | undefined
  productData: ServerProduct | null
  itemHasTermsAndCondition: boolean | undefined | false
  isVideo?: boolean
  isFrameAdvisor?: boolean | undefined | false
  teaserBackground: string
  media: IMedia | undefined
} => {
  const {
    teaserTitle,
    teaserText,
    teaserOverlaySettings,
    teaserOverlayTextAlign,
    teaserOverlayStyle,
  } = teaserPropsByView(viewType || 'default')

  const {
    promoteToH1 = false,
    teaserLXCallToActionSettings = [],
    teaserPreTitle = '',
    teaserIcon = '',
    teaserCountdownStart = '',
    teaserCountdownUntil = '',
    teaserHideExpiredCountdown = false,
  } = item || {}

  const teaserTitleValue = item?.[teaserTitle] || ''
  const teaserTextValue = replaceTextMediaCmsUrl(item?.[teaserText] || '')
  const teaserOverlaySettingsValue = item?.[teaserOverlaySettings]
  const teaserOverlayTextAlignValue = item?.[teaserOverlayTextAlign]
  const teaserOverlayStyleValue = item?.[teaserOverlayStyle]
  const areItemsInPlacement = placement && 'items' in placement
  const teaserCms = areItemsInPlacement
    ? placement?.items[0]
    : placement?.teasableItems[0]
  const teasersHotZones = item?.hotZones
  const placementHotZones = teasersHotZones
    ? teasersHotZones
    : (teaserCms as ILXTeaser)?.hotZones
  const teasersHotZonesSettings = item?.hotZonesSettings
  const placementHotZonesSettings = teasersHotZonesSettings
    ? teasersHotZonesSettings
    : (teaserCms as ILXTeaser)?.hotZonesSettings

  const teaserBackground = item?.teaserBackground || ''
  const isShoppableImage = teasersHotZones
    ? (teasersHotZones?.length && teasersHotZones?.length >= 0) || false
    : (placementHotZones?.length && placementHotZones?.length >= 0) || false

  const toLink = `${
    item?.teaserLXCallToActionSettings?.[0]?.target.formattedUrl ?? ''
  }`
  const isVideo = isVideoMedia(item?.media?.[0])
  const media = useMediaByDeviceType(item?.media!)

  const filterRelatedProduct = (item as ILXTeaser)?.relatedProduct?.find(
    (relatedProduct) =>
      ['CMProductTeaser', 'CMExternalProduct'].includes(relatedProduct.type)
  )
  const productData = filterRelatedProduct?.productData || null
  const itemHasTermsAndCondition = !!(item as ILXTeaser)
    ?.targetsTermsAndConditions?.target

  const isFrameAdvisor =
    item?.teaserLXCallToActionSettings?.[0]?.target?.type === 'Action' &&
    item?.teaserLXCallToActionSettings?.[0]?.target?.idAction ===
      'frame-advisor'

  return {
    teaserText,
    teaserTitle,
    teaserTitleValue,
    teaserTextValue,
    teaserOverlaySettingsValue,
    teaserOverlayTextAlignValue,
    teaserOverlayStyleValue,
    promoteToH1,
    teaserLXCallToActionSettings,
    teaserPreTitle,
    teaserIcon,
    teaserCountdownStart,
    teaserCountdownUntil,
    teaserHideExpiredCountdown,
    teaserBackground,
    placementHotZones,
    placementHotZonesSettings,
    isShoppableImage,
    toLink,
    filterRelatedProduct,
    productData,
    itemHasTermsAndCondition,
    isVideo,
    isFrameAdvisor,
    media,
  }
}

export default useCmsTeaserBanner
