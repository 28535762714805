import React from 'react'
import { useCountdown } from '@hooks/useCountdown'
import {
  CountdownContainer,
  StyledCountdownContainer,
} from './CmsCountdown.style'
import { TIME_SEPARATOR } from '../../../../constants/ui'
import { useSearchParams } from 'react-router-dom'

export const CmsCountdown: React.FC<{
  textAlign?: React.CSSProperties['textAlign']
  teaserCountdownStart: string
  teaserCountdownUntil: string
  teaserHideExpiredCountdown: boolean
}> = ({
  textAlign,
  teaserCountdownStart,
  teaserCountdownUntil,
  teaserHideExpiredCountdown,
  ...rest
}) => {
  const [searchParams] = useSearchParams()
  const previewDate = searchParams.get('previewDate')

  const countdownStart = previewDate
    ? new Date(previewDate).getTime()
    : new Date().getTime()

  const countdown = useCountdown({
    countdownEnd: +teaserCountdownUntil * 1000,
    countdownStart,
  })

  const { hours, minutes, seconds, isExpired } = countdown
  const notStarted =
    teaserCountdownStart && +teaserCountdownStart * 1000 > countdownStart
  const isCountdownHidden =
    teaserHideExpiredCountdown && (isExpired || notStarted)

  if (isCountdownHidden) return null

  return (
    <CountdownContainer textAlign={textAlign} {...rest}>
      <StyledCountdownContainer>
        {hours}
        {TIME_SEPARATOR}
        {minutes}
        {TIME_SEPARATOR}
        {seconds}
      </StyledCountdownContainer>
    </CountdownContainer>
  )
}
