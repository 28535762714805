import React from 'react'
import { SwiperSlide } from 'swiper/react'
import {
  BoardWithFieldsCarouselContainer,
  BoardWithFieldsItem,
  BoardWithFieldsContainer,
  BoardWithFieldsSlider,
} from './BoardWithFieldsBanner.style'
import BoardWithFieldsMediaOverlap from './components/BoardWithFieldsMediaOverlap'
import { BoardWithFieldsText } from './components/BoardWithFieldsText'
import useBreakpoints from '../../../../../hooks/useBreakpoints'
import { Pagination, Scrollbar } from 'swiper'
import { SwiperProps } from 'swiper/react/swiper-react.js'
import {
  IPlacement,
  isLXTeaser,
} from '../../../../../types/cmsPlacement/Placement'
import { ICMCollection } from '../../../../../types/cmsPlacement/CMCollection'

interface IBoardWithFieldsProps {
  placement: IPlacement | ICMCollection
  placementCenter?: boolean
  teaserIndex?: number
}
const BoardWithFieldsModule: React.FC<IBoardWithFieldsProps> = ({
  placement,
  placementCenter,
  teaserIndex,
}) => {
  const { isDesktop, isMobile } = useBreakpoints()
  const viewType = placement?.viewtype || 'default'
  const carouselTeasersCount = !isDesktop ? 3 : 4

  const teasers =
    'items' in placement
      ? placement?.items.filter(isLXTeaser)
      : placement?.teasableItems.filter(isLXTeaser)

  const countItems = !!teasers && teasers?.length > 4 ? 4 : teasers?.length
  const crop = `BOARD_WITH_FIELDS_${countItems}_ITEMS`
  const isWithCarousel = !!teasers
    ? teasers?.length >= carouselTeasersCount
    : false

  const sliderProps: SwiperProps = {
    spaceBetween: 8,
    slidesPerView: 'auto',
    centeredSlides: isMobile,
    roundLengths: true,
    modules: [Pagination, Scrollbar],
    /* pagination: {
      modifierClass: 'custom-',
      progressbarFillClass: 'custom-progressbar-fill',
      type: 'fraction',
    },*/
    scrollbar: {
      hide: false,
    },
  }

  return (
    <>
      {isWithCarousel ? (
        <BoardWithFieldsCarouselContainer>
          <BoardWithFieldsSlider {...sliderProps}>
            {teasers?.map((teaser, index) => (
              <SwiperSlide key={`slide__${index}`}>
                <BoardWithFieldsItem>
                  <BoardWithFieldsMediaOverlap
                    crop={crop}
                    teaser={teaser}
                    viewType={viewType}
                    teaserIndex={teaserIndex}
                    bannerIndex={index + 1}
                    countItems={countItems!}
                  />
                  <BoardWithFieldsText
                    teaserIndex={teaserIndex}
                    bannerIndex={index + 1}
                    item={teaser}
                    viewType={viewType}
                    placementCenter={placementCenter}
                  />
                </BoardWithFieldsItem>
              </SwiperSlide>
            ))}
          </BoardWithFieldsSlider>
        </BoardWithFieldsCarouselContainer>
      ) : (
        <BoardWithFieldsContainer>
          {teasers?.map((teaser, index) => (
            <BoardWithFieldsItem key={`board__${index}`}>
              <BoardWithFieldsMediaOverlap
                crop={crop}
                teaser={teaser}
                viewType={viewType}
                teaserIndex={teaserIndex}
                bannerIndex={index + 1}
                countItems={countItems!}
              />
              <BoardWithFieldsText
                teaserIndex={teaserIndex}
                bannerIndex={index + 1}
                item={teaser}
                viewType={viewType}
                placementCenter={placementCenter}
              />
            </BoardWithFieldsItem>
          ))}
        </BoardWithFieldsContainer>
      )}
    </>
  )
}

export default BoardWithFieldsModule
