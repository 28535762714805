//redux
import * as userAction from '../../redux/actions/user'
import Axios, { Canceler } from 'axios'
//Standard libraries
import React, { ChangeEvent, useEffect, useState } from 'react'
import { EMPTY_STRING } from '../../constants/common'
import Log from '../../services/Log'
//UI
import { Button, IconButton, NewPassword, TextField } from '../UI'
// Custom libraries
import getDisplayName from 'react-display-name'
import personService from '../../foundation/apis/transaction/person.service'
import { useDispatch, useSelector } from 'react-redux'

//Foundation libraries
import { useSite } from '../../foundation/hooks/useSite'
import { useTranslation } from 'react-i18next'
import {
  PasswordHideIcon,
  WarningIcon,
  PasswordShowVisibleIcon,
} from '../UI/Icons'

import { styled, useTheme } from '@mui/material/styles'
import { genericErrorSelector } from '../../redux/selectors/error'
import {
  INVALID_PARAMETER_ERROR,
  INVALID_CMD_PARAMETER_ERROR,
  INVALID_NEW_PASSWORD,
} from '../../foundation/constants/csr'
// import { RequirementCheck } from '../StyledUI/Icons/Icons'

export const StyledInputField = styled(TextField, {
  name: 'ForgotPassword',
  slot: 'StyledInputField',
})(() => ({
  width: '100%',
  '.MuiFormHelperText-root.Mui-error': {
    position: 'initial',
  },
}))

export const ResetPasswordContent = styled('div', {
  name: 'ForgotPassword',
  slot: 'ResetPasswordContent',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(11),
  [theme.breakpoints.up('sm')]: {
    width: '50%',
  },
}))

export const ResetPasswordMessage = styled('div', {
  name: 'ForgotPassword',
  slot: 'ResetPasswordMessage',
})(() => ({
  fontSize: 14,
}))

export const WarningIconWrapper = styled('div', {
  name: 'ForgotPassword',
  slot: 'IconWrapper',
})(() => ({
  width: 16,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

interface IProps {
  urlValidationCode: string
  email: string
}
const ResetPassword: React.FC<IProps> = ({ email, urlValidationCode }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const widgetName = getDisplayName(ResetPassword)

  const { mySite: site } = useSite()

  const [validationCode, setValidationCode] =
    useState<string>(urlValidationCode)
  const [newPassword, setNewPassword] = useState<string>(EMPTY_STRING)

  const [passwordVerify, setPasswordVerify] = useState<string>(EMPTY_STRING)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showPassword, setShowPassword] = React.useState<boolean>(false)
  const [showTemporaryPassword, setShowTemporaryPassword] =
    React.useState<boolean>(false)
  const [newPasswordInputType, setNewPasswordInputType] =
    React.useState<string>('password')
  const [temporaryPasswordInputType, setTemporaryPasswordInputType] =
    React.useState<string>('password')
  const [errorVerifyPassword, setErrorVerifyPassword] =
    React.useState<string>(EMPTY_STRING)
  const [errorValidationCode, setErrorValidationCode] =
    React.useState<boolean>(false)
  const [errorNewPassword, setErrorNewPassword] = React.useState<boolean>(false)
  const error: any = useSelector(genericErrorSelector)

  // const validationCodeLabel = t('ResetPassword.ValidationCodeLabel')
  const newPasswordLabel = t('ResetPassword.NewPasswordLabel')
  const verifyPasswordLabel = t('ResetPassword.VerifyPasswordLabel')
  const submitButton = t('ResetPassword.SubmitButton')
  // const helperPassword = t('ResetPassword.HelperPasswordMsg')
  const emailLabel = t('ForgotPassword.EmailLabel')
  const temporaryPasswordLabel = t('ForgotPassword.TemporaryPassword')

  const invalidVerifyPassword = t('ResetPassword.Msgs.InvalidVerifyPassword')
  const emptyVerifyPassword = t('ResetPassword.Msgs.Empty.verifyPassword')
  const emptyValidationCode = t('ResetPassword.Msgs.Empty.validationCode')

  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const handleValidationCodeChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (evt.target.value !== EMPTY_STRING) {
      setValidationCode(evt.target.value)
      setErrorValidationCode(false)
    } else {
      setValidationCode(evt.target.value)
      setErrorValidationCode(true)
    }
  }

  const handleNewPasswordChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (evt.target.value === EMPTY_STRING) {
      setNewPassword(evt.target.value)
    } else {
      setNewPassword(evt.target.value)
      setErrorNewPassword(true)
    }
  }

  const handleVerifyPasswordChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (evt.target.value !== EMPTY_STRING) {
      if (newPassword !== evt.target.value) {
        setPasswordVerify(evt.target.value)
        setErrorVerifyPassword(invalidVerifyPassword)
      } else {
        setPasswordVerify(evt.target.value)
        setErrorVerifyPassword(EMPTY_STRING)
      }
    } else {
      setPasswordVerify(evt.target.value)
      setErrorVerifyPassword(emptyVerifyPassword)
    }
  }

  const handleSubmit = async () => {
    if (
      validationCode !== EMPTY_STRING &&
      newPassword !== EMPTY_STRING &&
      passwordVerify !== EMPTY_STRING
    ) {
      setIsLoading(true)
      const storeID = site.storeID
      const parameters: any = {
        responseFormat: 'application/json',
        storeId: storeID,
        body: {
          logonId: email,
          resetPassword: 'true',
          xcred_validationCode: validationCode,
          logonPassword: newPassword,
          xcred_logonPasswordVerify: passwordVerify,
        },
        ...payloadBase,
      }
      const rest = await personService
        .updatePerson(parameters)
        .catch((err) => {
          Log.error('ERROR IN UPDATING PASSWORD', err)
        })
        .finally(() => {
          setIsLoading(false)
        })
      if (rest?.userId) {
        dispatch(
          userAction.LOGIN_REQUESTED_ACTION({
            body: {
              logonId: email,
              logonPassword: newPassword,
            },
            ...payloadBase,
          })
        )
      }
    } else {
      setErrorVerifyPassword(emptyVerifyPassword)
      setErrorValidationCode(true)
      setErrorNewPassword(true)
    }
  }

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel())
    }
  }, [])

  useEffect(() => {
    setNewPasswordInputType(showPassword ? 'text' : 'password')
  }, [showPassword])
  useEffect(() => {
    setTemporaryPasswordInputType(showTemporaryPassword ? 'text' : 'password')
  }, [showTemporaryPassword])
  useEffect(() => {
    setErrorNewPassword(false)
  }, [])
  return (
    <ResetPasswordContent>
      <ResetPasswordMessage>
        {t('ResetPassword.ResetPasswordMsg')}
      </ResetPasswordMessage>
      <StyledInputField
        variant="outlined"
        name="email"
        value={email}
        label={emailLabel}
        onChange={() => {}}
        className={'forgot-password-input'}
      />
      <StyledInputField
        required={true}
        error={
          errorValidationCode ||
          error.errorCode === INVALID_PARAMETER_ERROR ||
          error.errorKey === INVALID_CMD_PARAMETER_ERROR
        }
        className={'forgot-password-input'}
        variant="outlined"
        type={temporaryPasswordInputType}
        name="validationCode"
        value={validationCode}
        label={temporaryPasswordLabel}
        onChange={handleValidationCodeChange}
        helperText={
          errorValidationCode
            ? emptyValidationCode
            : (error.errorCode === INVALID_PARAMETER_ERROR ||
                error.errorKey === INVALID_CMD_PARAMETER_ERROR) &&
              t('ResetPassword.Msgs.InvalidVerifyPassword')
        }
        customInputProps={{
          endAdornment: (
            <>
              {errorValidationCode && (
                <WarningIconWrapper>
                  <WarningIcon htmlColor={theme.palette.error.main} />
                </WarningIconWrapper>
              )}
              <IconButton
                onClick={() => setShowTemporaryPassword(!showTemporaryPassword)}
              >
                {showTemporaryPassword ? (
                  <PasswordShowVisibleIcon
                    htmlColor={theme.palette.primary.main}
                  />
                ) : (
                  <PasswordHideIcon htmlColor={theme.palette.primary.main} />
                )}
              </IconButton>
            </>
          ),
        }}
      />
      <NewPassword
        name="newPassword"
        value={newPassword}
        label={newPasswordLabel}
        isPristine={!newPassword.length}
        onChange={handleNewPasswordChange}
        error={errorNewPassword}
      />
      <StyledInputField
        style={{ paddingBottom: '10px' }}
        required={true}
        className={'forgot-password-input'}
        variant="outlined"
        name="verifyPassword"
        type={newPasswordInputType}
        value={passwordVerify}
        label={verifyPasswordLabel}
        onChange={handleVerifyPasswordChange}
        helperText={
          errorVerifyPassword
            ? errorVerifyPassword
            : error.errorKey === INVALID_NEW_PASSWORD && error.errorMessage
        }
        error={
          errorVerifyPassword !== EMPTY_STRING ||
          error.errorKey === INVALID_NEW_PASSWORD
        }
        customInputProps={{
          endAdornment: (
            <>
              {errorVerifyPassword && (
                <WarningIconWrapper>
                  <WarningIcon htmlColor={theme.palette.error.main} />
                </WarningIconWrapper>
              )}
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? (
                  <PasswordShowVisibleIcon
                    htmlColor={theme.palette.primary.main}
                  />
                ) : (
                  <PasswordHideIcon htmlColor={theme.palette.primary.main} />
                )}
              </IconButton>
            </>
          ),
        }}
      />
      <Button
        fullWidth={true}
        variant="primary"
        onClick={handleSubmit}
        loading={isLoading}
      >
        {submitButton}
      </Button>
    </ResetPasswordContent>
  )
}

export { ResetPassword }
