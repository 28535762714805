import { Store } from '../types/Tab/store'
import { PhysicalStore } from '@utils/shipToStoreUtils'

export const generateStaticMapUrl = (
  store: Store | PhysicalStore,
  googleMapsApiKey?: string,
  isMarkerAvailable?: boolean
) => {
  return encodeURI(`https://maps.googleapis.com/maps/api/staticmap?center=${
    store.completeAddress
  }&zoom=13&size=444x259&maptype=roadmap
${
  isMarkerAvailable
    ? `&markers=icon:${window.location.origin}/images/common/marker.png|${store.latitude},${store.longitude}`
    : ''
}
&key=${googleMapsApiKey}&style=saturation:-100`)
}
