import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import {
  ProductResultGroup,
  ProductResultWrapper,
  ProductsResultContainer,
  ProductTileContainer,
} from './ProductsResult.style'
import { BASE, SEARCH } from '../../../../../constants/routes'
import { useStoreIdentity } from '../../../../../foundation/hooks/useStoreIdentity'
import { formattedParams } from '@utils/url'
import { ProductTileAlgolia } from '@components/ProductTile'
import {
  SearchInputDisclaimer,
  SearchResultRow,
  ViewAllResult,
} from '../SearchLayer.style'
import { PRODUCT_TYPES_MAP } from '../../../../../constants/product'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import { ProductAlgolia } from '../../../../../types/ProductAlgolia/ProductAlgolia'

type Props = {
  searchTerm: string
  groupedProducts: ProductAlgolia[]
  totalResults: number | undefined
}

const ProductsResultAlgolia = ({
  searchTerm,
  groupedProducts,
  totalResults,
}: Props) => {
  const { t } = useTranslation()
  const { langCode } = useStoreIdentity()

  const firstProduct = groupedProducts?.at(0)?.clusters?.at(0)!
  const { lxSearchPageProductType } = ProductAlgoliaUtils.getProductAttributes(
    firstProduct?.attributes_translated
  )

  const generateViewAllLink = () => {
    const searchTermAll = searchTerm.trim()
    const searchParams = formattedParams(
      {
        searchTerm: searchTermAll,
      },
      true
    )
    const basePath = generatePath(`${BASE}${SEARCH}`, { country: langCode })
    return `${basePath}${searchParams}`
  }

  return (
    <ProductsResultContainer>
      <ProductResultGroup>
        <SearchResultRow>
          <SearchInputDisclaimer>
            {`${t(
              `search.${lxSearchPageProductType || PRODUCT_TYPES_MAP.frames}`
            ).toUpperCase()} (${totalResults})`}
          </SearchInputDisclaimer>
          <ViewAllResult
            to={generateViewAllLink()}
            data-element-id="SearchPanel_Results_ViewAll"
            data-description="ViewAll"
          >
            {t('search.viewAll')}
          </ViewAllResult>
        </SearchResultRow>
        <ProductResultWrapper>
          {groupedProducts.map((hit, index) => {
            return (
              <ProductTileContainer key={index}>
                <ProductTileAlgolia product={hit} tileIndex={index} />
              </ProductTileContainer>
            )
          })}
        </ProductResultWrapper>
      </ProductResultGroup>
    </ProductsResultContainer>
  )
}

export default ProductsResultAlgolia
