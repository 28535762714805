import React, { useState } from 'react'
import {
  ContentFaqs,
  NoResultsMessage,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  TitleFaqs,
  WrapperFaqs,
} from './Faqs.style'
import { AddCircleIcon, RemoveCircleIcon } from '../../../../UI/Icons'
import { replaceTextMediaCmsUrl } from '@utils/placements'
import useCmsTeaserBanner from '../../../../../hooks/useCmsBanner'
import {
  IPlacement,
  isCMArticle,
  isLXTeaser,
} from '../../../../../types/cmsPlacement/Placement'
import { ICMArticle } from '../../../../../types/cmsPlacement/CMArticle'
import { useSelector } from 'react-redux'
import { faqsSearcSelector } from 'src/features/ui/selector'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash-es/isEmpty'

const Faqs: React.FC<{
  placement: IPlacement
}> = ({ placement }) => {
  const articles = placement?.items?.filter(isCMArticle) || []
  const teaser = placement?.items?.find(isLXTeaser)
  const viewType = placement?.viewtype || 'default'
  const { teaserTitleValue, teaserTitle, promoteToH1 } =
    useCmsTeaserBanner({ item: teaser, viewType }) || {}
  const { t } = useTranslation()

  const filteredArticles = articles.filter((article) => {
    const getFaqsSearch = useSelector(faqsSearcSelector).toUpperCase()
    const emptySearch = getFaqsSearch === ' '
    const isSearch =
      !emptySearch && article.title.toUpperCase().includes(getFaqsSearch)
    return !emptySearch && isSearch
  })

  return (
    <>
      <WrapperFaqs>
        {filteredArticles.length > 0 ? (
          <>
            <TitleFaqs
              data-cm-metadata={`[{"_":"properties.${teaserTitle}"}]`}
              variant={promoteToH1 ? 'h1' : 'h2'}
              visible={!isEmpty(teaserTitleValue)}
            >
              {teaserTitleValue}
            </TitleFaqs>
            <ContentFaqs>
              {filteredArticles.map((article) => (
                <React.Fragment key={article.id}>
                  <AccordionArticle article={article} />
                </React.Fragment>
              ))}
            </ContentFaqs>
          </>
        ) : (
          <NoResultsMessage>{t('FAQs.Labels.NoTermFound')}</NoResultsMessage>
        )}
      </WrapperFaqs>
    </>
  )
}

const AccordionArticle: React.FC<{ article: ICMArticle }> = ({ article }) => {
  const [expanded, setExpanded] = useState<string | false>(false)

  const detailText = replaceTextMediaCmsUrl(article.detailText || '')
  const handleChange = (id: string) => (_, isExpanded: boolean) => {
    setExpanded(isExpanded ? id : false)
  }

  return (
    <StyledAccordion
      expanded={expanded === article.id}
      onChange={handleChange(article?.id!)}
    >
      <StyledAccordionSummary
        expandIcon={expanded ? <RemoveCircleIcon /> : <AddCircleIcon />}
      >
        {article.title}
      </StyledAccordionSummary>
      <StyledAccordionDetails
        dangerouslySetInnerHTML={{ __html: detailText }}
      />
    </StyledAccordion>
  )
}

export default Faqs
