import Axios, { Canceler } from 'axios'
//Standard libraries
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import getDisplayName from 'react-display-name'
import { EMPTY_STRING } from '../../../constants/common'
//Custom libraries
//Redux
import { RESET_ERROR_ACTION } from '../../../redux/actions/error'
import { passwordExpiredErrorSelector } from '../../../redux/selectors/error'
import { loginStatusSelector } from '../../../redux/selectors/user'

//UI
import LogonAndChangePasswordForm from '../../../pages/SignIn/components/LogonAndChangePasswordForm'
import SocialLogin from '../../../pages/SignIn/components/SocialLogin'
import SignInFormCheckout from '../../../pages/SignIn/components/SignInFormCheckout'
import { WrapperSignInForm } from './SignInFormDialog.style'
import { fetchShipInfo } from '../../../features/order/thunks'
import { FETCH_USER_DETAILS_REQUESTED_ACTION } from '../../../redux/actions/user'
import { setOpenModalSignIn } from '../../../features/ui/action'

function SignInFormDialog() {
  const dispatch = useDispatch()
  const passwordExpiredError: any = useSelector(passwordExpiredErrorSelector)
  const widgetName = getDisplayName(SignInFormDialog)
  const loginStatus = useSelector(loginStatusSelector)

  const [currentEmail, setCurrentEmail] = useState<string>(EMPTY_STRING)
  const [errorMessageKey, setErrorMessageKey] = useState<string>(EMPTY_STRING)
  const [socialLoginReady, setSocialLoginReady] = useState(false)

  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []

  const payloadBase: object = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  useEffect(() => {
    if (passwordExpiredError.errorKey && passwordExpiredError.errorCode) {
      setErrorMessageKey(
        'error-message.' +
          passwordExpiredError.errorKey +
          '_' +
          passwordExpiredError.errorCode
      )
    } else {
      setErrorMessageKey(EMPTY_STRING)
    }
  }, [passwordExpiredError])

  useEffect(() => {
    return () => {
      dispatch(RESET_ERROR_ACTION())
      cancels.forEach((cancel) => cancel())
    }
  }, [dispatch])

  const onProviderReady = () => {
    setSocialLoginReady(true)
  }

  useEffect(() => {
    if (!!loginStatus) {
      dispatch(fetchShipInfo(payloadBase))
      dispatch(
        FETCH_USER_DETAILS_REQUESTED_ACTION({
          widget: '',
          ...payloadBase,
        })
      )
      dispatch(setOpenModalSignIn(false))
    }
  }, [loginStatus])

  return (
    <>
      {errorMessageKey ? (
        <LogonAndChangePasswordForm
          errorMessageKey={errorMessageKey}
          currentEmail={currentEmail}
          payloadBase={payloadBase}
        />
      ) : (
        <WrapperSignInForm>
          <SignInFormCheckout
            socialLoginReady={socialLoginReady}
            setCurrentEmail={setCurrentEmail}
            payloadBase={payloadBase}
          />
          <SocialLogin onProvidersReady={onProviderReady} />
        </WrapperSignInForm>
      )}
    </>
  )
}

export default SignInFormDialog
