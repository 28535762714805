import { useStoreIdentity } from '../../../../../foundation/hooks/useStoreIdentity'
import { localeLangCountryUtil } from '@utils/countryUtil'
import { useCallback, useEffect, useState } from 'react'
import { Product } from '../../../../../types/product'
import { ProductAlgolia } from '../../../../../types/ProductAlgolia/ProductAlgolia'
import { ICMAlgolia } from '../../../../../types/cmsPlacement/CMAlgolia'
import {
  initIndexName,
  searchClient,
} from '../../../../../foundation/algolia/algoliaConfig'
import { MultipleQueriesQuery } from '@algolia/client-search'
import {
  isCMAlgolia,
  isCMCollection,
} from '../../../../../types/cmsPlacement/Placement'
import { ICMExternalProduct } from '../../../../../types/cmsPlacement/CMExternalProduct'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import { IHeader } from '../../../../../features/cms/cms.type'
import { getPlpProductList } from '../../../../../features/plp/utils'
import { ICMCollection } from '../../../../../types/cmsPlacement/CMCollection'

const useTrendingProducts = (
  headerPlacements: IHeader['headerPlacements'] = []
) => {
  const { langCode, country } = useStoreIdentity()
  const langCountry = localeLangCountryUtil(langCode)

  const [trendingProducts, setTrendingProducts] = useState<
    Product[] | ProductAlgolia[]
  >([])
  const [trendingAlgoliaEnabled, setTrendingAlgoliaEnabled] =
    useState<boolean>(false)

  const createGroupedTrendingNowQueries = (algoliaTeaser: ICMAlgolia) => {
    const groupedIndexName: string = initIndexName(langCountry, undefined, true)
    const query: MultipleQueriesQuery = {
      indexName: groupedIndexName,
      query: '',
      params: {
        hitsPerPage: algoliaTeaser.displayProductsCount,
        ruleContexts: [algoliaTeaser.groupedRule],
        ...(algoliaTeaser.ungroupedRule
          ? { attributesToRetrieve: ['x_groupkey'] }
          : {}),
      },
    }

    return [query]
  }
  const createUnGroupedTrendingNowQueries = (hits) => {
    const ungroupedIndexName = initIndexName(langCountry, undefined, false)

    const queries: MultipleQueriesQuery[] = hits.map(({ x_groupkey }: any) => ({
      indexName: ungroupedIndexName,
      query: '',
      params: {
        filters: `x_groupkey:"${x_groupkey}"`,
      },
    }))

    return queries
  }
  const getProductsFromCMCollection = (collectionItem: ICMCollection) => {
    const items = collectionItem?.teasableItems ?? []
    return items
      .filter((i) => (i as ICMExternalProduct).productData)
      .map((i) => (i as ICMExternalProduct).productData)
  }

  const getProductsFromAlgoliaTeaser = useCallback(
    async (algoliaTeaser: ICMAlgolia) => {
      const groupedQueries = createGroupedTrendingNowQueries(algoliaTeaser)
      const groupedResult = await searchClient
        .multipleQueries(groupedQueries)
        .then(({ results }) => results) //fetchHitsByMultipleQueries(queries)

      if (!algoliaTeaser.ungroupedRule)
        return ProductAlgoliaUtils.getFormattedProductAlgolia(
          groupedResult[0].hits,
          country
        )

      const unGroupedHits = groupedResult[0].hits
      const unGroupedQueries = createUnGroupedTrendingNowQueries(unGroupedHits)

      const unGroupedResult = await searchClient
        .multipleQueries(unGroupedQueries)
        .then(({ results }) => results)

      return ProductAlgoliaUtils.getFormattedProductAlgolia(
        unGroupedResult,
        country
      )
    },
    []
  )

  const getTrendingProducts = useCallback(
    async (headerPlacements: IHeader['headerPlacements'] = []) => {
      // There are two ways to populate trending now products:
      // 1. Using CMCollection with CMExternalProduct
      // 2. Using a teaser that provides Algolia ruleContexts
      const trendingNowData = headerPlacements.find(
        (p) => p.name === 'header_search_trending_now'
      )
      if (!trendingNowData) return

      const algoliaItem = trendingNowData.items.find(isCMAlgolia)
      const collectionItem = trendingNowData.items.find(isCMCollection)

      if (algoliaItem) {
        const algoliaProducts = await getProductsFromAlgoliaTeaser(algoliaItem)
        setTrendingProducts(algoliaProducts)
        setTrendingAlgoliaEnabled(true)
        return
      } else if (collectionItem) {
        const productsFromCMCollection =
          getProductsFromCMCollection(collectionItem)
        const products = getPlpProductList(productsFromCMCollection)
        setTrendingProducts(products)
        return
      }
    },
    [getProductsFromCMCollection, getProductsFromAlgoliaTeaser]
  )

  useEffect(() => {
    if (headerPlacements) void getTrendingProducts(headerPlacements)
  }, [headerPlacements])

  return {
    trendingProducts,
    trendingAlgoliaEnabled,
  }
}

export default useTrendingProducts
