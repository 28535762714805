import { styled } from '@mui/material/styles'
import { StyledTypography } from '@components/UI'
import { Link } from 'react-router-dom'

export const WrapperElementQueryList = styled('div', {
  name: 'ElementQueryList',
  slot: 'Wrapper',
})(() => ({}))

export const ImageQueryList = styled('div', {
  name: 'ElementQueryList',
  slot: 'Image',
})(({}) => ({
  div: {
    height: '418px',
    bottom: '10px',
    span: {
      '&::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      },
    },
  },
}))

export const Title = styled(StyledTypography, {
  name: 'ElementQueryList',
  slot: 'Title',
})(() => ({}))

export const Text = styled(StyledTypography, {
  name: 'ElementQueryList',
  slot: 'Text',
})(() => ({
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
}))

export const LinkElementQueryList = styled(Link, {
  name: 'ElementQueryList',
  slot: 'Link',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(8),
  color: theme.palette.text.dark,
}))

export const ContentElementQueryList = styled('div', {
  name: 'ElementQueryList',
  slot: 'Content',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  padding: theme.spacing(0, 2),
}))
