import { styled } from '@mui/material/styles'
import { StyledAnchor } from '@components/UI'

export const StyledProductTileFooterWrapper = styled('div', {
  name: 'ColorVariants',
  slot: 'FooterWrapper',
})(({ theme }) => ({
  color: theme.palette.text.dark,
}))

export const StyledProductTileFooter = styled('div', {
  name: 'ProductTile',
  slot: 'Footer',
})(({ theme }) => ({
  color: theme.palette.text.dark,
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(3, 0, 0),

  '& > div': {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  '& > div:nth-of-type(2)': {
    flex: 0,

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
}))

export const StyledProductDescriptionAnchor = styled(StyledAnchor, {
  name: 'ProductAnchor',
  slot: 'AnchorElement',
  shouldForwardProp: (prop) => prop !== 'isClRelatedTile',
})<{ isClRelatedTile?: boolean }>(({ isClRelatedTile = true, theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(isClRelatedTile ? 1 : 0),
  fontSize: 0,

  '&::after': {
    height: '0 !important',
  },

  [theme.breakpoints.up('xs')]: {
    paddingTop: 0,
  },

  [theme.breakpoints.up('sm')]: {
    paddingTop: isClRelatedTile ? theme.spacing(1) : 'inherit',
  },

  [theme.breakpoints.up('md')]: {
    width: 'auto',
  },
}))
export const StyledProductTileDescription = styled('div', {
  name: 'ProductTile',
  slot: 'Description',
  shouldForwardProp: (prop) => prop !== 'isPDP',
})<{ isPDP?: boolean | null }>(({ isPDP = false }) => ({
  alignItems: isPDP ? 'center' : 'flex-start',
  whiteSpace: 'nowrap',
  overflow: 'auto',
}))

export const StyledProductName = styled('span', {
  name: 'ProductTile',
  slot: 'ProductName',
  shouldForwardProp: (prop) => prop !== 'type',
})<{ type?: string }>(({ type }) => ({
  fontSize:
    type === 'contactlenses' || type === 'contactlensesaccessories' ? 14 : 12,
  fontWeight:
    type === 'contactlenses' || type === 'contactlensesaccessories'
      ? 600
      : 'normal',
  lineHeight: 1.43,
  whiteSpace: 'normal',
  overflow: 'auto',
  textTransform:
    type === 'contactlenses' || type === 'contactlensesaccessories'
      ? 'uppercase'
      : 'capitalize',
}))
export const StyledProductBrandName = styled('span', {
  name: 'ProductTile',
  slot: 'ProductBrandName',
})(({ theme }) => ({
  fontSize: 12,
  lineHeight: 1,
  fontWeight: 600,
  marginTop: theme.spacing(0.5),
  overflow: 'auto',
  textTransform: 'capitalize',

  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
    lineHeight: 2,
  },
}))
