import { styled } from '@mui/material/styles'

export const ProductsResultContainer = styled('div', {
  name: 'ProductsResult',
  slot: 'Container',
})(({ theme }) => ({
  flex: 1,
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridGap: theme.spacing(6),
}))

export const ProductResultWrapper = styled('div', {
  name: 'ProductsResult',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: theme.spacing(8, 2),

  [theme.breakpoints.up('sm')]: {
    gridGap: theme.spacing(8),
  },
}))

export const ProductResultGroup = styled('div', {
  name: 'ProductsResult',
  slot: 'DivGroup',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(7),
  marginBottom: theme.spacing(5),
}))

export const ProductTileContainer = styled('div', {
  name: 'ProductTile',
  slot: 'Container',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const ProductPriceContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isMobile',
  name: 'ProductPrice',
  slot: 'Container',
})<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  fontSize: isMobile ? 12 : 14,
  marginTop: theme.spacing(-4),

  [theme.breakpoints.up('sm')]: {
    marginTop: 0,
  },
}))
