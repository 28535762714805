import { Product } from '../../types/product'
import { useMemo, useState } from 'react'
import { usePageType } from '../../foundation/hooks/usePageType'
import useBreakpoints from '@hooks/useBreakpoints'
import ProductTileUtils from './ProductTileUtilsOld'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { getBadges } from '@utils/productAttributes_new'

import { useSelector } from 'react-redux'
import {
  addPrescriptionLensesSelector,
  plpBadgesSelector,
} from '../../redux/selectors/site'
import { useTranslation } from 'react-i18next'

const useProductTileOld = (product: Product, isSuggestedProduct = false) => {
  const { langCode } = useStoreIdentity()
  const { pageType } = usePageType()
  const badgeSelector = useSelector(plpBadgesSelector)
  const isRXEnabled = useSelector(addPrescriptionLensesSelector)
  const { t } = useTranslation()
  const {
    isViewportWidthUnder426,
    isMobile,
    isTabletPortrait,
    isDesktop,
    isDesktopL,
  } = useBreakpoints()
  const [selectedClusterIndex, setSelectedClusterIndex] = useState<number>(0)

  //ClustersData
  const clusters = product.clusters || []
  const clusterLength = clusters.length || 0
  const clusterSelected = useMemo<Product>(
    () => (clusters ? clusters[selectedClusterIndex] : product),
    [clusters?.length, selectedClusterIndex, product?.id]
  )

  const { primaryBadge, secondaryBadges } = getBadges(
    clusterSelected,
    t,
    badgeSelector,
    isRXEnabled
  )

  const productLinkTo =
    ProductTileUtils.getMocoLinkTo(clusterSelected!, langCode) || ''

  const { name } = ProductTileUtils.getAttributes(clusterSelected)

  const getTileDataElementId = (tileIndex: number) => {
    if (isSuggestedProduct) {
      return `Recommendations_Tile${tileIndex}[${name}_${product.partNumber}]`
    }
    switch (pageType) {
      case 'search':
        return `SearchPanel_Content_Tile${tileIndex}`
      case 'pdp':
        return `X_X_AlsoLike_Tile${tileIndex}`
      default:
        return `X_X_Tiles_Tile${tileIndex}_Img`
    }
  }

  const getProductImageWidth = (variant?: string) => {
    return useMemo<number>(() => {
      const isCMS = variant === 'cms-products-module'
      switch (true) {
        case isCMS:
          return 400
        case isViewportWidthUnder426:
          return 213
        case isMobile:
          return 296
        case isTabletPortrait:
          return 600
        case isDesktopL:
          return 600
        case isDesktop:
          return 600
        default:
          return 260
      }
    }, [
      isViewportWidthUnder426,
      isMobile,
      isTabletPortrait,
      isDesktop,
      isDesktopL,
      variant,
    ])
  }

  return {
    getTileDataElementId,
    getProductImageWidth,
    selectedClusterIndex,
    setSelectedClusterIndex,
    getClustersData: {
      clusters,
      clusterLength,
      clusterSelected,
      productPrimaryBadge: primaryBadge,
      productSecondaryBadges: secondaryBadges,
      productLinkTo,
    },
  }
}

export default useProductTileOld
