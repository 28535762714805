class AddressFormUtils {
  getEmailAutocompleteOptions = (e, domains) => {
    if (!Array.isArray(domains)) return []
    const emailRegExp = new RegExp(
      // eslint-disable-next-line
      '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])'
    )
    if (emailRegExp.test(e)) {
      return []
    } else {
      const hasAtChar = e?.includes('@')
      let email = e
      if (hasAtChar) {
        email = e.split('@')[0]
      }
      return domains.map((domain) => `${email}@${domain}`)
    }
  }

  onAutoCompleteChange = (option, reason, cb, email) => {
    if (reason === 'selectOption') {
      const [username] = email.split('@')
      const [, domain] = option.split('@')
      cb(`${username}@${domain}`)
    } else {
      cb(option)
    }
  }

  filterEmailOptions = (options, { inputValue }) => {
    if (inputValue.includes('@')) {
      const domain = inputValue.split('@')[1]
      return options.filter((opt) => opt.includes(domain))
    } else {
      return options
    }
  }

  fiscalCodeValidation = (cf: string) => {
    const cfUpper = cf.toUpperCase()

    const regex =
      /^[A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST][0-9LMNPQRSTUV]{2}[A-Z][0-9LMNPQRSTUV]{3}[A-Z]$/
    if (!regex.test(cfUpper)) return false

    const set1 = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const set2 = 'ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const setEven = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const setOdd = 'BAKPLCQDREVOSFTGUHMINJWZYX'

    let s = 0

    for (let i = 1; i <= 13; i += 2) {
      s += setEven.indexOf(set2.charAt(set1.indexOf(cfUpper.charAt(i))))
    }
    for (let i = 0; i <= 14; i += 2) {
      s += setOdd.indexOf(set2.charAt(set1.indexOf(cfUpper.charAt(i))))
    }

    return s % 26 === cfUpper.charCodeAt(15) - 'A'.charCodeAt(0)
  }
}

export default new AddressFormUtils()
