//used by CSR
export const SESSION_ERROR_ACTION = 'SESSION_ERROR_ACTION'
export const LOCK_ORDER_ACTION = 'LOCK_ORDER_ACTION'
export const LOCK_ORDRE_ERROR = [
  '_ERR_ORDER_IS_NOT_LOCKED',
  '_ERR_ORDER_IS_LOCKED',
]
export const FOR_USER_SESSION = 'FOR_USER_SESSION'
export const PARENT_IFRAME = 'parentIFrame'
export const IFRAME_RESIZER = 'iFrameResizer'
export const AUTHENTICATION_ERROR = ['_ERR_AUTHENTICATION_ERROR']
export const BAD_PARMS_ERROR = '_ERR_BAD_PARMS'
export const INVALID_PARAMETER_ERROR = 'ERR_INVALID_PARAMETER'
export const INVALID_CMD_PARAMETER_ERROR = '_ERR_CMD_INVALID_PARAM'
export const INVALID_NEW_PASSWORD = '_ERR_AUTHENTICATION_REUSEOLD_PASSWORD'
export const ERROR401CODE = '401'
