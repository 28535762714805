import { Button, ModalDialog } from '../UI'
//Styles
import {
  ButtonContainer,
  StyledHeadingContainer,
} from './RemoveAddressDialog.style'
//Standard libraries
import React from 'react'

//UI
//Custom libraries
import useBreakpoints from '../../hooks/useBreakpoints'
//Foundation libraries
import { useTranslation } from 'react-i18next'
import { Contact } from '../../types/user'

const RemoveAddressDialogLayout: React.FC<{
  handleClose: () => void
  handleOk: () => void
  userAddress: Contact
}> = ({ handleClose, handleOk }) => {
  const { isDesktop } = useBreakpoints()

  const { t } = useTranslation()

  return (
    <ModalDialog
      open={true}
      onClose={handleClose}
      onCloseButton={isDesktop ? handleClose : undefined}
      widthSize={'sm'}
      roundBorders
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <StyledHeadingContainer>
          {t('RemoveAddressDialog.Title')}
        </StyledHeadingContainer>
        <ButtonContainer>
          <Button onClick={() => handleOk()}>
            {t('RemoveAddressDialog.YesRemove')}
          </Button>

          <Button fillType={'outline'} onClick={handleClose}>
            {t('RemoveAddressDialog.NoClose')}
          </Button>
        </ButtonContainer>
      </>
    </ModalDialog>
  )
}

export { RemoveAddressDialogLayout }
