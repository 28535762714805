import React from 'react'
import Log from '../../../services/Log'
import { StyledPlacementsWrapper } from './PlacementsSwitch.style'
import CmsPlacementContainer from '../CmsComponents/CmsPlacementContainer'

import {
  AssociatedProduct,
  Product,
  ServerProduct,
} from '../../../types/product'
import GridOfProducts from '@components/Cms/CmsPlacement/CmsBanner/GridOfProducts'
import DcwProducts from '@components/Cms/CmsPlacement/CmsBanner/DcwProducts'
import ComboMiniSlider from '@components/Cms/CmsPlacement/CmsBanner/ComboMiniSlider'
import WallOfBrands from '@components/Cms/CmsPlacement/CmsBanner/WallOfBrands'
import Faqs from '@components/Cms/CmsPlacement/CmsBanner/Faqs'
import LandscapeBanner from '@components/Cms/CmsPlacement/CmsBanner/LandscapeBanner'
import TopPageBanner from '@components/Cms/CmsPlacement/CmsBanner/TopPageBanner'
import SquatBanner from '@components/Cms/CmsPlacement/CmsBanner/SquatBanner'
import QueryList from '@components/Cms/CmsPlacement/CmsBanner/QueryList'
import BoardWithFieldsBanner from '@components/Cms/CmsPlacement/CmsBanner/BoardWithFieldsBanner'
import BoxWithMarginBanner from '@components/Cms/CmsPlacement/CmsBanner/BoxWithMargin/BoxWithMarginBanner'
import SquareBoardWithSplit from '@components/Cms/CmsPlacement/CmsBanner/SquareBoardWithSplit'
import SquareBoardWithoutSplit from '@components/Cms/CmsPlacement/CmsBanner/SquareBoardWithoutSplit'
import GridOfBoards from '@components/Cms/CmsPlacement/CmsBanner/GridOfBoards'
import TextModule from '@components/Cms/CmsPlacement/CmsBanner/TextModule'
import FullWidthBanner from '@components/Cms/CmsPlacement/CmsBanner/FullWidthBanner'

import SuggestedProducts from '../../SuggestedProducts'
import { StyledSuggestionContainer } from '../../../pages/ProductDetails/ProductDetails.style'
import {
  IPlacement,
  isCMCollection,
  isCMHtml,
  isCMPlaceholder,
  isCMProductList,
  isCMQueryList,
} from '../../../types/cmsPlacement/Placement'
import { IViewType } from '../../../types/cmsPlacement/ViewType'

import SizeGuide from '../../../pages/ProductDetails/components/SizeGuide/SizeGuide'
import { FaqsSearch } from '@components/Cms/CmsPlacement/CmsBanner/Faqs/FaqsSearch'
import AlgoliaRecommendations from '@components/Cms/CmsPlacement/CmsBanner/AlgoliaRecommendations'
import WebFormModule from '@components/Cms/CmsPlacement/CmsBanner/WebForm/WebFormModule'
import NewsLetterBanner from '@components/Cms/CmsPlacement/CmsBanner/NewsLetterBanner'

interface PlacementsSwitchProps {
  placements?: IPlacement[]
  activePlacements?: IViewType[]
  currentProduct?: ServerProduct | Product
  suggestedProducts?: AssociatedProduct[]
}

interface GetPlacementProps {
  activePlacements: IViewType[]
  placement: IPlacement
  currentProduct?: ServerProduct | Product
  suggestedProducts?: AssociatedProduct[]
  teaserIndex: number
}

const isPlacementVisible = (
  activePlacements: PlacementsSwitchProps['activePlacements'],
  placementViewtype: IViewType
): boolean => activePlacements?.includes(placementViewtype) || false

const GetPlacement: React.FC<GetPlacementProps> = ({
  activePlacements,
  placement,
  currentProduct,
  suggestedProducts = [],
  teaserIndex,
}) => {
  const reflect = placement.placementReflect || false
  const placementCenter = placement.placementCenter || false

  try {
    switch (placement.viewtype) {
      case 'full-width-banner':
        return (
          <FullWidthBanner placement={placement} teaserIndex={teaserIndex} />
        )
      case 'grid-of-products':
        return (
          <GridOfProducts placement={placement} teaserIndex={teaserIndex} />
        )
      case 'dcw-products':
        return <DcwProducts placement={placement} teaserIndex={teaserIndex} />
      case 'combo-mini-slider-plus-box-all-fields':
        return (
          <ComboMiniSlider
            reflect={reflect}
            placement={placement}
            teaserIndex={teaserIndex}
          />
        )
      case 'wall-of-brands':
        return <WallOfBrands placement={placement} />
      case 'faqs':
        return <Faqs placement={placement} />
      case 'landscape-banner':
        return (
          <LandscapeBanner placement={placement} teaserIndex={teaserIndex} />
        )
      case 'top-page-banner':
        return <TopPageBanner placement={placement} teaserIndex={teaserIndex} />
      case 'box-with-margin':
        return (
          <BoxWithMarginBanner
            reflect={reflect}
            placement={placement}
            teaserIndex={teaserIndex}
          />
        )
      case 'boards-with-fields-below':
        return (
          <BoardWithFieldsBanner
            placement={placement}
            placementCenter={placementCenter}
            teaserIndex={teaserIndex}
          />
        )
      case 'grid-of-boards-two-columns':
        return (
          <GridOfBoards
            columnAmount={2}
            placement={placement}
            placementCenter={placementCenter}
            teaserIndex={teaserIndex}
          />
        )
      case 'grid-of-boards-three-columns':
        return (
          <GridOfBoards
            columnAmount={3}
            placement={placement}
            placementCenter={placementCenter}
            teaserIndex={teaserIndex}
          />
        )
      case 'squat-banner':
        return <SquatBanner placement={placement} teaserIndex={teaserIndex} />
      case 'square-boards-with-split':
        return (
          <SquareBoardWithSplit
            placement={placement}
            reflect={reflect}
            teaserIndex={teaserIndex}
          />
        )
      case 'square-boards-without-split':
        return (
          <SquareBoardWithoutSplit
            placement={placement}
            reflect={reflect}
            teaserIndex={teaserIndex}
          />
        )
      case 'text-module':
        return <TextModule placement={placement} teaserIndex={teaserIndex} />
      case 'default':
        if (placement.items.length === 0) {
          return null
        }

        const collection = placement.items
          ?.filter((item) => item !== null)
          .find(isCMCollection)

        const placeholder = placement.items
          ?.filter((item) => item !== null)
          .find(isCMPlaceholder)

        const productList = placement.items
          ?.filter((item) => item !== null)
          .find(isCMProductList)

        const queryList = placement.items
          ?.filter((item) => item !== null)
          .find(isCMQueryList)

        const cmHtml = placement.items
          ?.filter((item) => item !== null)
          .find(isCMHtml)

        const firstItem =
          collection ||
          productList ||
          queryList ||
          placeholder ||
          cmHtml! ||
          null

        if (firstItem !== null && cmHtml) {
          const html = cmHtml.html || ''
          return <div dangerouslySetInnerHTML={{ __html: html }} />
        }
        if (
          firstItem !== null &&
          isPlacementVisible(activePlacements, firstItem?.viewtype)
        ) {
          const firstItem =
            collection! || productList! || queryList! || placeholder!

          switch (firstItem.viewtype) {
            case 'text-module':
              return (
                <TextModule placement={firstItem} teaserIndex={teaserIndex} />
              )
            case 'full-width-banner':
              return (
                <FullWidthBanner
                  placement={firstItem}
                  teaserIndex={teaserIndex}
                />
              )
            case 'combo-mini-slider-plus-box-all-fields':
              return (
                <ComboMiniSlider
                  reflect={reflect}
                  placement={firstItem}
                  teaserIndex={teaserIndex}
                />
              )
            case 'grid-of-products':
              return (
                <GridOfProducts
                  placement={firstItem}
                  teaserIndex={teaserIndex}
                />
              )
            case 'dcw-products':
              return (
                <DcwProducts placement={firstItem} teaserIndex={teaserIndex} />
              )
            case 'landscape-banner':
              return (
                <LandscapeBanner
                  placement={firstItem}
                  teaserIndex={teaserIndex}
                />
              )
            case 'top-page-banner':
              return (
                <TopPageBanner
                  placement={firstItem}
                  teaserIndex={teaserIndex}
                />
              )
            case 'squat-banner':
              return (
                <SquatBanner placement={firstItem} teaserIndex={teaserIndex} />
              )
            case 'box-with-margin':
              return (
                <BoxWithMarginBanner
                  reflect={reflect}
                  placement={firstItem}
                  teaserIndex={teaserIndex}
                />
              )
            case 'boards-with-fields-below':
              return (
                <BoardWithFieldsBanner
                  placement={firstItem}
                  placementCenter={placementCenter}
                  teaserIndex={teaserIndex}
                />
              )
            case 'square-boards-with-split':
              return (
                <SquareBoardWithSplit
                  placement={firstItem}
                  reflect={reflect}
                  teaserIndex={teaserIndex}
                />
              )
            case 'square-boards-without-split':
              return (
                <SquareBoardWithoutSplit
                  placement={firstItem}
                  reflect={reflect}
                  teaserIndex={teaserIndex}
                />
              )
            case 'grid-of-boards-two-columns':
              return (
                <GridOfBoards
                  columnAmount={2}
                  placement={firstItem}
                  placementCenter={placementCenter}
                  teaserIndex={teaserIndex}
                />
              )
            case 'grid-of-boards-three-columns':
              return (
                <GridOfBoards
                  columnAmount={3}
                  placement={firstItem}
                  placementCenter={placementCenter}
                  teaserIndex={teaserIndex}
                />
              )
            case 'query-list':
              return <QueryList placement={firstItem} />
            case 'you-may-also-like':
              if (suggestedProducts.length === 0) return null
              return (
                <StyledSuggestionContainer>
                  <SuggestedProducts products={suggestedProducts} />
                </StyledSuggestionContainer>
              )
            case 'wall-of-brands':
              return <WallOfBrands placement={firstItem} />
            case 'size-guide':
              return <SizeGuide currentProduct={currentProduct as Product} />
            case 'faq-search':
              return <FaqsSearch />
            case 'Algolia Recommendations':
              return (
                <AlgoliaRecommendations
                  placement={firstItem}
                  isGrouped={placement.clusterTile}
                  currentProduct={currentProduct as Product}
                />
              )
            case 'Webform':
              return (
                <WebFormModule
                  placement={firstItem}
                  teaserIndex={teaserIndex}
                />
              )
            case 'Newsletter':
              return <NewsLetterBanner />
            default:
              return null
          }
        } else {
          return null
        }
      default:
        return null
    }
  } catch (e: any) {
    Log.error('ERROR IN GETTING CMS PLACEMENTS', e)
    return null
  }
}

const PlacementsSwitch: React.FC<PlacementsSwitchProps> = ({
  activePlacements = [],
  currentProduct,
  placements,
  suggestedProducts,
}) => {
  const placementsToShow: IViewType[] = [...activePlacements, 'default']

  const activePlacementsList =
    placements?.filter(
      (placement) =>
        isPlacementVisible(placementsToShow, placement.viewtype) &&
        ![
          'header_promo_bar',
          'footer_before_footer',
          'footer_newsletter_overlay',
          'footer_newsletter_side_banner',
        ].includes(placement?.name!)
    ) || []

  return (
    <StyledPlacementsWrapper>
      {activePlacementsList.map((placement, i) => {
        return (
          <CmsPlacementContainer placement={placement} key={i}>
            <GetPlacement
              activePlacements={placementsToShow}
              placement={placement}
              currentProduct={currentProduct}
              suggestedProducts={suggestedProducts}
              teaserIndex={i + 1}
            />
          </CmsPlacementContainer>
        )
      })}
    </StyledPlacementsWrapper>
  )
}

export default PlacementsSwitch
