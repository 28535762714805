import { styled } from '@mui/material/styles'
import {
  ProductPriceCurrentPriceFinal,
  ProductPriceWrapper,
} from '@components/ProductPriceFinal/ProductPriceFinal.style'

export const ProductPriceWrapperPlp = styled(ProductPriceWrapper, {
  name: 'ProductPrice',
  slot: 'WrapperPlp',
  shouldForwardProp: (prop) => prop !== 'isCompact',
})<{ isCompact: boolean }>(({ isCompact, theme }) => ({
  flexDirection: isCompact ? 'column' : 'row',
  gap: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}))

export const ProductPriceCurrentPricePlp = styled(
  ProductPriceCurrentPriceFinal,
  {
    name: 'ProductPrice',
    slot: 'CurrentPricePlp',
    shouldForwardProp: (prop) => prop !== 'isCompact',
  }
)<{ isCompact: boolean }>(({ isCompact }) => ({
  fontSize: isCompact ? 12 : 14,
}))
