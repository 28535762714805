import { styled } from '@mui/material/styles'

export const ProductPriceContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isPDP' && prop !== 'isCompact',
  name: 'ProductPrice',
  slot: 'Container',
})<{ isPDP?: boolean | null; isCompact: boolean | null }>(
  ({ isPDP = false, isCompact, theme }) => ({
    width: isCompact ? '100%' : 'fit-content',
    display: 'flex',
    flexWrap: isCompact ? 'wrap' : 'nowrap',
    justifyContent: isPDP ? 'flex-start' : 'center',
    alignItems: 'left',
    fontSize: isPDP ? 14 : isCompact ? 10 : 12,

    [theme.breakpoints.up('sm')]: {
      fontSize: isPDP ? 14 : 12,
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
    },
  })
)

export const ProductPriceDetails = styled('div', {
  name: 'ProductPrice',
  slot: 'ContentDiv',
  shouldForwardProp: (prop) => prop !== 'isPDP',
})<{ isPDP?: boolean }>(({ isPDP, theme }) => ({
  width: '100%',
  flexWrap: 'wrap',
  justifyContent: isPDP ? 'left' : 'center',
  alignItems: 'left',
  rowGap: '0px',

  [theme.breakpoints.up('sm')]: {
    width: 'fit-content',
    justifyContent: 'left',
  },
}))

export const ProductPriceContainerPDP = styled('div', {
  name: 'ProductPrice',
  slot: 'ContainerPDP',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  fontWeight: 600,
  justifyContent: 'space-between',
  color: theme.palette.text.dark,

  div: {
    width: 'fit-content',
  },
}))

export const KlarnaMessageContainerPDP = styled('div', {
  name: 'KlarnaMessage',
  slot: 'ContainerPDP',
})(() => ({
  position: 'relative',
}))

export const SuggestedProductPriceContainerPDP = styled('div', {
  name: 'SuggestedProductPrice',
  slot: 'Container',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}))

export const FavouriteProductPriceContainerPDP = styled('div', {
  name: 'FavouriteProductPrice',
  slot: 'Container',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}))

export const ProductPriceTypeName = styled('span', {
  name: 'ProductPrice',
  slot: 'TextField',
})(({ theme }) => ({
  flexGrow: 0,
  fontFamily: 'inherit',
  fontSize: 20,
  fontWeight: 600,
  lineHeight: 1.4,
  textAlign: 'left',
  textTransform: 'uppercase',
  color: theme.palette.text.dark,
}))

export const ProductCurrentPriceContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isPDP',
  name: 'ProductPrice',
  slot: 'CurrentPriceContainer',
})<{ isPDP?: boolean | null }>(({ isPDP = false, theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  color: theme.palette.text.dark,
  fontSize: 20,
  fontWeight: isPDP ? 600 : 400,
}))

export const ProductFrame = styled('span')({})

export const ProductPriceCurrentPrice = styled('span', {
  shouldForwardProp: (prop) =>
    prop !== 'isPDP' && prop !== 'isCompact' && prop !== 'isCart',
  name: 'ProductPrice',
  slot: 'CurrentPrice',
})<{ isPDP?: boolean; isCompact?: boolean; isCart?: boolean }>(
  ({ isPDP = false, isCompact, isCart, theme }) => ({
    fontSize: isPDP ? 16 : isCompact ? 12 : isCart ? 16 : 14,
    fontWeight: isPDP ? 600 : isCart ? 600 : 400,

    [theme.breakpoints.up('sm')]: {
      fontSize: isPDP ? 16 : isCart ? 16 : 14,
    },
  })
)

export const ProductPriceLabel = styled('span', {
  shouldForwardProp: (prop) => prop !== 'isPDP',
  name: 'ProductPrice',
  slot: 'Label',
})<{ isPDP: boolean | null }>(({ isPDP }) => ({
  fontSize: isPDP ? 16 : 14,
  fontWeight: isPDP ? 600 : 400,
  textTransform: 'uppercase',
}))

export const ProductPriceCurrentPriceContainer = styled('div', {
  name: 'ProductPrice',
  slot: 'CurrentPriceContainer',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const SuggestedProductPriceCurrentPrice = styled('span', {
  name: 'SuggestedProductPrice',
  slot: 'CurrentPrice',
})(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 'normal',
  lineHeight: 1.6,

  [theme.breakpoints.up('sm')]: {
    lineHeight: 1.43,
  },
}))

export const FavouriteProductPriceCurrentPrice = styled('span', {
  name: 'FavouriteProductPrice',
  slot: 'CurrentPrice',
})(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  fontWeight: 'normal',
  lineHeight: 1.6,

  [theme.breakpoints.up('sm')]: {
    lineHeight: 1.43,
  },
}))

export const SuggestedPerBoxProductPrice = styled('span')(({ theme }) => ({
  fontSize: 10,
  lineHeight: 1.6,

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
    lineHeight: 1.43,
  },
}))

export const ProductPriceFrom = styled('span', {
  name: 'ProductPrice',
  slot: 'TextField',
})({
  fontSize: 12,
})

export const SuggestedProductPriceFrom = styled('span', {
  name: 'ProductPrice',
  slot: 'TextField',
})({
  fontSize: 12,
  fontWeight: 400,
})

export const ProductPriceInitialPrice = styled('span', {
  name: 'ProductPrice',
  slot: 'InitialPrice',
  shouldForwardProp: (prop) => prop !== 'isCart',
})<{ isCart?: boolean }>(({ theme, isCart }) => ({
  color: theme.palette.text.dark,
  fontSize: isCart ? 14 : 10,
  textDecoration: 'line-through',

  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
  },
}))

export const ProductPriceInitialPricePDP = styled('span', {
  name: 'ProductPrice',
  slot: 'InitialPricePDP',
})(({ theme }) => ({
  fontSize: 14,
  flex: '0 0 auto',
  textDecoration: 'line-through',
  padding: theme.spacing(0, 2.5),
}))

export const SuggestedProductPriceInitialPricePDP = styled('span', {
  name: 'InitialPrice',
  slot: 'TextField',
})(({ theme }) => ({
  fontSize: 10,
  textDecoration: 'line-through',

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
}))

export const FavouriteProductPriceInitialPricePDP = styled('span', {
  name: 'InitialPrice',
  slot: 'TextField',
})(({ theme }) => ({
  fontSize: theme.spacing(3),
  textDecoration: 'line-through',
}))

export const ProductPriceDiscountBox = styled('span', {
  shouldForwardProp: (prop) => prop !== 'isPDP',
  name: 'ProductPrice',
  slot: 'DiscountBox',
})<{ isPDP?: boolean | null }>(({ isPDP = false, theme }) => ({
  display: 'flex',
  flex: '0 0 auto',
  marginLeft: 0,
  padding: theme.spacing(0, 2),
  marginTop: theme.spacing(1),
  fontSize: isPDP ? 14 : 12,
  fontWeight: 600,
  border: `1px solid ${theme.palette.secondary.dark}`,
  borderRadius: 25,
  // width: 'fit-content',

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 2),
  },
}))

export const ProductPriceDiscount = styled('span', {
  name: 'ProductPrice',
  slot: 'DiscountText',
})(({ theme }) => ({
  fontFamily: 'inherit',
  fontSize: 14,
  fontWeight: 600,
  lineHeight: 1.43,
  textTransform: 'uppercase',
  color: theme.palette.text.dark,
}))

export const ProductPriceContent = styled('div', {
  name: 'ProductPrice',
  slot: 'Content',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: 0,
}))

export const ProductPriceDiscountContainer = styled('div', {
  name: 'ProductPrice',
  slot: 'DiscountContainer',
})(({ theme }) => ({
  padding: theme.spacing(0.5),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.palette.primary.main}`,
  background: 'transparent',
}))

export const ProductPriceBoxesNr = styled('span', {
  name: 'ProductPrice',
  slot: 'BoxesNr',
})(({ theme }) => ({
  flexGrow: 0,
  fontFamily: 'inherit',
  fontSize: 14,
  fontWeight: 'normal',
  lineHeight: 1.43,
  textAlign: 'left',
  color: theme.palette.text.dark,
  paddingTop: theme.spacing(4),
}))

export const ProductPriceWrapper = styled('div', {
  name: 'ProductPrice',
  slot: 'Wrapper',
  shouldForwardProp: (prop) => prop !== 'isLoading',
})<{ isLoading?: boolean }>(({ theme, isLoading }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  div: {
    display: isLoading ? 'inherit' : 'flex',
    flexDirection: 'row',
    flexGrow: 0,
    alignItems: 'baseline',
    gap: theme.spacing(0.5),

    [theme.breakpoints.up('xs')]: {
      gap: theme.spacing(1),
    },
  },
}))

export const ProductPricePricePerBoxContainer = styled('div', {
  name: 'ProductPrice',
  slot: 'PricePerBoxContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 0,
  alignSelf: 'stretch',
  justifyContent: 'space-between',

  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(4),
  },
}))

export const ProductPricePricePerBox = styled('span', {
  name: 'ProductPrice',
  slot: 'PricePerBox',
})(({ theme }) => ({
  fontFamily: 'inherit',
  fontSize: 14,
  lineHeight: 1.43,
  textAlign: 'left',
  color: theme.palette.text.dark,
  width: 'inherit',
}))

export const ProductPricePricePerBoxPrices = styled('div', {
  name: 'ProductPrice',
  slot: 'PricePerBoxPrices',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 0,
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  padding: 0,
  color: theme.palette.text.dark,
}))

export const ProductPricePricePerBoxInitialPrice = styled('span', {
  name: 'ProductPrice',
  slot: 'PricePerBoxInitialPrice',
})({
  fontFamily: 'inherit',
  fontSize: 12,
  flexGrow: 0,
  lineHeight: 1.33,
  textDecoration: 'line-through',
  textAlign: 'right',
})

export const ProductPricePricePerBoxCurrentPrice = styled('span', {
  name: 'ProductPrice',
  slot: 'PricePerBoxCurrentPrice',
})({
  fontFamily: 'inherit',
  fontSize: 14,
  flexGrow: 0,
  lineHeight: 1.43,
  textAlign: 'right',
})
