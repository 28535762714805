import * as React from 'react'
import { styled } from '@mui/material/styles'
import {
  WarningIcon,
  CloseCircleIcon,
  CheckmarkRoundedValidIcon,
  ArrowDownIcon,
} from '../Icons'
import { CustomInputFieldProps } from '../../../types/inputFields'
import { TextField as MuiTextField } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import { useTheme } from '@mui/material/styles'

export const StyledTextFieldWrapper = styled('div', {
  name: 'CustomThemeComponent',
  slot: 'Root',
  shouldForwardProp: (prop) =>
    prop !== 'showvalidationstatus' &&
    prop !== 'ispristine' &&
    prop !== 'isvalid',
})<CustomInputFieldProps>(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  minHeight: theme.spacing(12),
  width: '100%',
  '& > div': {
    width: '100%',
  },
}))

export const StyledClearButton = styled(CloseCircleIcon, {
  name: 'TextField',
  slot: 'ClearButton',
})(() => ({
  cursor: 'pointer',
  height: 16,
}))

const BootstrapInput = styled(MuiTextField, {
  name: 'TextField',
  slot: 'Input',
  shouldForwardProp: (prop) =>
    prop !== 'isCartRecap' && prop !== 'isLong' && prop !== 'isLongHelper',
})<{ isCartRecap?: boolean; isLongHelper?: boolean }>(
  ({ isCartRecap, isLongHelper, theme }) => ({
    '.MuiInputLabel-root': {
      lineHeight: 1.15,
      fontWeight: 'normal',
      '&.MuiInputLabel-shrink': {
        fontSize: 12,
        color: theme.palette.text.dark,
      },
      '&.Mui-error': {
        color: theme.palette.text.dark,
      },
      '&[data-shrink="true"]': {
        transform: 'translate(12px, -14px) scale(1)',
        backgroundColor: 'transparent',
        left: 6,
      },
    },
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.color.white.main,
      letterSpacing: 'normal',
      height: '100%',
      padding: theme.spacing(0, 4),
      '.is-select &': {
        padding: 0,
        '.MuiSelect-select': {
          height: '100%',
          padding: theme.spacing(0, 0, 0, 4),
          display: 'flex',
          alignItems: 'center',
        },
        '.MuiList-root': {
          border: `2px solid ${theme.palette.text.dark}`,
        },
        '.MuiMenuItem-root': {
          color: theme.palette.text.dark,
          fontFamily: 'inherit',
          padding: theme.spacing(3, 4),
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
        },
      },

      '.MuiOutlinedInput-notchedOutline': {
        borderRadius: 4,
        border: `1px solid ${theme.palette.color.grey.light}`,
        top: 0,
        legend: {
          display: 'none',
        },
      },
      /*'&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.text.light,
      },*/
      '&.Mui-error > fieldset': {
        borderColor: theme.palette.error.main,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
      },
      '&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.error.main}`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.palette.primary.main}`,
      },
      '.is-valid &:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.success.main}`,
      },
    },
    '& .MuiInputBase-input': {
      position: 'relative',
      backgroundColor: theme.palette.text.light,
      color: theme.palette.text.dark,
      fontSize: 16,
      width: '100%',
      lineHeight: theme.spacing(6),
      padding: theme.spacing(0, 4, 0, 0),
      height: theme.spacing(12),
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&:focus': {
        borderColor: theme.palette.text.light,
      },
      '&::-webkit-search-decoration': { display: 'none' },
      '&::-webkit-search-cancel-button': { display: 'none' },
      '&::-webkit-search-results-button': { display: 'none' },
      '&::-webkit-search-results-decoration': { display: 'none' },
      '&:-webkit-autofill,&:-webkit-autofill:hover,&:-webkit-autofill:focus,&:-webkit-autofill:active':
        {
          backgroundClip: 'content-box !important',
          webkitBoxShadow: `0 0 0 30px ${theme.palette.text.light} inset`,
          boxShadow: `0 0 0 30px ${theme.palette.text.light} inset`,
          WebkitTextFillColor: theme.palette.text.dark,
        },
    },
    '.MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      left: 0,
      bottom: 'calc(-4px - 16px)', // indent from input: 4px, line-height: 16px
      fontSize: 12,
      lineHeight: '16px',
      color: theme.palette.error.main,
      top: isLongHelper ? '105%' : 'auto',
    },
    '.MuiInputAdornment-root': {
      margin: 0,
      color: theme.palette.text.dark,
      svg: {
        width: 'auto',
        height: 'auto',
      },
      '&.MuiInputAdornment-positionStart': {
        width: theme.spacing(15),
        marginRight: 0,
      },
      '&.MuiInputAdornment-positionEnd': {
        gap: theme.spacing(4),
      },
      '.is-select &': {
        position: 'absolute',
        '&.MuiInputAdornment-positionEnd': {
          right: theme.spacing(12),
        },
      },
    },
    '.MuiSelect-icon': {
      right: theme.spacing(4),
      top: isCartRecap ? theme.spacing(2) : theme.spacing(4),
      width: 16,
      height: 16,
    },
    '.MuiAutocomplete-inputRoot': {
      //TODO remove !important
      padding: `${theme.spacing(0, 4)} !important`,
      '.MuiAutocomplete-endAdornment': {
        position: 'static',
        display: 'flex',
        gap: theme.spacing(4),
        right: theme.spacing(4),
      },
      '.MuiAutocomplete-input': {
        padding: '0 !important',
      },
    },
  })
)

export const TextField = ({
  showvalidationstatus,
  showclearbutton,
  error,
  isfilled,
  isvalid,
  onClearButtonClick,
  customInputProps,
  SelectProps,
  type,
  select,
  isCartRecap,
  isLongHelper,
  ...props
}: CustomInputFieldProps) => {
  const theme = useTheme()
  const wrapperClassNames = [
    'dc-textfield-wrapper',
    ...(isvalid ? ['is-valid'] : []),
    ...(select ? ['is-select'] : []),
  ]

  const getEndAdornments = () => {
    let validationIcons: React.ReactNode | null = null
    if (showclearbutton && !error && isfilled) {
      validationIcons = (
        <StyledClearButton
          onClick={() => onClearButtonClick && onClearButtonClick()}
        />
      )
    } else if (error && showvalidationstatus) {
      validationIcons = <WarningIcon htmlColor={theme.palette.error.main} />
    } else if (isvalid && showvalidationstatus) {
      validationIcons = (
        <CheckmarkRoundedValidIcon
          width={16}
          height={16}
          htmlColor={theme.palette.success.main}
        ></CheckmarkRoundedValidIcon>
      )
    }
    return (
      <InputAdornment position="end">
        {validationIcons}
        {customInputProps?.endAdornment}
      </InputAdornment>
    )
  }

  const getStartAdornments = () => {
    return customInputProps?.startAdornment ? (
      <InputAdornment position="start">
        {customInputProps?.startAdornment}
      </InputAdornment>
    ) : null
  }

  return (
    <StyledTextFieldWrapper
      className={wrapperClassNames.join(' ')}
      showvalidationstatus={showvalidationstatus}
      isvalid={isvalid}
    >
      <BootstrapInput
        InputProps={{
          startAdornment: getStartAdornments(),
          endAdornment: getEndAdornments(),
          ...customInputProps,
        }}
        SelectProps={{
          IconComponent: ArrowDownIcon,
          MenuProps: {
            disableScrollLock: true,
            disablePortal: true,
            elevation: 0,
            marginThreshold: 0,
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          },
          ...SelectProps,
        }}
        type={type}
        error={error}
        variant="outlined"
        select={select}
        isCartRecap={isCartRecap}
        isLongHelper={isLongHelper}
        {...props}
      />
    </StyledTextFieldWrapper>
  )
}
