import {
  BASE,
  CART,
  WISHLIST,
  CHECKOUT_CHILDREN,
  CHECKOUT,
  PRESCRIPTION_VERIFICATION,
  STORELOCATOR,
  SIGNIN,
  SITEMAP,
} from '../../constants/routes'

import { currentPageSeoDataSelector } from '../../features/seo/selector'
import { useRouteMatcher } from './usePathMatcher'
import { useSelector } from 'react-redux'
import { openDrawerSearchSelector } from '../../features/ui/selector'
import { useLocation } from 'react-router-dom'

export const usePageType = () => {
  const location = useLocation()
  const seoUrlIdentifier = location.pathname?.split('/').slice(2)?.join('/')
  const seoData = useSelector(
    currentPageSeoDataSelector(decodeURIComponent(seoUrlIdentifier))
  )
  const isSearchDrawerVisible = useSelector(openDrawerSearchSelector)
  const matchRoute = useRouteMatcher()

  const seoPageType: string = seoData?.page?.type || ''

  const pageTypes = {
    search: isSearchDrawerVisible,
    home: !!matchRoute(BASE),
    cart: !!matchRoute(`/:country/${CART}`),
    checkout:
      !!matchRoute(
        `/:country/${CHECKOUT}/${CHECKOUT_CHILDREN.UPLOAD_PRESCRIPTION}`
      ) ||
      !!matchRoute(`/:country/${CHECKOUT}/${CHECKOUT_CHILDREN.SHIPPING}`) ||
      !!matchRoute(`/:country/${CHECKOUT}/${CHECKOUT_CHILDREN.PAYMENT}`) ||
      !!matchRoute(
        `/:country/${CHECKOUT}/${CHECKOUT_CHILDREN.ORDER_CONFIRMATION}`
      ),
    checkoutExternal: !!matchRoute(`/:country/${PRESCRIPTION_VERIFICATION}`),
    plp: seoPageType === 'ProductListPage' || seoPageType === 'CategoryPage',
    pdp: seoPageType === 'ProductPage' || seoPageType === 'ItemPage',
    sitemap: !!matchRoute(SITEMAP),
    wishlist: !!matchRoute(WISHLIST),
    storeLocator: !!matchRoute(STORELOCATOR),
    signIn: !!matchRoute(SIGNIN),
    landing: location.pathname.includes('/c/'),
    page404: !seoPageType,
  } as const

  return { pageType: Object.keys(pageTypes).find((key) => pageTypes[key]) }
}
