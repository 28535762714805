import { createAction } from '@reduxjs/toolkit'
import { PersonResponse } from '../../types/user'
import * as ACTIONTYPES from '../action-types/user'
import { AppPayloadWithWidget } from '../store'

const {
  INIT_STATE_FROM_STORAGE,
  FETCH_USER_DETAILS_REQUESTED,
  FETCH_USER_DETAILS_PENDING,
} = ACTIONTYPES

/** @returns action created using reduxjs/toolkit 'createAction' with `payload: AppBaseActionPayload` */
const createBaseAppAction = <T extends string>(t: T) =>
  createAction<AppPayloadWithWidget, T>(t)

const LOGIN_REQUESTED_ACTION = createAction<any, string>(
  ACTIONTYPES.LOGIN_REQUESTED
)
const LOGOUT_REQUESTED_ACTION = createAction<any, string>(
  ACTIONTYPES.LOGOUT_REQUESTED
)
const LOGIN_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.LOGIN_SUCCESS
)
const SESSION_ERROR_LOGIN_REQUESTED_ACTION = createAction<any, string>(
  ACTIONTYPES.SESSION_ERROR_LOGIN_REQUESTED
)
const SESSION_ERROR_LOGIN_ERROR_ACTION = createAction<any, string>(
  ACTIONTYPES.SESSION_ERROR_LOGIN_ERROR
)
const LOGOUT_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.LOGOUT_SUCCESS
)

const GUEST_LOGIN_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.GUEST_LOGIN_SUCCESS
)
const loginErrorAction = createAction<any, string>(ACTIONTYPES.LOGIN_ERROR)
const registrationAction = createAction<any, string>(
  ACTIONTYPES.REGISTRATION_REQUESTED
)
const REGISTRATION_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.REGISTRATION_SUCCESS
)
const REGISTRATION_PENDING_ACTION = createAction(
  ACTIONTYPES.REGISTRATION_PENDING
)
const NEWSLETTER_SUBSCRIBED_UNSUBSCRIBED_ACTION = createAction<any, string>(
  ACTIONTYPES.NEWSLETTER_SUBSCRIBED_UNSUBSCRIBED
)
const registrationErrorAction = createAction<any, string>(
  ACTIONTYPES.REGISTRATION_ERROR
)

const INIT_USER_FROM_STORAGE_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.INIT_USER_FROM_STORAGE_SUCCESS
)

const INIT_STATE_FROM_STORAGE_ACTION = createBaseAppAction(
  INIT_STATE_FROM_STORAGE
)

const FETCH_USER_DETAILS_PENDING_ACTION = createAction(
  FETCH_USER_DETAILS_PENDING
)

const FETCH_USER_DETAILS_REQUESTED_ACTION = createAction<AppPayloadWithWidget>(
  FETCH_USER_DETAILS_REQUESTED
)

const FETCH_USER_DETAILS_SUCCESS_ACTION = createAction<PersonResponse, string>(
  ACTIONTYPES.FETCH_USER_DETAILS_SUCCESS
)

const LISTEN_USER_FROM_STORAGE_ACTION = createAction<any, string>(
  ACTIONTYPES.LISTEN_USER_FROM_STORAGE
)

const LOGON_AND_CHANGE_PASSWORD_ACTION = createAction<any, string>(
  ACTIONTYPES.LOGON_AND_CHANGE_PASSWORD_REQUESTED
)

const LOGON_AND_CHANGE_PASSWORD_FAIL_ACTION = createAction<any, string>(
  ACTIONTYPES.LOGON_AND_CHANGE_PASSWORD_FAIL
)

export {
  LOGIN_REQUESTED_ACTION,
  LOGIN_SUCCESS_ACTION,
  SESSION_ERROR_LOGIN_REQUESTED_ACTION,
  LOGOUT_REQUESTED_ACTION,
  LOGOUT_SUCCESS_ACTION,
  GUEST_LOGIN_SUCCESS_ACTION,
  loginErrorAction,
  registrationAction,
  REGISTRATION_SUCCESS_ACTION,
  REGISTRATION_PENDING_ACTION,
  registrationErrorAction,
  INIT_USER_FROM_STORAGE_SUCCESS_ACTION,
  INIT_STATE_FROM_STORAGE_ACTION,
  FETCH_USER_DETAILS_REQUESTED_ACTION,
  FETCH_USER_DETAILS_PENDING_ACTION,
  FETCH_USER_DETAILS_SUCCESS_ACTION,
  SESSION_ERROR_LOGIN_ERROR_ACTION,
  LISTEN_USER_FROM_STORAGE_ACTION,
  LOGON_AND_CHANGE_PASSWORD_ACTION,
  LOGON_AND_CHANGE_PASSWORD_FAIL_ACTION,
  NEWSLETTER_SUBSCRIBED_UNSUBSCRIBED_ACTION,
}
