import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const LoadingSkeleton: React.FC<{ width?: string; height?: string }> = ({
  width = '100%',
  height = '800px',
}) => {
  return (
    <SkeletonTheme baseColor="transparent" highlightColor="rgba(0, 0, 0, 0.1)">
      <Skeleton width={width} height={height} />
    </SkeletonTheme>
  )
}

export default LoadingSkeleton
