import { styled } from '@mui/material/styles'
import {
  ProductPriceContainerFinal,
  ProductPriceCurrentPriceFinal,
  ProductPriceDiscountBoxFinal,
  ProductPriceInitialPriceFinal,
  ProductPriceWrapper,
} from '@components/ProductPriceFinal/ProductPriceFinal.style'
import { InfoIcon } from '@components/UI/Icons/Icons'
import { Z_INDEX_LV1 } from 'src/constants/ui'

export const ProductPriceLabelPdp = styled('div', {
  name: 'ProductPrice',
  slot: 'LabelPdp',
})(({ theme }) => ({
  lineHeight: 1,
  fontSize: 16,
  fontWeight: 'bold',
  textTransform: 'uppercase',
  gap: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  '& > div': {
    height: '1rem',
    flex: '0 1 16px',
  },
}))

export const ProductPriceWrapperPdp = styled(ProductPriceWrapper, {
  name: 'ProductPrice',
  slot: 'WrapperPdp',
})(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'column',
  },
}))

export const ProductPriceContainerPdp = styled(ProductPriceContainerFinal, {
  name: 'ProductPrice',
  slot: 'ContainerPdp',
})(({ theme }) => ({
  gap: theme.spacing(3.5),
}))

export const ProductPriceCurrentPricePdp = styled(
  ProductPriceCurrentPriceFinal,
  {
    name: 'ProductPrice',
    slot: 'CurrentPdp',
  }
)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 'bold',
  [theme.breakpoints.up('sm')]: {
    fontSize: 16,
  },
}))

export const ProductPriceInitialPricePdp = styled(
  ProductPriceInitialPriceFinal,
  {
    name: 'ProductPrice',
    slot: 'InitialPdp',
  }
)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 'bold',
  color: theme.palette.text.dark,
}))
export const ProductPriceDiscountBoxPdp = styled(ProductPriceDiscountBoxFinal, {
  name: 'ProductPrice',
  slot: 'DiscountBoxPdp',
})(() => ({
  fontSize: 14,
}))

export const ProductPricePerBoxContainerPdp = styled('div', {
  name: 'ProductPrice',
  slot: 'PricePerBoxContainerPdp',
})(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const ProductPricePerBoxDetailsContainerPdp = styled('div', {
  name: 'ProductPrice',
  slot: 'PricePerBoxDetailsContainerPdp',
})(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}))

export const ProductPriceDetailsContainerPdp = styled('div', {
  name: 'ProductPrice',
  slot: 'DetailsContainerPdp',
})(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  position: 'relative',
  minHeight: 24,
  height: '100%',
}))

export const KlarnaMessageContainerPdp = styled('div', {
  name: 'KlarnaMessage',
  slot: 'ContainerPdp',
})(() => ({
  width: '100%',
  position: 'relative',
}))

export const ProductPriceBoxesNrPdp = styled('span', {
  name: 'ProductPrice',
  slot: 'BoxesNrPdp',
})(({ theme }) => ({
  fontFamily: 'inherit',
  fontSize: 14,
  lineHeight: 1.43,
  textAlign: 'left',
  color: theme.palette.text.dark,
}))

export const ProductPriceTotalTooltipContainer = styled('div', {
  name: 'ProductPrice',
  slot: 'TotalTooltipContainer',
})(() => ({
  flex: '1 0 auto',
  position: 'relative',
}))

export const ProductPriceTotalTooltipIcon = styled(InfoIcon, {
  name: 'ProductPrice',
  slot: 'TotalTooltipIcon',
})(() => ({
  height: '1rem',
  flex: '0 1 16px',
}))

export const ProductPriceTotalTooltip = styled('div', {
  name: 'ProductPrice',
  slot: 'TotalTooltip',
})<{
  open?: boolean | null
}>(({ open }) => ({
  position: 'absolute',
  width: 256,
  top: -30,
  marginLeft: 12,
  opacity: open ? '1' : '0',
  transform: 'none',
  transition:
    'opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
}))

export const ProductPriceTotalTooltipWrapper = styled('div', {
  name: 'ProductPrice',
  slot: 'TotalTooltipWrapper',
})(() => ({
  position: 'absolute',
  top: -24,
}))
export const ProductPriceTotalTooltipMain = styled('div', {
  name: 'ProductPrice',
  slot: 'TotalTooltipMain',
})(({ theme }) => ({
  position: 'relative',

  '&::after': {
    content: "''",
    display: 'block',
    position: 'absolute',
    left: '-4px',
    top: 'calc(50% - 8px)',
    width: 16,
    backgroundColor: theme.palette.color.grey.dark,
    height: 16,
    transition: 'rotate(45deg)',
    transform: 'rotate(45deg)',
    zIndex: 0,
  },
}))
export const ProductPriceTotalTooltipContent = styled('div', {
  name: 'ProductPrice',
  slot: 'TotalTooltipContent',
})(({ theme }) => ({
  padding: '8px 16px',
  backgroundColor: theme.palette.color.grey.dark,
  position: 'relative',
  zIndex: Z_INDEX_LV1,
  fontSize: 12,
  lineHeight: '16px',
  color: theme.palette.text.light,
}))

export const ProductPriceSegmentDiscountBoxFinal = styled('div', {
  name: 'ProductPrice',
  slot: 'SegmentDiscountBoxFinal',
})(({ theme }) => ({
  fontSize: 12,
  display: 'flex',
  padding: theme.spacing(2, 2),
  fontWeight: 600,
  border: `1px solid ${theme.palette.secondary.dark}`,
  borderRadius: 25,
  gap: theme.spacing(2),
}))

export const ProductPriceSegmentDiscountBoxFinalText = styled('span', {
  name: 'ProductPrice',
  slot: 'SegmentDiscountBoxFinalText',
})(() => ({}))
