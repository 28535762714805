import React from 'react'

import {
  StyledBoardWithFieldsArticle,
  StyledBoardWithFieldsCTAContainer,
  StyledBoardWithFieldsPreTitle,
  StyledBoardWithFieldsTextContainer,
  StyledBoardWithFieldsTitle,
} from '../BoardWithFieldsBanner.style'
import CmsProductTile from '../../../../CmsComponents/CmsProductTile'

import CmsCta from '../../../../CmsComponents/CmsCta'
import useCmsTeaserBanner from '../../../../../../hooks/useCmsBanner'
import { getTeaserOverlayTextAlign } from '@utils/placements'
import { IViewType } from '../../../../../../types/cmsPlacement/ViewType'
import { ILXTeaser } from '../../../../../../types/cmsPlacement/LXTeaser'

interface BoardWithFieldsTextProps {
  viewType: IViewType
  bannerIndex: number
  item: ILXTeaser
  placementCenter?: boolean
  teaserIndex?: number
}
export const BoardWithFieldsText: React.FC<BoardWithFieldsTextProps> = ({
  item,
  viewType,
  placementCenter,
  bannerIndex,
  teaserIndex,
}) => {
  const {
    teaserTitle,
    teaserText,
    teaserTitleValue,
    teaserTextValue,
    teaserOverlaySettingsValue,
    teaserOverlayTextAlignValue,
    teaserOverlayStyleValue,
    promoteToH1,
    teaserLXCallToActionSettings,
    teaserPreTitle,
    productData,
    isFrameAdvisor,
  } = useCmsTeaserBanner({ item, viewType }) || {}

  const teaserLXCallToActionSettingsValue = teaserLXCallToActionSettings?.[0]
  const overlaySettingsControl = placementCenter
    ? 'block-center-middle'
    : teaserOverlaySettingsValue!

  const overlayTextAlignControl = placementCenter
    ? undefined
    : getTeaserOverlayTextAlign(teaserOverlayTextAlignValue)

  const content = (
    <>
      {teaserPreTitle && (
        <StyledBoardWithFieldsPreTitle
          data-cm-metadata={'[{"_":"properties.teaserPreTitle"}]'}
          variant={promoteToH1 ? 'h1' : 'h2'}
          fontWeight={'600'}
        >
          {teaserPreTitle}
        </StyledBoardWithFieldsPreTitle>
      )}
      {teaserTitleValue && (
        <StyledBoardWithFieldsTitle
          data-cm-metadata={`[{"_":"properties.${teaserTitle}"}]`}
          variant={promoteToH1 ? 'h1' : 'h2'}
        >
          {teaserTitleValue}
        </StyledBoardWithFieldsTitle>
      )}
      {teaserTextValue && (
        <StyledBoardWithFieldsTextContainer
          data-cm-metadata={`[{"_":"properties.${teaserText}"}]`}
          dangerouslySetInnerHTML={{ __html: teaserTextValue }}
        />
      )}
      {productData !== null && (
        <CmsProductTile product={productData} tileIndex={0} />
      )}

      {teaserLXCallToActionSettingsValue && (
        <StyledBoardWithFieldsCTAContainer
          data-element-id={isFrameAdvisor && 'X_X_Footer_FrameGenius_Open'}
        >
          <CmsCta
            actionSettings={teaserLXCallToActionSettingsValue}
            dataElementId={`${teaserIndex}Placement_Banner${bannerIndex}_CTA${1}`}
            key={`cms-content__text-module--cta-${1}`}
          />
        </StyledBoardWithFieldsCTAContainer>
      )}
    </>
  )

  return (
    <StyledBoardWithFieldsArticle
      overlaysettings={overlaySettingsControl}
      overlaytxtalign={overlayTextAlignControl}
      teaseroverlaystyle={teaserOverlayStyleValue}
    >
      {content}
    </StyledBoardWithFieldsArticle>
  )
}
