import DateService from './../services/DateService'

/**
 * Picks the latest expectedShipDate from orderItems, otherwise return null
 * @param orderItems the array that contains all the order items.
 * @param locale current locale.
 * @returns estimatedDeliveryDate | null
 */
export const getEstimatedDeliveryDate = (
  daysToAdd: number,
  locale: string
): string => {
  // - RX: TODAY + numberOfBusinessDays(SHIPPING_DAYS_RX+ MAN_DAYS_RX);
  // - NON-RX: TODAY + numberOfBusinessDays(AVAIL_SHIP_DAYS_PLANO_Standard)
  const dateFormatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  const estimatedDeliveryDate = DateService.addBusinessDays(
    new Date(),
    daysToAdd
  )

  return dateFormatter.format(estimatedDeliveryDate || new Date())
  // const maxOrderItemExpectedShipDate = orderItems.reduce((acc, cur) => {
  //   const { expectedShipDate } = cur
  //   if (acc < expectedShipDate) {
  //     acc = expectedShipDate
  //   }
  //   return acc
  // }, '')

  // const parsedDate = Date.parse(maxOrderItemExpectedShipDate)

  // if (isNaN(parsedDate)) return null

  // const estimatedDeliveryDate = DateService.addBusinessDays(parsedDate, availableShippingDays)

  // return dateFormatter.format(estimatedDeliveryDate)
}

export const formatEstimatedDeliveryDate = (
  formattedDate: string,
  locale: string
): string => {
  if (formattedDate === '') return ''

  const dateFormatter = new Intl.DateTimeFormat(locale, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })

  const dateParts: string[] = formattedDate.split('/')
  const dateObject = dateParts.length
    ? new Date(
        +dateParts[2],
        (dateParts[1] as unknown as number) - 1,
        +dateParts[0]
      )
    : new Date()
  return dateFormatter.format(dateObject)
}
