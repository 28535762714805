import { Cart, OrderItem } from '../types/order'
import { CheckoutAddressFormField } from '../types/checkout'

export interface PhysicalStoreResponse {
  country: string
  Description: {
    displayStoreName: string
  }[]
  Attribute: AttributePhysicalStore[]
  city: string
  postalCode: string
  latitude: string
  telephone1: string
  addressLine: string[]
  stateOrProvinceName: string
  x_staticMapImage: string
  storeName: string
  uniqueID: string
  longitude: string
  distance: string
}

interface AttributePhysicalStore {
  displayName: string
  displayValue: string
  name: string
  value: string
}

export interface PhysicalStore {
  uniqueID: string
  storeId: string
  distance: number
  country: string
  storeName: string
  completeAddress: string
  address: string
  city: string
  zipcode: string
  telephone: string
  storeEmailAddress: string
  latitude: string
  longitude: string
  mapImage: string
  mondayOT: string
  mondayCT: string
  tuesdayOT: string
  tuesdayCT: string
  wednesdayOT: string
  wednesdayCT: string
  thursdayOT: string
  thursdayCT: string
  fridayOT: string
  fridayCT: string
  saturdayOT: string
  saturdayCT: string
  sundayOT: string
  sundayCT: string
  enabledSearch?: boolean
}
class ShipToStoreUtils {
  getMapPhysicalStoresData = (
    stores: PhysicalStoreResponse[]
  ): PhysicalStore[] => {
    return stores.map((storeInfo) => {
      const attribute = this.getAttributePhysicalStore(storeInfo.Attribute)
      return {
        uniqueID: storeInfo.uniqueID,
        storeId: storeInfo.storeName,
        distance: Number(parseFloat(storeInfo.distance).toFixed(1)),
        country: storeInfo.country,
        storeName: attribute.storeName,
        completeAddress: `${storeInfo.city}, ${storeInfo.addressLine[0]}`,
        address: storeInfo.addressLine[0],
        city: attribute.city,
        zipcode: storeInfo.postalCode,
        telephone: storeInfo.telephone1,
        storeEmailAddress: attribute.storeEmailAddress,
        latitude: storeInfo.latitude || '',
        longitude: storeInfo.longitude || '',
        mapImage: storeInfo.x_staticMapImage,
        mondayOT: attribute.mondayOT,
        mondayCT: attribute.mondayCT,
        tuesdayOT: attribute.tuesdayOT,
        tuesdayCT: attribute.tuesdayCT,
        wednesdayOT: attribute.wednesdayOT,
        wednesdayCT: attribute.wednesdayCT,
        thursdayOT: attribute.thursdayOT,
        thursdayCT: attribute.thursdayCT,
        fridayOT: attribute.fridayOT,
        fridayCT: attribute.fridayCT,
        saturdayOT: attribute.saturdayOT,
        saturdayCT: attribute.saturdayCT,
        sundayOT: attribute.sundayOT,
        sundayCT: attribute.sundayCT,
      }
    })
  }
  getAttributePhysicalStore = (attributes: AttributePhysicalStore[]) => {
    const getDisplayValue = (displayName: string) => {
      return attributes.find(
        (attribute) => attribute?.displayName! === displayName
      )?.displayValue!
    }

    const city: string = getDisplayValue('shipToOrt1')
    const storeName: string = getDisplayValue('shipToName2')
    const storeEmailAddress: string = getDisplayValue('ship_to_smtp_addr')
    const mondayOT: string = getDisplayValue('openMonday') || 'closed'
    const mondayCT: string = getDisplayValue('closeMonday') || 'closed'
    const tuesdayOT: string = getDisplayValue('openTuesday') || 'closed'
    const tuesdayCT: string = getDisplayValue('closeTuesday') || 'closed'
    const wednesdayOT: string = getDisplayValue('openWednesday') || 'closed'
    const wednesdayCT: string = getDisplayValue('closeWednesday') || 'closed'
    const thursdayOT: string = getDisplayValue('openThursday') || 'closed'
    const thursdayCT: string = getDisplayValue('closeThursday') || 'closed'
    const fridayOT: string = getDisplayValue('openFriday') || 'closed'
    const fridayCT: string = getDisplayValue('closeFriday') || 'closed'
    const saturdayOT: string = getDisplayValue('openSaturday') || 'closed'
    const saturdayCT: string = getDisplayValue('closeSaturday') || 'closed'
    const sundayOT: string = getDisplayValue('openSunday') || 'closed'
    const sundayCT: string = getDisplayValue('closeSunday') || 'closed'

    return {
      city,
      storeName,
      storeEmailAddress,
      mondayOT,
      mondayCT,
      tuesdayOT,
      tuesdayCT,
      wednesdayOT,
      wednesdayCT,
      thursdayOT,
      thursdayCT,
      fridayOT,
      fridayCT,
      saturdayOT,
      saturdayCT,
      sundayOT,
      sundayCT,
    }
  }

  getPhysicalStoreSelectedInfo = (orderItem: OrderItem) => {
    const address = orderItem?.addressLine?.at(0)?.toLowerCase() || ''
    const city = orderItem?.city?.toLowerCase() || ''
    const physicalStoreId = orderItem?.physicalStoreId || ''
    const physicalStoreExternalId = orderItem?.physicalStoreExternalId || ''
    const telephone = orderItem?.phone1 || ''
    const email = orderItem?.email2 || ''
    const zipCode = orderItem?.zipCode || ''
    const stateOrProvinceName = orderItem?.stateOrProvinceName || ''

    return {
      address,
      city,
      physicalStoreId,
      physicalStoreExternalId,
      telephone,
      email,
      zipCode,
      stateOrProvinceName,
      uniqueID: physicalStoreId,
    }
  }
  getShippingFormFieldsShipToStore = (
    shippingFormFields: CheckoutAddressFormField[]
  ) => {
    return shippingFormFields.filter((field) => {
      const removeFields = [
        'addressLine1',
        'addressLine2',
        'city',
        'state',
        'country',
        'zipCode',
      ]
      return !removeFields.includes(field.fieldName)
    })
  }

  getExtendAttributeShipToStore = (
    orderExtendAttribute: Cart['orderExtendAttribute']
  ) => {
    const getValue = (attributeName: string) => {
      return orderExtendAttribute.find(
        (attribute) => attribute?.attributeName! === attributeName
      )?.attributeValue
    }

    const name = getValue('RecipientName') || ''
    const surname = getValue('RecipientSurname') || ''
    const email = getValue('RecipientEmail') || ''
    const telephone = getValue('RecipientTelephone') || ''
    const fiscalCode = getValue('RecipientFiscalCode') || ''
    const mapLink =
      getValue('physicalStoreMapLink')
        ?.replace('zoom=15', 'zoom=13&style=saturation:-100')
        .replace('markers=anchor:32,10|', 'center=') || ''

    return {
      fullName: `${name} ${surname}`,
      email,
      telephone,
      fiscalCode,
      mapLink,
    }
  }

  isShipToStore = (orderItems: OrderItem[]) => {
    const orderItem = orderItems?.find((item) => !!item)

    const shipModeCode = orderItem?.shipModeCode
    return Boolean(shipModeCode === 'ShipToStore')
  }
}

export const shipToStoreUtils = new ShipToStoreUtils()
