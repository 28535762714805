import { styled } from '@mui/material/styles'

const CmsTeaserDetailText = styled('div', {
  name: 'CmsTeaser',
  slot: 'DetailText',
})(({ theme }) => ({
  fontSize: 16,
  p: {
    margin: 0,
  },
  a: {
    color: theme.palette.text.dark,
    textDecoration: 'underline',
  },
  [theme.breakpoints.up('md')]: {
    '.align--left,& .align--left': {
      textAlign: 'left',
    },
    '.align--center,& .align--center': {
      textAlign: 'center',
    },
    '.align--right,& .align--right': {
      textAlign: 'right',
    },
    '.align--justify': {
      textAlign: 'justify',
    },
    '& .left': {
      textAlign: 'left',
    },
    '& .center': {
      textAlign: 'center',
    },
    '& .right': {
      textAlign: 'right',
    },
    '& .justify': {
      textAlign: 'justify',
    },
  },
  h1: {
    fontSize: 32,
  },
  h2: {
    fontSize: 28,
  },
  h3: {
    fontSize: 16,
  },
  '.text-small': {
    fontSize: 12,
  },
  '.text-large': {
    fontSize: 20,
    lineHeight: 1.7,
    [theme.breakpoints.up('sm')]: {
      fontSize: 40,
      lineHeight: 1.4,
    },
  },

  table: {
    display: 'flex',
    justifyContent: 'left',
    borderCollapse: 'collapse',
    fontSize: 12,
    'tr, td': {
      border: `2px solid ${theme.palette.text.dark}`,
      textAlign: 'left',
      padding: theme.spacing(2),
    },
    'tr:first-of-type': {
      fontWeight: 600,
      backgroundColor: theme.palette.color.grey.lightest,
    },
  },
  'table.center': {
    justifyContent: 'center',
  },
  'table.left': {
    justifyContent: 'left',
  },
  'table.right': {
    justifyContent: 'right',
  },
}))

export default CmsTeaserDetailText
