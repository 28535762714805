import { useEffect, useRef, useState } from 'react'

type UseCountdown = {
  hours: string
  minutes: string
  seconds: string
}

const checkIfLessThanZero = (n: number): string =>
  String(n > 0 ? n : 0).padStart(2, '0')

interface IUseCountdownProps {
  countdownEnd: number // Datetime of when the countdown ends
  countdownStart?: number // Datetime of when the countdown starts (if not provided, it will start at the current time)
}
const useCountdown = (props: IUseCountdownProps) => {
  const { countdownEnd, countdownStart = new Date().getTime() } = props

  // Real time of countdown start
  const realStartTime = useRef<number>(new Date().getTime())

  // Time passed since countdown start
  const [timePassed, setTimePassed] = useState<number>(0)

  // Current time
  const currentTime = countdownStart + timePassed

  // Time left until countdown ends
  const timeLeft = countdownEnd - currentTime

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimePassed(new Date().getTime() - realStartTime.current)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [])

  const { hours, minutes, seconds } = getReturnValues(timeLeft)

  return {
    hours,
    minutes,
    seconds,
    isExpired: currentTime > countdownEnd,
  }
}

const getReturnValues = (milliseconds: number): UseCountdown => {
  // calculate time left
  const hours = checkIfLessThanZero(Math.floor(milliseconds / (1000 * 60 * 60)))
  const minutes = checkIfLessThanZero(
    Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60))
  )
  const seconds = checkIfLessThanZero(
    Math.round((milliseconds % (1000 * 60)) / 1000)
  )

  return {
    hours,
    minutes,
    seconds,
  }
}

export { useCountdown }
