import { styled } from '@mui/material/styles'
import {
  ITeaserOverlayStyle,
  ITeaserOverlaySettings,
  ITeaserOverlayTextAlign,
} from '../../../../../types/cmsPlacement/LXTeaser'
import { getRatioBanner, teaserOverlayStyleToColor } from '@utils/placements'
import { CmsShoppable } from '../../../CmsComponents/CmsShoppable/CmsShoppableImage'
import CmsTeaserDetailText from '../../../CmsComponents/CmsTeaserDetailText'
import CmsTeaserTitle from '../../../CmsComponents/CmsTeaserTitle'
import { StyledAnchor, StyledTypography } from '../../../../UI'
import { IViewType } from '../../../../../types/cmsPlacement/ViewType'

export const SquareBoardWithSplitWrapper = styled('div', {
  name: 'SquareBoardWithSplit',
  slot: 'Wrapper',
  shouldForwardProp: (prop) => prop !== 'order' && prop !== 'isComboMiniSlider',
})<{
  order?: string
  isComboMiniSlider?: boolean
}>(({ order, isComboMiniSlider, theme }) => ({
  display: 'grid',
  gap: theme.spacing(6),
  flexDirection: 'row',
  flexWrap: 'wrap',
  a: {
    flexGrow: 0,
    whiteSpace: 'nowrap',
  },
  '& > div:nth-of-type(1)': {
    order: order,
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 16),
  },
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: isComboMiniSlider ? 'auto' : '1fr 1fr',
    gap: theme.spacing(8),
    '.lazy-load-wrapper img': {
      height: 'auto',
    },
  },
}))
export const SquareBoardWithSplitTextContent = styled('div', {
  name: 'SquareBoardWithSplit',
  slot: 'TextContent',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  position: 'absolute',
  flexDirection: 'column',
  bottom: 0,
  marginBottom: theme.spacing(6),
  padding: theme.spacing(0, 6),
  gap: theme.spacing(4),

  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    gap: theme.spacing(10),
  },
}))

export const SquareBoardWithSplitArticle = styled('article', {
  name: 'SquareBoardWithSplit',
  slot: 'Article',
  shouldForwardProp: (prop) =>
    prop !== 'overlaysettings' &&
    prop !== 'overlaytxtalign' &&
    prop !== 'teaseroverlaystyle',
})<{
  overlaysettings?: ITeaserOverlaySettings
  overlaytxtalign?: ITeaserOverlayTextAlign
  teaseroverlaystyle?: ITeaserOverlayStyle
}>(({ theme, teaseroverlaystyle }) => ({
  '& > *': {
    textAlign: ' left',
    ...teaserOverlayStyleToColor(theme, teaseroverlaystyle),
  },
}))

export const SquareBoardWithSplitPreTitle = styled(StyledTypography, {
  name: 'SquareBoardWithSplit',
  slot: 'PreTitle',
})(({ theme }) => ({
  fontSize: 16,
  paddingBottom: theme.spacing(),
}))

export const SquareBoardWithSplitTitle = styled(CmsTeaserTitle, {
  name: 'SquareBoardWithSplit',
  slot: 'Title',
})(() => ({}))

export const SquareBoardWithSplitTextContainer = styled(CmsTeaserDetailText, {
  name: 'SquareBoardWithSplit',
  slot: 'TextContainer',
})(() => ({}))

export const SquareBoardWithSplitCTAContainer = styled('div', {
  name: 'SquareBoardWithSplit',
  slot: 'CTAContainer',
})(({ theme }) => ({
  alignSelf: 'end',
  display: 'flex',
  flex: 1,
  width: '100%',
  justifyContent: 'start',
  [theme.breakpoints.up('lg')]: {
    justifyContent: 'end',
  },
  '& > a': {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'unset',
    },
  },
}))

export const SquareBoardWithSplitMediaOverlapContainer = styled('div', {
  name: 'SquareBoardWithSplitMediaOverlap',
  slot: 'Container',
  shouldForwardProp: (prop) => prop !== 'viewType',
})<{ viewType: IViewType }>(({ viewType, theme }) => ({
  width: '100%',
  position: 'relative',
  ...getRatioBanner(viewType, theme),
}))

export const SquareBoardWithSplitMediaOverlapAnchor = styled(StyledAnchor, {
  name: 'SquareBoardWithSplitMediaOverlap',
  slot: 'Anchor',
})(() => ({
  display: 'block',
  height: '100%',
  fontSize: 0,
  '&::after': {
    backgroundColor: 'transparent',
  },
  '& > span': {
    display: 'flex !important',
  },
}))

export const SquareBoardWithSplitItem = styled('div', {
  name: 'SquareBoardWithsplit',
  slot: 'Item',
})(() => ({
  overflow: 'hidden',
  position: 'relative',
}))

export const SquareBoardWithShoppbaleImg = styled(CmsShoppable, {
  name: 'SquareBoardWithsplit',
  slot: 'ShoppbaleImg',
})(({ theme }) => ({
  left: theme.spacing(6),
}))
