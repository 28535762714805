import { ButtonAsLink } from '../UI'
import { styled } from '@mui/material/styles'

export const StyledOrderRecapItemsWrapper = styled('div', {
  name: 'OrderRecap',
  slot: 'ItemsWrapper',
})(({ theme }) => ({
  flexGrow: 1,
  marginBottom: theme.spacing(2),
}))
export const StyledOrderRecapWrapper = styled('div', {
  name: 'OrderRecap',
  slot: 'Wrapper',
})(() => ({
  position: 'relative',
}))

export const OrderRecapTitle = styled('div', {
  name: 'OrderRecap',
  slot: 'Title',
})(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  color: theme.palette.text.dark,
  fontSize: 14,
  fontWeight: 600,
}))

export const StyledRecapContainer = styled('div', {
  name: 'OrderRecap',
  slot: 'Container',
})(({ theme }) => ({
  marginTop: theme.spacing(4),
  backgroundColor: theme.palette.color.white.main,
  border: `1px solid ${theme.palette.color.grey.light}`,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(4),

  '&:first-of-type': {
    marginTop: 'unset',
  },
}))

export const StyledRecapCTAContainer = styled(StyledRecapContainer, {
  name: 'OrderRecap',
  slot: 'ContinueCTA',
})(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 0,
  textAlign: 'center',
  width: '100%',
  padding: 0,
  marginTop: theme.spacing(4),

  '& a': {
    whiteSpace: 'nowrap',
  },
}))

export const StyledRecapShipping = styled(StyledRecapContainer, {
  name: 'OrderRecap',
  slot: 'Shipping',
})(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '1fr 1fr',
  gridTemplateColumns: '1fr auto',
  fontSize: 14,
  color: theme.palette.text.dark,
}))

export const StyledRecapCodPaymentMethod = styled(StyledRecapContainer, {
  name: 'OrderRecap',
  slot: 'PaymentMethod',
})(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.text.dark,
}))

export const StyledEditButtonWrapper = styled('div', {
  name: 'OrderRecap',
  slot: 'EditButtonWrapper',
})(() => ({
  textAlign: 'right',
  justifyContent: 'right',
  fontWeight: 600,
}))

export const StyledEditButtonLink = styled(ButtonAsLink, {
  name: 'OrderRecap',
  slot: 'EditButtonLink',
})(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  color: theme.palette.primary.main,
  '&::after': {
    background: theme.palette.primary.main,
  },
}))

export const StyledOrderRecapSummary = styled(StyledRecapContainer, {
  name: 'OrderRecap',
  slot: 'Summary',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  color: theme.palette.text.dark,
}))

export const TotalRow = styled('div', {
  name: 'OrderRecap',
  slot: 'Total',
})(() => ({
  display: 'grid',
  fontWeight: 600,
  gridTemplateColumns: '1fr 1fr',
  fontSize: '20px',
  textTransform: 'uppercase',
  lineHeight: '20px',
}))

export const Total = styled('div', {
  name: 'OrderRecap',
  slot: 'TotalValue',
})(() => ({
  alignItems: 'start',
}))

export const TotalWrapper = styled('div', {
  name: 'OrderRecap',
  slot: 'TotalWrapper',
})(() => ({
  fontSize: 16,
  textAlign: 'right',
}))

export const VatIncludedWrapper = styled('div', {
  name: 'VatIncluded',
  slot: 'Wrapper',
})(() => ({
  fontSize: 11,
  fontWeight: 'normal',
  textTransform: 'none',
  textAlign: 'right',
}))

export const StyledTitleWrapper = styled('div', {
  name: 'TitleWrapperEditPopup',
  slot: 'StyledTitleWrapper',
})(() => ({
  width: 261,
}))

export const ShippingBillingRecapContent = styled('div', {
  name: 'OrderRecap',
  slot: 'ShippingBillingRecapContent',
})(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.dark,
  marginBottom: theme.spacing(2),
  '& *': {
    display: 'block',
    lineHeight: '14px',
  },
}))

export const ShippingBillingRecaptitle = styled(OrderRecapTitle, {
  name: 'OrderRecap',
  slot: 'ShippingBillingRecapTitle',
})(() => ({
  fontSize: 14,
  justifyContent: 'space-between',
}))

export const PromoCodeWrapper = styled('div', {
  name: 'OrderRecap',
  slot: 'PromoCodeWrapper',
})(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const PromoText = styled('span', {
  name: 'OrderRecap',
  slot: 'Promo',
})(({ theme }) => ({
  color: theme.palette.text.dark,
  fontSize: 16,
}))

export const ProductImageContainer = styled('div', {
  name: 'OrderRecap',
  slot: 'ImageContainer',
})(() => ({
  display: 'flex',
  borderRadius: 8,
  overflow: 'hidden',
}))

export const OrderRecapPhysicalStoreInfoContent = styled('div', {
  name: 'OrderRecap',
  slot: 'PhysicalStoreInfoContent',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  textTransform: 'capitalize',
}))
