import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'
import { RootReducerState } from '../../redux/reducers'

export interface IVirtualMirrorAvailableResponse {
  [key: string]: IVirtualMirrorAvailable
}
export interface IVirtualMirrorAvailable {
  available: boolean
  lods: string[]
  resolutions: string[]
  transitions: boolean
  transitionsResolutions: string[]
}

export const vmApi = createApi({
  reducerPath: 'vmApi',
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  endpoints: (builder) => ({
    getVMAvailable: builder.query<IVirtualMirrorAvailable, string>({
      queryFn: async (upc, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootReducerState
        const { xStoreCfg } = state.site.currentSite!
        const basePath =
          xStoreCfg?.VM_AVAILABILITY_SERVER || 'https://vmcore.luxottica.com'

        const result = await baseQuery({
          url: `${basePath}/api/upc/v2/${upc}/availability`,
        })

        return (result.data as IVirtualMirrorAvailableResponse)
          ? {
              data: transformResponse(
                result?.data as IVirtualMirrorAvailableResponse,
                upc
              ) as IVirtualMirrorAvailable,
            }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
  }),
})

const transformResponse = (
  response: IVirtualMirrorAvailableResponse,
  upc: string
) => {
  return response[`${upc}`]
}
export const { useGetVMAvailableQuery } = vmApi
