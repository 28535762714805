import { PRODUCT_TYPES_MAP } from '../../constants/product'

import { pickBy } from 'lodash-es'
import { useSelector } from 'react-redux'
import {
  addPrescriptionLensesSelector,
  plpBadgesSelector,
} from '../../redux/selectors/site'
import { ProductAlgolia } from '../../types/ProductAlgolia/ProductAlgolia'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import { SLASH_CHARACTER } from '../../constants/ui'

export const PRODUCT_ATTRIBUTE_CHILD_VALUE = 'BAMBINO' as const
export const PRODUCT_ATTRIBUTE_PHOTOCHROMIC_VALUE = [
  'PHOTOCHROMIC',
  //'TRANSITIONS®',
]
export const PRODUCT_ATTRIBUTE_BLUE_LIGHT_FILTER_VALUE = 'FILTRO ANTI LUCE BLU'
//export const PRODUCT_ATTRIBUTE_TRANSITION_VALUE = 'TRANSITIONS'

export interface ProductBadges {
  isNew: boolean
  isBadge: boolean
  isLimitedEdition: boolean
  isExclusive: boolean
  isAvantPremiere: boolean
  isOnlineExclusive: boolean
  isSustainable: boolean
  isCustomizable: boolean
  isPolarized: boolean
  isMostPopular: boolean
  isRoxable: boolean
  isKids: boolean
  isPhotochromic: boolean
  //isTransitions?: boolean
  isBlueLightFilter: boolean
  clIsNewArrival: boolean
  clIsExclusive: boolean
}
class ProductTileAlgoliaUtils {
  getProductTileAttributes = (
    attributes: ProductAlgolia['attributes_translated']
  ) => {
    const colorLabel = this.getColorLabel(attributes)
    const badges = this.getBadges(attributes)

    return {
      colorLabel,
      badges,
    }
  }

  getColorLabel = (attributes: ProductAlgolia['attributes_translated']) => {
    const getAttributes = ProductAlgoliaUtils.getProductAttributes(attributes)
    const { productType, frontColor, lensColor, lensColorFacet } = getAttributes

    switch (productType) {
      case PRODUCT_TYPES_MAP.accessories:
      case PRODUCT_TYPES_MAP.optical:
        return frontColor
      case PRODUCT_TYPES_MAP.sun:
      case PRODUCT_TYPES_MAP.electronics:
        const lensColorCrl = lensColor || lensColorFacet || ''
        const frontColorCrl = frontColor || ''
        return frontColorCrl === lensColorCrl
          ? frontColorCrl
          : frontColor
          ? `${frontColorCrl} ${SLASH_CHARACTER} ${lensColorCrl}`.trim()
          : lensColorCrl

      default:
        return ''
    }
  }
  getBadges = (attributes: ProductAlgolia['attributes_translated']) => {
    const plpBadges = useSelector(plpBadgesSelector)!
    const isRXEnabled = useSelector(addPrescriptionLensesSelector)
    const getPriorityBadgeText = (badges: ProductBadges): string => {
      switch (true) {
        /*  case badges.isAvantPremiere:
          return t('ProductTile.Labels.avantPremiere')*/
        case badges.isLimitedEdition:
          return 'limitedEdition'
        case badges.isExclusive:
          return 'exclusive'
        case badges.clIsExclusive:
        case badges.isOnlineExclusive:
          return 'onlineExclusive'
        case badges.isNew:
        case badges.clIsNewArrival:
          return 'new'
        case badges.isMostPopular:
          return 'mostPopular'
        default:
          return ''
      }
    }

    const controlBadge = (plpBadge, attribute: string) => {
      return (
        !!plpBadge &&
        !!attribute &&
        plpBadge.toLowerCase() === attribute.toLowerCase()
      )
    }
    const getAttributes = ProductAlgoliaUtils.getProductAttributes(attributes)
    const {
      avantPremiere,
      badge,
      isNew,
      limitedEdition,
      webExclusive,
      exclusive,
      roxable,
      customizable,
      poralized,
      isMostPopular,
      sustainabilityClaim,
      lensTreatment,
      gender,
      clIsNewArrival,
      clIsExclusive,
    } = getAttributes

    const badges: ProductBadges = {
      isBadge: controlBadge(plpBadges.BADGE, badge),
      isNew: controlBadge(plpBadges.IS_NEW, isNew),
      isLimitedEdition: controlBadge(plpBadges.LIMITED_EDITION, limitedEdition),
      isOnlineExclusive: controlBadge(plpBadges['WEB EXCLUSIVE'], webExclusive),
      isExclusive: controlBadge(plpBadges.EXCLUSIVE, exclusive),
      isAvantPremiere: controlBadge(plpBadges.AVANT_PREMIERE, avantPremiere),
      isRoxable: controlBadge(plpBadges.ROXABLE, roxable) && isRXEnabled,
      isCustomizable: controlBadge(plpBadges.CUSTOMIZABLE, customizable),
      isPolarized: controlBadge(plpBadges.POLARIZED, poralized),
      isMostPopular: controlBadge(
        plpBadges.LX_IS_MOST_POPULAR_TRUE,
        isMostPopular
      ),
      isSustainable: controlBadge(
        plpBadges.SUSTAINABILITY_CLAIM,
        sustainabilityClaim
      ),
      isKids: gender?.toUpperCase() === PRODUCT_ATTRIBUTE_CHILD_VALUE,
      isPhotochromic: PRODUCT_ATTRIBUTE_PHOTOCHROMIC_VALUE.includes(
        lensTreatment?.toUpperCase()
      ),
      /* isTransitions:
        lensTreatment?.toUpperCase() === PRODUCT_ATTRIBUTE_TRANSITION_VALUE,*/
      isBlueLightFilter:
        lensTreatment?.toUpperCase() ===
        PRODUCT_ATTRIBUTE_BLUE_LIGHT_FILTER_VALUE,
      clIsNewArrival: controlBadge(plpBadges.CL_IS_NEWARRIVAL, clIsNewArrival),
      clIsExclusive: controlBadge(plpBadges.CL_IS_EXCLUSIVE, clIsExclusive),
    }
    const primaryBadge = getPriorityBadgeText(badges)

    //const currentLensTreatmentFacet = lensTreatmentFacet
    const activeBadges = Object.keys(
      pickBy(
        {
          Polarised: badges.isPolarized,
          BlueLightFilter: badges.isBlueLightFilter,
          Photochromic: badges.isPhotochromic,
          //Transitions: badges.isTransitions,
          Sustainable: badges.isSustainable,
          Kids: badges.isKids,
        },
        (cb) => cb
      )
    )

    const secondaryBadge = (
      activeBadges.length > 2 ? activeBadges.slice(0, 2) : activeBadges
    ).join(' | ')

    return { primaryBadge, secondaryBadge }
  }
}

export default new ProductTileAlgoliaUtils()
