import React, { useEffect, useState } from 'react'
import {
  ContentLandingTitle,
  LandingPageTitle,
  WrapperLandingTitle,
} from '../CmsPages/LadingPage/LandingPage'
import { useTranslation } from 'react-i18next'
import {
  TrackOrderButton,
  TrackOrderDetailsAuthenticationWrapper,
  TrackOrderDetailsWrapper,
  TrackOrderErrorMessage,
  TrackOrderFieldsAndErrorsWrapper,
  TrackOrderFieldsWrapper,
  TrackOrderFormWrapper,
  TrackOrderTextFieldContent,
} from './TrackOrder.style'
import { Button, StyledTypography, TextField } from '@components/UI'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import {
  OrderTrackingPayload,
  useTrackingOrderMutation,
} from '../../features/order/query_new'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import { loginStatusSelector, userIdSelector } from '../../redux/selectors/user'
import { IOrderDetails, OrderItem } from '../../types/order'
import { getGuestIdentity } from '../../features/user/thunks'
import OrderDetails from '../OrderHistory/components/OrderDetails'
import SignInDialog from '@components/SignInDialog'
import {
  setOpenModalRegistration,
  setOpenModalSignIn,
} from '../../features/ui/action'
import { OrderDetailsHeader } from '../OrderHistory/OrderHistory.style'
import RegistrationDialog from '@components/RegistrationDialog'
import {
  ITEM_DETAILS_PROFILE_NAME,
  PROFILE_NAME_PARAM,
} from '../../constants/common'
import { useGetProductDetailsByIdQuery } from '../../features/product/query'
import { Product } from '../../types/product'
import { useGetHclPageQuery } from '../../features/cms/query'
import PlacementLayout from '@components/Cms/PlacementLayout'
import { sendStaticEvent } from '../../foundation/analytics/tealium/lib'
import { useAnalyticsData } from '../../foundation/analytics/hooks/useAnalyticsData'

interface TrackOrderFormData {
  orderNumber: string
  email: string
}

const TrackOrder: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const cmsAnalyticsData = useAnalyticsData('page')
  const loginStatus: boolean = useSelector(loginStatusSelector) || false
  const { data, isLoading: isLoadingCms } = useGetHclPageQuery('traccia-ordine')
  const placements = data?.commercePlacements! || []
  const { orderDetails, triggerTrackingOrder, isLoading } = useOrderDetails()

  /*const orderId = orderDetails?.orderId || ''
  const email = orderDetails?.orderItem?.at(0)?.email1 || ''
  const { data } = useCheckRegisterEmailQuery(
    { orderId: orderId, email: email },
    { skip: !orderId && !email }
  )*/

  const handleTrackingOrder = async (payload: OrderTrackingPayload) => {
    //return await trackingOrder(payload).unwrap()
    return await triggerTrackingOrder(payload).unwrap()
  }

  useEffect(() => {
    sendStaticEvent({
      common: cmsAnalyticsData,
      page_Section1: 'Other',
      page_Section2: 'TrackMyOrder',
    })
  }, [placements.length])

  return (
    <>
      <WrapperLandingTitle>
        <ContentLandingTitle>
          <LandingPageTitle variant={'h1'}>
            {t('TrackOrder.Labels.Title')}
          </LandingPageTitle>
        </ContentLandingTitle>
      </WrapperLandingTitle>
      {orderDetails ? (
        <>
          <TrackOrderDetailsWrapper>
            {!loginStatus && (
              <TrackOrderDetailsAuthenticationWrapper>
                <TrackOrderButton
                  data-description={encodeURIComponent(
                    t('OrderConfirmation.Msgs.LoginCta')
                  )}
                  data-element-id="X_X_SignIn_Open"
                  onClick={() => dispatch(setOpenModalSignIn(true))}
                >
                  {t('OrderConfirmation.Msgs.LoginCta')}
                  {'\u00A0'}
                </TrackOrderButton>
                {t('OrderConfirmation.Msgs.OrAlt')}
                {'\u00A0'}
                <TrackOrderButton
                  style={{ textTransform: 'lowercase' }}
                  data-description={encodeURIComponent(
                    t('OrderConfirmation.Msgs.CreateAnAccount')
                  )}
                  data-element-id="X_X_NewAccount_Open"
                  onClick={() => dispatch(setOpenModalRegistration(true))}
                >
                  {t('OrderConfirmation.Msgs.CreateAnAccount')}
                </TrackOrderButton>
                {'\u00A0'}
                {t('TrackOrder.Labels.SyncYourPurchase')}
              </TrackOrderDetailsAuthenticationWrapper>
            )}
            <OrderDetailsHeader>{t('Order.OrderDetails')}</OrderDetailsHeader>
            <OrderDetails trackingOrderDetails={orderDetails} />
          </TrackOrderDetailsWrapper>
          <SignInDialog />
          <RegistrationDialog />
        </>
      ) : (
        <>
          <PlacementLayout placements={placements} loading={isLoadingCms} />
          <TrackOrderForm
            trackingOrder={handleTrackingOrder}
            isLoading={isLoading}
          />
        </>
      )}
    </>
  )
}

const TrackOrderForm: React.FC<{
  trackingOrder: (payload: OrderTrackingPayload) => Promise<IOrderDetails>
  isLoading: boolean
}> = ({ trackingOrder, isLoading }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const loginStatus: boolean = useSelector(loginStatusSelector) || false
  const userId: string = useSelector(userIdSelector) || ''
  const [errorResponseEnabled, setErrorResponseEnabled] =
    useState<boolean>(false)

  const schema = yup.object().shape({
    orderNumber: yup.string().required(t('TrackOrder.Errors.EmptyOrderNumber')),
    email: loginStatus
      ? yup.string().email(t('TrackOrder.Errors.InvalidEmail'))
      : yup
          .string()
          .email(t('TrackOrder.Errors.InvalidEmail'))
          .required(t('TrackOrder.Errors.EmptyEmail')),
  })

  const { handleSubmit, control } = useForm<TrackOrderFormData>({
    defaultValues: {
      orderNumber: '',
      email: '',
    },
    resolver: yupResolver(schema),
  })

  const onSubmit: SubmitHandler<TrackOrderFormData> = async (data) => {
    try {
      setErrorResponseEnabled(false)

      if (!userId) await dispatch(getGuestIdentity())

      const trackingOrderPayload: OrderTrackingPayload = {
        orderId: data.orderNumber.trim(),
      }

      if (data.email)
        trackingOrderPayload.email = data.email.toLowerCase().trim()

      await trackingOrder(trackingOrderPayload)
    } catch (e) {
      setErrorResponseEnabled(true)
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <TrackOrderFormWrapper>
        <TrackOrderFieldsAndErrorsWrapper>
          <TrackOrderFieldsWrapper>
            <TrackOrderTextFieldContent>
              <Controller
                name="orderNumber"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="none"
                      name="orderNumber"
                      label={t('TrackOrder.Labels.OrderNumber')}
                      value={value}
                      showvalidationstatus
                      onChange={onChange}
                      helperText={error ? error.message : null}
                      error={!!error || errorResponseEnabled}
                      isvalid={
                        !error && !!value?.length && !errorResponseEnabled
                      }
                    />
                  )
                }}
              />
            </TrackOrderTextFieldContent>
            <TrackOrderTextFieldContent>
              <Controller
                name="email"
                control={control}
                //rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="none"
                      name="email"
                      label={t('TrackOrder.Labels.Email')}
                      value={value}
                      showvalidationstatus
                      type="email"
                      onChange={onChange}
                      helperText={error ? error.message : null}
                      error={!!error || errorResponseEnabled}
                      required={true}
                      isvalid={
                        !error && !!value?.length && !errorResponseEnabled
                      }
                    />
                  )
                }}
              />
            </TrackOrderTextFieldContent>
          </TrackOrderFieldsWrapper>
          {errorResponseEnabled && (
            <TrackOrderErrorMessage>
              <StyledTypography fontSize={12}>
                {t('TrackOrder.Errors.Generic')}
              </StyledTypography>
            </TrackOrderErrorMessage>
          )}
        </TrackOrderFieldsAndErrorsWrapper>
        <Button
          data-element-id="TrackMyOrder_FindMyOrder"
          data-description="Trovailmioordine"
          style={{ marginRight: 'auto' }}
          type="submit"
          loading={isLoading}
          disabled={isLoading}
        >
          {t('TrackOrder.Labels.Submit')}
        </Button>
      </TrackOrderFormWrapper>
    </form>
  )
}

export default TrackOrder

const useOrderDetails = () => {
  /*const {
    data: findOrderByIdData,
    isLoading: isLoadingOrderById,
    isFetching,
  } = useFindOrderByIdQuery(
    { orderId: orderId || '', skipErrorSnackbar: true },
    { skip: !orderId }
  )*/

  const [
    triggerTrackingOrder,
    { data: trackingOrderData, isLoading: isLoadingTrackingOrder },
  ] = useTrackingOrderMutation()

  const orderDetails = trackingOrderData || undefined
  const orderItem = orderDetails?.orderItem || []

  //getPorductData
  const id = [...new Set(orderItem.map((i) => i.productId))]
  const parameters = {
    [PROFILE_NAME_PARAM]: ITEM_DETAILS_PROFILE_NAME,
    id: id,
  }
  const { data: getProductDetails, isLoading: isLoadingProducts } =
    useGetProductDetailsByIdQuery(parameters, {
      skip: !id.length,
    })

  const getOrderItemWithProducts = (
    orderItem: IOrderDetails['orderItem'],
    products: Product[]
  ) => {
    return orderItem.map((item) => mapProductDetailsToOrderItem(item, products))
  }

  const mapProductDetailsToOrderItem = (
    item: OrderItem,
    products: Product[]
  ) => {
    const product =
      products.find(
        (product) => String(product.id) === String(item.productId)
      ) || ({} as Product)
    return assignProductDetailsToOrderItem(item, product)
  }

  const assignProductDetailsToOrderItem = (
    item: OrderItem,
    product: Product
  ) => {
    const {
      name = '',
      productAttributes = {},
      attachments = [],
      seo = { href: '' },
      attributes = [],
    } = product
    return {
      ...item,
      name,
      productAttributes,
      attributes,
      attachments,
      seo,
    }
  }
  //OderDetails with products
  let orderDetailsWithProducts: IOrderDetails | undefined
  if (orderDetails && getProductDetails) {
    orderDetailsWithProducts = {
      ...orderDetails,
      detailedOrderItems: getOrderItemWithProducts(
        orderItem || [],
        getProductDetails || []
      ),
    }
  }
  return {
    triggerTrackingOrder,
    orderDetails: orderDetailsWithProducts,
    isLoading: Boolean(isLoadingTrackingOrder || isLoadingProducts),
  }
}
