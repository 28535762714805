import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledWrapper = styled('div', {
  name: 'SizeGuide',
  slot: 'Wrapper',
})(({ theme }) => ({
  padding: theme.spacing(8, 4),

  background: theme.palette.color.grey.lightest,
  color: theme.palette.text.dark,

  h3: {
    fontSize: 28,
    fontWeight: 600,
    lineHeight: 1.4,
    marginBottom: theme.spacing(6),
    textAlign: 'center',
  },
}))

const StyledContainer = styled('div', {
  name: 'SizeGuide',
  slot: 'Container',
})(({ theme }) => ({
  fontSize: 14,
  fontWeight: 'normal',
  lineHeight: 1.43,

  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(10.75, 22, 17.25, 16),

    display: 'grid',
    gridTemplateColumns: '25% 60% 1fr',
  },
}))

const StyledGuideBody = styled('div', {
  name: 'SizeGuide',
  slot: 'Body',
})(({ theme }) => ({
  width: '100%',

  h4: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.5,

    margin: 0,
    marginBottom: theme.spacing(1),
  },
}))

const StyledSizeData = styled('div', {
  name: 'SizeGuide',
  slot: 'Data',
})(({ theme }) => ({
  marginBottom: theme.spacing(6),
}))

const StyledConverterContainer = styled('div', {
  name: 'SizeGuide',
  slot: 'ConverterContainer',
})(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2.75),
  alignItems: 'center',
  marginBottom: theme.spacing(4),
}))

const StyledSizeHelper = styled('div', {
  name: 'SizeGuide',
  slot: 'Helper',
})(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

const StyledProgressContainer = styled('div', {
  name: 'SizeGuide',
  slot: 'ProgressContainer',
  shouldForwardProp: (prop) => prop !== 'secondSlide',
})<{ secondSlide: boolean }>(({ theme, secondSlide }) => ({
  height: 2,
  width: '50%',
  margin: theme.spacing(8, 'auto'),
  backgroundColor: theme.palette.primary.main,

  div: {
    height: 2,
    backgroundColor: theme.palette.primary.light,
    width: secondSlide ? '100%' : '50%',
    transition: 'width .25s',
  },
}))

const StyledSwiperContainer = styled('div', {
  name: 'SizeGuide',
  slot: 'SwiperContainer',
})(({ theme }) => ({
  marginBottom: theme.spacing(8),
}))

const StyledNavigationContainer = styled('div', {
  name: 'SizeGuide',
  slot: 'NavigationContainer',
})({
  position: 'relative',
})

const StyledNavigationButtons = styled(Button, {
  name: 'SizeGuide',
  slot: 'NavigationButtons',
  shouldForwardProp: (props) => props !== 'shouldShowButton',
})<{
  shouldShowButton?: boolean
}>(({ theme, shouldShowButton }) => ({
  fontSize: 14,
  fontWeight: 600,
  lineHeight: 1.43,
  color: theme.palette.text.dark,
  position: 'absolute',
  top: '50%',
  right: 0,
  transform: 'translateY(-50%)',
  display: 'none',

  [theme.breakpoints.up('sm')]: {
    display: shouldShowButton ? 'flex' : 'none',
  },
}))

export {
  StyledWrapper,
  StyledContainer,
  StyledGuideBody,
  StyledSizeData,
  StyledConverterContainer,
  StyledSizeHelper,
  StyledSwiperContainer,
  StyledProgressContainer,
  StyledNavigationContainer,
  StyledNavigationButtons,
}
