import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@mui/material'
import {
  LinkAsButton,
  StyledCheckbox,
  StyledFormControlLabel,
} from '../../../components/UI'
import { Contact } from '../../../types/user'
import useConfirm from '@hooks/useConfirm'
import {
  AddressBookContent,
  AddressBoxWrapper,
  AddressButtonWrapper,
  AddressCustomerName,
  AddressLineBreak,
  RemoveButtonWrapper,
  StyledSecondaryText,
  UserAddressBox,
  UserAddressWrapper,
  AddressDetails,
  AddressDetailsBottomLinkWrapper,
  RemoveAddressModalContainer,
  StyledRemoveButtonAsLink,
} from '../AddressBook.style'
import { RemoveAddressDialog } from '@components/RemoveAddressDialog'
import { generateAccountPagePath } from 'src/pages/Account/Account'
import { ACCOUNT_CHILDREN } from 'src/constants/routes'
import { useStoreIdentity } from 'src/foundation/hooks/useStoreIdentity'

interface IProps {
  address: Contact
  updateAddress?: (address: Contact) => void
  deleteAddress?: (nickName: string) => void
}

const UserAddress: FC<IProps> = ({ address, updateAddress, deleteAddress }) => {
  const { t } = useTranslation()
  const { langCode } = useStoreIdentity()

  const userAddressArray: string[] = useMemo(() => {
    if (!address) {
      return []
    }

    const { addressLine = [], zipCode, city, country, phone1 } = address

    return [
      `${addressLine[0] || ''}, ${city || ''} ${zipCode || ''},`,
      country,
      phone1 || '',
    ]
  }, [address])

  const { handleCancel, handleShow, handleOk, showConfirmationModal } =
    useConfirm()

  return (
    <AddressBookContent key={address.nickName}>
      <Modal
        open={showConfirmationModal}
        onClose={handleCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <RemoveAddressModalContainer>
          <RemoveAddressDialog
            userAddress={address}
            handleClose={handleCancel}
            handleOk={handleOk}
          />
        </RemoveAddressModalContainer>
      </Modal>
      <AddressBoxWrapper>
        <RemoveButtonWrapper>
          <AddressCustomerName>
            {t(`${address?.firstName} ${address?.lastName}`)}
          </AddressCustomerName>
          <AddressButtonWrapper>
            <LinkAsButton
              fillType={'outline'}
              data-element-id={address && 'X_X_AddressBook_Edit'}
              to={generateAccountPagePath(
                langCode,
                ACCOUNT_CHILDREN.EDIT_ADDRESS,
                {
                  addressId: address.addressId,
                }
              )}
            >
              {t('AddressBook.EditButton')}
            </LinkAsButton>
          </AddressButtonWrapper>
        </RemoveButtonWrapper>
        <AddressLineBreak />
        <AddressDetails>
          <UserAddressWrapper>
            {address && (
              <UserAddressBox>
                {userAddressArray.map((text) => (
                  <StyledSecondaryText>{text}</StyledSecondaryText>
                ))}
              </UserAddressBox>
            )}
          </UserAddressWrapper>
          <AddressDetailsBottomLinkWrapper>
            <StyledFormControlLabel
              control={<StyledCheckbox color="primary" />}
              label={
                address?.primary === 'true'
                  ? t('AddressBook.DefaultAddressMsg')
                  : t('AddressBook.SetAsDefaultAddressMsg')
              }
              checked={address?.primary === 'true'}
              onChange={() => {
                const defaultAddress = { ...address, primary: 'true' }
                !!updateAddress && updateAddress(defaultAddress)
              }}
            />
            <StyledRemoveButtonAsLink
              onClick={async () => {
                const confirm = await handleShow()
                confirm && !!deleteAddress && deleteAddress(address.nickName!)
              }}
            >
              {t('CartRecap.Actions.Delete')}
            </StyledRemoveButtonAsLink>
          </AddressDetailsBottomLinkWrapper>
        </AddressDetails>
      </AddressBoxWrapper>
    </AddressBookContent>
  )
}

export default UserAddress
