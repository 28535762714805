import { AxiosResponse } from 'axios'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import guestIdentityService from 'src/foundation/apis/transaction/guestIdentity.service'
import { GUEST_LOGIN_SUCCESS_ACTION } from 'src/redux/actions/user'
import { AppState } from 'src/redux/store'

export const getGuestIdentity =
  (): ThunkAction<void, AppState, unknown, Action<any>> => async (dispatch) => {
    const payload = { widget: 'axiosConfig' }
    const guestIdentity: AxiosResponse = await guestIdentityService.login(
      payload
    )
    dispatch(GUEST_LOGIN_SUCCESS_ACTION({ ...guestIdentity.data, ...payload }))
  }
