//TODO: remove this until product model is normalized everywhere
// @ts-nocheck
import {
  ServerProduct,
  ServerProductPrice as ProductPriceType,
} from '../../../../../../types/product'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ProductUtils from '@utils/ProductUtils'
import { styled } from '@mui/material/styles'
import FormattedPriceDisplay from '@components/formatted-price-display'

const WrapperCmsShoppableProductPrice = styled('div', {
  name: 'CmsShoppableProductPrice',
  slot: 'Wrapper',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.text.dark,
  lineHeight: 1.33,
  alignItems: 'center',
}))

const ContentInitialPrice = styled('div', {
  name: 'CmsShoppableProductPrice',
  slot: 'ContentInitial',
})(() => ({
  display: 'flex',
  fontSize: 10,
}))

const InitialPrice = styled('div', {
  name: 'CmsShoppableProductPrice',
  slot: 'InitialPrice',
})(() => ({
  textDecoration: 'line-through',
}))

const ContentCurrentPrice = styled('div', {
  name: 'CmsShoppableProductPrice',
  slot: 'ContentCurrent',
})(() => ({
  display: 'flex',
  fontSize: 12,
}))

const CurrentPrice = styled('div', {
  name: 'CmsShoppableProductPrice',
  slot: 'CurrentPrice',
})(() => ({}))

const ProductPriceTextFrom = styled('span', {
  name: 'CmsShoppableProductPrice',
  slot: 'TextFrom',
})({
  display: 'flex',
  alignItems: 'center',
  fontSize: 10,
})

type PriceModel = Pick<ProductPriceType, 'currency' | 'value' | 'usage'>

const CmsShoppableProductPrice: React.FC<{ price: ServerProduct['price'] }> = ({
  price,
}) => {
  const { t } = useTranslation()

  const currentPrice = useMemo(
    () => price && ProductUtils.getCurrentPriceModel(price),
    [price]
  )
  const initialPrice = useMemo(
    () => price && ProductUtils.getInitialPriceModel(price),
    [price]
  )

  const getDiscount = useCallback(
    (current: PriceModel, initial: PriceModel): number => {
      if (!(current && initial && current.value && initial.value)) {
        return 0
      }

      const numberCurrent = Number(current.value)
      const numberInitial = Number(initial.value)

      if (!isNaN(numberCurrent) && !isNaN(numberInitial)) {
        return ((numberInitial - numberCurrent) / numberInitial) * 100
      } else {
        return 0
      }
    },
    []
  )

  const discountAmount = useMemo<number>(() => {
    if (!(currentPrice && initialPrice)) {
      return 0
    }

    return getDiscount(currentPrice, initialPrice)
  }, [currentPrice, initialPrice])

  const hasInitialPriceDiscount = !!(
    discountAmount &&
    initialPrice &&
    initialPrice?.value &&
    +initialPrice.value > 0
  )
  return (
    <WrapperCmsShoppableProductPrice>
      {hasInitialPriceDiscount && (
        <ContentInitialPrice>
          <ProductPriceTextFrom>
            {t('ProductTile.Labels.from')}&nbsp;
          </ProductPriceTextFrom>
          <InitialPrice>
            <FormattedPriceDisplay
              min={+initialPrice.value}
              currency={initialPrice.currency}
            />
          </InitialPrice>
        </ContentInitialPrice>
      )}
      <ContentCurrentPrice>
        {!hasInitialPriceDiscount && (
          <ProductPriceTextFrom>
            {t('ProductTile.Labels.from')}&nbsp;
          </ProductPriceTextFrom>
        )}
        <CurrentPrice>
          <FormattedPriceDisplay
            min={+currentPrice?.value}
            currency={currentPrice?.currency}
          />
        </CurrentPrice>
      </ContentCurrentPrice>
    </WrapperCmsShoppableProductPrice>
  )
}

export default CmsShoppableProductPrice
