import * as PaymentMethod from '../../constants/paymentMethods'

import { CountryStates, StatesProvinces } from '../../types/common'

import { CheckoutAddressFormField } from '../../types/checkout'
import { IOrderDetails } from '../../types/order'
import { ISeo } from '../../types/seo'
import { BooleanString, ServerProduct } from '../../types/product'
import { UserDetails } from '../../types/user'

export interface ErrorReducerState {
  errorKey?: string | null
  errorCode?: string | null
  errorTitleKey?: string | null
  errorMsgKey?: string | null

  [extraProps: string]: any
}

export interface AccountReducerState {
  orders: any | null
  isFetching: boolean
  countries?: CountryStates[]
  statesProvinces?: StatesProvinces[]
}

export interface CatalogReducerState {
  productList: ServerProduct[] //Stores list of product objects for the current category/search
  productListTotal: number //Stores total number of products in the current category/search
  priceMode: string //Price mode returned from catalog services
  facets: any[] | null //Stores list of all facet objects (including price facet) associated to the current category/search
  baseFacets: any[] | null
  selectedFacets: {
    [key: string]: { value: string; facetName: string }
  } //Stores map of selected facets for the current category/search. Format is "[facetEntryValue]: facetEntryLabel"
  selectedFacetLimits: string[] //Stores list of selected facet limit values for the current category/search. Format is "facetvalue:-1"
  selectedFacetPrices: {
    min: number
    max: number
  } //Stores the selected min/max price range for the current category/search. Persisted values should always be valid
  selectedPageOffset: number //Stores the selected pagination offset value for the current category/search
  defaultSortOption: SingleSortOrderOption
  selectedSortOption: SingleSortOrderOption //Stores the selected sort option value for the current category/search
  profileName: string | null
  breadcrumbs: any[]
  loading?: boolean
  facetsUpdating?: boolean
}

export type Catentry = ServerProduct & {
  disallowRecurringOrder: string
  thumbnail: string | undefined
  x_offerpriceRx?: string
  x_offerDiscountpriceRx?: string
  x_priceBadge?: string
}

export type Catentries = Record<string, Catentry>

export enum AuthenticationErrorType {
  login = 'LOGIN',
  register = 'REGISTER',
}

export interface PaypalExpressStatus {
  decision?: string
  error: boolean
  isSelected: boolean
  loading: boolean
  orderId?: string
  redirecturl?: string
}

export interface UserReducerState {
  WCToken: string
  WCTrustedToken: string
  initiatedFromStorage?: boolean
  lastUpdated?: number
  details?: UserDetails | null
  areDetailsLoading?: boolean
  errorType?: AuthenticationErrorType | null

  [extraProps: string]: any
}

export interface SearchReducerState {
  keywords: string[]
}

export interface SEOReducerState {
  [url: string]: ISeo
}

export interface ContractReducerState {
  [extraProps: string]: string
}

export interface OrganizationReducerState {
  entitledOrganizations: any
  organizationDetails: any

  [extraProps: string]: any
}

export interface ContextReducerState {
  audit?: {
    personalizationId: string
  }
  globalization: {
    preferredCurrency: string
    languageId: number
    currency: string
    preferredLanguageId: number
  }
  catalog: {
    catalogId: number
    masterCatalog: boolean
  }
  entitlement: {
    eligibleTradingAgreementIds: string[]
    hostingContractId: string
    currentTradingAgreementIds: string[]
    activeOrganizationId: string
    sessionTradingAgreementIds: any
  }
  activityToken?: {
    activityId: number
  }
  isPartiallyAuthenticated: false
  basicInfo: {
    runAsId: number
    callerId: number
    registerType: string
    storeId: number
    channelId: number
  }
}

/**
 * The interface of success message object
 */
export interface SuccessMessageReducerState {
  /**
   * Represents the type of message, and it is also
   * served as part of message key in translation file
   */
  key: string
  /**
   * A name value parameter map of string that to be used as token
   * in translation file.
   */
  messageParameters?: {
    [extraProps: string]: string
  }
  /**
   * The React route url that needs to displayed as part of message
   */
  link?: {
    url: string
    textKey: string
  }
}

export interface ConfirmationReducerState {
  /**
   * Represents the type of message, and it is also
   * served as part of message key in translation file
   */
  key: string
  /**
   * A name value parameter map of string that to be used as token
   * in translation file.
   */
  messageParameters?: {
    [extraProps: string]: string
  }
  /**
   * The text or text key used for confirmation dialog title
   */
  title: string
  /**
   * The React route url that needs to displayed as part of message
   */
  okAction?: Function
  cancelAction?: Function
}

export interface RecurringOrderReducerState {
  resultList: any[]
}

export interface IPlpFacets {
  accessories: string[]
  contactLenses: string[]
  contactLensesAccessories: string[]
  frames: string[]
  optical: string[]
  sun: string[]
}

interface RXConfPropertyBasedOnBrand {
  [key: string]: {
    [key: string]: BooleanString
  }
}

export interface IXStoreConfig {
  addPrescriptionLenses: string
  MostUsedEmailDomains: string[]
  allowedPromosLimit: string
  availablePaymentMethods: {
    name: PaymentMethod.CheckoutName
    sequence: number
    enabledInCartShipToStore: string
    enabledShipToStore: string
  }[]
  availableShippingMethods: {
    name: string
    sequence: string
  }[]
  chatGenesysEnabled: boolean
  chatGenesysDeploymentId: string
  isSocialLoginEnabled: {
    facebook: string
    google: string
    global: string
  }
  AVAIL_SHIP_DAYS_PLANO_Standard: number
  badges: IBadges
  billingAddressFieldsList: CheckoutAddressFormField[]
  BookAnEyeUrl: string
  brand: string
  calculateTaxEnabled: string
  creditCardTypes: string
  customerServiceSupportEmailAddress: string
  customerServiceSupportPhoneNumber: string
  expireLimit: number
  GOOGLE_API_ENABLED: {
    isEnabled: string
    isEnabledOnShipping: string
    isEnabledOnBilling: string
    isEnabledOnAddressBook: string
  }
  googleApiKey: string
  hostname: string
  logonIdPrefix: string
  MAN_DAYS_RX: number
  monetateEnabled: boolean
  monetateURL: string
  optInBoxes: boolean[]
  PayerAuthenticationReturnUrl: string
  paymentMethods: PaymentMethod.FooterName[]
  pdpAccImageOrder: number[]
  pdpClImageOrder: number[]
  pdpFrameImageOrder: number[]
  pdOptionValues: string
  plpDashButtonsFacet: Record<string, string>
  /**
   * key: category identifier; value: array of objects with facet data.
   * Used for suggested PLP filters.
   *
   * Example: `{ dc_brand: [{ FRAME_SHAPE_FACET: "Square", FRAME_MATERIAL_FACET: "Acetate" }] }`
   */
  plpDashfacet: Record<string, Record<string, string>[]>
  /**
   * Facet names for different product types, which should be displayed on PLP. [DCW-61]
   * Facet names in arrays also reflect the order to follow on PLP (as PLP filters). [DCW-1017]
   */
  plpFacet: IPlpFacets
  plpPageSize: number
  /**
   * Shows Price filter in all the categories, except the ones from `{plpPriceFacets}` array (DCW-4448).
   *
   * Example: `['dc_contactlenses_acc', 'dc_contactlenses_lenstype_Single Vision', 'dc_contactlenses_brand_DAILIES']`.
   */
  plpPriceFacets: string[]
  prescriptionFileLocation: string
  prescriptionModes: string
  prop65WarningStates: string
  roxPDValues: string
  roxPrescriptionObject: string
  RX_SCRIPT_SRC: string
  RX_STYLE_SRC: string
  secureAcceptancePaymentFailureUrl: string
  secureAcceptancePaymentSuccessUrl: string
  serviceLinks: string
  shippingAddressFieldsList: CheckoutAddressFormField[]
  SHIPPING_DAYS_STANDARD_RX: number
  soldOutExpiredTime: string
  sortorderOptions: SortOrderOptions
  stoppedProductionBrand: string
  uploadRXFileOnOrderConfirm: string
  VERTEX_ENABLED: string
  WELCOME_NEWSLETTER_PROMO_NAME: string
  WELCOME_SIGN_UP_PROMO_NAME: string
  wishlistEnabled: boolean
  WISHLIST_RETURN_PRODUCT_DETAILS: boolean
  FORTER_SITE_ID: string
  is3dsEnabled: boolean
  plpRadioButtonFacets: string[]
  showBundlePrice: RXConfPropertyBasedOnBrand
  largeIconLayout: RXConfPropertyBasedOnBrand
  enableDesignTypeStep: RXConfPropertyBasedOnBrand
  storeLocatorScriptSrc: string
  isStoreLocatorEnabled: boolean
  frameGenius: FrameGeniusConfig
  shortCodes: ShortCodesConfig
  /* isTrustPilotEnabled: boolean
  trustPilotBusinessUnitID: string
  trustPilotScriptSrc: string*/
  trustPilot: TrustPilotConfig
  webFormLibs?: {
    css?: string
    js?: string
  }
  wishlistConfiguration: {
    globalEnabled: string
    guestEnabled: string
    loggedEnabled: string
    notAllowedProductTypes: []
  }
  VM_AVAILABILITY_SERVER: string
  VM_SCRIPT_SRC: string
  klarnaScriptSrc: string
  klarnaClientId: string
  klarnaServicesScriptUrl: string
  TAB_SCRIPT_SRC: string
  TAB_STORE_DETAILS_URL: string
  TAB_STORE_LOCATOR_URL: string
  omnibusRegulationEnabled: boolean
  customerSegmentsEnabled: boolean
  customerSegmentsCookiePriority: string[]
  customerSegmentsCookieExpiry: number
}

export interface ParsedServiceLinks {
  terms: string
  privacy: string

  [key: string]: string
}

interface ShortCodesConfig {
  CUSTOMER_CARE_EMAIL: string
  enableShortCodes: string
  SHIPPING_DAYS_FRAME_STANDARD: string
  CUSTOMER_CARE_PHONE: string
  SHIPPING_DAYS_FRAME_GREEN: string
  SHIPPING_DAYS_ROX_GREEN: string
  SHIPPING_DAYS_STANDARD_SOLUTIONS: string
  SHIPPING_DAYS_ROX_STANDARD: string
  CUSTOMER_CARE_HOURS_DAY_PHRASE: string
  SHIPPING_DAYS_STANDARD_CL: string
  PAYMENT_METHODS: string
  HOLIDAY_RETURN_DAYS: string
  RETURN_DAYS: string
  SHIPPING_STANDARD_COST_TRESHOLD: string
}

export interface TrustPilotConfig {
  /*isTrustPilotEnabled: boolean
  trustPilotBusinessUnitID: string
  trustPilotScriptSrc: string*/
  trustpilotWidgetEnabled: boolean
  trustpilotWidgetEnabled_Footer: boolean
  trustpilotWidgetEnabled_PDP: boolean
}
interface FrameGeniusConfig {
  isFrameAdvisorEnabled: boolean
  scriptUrl: string
  isSizeAdvisorEnabled: boolean
}
export interface GlobalXStoresCfg {
  allStoreXConf?: any[]
  paypalReturnUrl?: string
  paypalCancelUrl?: string
  cartMaxItems?: number
  damDomain?: string
  newsletterType?: string
  paypalSecureAcceptanceUrl?: string
  customerSegmentsEnabled?: string
  customerSegmentsCookiePriority?: string[]
  customerSegmentsCookieExpiry?: number
}

export interface SiteInfo {
  storeName: string
  storeID: string
  catalogID: string
  defaultLanguageID: string
  defaultCurrencyID: string
  country: string
  locale: string
  prefix: string
  searchContext: string
  transactionContext?: string
  dxContext?: string
  storeType: string
  isB2B: boolean
  inventorySystem: number
  storeCfg: any
  supportedLanguages: any[]
  enableGA?: boolean
  enableUA?: boolean
  enableGA4?: boolean
  xStoreCfg: IXStoreConfig
  xStoresCfg?: any[]
  paypalReturnUrl?: string
  paypalCancelUrl?: string
  cartMaxItems?: number
  damDomain: string
  newsletterType: string
  paypalSecureAcceptanceUrl: string
  [extraPros: string]: any
}

export interface OrderDetailsReducerState {
  data: { [orderId: string]: IOrderDetails }
  loadings: string[] // array with orderIds that are being fetched
}

export interface SiteReducerState {
  currentSite: SiteInfo | null
}

export interface ApiReducerState {
  apiFlowList: string[] //Tracks list of API calls for development use only. State will exist in Production but will not be used.
}

export interface UIReducerState {
  filtersDrawerOpen: boolean
  cartSummaryDrawerOpen: boolean
  searchDrawerOpen: boolean
  newsletterDrawerOpen: boolean
  productNotificationAvailable: boolean
  myAccountDrawerOpen: boolean
  currentIdentifier: string
  pageType: string
  lastSelectedProduct: string
  promoBar: PromotionBar
}

export interface ThemeReducerState {
  themeMode: string
}

export interface IBadges {
  //text badges
  IS_NEW: string
  BADGE: string
  LIMITED_EDITION: string
  SPECIAL_PROJECT_FLAG: string
  ['WEB EXCLUSIVE']: string
  EXCLUSIVE: string
  AVANT_PREMIERE: string
  LX_IS_MOST_POPULAR_TRUE: string
  CL_IS_NEWARRIVAL: string
  CL_IS_EXCLUSIVE: string
  //icon badges
  POLARIZED: string
  SUSTAINABILITY_CLAIM: string
  ROXABLE: string
  CUSTOMIZABLE: string
}

export type SortOrderOptions = {
  [key: string]: SingleSortOrderOption
}

export type SingleSortOrderOption = {
  value: string | (string | null)[]
  profileName: string | (string | null)[]
}

export type PromotionBar = {
  active: boolean
  height: number
}
