import React, { useState } from 'react'
import { BoxWithMarginText } from './components/BoxWithMarginText'
import { BoxWithMarginContainer } from './BoxWithMarginBanner.style'
import BoxWithMarginMediaOverlap from './components/BoxWithMarginMediaOverlap'
import { ILXTeaser } from '../../../../../types/cmsPlacement/LXTeaser'
import { CmsTermsAndConditionPanel } from '../../../CmsComponents/CmsTermsAndConditions'
import { ToastProp } from '../../../../UI/Toast/Toast.type'
import { IBoxWithMarginProps } from './BoxWithMarginBanner'
import { isLXTeaser } from '../../../../../types/cmsPlacement/Placement'

const BoxWithMarginBannerMobile: React.FC<IBoxWithMarginProps> = ({
  placement,
  teaserIndex,
}) => {
  const viewType = placement.viewtype || 'default'
  const crop = 'BOX_WITH_MARGIN_BANNER'

  const item =
    'items' in placement
      ? placement?.items?.find(isLXTeaser)
      : placement?.teasableItems?.find(isLXTeaser)

  const itemHasTermsAndCondition = !!(item as ILXTeaser)
    ?.targetsTermsAndConditions
  const [isTermsAndConditionOpen, setIsTermsAndConditionOpen] =
    useState<ToastProp['open']>(false)

  return (
    <BoxWithMarginContainer
      termsAndConditionsVisible={itemHasTermsAndCondition}
    >
      <BoxWithMarginMediaOverlap
        crop={crop}
        viewType={viewType}
        teaserIndex={teaserIndex}
        bannerIndex={1}
        teaser={item}
      />
      <BoxWithMarginText
        teaserIndex={teaserIndex}
        bannerIndex={1}
        teaser={item}
        viewType={viewType}
        termsAndConditionVisible={itemHasTermsAndCondition}
        onTermsAndContitionButtonClick={() => setIsTermsAndConditionOpen(true)}
      />
      {itemHasTermsAndCondition ? (
        <CmsTermsAndConditionPanel
          teaser={item as ILXTeaser}
          open={isTermsAndConditionOpen}
          onClose={() => setIsTermsAndConditionOpen(false)}
        />
      ) : null}
    </BoxWithMarginContainer>
  )
}

export default BoxWithMarginBannerMobile
