import React, { useCallback } from 'react'
import {
  StoreInfoLeftColumn,
  StoreBadgeName,
  StoreBadgeText,
  StoreBadgePhone,
  IndicationCTAContainer,
  DirectionsCTALink,
  MapImage,
  StoreInfoRightColumn,
  StoreInfoContainer,
  StoreDetailsBookAppointmentButton,
  MarkerImage,
} from './StoreInfo.style'
import { useTranslation } from 'react-i18next'
import { MapMarkIcon } from '@components/UI/Icons'
import { generateStaticMapUrl } from '@utils/staticMapUrl'
import { useSelector } from 'react-redux'
import { googleApiKeySelector } from '../../../../redux/selectors/site'
import { createSearchParams, generatePath, useNavigate } from 'react-router-dom'
import { BOOKAPPOINTMENT } from '../../../../constants/routes'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import { Store } from '../../../../types/Tab/store'
import config from '@configs/config.base'
import { pascalCase } from '@utils/common'
const StoreInfo: React.FC<{ store: Store }> = ({ store }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { langCode } = useStoreIdentity()
  const googleMapsApiKey = useSelector(googleApiKeySelector)
  const markerGV = `${config.PUBLIC_URL}/images/common/marker.png`

  const isMarkerAvailable = store.latitude !== ''
  const staticMapUrl = generateStaticMapUrl(
    store,
    googleMapsApiKey,
    isMarkerAvailable
  )

  const onBookEyeTest = useCallback(() => {
    navigate({
      pathname: generatePath(`/:country/${BOOKAPPOINTMENT}`, {
        country: langCode,
      }),
      search: createSearchParams({
        storeNumber: store.storeId.toString(),
      }).toString(),
    })
  }, [navigate, langCode, store])

  const urlGoogleStore = `https://www.google.com/maps?q=GrandVision ${store.address}, ${store.city}`

  return (
    <StoreInfoContainer>
      <StoreInfoLeftColumn>
        <StoreBadgeName fontSize={28} fontWeight={'bold'}>
          {store.completeAddress}
        </StoreBadgeName>
        {store.telephone ? (
          <div>
            <StoreBadgeText
              fontSize={14}
              fontWeight={'bold'}
              lineHeight={'2.5'}
            >
              {t('StoreDetails.Labels.Phone')}
            </StoreBadgeText>
            <StoreBadgePhone
              data-element-id="OEE_CallNow"
              to={`tel:${store.telephone}`}
              external
            >
              {store.telephone}
            </StoreBadgePhone>
          </div>
        ) : null}
        {/*         <OpeningHoursContainer>
          <StoreBadgeText fontSize={14} fontWeight={'bold'} lineHeight={'2.5'}>
            {t('StoreDetails.Labels.OpeningTimes')}
          </StoreBadgeText>
          <StoreOpeningHours store={store} isBold={true} />
        </OpeningHoursContainer> */}
        <IndicationCTAContainer>
          <MapMarkIcon />
          <DirectionsCTALink
            data-element-id="OEE_StoreGetDirections"
            target="_blank"
            rel="noopener noreferrer"
            external
            to={urlGoogleStore}
          >
            {t('StoreDetails.Labels.Directions')}
          </DirectionsCTALink>
        </IndicationCTAContainer>
        <div>
          <StoreDetailsBookAppointmentButton
            data-element-id="OEE_BookNow"
            data-description={pascalCase(t('StoreLocator.Labels.BookEyeTest'))}
            onClick={onBookEyeTest}
          >
            {t('StoreDetails.CTA.BookAnAppointment')}
          </StoreDetailsBookAppointmentButton>
        </div>
      </StoreInfoLeftColumn>
      <StoreInfoRightColumn>
        <MapImage src={staticMapUrl} alt={store.storeName} />
        <MarkerImage src={markerGV} alt={store.storeName} />
      </StoreInfoRightColumn>
    </StoreInfoContainer>
  )
}

export default StoreInfo
