import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IWishlist } from '../../types/wishlist'

export type WishListState = Partial<Pick<IWishlist, 'GiftList' | 'x_data'>> & {
  isLoading: boolean
}

const initialState = {} as WishListState

export const wishListSlice = createSlice({
  name: 'wishList',
  initialState,
  reducers: {
    setWishList: (state, action: PayloadAction<WishListState>) => {
      return { ...state, ...action.payload }
    },
    removeWishList: () => {
      return initialState
    },
  },
})

export const { removeWishList, setWishList } = wishListSlice.actions
export default wishListSlice.reducer
