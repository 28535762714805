import React from 'react'
import { ProductAlgoliaPrice } from '../../../types/ProductAlgolia/ProductAlgolia'
import { useTranslation } from 'react-i18next'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import {
  ProductPriceContainerFinal,
  ProductPriceDiscountBoxFinal,
  ProductPriceInitialPriceFinal,
} from '@components/ProductPriceFinal/ProductPriceFinal.style'
import FormattedPriceDisplay from '@components/formatted-price-display'
import useBreakpoints from '@hooks/useBreakpoints'
import { useSelector } from 'react-redux'
import { productsMobileListingLayoutSelector } from '../../../features/ui/selector'
import ProductPriceAlgoliaUtils from '@utils/ProductAlgolia/ProductPriceAlgoliaUtils'
import {
  ProductPriceCurrentPricePlp,
  ProductPriceWrapperPlp,
} from '@components/ProductPriceFinal/components/ProductPricePlp.style'

interface ProductPricePlp {
  price: ProductAlgoliaPrice
  productType: string
}
const ProductPricePlp: React.FC<ProductPricePlp> = ({ price, productType }) => {
  const { isMobile } = useBreakpoints()
  const { t } = useTranslation()
  const productsMobileListingLayout = useSelector(
    productsMobileListingLayoutSelector
  )
  const isCompact = productsMobileListingLayout === 'compact'
  const { getLxProductTypes } = ProductAlgoliaUtils
  const { isContactLensesAccessories, isContactLenses } =
    getLxProductTypes(productType)

  const {
    listPrice,
    offerPrice,
    currency,
    percentageDiscount,
    badgeDiscount,
    hasInitialPriceDiscount,
  } = ProductPriceAlgoliaUtils.getProductPriceData(price)

  return (
    <ProductPriceWrapperPlp isCompact={isCompact}>
      <ProductPriceContainerFinal>
        {isContactLenses &&
          hasInitialPriceDiscount &&
          t('ProductTile.Labels.from')}
        {Boolean(listPrice) && hasInitialPriceDiscount && (
          <ProductPriceInitialPriceFinal>
            <FormattedPriceDisplay min={listPrice} currency={currency} />
          </ProductPriceInitialPriceFinal>
        )}
        {Boolean(offerPrice) && (
          <>
            {(isContactLenses || isContactLensesAccessories) &&
              !percentageDiscount && <>{t('ProductTile.Labels.from')}</>}
            <ProductPriceCurrentPricePlp isCompact={isCompact}>
              <FormattedPriceDisplay min={offerPrice} currency={currency} />
            </ProductPriceCurrentPricePlp>
          </>
        )}
        {(isContactLenses || isContactLensesAccessories) &&
          t(`ContactLenses.Labels.${isMobile ? 'perBoxMobile' : 'perBox'}`)}
      </ProductPriceContainerFinal>
      {Boolean(percentageDiscount) && (
        <ProductPriceDiscountBoxFinal>
          {badgeDiscount}
        </ProductPriceDiscountBoxFinal>
      )}
    </ProductPriceWrapperPlp>
  )
}

export default ProductPricePlp
