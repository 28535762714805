import React from 'react'
import {
  OurServicesContainer,
  OurServicesTitle,
  ServicesContainer,
} from './OurServices.style'
import { useTranslation } from 'react-i18next'
import SingleService from './SingleService'
import {
  AfterCareIcon,
  ContactLensIcon,
  PilotGlassesIcon,
  TestIcon,
} from '@components/UI/Icons'

const OurServices = () => {
  const { t } = useTranslation()

  const ourServices = [
    {
      type: 'CUSV',
      icon: <TestIcon />,
    },
    {
      type: 'PALGV',
      icon: <ContactLensIcon />,
    },
    {
      type: 'PSHOPFS',
      icon: <PilotGlassesIcon />,
    },
    {
      type: 'PSHOPGV',
      icon: <AfterCareIcon />,
    },
  ]

  return (
    <OurServicesContainer>
      <OurServicesTitle>
        {t('StoreDetails.Labels.OurServices')}
      </OurServicesTitle>
      <ServicesContainer>
        {ourServices.map((service) => (
          <SingleService type={service.type} icon={service.icon} />
        ))}
      </ServicesContainer>
    </OurServicesContainer>
  )
}

export default OurServices
