import { useEffect, useState, useRef } from 'react'
import { APP_MAIN_CONTAINER_ID } from '../../constants/ui'

const Y_POS_DELTA = 10
/**
 * useScrollingUp custom react hook
 * @returns {boolean}
 */
const useScrollingUp = () => {
  const prevScrollY = useRef(0)
  const [goingUp, setGoingUp] = useState<boolean | null>(null)

  useEffect(() => {
    const appMainContainerElement = document.getElementById(
      APP_MAIN_CONTAINER_ID
    )
    const handleScroll = () => {
      const currentScrollY = appMainContainerElement
        ? appMainContainerElement?.scrollTop
        : 0

      // i'm on top of the page
      if (currentScrollY <= 1) {
        setGoingUp(null)
        return
      }
      if (Math.abs(prevScrollY.current - currentScrollY) >= Y_POS_DELTA) {
        // i'm scrolling down
        if (prevScrollY.current < currentScrollY) {
          setGoingUp(false)
        }
        // i'm scrolling up
        if (prevScrollY.current > currentScrollY) {
          setGoingUp(true)
        }
      }
      prevScrollY.current = currentScrollY
    }

    appMainContainerElement?.addEventListener('scroll', handleScroll, {
      passive: true,
    })

    return () =>
      appMainContainerElement?.removeEventListener('scroll', handleScroll)
  }, [])
  return goingUp
}

export default useScrollingUp
