import React, { useEffect, useState } from 'react'
import { SearchClient } from 'algoliasearch'
import { useInstantSearch } from 'react-instantsearch-hooks-web'
import {
  DcwProductsPreTitle,
  DcwProductsText,
  DcwProductsTitle,
  StyledSuggestionContainer,
  WrapperDcwProductsCta,
  WrapperDcwProductsTitleText,
} from '../DcwProducts/DcwProducts.style'
import { MultipleQueriesQuery } from '@algolia/client-search'
import { initIndexName } from '../../../../../foundation/algolia/algoliaConfig'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import { useStoreIdentity } from '../../../../../foundation/hooks/useStoreIdentity'
import SuggestedProductsAlgolia from '@components/SuggestedProductAlgolia'
import { localeLangCountryUtil } from '@utils/countryUtil'
import { ProductAlgolia } from '../../../../../types/ProductAlgolia/ProductAlgolia'
import { ICMAlgolia } from '../../../../../types/cmsPlacement/CMAlgolia'
import CmsCta from '@components/Cms/CmsComponents/CmsCta'

interface IProps {
  teaserIndex?: number
  searchClient: SearchClient
  searchTerm?: string
  isPlpMethod: boolean
  item: ICMAlgolia
}

const DcwProductsAlgolia: React.FC<IProps> = ({
  searchTerm,
  searchClient,
  isPlpMethod,
  item,
}) => {
  const { results } = useInstantSearch()
  const { country, langCode } = useStoreIdentity()
  const langCountry = localeLangCountryUtil(langCode)

  const [productList, setProductList] = useState<ProductAlgolia[]>([])
  const [isCatalogLoading, setIsCatalogLoading] = useState<boolean | undefined>(
    false
  )

  const formatProducts = (hits) => {
    const formattedProductAlgolia =
      ProductAlgoliaUtils.getFormattedProductAlgolia(hits, country)

    setProductList(formattedProductAlgolia)
    setIsCatalogLoading(false)
  }

  const fetchProducts = (hits) => {
    const ungroupedIndexName: string = initIndexName(
      langCountry,
      undefined,
      false
    )
    const queries: MultipleQueriesQuery[] = hits.map(({ x_groupkey }) => ({
      indexName: ungroupedIndexName,
      query: searchTerm,
      params: {
        filters: `x_groupkey:"${x_groupkey}"`,
      },
    }))

    if (queries.length && searchClient) {
      searchClient
        .multipleQueries(queries)
        .then(({ results }) => {
          setIsCatalogLoading(true)

          const formattedProductAlgolia =
            ProductAlgoliaUtils.getFormattedProductAlgolia(results, country)

          setProductList(formattedProductAlgolia)
          setIsCatalogLoading(false)
        })
        .catch(() => {
          setIsCatalogLoading(false)
          setProductList(hits)
        })
    }
  }

  const getProducts = isPlpMethod ? fetchProducts : formatProducts

  useEffect(() => {
    if (results && !productList.length) {
      const { hits } = results
      getProducts(hits)
    }
  }, [results])

  const teaserTitle = item?.teaserTitle4 || ''
  const teaserText = item?.teaserText2 || ''
  const teaserPreTitle = item?.teaserPreTitle || ''
  const teaserLXCallToActionSettings = item?.teaserLXCallToActionSettings || []
  const paddingTopEnabled = teaserLXCallToActionSettings.length > 0
  const paddingBottomEnabled = !!(teaserTitle || teaserPreTitle || teaserText)

  return (
    <>
      {!isCatalogLoading && productList.length > 0 && (
        <StyledSuggestionContainer>
          <WrapperDcwProductsTitleText
            paddingBottomEnabled={paddingBottomEnabled}
          >
            {teaserTitle && (
              <DcwProductsTitle fontSize={28} fontWeight={'bold'}>
                {teaserTitle}
              </DcwProductsTitle>
            )}
            {teaserPreTitle && (
              <DcwProductsPreTitle fontSize={16}>
                {teaserPreTitle}
              </DcwProductsPreTitle>
            )}
            {teaserText && (
              <DcwProductsText
                dangerouslySetInnerHTML={{ __html: teaserText }}
              />
            )}
          </WrapperDcwProductsTitleText>
          <SuggestedProductsAlgolia
            products={productList}
            isClustered={isPlpMethod}
          />
          <WrapperDcwProductsCta paddingTopEnabled={paddingTopEnabled}>
            {teaserLXCallToActionSettings?.map((actionSettings, index) => (
              <CmsCta
                actionSettings={actionSettings}
                dataElementId={`X_X_1Placement_Banner${index}_CTA`}
                key={`cms-content__dcw-products--cta-${index}`}
              />
            ))}
          </WrapperDcwProductsCta>
        </StyledSuggestionContainer>
      )}
    </>
  )
}

export default DcwProductsAlgolia
