import { ArrowLeftIcon, ArrowRightIcon } from '@components/UI/Icons'
import ProductImage, {
  ProductImageProps,
} from '../../../../components/ProductImage/ProductImage'
import {
  ProductImageSlider,
  SliderContainer,
  SliderNavigationButton,
  SliderNavigationContainer,
} from './ProductImagesSlider.style'
import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react'
import { SwiperProps, SwiperSlide } from 'swiper/react'

import { Attachment } from '../../../../types/product'
import { EffectFade } from 'swiper'
// TYPES
import { Swiper as SwiperClass } from 'swiper/types'
import { useTheme } from '@mui/material/styles'

// SWIPER CSS
import 'swiper/swiper-bundle.min.css'

/**
 * @param { Attachment[] } images slider images
 * @param { ProductImageProps } sliderImageProps slider images props
 * @param { string } mainCarouselWidth width in pixel of slider images and slider
 * @param { function } onImageClick callback when clicking the image
 * @param { function } onPrevClick callback when clicking the prev slide button
 * @param { function } onNextClick callback when clicking the next slide button
 * @param { function } onSlideChange callback when slide changes
 * @param { SwiperProps} sliderProps you can pass props to the main slider using this prop
 * @param { number } currentSlide you can force the slide index to the slider
 * @param { boolean } shownavigation show navigation buttons
 * visit https://swiperjs.com/react for more infos
 */

export type ProductImagesSliderProps = PropsWithChildren<{
  sliderProps?: SwiperProps
  sliderImageProps: ProductImageProps
  images?: Attachment[]
  mainCarouselWidth?: number
  onImageClick?: (index: number) => void
  onPrevClick?: () => void
  onNextClick?: () => void
  onSlideChange?: (slideNumber: number | undefined) => void
  currentSlide?: number
  pdpdataloading?: boolean
  shownavigation?: boolean
  backgroundColor?: string
  onSlidesLoadedAndRendered?(): void // is triggered when all images are fully loaded and rendered
  isImageLazyLoadEnabled?: (index: number) => boolean
}>

const ProductImagesSlider: FC<ProductImagesSliderProps> = ({
  children,
  currentSlide = 0,
  images,
  pdpdataloading,
  shownavigation,
  sliderProps,
  sliderImageProps,
  onImageClick,
  onSlideChange,
  onSlidesLoadedAndRendered,
  isImageLazyLoadEnabled,
  ...rest
}: ProductImagesSliderProps) => {
  const theme = useTheme()
  const swiperRef = useRef<SwiperClass | null>(null)
  const initialSlide = sliderProps?.initialSlide || 0

  const [imagesLoadedAndRenderedCount, setImagesLoadedAndRenderedCount] =
    useState(0)

  useEffect(() => {
    swiperRef.current?.slideToLoop(currentSlide)
  }, [currentSlide])

  useEffect(() => {
    if (
      onSlidesLoadedAndRendered &&
      imagesLoadedAndRenderedCount === images?.length
    ) {
      onSlidesLoadedAndRendered()
    }
  }, [imagesLoadedAndRenderedCount, images?.length])

  return images && images?.length > 0 && !pdpdataloading ? (
    <SliderContainer {...rest}>
      {images?.length > 1 && shownavigation ? (
        <SliderNavigationContainer>
          <SliderNavigationButton
            onClick={() => swiperRef.current?.slidePrev()}
          >
            <ArrowLeftIcon htmlColor={theme.palette.color.grey.dark} />
          </SliderNavigationButton>
          <SliderNavigationButton
            onClick={() => swiperRef.current?.slideNext()}
          >
            <ArrowRightIcon htmlColor={theme.palette.color.grey.dark} />
          </SliderNavigationButton>
        </SliderNavigationContainer>
      ) : null}

      <ProductImageSlider
        loop={false}
        slidesPerView={1}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        onSlideChange={() =>
          onSlideChange && onSlideChange(swiperRef.current?.realIndex)
        }
        modules={[EffectFade]}
        simulateTouch={false}
        effect="fade"
        {...sliderProps}
      >
        {images?.map((_, index) => (
          <SwiperSlide
            key={index}
            className={currentSlide === index ? 'selected' : ''}
          >
            <ProductImage
              onClick={() => onImageClick && onImageClick(index)}
              srcsetmap={sliderImageProps?.srcsetmap}
              width={sliderImageProps?.width}
              usage={sliderImageProps?.usage}
              attachments={[images[index]]}
              backgroundColor={theme.palette.color.white.main}
              isLazyLoadEnabled={
                isImageLazyLoadEnabled?.(index) ?? index !== initialSlide
              }
              onImageLoaded={() =>
                requestAnimationFrame(() => {
                  setImagesLoadedAndRenderedCount((v) => ++v)
                })
              }
              {...sliderImageProps}
            />
          </SwiperSlide>
        ))}
      </ProductImageSlider>

      {children}
    </SliderContainer>
  ) : null
}

export default ProductImagesSlider
