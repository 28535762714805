import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ProductTileSecondaryBadge,
  StyledProductTileContactLensesAnnualSupplySaveAmount,
  StyledProductTileContactLensesAnnualSupplyWrapper,
  StyledProductTileHeader,
  ProductTilePrimaryBadge,
} from '@components/ProductTile/components/ProductTileHeader/ProductTileHeader.style'
import { SupplyData } from '../../../../types/product'

export interface ProductTileHeaderProps {
  isContactLenses: boolean
  annualSupply?: SupplyData
  isFullListeningLayout?: boolean
  currency: string
  primaryBadge: string
  secondaryBadge: string
}
const ProductTileHeaderOld: React.FC<ProductTileHeaderProps> = ({
  isContactLenses,
  annualSupply,
  isFullListeningLayout,
  currency,
  primaryBadge,
  secondaryBadge,
}) => {
  const { t } = useTranslation()
  const annualSupplyBadges =
    parseInt(annualSupply?.originalBoxPrice || '') *
      parseInt(annualSupply?.timePeriod || '') -
    parseInt(annualSupply?.discountedBoxPrice || '') *
      parseInt(annualSupply?.timePeriod || '')

  return (
    <StyledProductTileHeader>
      {isContactLenses && annualSupplyBadges ? (
        <StyledProductTileContactLensesAnnualSupplyWrapper
          isFull={isFullListeningLayout}
        >
          <StyledProductTileContactLensesAnnualSupplySaveAmount>
            {t('ProductTile.Labels.SaveAmountLine1', {
              currency: currency,
              amount: annualSupplyBadges,
            })}
          </StyledProductTileContactLensesAnnualSupplySaveAmount>
          {t('ProductTile.Labels.SaveAmountLine2')}
        </StyledProductTileContactLensesAnnualSupplyWrapper>
      ) : (
        <ProductTilePrimaryBadge>
          {primaryBadge || '\u00A0'}
        </ProductTilePrimaryBadge>
      )}
      <ProductTileSecondaryBadge>
        {secondaryBadge || '\u00A0'}
      </ProductTileSecondaryBadge>
    </StyledProductTileHeader>
  )
}

export default ProductTileHeaderOld
