import { styled } from '@mui/material/styles'
import { StyledRecapContainer } from '@components/order-recap/OrderRecap.style'
import { StyledAnchor, StyledTypography } from '@components/UI'

export const CheckoutSummaryContainer = styled('div', {
  name: 'CheckoutSummary',
  slot: 'Container',
})(({ theme }) => ({
  color: theme.palette.text.dark,
  marginTop: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: 0,
  },
}))

export const ShippingSummaryContainer = styled(StyledRecapContainer, {
  name: 'ShippingSummaryContainer',
  slot: 'Container',
})(({ theme }) => ({
  marginTop: 0,
  display: 'grid',
  gridTemplateRows: '1fr 1fr',
  fontSize: 14,
  padding: theme.spacing(2, 4),
}))

export const ShippingPriceContainer = styled('div', {
  name: 'ShippingPriceContainer',
  slot: 'Container',
})(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

export const PaymentSummaryContainer = styled(StyledRecapContainer, {
  name: 'PaymentSummary',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '14px',
  lineHeight: '20px',
  color: theme.palette.text.dark,
  fontWeight: 600,
  padding: theme.spacing(2, 4),

  '>div': {
    paddingTop: theme.spacing(1),
    flex: 1,
  },
}))

export const DeliverySummaryContainer = styled(StyledRecapContainer, {
  name: 'DeliverySummary',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2, 3),

  '>div': {
    flex: 1,
  },

  span: {
    fontSize: 12,
  },
}))

export const TotalSummaryContainer = styled(StyledRecapContainer, {
  name: 'TotalSummary',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(3.75, 3.25),

  p: {
    flex: 1,
  },
}))

export const Total = styled(StyledTypography, {
  name: 'CheckoutSummary',
  slot: 'Total',
})(() => ({
  textAlign: 'right',
}))

export const TitleDeliverySummaryContent = styled('div', {
  name: 'CheckoutSummary',
  slot: 'TitleDeliverySummaryContent',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const LabelShippingStyle = styled(StyledTypography, {
  name: 'CheckoutSummary',
  slot: 'LabelShippingStyle',
})(() => ({
  textAlign: 'right',
}))

export const PhysicalStoreSummaryContainer = styled('div', {
  name: 'PhysicalStoreSummary',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}))

export const PhysicalStoreGetDirectionContainer = styled('div', {
  name: 'PhysicalStoreGetDirection',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  paddingTop: theme.spacing(2),
}))

export const PhysicalStoreMapAnchor = styled(StyledAnchor, {
  name: 'PhysicalStoreMap',
  slot: 'Anchor',
})(() => ({
  fontWeight: 'bold',
}))

export const PhysicalStoreMapContainer = styled('div', {
  name: 'PhysicalStoreMap',
  slot: 'Container',
})(() => ({
  position: 'relative',
}))

export const PhysicalStoreMapImage = styled('img', {
  name: 'PhysicalStoreContent',
  slot: 'MapImage',
})(({ theme }) => ({
  width: '100%',
  objectFit: 'cover',
  border: `1px solid ${theme.palette.color.grey.main}`,
  aspectRatio: '31/15',

  [theme.breakpoints.up('md')]: {
    width: '100%',
    height: '100%',
    position: 'relative',
    top: 0,
    left: 0,
    transform: 'unset',
    aspectRatio: '99/56',
    objectFit: 'cover',
  },
}))

export const PhysicalStoreMarkerIconContainer = styled('div', {
  name: 'PhysicalStoreContent',
  slot: 'MarkerIconContainer',
})(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
}))

export const PhysicalStoreMarkerImage = styled('img', {
  name: 'PhysicalStoreContent',
  slot: 'MarkerImage',
})(() => ({}))
