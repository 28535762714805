import { styled } from '@mui/material/styles'
import { IconButton } from '@components/UI'

export const ReducedHeaderWrapper = styled('header', {
  name: 'ReducedHeaderWrapper',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  height: theme.spacing(16),
  paddingRight: theme.spacing(16),
  paddingLeft: theme.spacing(16),
  alignItems: 'center',
  boxShadow: `inset 0 -1px 0 0 ${theme.palette.color.grey.light}`,
}))

export const ReducedHeaderNavigationLogoWrapper = styled('div', {
  name: 'ReducedHeaderWrapper',
  slot: 'NavigationLogoWrapper',
})(() => ({
  flex: 1,
}))

export const ReducedHeaderCheckoutDisclaimer = styled('div', {
  name: 'ReducedHeaderWrapper',
  slot: 'CheckoutDisclaimer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  flex: 1,
  fontSize: 12,
  color: theme.palette.text.dark,
  textTransform: 'uppercase',
  fontWeight: 600,
  textAlign: 'right',
  span: {
    display: 'flex',
    textAlign: 'center',
    width: '60%',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      textAlign: 'right',
      width: 'auto',
    },
  },
}))

export const ReducedHeaderMobileWrapper = styled('div', {
  name: 'ReducedHeaderWrapper',
  slot: 'MobileWrapper',
})(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  fontSize: '10px',
  boxShadow: `inset 0 -1px 0 0 ${theme.palette.color.grey.light}`,
}))

export const ReducedHeaderMobileBack = styled(IconButton, {
  name: 'ReducedHeaderWrapper',
  slot: 'MobileBack',
})(() => ({
  flex: '1',
  justifyContent: 'flex-start',
}))

export const ReducedHeaderMobileTop = styled('div', {
  name: 'ReducedHeaderWrapper',
  slot: 'MobileTop',
})(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2, 4),
  borderBottom: `1px solid ${theme.palette.color.grey.main}`,
}))
export const ReducedHeaderMobileBottom = styled('div', {
  name: 'ReducedHeaderWrapper',
  slot: 'Mobilebottom',
})(({ theme }) => ({
  padding: theme.spacing(0, 12, 4, 12),
}))
