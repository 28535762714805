import { IOrderDetails, OrderItem } from '../../../../types/order'
import {
  PageTypes,
  ProductForAnalytics,
  ProductForAnalyticsFields,
} from '../interfaces'
import { getSoldOut } from '@utils/productAttributes'

import { ProductAnalyticsRX, ServerProduct } from '../../../../types/product'
import { getProductPrices } from '@utils/productPrice'
import { getRxPrice } from '@utils/rx'
import {
  ALLOWED_PAGES_BY_PRODUCT_FIELDS,
  RESTRICTED_PRODUCT_FIELDS,
  RESTRICTED_PRODUCT_FIELDS_BY_EVENT,
} from '../constants/restrictions'
import { removeRestrictedFields } from '../utils'
import { PRODUCT_TAX_RATE, PRODUCT_TYPES } from '../constants/product'
import { ORDER_PAYMENT_TYPES } from '../constants/order'
import { isPrescriptionContactLenses } from '@utils/contactLenses'
import { PAGE_TYPES } from '../pageTypes'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'

export const parseCatentriesForRX = (
  orderItems: OrderItem[],
  products: ServerProduct[]
): ProductAnalyticsRX[] => {
  // If there's an RX product, removes from the catEntries the lens and services objects (that are provided as a normal item)
  // Then, add to the single RX item (the frame), the total RX price.
  const RX_FRAME = orderItems?.find((i) => !!i?.roxableServices?.length)!
  const FRAME_ID = RX_FRAME?.productId
  const SERVICES_IDS =
    RX_FRAME &&
    RX_FRAME!.roxableServices &&
    (RX_FRAME!.roxableServices!.map((s) => s.productId) as string[])
  const FRAME_CATENTRIES = products.filter(
    (f) => f.id && !SERVICES_IDS?.includes(f.id)
  ) as ProductAnalyticsRX[]
  const FRAME_CATENTRY = FRAME_CATENTRIES.find((f) => f.id === FRAME_ID)
  const FRAME_CATENTRY_INDEX = FRAME_CATENTRIES.findIndex(
    (f) => f.id === FRAME_ID
  )
  const RX_PRICE =
    RX_FRAME &&
    getRxPrice(RX_FRAME.roxableServices!, FRAME_CATENTRY?.x_offerpriceRx)
  FRAME_CATENTRIES[FRAME_CATENTRY_INDEX] = {
    ...FRAME_CATENTRIES[FRAME_CATENTRY_INDEX],
    rxPrice: RX_PRICE,
  }

  return FRAME_CATENTRIES
}

const getProductTaxRate = (product: ServerProduct) => {
  return isPrescriptionContactLenses(product)
    ? PRODUCT_TAX_RATE.prescriptionCL
    : PRODUCT_TAX_RATE.frames
}

// TODO product got from addToCartEvent don't have prices
//TODO Fix Status when add to cart
export const formatProduct = (
  item: ProductAnalyticsRX,
  quantity?: number,
  pageType?: PageTypes,
  eventId?: string
): ProductForAnalyticsFields => {
  const attributes = ProductAlgoliaUtils.getFormattedServerProductAttributes(
    item.attributes
  )
  const prices = getProductPrices(item)

  const {
    productType,
    brand,
    clBrand,
    modelName,
    lensColor,
    lensTreatmentFacet,
    frontColor,
    frameMaterialClass,
    frameShape,
  } = ProductAlgoliaUtils.getProductAttributes(attributes)

  let productContext: ProductForAnalyticsFields = {
    Case: '',
    Cloth: '',
    Frame: '',
    Lens: '',
    ModelCode: '',
    Size: '',
    Status: getSoldOutWithDefault(item) || 'Available', // TODO
    OosOptions: '', // TODO
    Category:
      productType.toLowerCase() === 'electronics'
        ? PRODUCT_TYPES[productType.toLowerCase()] || ''
        : PRODUCT_TYPES[productType.toLowerCase()]?.toUpperCase() || '',
    Type: item.rxPrice ? 'RX' : 'STD',
    LensType: item.rxPrice ? 'ROX' : 'PLANO',
    Price: item.rxPrice ? item.rxPrice : prices?.discount,
    PriceFull: item.rxPrice ? item.rxPrice : prices?.full,
    Brand: brand || clBrand,
    Sku: item.name,
    ModelName: modelName,
    MoCo: `${item.name}`,
    LensColor: lensColor,
    LensTechnology: lensTreatmentFacet,
    FrameColor: frontColor,
    FrameTechnology: frameMaterialClass,
    Shape: frameShape,
    LensUPC: '',
    Visibility: 'Public',
    FrameType: undefined,
    Units: quantity?.toString() || '1',
    PerkCode: '',
    Warranty: '0',
    TaxRate: getProductTaxRate(item).toString(),
    CancelledUnits: '',
    CancelledAmount: '',
    Badges: '',
    Preorder: '0',
    BackOrder: '0',
  }

  const restrictedFieldsByPage = [
    ...(pageType ? RESTRICTED_PRODUCT_FIELDS[pageType] || [] : []),
    ...(
      Object.keys(ALLOWED_PAGES_BY_PRODUCT_FIELDS) as Array<
        keyof ProductForAnalyticsFields
      >
    ).filter((productField) => {
      const allowedPages = ALLOWED_PAGES_BY_PRODUCT_FIELDS[productField]
      return (
        !pageType || (allowedPages?.length && !allowedPages?.includes(pageType))
      )
    }),
  ]

  const restrictedFieldsByEvent = eventId
    ? RESTRICTED_PRODUCT_FIELDS_BY_EVENT[eventId] || []
    : []

  return removeRestrictedFields(productContext, [
    ...restrictedFieldsByPage,
    ...restrictedFieldsByEvent,
  ])
}

const getSoldOutWithDefault = (product: ServerProduct) => {
  const soldOutLabel = getSoldOut(product)
  return !soldOutLabel || soldOutLabel.toLowerCase() === 'none'
    ? 'Available'
    : 'SoldOut'
}

export const formatProductForTYP = (
  orderItem: OrderItem
): Partial<ProductForAnalyticsFields> => ({
  TaxRate: orderItem.salesTax,
})

export const getProductsForAnalytics = (
  products: ProductAnalyticsRX[],
  pageType?: PageTypes,
  eventId?: string
): ProductForAnalytics => {
  return products.reduce((acc: ProductForAnalytics, p: ProductAnalyticsRX) => {
    const productId = getProductId(p)
    if (productId) {
      acc[productId] = {
        ...formatProduct(
          p,
          p.quantity ? +p.quantity : undefined,
          pageType,
          eventId
        ),
      }
    }

    return acc
  }, {})
}

export const getProductsForTYPAnalytics = (
  products: ServerProduct[],
  orderItems: OrderItem[]
): ProductForAnalytics => {
  const productsForAnalytics = getProductsForAnalytics(
    products,
    PAGE_TYPES.THANK_YOU
  )
  return Object.keys(productsForAnalytics).reduce((res, productId) => {
    const currentProduct = productsForAnalytics[productId]
    const orderItem = orderItems.find((i) => i.productId === productId)

    res[productId] = orderItem
      ? { ...currentProduct, ...formatProductForTYP(orderItem) }
      : currentProduct
    return res
  }, {})
}

export const getOrderTotalDiscount = (orderDetails: IOrderDetails): string => {
  const totalDiscount = orderDetails.adjustment?.reduce((acc, adj) => {
    if (adj.usage === 'Discount') {
      return acc + Number(adj.amount)
    }
    return acc
  }, 0)
  return String(totalDiscount)
}

export const getOrderState = (orderDetails: IOrderDetails): string =>
  //get(orderDetails, 'paymentInstruction[0].country')
  orderDetails.paymentInstruction?.[0]?.country || ''

export const getOrderZipCode = (orderDetails: IOrderDetails): string =>
  //get(orderDetails, 'paymentInstruction[0].zipCode')
  orderDetails.paymentInstruction?.[0]?.zipCode || ''

export const getOrderPaymentType = (orderDetails: IOrderDetails): string => {
  const paymentType =
    orderDetails.paymentInstruction?.[0]?.piDescription?.replace(/\s/g, '') ||
    ''
  switch (paymentType) {
    case 'Contrassegno':
    case 'Contantiallaconsegna':
      return ORDER_PAYMENT_TYPES.CashOnDelivery
    case 'ZeroCheckout':
      return ORDER_PAYMENT_TYPES.GiftCard
    case 'AmericanExpress':
      return ORDER_PAYMENT_TYPES.Amex
    case 'AmericanExpressExpressCheckout':
      return ORDER_PAYMENT_TYPES.AmexExpressCheckout
    default:
      return paymentType
  }
}

//Reference: https://luxotticaretail.atlassian.net/browse/GVI-1071
const getProductId = (p: ServerProduct): string | undefined => {
  return p.name || p.id || p.uniqueID
}
