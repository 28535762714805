import find from 'lodash/find'
import { IPlacement } from '../../types/cmsPlacement/Placement'

export const getContentHeader = (headerPlacements: IPlacement[]) => {
  const benefitBar = find(headerPlacements, { name: 'header_benefit_bar' })
  const trendingNow = find(headerPlacements, {
    name: 'header_search_trending_now',
  })
  const searchBanner = find(headerPlacements, { name: 'header_search_banner' })
  const promoBar = find(headerPlacements, { name: 'header_promo_bar' })
  const findStore = find(headerPlacements, { name: 'header_find_a_store' })

  return {
    benefitBar,
    trendingNow,
    searchBanner,
    promoBar,
    findStore,
  }
}
