import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useInstantSearch } from 'react-instantsearch-hooks-web'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MultipleQueriesQuery } from '@algolia/client-search'
import { SearchClient } from 'algoliasearch'

import { DrawerProps } from '@mui/material'
import {
  NoResult,
  NoResultText,
  SearchInputDisclaimer,
  SearchLayerContentWrapper,
  SearchLayerHeader,
  SearchLayerSectionLeft,
  SearchLayerSectionRight,
  SearchLayerWrapper,
} from './SearchLayer.style'
import {
  StyledGridProductLayout,
  StyledProductWrap,
} from '@components/ProductGrid/components/ProductGridView/ProductGridView.style'
import { ProductTileLoading } from '@components/ProductTile'
import { IconButton } from '@components/UI'
import { CloseIcon } from '@components/UI/Icons'
import NavigationLogo from '../NavigationBar/components/NavigationLogo'
import { setOpenDrawerSearch } from '../../../../features/ui/action'
import useBreakpoints from '../../../../hooks/useBreakpoints'
import { useTheme } from '@mui/material/styles'
import { ServerProduct } from '../../../../types/product'
import ProductsResultAlgolia from './components/ProductsResultAlgolia'
import SearchBoxAlgolia from './components/SearchBoxAlgolia'
import TrendingProducts from './components/TrendingProducts'
import { initIndexName } from '../../../../foundation/algolia/algoliaConfig'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import { localeLangCountryUtil } from '@utils/countryUtil'
import { SET_ALGOLIA_BASED_PLP_ACTION } from '../../../../redux/actions/site'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import { sendSearchEvent } from 'src/foundation/analytics/tealium/lib'

import useTrendingProducts from './hooks/useTrendingProducts'
import SearchLayerUtils from './utils/SearchLayerUtils'
import { IHeader } from '../../../../features/cms/cms.type'

export type SubWord = {
  subWord: string
  highlighted?: boolean
}

export type KeywordSuggestion = {
  originalTerm: string
  terms: string[] | SubWord[][]
  frequency: string
}

export type ProductResults = {
  contents: ServerProduct[]
  total: number
  originalFacet: string
}

const SearchLayerAlgolia: React.FC<{
  open?: boolean
  hasScrolled: boolean
  drawerprops?: DrawerProps
  searchClient: SearchClient
  headerPlacements: IHeader['headerPlacements']
}> = ({ open, drawerprops, hasScrolled, searchClient, headerPlacements }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { isDesktop } = useBreakpoints()
  const { t } = useTranslation()
  const location = useLocation()
  const { langCode, country } = useStoreIdentity()
  const langCountry = localeLangCountryUtil(langCode)
  const [search, setSearch] = useState<string>('')
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false)
  const [searchMode, setSearchMode] = useState<boolean>(false)
  const [groupedProducts, setGroupedProducts] = useState<any>([])
  const [productsTotal, setProductsTotal] = useState<number | undefined>(0)

  const { results, use } = useInstantSearch()
  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const fetchUngroupedProducts = (hits) => {
    const ungroupedIndexName: string = initIndexName(
      langCountry,
      undefined,
      false
    )

    const queries: MultipleQueriesQuery[] = hits.map(({ x_groupkey }) => ({
      indexName: ungroupedIndexName,
      query: search,
      params: {
        filters: `x_groupkey:"${x_groupkey}"`,
      },
    }))

    const filterHits = (hits) => {
      return hits.filter((item) => {
        const isDisplayable = item.display === true
        const isBuyable = item.buyable === true
        return isDisplayable && isBuyable
      })
    }
    searchClient
      .multipleQueries(queries)
      .then(({ results: ungroupedProductsResults }) => {
        if (isMounted.current) {
          const filteredResults = ungroupedProductsResults.filter((result) => {
            return !!filterHits(result.hits).length
          })

          const formattedProductAlgolia =
            ProductAlgoliaUtils.getFormattedProductAlgolia(
              filteredResults,
              country
            )

          setGroupedProducts(formattedProductAlgolia)
          sendSearchEvent(search, results.nbHits ?? 0)
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setGroupedProducts(hits)
        }
      })
  }

  useEffect(() => {
    const middleware = ({ instantSearchInstance }) => {
      function handleError() {
        // Set algolia flag to false and rollback to HCL implementation
        dispatch(SET_ALGOLIA_BASED_PLP_ACTION(false))
      }

      return {
        subscribe() {
          instantSearchInstance.addListener('error', handleError)
        },
        unsubscribe() {
          instantSearchInstance.removeListener('error', handleError)
        },
      }
    }

    return use(middleware)
  }, [use])

  useEffect(() => {
    if (results) {
      const { nbHits, hits } = results
      setProductsTotal(nbHits)
      if (nbHits) {
        fetchUngroupedProducts(hits)
      } else {
        setGroupedProducts([])
        setProductsTotal(0)
      }
    }
  }, [results])

  useEffect(() => {
    dispatch(setOpenDrawerSearch(false))
  }, [location])

  const leftSideMenu = useMemo(
    () => SearchLayerUtils.getLeftSideMenu(headerPlacements),
    [headerPlacements]
  )

  const { trendingProducts, trendingAlgoliaEnabled } =
    useTrendingProducts(headerPlacements)

  return (
    <SearchLayerWrapper
      open={open}
      isScrolled={hasScrolled}
      anchor="top"
      hideBackdrop={true}
      {...drawerprops}
    >
      <SearchLayerHeader>
        {isDesktop ? <NavigationLogo /> : <NavigationLogo />}
        <IconButton
          onClick={() => {
            dispatch(setOpenDrawerSearch(false))
          }}
        >
          <CloseIcon htmlColor={theme.palette.primary.main} />
        </IconButton>
      </SearchLayerHeader>
      <SearchLayerContentWrapper>
        <SearchLayerSectionLeft>
          <SearchInputDisclaimer>
            {t('search.whatAreYouLookingFor')}
          </SearchInputDisclaimer>
          <SearchBoxAlgolia
            searchClient={searchClient}
            searchMode={searchMode}
            setSearchMode={setSearchMode}
            searchTerm={search}
            setSearchTerm={setSearch}
            leftSideMenu={leftSideMenu}
            setLoadingSearch={setLoadingSearch}
          />
        </SearchLayerSectionLeft>
        <SearchLayerSectionRight>
          {loadingSearch && (
            <>
              {new Array(8).fill(1).map((_, index) => (
                <StyledGridProductLayout key={index}>
                  <StyledProductWrap>
                    <ProductTileLoading />
                  </StyledProductWrap>
                </StyledGridProductLayout>
              ))}
            </>
          )}
          {!loadingSearch && !searchMode && (
            <TrendingProducts
              products={trendingProducts}
              isProductAlgolia={trendingAlgoliaEnabled}
            />
          )}
          {!loadingSearch &&
            !!search &&
            groupedProducts &&
            !!groupedProducts.length &&
            searchMode && (
              <ProductsResultAlgolia
                groupedProducts={groupedProducts}
                searchTerm={search}
                totalResults={productsTotal}
              />
            )}
          {!loadingSearch &&
            !!search &&
            groupedProducts &&
            groupedProducts.length === 0 &&
            searchMode && (
              <NoResult>
                <NoResultText>
                  {t('search.noResults')} "<b>{search}</b>"
                </NoResultText>
              </NoResult>
            )}
        </SearchLayerSectionRight>
      </SearchLayerContentWrapper>
    </SearchLayerWrapper>
  )
}

export default SearchLayerAlgolia
