import { AppDispatch } from '../../redux/store'
import wishListService from '../../foundation/apis/transaction/wishList.service'
import { setWishList, removeWishList } from './slice'
import { IWishlistItem } from 'src/types/wishlist'
import WishlistService from '../../foundation/apis/transaction/wishList.service'
import { Dispatch } from 'redux'
import { storageSessionHandler } from 'src/foundation/utils/storageUtil'
import Log from 'src/services/Log'
import { getGuestIdentity } from '../user/thunks'
import { wishlistSelector } from './selector'
import { RootReducerState } from 'src/redux/reducers'

export const fetchWishlistAction = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setWishList({ isLoading: true }))

    const wishlist = await wishListService.getWishlist()

    dispatch(
      setWishList({
        GiftList: wishlist.GiftList,
        x_data: wishlist.x_data,
        isLoading: false,
      })
    )
  } catch (error) {
    dispatch(setWishList({ isLoading: false }))
  }
}

export const clearWishlistAction = () => (dispatch: AppDispatch) =>
  dispatch(removeWishList())

const addItemToWishlist =
  (wishListId: string, item: IWishlistItem[], langId?: string) =>
  async (dispatch: Dispatch<any>) => {
    await WishlistService.addItemToWishlist(wishListId, item, langId)
    dispatch(fetchWishlistAction())
  }

export const addToWishList =
  (partNumber: string, langId?: string) =>
  async (dispatch: Dispatch<any>, getState: () => RootReducerState) => {
    try {
      const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()

      let wishListId = ''

      //if generic user, call the guest identity
      if (!currentUser) {
        await dispatch(getGuestIdentity())
        wishListId = await WishlistService.createWishlist()
      } else {
        //if NOT generic user, call the get wishlist
        const wishList =
          wishlistSelector(getState()) ||
          (await WishlistService.getWishlist(langId))
        if (!!wishList) {
          wishListId = wishList?.GiftList?.[0]?.uniqueID || ''
        } else {
          wishListId = await WishlistService.createWishlist()
        }
      }

      if (!!wishListId && wishListId.length > 0) {
        dispatch(
          addItemToWishlist(
            wishListId,
            [
              {
                partNumber: partNumber,
                quantityRequested: '1',
                location: 'online',
              },
            ],
            langId
          )
        )
      } else {
        throw new Error()
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        Log.error('Add wishlist error: ', error)
      }
    }
  }

export const removeFromWishList =
  (wishListId: string, itemIdWishList: string) =>
  async (dispatch: Dispatch<any>) => {
    await WishlistService.deleteItemFromWishlist({
      externalId: wishListId,
      itemId: itemIdWishList,
    })
    dispatch(fetchWishlistAction())
  }
