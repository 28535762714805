import { styled, Theme } from '@mui/material/styles'
import type { ToastProp } from './Toast.type'
import { Typography } from '@mui/material'
import { IconButton } from '../IconButton'
import { PropsWithChildren } from 'react'

const themeVariantMap = (
  theme: Theme,
  themeVariant: ToastProp['themeVariant'] = 'primary'
) => {
  const themeStyles = {
    primary: {
      background: theme.palette.primary.main,
      color: theme.palette.text.light,
      borderColor: undefined,
    },
    secondary: {
      background: theme.palette.color.white.main,
      color: theme.palette.text.dark,
      borderColor: theme.palette.color.grey.light,
    },
    tertiary: {
      background: theme.palette.primary.dark,
      color: theme.palette.text.dark,
      borderColor: undefined,
    },
  }
  return themeStyles[themeVariant!]
}

export const ToastWrapper = styled('div', {
  name: 'Toast',
  slot: 'Wrapper',
  shouldForwardProp: (prop) => prop !== 'themeVariant',
})<PropsWithChildren<ToastProp>>(({ themeVariant, theme, open }) => ({
  zIndex: 2,
  position: 'absolute',
  width: '100%',
  padding: theme.spacing(0, 4),
  backgroundColor: themeVariantMap(theme, themeVariant)?.background,
  color: themeVariantMap(theme, themeVariant)?.color,
  border: open
    ? `1px solid ${themeVariantMap(theme, themeVariant)?.borderColor}`
    : 'none',
  fontSize: 12,
  lineHeight: 1.33,
  bottom: 0,
  transform: open ? 'translateY(0)' : 'translateY(100%)',
  transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(6, 16),
  },
}))

export const ToastHeader = styled('div', {
  name: 'Toast',
  slot: 'Header',
})<ToastProp>(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  paddingTop: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    paddingTop: 0,
  },
}))

export const ToastContainer = styled('div', {
  name: 'Toast',
  slot: 'container',
})<ToastProp>(() => ({}))

export const ToastCloseButton = styled(IconButton, {
  name: 'Toast',
  slot: 'CloseIcon',
})<ToastProp>(() => ({
  position: 'absolute',
  right: '0',
  color: 'inherit',
}))

export const ToastHeading = styled(Typography, {
  name: 'Toast',
  slot: 'Heading',
  shouldForwardProp: (prop) => prop !== 'themeVariant',
})<ToastProp>(({ theme, themeVariant }) => ({
  width: '100%',
  maxWidth: '100vw',
  margin: '0 auto',
  fontSize: '1rem',
  fontWeight: 600,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: theme.spacing(4),
  paddingTop: theme.spacing(3),

  [theme.breakpoints.up('sm')]: {
    paddingTop: 0,
    justifyContent: 'center',
  },

  '&::before, &::after': {
    content: '""',
    display: 'flex',
    flex: '0 1 63px',
    height: 1,
    backgroundColor: themeVariantMap(theme, themeVariant)?.color,
  },

  '&::before': {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(12),
    },
  },

  '&::after': {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(12),
    },
  },
}))
