import { ButtonAsLink } from '@components/UI'
import { styled } from '@mui/material/styles'
import { Z_INDEX_LV5 } from '../../../../constants/ui'

export const CartRecapContainer = styled('div', {
  name: 'CartRecap',
  slot: 'Container',
})(({ theme }) => ({
  color: theme.palette.text.dark,
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(6),
  gap: theme.spacing(3),
}))

export const CartRecapItemWrapper = styled('div', {
  name: 'CartRecap',
  slot: 'ItemWrapper',
})(({ theme }) => ({
  border: `1px solid ${theme.palette.color.grey.light}`,
  borderRadius: 8,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
}))

export const CartRecapContentContainer = styled('div', {
  name: 'CartRecap',
  slot: 'ContentContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}))

export const CartRecapEDD = styled('div', {
  name: 'CartRecap',
  slot: 'EDD',
})(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: theme.palette.color.backgrounds.color4,
  color: theme.palette.text.dark,
  fontSize: 14,
  padding: theme.spacing(1.5, 0, 1.5, 4),
  span: {
    fontWeight: 600,
  },
  borderBottom: `1px solid ${theme.palette.color.grey.light}`,
}))

export const EstimatedDeliveryDate = styled('span', {
  name: 'CartRecap',
  slot: 'EstimatedDeliveryDate',
})(() => ({
  textTransform: 'capitalize',
}))

const cartRecapItemImageWidthMd = '304px'

export const CartRecapImageContainer = styled('div', {
  name: 'CartRecap',
  slot: 'ImageContainer',
})(({ theme }) => ({
  backgroundColor: theme.palette.color.grey.lightest,
  position: 'relative',
  minWidth: 100,
  fontWeight: 600,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  img: {
    width: '100%',
    height: 187,
    objectFit: 'contain',
    cursor: 'pointer',
  },

  [theme.breakpoints.up('sm')]: {
    padding: 0,
    height: 'auto',
    img: {
      width: cartRecapItemImageWidthMd,
    },
  },
}))

export const StyledRemoveButtonContainer = styled('div', {
  name: 'RemoveButtonCart',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  height: theme.spacing(6),
  marginTop: theme.spacing(4.5),
  marginBottom: theme.spacing(2),
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
}))

export const CartRecapRxImageFallbackContainer = styled('div', {
  name: 'CartRecap',
  slot: 'RxImageFallbackContainer',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  margin: theme.spacing(0, 2, 4, 2),
  padding: theme.spacing(2, 4),

  backgroundColor: theme.palette.color.white.main,
  borderRadius: theme.spacing(2),
  border: `solid 1px ${theme.palette.color.grey.main}`,
  color: theme.palette.primary.main,

  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    bottom: theme.spacing(8),

    margin: theme.spacing(0, 2),
  },

  img: {
    width: 40,
    height: 40,
  },
}))

export const CartRecapRxImageFallbackTextContainer = styled('div', {
  name: 'CartRecap',
  slot: 'RxImageFallbackTextContainer',
})(() => ({
  fontSize: 10,
  fontWeight: 'normal',

  h5: {
    margin: 0,

    fontSize: 12,
    fontWeight: 600,
  },
}))

export const CartRecapImageFooter = styled('div', {
  name: 'CartRecap',
  slot: 'ImageFooter',
})(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  display: 'none',
  margin: theme.spacing(0, 4),
  padding: theme.spacing(3.5, 0),

  [theme.breakpoints.up('sm')]: {
    display: 'initial',
  },
}))

export const CartRecapTop = styled('div', {
  name: 'CartRecap',
  slot: 'Top',
})(() => ({
  '& > *': {
    width: 'auto',
  },
}))

export const CartRecapBottom = styled('div', {
  name: 'CartRecap',
  slot: 'Bottom',
})(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(4),
    padding: 0,
    textAlign: 'right',
  },
}))

export const StyledRemoveButtonAsLink = styled(ButtonAsLink, {
  name: 'RemoveButtonCart',
  slot: 'AsLink ',
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled?: boolean }>(({ theme, disabled }) => ({
  fontSize: 12,
  fontWeight: 600,
  '&::after': {
    [theme.breakpoints.down('md')]: {
      bottom: 5,
    },
  },
  cursor: disabled ? 'not-allowed' : 'pointer',
}))

export const CartRecapRightColumn = styled('div', {
  name: 'CartRecap',
  slot: 'RightColumn',
  shouldForwardProp: (prop) => prop !== 'isClOrCla',
})<{ isClOrCla?: boolean }>(({ theme, isClOrCla }) => ({
  flex: `1 0 calc(100% - ${cartRecapItemImageWidthMd})`,
  padding: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    padding: isClOrCla ? theme.spacing(6) : theme.spacing(6, 6, 4, 6),
  },
}))

export const CartRecapData = styled('div', {
  name: 'CartRecap',
  slot: 'Data',
})(() => ({
  height: '100%',
  display: 'flex',
  flex: '1 0 50%',
  flexDirection: 'column',
}))

export const CartRecapDataContent = styled('div', {
  name: 'CartRecap',
  slot: 'DataContent',
  shouldForwardProp: (prop) => prop !== 'isClAccessories',
})<{ isClAccessories?: boolean }>(({ theme, isClAccessories }) => ({
  flex: 1,

  [theme.breakpoints.up('sm')]: {
    height: '100%',
    minHeight: 150,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: isClAccessories ? 'space-around' : 'unset',
    marginTop: theme.spacing(3),
  },
}))

export const CartRecapModelPriceDataRow = styled('div', {
  name: 'CartRecap',
  slot: 'DataRow',
})(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  fontSize: 12,
  lineHeight: 1,
}))

export const CartRecapTotalDataRowWrapper = styled('div', {
  name: 'CartRecapTotal',
  slot: 'DataRowWrapper',
  shouldForwardProp: (prop) =>
    prop !== 'isClAccessories' && prop !== 'isNotContactLenses',
})<{ isNotContactLenses?: boolean }>(({ isNotContactLenses }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  flex: isNotContactLenses ? 1 : 0,
}))

export const CartRecapTotalDataRow = styled('div', {
  name: 'CartRecapTotal',
  slot: 'DataRow',
})(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  fontSize: 20,

  '& > div:first-of-type': {
    textTransform: 'uppercase',
    fontWeight: 600,
  },
}))

export const CartRecapPromoCodeLabelWrapper = styled('div', {
  name: 'CartRecapTotal',
  slot: 'PromoCodeLabel',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  color: theme.palette.text.dark,
  fontSize: 16,
  marginBottom: theme.spacing(2),

  '& > div:first-of-type': {
    fontWeight: 600,
    fontSize: 14,
  },
}))

export const CartRecapTotalFramesDataRow = styled('div', {
  name: 'CartRecapFramesTotal',
  slot: 'DataRow',
})(() => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  textTransform: 'uppercase',

  '& > div:first-of-type': {
    fontSize: 16,
    fontWeight: 600,
  },
}))

export const CartRecapAnnualSupplyRow = styled('div', {
  name: 'CartRecapTotal',
  slot: 'AnnualSupplyRow',
})(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  paddingTop: theme.spacing(2),
}))

export const TotalWrapperUnit = styled('div', {
  name: 'CartRecapTotal',
  slot: 'TotalPrice',
})(() => ({
  fontWeight: 600,
  fontSize: 20,
  textTransform: 'uppercase',
}))

export const CartRecapTotalInitialPrice = styled('div', {
  name: 'CartRecapTotal',
  slot: 'InitialPrice',
})(() => ({
  fontSize: 18,
  textDecoration: 'line-through',
}))

export const CartRecapTotalDiscountedPrice = styled('div', {
  name: 'CartRecapTotal',
  slot: 'DiscountedPrice',
})(() => ({
  fontSize: 20,
  fontWeight: 600,
}))

export const CartRecapDataRow = styled('div', {
  name: 'CartRecap',
  slot: 'DataRow',
})(() => ({
  display: 'flex',
  fontSize: 12,
}))

export const CartItemsRecap = styled('div', {
  name: 'CartRecap',
  slot: 'ItemsRecapContainer',
})(({ theme }) => ({
  marginTop: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    marginTop: 0,
  },
}))

export const CartItemRecapLabel = styled('span', {
  name: 'CartRecap',
  slot: 'DataRowLabel',
})(({ theme }) => ({
  fontSize: 12,
  'span:first-of-type': {
    fontWeight: 600,
    marginRight: theme.spacing(1),
  },
  '&:after': {
    content: '""',
  },
}))

export const CartRecapDataRowLabel = styled('span', {
  name: 'CartRecap',
  slot: 'DataRowLabel',
})(({ theme }) => ({
  fontSize: 12,
  'span:first-of-type': {
    fontWeight: 600,
    marginRight: theme.spacing(1),
  },
  '&:after': {
    content: '""',
  },
}))

export const CartRecapDataRowValue = styled('span', {
  name: 'CartRecap',
  slot: 'DataRowValue',
})(() => ({
  lineHeight: '1rem',
}))

export const CartRecapDataRowProductModel = styled('span', {
  name: 'CartRecap',
  slot: 'RowProductModel',
})(({ theme }) => ({
  color: theme.palette.text.dark,
}))

export const CartRecapDataRowPrice = styled('span', {
  name: 'CartRecap',
  slot: 'RowPrice',
})(() => ({
  fontSize: 16,
}))

export const CartRecapDesktopDataRowPrice = styled('span', {
  name: 'CartRecap',
  slot: 'DesktopDataRowPrice',
})(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  fontSize: 16,
}))

export const CartRecapDesktopBrandAndModel = styled('span', {
  name: 'CartRecap',
  slot: 'DesktopBrandAndModel',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const CartRecapDesktopPriceWrapper = styled('div', {
  name: 'CartRecap',
  slot: 'DesktopPriceWrapper',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
}))

export const CartRecapDataWrapper = styled('div', {
  name: 'CartRecap',
  slot: 'RecapDataWrapper',
})(() => ({
  fontSize: 12,
}))

export const CartRecapModelName = styled('div', {
  name: 'CartRecap',
  slot: 'RecapModelName',
})(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.dark,
}))

export const CartRecapBrand = styled('div', {
  name: 'CartRecap',
  slot: 'BrandName',
})(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
  color: theme.palette.text.dark,
}))

export const StyledCartRecapDivider = styled('div', {
  name: 'CartRecap',
  slot: 'Divider',
})(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  width: '100%',
  height: 1,
  backgroundColor: theme.palette.color.grey.light,
}))

export const ContactLensQuantityPrescriptionMessage = styled('div', {
  name: 'ContactLensQuantity',
  slot: 'PrescriptionMessage',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  gap: theme.spacing(3),
  background: theme.palette.primary.light,
  height: 32,
  fontSize: 10,
  fontWeight: 600,
  marginBottom: theme.spacing(6),
  svg: {
    height: 16,
    width: 16,
    marginLeft: theme.spacing(3),
    rotate: '180deg',
  },

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
}))

export const RxPrescriptionDetailsAddedMessage = styled(
  ContactLensQuantityPrescriptionMessage,
  {
    name: 'Rx',
    slot: 'PrescriptionDetailsAddedMessage',
  }
)(() => ({
  cursor: 'pointer',
}))

export const CartRecapCLModelName = styled('div', {
  name: 'CartRecap',
  slot: 'RecapModelName',
})(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.text.dark,
  fontWeight: 600,
  lineHeight: 1.43,
  textTransform: 'uppercase',
}))

export const CartRecapCLBrandName = styled('div', {
  name: 'CartRecap',
  slot: 'RecapBrandName',
})(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.dark,
}))

export const CartRecapPriceContainer = styled('div', {
  name: 'CartRecap',
  slot: 'PriceContainer',
})(() => ({
  display: 'flex',
  alignItems: 'center',
}))

export const CartRecapInitialTotalPrice = styled('div', {
  name: 'CartRecap',
  slot: 'InitialTotalPrice',
})(({ theme }) => ({
  textDecoration: 'line-through',
  marginRight: theme.spacing(2),
  fontSize: 14,
}))

export const CartRecapTotalPrice = styled('div', {
  name: 'CartRecap',
  slot: 'TotalPrice',
  shouldForwardProp: (prop) => prop !== 'withDiscount',
})({
  fontWeight: 600,
})

export const CartRecapRxInitialTotalPrice = styled(CartRecapInitialTotalPrice)(
  () => ({
    fontSize: 14,
  })
)

export const CartRecapPrescriptionAddedText = styled('span', {
  name: 'CartRecap',
  slot: 'PrescriptionAddedText',
})(() => ({
  textDecoration: 'underline',
}))

export const QuantitySelectContainer = styled('div', {
  name: 'CartRecapItem',
  slot: 'QuantitySelectContainer',
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
}))

export const QuantitySelectWrapper = styled('div', {
  name: 'CartRecapItem',
  slot: 'QuantitySelectWrapper',
})(({ theme }) => ({
  flex: '0 1 100px',
  marginRight: theme.spacing(6),
}))

export const QuantitySelectLabel = styled('span', {
  name: 'CartRecapItem',
  slot: 'QuantitySelectWrapper',
})(({ theme }) => ({
  display: 'block',
  fontSize: 12,
  color: 'inherit',
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  textTransform: 'uppercase',
}))

export const PricePerBoxContainer = styled('div', {
  name: 'CartRecapItem',
  slot: 'PricePerBoxContainer',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  fontSize: 12,
  marginTop: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    alignItems: 'center',
  },

  [theme.breakpoints.up('lg')]: {
    marginRight: 0,
    marginLeft: 'auto',
  },
}))

export const CartRecapCutLabel = styled('span', {
  name: 'CartRecapItem',
  slot: 'CutLabel',
  shouldForwardProp: (prop) => prop !== 'withDiscount',
})<{ withDiscount?: boolean }>(({ withDiscount, theme }) => ({
  textDecoration: withDiscount ? 'line-through' : '',
  marginRight: withDiscount ? theme.spacing(1.25) : '',
}))

export const LoaderWrapper = styled('div', {
  name: 'ProductSummary',
  slot: 'Loader',
})(() => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  display: 'grid',
  placeItems: 'center',
  zIndex: Z_INDEX_LV5,
}))
