import { styled } from '@mui/material'

export const ProductNameWrapper = styled('div', {
  name: 'ProductName',
  slot: 'NameWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
  gap: theme.spacing(2),
}))

export const ProductBrandWrapper = styled('div', {
  name: 'ProductName',
  slot: 'BrandWrapper',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const ProdcuctBrand = styled('span', {
  name: 'ProdcuctName',
  slot: 'BrandSpan',
})(({ theme }) => ({
  flexGrow: 0,
  fontSize: 14,
  fontWeight: 600,
  lineHeight: 1.43,
  textAlign: 'left',
  color: theme.palette.text.dark,
  textTransform: 'uppercase',
}))

export const ProductName = styled('h1', {
  name: 'ProdcuctName',
  slot: 'ModelName',
})(({ theme }) => ({
  flexGrow: 0,
  fontSize: 12,
  lineHeight: 1.33,
  textAlign: 'left',
  color: theme.palette.text.dark,
  textTransform: 'uppercase',
}))

export const ProductCLName = styled('span', {
  name: 'ProdcuctName',
  slot: 'CLModelName',
})(({ theme }) => ({
  flexGrow: 0,
  fontSize: 14,
  fontWeight: 600,
  lineHeight: 1.33,
  textAlign: 'left',
  color: theme.palette.text.dark,
}))

export const ProductCLBrand = styled('span', {
  name: 'ProdcuctName',
  slot: 'CLBrandName',
})(({ theme }) => ({
  flexGrow: 0,
  fontSize: 12,
  fontWeight: 400,
  lineHeight: 1.43,
  textAlign: 'left',
  color: theme.palette.text.dark,
  textTransform: 'uppercase',
}))
