import React from 'react'
import { ILXTeaser } from '../../../../../../types/cmsPlacement/LXTeaser'

import { CmsCountdown } from '../../../../CmsComponents/CmsCountdown'
import {
  BoxWithMarginCmsProductTile,
  BoxWithMarginTermsConditionButton,
  BoxWithMarginTermsConditionButtonContainer,
  IconContentBoxWithMargin,
  ProductTileContentBoxWithMargin,
  StyledBoxWithMarginArticle,
  StyledBoxWithMarginCTAContainer,
  StyledBoxWithMarginPreTitle,
  StyledBoxWithMarginTextContainer,
  StyledBoxWithMarginTitle,
} from '../BoxWithMarginBanner.style'

import CmsCta from '../../../../CmsComponents/CmsCta'
import useCmsTeaserBanner from '../../../../../../hooks/useCmsBanner'
import {
  getTeaserOverlayTextAlign,
  teaserBackGroundColorToBg,
} from '../../../../../../utils/placements'
import CmsIcon from '../../../../CmsComponents/CmsIcon'
import { useTheme } from '@mui/material/styles'
import { IViewType } from '../../../../../../types/cmsPlacement/ViewType'

interface IBoxWithMarginTextProps {
  teaserIndex?: number
  bannerIndex?: number
  viewType?: IViewType
  teaser?: ILXTeaser
  onTermsAndContitionButtonClick?: () => void
  termsAndConditionVisible?: boolean
}

export const BoxWithMarginText: React.FC<IBoxWithMarginTextProps> = ({
  teaser,
  viewType,
  onTermsAndContitionButtonClick,
  termsAndConditionVisible,
  teaserIndex,
  bannerIndex,
}) => {
  const theme = useTheme()

  const {
    teaserTitle,
    teaserText,
    teaserTitleValue,
    teaserTextValue,
    teaserOverlaySettingsValue,
    teaserOverlayTextAlignValue,
    teaserOverlayStyleValue,
    promoteToH1,
    teaserLXCallToActionSettings,
    teaserPreTitle,
    teaserIcon,
    teaserCountdownStart,
    teaserCountdownUntil,
    teaserHideExpiredCountdown,
    productData,
    isFrameAdvisor,
    teaserBackground,
  } =
    useCmsTeaserBanner({
      item: teaser,
      viewType,
    }) || {}

  const { backgroundColor } = teaserBackGroundColorToBg(teaserBackground, theme)
  const content = (
    <>
      {teaserIcon && (
        <IconContentBoxWithMargin marginProductEnabled={!!productData}>
          <CmsIcon teaserIcon={teaserIcon} />
        </IconContentBoxWithMargin>
      )}
      {teaserCountdownStart &&
        teaserCountdownUntil &&
        teaserHideExpiredCountdown && (
          <CmsCountdown
            teaserCountdownStart={teaserCountdownStart}
            teaserCountdownUntil={teaserCountdownUntil}
            teaserHideExpiredCountdown={teaserHideExpiredCountdown}
          />
        )}
      <>
        {teaserPreTitle && (
          <>
            <StyledBoxWithMarginPreTitle
              data-cm-metadata={'[{"_":"properties.teaserPreTitle"}]'}
              variant={promoteToH1 ? 'h1' : 'h2'}
              fontWeight={'600'}
              marginProductEnabled={!!productData}
            >
              {teaserPreTitle}
            </StyledBoxWithMarginPreTitle>
          </>
        )}
        {teaserTitleValue && (
          <StyledBoxWithMarginTitle
            data-cm-metadata={`[{"_":"properties.${teaserTitle}"}]`}
            variant={promoteToH1 ? 'h1' : 'h2'}
            marginProductEnabled={!!productData}
          >
            {teaserTitleValue}
          </StyledBoxWithMarginTitle>
        )}
        {teaserTextValue && (
          <StyledBoxWithMarginTextContainer
            data-cm-metadata={`[{"_":"properties.${teaserText}"}]`}
            dangerouslySetInnerHTML={{ __html: teaserTextValue }}
          />
        )}
      </>
      {!!productData && (
        <ProductTileContentBoxWithMargin>
          <BoxWithMarginCmsProductTile
            product={productData}
            tileIndex={0}
            backgroundColor={
              backgroundColor === 'transparent' ? '#ffffff' : backgroundColor
            }
            teaserOverlayStyle={teaserOverlayStyleValue}
          />
        </ProductTileContentBoxWithMargin>
      )}
      {!!teaserLXCallToActionSettings &&
        teaserLXCallToActionSettings?.length > 0 && (
          <StyledBoxWithMarginCTAContainer
            marginProductEnabled={!!productData}
            id="boxWithMarginCta"
            data-element-id={isFrameAdvisor && 'Footer_FrameGenius_Open'}
          >
            {teaserLXCallToActionSettings?.map((actionSettings, index) => (
              <CmsCta
                actionSettings={actionSettings}
                dataElementId={`${teaserIndex}Placement_Banner${bannerIndex}_CTA${
                  index + 1
                }`}
                key={`cms-content__text-module--cta-${index + 1}`}
              />
            ))}
          </StyledBoxWithMarginCTAContainer>
        )}
      {termsAndConditionVisible ? (
        <BoxWithMarginTermsConditionButtonContainer
          id={`boxWithMarginTermsCondition${teaserIndex}`}
        >
          <BoxWithMarginTermsConditionButton
            teaser={teaser!}
            onClick={onTermsAndContitionButtonClick}
          />
        </BoxWithMarginTermsConditionButtonContainer>
      ) : null}
    </>
  )

  return (
    <>
      <StyledBoxWithMarginArticle
        overlaysettings={teaserOverlaySettingsValue}
        overlaytxtalign={getTeaserOverlayTextAlign(teaserOverlayTextAlignValue)}
        teaseroverlaystyle={teaserOverlayStyleValue}
        teaserIndex={teaserIndex}
      >
        {content}
      </StyledBoxWithMarginArticle>
    </>
  )
}
