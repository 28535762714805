import React from 'react'
import { Button, ButtonAsLink, StyledAnchor } from '../../UI'
import { Trans, useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { useSite } from '../../../foundation/hooks/useSite'

export const TemporaryPasswordContent = styled('div', {
  name: 'ForgotPassword',
  slot: 'TemporaryPasswordContent',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(8),
  [theme.breakpoints.up('sm')]: {
    width: '50%',
  },
}))

export const SubmittedEmail = styled('div', {
  name: 'ForgotPassword',
  slot: 'SubmittedEmail',
})(({ theme }) => ({
  fontSize: 16,
  color: `${theme.palette.text.dark}`,
  fontWeight: 600,
}))

export const MessageContent = styled('div', {
  name: 'ForgotPassword',
  slot: 'MessageContent',
})({
  fontSize: 14,
  a: {
    fontWeight: 600,
  },
})

export const ButtonResetYouPasswordContent = styled('div', {
  name: 'ForgotPassword',
  slot: 'ButtonSubmitContent',
})(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(1),
  width: '100%',
}))

export const ResendEmailButton = styled(ButtonAsLink, {
  name: 'ForgotPassword',
  slot: 'ResendEmailButton',
})(() => ({
  fontSize: 14,
  fontWeight: 600,
}))

interface ForgotPasswordTemporaryProps {
  email: string
  isLoading: boolean
  setResetPasswordState: (e: boolean) => void
  handleSubmit: (e: any) => void
}

const ForgotPasswordTemporary: React.FC<ForgotPasswordTemporaryProps> = ({
  email,
  setResetPasswordState,
  isLoading,
  handleSubmit,
}) => {
  const { t } = useTranslation()
  const { mySite } = useSite()
  const resetPasswordButton = t('ForgotPassword.ResetPasswordButton')

  return (
    <TemporaryPasswordContent>
      <SubmittedEmail>{email}</SubmittedEmail>
      <MessageContent>
        <Trans i18nKey="ForgotPassword.Msgs.TemporaryPassword">
          <StyledAnchor
            external={true}
            to={`mailto:${mySite?.xStoreCfg?.customerServiceSupportEmailAddress}`}
          />
        </Trans>
      </MessageContent>
      <ButtonResetYouPasswordContent>
        <Button
          fullWidth={true}
          variant="primary"
          onClick={() => setResetPasswordState(true)}
          disabled={isLoading}
        >
          {resetPasswordButton}
        </Button>
      </ButtonResetYouPasswordContent>
      <ResendEmailButton onClick={handleSubmit}>
        {t('ForgotPassword.ResendEmail')}
      </ResendEmailButton>
    </TemporaryPasswordContent>
  )
}

export default ForgotPasswordTemporary
