import {
  DownloadReceiptWrapper,
  OnlineWrapper,
  OrderDetailsBillingAndPaymentListItem,
  OrderDetailsContentMapLink,
  OrderDetailsFooter,
  OrderDetailsFooterItem,
  OrderDetailsHeader,
  OrderDetailsHeaderDetails,
  OrderDetailsItemDetailsRow,
  OrderDetailsItemList,
  OrderDetailsListItem,
  OrderDetailsLoaderWrapper,
  OrderDetailsPriceDetailsItem,
  OrderDetailsPriceDetailsTotalItem,
  OrderDetailsPriceDetailsWrapper,
  OrderDetailsShippingPaymentDetails,
  OrderDetailsShippingPaymentDetailsTitle,
  OrderDetailsShipToStoreCompleteWrapper,
  OrderDetailsShipToStoreContentLink,
  OrderDetailsShipToStorePhoneLink,
  OrderDetailsShipToStoreTextBody,
  OrderDetailsWrapper,
  OrderReturnCopy,
  StyledDetailsDownloadReceipt,
  StyledDetailsTitle,
} from './OrderDetails.style'
import React, { FC, useEffect, useState } from 'react'
import { LinkAsButton, StyledLoader, StyledTypography } from '@components/UI'
import { isRxCart, parseRxOrderItems } from '@utils/rx'
import {
  useOrderDetails,
  useOrderDetailsLoadings,
} from '@hooks/useOrderDetails'
import DateService from '../../../services/DateService'
import { FETCH_ORDER_DETAILS_ACTION } from '../../../redux/actions/orderDetails'
import { IOrderDetails, Order, OrderAdjustmentCode } from '../../../types/order'
import get from 'lodash/get'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { OrderComponent } from './OrderComponent'
import {
  getOrderReturnedStatus,
  getOrderShippingPrice,
  getParsedOrderItems,
} from '@utils/order'
import { ORDER_ITEM_EXTEND_ATTRIBUTE_NAME_GETTERS } from '../../../constants/order'
import orderService from '../../../foundation/apis/transaction/order.service'
import { useSite } from '../../../foundation/hooks/useSite'
import { COLON_CHARACTER } from '../../../constants/ui'
import { DownloadIcon, EarthIcon, MapMarkIcon } from '@components/UI/Icons'
import FormattedPriceDisplay from '@components/formatted-price-display'
import { useShippingLabel } from 'src/foundation/hooks/useShippingLabel'
import { shipToStoreUtils } from '@utils/shipToStoreUtils'
import {
  PhysicalStoreGetDirectionContainer,
  PhysicalStoreMapAnchor,
} from '../../Checkout/components/CheckoutSummary.style'
import { BOOKAPPOINTMENT } from '../../../constants/routes'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'

interface OrderDetailsProps {
  orderId?: Order['orderId']
  xtrackingUrls?: Order['xtrackingUrls']
  trackingOrderDetails?: IOrderDetails
}

const OrderDetails: FC<OrderDetailsProps> = ({
  orderId,
  trackingOrderDetails,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { mySite } = useSite()
  const { langCode } = useStoreIdentity()
  const orderDetails = trackingOrderDetails || useOrderDetails(orderId!)
  const areOrderDetailsLoading = useOrderDetailsLoadings(orderId!)
  const { isDesktop } = useBreakpoints()
  const [downloadingReceipt, setDownloadReceiptStatus] =
    useState<boolean>(false)
  const [orderShippingAddress, setOrderShippingAddress] = useState<string>('')
  const [orderBillingAddress, setOrderBillingAddress] = useState<string>('')
  const { getShippingCompleteLabel } = useShippingLabel()
  const contactPageUrl = `/${langCode}/c/informazioni-utili/contatti`

  const {
    totalProductPrice: subtotal,
    totalProductPriceCurrency: subtotalCurrency,
    totalShippingCharge: shipping,
    totalShippingChargeCurrency: shippingCurrency,
    grandTotal,
    grandTotalCurrency,
  } = orderDetails || {}

  const savingsAmount = orderDetails?.adjustment?.find(
    (a) => a.usage === OrderAdjustmentCode.DISCOUNT
  )?.amount
  const codSurchargeAmount = orderDetails?.adjustment?.find(
    (a) => a.usage === OrderAdjustmentCode.COD_SURCHARGE
  )?.amount

  const shippingCost = orderDetails ? getOrderShippingPrice(orderDetails) : 0
  const shippingName = get(orderDetails, 'orderItem[0].shipModeCode')

  useEffect(() => {
    if (orderDetails) {
      setOrderBillingAddress(
        [
          orderDetails.paymentInstruction?.[0]?.firstName,
          orderDetails.paymentInstruction?.[0]?.lastName,
          orderDetails.paymentInstruction?.[0]?.addressLine?.[0],
          orderDetails.paymentInstruction?.[0]?.city,
          orderDetails.paymentInstruction?.[0]?.zipCode,
          orderDetails.paymentInstruction?.[0]?.country,
        ].join(' ')
      )

      setOrderShippingAddress(
        [
          orderDetails.orderItem?.[0]?.firstName,
          orderDetails.orderItem?.[0]?.lastName,
          orderDetails.orderItem?.[0]?.addressLine?.[0],
          orderDetails.orderItem?.[0]?.city,
          orderDetails.orderItem?.[0]?.zipCode,
          orderDetails.orderItem?.[0]?.country,
        ].join(' ')
      )
    }
  }, [orderDetails])

  useEffect(() => {
    if (orderId && !trackingOrderDetails) {
      dispatch(FETCH_ORDER_DETAILS_ACTION({ orderId, skipErrorSnackbar: true }))
    }
  }, [orderId])

  const PARSED_ORDER_ITEMS = isRxCart(orderDetails?.orderExtendAttribute || [])
    ? parseRxOrderItems(
        getParsedOrderItems(orderDetails?.detailedOrderItems || []) || []
      )
    : getParsedOrderItems(orderDetails?.detailedOrderItems || [])

  if (areOrderDetailsLoading) {
    return (
      <OrderDetailsLoaderWrapper>
        <StyledLoader size={100} />
      </OrderDetailsLoaderWrapper>
    )
  }

  const hasTrackingInformation = PARSED_ORDER_ITEMS?.map(
    (order) =>
      order?.orderItemExtendAttribute?.find(
        (a) =>
          a.attributeName ===
          ORDER_ITEM_EXTEND_ATTRIBUTE_NAME_GETTERS.TRACK_NUMBER(
            orderDetails?.orderId || ''
          )
      )?.attributeValue
  ).filter((t) => t).length

  const trackUrlsMap =
    orderDetails?.orderItem?.reduce((acc, item) => {
      if (orderDetails?.xtrackingUrls[item.orderItemId]) {
        acc[item.orderItemId] = orderDetails?.xtrackingUrls[item.orderItemId]
      }
      return acc
    }, {}) || {}

  const onDownloadReceiptClick = async () => {
    try {
      setDownloadReceiptStatus(true)
      const file = await orderService.requestInvoice({
        siteInfo: mySite,
        orderId: orderDetails?.orderId,
      })
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL)
      setDownloadReceiptStatus(false)
    } catch (e) {
      setDownloadReceiptStatus(false)
    }
  }

  const orderItemsStatuses = PARSED_ORDER_ITEMS?.map((o) =>
    getOrderReturnedStatus(o)
  ).filter((el) => el !== null)

  const orderStatus = (
    orderItemsStatuses?.length === PARSED_ORDER_ITEMS?.length &&
    Boolean(orderDetails?.orderItem)
      ? orderItemsStatuses?.[0]
      : orderDetails?.orderStatus
  ) as string

  const isCompletedOrder = orderStatus === '1'

  const shippingFormShipToStore =
    shipToStoreUtils.getExtendAttributeShipToStore(
      orderDetails?.orderExtendAttribute || []
    )
  const isShipToStore = shipToStoreUtils.isShipToStore(
    orderDetails?.detailedOrderItems!
  )

  const orderItem = orderDetails?.detailedOrderItems?.find(
    (orderItem) => !!orderItem
  )

  const physicalStoreInfo = shipToStoreUtils.getPhysicalStoreSelectedInfo(
    orderItem!
  )

  return (
    <OrderDetailsWrapper>
      <OrderDetailsHeader>
        <OrderDetailsHeaderDetails>
          <OnlineWrapper>
            <EarthIcon />
            <StyledTypography fontSize={14}>
              {t('Order.Online')}
            </StyledTypography>
          </OnlineWrapper>
          <StyledDetailsTitle isUppercased variant="caption">
            <span>
              {t('Order.OrderNumber')}
              {COLON_CHARACTER}
            </span>
            {'\u00A0'}
            {orderDetails?.orderId}
          </StyledDetailsTitle>
          <StyledDetailsTitle isUppercased variant="caption">
            <span>
              {t('Order.OrderDate')}
              {COLON_CHARACTER}
            </span>
            {'\u00A0'}
            {DateService.format(orderDetails?.placedDate || '', 'dd/MM/yyyy')}
          </StyledDetailsTitle>
          <StyledDetailsTitle isUppercased>
            <span>
              {t('Order.Status')}
              {COLON_CHARACTER}
            </span>
            {'\u00A0'}
            {t(`Order.Status_.${orderStatus}`)}
          </StyledDetailsTitle>
        </OrderDetailsHeaderDetails>
        <OrderDetailsHeaderDetails style={{ gap: 16 }}>
          <LinkAsButton
            data-element-id="TrackMyOrder_ContactUs"
            data-description="Contattaci"
            to={contactPageUrl}
            fillType={'outline'}
          >
            {t('Order.ContactUs')}
          </LinkAsButton>
          {orderDetails?.x_displayinvoice && (
            <>
              {downloadingReceipt && <StyledLoader size={24} />}
              {!downloadingReceipt && (
                <DownloadReceiptWrapper>
                  <DownloadIcon />
                  <StyledDetailsDownloadReceipt
                    onClick={onDownloadReceiptClick}
                  >
                    {t('Order.DownloadReceipt')}
                  </StyledDetailsDownloadReceipt>
                </DownloadReceiptWrapper>
              )}
            </>
          )}
        </OrderDetailsHeaderDetails>
      </OrderDetailsHeader>

      {isCompletedOrder && (
        <OrderReturnCopy
          dangerouslySetInnerHTML={{
            __html: t(
              `Order.${isDesktop ? 'OrderIssuesDesktop' : 'OrderIssues'}`
            ),
          }}
        />
      )}
      {isCompletedOrder && isShipToStore && (
        <OrderDetailsShipToStoreCompleteWrapper>
          <StyledTypography fontWeight={'bold'} fontSize={16}>
            {t('Order.OrderShipToStoreCompleteTitle')}
          </StyledTypography>
          <StyledTypography fontWeight={'bold'} fontSize={12}>
            {orderShippingAddress}
          </StyledTypography>
          <OrderDetailsShipToStoreTextBody fontSize={12}>
            {t('Order.OrderShipToStoreCompleteBody')}
          </OrderDetailsShipToStoreTextBody>
          <OrderDetailsShipToStoreContentLink>
            <LinkAsButton
              to={`/${langCode}/${BOOKAPPOINTMENT}?storeNumber=${physicalStoreInfo.physicalStoreExternalId}`}
              fillType={'outline'}
            >
              {t('Order.OrderShipToStoreCompleteButton')}
            </LinkAsButton>
            <OrderDetailsContentMapLink>
              <OrderDetailsShipToStorePhoneLink
                external
                to={`tel:${physicalStoreInfo.telephone}`}
              >
                {physicalStoreInfo.telephone}
              </OrderDetailsShipToStorePhoneLink>
              <PhysicalStoreGetDirectionContainer style={{ paddingTop: 0 }}>
                <MapMarkIcon />
                <PhysicalStoreMapAnchor
                  target="_blank"
                  rel="noopener noreferrer"
                  external
                  to={`https://www.google.com/maps?q= GrandVision ${physicalStoreInfo.address}, ${physicalStoreInfo.city}`}
                >
                  {t('StoreDetails.Labels.Directions')}
                </PhysicalStoreMapAnchor>
              </PhysicalStoreGetDirectionContainer>
            </OrderDetailsContentMapLink>
          </OrderDetailsShipToStoreContentLink>
        </OrderDetailsShipToStoreCompleteWrapper>
      )}
      <OrderDetailsItemList>
        {PARSED_ORDER_ITEMS?.map((orderItem) => {
          const trackUrlLink = trackUrlsMap[orderItem.orderItemId] || ''
          return (
            <OrderComponent
              key={orderItem.orderItemId}
              orderItem={orderItem}
              orderDetails={orderDetails!}
              hasTrackingInformation={!!hasTrackingInformation}
              orderStatus={orderStatus}
              trackUrlLink={trackUrlLink}
            />
          )
        })}{' '}
      </OrderDetailsItemList>
      <OrderDetailsFooter>
        <OrderDetailsShippingPaymentDetails>
          <OrderDetailsShippingPaymentDetailsTitle>
            {t('Order.ShippingPaymentDetails')}
          </OrderDetailsShippingPaymentDetailsTitle>
          <OrderDetailsItemDetailsRow>
            <OrderDetailsListItem>
              {t('Order.ShippingMethod')}
              {COLON_CHARACTER}
              {'\u00A0'}
            </OrderDetailsListItem>
            <OrderDetailsFooterItem>
              <span>{getShippingCompleteLabel(shippingName || '')}</span>
              {isShipToStore && (
                <>
                  <br />
                  <span>{orderShippingAddress}</span>
                </>
              )}
            </OrderDetailsFooterItem>
          </OrderDetailsItemDetailsRow>
          <OrderDetailsItemDetailsRow>
            <OrderDetailsListItem>
              {t('Order.ShippingAddress')}
              {COLON_CHARACTER}
              {'\u00A0'}
            </OrderDetailsListItem>
            <OrderDetailsFooterItem>
              {isShipToStore ? (
                <>
                  <span>{shippingFormShipToStore.fullName}</span>
                  <br />
                  <span>{shippingFormShipToStore.email}</span>
                  <br />
                  <span>{shippingFormShipToStore.telephone}</span>
                </>
              ) : (
                orderShippingAddress
              )}
            </OrderDetailsFooterItem>
          </OrderDetailsItemDetailsRow>
          <OrderDetailsItemDetailsRow>
            <OrderDetailsBillingAndPaymentListItem>
              {t('Order.BillingAddress')}
              {COLON_CHARACTER}
              {'\u00A0'}
            </OrderDetailsBillingAndPaymentListItem>
            <OrderDetailsFooterItem>
              {orderBillingAddress}
            </OrderDetailsFooterItem>
          </OrderDetailsItemDetailsRow>
          <OrderDetailsItemDetailsRow>
            <OrderDetailsBillingAndPaymentListItem>
              {t('Order.PaymentMethod')}
              {COLON_CHARACTER}
              {'\u00A0'}
            </OrderDetailsBillingAndPaymentListItem>
            <OrderDetailsFooterItem>
              {orderDetails?.paymentInstruction?.[0].piDescription}
            </OrderDetailsFooterItem>
          </OrderDetailsItemDetailsRow>
        </OrderDetailsShippingPaymentDetails>
        <OrderDetailsPriceDetailsWrapper>
          <OrderDetailsItemDetailsRow>
            <OrderDetailsListItem>
              {t('Order.Subtotal')}
              {COLON_CHARACTER}
              {'\u00A0'}
            </OrderDetailsListItem>
            <OrderDetailsPriceDetailsItem>
              <FormattedPriceDisplay
                min={subtotal ? +subtotal : 0}
                currency={subtotalCurrency}
              />
            </OrderDetailsPriceDetailsItem>
          </OrderDetailsItemDetailsRow>
          {savingsAmount && (
            <OrderDetailsItemDetailsRow>
              <OrderDetailsListItem>
                {t('Order.Savings')}
                {COLON_CHARACTER}
                {'\u00A0'}
              </OrderDetailsListItem>
              <OrderDetailsPriceDetailsItem>
                <FormattedPriceDisplay
                  min={+savingsAmount}
                  currency={subtotalCurrency}
                />
              </OrderDetailsPriceDetailsItem>
            </OrderDetailsItemDetailsRow>
          )}
          <OrderDetailsItemDetailsRow>
            <OrderDetailsListItem>
              {t('Order.ShippingCost')}
              {COLON_CHARACTER}
              {'\u00A0'}
            </OrderDetailsListItem>
            <OrderDetailsPriceDetailsItem>
              {shippingCost === 0 ? (
                t('OrderTotalSummary.Labels.FreeShipping')
              ) : (
                <FormattedPriceDisplay
                  min={shipping ? +shipping : 0}
                  currency={shippingCurrency}
                />
              )}
            </OrderDetailsPriceDetailsItem>
          </OrderDetailsItemDetailsRow>
          {codSurchargeAmount && (
            <OrderDetailsItemDetailsRow>
              <OrderDetailsListItem>
                {t('Order.CODSurchargeCost')}
                {COLON_CHARACTER}
                {'\u00A0'}
              </OrderDetailsListItem>
              <OrderDetailsPriceDetailsItem>
                <FormattedPriceDisplay
                  min={+codSurchargeAmount}
                  currency={subtotalCurrency}
                />
              </OrderDetailsPriceDetailsItem>
            </OrderDetailsItemDetailsRow>
          )}
          <OrderDetailsItemDetailsRow>
            <OrderDetailsListItem>
              {t('Order.OrderTotal')}
              {COLON_CHARACTER}
              {'\u00A0'}
            </OrderDetailsListItem>
            <OrderDetailsPriceDetailsTotalItem>
              <FormattedPriceDisplay
                min={grandTotal ? +grandTotal : 0}
                currency={grandTotalCurrency}
              />
            </OrderDetailsPriceDetailsTotalItem>
          </OrderDetailsItemDetailsRow>
        </OrderDetailsPriceDetailsWrapper>
      </OrderDetailsFooter>
    </OrderDetailsWrapper>
  )
}

export default OrderDetails
