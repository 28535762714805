import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import listenerMiddleware from '../../redux/listener'
import { userSegmentsApi } from './query'
import {
  GUEST_LOGIN_SUCCESS_ACTION,
  LOGIN_SUCCESS_ACTION,
  LOGOUT_SUCCESS_ACTION,
  REGISTRATION_SUCCESS_ACTION,
} from '../../redux/actions/user'
import { siteApi } from '../site/query'
import Log from '../../services/Log'
import { RootReducerState } from '../../redux/reducers'
import {
  getCustomerSegmentsfromCookie,
  setCustomerSegmentCookie,
  sortSegments,
} from '@utils/userSegments'
import { USER_SEGMENT_GUEST } from '../../constants/common'
import { AxiosResponse } from 'axios'
import guestIdentityService from '../../foundation/apis/transaction/guestIdentity.service'
import { localStorageUtil } from '../../foundation/utils/storageUtil'
import { PERSONALIZATION_ID } from '../../foundation/constants/user'
import { pageType } from '../ui/slice'

export interface UserSegmentsState {
  customerSegments: string[]
}

export type CustomerSegmentsResponse = {
  customerSegments: string[]
}
export const initialCustomerSegmentsState: UserSegmentsState = {
  customerSegments: getCustomerSegmentsfromCookie(),
}

const userSegmentSlice = createSlice({
  name: 'userSegments',
  initialState: initialCustomerSegmentsState,
  reducers: {
    updateSegments: (
      state: UserSegmentsState,
      action: PayloadAction<CustomerSegmentsResponse['customerSegments']>
    ) => {
      state.customerSegments = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userSegmentsApi.endpoints?.getCustomerSegments.matchFulfilled,
      (
        state: UserSegmentsState,
        { payload }: PayloadAction<CustomerSegmentsResponse>
      ) => {
        state.customerSegments = payload?.customerSegments
      }
    )
  },
})

export const customerSegmentsRetrievedListener = (
  startListening: typeof listenerMiddleware.startListening
) => {
  startListening({
    matcher: isAnyOf(
      userSegmentsApi.endpoints?.getCustomerSegments.matchFulfilled
    ),
    effect: async (
      action: PayloadAction<CustomerSegmentsResponse>,
      listenerApi
    ) => {
      const { payload: segments } = action
      const state = listenerApi.getState() as RootReducerState
      const {
        site: { currentSite },
      } = state
      const { customerSegmentsCookieExpiry, customerSegmentsCookiePriority } =
        currentSite?.xStoreCfg! || {}
      let orderedSegments: string[] = [USER_SEGMENT_GUEST]
      orderedSegments.unshift(
        ...(customerSegmentsCookiePriority
          ? sortSegments(
              customerSegmentsCookiePriority,
              segments?.customerSegments
            )
          : segments?.customerSegments)
      )

      listenerApi.dispatch(updateSegments(orderedSegments))
      setCustomerSegmentCookie(orderedSegments, customerSegmentsCookieExpiry)
    },
  })
}
/*
 * listen for site initialized and look for promo paramas in the url
 * */
export const customerSegmentsCampaignListener = (
  startListening: typeof listenerMiddleware.startListening
) => {
  startListening({
    matcher: isAnyOf(
      siteApi.endpoints?.initializeSite.matchFulfilled,
      pageType, //refetch segments on page change
      REGISTRATION_SUCCESS_ACTION,
      LOGOUT_SUCCESS_ACTION,
      LOGIN_SUCCESS_ACTION
    ),
    effect: async (_action, listenerApi) => {
      try {
        const state = listenerApi.getState() as RootReducerState
        const {
          site: { currentSite },
        } = state
        const customerSegmentsEnabled =
          currentSite?.xStoreCfg.customerSegmentsEnabled || false
        const promoParam = new URLSearchParams(window.location.search).get(
          'promo'
        )

        if (!customerSegmentsEnabled) return

        if (!!promoParam) {
          if (!localStorageUtil.get(PERSONALIZATION_ID)) {
            //TODO: port this to rtk
            const guestIdentity: AxiosResponse =
              await guestIdentityService.login({})
            listenerApi.dispatch(
              GUEST_LOGIN_SUCCESS_ACTION({ ...guestIdentity.data })
            )
          }

          await listenerApi.dispatch(
            userSegmentsApi.endpoints?.getWebActivity.initiate({
              espotName: promoParam,
            })
          )
        }

        await listenerApi.dispatch(
          userSegmentsApi.endpoints?.getCustomerSegments.initiate()
        )
      } catch (e: any) {
        Log.error('error in customerSegmentsCampaignListener', e)
      }
    },
  })
}
/*
export const customerSegmentsUserListeners = (
  startListening: typeof listenerMiddleware.startListening
) => {
  startListening({
    matcher: isAnyOf(
      //GUEST_LOGIN_SUCCESS_ACTION,
      REGISTRATION_SUCCESS_ACTION,
      LOGOUT_SUCCESS_ACTION,
      LOGIN_SUCCESS_ACTION,
      setCurrentPageType
    ),
    effect: async (action, listenerApi) => {
      listenerApi.dispatch(
        userSegmentsApi.endpoints?.getCustomerSegments.initiate()
      )
    },
  })
}*/

const { reducer } = userSegmentSlice
// Extract and export each action creator by name
export const { updateSegments } = userSegmentSlice.actions
export default reducer
