import { styled } from '@mui/material/styles'
import { ButtonAsLink } from '@components/UI'

export const WrapperSignInForm = styled('div', {
  name: 'SignInFormDialog',
  slot: 'Wrapper',
})(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(0, 65),
  },
}))
export const StyledForm = styled('form', {
  name: 'SignIn',
  slot: 'Form',
})(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: '50%',
  },
}))

export const ContentTextField = styled('div', {
  name: 'SignIn',
  slot: 'ContentTextField',
})(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(9),
  padding: theme.spacing(4, 0),
  flexDirection: 'column',
}))

export const ContentButton = styled('div', {
  name: 'SignIn',
  slot: 'ContentButton',
})(({ theme }) => ({
  marginTop: theme.spacing(10),
}))

export const ErrorMessage = styled('div', {
  name: 'SignIn',
  slot: 'ErrorMessage',
})(({ theme }) => ({
  color: theme.palette.error.main,
}))

export const ContentForgotPassword = styled('div', {
  name: 'SignIn',
  slot: 'ContentForgotPassword',
})(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}))

export const FastLoginCheckoutContainer = styled('div', {
  name: 'SignInFormDialog',
  slot: 'FastLoginContainer',
})(({ theme }) => ({
  height: 24,
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'stretch',
  flexGrow: 0,
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(4),
  padding: theme.spacing(5),
}))

export const FastLoginCheckoutContent = styled('span', {
  name: 'SignInFormDialog',
  slot: 'FastLoginContent',
})(({ theme }) => ({
  flexGrow: 0,
  fontSize: 16,
  lineHeight: 1.5,
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(4),
}))

export const SignInFormDialogCTAContainer = styled('div', {
  name: 'SignInFormDialog',
  slot: 'CTAContainer',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 14,
  marginTop: theme.spacing(8),
}))

export const SignInFormDialogAuthCTA = styled(ButtonAsLink, {
  name: 'SignInFormDialog',
  slot: 'AuthCTA',
})(() => ({
  fontWeight: 600,
}))
