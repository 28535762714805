import { styled } from '@mui/material/styles'
import { StyledTypography } from '@components/UI'

export const WrapperNotFound = styled('div', {
  name: 'NotFound',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(8, 4),

  [theme.breakpoints.up('md')]: {
    justifyContent: 'center',
    padding: theme.spacing(22, 4),
  },
}))

export const ContentBodyNotFound = styled('div', {
  name: 'NotFound',
  slot: 'ContentBody',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(10),
  padding: theme.spacing(0, 6.5),
  alignItems: 'center',
}))

export const TitleNotFound = styled(StyledTypography, {
  name: 'NotFound',
  slot: 'Title',
})(({ theme }) => ({
  color: theme.palette.text.dark,
}))

export const SubTitleNotFound = styled(StyledTypography, {
  name: 'NotFound',
  slot: 'SubTitle',
})(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.text.dark,
  maxWidth: '80%',
  [theme.breakpoints.up('md')]: {
    maxWidth: '50%',
  },
}))

export const ParagraphNotFound = styled(StyledTypography, {
  name: 'NotFound',
  slot: 'Paragraph',
})(({ theme }) => ({
  maxWidth: '90%',
  textAlign: 'center',
  color: theme.palette.text.dark,
}))

export const ContentButtonsNotFound = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
}))

export const UnderParagraphNotFound = styled('p', {
  name: 'NotFound',
  slot: 'UnderParagraph',
})(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.text.dark,
  fontSize: 12,
  a: {
    color: theme.palette.text.dark,
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))
