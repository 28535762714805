import './styles/index.scss'

import * as ROUTES from './constants/routes'

import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Navigate, Route, Routes } from 'react-router-dom'
//Standard libraries
import React, { Dispatch, lazy, useEffect, useState } from 'react'

import Log from './services/Log'
import { StoreAppContainer } from './layouts/StoreAppContainer'
import config from './configs'
import { countryUtil } from '@utils/countryUtil'
import { fetchCountryListDetails } from './features/account/thunks'
import { initAxios } from './foundation/axios/axiosConfig'
import { useDispatch } from 'react-redux'
import { useSite } from './foundation/hooks/useSite'
import { BaseLayoutSkeleton } from './layouts/BaseLayout'

const PreviewLayout = lazy(() => import('./pages/Preview/Preview'))
const Maintenance = lazy(() => import('./pages/Maintenance'))

const App: React.FC = () => {
  const dispatch = useDispatch<Dispatch<any>>()
  const { currentLangCode } = countryUtil('', config.defaultLocale)
  const { mySite } = useSite()

  const [isLoaded, setIsLoaded] = useState(false)

  initAxios(dispatch)

  useEffect(() => {
    const storeId = mySite?.storeID
    if (!storeId) {
      return
    }
    dispatch(
      fetchCountryListDetails({
        storeId,
      })
    )
  }, [dispatch, mySite?.storeID])

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  Log.info('Init Axios in App.tsx')
  Log.info('App start in App.tsx')

  if (!isLoaded) {
    return <BaseLayoutSkeleton />
  }

  return (
    <TransitionGroup component={null}>
      <CSSTransition classNames="fade" timeout={250}>
        <Routes>
          <Route path={ROUTES.MAINTENANCE} element={<Maintenance />} />
          <Route path={ROUTES.CMSPREVIEW} element={<PreviewLayout />} />
          <Route path="/:country/*" element={<StoreAppContainer />} />
          <Route path="/" element={<Navigate to={`/${currentLangCode}`} />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default App
