// Standard libraries
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

//UI
import { FindStore } from '@components/FindStore'
import {
  AccountStoresTitle,
  AccountStoresBodyWrapper,
  AccountStoresList,
  AccountStoresCard,
  AccountStoresCardName,
  AccounStoresCardContent,
  MapContainer,
  AccountStoreAddress,
  AccountStoreInfo,
  LinkStoreInfo,
  LinksContainer,
  StyledDivider,
  AccountStoresCardHeader,
  BookEyeTestButton,
  StyledCheckmarkIcon,
  DefaultStoreText,
  ToggleDefaultStore,
  MapImage,
  AccountStoresCheckboxContainer,
  StyledButton,
  AccountStoresMarkerIconContainer,
} from './AccountStores.style'
import { StyledCheckbox } from '@components/UI'
import { useFavoriteStores } from '@hooks/useFavoriteStores'
import isEmpty from 'lodash/isEmpty'
import { useFavoriteStoresMethods } from '@hooks/useFavoriteStoresMethods'
import { generateStaticMapUrl } from '@utils/staticMapUrl'
import { useSelector } from 'react-redux'
import { googleApiKeySelector } from '../../redux/selectors/site'
import { Store } from '../../types/Tab/store'
import Skeleton from 'react-loading-skeleton'
import useBreakpoints from '../../hooks/useBreakpoints'
import { APP_MAIN_CONTAINER_ID } from '../../constants/ui'
import { BOOKAPPOINTMENT } from '../../constants/routes'
import { useTheme } from '@mui/material'
import { StoreMarkerIcon } from '@components/UI/Icons'
import { generatePath, useNavigate, createSearchParams } from 'react-router-dom'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useAnalyticsData } from 'src/foundation/analytics/hooks/useAnalyticsData'
import { sendAccountInformationEvent } from 'src/foundation/analytics/tealium/lib'

export const StoreInfo: React.FC<{
  store: Store
  hideBookEyeBtn?: boolean
  className?: string
  isBoldStoreHours?: boolean
}> = ({ store, className, hideBookEyeBtn = false }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { langCode } = useStoreIdentity()

  const bookAnEyeTestHandler = () => {
    navigate({
      pathname: generatePath(`/:country/${BOOKAPPOINTMENT}`, {
        country: langCode,
      }),
      search: createSearchParams({
        storeNumber: store.storeId.toString(),
      }).toString(),
    })
  }

  const urlGoogleStore = `https://www.google.com/maps?q=GrandVision ${store.address}, ${store.city}`

  return (
    <AccountStoreInfo className={className}>
      <AccountStoreAddress>{store.completeAddress}</AccountStoreAddress>
      <LinksContainer>
        {store.telephone && (
          <LinkStoreInfo external to={`tel:${store.telephone}`}>
            {store.telephone}
          </LinkStoreInfo>
        )}
        <LinkStoreInfo
          target="_blank"
          rel="noopener noreferrer"
          external
          to={urlGoogleStore}
        >
          {t('AccountStores.GetDirection')}
        </LinkStoreInfo>
      </LinksContainer>
      {/*  <StyledDivider />
      <StoreHoursTitle className={'store-info__store-hours-title'}>
        {t('AccountStores.StoreHoursTitle')}
      </StoreHoursTitle>
          <StoreOpeningHoursWrapper>
        <StoreOpeningHours store={store} isBold={isBoldStoreHours} />
      </StoreOpeningHoursWrapper> */}
      {!hideBookEyeBtn && (
        <BookEyeTestButton onClick={bookAnEyeTestHandler}>
          {t('AccountStores.BookEyeTest')}
        </BookEyeTestButton>
      )}
    </AccountStoreInfo>
  )
}

const AccountStores: FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const googleMapsApiKey = useSelector(googleApiKeySelector)
  const analyticsData = useAnalyticsData('profile')

  const { favoriteStores, defaultFavoriteStoreId } = useFavoriteStores()
  const { changeDefaultFavoriteStore, removeFavoriteStore, isLoading } =
    useFavoriteStoresMethods()

  const { isMobile } = useBreakpoints()

  const favoriteStoresSorted = favoriteStores && [
    ...favoriteStores.filter((s) => `${s.storeId}` === defaultFavoriteStoreId),
    ...favoriteStores.filter((s) => `${s.storeId}` !== defaultFavoriteStoreId),
  ]

  const setDefaultStore = (id: string) => {
    changeDefaultFavoriteStore(id)

    const appMainContainerElement = document.getElementById(
      APP_MAIN_CONTAINER_ID
    )
    appMainContainerElement?.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    sendAccountInformationEvent({
      common: analyticsData,
      Page_Section2: 'MyStores',
    })
  }, [])

  if (isLoading) {
    return (
      <AccountStoresBodyWrapper>
        <>
          {new Array(
            favoriteStoresSorted?.length === 0
              ? 6
              : favoriteStoresSorted?.length
          )
            .fill(1)
            .map((_, index) => (
              <div key={index}>
                <Skeleton
                  style={{
                    height: isMobile ? '760px' : '360px',
                    width: '100%',
                  }}
                />
              </div>
            ))}
        </>
      </AccountStoresBodyWrapper>
    )
  }

  return (
    <AccountStoresBodyWrapper>
      <AccountStoresTitle> {t('AccountStores.Title')}</AccountStoresTitle>
      {isEmpty(favoriteStoresSorted) ? (
        <FindStore />
      ) : (
        <>
          <FindStore />
          <AccountStoresList>
            <AccountStoresTitle>
              {t('AccountStores.SavedStores')}
            </AccountStoresTitle>
            {favoriteStoresSorted?.map((store) => {
              const isMarkerAvailable = store.latitude !== ''
              const staticMapUrl = generateStaticMapUrl(
                store,
                googleMapsApiKey,
                isMarkerAvailable
              )
              return (
                <AccountStoresCard key={store.storeId}>
                  <ToggleDefaultStore>
                    {defaultFavoriteStoreId === `${store.storeId}` ? (
                      <>
                        <StyledCheckmarkIcon
                          htmlColor={theme.palette.primary.main}
                        />
                        <DefaultStoreText>
                          {t('AccountStores.DefaultStore')}
                        </DefaultStoreText>
                      </>
                    ) : (
                      <>
                        <AccountStoresCheckboxContainer>
                          <StyledCheckbox
                            onClick={() => setDefaultStore(`${store.storeId}`)}
                          />
                        </AccountStoresCheckboxContainer>
                        <DefaultStoreText>
                          {t('AccountStores.SetDefaultStore')}
                        </DefaultStoreText>
                      </>
                    )}
                  </ToggleDefaultStore>
                  <AccountStoresCardHeader>
                    <AccountStoresCardName>
                      {t('StoreDetails.Labels.StoreBrand')}
                    </AccountStoresCardName>
                    <StyledButton
                      fillType={'outline'}
                      onClick={() => removeFavoriteStore(`${store.storeId}`)}
                    >
                      {t('AccountStores.Remove')}
                    </StyledButton>
                  </AccountStoresCardHeader>
                  <StyledDivider />
                  <AccounStoresCardContent>
                    <MapContainer>
                      <MapImage src={staticMapUrl} alt={store.storeName} />
                      {!isMarkerAvailable && (
                        <AccountStoresMarkerIconContainer>
                          <StoreMarkerIcon />
                        </AccountStoresMarkerIconContainer>
                      )}
                    </MapContainer>
                    <StoreInfo store={store} isBoldStoreHours={true} />
                  </AccounStoresCardContent>
                </AccountStoresCard>
              )
            })}
          </AccountStoresList>
        </>
      )}
    </AccountStoresBodyWrapper>
  )
}

export default AccountStores
