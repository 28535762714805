import { styled } from '@mui/material/styles'
import { ButtonAsLink } from '../../../UI'
import { ITeaserOverlayStyle } from '../../../../types/cmsPlacement/LXTeaser'
import { teaserOverlayStyleToColor } from '@utils/placements'
import { Z_INDEX_LV4 } from '../../../../constants/ui'
export const StyledTermsConditionWrapper = styled('div', {
  name: 'TermsCondition',
  slot: 'Wrapper',
  //shouldForwardProp: (prop) => prop !== 'isLandscape',
})<{
  //isLandscape?: boolean
}>(({ theme }) => ({
  //avoid toast border to show on bottom
  position: 'absolute',
  bottom: -1,
  zIndex: Z_INDEX_LV4,
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  marginTop: 0,
  [theme.breakpoints.up('md')]: {
    right: '0',
  },
  '.right': {
    textAlign: 'right',
  },
  '.center': {
    textAlign: 'center',
  },
}))

export const StyledDetailsInfo = styled(ButtonAsLink, {
  name: 'TermsCondition',
  slot: 'ButtonAsLink',
})<{
  //isBoxWithMargin?: boolean
  teaseroverlaystyle?: ITeaserOverlayStyle
}>(({ theme, teaseroverlaystyle }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  ...teaserOverlayStyleToColor(theme, teaseroverlaystyle),
  cursor: 'pointer',
  textDecoration: 'underline',
  '&::after': {
    display: 'none',
  },

  [theme.breakpoints.up('md')]: {
    ...teaserOverlayStyleToColor(theme, teaseroverlaystyle),
    fontSize: theme.spacing(3.5),
    fontWeight: 600,
    '&:hover': {
      ...teaserOverlayStyleToColor(theme, teaseroverlaystyle),
    },
  },
}))
