import { styled } from '@mui/material/styles'
import CmsBroadContentWrapper from '../../../CmsComponents/CmsBroadContentWrapper'
import {
  ITeaserOverlayStyle,
  ITeaserOverlaySettings,
  ITeaserOverlayTextAlign,
} from '../../../../../types/cmsPlacement/LXTeaser'
import {
  overLaySettingsToVerticalCSS,
  overLayTextAlignToCSS,
  teaserOverlayStyleToColor,
} from '@utils/placements'
import { StyledTypography } from '../../../../UI'
import CmsTeaserTitle from '../../../CmsComponents/CmsTeaserTitle'
import CmsTeaserDetailText from '../../../CmsComponents/CmsTeaserDetailText'
import { PropsWithChildren } from 'react'

export const WrapperWebFormModule = styled(CmsBroadContentWrapper, {
  name: 'WebFormModule',
  slot: 'Content',
  shouldForwardProp: (prop) =>
    prop !== 'overlayTextAlign' &&
    prop !== 'overlaySettings' &&
    prop !== 'teaserOverlayStyle',
})<
  PropsWithChildren<{
    overlaySettings?: ITeaserOverlaySettings
    overlayTextAlign?: ITeaserOverlayTextAlign
    teaserOverlayStyle?: ITeaserOverlayStyle
  }>
>(({ overlaySettings, overlayTextAlign, teaserOverlayStyle, theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    ...overLaySettingsToVerticalCSS(overlaySettings || ''),
  },

  '& > *': {
    ...overLayTextAlignToCSS(overlayTextAlign),
    ...teaserOverlayStyleToColor(theme, teaserOverlayStyle),
  },
}))

export const ContentWebFormModule = styled('article', {
  name: 'WebFormModule',
  slot: 'Content',
  shouldForwardProp: (prop) => prop !== 'removePaddingTop',
})<{ removePaddingTop?: boolean }>(({ removePaddingTop, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  padding: theme.spacing(removePaddingTop ? 0 : 10, 4, 10),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(removePaddingTop ? 0 : 10, 24, 10),
  },

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(removePaddingTop ? 0 : 12, 44, 12),
  },

  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(removePaddingTop ? 0 : 16, 44, 16),
  },
}))

export const PreTitleWebFormModule = styled(StyledTypography, {
  name: 'WebFormModule',
  slot: 'PreTitle',
})(() => ({
  fontSize: 16,
}))

export const TitleWebFormModule = styled(CmsTeaserTitle, {
  name: 'WebFormModule',
  slot: 'Title',
})(({}) => ({
  fontSize: 28,
  lineHeight: 1.14,
  textTransform: 'none',
}))

export const ContainerWebFormModule = styled('div', {
  name: 'WebFormModule',
  slot: 'Container',
})(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: 605,
  },
}))

export const TextWebFormModule = styled(CmsTeaserDetailText, {
  name: 'WebFormModule',
  slot: 'Text',
})(({}) => ({
  width: '100%',
}))

export const CtaContentWebFormModule = styled('div', {
  name: 'WebFormModule',
  slot: 'CtaContent',
})(({ theme }) => ({
  display: 'flex',
  width: '100%',
  marginTop: theme.spacing(2),
  justifyContent: 'inherit',
  gap: theme.spacing(4),
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}))

export const IconContentWebFormModule = styled('div', {
  name: 'WebFormModule',
  slot: 'IconContent',
})(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(2),
  svg: {
    height: 48,
    width: 48,
  },
}))

export const FormCollapseButton = styled('div', {
  name: 'WebFormModule',
  slot: 'FormCollapseButton',
})(() => ({
  textTransform: 'uppercase',
  width: 'fit-content',
  cursor: 'pointer',
}))

export const FormCollapseButtonIcon = styled('i', {
  name: 'WebFormModule',
  slot: 'FormCollapseButtonIcon ',
})<{
  rotated?: boolean
}>(({ rotated }) => ({
  border: 'solid black',
  borderWidth: '0 1px 1px 0',
  display: 'inline-block',
  padding: '2px',
  transform: `rotate(${rotated ? '-135deg' : '-45deg'})`,
  height: 'fit-content',
}))

export const FormCollapseButtonTextIconWrapper = styled('div', {
  name: 'WebFormModule',
  slot: 'FormCollapseButtonTextIconWrapper',
})(() => ({
  position: 'relative',
  lineHeight: 1,
  display: 'flex',
  alignItems: 'center',
  gap: 3,
}))

export const FormCollapseButtonUnderline = styled('span', {
  name: 'WebFormModule',
  slot: 'FormCollapseButtonUnderline',
})(() => ({
  flexGrow: 1,
  borderTop: '1px solid black',
  position: 'absolute',
  bottom: 1,
  width: '100%',
}))
