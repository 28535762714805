import { styled } from '@mui/material/styles'

export const StyledHeadingContainer = styled('div', {
  name: 'RemoveAddressDialog',
  slot: 'Container',
})(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.text.dark,
  padding: theme.spacing(0, 4, 9),

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(9, 4),
  },

  fontSize: 24,

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(9, 14),
  },
}))

export const ButtonContainer = styled('div', {
  name: 'RemoveAddressDialog',
  slot: 'ButtonContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  padding: theme.spacing(0, 6),

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 37),
  },
}))
