import React, { ReactNode } from 'react'
import {
  SingleServiceContainer,
  SingleServiceDescription,
  SingleServiceIconWrapper,
  SingleServiceName,
} from './SingleService.style'

import { useTranslation } from 'react-i18next'

const SingleService: React.FC<{
  type: string
  icon: ReactNode
}> = ({ type, icon }) => {
  const { t } = useTranslation()

  return (
    <SingleServiceContainer>
      <SingleServiceIconWrapper>{icon}</SingleServiceIconWrapper>
      <SingleServiceName>
        {t(`StoreDetails.TabLabels.${type}`)}
      </SingleServiceName>
      <SingleServiceDescription>
        {t(`StoreDetails.TabLabels.${type}_SUBTITLE`)}
      </SingleServiceDescription>
    </SingleServiceContainer>
  )
}

export default SingleService
