import React from 'react'
import { ICMCollection } from '../../../../../types/cmsPlacement/CMCollection'
import { useStoreIdentity } from '../../../../../foundation/hooks/useStoreIdentity'
import { useSelector } from 'react-redux'
import { orderItemsSelector } from '../../../../../features/order/selector'
import { Product } from '../../../../../types/product'
import { OrderItem } from '../../../../../types/order'
import {
  initIndexName,
  recommendClient,
} from '../../../../../foundation/algolia/algoliaConfig'
import { localeLangCountryUtil } from '@utils/countryUtil'
import { BaseHit } from 'instantsearch.js'
import {
  useFrequentlyBoughtTogether,
  useRelatedProducts,
  useTrendingFacets,
  useTrendingItems,
} from '@algolia/recommend-react'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import SuggestedProductsAlgolia from '@components/SuggestedProductAlgolia'
import {
  DcwProductsTitle,
  StyledSuggestionContainer,
  WrapperDcwProductsCta,
  WrapperDcwProductsTitleText,
} from '@components/Cms/CmsPlacement/CmsBanner/DcwProducts/DcwProducts.style'
import { ICMPlaceholder } from '../../../../../types/cmsPlacement/CMPlaceholder'

export type IAlgoliaBaseHit = BaseHit

const getProductsPartNumbers = (
  orderItems: OrderItem[],
  currentProduct?: Product
): string[] => {
  if (currentProduct) {
    return [currentProduct.partNumber]
  } else {
    return orderItems && orderItems.map((item) => item.partNumber)
  }
}

const useGetRecommendationProducts = (
  action: string | undefined,
  indexName: string,
  productsPartNumber: string[],
  facetName?: string,
  maxRecommendations?: number
): IAlgoliaBaseHit[] => {
  const actions = {
    frequentlyboughttogether: () =>
      useFrequentlyBoughtTogether({
        recommendClient,
        indexName,
        objectIDs: productsPartNumber,
        maxRecommendations,
      }).recommendations,

    relatedproducts: () =>
      useRelatedProducts({
        recommendClient,
        indexName,
        objectIDs: productsPartNumber,
        maxRecommendations,
      }).recommendations,

    trendingitems: () =>
      useTrendingItems({
        recommendClient,
        indexName,
        maxRecommendations,
      }).recommendations,

    trendingfacets: () =>
      useTrendingFacets({
        recommendClient,
        indexName,
        facetName: facetName ? facetName : '',
        maxRecommendations,
      }).recommendations,

    default: () => {
      return []
    },
  }

  return !!productsPartNumber.length ? actions[action || 'default']() : []
}
const AlgoliaRecommendations: React.FC<{
  placement: ICMCollection | ICMPlaceholder
  isGrouped: boolean
  currentProduct?: Product
}> = ({ placement, isGrouped, currentProduct }) => {
  const { langCode, country } = useStoreIdentity()
  const langCountry = localeLangCountryUtil(langCode)
  const orderItems = useSelector(orderItemsSelector)

  const { lxSearchPageProductType } = ProductAlgoliaUtils.getProductAttributes(
    currentProduct?.productAttributes!
  )

  const { isContactLenses } = ProductAlgoliaUtils.getLxProductTypes(
    lxSearchPageProductType
  )

  const productsPartNumber: string[] = getProductsPartNumbers(
    orderItems,
    currentProduct as Product
  )
  const idAction = placement.idAction?.toLocaleLowerCase() || ''
  const facetName = placement.facetName
  const indexName = initIndexName(langCountry, undefined, isGrouped)
  const title = placement.title || ''
  const maxRecommendations = isContactLenses ? 4 : 6

  const recommendationProducts = useGetRecommendationProducts(
    idAction,
    indexName,
    productsPartNumber,
    facetName,
    maxRecommendations
  )

  if (
    !Array.isArray(recommendationProducts) ||
    recommendationProducts.length === 0
  )
    return null

  if (placement.idAction?.toLocaleLowerCase() === 'trendingfacets') {
    return <>{/* N.B.: PUT HERE THE TRENDING FACETS COMPONENT */}</>
  }

  const formatProductAlgolia = ProductAlgoliaUtils.getFormattedProductAlgolia(
    recommendationProducts,
    country
  )

  return (
    <StyledSuggestionContainer>
      <WrapperDcwProductsTitleText paddingBottomEnabled={Boolean(title)}>
        {title && (
          <DcwProductsTitle fontSize={28} fontWeight={'bold'}>
            {title}
          </DcwProductsTitle>
        )}
      </WrapperDcwProductsTitleText>
      <SuggestedProductsAlgolia products={formatProductAlgolia} />
      <WrapperDcwProductsCta paddingTopEnabled={false} />
    </StyledSuggestionContainer>
  )
}

export default AlgoliaRecommendations
