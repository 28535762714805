//Standard libraries
import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useRouteQuery from 'src/foundation/hooks/useRouteQuery'
import {
  userDetailsSelector,
  userEmailSelector,
} from 'src/redux/selectors/user'
import EditAddressForm from './components/EditAddressForm'
import { useUpdatePersonContactMutation } from 'src/features/account/query'
import { FETCH_USER_DETAILS_REQUESTED_ACTION } from 'src/redux/actions/user'
import { sendSuccessMessage } from 'src/features/success/slice'
import Log from 'src/services/Log'
import { useStoreIdentity } from 'src/foundation/hooks/useStoreIdentity'
import { AddressFormData } from 'src/types/form'
import { updateUrlWithParams } from '@utils/url'
import { BackToAddressBookLink, EditAddressWrapper } from './EditAddress.style'
import { useTheme } from '@mui/material/styles'
import { generateAccountPagePath } from '../Account/Account'
import { ACCOUNT_CHILDREN } from 'src/constants/routes'
import { ArrowLeftIcon } from '@components/UI/Icons'
import { useTranslation } from 'react-i18next'
import { ADDRESS_ID_PARAMETER } from 'src/constants/user'
import { Navigate } from 'react-router-dom'
import addressUtil from '@utils/addressUtil'

const EditAddress: FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const query = useRouteQuery()
  const addressId = query.get(ADDRESS_ID_PARAMETER)
  const userDetails = useSelector(userDetailsSelector)
  const storeCountry = useStoreIdentity().country.toUpperCase()
  const langCode = useStoreIdentity().langCode
  const userEmail = useSelector(userEmailSelector)
  const [formValid, setFormValid] = useState<boolean>(false)
  const dispatch = useDispatch()
  const selectedAddress =
    userDetails?.contact
      ?.filter((contact) => contact.addressId === addressId)
      .pop() || null

  const [updatePersonContact, { isLoading, isSuccess }] =
    useUpdatePersonContactMutation()

  const [newAddressFormData, setNewAddressFormData] = useState<AddressFormData>(
    {
      ...selectedAddress,
      addressLine1: selectedAddress?.addressLine?.[0] || '',
      addressLine2: selectedAddress?.addressLine?.[1] || '',
      country: storeCountry,
      email1: userEmail,
    }
  )

  const updateAddress = async (c: AddressFormData) => {
    try {
      if (c.nickName) {
        const controlAddressData =
          addressUtil.removeLeadingTrailingWhiteSpace(c)

        controlAddressData.addressLine = [controlAddressData.addressLine1]
        if (
          controlAddressData.addressLine2 &&
          controlAddressData.addressLine2.trim() !== ''
        ) {
          controlAddressData.addressLine.push(controlAddressData.addressLine2)
        }

        const result = await updatePersonContact({
          nickName: c.nickName,
          addressData: controlAddressData,
        })
        dispatch(FETCH_USER_DETAILS_REQUESTED_ACTION({ widget: 'EditAddress' }))

        dispatch(
          sendSuccessMessage({
            key: 'success-message.EDIT_ADDRESS_SUCCESS',
            messageParameters: {
              '0': c.nickName,
            },
          })
        )
        updateUrlWithParams(window.location.href, {
          addressId: result['data']?.addressId,
        })
      }
    } catch (e: any) {
      Log.error('Could not update address', e)
    }
  }
  return !addressId ? (
    <Navigate
      to={generateAccountPagePath(langCode, ACCOUNT_CHILDREN.ADDRESS_BOOK)}
    />
  ) : (
    <EditAddressWrapper>
      <BackToAddressBookLink
        variant="noAnimation"
        to={generateAccountPagePath(langCode, ACCOUNT_CHILDREN.ADDRESS_BOOK)}
      >
        <ArrowLeftIcon htmlColor={theme.palette.text.dark} />
        <span>{t('EditAddress.BackToAddressBookLink')}</span>
      </BackToAddressBookLink>
      <EditAddressForm
        formData={newAddressFormData}
        onAddressSaveClick={async (contact) => {
          await updateAddress(contact)
        }}
        onAddressFormDataChanged={(data) => {
          setNewAddressFormData(data)
        }}
        onFormValidaTionStatusChanged={(isValid) => {
          setFormValid(isValid || false)
        }}
        isValid={formValid}
        loading={isLoading}
        success={isSuccess}
      />
    </EditAddressWrapper>
  )
}

export default EditAddress
