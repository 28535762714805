import React from 'react'
import BoxWithMarginBannerMobile from './BoxWithMarginBannerMobile'
import BoxWithMarginBannerDesktop from './BoxWithMarginBannerDesktop'

import useBreakpoints from '../../../../../hooks/useBreakpoints'
import { IPlacement } from '../../../../../types/cmsPlacement/Placement'
import { ICMCollection } from '../../../../../types/cmsPlacement/CMCollection'

export interface IBoxWithMarginProps {
  placement: IPlacement | ICMCollection
  teaserIndex?: number
  reflect?: boolean
}

const BoxWithMarginBanner: React.FC<IBoxWithMarginProps> = ({
  placement,
  teaserIndex,
  reflect,
}) => {
  const { isMobile, isTabletPortrait } = useBreakpoints()
  return (
    <>
      {isMobile || isTabletPortrait ? (
        <BoxWithMarginBannerMobile
          placement={placement}
          teaserIndex={teaserIndex}
        />
      ) : (
        <BoxWithMarginBannerDesktop
          reflect={reflect}
          placement={placement}
          teaserIndex={teaserIndex}
        />
      )}
    </>
  )
}

export default BoxWithMarginBanner
