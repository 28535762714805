import React from 'react'
import { ProductAlgoliaPrice } from '../../../types/ProductAlgolia/ProductAlgolia'
import {
  ProductPriceCurrentPriceFinal,
  ProductPriceInitialPriceFinal,
} from '@components/ProductPriceFinal/ProductPriceFinal.style'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import FormattedPriceDisplay from '@components/formatted-price-display'
import { useTranslation } from 'react-i18next'
import KlarnaOnSiteMessage from '@components/Klarna/KlarnaOnSiteMessage'
import ProductPriceAlgoliaUtils from '@utils/ProductAlgolia/ProductPriceAlgoliaUtils'
import {
  KlarnaMessageContainerPdp,
  ProductPriceBoxesNrPdp,
  ProductPriceContainerPdp,
  ProductPriceCurrentPricePdp,
  ProductPriceDetailsContainerPdp,
  ProductPriceDiscountBoxPdp,
  ProductPriceInitialPricePdp,
  ProductPriceLabelPdp,
  ProductPricePerBoxContainerPdp,
  ProductPricePerBoxDetailsContainerPdp,
  ProductPriceTotalTooltip,
  ProductPriceTotalTooltipContainer,
  ProductPriceTotalTooltipContent,
  ProductPriceTotalTooltipIcon,
  ProductPriceTotalTooltipMain,
  ProductPriceWrapperPdp,
} from '@components/ProductPriceFinal/components/ProductPricePdp.style'
import { PriceTotalProps } from 'src/types/pdp'
import ClickAwayListener from '@mui/material/ClickAwayListener'

type ProductPricePdp = PriceTotalProps & {
  price: ProductAlgoliaPrice
  productType: string
  totalBoxes: number
  productQuantity: number
}

const ProductPricePdp: React.FC<ProductPricePdp> = ({
  price,
  productType,
  totalBoxes,
  productQuantity,
  hideTotal,
  showTotalTooltip,
  showTotalTooltipBtn,
  onTotalTooltipBtnClick,
  onTotalTooltipClose,
  priceTooltipActive,
}) => {
  const { t } = useTranslation()
  const { getLxProductTypes } = ProductAlgoliaUtils
  const { isContactLensesAccessories, isContactLenses, isFrames } =
    getLxProductTypes(productType)

  const {
    listPrice,
    offerPrice,
    currency,
    percentageDiscount,
    badgeDiscount,
    hasInitialPriceDiscount,
  } = ProductPriceAlgoliaUtils.getProductPriceData(price)

  const boxesNr = isContactLenses ? totalBoxes : productQuantity

  const currentPriceControl = isFrames
    ? offerPrice
    : ProductPriceAlgoliaUtils.getClAndClaTotalPrice(offerPrice, boxesNr)

  const initialPriceControl = isFrames
    ? listPrice
    : ProductPriceAlgoliaUtils.getClAndClaTotalPrice(listPrice, boxesNr)

  return (
    <ProductPriceWrapperPdp>
      {(isContactLenses || isContactLensesAccessories) && (
        <>
          <ProductPricePerBoxContainerPdp>
            <div>{t('ContactLenses.Labels.PerBox')}</div>
            <ProductPricePerBoxDetailsContainerPdp>
              {Boolean(listPrice) && hasInitialPriceDiscount && (
                <ProductPriceInitialPriceFinal>
                  <FormattedPriceDisplay min={listPrice} currency={currency} />
                </ProductPriceInitialPriceFinal>
              )}
              {Boolean(offerPrice) && (
                <ProductPriceCurrentPriceFinal>
                  <FormattedPriceDisplay min={offerPrice} currency={currency} />
                </ProductPriceCurrentPriceFinal>
              )}
            </ProductPricePerBoxDetailsContainerPdp>
          </ProductPricePerBoxContainerPdp>
          <ProductPriceBoxesNrPdp>{`${boxesNr} ${
            boxesNr > 1
              ? t('ProductDetails.Labels.Boxes.Box_multiple')
              : t('ProductDetails.Labels.Boxes.Box_single')
          }`}</ProductPriceBoxesNrPdp>
        </>
      )}
      <ClickAwayListener
        onClickAway={(e) => !!onTotalTooltipClose && onTotalTooltipClose(e)}
      >
        <ProductPriceDetailsContainerPdp>
          <ProductPriceLabelPdp>
            {isContactLensesAccessories || isContactLenses
              ? t('ProductTile.Labels.Total')
              : t('ProductDetails.SpecialFrameInfo.Label.Frame')}

            {showTotalTooltipBtn && (
              <ProductPriceTotalTooltipIcon
                onClick={() =>
                  !!onTotalTooltipBtnClick && onTotalTooltipBtnClick()
                }
              />
            )}
          </ProductPriceLabelPdp>

          {priceTooltipActive && (
            <ProductPriceTotalTooltipContainer>
              <ProductPriceTotalTooltip
                aria-describedby=" Inserisci tutti i dettagli della tua prescrizione per
                  visualizzare il prezzo"
                open={showTotalTooltip}
              >
                <ProductPriceTotalTooltipMain>
                  <ProductPriceTotalTooltipContent>
                    {t('ContactLenses.Labels.PriceTooltip')}
                  </ProductPriceTotalTooltipContent>
                </ProductPriceTotalTooltipMain>
              </ProductPriceTotalTooltip>
            </ProductPriceTotalTooltipContainer>
          )}

          <ProductPriceContainerPdp>
            {Boolean(percentageDiscount) && !hideTotal && (
              <ProductPriceDiscountBoxPdp>
                {badgeDiscount}
              </ProductPriceDiscountBoxPdp>
            )}
            {Boolean(listPrice) && hasInitialPriceDiscount && !hideTotal && (
              <ProductPriceInitialPricePdp>
                <FormattedPriceDisplay
                  min={initialPriceControl}
                  currency={currency}
                />
              </ProductPriceInitialPricePdp>
            )}
            {Boolean(offerPrice && !hideTotal) && (
              <ProductPriceCurrentPricePdp>
                <FormattedPriceDisplay
                  min={currentPriceControl}
                  currency={currency}
                />
              </ProductPriceCurrentPricePdp>
            )}
          </ProductPriceContainerPdp>
        </ProductPriceDetailsContainerPdp>
      </ClickAwayListener>

      {Boolean(offerPrice) && (
        <KlarnaMessageContainerPdp>
          <KlarnaOnSiteMessage
            variant={'credit-promotion-auto-size'}
            grandTotal={currentPriceControl || ''}
          />
        </KlarnaMessageContainerPdp>
      )}
    </ProductPriceWrapperPdp>
  )
}

export default ProductPricePdp
