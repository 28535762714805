import { CSSInterpolation } from '@emotion/serialize'
import { css, styled, Theme } from '@mui/material/styles'
import React from 'react'
import { Link, LinkProps, NavLinkProps } from 'react-router-dom'

type StyledLinkVariant = (theme: Theme) => CSSInterpolation

const primary: StyledLinkVariant = (theme) => ({
  position: 'relative',
  fontSize: '14px',
  color: theme.palette.text.dark,
  textDecoration: 'none',
  '&::after': {
    position: 'absolute',
    content: '""',
    width: '100%',
    height: 1,
    bottom: 1,
    left: 0,
    background: theme.palette.text.dark,
    transition: '200ms',
    visibility: 'visible',
  },

  '&:hover::after': {
    width: 0,
    visibility: 'hidden',
  },

  '&:hover': {
    color: theme.palette.text.dark,
    cursor: 'pointer',
  },
})

const wrapper: StyledLinkVariant = () => `
  font-size: 0;
}`

const noAnimation: StyledLinkVariant = (theme) => ({
  position: 'relative',
  fontSize: '14px',
  color: theme.palette.text.dark,
  textDecoration: 'none',

  '&::after': {
    visibility: 'hidden',
  },
  '&:hover::after': {
    visibility: 'hidden',
  },
})

const anchorVariants = {
  primary,
  wrapper,
  noAnimation,
}

export interface AnchorProps extends LinkProps {
  children?: React.ReactNode
  external?: boolean
  to: NavLinkProps['to']
  variant?: keyof typeof anchorVariants
}

export const cssAnchor = ({
  theme,
  variant = 'primary',
}: Pick<AnchorProps, 'variant'> & { theme: Theme }) =>
  css(anchorVariants[variant](theme))

/**
 * Anchor component.
 * @param {string} to  param to set the redirect
 * @param {boolean} external param to change Link(router-dom) to Html link
 */
export const StyledAnchor = styled(
  ({ external, to, children, ...props }: AnchorProps) => {
    return external ? (
      <a href={`${to}`} {...props}>
        {children}
      </a>
    ) : (
      <Link to={to} {...props}>
        {children}
      </Link>
    )
  }
)(cssAnchor)
