import Axios, { Canceler } from 'axios'
import pick from 'lodash/pick'
import React, { FC, useEffect, useState } from 'react'
import getDisplayName from 'react-display-name'
import { useDispatch, useSelector } from 'react-redux'
// import { generatePath, useNavigate } from 'react-router-dom'
import { EMPTY_STRING } from '../../../constants/common'
import personService from '../../../foundation/apis/transaction/person.service'
// import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import * as userActions from '../../../redux/actions/user'
import { siteSelector } from '../../../redux/selectors/site'
import { userDetailsSelector } from '../../../redux/selectors/user'
// import { sendSuccessMessage } from '../../../features/success/slice'
import Log from '../../../services/Log'
import { PersonalInfoFormData, UserDetails } from '../../../types/user'
// import { ACCOUNT } from './../../../constants/routes'
import PersonalInformationForm from './PersonalInformationForm'

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

const infoFormDataInit: PersonalInfoFormData = {
  firstName: EMPTY_STRING,
  lastName: EMPTY_STRING,
  phone1: EMPTY_STRING,
  preferredCurrency: EMPTY_STRING,
  preferredLanguage: EMPTY_STRING,
  gender: 'Unspecified',
  dateOfBirth: EMPTY_STRING,
  email1: EMPTY_STRING,
  logonId: EMPTY_STRING,
}

const pickPersonalInfoDataFromUserDetails = (
  userDetails: UserDetails
): PersonalInfoFormData => {
  return pick<UserDetails, keyof PersonalInfoFormData>(
    userDetails,
    'dateOfBirth',
    'firstName',
    'gender',
    'lastName',
    'phone1',
    'email1',
    'preferredCurrency',
    'preferredLanguage'
  )
}

const CancelToken = Axios.CancelToken
let cancels: Canceler[] = []

const PersonalInformationSection: FC = () => {
  const widgetName = getDisplayName(PersonalInformationSection)
  const userDetails = useSelector(userDetailsSelector)
  const siteSettings = useSelector(siteSelector)
  const storeCurrency = siteSettings?.defaultCurrencyID
  const [isSuccessSubmit, setIsSubmitSuccess] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // const navigate = useNavigate()
  // const { langCode } = useStoreIdentity()

  const dispatch = useDispatch()
  const [formData, setFormData] = useState<PersonalInfoFormData>({
    ...infoFormDataInit,
    ...(userDetails ? pickPersonalInfoDataFromUserDetails(userDetails) : null),
    preferredCurrency: storeCurrency || '',
  })

  const payloadBase = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const updateProfile = (formFieldsData: PersonalInfoFormData) => {
    const formToUpdate: Optional<
      PersonalInfoFormData,
      'preferredCurrency' | 'preferredLanguage'
    > = Object.assign({}, formFieldsData)
    delete formToUpdate.preferredCurrency
    delete formToUpdate.preferredLanguage

    formToUpdate.logonId = formToUpdate.email1

    for (const key in formToUpdate) {
      formToUpdate[key] = formToUpdate[key].trim()
    }

    setIsLoading(true)

    personService
      .updatePerson({
        body: formToUpdate,
        extraParams: payloadBase,
      })
      .then((addressData) => {
        if (addressData.addressId) {
          dispatch(
            userActions.FETCH_USER_DETAILS_SUCCESS_ACTION({
              ...userDetails!,
              ...formFieldsData,
            })
          )
          setIsLoading(false)
          setIsSubmitSuccess(true)
          // const successMessage = {
          //   key: 'success-message.EDIT_ADDRESS_SUCCESS',
          //   messageParameters: { '0': userDetails?.nickName },
          // }

          // dispatch(sendSuccessMessage(successMessage))

          // navigate(generatePath(ACCOUNT, { country: langCode }))
        }
      })
      .catch((e) => {
        setIsLoading(false)
        Log.error('Could not create new address', e)
      })
  }

  useEffect(() => {
    if (userDetails) {
      setFormData({ ...formData, ...userDetails })
    }
  }, [userDetails])

  return (
    <PersonalInformationForm
      fieldsData={formData}
      onDataChange={(data) => setFormData(data)}
      onSubmit={(data) => updateProfile(data)}
      isSuccessSubmit={isSuccessSubmit}
      isLoading={isLoading}
    />
  )
}

export default PersonalInformationSection
