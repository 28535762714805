import { elasticSearchApi } from '../elasticSearchApi'
import { Cart, IOrderDetails } from '../../types/order'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'

export interface OrderTrackingPayload {
  orderId: string
  email?: string
}

export const extendedOrderApi = elasticSearchApi.injectEndpoints({
  endpoints: (build) => ({
    getCart: build.query<Cart, void>({
      providesTags: ['GetCart'],
      async queryFn(args, queryApi, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: '/store/{storeId}/cart/@self',
          method: 'GET',
        })

        return result.data
          ? { data: result.data as Cart }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    trackingOrder: build.mutation<IOrderDetails, OrderTrackingPayload>({
      invalidatesTags: ['GetProductDetails'],
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const body = {
          ...args,
        }
        const result = await fetchWithBQ({
          url: `/store/{storeId}/trackingorder/${args.orderId}/carrierUrl`,
          method: 'POST',
          body,
        })
        return result.data
          ? { data: result.data as IOrderDetails }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    findOrderById: build.query<
      IOrderDetails,
      { orderId: string; skipErrorSnackbar: boolean }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: `/store/{storeId}/order/${args.orderId}`,
          method: 'GET',
          queryParams: {
            skipErrorSnackbar: args.skipErrorSnackbar,
          },
        })

        return result.data
          ? { data: result.data as IOrderDetails }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
  }),
})

export const {
  useTrackingOrderMutation,
  useGetCartQuery,
  useFindOrderByIdQuery,
} = extendedOrderApi
