import { styled } from '@mui/material/styles'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'

export const ContactLensDetailsWrapper = styled(Accordion, {
  name: 'OrderRecapClItem',
  slot: 'ContactLensDetailsWrapper',
})(() => ({
  boxShadow: 'unset',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 'unset',
  },
}))

export const ContactLensDetailsTitle = styled(AccordionSummary, {
  name: 'OrderRecapClItem',
  slot: 'ContactLensDetailsTitle ',
})(({ theme }) => ({
  minHeight: 'unset',
  justifyContent: 'unset',
  padding: 0,
  '&: > *': {
    margin: 0,
  },
  '&.Mui-expanded': {
    minHeight: 'unset',
  },
  '& > .MuiAccordionSummary-content': {
    flex: '0 1 auto',
    margin: 0,
    fontSize: 12,
    color: theme.palette.text.dark,
    textDecoration: 'underline',
    fontWeight: 600,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  '& > .MuiAccordionSummary-expandIconWrapper': {
    marginLeft: theme.spacing(),
  },
}))

export const ContactLensDetailsContent = styled(AccordionDetails, {
  name: 'OrderRecapClItem',
  slot: 'ContactLensDetailsContent',
})(({ theme }) => ({
  padding: theme.spacing(0),
  color: theme.palette.text.dark,
  width: '100%',
}))

export const ContactLensAttributesList = styled('ul', {
  name: 'OrderRecapClItem',
  slot: 'AttributesList',
})(({ theme }) => ({
  justifyContent: 'space-around',
  margin: 0,
  padding: 0,
  listStyleType: 'none',
  display: 'flex',
  marginBottom: theme.spacing(2),
}))

export const ContactLensAttributesListItem = styled('li', {
  name: 'OrderRecapClItem',
  slot: 'AttributesList',
})(() => ({
  fontSize: 12,
  flex: '1 1 auto',
  maxWidth: '100%',
  width: '100%',
  textTransform: 'capitalize',
  position: 'relative',
}))

export const ContactLensAttributesListItemTitle = styled('span', {
  name: 'OrderRecapClItem',
  slot: 'AttributesListItemTitle',
})(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  textTransform: 'uppercase',
  color: theme.palette.text.dark,

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    color: 'inherit',
  },
}))

export const ContactLensAttributesListItemDivider = styled('div', {
  name: 'OrderRecapClItem',
  slot: 'AttributesListItemDivider',
})(({ theme }) => ({
  position: 'absolute',
  width: 1,
  height: 82,
  background: theme.palette.color.grey.dark,

  [theme.breakpoints.up('md')]: {
    left: 0,
  },

  [theme.breakpoints.up('lg')]: {
    left: '-20px',
  },

  [theme.breakpoints.up('xl')]: {
    left: '-50px',
  },
}))

export const ContactLensQuantitySelectContainer = styled('div', {
  name: 'OrderRecapClItem',
  slot: 'QuantitySelectContainer',
})(() => ({
  display: 'flex',
}))

export const ContactLensQuantitySelectWrapper = styled('div', {
  name: 'OrderRecapClItem',
  slot: 'ContactLensQuantitySelectWrapper',
})(({ theme }) => ({
  flex: '0 1 100px',
  marginRight: theme.spacing(6),
}))

export const ContactLensQuantitySelectLabel = styled('span', {
  name: 'OrderRecapClItem',
  slot: 'ContactLensQuantitySelectWrapper',
})(({ theme }) => ({
  display: 'block',
  fontSize: '12px',
  color: 'inherit',
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  textTransform: 'uppercase',
}))

export const ContactLensQuantityRecap = styled('span', {
  name: 'OrderRecapClItem',
  slot: 'ContactLensQuantityRecap',
})(() => ({
  fontSize: '1rem',
  color: 'inherit',
  fontWeight: 'normal',
  '&: before': {
    content: '"( "',
  },
  '&: after': {
    content: '" )"',
  },
}))
