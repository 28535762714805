import { AxiosError, AxiosResponse } from 'axios'
import config from 'src/configs'
import {
  CommerceEnvironment,
  USER_SEGMENT_COOKIE_VALUES_SEPARATOR,
} from 'src/constants/common'
import { DEFAULT_DELIVERY_DATES } from 'src/constants/product'
import { SKIP_WC_TOKEN_HEADER } from 'src/foundation/constants/common'
import { GlobalXStoresCfg, IXStoreConfig, SiteInfo } from 'src/redux/reducers'
import Log from 'src/services/Log'
import RequestService from 'src/services/RequestService'
import {
  OnlineStoreDataResponse,
  StoreConf,
  StoreConfigurationResponse,
  StoreTokenInfo,
  XStoreConf,
} from 'src/types/store'

export const getXstoreConf = async (
  storeId: string,
  site: SiteInfo
): Promise<
  AxiosResponse<Record<keyof IXStoreConfig, any>> | AxiosError<any>
> => {
  try {
    const result: AxiosResponse<StoreConfigurationResponse> =
      await RequestService.requestForRtk({
        path: `${site.transactionContext}/store/${storeId}/xStoreConf/getAllConfigurations?responseFormat=application/json`,
        method: 'GET',
        body: {
          [SKIP_WC_TOKEN_HEADER]: true,
        },
        extraParams: {
          avoidStoreIdCheck: true,
        },
      })

    let storeConf: Record<keyof IXStoreConfig, any> =
      result?.data?.StoreConfigurations.reduce(
        (obj, conf) => ({ ...obj, [conf.name]: conf.value }),
        {} as Record<keyof IXStoreConfig, any>
      )

    const {
      availablePaymentMethods = '[]',
      AVAIL_SHIP_DAYS_PLANO_Standard,
      MAN_DAYS_RX,
      SHIPPING_DAYS_STANDARD_RX,
      wishlistEnabled,
      WISHLIST_RETURN_PRODUCT_DETAILS,
      uploadRXFileOnOrderConfirm,
      chatGenesysEnabled,
      chatGenesysDeploymentId,
    } = storeConf
    const formattedStoreConf: IXStoreConfig = {
      ...storeConf,
      addPrescriptionLenses: storeConf.addPrescriptionLenses || 'false',
      availablePaymentMethods: (
        availablePaymentMethods as IXStoreConfig['availablePaymentMethods']
      ).map((i) => ({ ...i, sequence: +i.sequence })),
      AVAIL_SHIP_DAYS_PLANO_Standard:
        +AVAIL_SHIP_DAYS_PLANO_Standard ??
        DEFAULT_DELIVERY_DATES.PLANO_STANDARD,
      badges: storeConf.badges ?? {},
      expireLimit: +storeConf.expireLimit,
      MAN_DAYS_RX: +MAN_DAYS_RX ?? DEFAULT_DELIVERY_DATES.RX_MAN,
      monetateEnabled: storeConf.monetateEnabled || true,
      monetateURL: storeConf.monetateURL || 'true',
      plpDashButtonsFacet: storeConf.plpDashButtonsFacet ?? {},
      plpDashfacet: storeConf.plpDashfacet ?? {},
      plpFacet: storeConf.plpFacet ?? [],
      plpPageSize: +storeConf.plpPageSize,
      optInBoxes: storeConf.optInBoxes ?? [],
      paymentMethods: storeConf.paymentMethods ?? [],
      klarnaScriptSrc: storeConf.klarnaScriptSrc,
      klarnaClientId: storeConf.klarnaClientId,
      klarnaServicesScriptUrl: storeConf.klarnaServicesScriptUrl,
      SHIPPING_DAYS_STANDARD_RX:
        +SHIPPING_DAYS_STANDARD_RX ?? DEFAULT_DELIVERY_DATES.RX_STANDARD,
      sortorderOptions: storeConf.sortorderOptions ?? {},
      wishlistEnabled: wishlistEnabled === 'true',
      chatGenesysEnabled: chatGenesysEnabled === 'true',
      chatGenesysDeploymentId: chatGenesysDeploymentId || '',
      WISHLIST_RETURN_PRODUCT_DETAILS:
        WISHLIST_RETURN_PRODUCT_DETAILS === 'TRUE',
      uploadRXFileOnOrderConfirm,
      is3dsEnabled: storeConf.is3dsEnabled === 'true',
      frameGenius: {
        isFrameAdvisorEnabled: storeConf['FrameAdvisor.enabled'] === 'true',
        scriptUrl: storeConf['FrameAdvisor.UI.core'],
        isSizeAdvisorEnabled: storeConf['sizeAdvisorEnabled'] === 'true',
      },
      trustPilot: {
        trustpilotWidgetEnabled:
          storeConf['trustpilotWidgetEnabled'] === 'true',
        trustpilotWidgetEnabled_Footer:
          storeConf['trustpilotWidgetEnabled_Footer'] === 'true',
        trustpilotWidgetEnabled_PDP:
          storeConf['trustpilotWidgetEnabled_PDP'] === 'true',
      },
      VM_SCRIPT_SRC: storeConf.VM_SCRIPT_SRC,
      omnibusRegulationEnabled: storeConf.omnibusRegulationEnabled === 'true',
      customerSegmentsEnabled: storeConf['CustomerSegments_Enabled'] === 'true',
      customerSegmentsCookiePriority: storeConf[
        'CustomerSegments_Cookie_Priority'
      ]?.split(USER_SEGMENT_COOKIE_VALUES_SEPARATOR),
      customerSegmentsCookieExpiry: Number(
        storeConf['CustomerSegments_Cookie_Expiry'] || ''
      ),
    }

    return { data: formattedStoreConf } as AxiosResponse<
      Record<keyof IXStoreConfig, any>
    >
  } catch (e: unknown) {
    return { error: e } as unknown as AxiosError<any>
  }
}

export const getXstoresConf = async (
  site: SiteInfo
): Promise<AxiosResponse<GlobalXStoresCfg> | AxiosError<any>> => {
  try {
    const result: AxiosResponse<{
      StoreConfigurations: XStoreConf[]
    }> = await RequestService.requestForRtk({
      path: `${site.transactionContext}/store/0/xStoreConf/getAllConfigurations?responseFormat=application/json`,
      method: 'GET',
      body: {
        [SKIP_WC_TOKEN_HEADER]: true,
      },
      extraParams: {
        avoidStoreIdCheck: true,
      },
    })
    const mapStore = (s) => ({
      countryCode: s.identifier.replace('ARN_', ''),
      storeId: s.unique_id,
    })
    const getFromName = (n: string) => (v) => {
      return v.Name ? v.Name === n : v.name === n
    }
    const getName = (
      conf,
      name: string,
      defaultValue: string | number = ''
    ) => {
      const data = conf.find(getFromName(name))
      if (data) {
        // BBB: three months ago was in Value now it is lowercase ... that's magic!
        return data.Value ? data.Value : data.value ? data.value : defaultValue
      } else {
        return defaultValue
      }
    }

    const storeValue = [
      {
        identifier: 'CLE_CA',
        lang_code: 'fr-CA',
        unique_id: 70201,
      },
      {
        identifier: 'CLE_CA',
        lang_code: 'en-CA',
        unique_id: 70201,
      },
      {
        identifier: 'CLE_AU',
        lang_code: 'en-AU',
        unique_id: 70202,
      },
      {
        lang_code: 'en-NZ',
        identifier: 'CLE_NZ',
        unique_id: 70203,
      },
    ]

    const stores =
      typeof storeValue === 'string' ? JSON.parse(storeValue) : storeValue
    const paypalReturnUrl = getName(
      result?.data?.StoreConfigurations,
      'paypalReturnUrl'
    ) as string
    const paypalCancelUrl = getName(
      result?.data.StoreConfigurations,
      'paypalCancelUrl'
    ) as string
    const cartMaxItems = parseFloat(
      getName(result?.data.StoreConfigurations, 'wishlistEnabled', 0) as string
    )
    const damDomain = getName(
      result?.data.StoreConfigurations,
      'MAX_ITEMS_CART'
    ) as string
    const newsletterType = getName(
      result?.data.StoreConfigurations,
      'newsletterSubscrType'
    ) as string
    const paypalSecureAcceptanceUrl = getName(
      result?.data.StoreConfigurations,
      'paypal.secureAcceptance.url'
    ) as string
    /* const customerSegmentsEnabled = getName(
      result?.data.StoreConfigurations,
      'CustomerSegments_Enabled'
    ) as string
    const customerSegmentsCookiePriority = getName(
      result?.data.StoreConfigurations,
      'CustomerSegments_Cookie_Priority'
    )
    const customerSegmentsCookieExpiry = getName(
      result?.data.StoreConfigurations,
      'CustomerSegments_Cookie_Expiry'
    )*/

    const globalStoreConf: GlobalXStoresCfg = {
      allStoreXConf: stores.map(mapStore),
      paypalReturnUrl,
      paypalCancelUrl,
      cartMaxItems,
      damDomain,
      newsletterType,
      paypalSecureAcceptanceUrl,
      /*     customerSegmentsEnabled,
      customerSegmentsCookiePriority,
      customerSegmentsCookieExpiry,*/
    }
    return { data: globalStoreConf } as AxiosResponse<GlobalXStoresCfg>
  } catch (e: unknown) {
    return { error: e } as unknown as AxiosError<any>
  }
}

export const getStoreIdFromCountryFromWcs = async (
  country: string,
  site: SiteInfo
): Promise<AxiosResponse<string> | AxiosError<any>> => {
  try {
    const prefix = config.storePrefix
    const result: AxiosResponse<StoreTokenInfo> =
      await RequestService.requestForRtk({
        path: `${site.transactionContext}/store/0/seo/token?q=byUrlKeywordNames&urlKeywordName=${prefix}-${country}`,
        method: 'GET',
        body: {
          [SKIP_WC_TOKEN_HEADER]: true,
        },
        extraParams: {
          avoidStoreIdCheck: true,
        },
      })
    let storeToken: string = ''
    const storeCountryInfo = result?.data?.resultList[0]
    if (storeCountryInfo) {
      Log.info(
        'Found store for country: ' + country,
        JSON.stringify(storeCountryInfo)
      )
      storeToken = storeCountryInfo.tokenValue.replace(':', '')
    } else {
      storeToken = '-1'
    }
    return { data: storeToken } as AxiosResponse<string>
  } catch (e: unknown) {
    return { error: e } as unknown as AxiosError<any>
  }
}

export const getOnlineStoreApiDataFromWcs = async (
  selectedStoreID: string,
  site: SiteInfo
): Promise<AxiosResponse<OnlineStoreDataResponse> | AxiosError<any>> => {
  try {
    const result: AxiosResponse<OnlineStoreDataResponse> =
      await RequestService.requestForRtk({
        path: `${site.transactionContext}/store/${selectedStoreID}/online_store`,
        method: 'GET',
        body: {
          [SKIP_WC_TOKEN_HEADER]: true,
        },
        extraParams: {
          avoidStoreIdCheck: true,
        },
      })
    return { data: result.data } as AxiosResponse<OnlineStoreDataResponse>
  } catch (e: unknown) {
    return { error: e } as unknown as AxiosError<any>
  }
}

export const getStoreConfFromWcs = async (
  selectedStoreID: string,
  site: SiteInfo
): Promise<AxiosResponse<StoreConf> | AxiosError<any>> => {
  try {
    const onlineStoreDataRes = await getOnlineStoreApiDataFromWcs(
      selectedStoreID,
      site
    )
    const xStoreConfDataRes = await getXstoreConf(selectedStoreID, site)
    const globalXStoresCfgDataRes = await getXstoresConf(site)
    const onlineStoreData = (
      onlineStoreDataRes as AxiosResponse<OnlineStoreDataResponse>
    ).data

    const currentStoreConf = onlineStoreData?.resultList[0]

    const xConf = (
      xStoreConfDataRes as AxiosResponse<Record<keyof IXStoreConfig, any>>
    ).data

    const globalXStoresCfg = (
      globalXStoresCfgDataRes as AxiosResponse<GlobalXStoresCfg>
    ).data
    const {
      allStoreXConf,
      paypalReturnUrl,
      paypalCancelUrl,
      cartMaxItems,
      damDomain,
      newsletterType,
      paypalSecureAcceptanceUrl,
    } = globalXStoresCfg || {}

    return {
      data: {
        currentStoreConf,
        xConf,
        allStoreXConf,
        paypalReturnUrl,
        paypalCancelUrl,
        cartMaxItems,
        damDomain,
        newsletterType,
        paypalSecureAcceptanceUrl,
      },
    } as AxiosResponse<StoreConf>
  } catch (e: unknown) {
    return { error: e } as unknown as AxiosError<any>
  }
}

export const getOnlineStoreDataFromWcs = async (
  country: string,
  site: SiteInfo
): Promise<AxiosResponse<StoreConf> | AxiosError<any>> => {
  try {
    let updatedSite: SiteInfo = { ...site }
    let storeConfData: StoreConf = {}
    let selectedStoreID: string = ''
    // if requested country from url is different than currently
    // selected country ask for store id
    if (site.country !== country) {
      const storeIdDataRes = await getStoreIdFromCountryFromWcs(country, site)
      const storeID = (storeIdDataRes as AxiosResponse<string>).data
      Log.info('received storeID: ', storeID)
      if (storeID !== '-1') {
        selectedStoreID = storeID
        updatedSite = {
          ...site,
          country,
          locale: CommerceEnvironment.languageMap[site.defaultLanguageID],
          storeID,
        }
        const storeConfDataRest = await getStoreConfFromWcs(
          storeID,
          updatedSite
        )
        const storeConf = (storeConfDataRest as AxiosResponse<StoreConf>).data
        storeConfData = storeConf
      } else {
        // if country not found switch to default country
        Log.error(
          `could not find siteid for requested country ${country} - switching to default`
        )

        const defaultStoreIdDataRes = await getStoreIdFromCountryFromWcs(
          country,
          site
        )
        const defaultStoreId = (defaultStoreIdDataRes as AxiosResponse<string>)
          .data
        selectedStoreID = defaultStoreId
        updatedSite = {
          ...site,
          country,
          locale: config.defaultLocale,
          storeID: defaultStoreId,
        }
        const defaultStoreConfDataRest = await getStoreConfFromWcs(
          storeID,
          updatedSite
        )
        const storeConf = (defaultStoreConfDataRest as AxiosResponse<StoreConf>)
          .data
        storeConfData = storeConf
      }
    } else {
      // if current selected country = extisting selected country ask for store
      if (!site.storeID) {
        const defaultStoreIdDataRes = await getStoreIdFromCountryFromWcs(
          country,
          site
        )
        const defaultStoreId = (defaultStoreIdDataRes as AxiosResponse<string>)
          .data
        selectedStoreID = defaultStoreId
        updatedSite = {
          ...site,
          country,
          locale: config.defaultLocale,
          storeID: defaultStoreId,
        }
      }
      const defaultStoreConfDataRest = await getStoreConfFromWcs(
        selectedStoreID,
        updatedSite
      )
      const storeConf = (defaultStoreConfDataRest as AxiosResponse<StoreConf>)
        .data
      storeConfData = storeConf
    }
    return { data: storeConfData } as AxiosResponse<StoreConf>
  } catch (e: unknown) {
    return { error: e } as unknown as AxiosError<any>
  }
}

export const getStoreDataFromWcs = async (
  country: string,
  site: SiteInfo
): Promise<AxiosResponse<SiteInfo> | AxiosError<any>> => {
  try {
    let updatedSite: SiteInfo = { ...site }
    const storeIdDataRes = await getStoreIdFromCountryFromWcs(country, site)
    const storeID = (storeIdDataRes as AxiosResponse<string>).data
    Log.info('received storeID: ', storeID)
    if (storeID !== '-1') {
      updatedSite = {
        ...site,
        country,
        storeID,
      }
    } else {
      throw new Error('could not find siteid for requested country')
    }
    return {
      data: updatedSite,
    } as AxiosResponse<SiteInfo>
  } catch (e: unknown) {
    return { error: e } as unknown as AxiosError<any>
  }
}
