import { styled } from '@mui/material/styles'
import {
  Button,
  StyledAnchor,
  ButtonAsLink,
  LinkAsButton,
} from '@components/UI'

export const DashboardWrapper = styled('div', {
  name: 'Dashboard',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(6, 4),
  rowGap: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(8),
    alignItems: 'center',
  },
}))

export const DashboardTitleWrapper = styled('div', {
  name: 'DashboardTitle',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  alignSelf: 'flex-start',
  marginTop: theme.spacing(2),
  fontSize: 20,
  fontWeight: 600,
  lineHeight: 1.4,
  color: theme.palette.text.dark,
}))

export const DashboardStyledLinkCTA = styled(ButtonAsLink, {
  name: 'Dashboard',
  slot: 'LinkCTA',
})(() => ({
  fontWeight: 600,
  display: 'flex',
  alignSelf: 'flex-start',

  '&::after': {
    width: '100%',
  },
}))

export const DashboardStyledAnchorCTA = styled(StyledAnchor, {
  name: 'Dashboard',
  slot: 'StyledAnchorCTA',
})(({ theme }) => ({
  fontWeight: 600,
  textDecoration: 'underline',
  whiteSpace: 'nowrap',
  marginLeft: theme.spacing(2),
  '&::after': {
    width: '0%',
  },

  [theme.breakpoints.up('sm')]: {
    textDecoration: 'none',
    marginLeft: '10px',
    '&::after': {
      width: '100%',
    },
  },
}))

export const DashboardRow = styled('div', {
  name: 'DashboardRod',
  slot: 'div',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    columnGap: theme.spacing(4),
  },

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    columnGap: theme.spacing(8),
    justifyContent: 'center',
  },
}))

export const DashboardContainerWrapper = styled('div', {
  name: 'Dashboard',
  slot: 'GridContainerWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(4),
  marginBottom: theme.spacing(8),

  [theme.breakpoints.up('sm')]: {
    rowGap: theme.spacing(4),
  },

  [theme.breakpoints.up('md')]: {
    rowGap: theme.spacing(8),
  },
}))

export const DashboardTile = styled('div', {
  name: 'Dashboard',
  slot: 'div',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 0,
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(8, 6),
  borderRadius: 16,
  border: `solid 1px ${theme.palette.color.grey.light}`,
  backgroundColor: theme.palette.color.white.main,
  minHeight: 400,
  height: '100%',
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(8, 6),
  },

  [theme.breakpoints.up('md')]: {
    width: 360,
  },

  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(10, 12),
    width: 439,
    height: 439,
  },
}))

export const DashboardTileHeaderWrapper = styled('div', {
  name: 'Dashboard',
  slot: 'GridItemHeaderWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  flexGrow: 0,
  alignItems: 'center',
  gap: theme.spacing(4),
  padding: 0,
  marginBottom: 'auto',
}))

export const DashboardTileHeader = styled('span', {
  name: 'Dashboard',
  slot: 'GridItemHeader',
})(({ theme }) => ({
  fontSize: 28,
  fontWeight: 600,
  lineHeight: 1.4,
  color: theme.palette.text.dark,
  marginTop: theme.spacing(1),

  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(2),
  },
}))

export const DashboardTileSubheader = styled('span', {
  name: 'Dashboard',
  slot: 'GridItemSubheader',
})(({ theme }) => ({
  fontSize: 16,
  fontWeight: 'normal',
  lineHeight: 1.5,
  color: theme.palette.text.dark,
  textTransform: 'uppercase',
}))

export const DashboardLastOrderContent = styled('div', {
  name: 'Dashboard',
  slot: 'LastOrderContent',
})(({ theme }) => ({
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: theme.spacing(4),
  padding: 0,
}))

export const DashboardLastOrderTitle = styled('span', {
  name: 'Dashboard',
  slot: 'LastOrderTitle',
})(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  lineHeight: 1.5,
  textTransform: 'uppercase',
  color: theme.palette.text.dark,
}))

export const DashboardLastOrderDetails = styled('div', {
  name: 'Dashboard',
  slot: 'LastOrderDetails',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'stretch',
  rowGap: theme.spacing(1),
  marginBottom: theme.spacing(-1),
}))

export const DashboardLastOrderDetailsRow = styled('div', {
  name: 'Dashboard',
  slot: 'LastOrderDetailsRow',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'left',
}))

export const DashboardLastOrderDetailsLabel = styled('div', {
  name: 'Dashboard',
  slot: 'LastOrderDetailsLabel',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  fontSize: 16,
  lineHeight: 1.5,
  textTransform: 'uppercase',
}))

export const DashboardLastOrderDetailsItem = styled('div', {
  name: 'Dashboard',
  slot: 'LastOrderDetailsItem',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 3,
  fontSize: 14,
  lineHeight: 1.43,
  columnGap: theme.spacing(2),
}))

export const DashboardTileContent = styled('div', {
  name: 'Dashboard',
  slot: 'GridItemContent',
})(({ theme }) => ({
  fontSize: 16,
  lineHeight: 1.5,
  textAlign: 'center',
  color: theme.palette.text.dark,
  [theme.breakpoints.up('lg')]: {
    width: 283,
  },
}))

export const DashboardMyStoresStyled = styled('div', {
  name: 'Dashboard',
  slot: 'MyStore',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(8),
  padding: 0,
  color: theme.palette.text.dark,

  [theme.breakpoints.up('sm')]: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: theme.spacing(4),
  },

  [theme.breakpoints.up('md')]: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: theme.spacing(8),
  },
}))

export const CTATextContainer = styled('div', {
  name: 'CallToAction',
  slot: 'TextContainer',
})(({ theme }) => ({
  alignSelf: 'center',
  textAlign: 'center',
  padding: theme.spacing(1),
  [theme.breakpoints.up('lg')]: {
    width: 283,
  },
}))

export const TextFieldContainer = styled('div', {
  name: 'TextField',
  slot: 'Container',
})(({ theme }) => ({
  width: 295,
  alignSelf: 'center',
  position: 'relative',
  color: theme.palette.text.dark,
  [theme.breakpoints.up('md')]: {
    width: 240,
  },
  [theme.breakpoints.up('lg')]: {
    width: 343,
  },
}))

export const TextFieldIconContainer = styled('div', {
  name: 'TextField',
  slot: 'IconContainer',
})(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(-4),
  top: theme.spacing(4),
  flexGrow: 0,
  alignItems: 'center',
  gap: theme.spacing(4),
  padding: 0,
  fontSize: 20,
  fontWeight: 600,
  lineHeight: 1.4,
  textAlign: 'center',
  color: theme.palette.text.dark,
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(2),
  },
}))

export const HeartIconContainer = styled('div', {
  name: 'HeartIcon',
  slot: 'IconContainer',
})(() => ({
  display: 'flex',

  '& > svg': {
    transform: 'scale(3) translate(33%, 33%)',
  },
}))

export const ShopNowAccountButton = styled(LinkAsButton, {
  name: 'Account',
  slot: 'ShopNowButton',
})(() => ({
  marginTop: 'auto',
}))

export const StyledLinkAsButton = styled(LinkAsButton, {
  name: 'Dashboard',
  slot: 'StyledLinkAsButton',
})(() => ({
  marginTop: 'auto',
  '&.disabled': {
    pointerEvents: 'none',
  },
}))

export const StyledButton = styled(Button, {
  name: 'Dashboard',
  slot: 'StyledButton',
})(() => ({
  marginTop: 'auto',
}))

export const DashboardStyledMyStores = styled('div', {
  name: 'Dashboard',
  slot: 'MyStores',
})(({ theme }) => ({
  minHeight: 400,
  width: '100%',
  order: 1,

  [theme.breakpoints.up('sm')]: {
    order: 2,
    minHeight: 340,
  },

  [theme.breakpoints.up('md')]: {
    minHeight: 360,
    width: 360,
  },

  [theme.breakpoints.up('lg')]: {
    minHeight: 439,
    width: 467,
  },
}))

export const DashboardFindYourLook = styled('div', {
  name: 'Dashboard',
  slot: 'FindYourLook',
})(({ theme }) => ({
  minHeight: 400,
  width: '100%',
  order: 2,
  '& > div': {
    rowGap: theme.spacing(16.5),
  },
  [theme.breakpoints.up('sm')]: {
    order: 3,
    minHeight: 340,
  },

  [theme.breakpoints.up('md')]: {
    minHeight: 360,
    width: 360,
  },

  [theme.breakpoints.up('lg')]: {
    minHeight: 439,
    width: 467,
  },
}))

export const DashboardMyFavourites = styled('div', {
  name: 'Dashboard',
  slot: 'MyFavourites',
})(({ theme }) => ({
  minHeight: 400,
  width: '100%',
  order: 3,
  [theme.breakpoints.up('sm')]: {
    order: 4,
    minHeight: 340,
  },

  [theme.breakpoints.up('md')]: {
    minHeight: 360,
    width: 360,
  },

  [theme.breakpoints.up('lg')]: {
    minHeight: 439,
    width: 467,
  },
}))

export const DashboardMyOrders = styled('div', {
  name: 'Dashboard',
  slot: 'MyOrders',
})(({ theme }) => ({
  minHeight: 400,
  width: '100%',
  order: 4,
  [theme.breakpoints.up('sm')]: {
    order: 1,
    minHeight: 340,
  },

  [theme.breakpoints.up('md')]: {
    minHeight: 360,
    width: 360,
  },

  [theme.breakpoints.up('lg')]: {
    minHeight: 439,
    width: 467,
  },
}))
