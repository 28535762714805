import React from 'react'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import { Link } from 'react-router-dom'

const CmsFullBannerAnchor: React.FC<{
  children: React.ReactNode
  toLink?: string
  bannerIndex?: number
  teaserIndex?: number
}> = ({ children, toLink, bannerIndex, teaserIndex }) => {
  const { basePath } = useStoreIdentity()
  const AnchorImageBannerProps = {
    'aria-label': `Placement_Banner${bannerIndex}_IMG link. Teaser №${teaserIndex}`,
    'data-element-id': `${teaserIndex}Placement_Banner${bannerIndex}_IMG`,
  }
  return (
    <>
      {toLink ? (
        <Link {...AnchorImageBannerProps} to={`${basePath}${toLink}`}>
          {children}
        </Link>
      ) : (
        <>{children}</>
      )}
    </>
  )
}
export default CmsFullBannerAnchor
