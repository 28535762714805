import { GridContainer, GridItem, ButtonAsLink, TextField } from '../UI'
import { Z_INDEX_LV2 } from '../../constants/ui'
import { styled } from '@mui/material/styles'

export const AddressFormWrapper = styled('div', {
  name: 'AddressForm',
  slot: 'AddressFormWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
  '& :nth-of-type(7)': {
    marginTop: theme.spacing(5),
  },

  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(2),
  },
}))

export const TaxCodeMessage = styled('div', {
  name: 'AddressForm',
  slot: 'TaxCodeMessage',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  gap: theme.spacing(3),
  background: theme.palette.primary.light,
  height: 'auto',
  fontSize: 12,
  fontWeight: 300,
  margin: theme.spacing(1, 0, 4),
  padding: theme.spacing(1, 0),

  svg: {
    height: 16,
    width: 16,
    marginLeft: theme.spacing(3),
    alignSelf: 'start',
  },

  [theme.breakpoints.up('sm')]: {
    height: 32,
    padding: theme.spacing(6, 0),
  },
}))

export const StyledAddressForm = styled('form', {
  name: 'AddressForm',
  slot: 'Form',
})(() => ({}))

export const StyledTextField = styled(TextField, {
  name: 'AddressForm',
  slot: 'TextField',
  shouldForwardProp: (prop) => prop !== 'gMapLoaded',
})(({ theme }) => ({
  '& .MuiFormHelperText-root.Mui-error': {
    background: 'transparent',
    margin: 0,
    paddingLeft: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(0.5),
    position: 'relative',
    bottom: 0,
  },
  '.MuiAutocomplete-endAdornment': {
    right: '0 !important',
  },
  '.MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
    flexDirection: 'row-reverse',
  },
  '.MuiInputAdornment-root svg': {
    marginLeft: theme.spacing(1),
  },
  'input::-webkit-contacts-auto-fill-button': {
    visibility: 'hidden',
    display: 'none !important',
    pointerEvents: 'none',
    position: 'absolute',
    right: 0,
  },
}))

export const StyledGridContainer = styled(GridContainer, {
  name: 'AddressForm',
  slot: 'GridContainer',
})(({ theme }) => ({
  '& > div:nth-of-type(7)': {
    paddingTop: theme.spacing(5),
  },
}))

export const StyledGridItem = styled(GridItem, {
  name: 'AddressForm',
  slot: 'GridItem',
  shouldForwardProp: (prop) => prop !== 'maxWidth',
})<{ maxWidth?: number | string }>(({ maxWidth }) => ({
  width: 'auto',
  maxWidth,
}))

export const StyledGridItemPostcode = styled(StyledGridItem, {
  name: 'AddressForm',
  slot: 'GridItemPostcode',
  shouldForwardProp: (prop) => prop !== 'maxWidth',
})<{ maxWidth?: number | string }>(({ maxWidth }) => ({
  maxWidth,
}))

export const StyledGridItemAddressLine1 = styled(StyledGridItem, {
  name: 'AddressForm',
  slot: 'GridItemAddressLine1',
  shouldForwardProp: (prop) => prop !== 'maxWidth',
})<{ maxWidth?: number | string }>(({ maxWidth }) => ({
  maxWidth,
}))

export const StyledGridItemPhoneNumber = styled(StyledGridItem, {
  name: 'AddressForm',
  slot: 'GridItemPhoneNumber',
  shouldForwardProp: (prop) => prop !== 'maxWidth',
})(({ theme }) => ({
  '.MuiInputBase-root': {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 307,
    },
  },
}))

export const StyledGridItemGeneric = styled(StyledGridItem, {
  name: 'AddressForm',
  slot: 'GridItemGeneric',
  shouldForwardProp: (prop) => prop !== 'maxWidth',
})<{ maxWidth?: number | string }>(({ maxWidth, theme }) => ({
  [theme.breakpoints.up('sm')]: {
    maxWidth,
  },
}))

export const StyledGridItemFiscalCode = styled(StyledGridItem, {
  name: 'AddressForm',
  slot: 'GridItemFiscalCode',
  shouldForwardProp: (prop) => prop !== 'maxWidth',
})<{ maxWidth?: number | string }>(({ maxWidth, theme }) => ({
  [theme.breakpoints.up('sm')]: {
    maxWidth,
  },
}))

export const StyledGridItemAdditionalText = styled('div', {
  name: 'AddressForm',
  slot: 'GridItemAdditionalText',
})(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.dark,
  width: '100%',
}))

export const WrapperCountry = styled('div', {
  name: 'AddressForm',
  slot: 'CountryWrapper',
})(({ theme }) => ({
  padding: theme.spacing(6, 5, 0, 5),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(6),
  color: theme.palette.text.dark,
}))

export const CountryTitle = styled('div', {
  name: 'AddressForm',
  slot: 'TitleCountry',
})(() => ({}))

export const CountryText = styled('div', {
  name: 'AddressForm',
  slot: 'CountryText',
})(() => ({
  fontWeight: 600,
}))

export const AddressLine2Button = styled(ButtonAsLink, {
  name: 'AddressForm',
  slot: 'AddressLine2Button',
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen: boolean }>(({ isOpen, theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  marginBottom: isOpen ? theme.spacing(6) : 0,
}))

export const AddressSuggesterWrapper = styled('div', {
  name: 'AddressForm',
  slot: 'SuggesterWrapper',
})(() => ({
  position: 'relative',
}))

export const SuggestionsWrapper = styled('ul', {
  name: 'AddressForm',
  slot: 'SuggestionsWrapper',
})(({ theme }) => ({
  color: theme.palette.text.dark,
  position: 'absolute',
  zIndex: Z_INDEX_LV2,
  padding: 0,
  margin: 0,
  listStyleType: 'none',
  width: '100%',
  border: `2px solid ${theme.palette.primary.main}`,
  borderTop: 0,
  top: 48,
}))

export const SuggestionsElement = styled('li', {
  name: 'AddressForm',
  slot: 'SuggestionsElement',
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected: boolean }>(({ selected, theme }) => ({
  color: 'inherit',
  cursor: 'pointer',
  padding: theme.spacing(3, 1),
  margin: 0,
  background: selected
    ? theme.palette.primary.light
    : theme.palette.color.white.main,
  '&:hover': {
    background: theme.palette.primary.light,
  },
}))

export const TaxCodeOptionalText = styled('span', {
  name: 'AddressForm',
  slot: 'TaxCodeOptionalText',
})(({ theme }) => ({
  fontSize: 12,
  fontWeight: 300,
  color: theme.palette.color.grey.dark,
  lineHeight: 1.33,
  marginLeft: theme.spacing(4),
}))

export const FiscalCodeErrorMsg = styled('span', {
  name: 'AddressForm',
  slot: 'FiscalCodeErrorMsg',
})(({ theme }) => ({
  fontSize: 12,
  lineHeight: 1.33,
  margin: theme.spacing(1, 0, 0, 2),
}))
