import { put, select } from 'redux-saga/effects'
import { orderApi } from 'src/features/order/query'
import { cartSelector } from 'src/features/order/selector'
import {
  defaultCurrencyIdSelector,
  storeIdSelector,
} from 'src/redux/selectors/site'
import { Cart } from 'src/types/order'

export function* getCart() {
  const storeId = yield select(storeIdSelector)
  const currency = yield select(defaultCurrencyIdSelector)

  const getCartResult = yield put(
    orderApi.endpoints.getCart.initiate({
      storeId,
      currency,
    })
  )

  // Wait for getCartResult to finish
  yield getCartResult

  const cart: Cart = yield select(cartSelector)
  return cart
}
