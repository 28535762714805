import React from 'react'
import CMSCommonMedia from '../../../../CmsComponents/CmsCommonMedia'
import { placementHasTextShadow } from '@utils/placements'
import { MediaOverlapTopPageContainer } from '../TopPageBanner.style'
import { ImageOverlapProps } from '@components/Cms/CmsPlacement/CmsBanner/CMBanner.type'

const TopPageMediaOverlap: React.FC<ImageOverlapProps> = ({
  viewType,
  teaser,
  crop,
  playerBannerHook,
  media,
  children,
  teaserIndex,
}) => {
  return (
    <MediaOverlapTopPageContainer viewType={viewType!}>
      {children}
      <CMSCommonMedia
        isLazy={true}
        viewType={viewType!}
        type={crop || ''}
        media={media}
        playerBannerHook={playerBannerHook}
        teaser={teaser}
        isBackgroundGradientNeeded={placementHasTextShadow({
          teaser,
          viewType,
        })}
        isFetchPriority={teaserIndex === 1}
      />
    </MediaOverlapTopPageContainer>
  )
}

export default TopPageMediaOverlap
