import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useSearchBox } from 'react-instantsearch-hooks-web'
import { getAlgoliaResults } from '@algolia/autocomplete-js'
import {
  createAutocomplete,
  AutocompleteState,
} from '@algolia/autocomplete-core'
import { Hit } from '@algolia/client-search'
// import { debounce } from '@algolia/autocomplete-shared'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'

import { initIndexName } from '../../../../../foundation/algolia/algoliaConfig'
import {
  SearchInputWrapper,
  SearchInputIconWrapper,
  SearchInputDisclaimer,
  SearchInput,
  KeywordsContainer,
  KeywordItem,
  LeftSideMenuContainer,
  SideMenuContainer,
  ClearButton,
  KeywordItemLink,
} from '../SearchLayer.style'
import { CloseIconSearch, SearchIcon } from '@components/UI/Icons'
import { generatePath, useNavigate } from 'react-router'
import { formattedParams } from '@utils/url'
import { BASE, SEARCH } from '../../../../../constants/routes'
import { useStoreIdentity } from '../../../../../foundation/hooks/useStoreIdentity'
import { localeLangCountryUtil } from '@utils/countryUtil'
import { INDICES_MAP } from '../../../../../constants/productAlgolia'

type AutocompleteItem = Hit<{
  query: string
}>

const initialAutocompleteState: AutocompleteState<AutocompleteItem> = {
  collections: [],
  completion: null,
  context: {},
  isOpen: false,
  query: '',
  activeItemId: null,
  status: 'idle',
}
const DEBOUNCE_TIMEOUT = 600
const SearchBoxAlgolia = ({
  searchTerm,
  setSearchTerm,
  searchMode,
  setSearchMode,
  searchClient,
  leftSideMenu,
  setLoadingSearch,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { langCode, basePath } = useStoreIdentity()
  const langCountry = localeLangCountryUtil(langCode)

  const { refine: setQuery } = useSearchBox()
  const [autocompleteState, setAutocompleteState] = useState<
    AutocompleteState<AutocompleteItem>
  >(initialAutocompleteState)

  const debouncedSearch = useCallback(
    debounce((searchTerm: string) => {
      setQuery(searchTerm)
      setLoadingSearch(false)
    }, DEBOUNCE_TIMEOUT),
    []
  )

  const debouncePromise = (fn: Function, time: number): any => {
    let timerId: ReturnType<typeof setTimeout> | undefined = undefined
    return function debounced(...args) {
      if (timerId) {
        clearTimeout(timerId)
      }
      return new Promise((resolve) => {
        timerId = setTimeout(() => resolve(fn(...args)), time)
      })
    }
  }
  const debounced = debouncePromise(
    (items) => Promise.resolve(items),
    DEBOUNCE_TIMEOUT
  )

  useEffect(() => {
    setLoadingSearch(!!searchTerm)
    setSearchMode(!!searchTerm)
    debouncedSearch(searchTerm)
  }, [searchTerm])

  const autocomplete = useMemo(() => {
    const indexName = initIndexName(langCountry, INDICES_MAP.QUERY_SUGGESTIONS)

    return createAutocomplete<
      AutocompleteItem,
      React.BaseSyntheticEvent,
      React.MouseEvent,
      React.KeyboardEvent
    >({
      onStateChange({ state }) {
        setAutocompleteState(state)
        setSearchTerm(state.query)
        /* if (context?.queryID && indexName) {
          storeQueryIdAndIndexName(context.queryID, indexName, 'autosuggest')
        } */
      },
      onReset() {
        setSearchTerm('')
        setSearchMode(false)
      },
      getSources() {
        return debounced([
          {
            sourceId: 'querySuggestions',
            getItems({ query, setContext }) {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: indexName,
                    query,
                    params: {
                      hitsPerPage: 5,
                      clickAnalytics: true,
                    },
                  },
                ],
                transformResponse({ hits, results }) {
                  // @ts-ignore
                  setContext({ queryID: results[0].queryID })
                  return hits
                },
              })
            },
          },
        ])
      },
    })
  }, [])

  const inputRef = React.useRef<HTMLInputElement>(null)
  const formRef = React.useRef<HTMLFormElement>(null)
  const panelRef = React.useRef<HTMLDivElement>(null)
  const { getEnvironmentProps } = autocomplete

  React.useEffect(() => {
    if (!formRef.current || !panelRef.current || !inputRef.current) {
      return undefined
    }

    const { onTouchStart, onTouchMove } = getEnvironmentProps({
      formElement: formRef.current,
      inputElement: inputRef.current,
      panelElement: panelRef.current,
    })

    window.addEventListener('touchstart', onTouchStart)
    window.addEventListener('touchmove', onTouchMove)

    return () => {
      window.removeEventListener('touchstart', onTouchStart)
      window.removeEventListener('touchmove', onTouchMove)
    }
  }, [getEnvironmentProps, formRef, inputRef, panelRef])

  const {
    getRootProps,
    getFormProps,
    getInputProps,
    getItemProps,
    getListProps,
  } = autocomplete

  const onKeywordClick = (searchTerm: string) => {
    const searchTermAll = searchTerm.trim() + '*'
    const originalSearchTerm = inputRef?.current?.value

    const params = formattedParams(
      {
        searchTerm: searchTermAll,
        originalSearchTerm,
      },
      true
    )

    navigate({
      pathname: generatePath(`${BASE}${SEARCH}`, { country: langCode }),
      search: params,
    })
  }

  return (
    <>
      <form
        ref={formRef}
        className="aa-Form"
        {...getFormProps({ inputElement: inputRef.current })}
      >
        <SearchInputWrapper {...getRootProps()}>
          <SearchInputIconWrapper type={'left'}>
            <SearchIcon htmlColor={theme.palette.color.grey.dark} />
          </SearchInputIconWrapper>
          <SearchInput
            ref={inputRef}
            {...getInputProps({ inputElement: inputRef.current })}
          />
          <ClearButton type="reset">
            <SearchInputIconWrapper type={'right'}>
              {searchTerm.length > 0 && (
                <CloseIconSearch htmlColor={theme.palette.color.grey.dark} />
              )}
            </SearchInputIconWrapper>
          </ClearButton>
        </SearchInputWrapper>
      </form>
      {searchMode ? (
        <KeywordsContainer {...getListProps()}>
          {autocompleteState &&
            autocompleteState.isOpen &&
            autocompleteState.collections.map((collection, index) => {
              const { source, items } = collection

              return (
                <div key={`source-${index}`}>
                  <SearchInputDisclaimer>
                    {t('search.keywords')}
                  </SearchInputDisclaimer>

                  {items.length > 0 &&
                    items.map((item) => (
                      <li
                        style={{ listStyle: 'none' }}
                        {...getItemProps({
                          item,
                          source,
                        })}
                      >
                        <KeywordItem
                          data-element-id="X_X_SearchPanel_Content_link"
                          data-description={item.query}
                          onClick={() => onKeywordClick(item.query)}
                        >
                          <span>{item.query}</span>
                        </KeywordItem>
                      </li>
                    ))}
                </div>
              )
            })}
        </KeywordsContainer>
      ) : (
        <LeftSideMenuContainer>
          {leftSideMenu.map((sm, i) => {
            return (
              <SideMenuContainer key={`${sm.title}-${i}`}>
                <SearchInputDisclaimer>{sm.title}</SearchInputDisclaimer>
                {sm.menuVoices?.map((mv) => (
                  <KeywordItemLink
                    key={`${mv.title}-${i}`}
                    to={`${basePath}${mv.link}` ?? ''}
                    data-element-id="SearchPanel_Content_link"
                    data-description={mv.title}
                  >
                    {mv.title}
                  </KeywordItemLink>
                ))}
              </SideMenuContainer>
            )
          })}
        </LeftSideMenuContainer>
      )}
    </>
  )
}

export default SearchBoxAlgolia
