import React from 'react'
import { ILXTeaser } from '../../../../../types/cmsPlacement/LXTeaser'
import { StyledToast } from '../../../../UI'
import { ToastProp } from '../../../../UI/Toast/Toast.type'
import { StyledTermsConditionWrapper } from '../CmsTermsAndConditions.style'

export interface CmsTermsAndConditionPanelProps {
  onClose?: () => void
  open?: boolean
  teaser: ILXTeaser
}

const CmsTermsAndConditionPanel: React.FC<CmsTermsAndConditionPanelProps> = ({
  teaser,
  onClose,
  open,
}) => {
  const targetsTermsAndConditions = teaser?.targetsTermsAndConditions
  const target = targetsTermsAndConditions?.target
  const style = targetsTermsAndConditions?.style || ''
  const variant = style?.split('-').pop() as ToastProp['themeVariant']

  if (!target) return null

  return (
    <StyledTermsConditionWrapper>
      <StyledToast
        onClose={onClose}
        headingText={target.title}
        body={target.detailText}
        open={open}
        themeVariant={variant || 'primary'}
      />
    </StyledTermsConditionWrapper>
  )
}

export default CmsTermsAndConditionPanel
