import React, {
  useState,
  useEffect,
  createContext,
  PropsWithChildren,
  useMemo,
  useContext,
} from 'react'

const SCRIPT_ID = 'google-social-oauth'
const useLoadGoogleOAuthScript = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false)

  const isScriptLoaded = (scriptId: string) => {
    return !!document.getElementById(scriptId)
  }

  const loadScript = (scriptId: string) => {
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.id = scriptId
    script.async = true
    script.defer = true
    script.onload = () => {
      setScriptLoaded(true)
    }
    script.onerror = () => {
      setScriptLoaded(false)
    }
    return script
  }

  useEffect(() => {
    if (isScriptLoaded(SCRIPT_ID)) return
    const script = loadScript(SCRIPT_ID)

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return scriptLoaded
}

interface GoogleAuthContext {
  clientId: string
  scriptLoaded: boolean
}

const GoogleOAuthContext = createContext<GoogleAuthContext | undefined>(
  undefined
)

interface GoogleAuthProvider extends PropsWithChildren {
  clientId: string
}

export const GoogleAuthProvider: React.FC<GoogleAuthProvider> = ({
  children,
  clientId,
}) => {
  const scriptLoaded = useLoadGoogleOAuthScript()

  const ctx = useMemo(
    () => ({ scriptLoaded, clientId }),
    [scriptLoaded, clientId]
  )

  return (
    <GoogleOAuthContext.Provider value={ctx}>
      {children}
    </GoogleOAuthContext.Provider>
  )
}

export const useGoogleOAuth = () => {
  const context = useContext(GoogleOAuthContext)

  if (!context) {
    throw new Error(
      'Google Auth components should used with GoogleAuthProvider'
    )
  }
  return context
}
