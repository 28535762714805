import { ServerProduct } from 'src/types/product'
import { getProductAttribute } from './productAttributes'
import { isContactLensesProduct } from './product'

export const isPrescriptionContactLenses = (product: ServerProduct) => {
  if (!isContactLensesProduct(product)) return false

  return (
    getProductAttribute(product, 'CL_SPHERE_POWER') === '+0.00' &&
    !getProductAttribute(product, 'CL_ADDITION') &&
    !getProductAttribute(product, 'CL_AXIS') &&
    !getProductAttribute(product, 'CL_CYLINDER')
  )
}
