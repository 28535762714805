import React from 'react'
import { ILXTeaser } from '../../../../../types/cmsPlacement/LXTeaser'
import { CmsCountdown } from '../../../CmsComponents/CmsCountdown'
import {
  IconContentSquadBanner,
  StyledSquatBannerArticle,
  StyledSquatBannerCTAContainer,
  StyledSquatBannerPreTitle,
  StyledSquatBannerTextContainer,
  StyledSquatBannerTitle,
  StyledSquatBannerWrapper,
} from './SquatBanner.style'
import CmsProductTile from '../../../CmsComponents/CmsProductTile'
import SquatMediaOverlap from './components/SquatMediaOverlap'
import CmsCta from '../../../CmsComponents/CmsCta'
import useCmsTeaserBanner from '../../../../../hooks/useCmsBanner'
import { CmsShoppable } from '../../../CmsComponents/CmsShoppable/CmsShoppableImage'
import { SquatBannerTermsAndCondition } from './components/SquatBannerTermsAndCondition'
import { getTeaserOverlayTextAlign } from '@utils/placements'
import { ISquatBannerProps } from './index'
import usePlayerBanner from '../../../../../hooks/useBannerPlayer'
import CmsFullBannerAnchor from '../../../CmsComponents/CmsFullBannerAnchor'
import CmsIcon from '../../../CmsComponents/CmsIcon'
import CmsVideoController from '@components/Cms/CmsComponents/CmsCommonMedia/CmsVideoController'
import { isLXTeaser } from '../../../../../types/cmsPlacement/Placement'
import { IVideoMedia } from '../../../../../types/cmsPlacement/Media'

export const SquatMobileBanner: React.FC<ISquatBannerProps> = ({
  placement,
  teaserIndex,
  bannerIndex,
}) => {
  const crop = 'SQUAT_BANNER'
  const viewType = placement.viewtype || 'default'
  const item =
    'items' in placement
      ? placement?.items?.find(isLXTeaser)
      : placement?.teasableItems?.find(isLXTeaser)
  const {
    teaserTitle,
    teaserText,
    teaserPreTitle,
    teaserTitleValue,
    teaserTextValue,
    teaserOverlaySettingsValue,
    teaserOverlayTextAlignValue,
    teaserOverlayStyleValue,
    promoteToH1,
    teaserLXCallToActionSettings,
    teaserIcon,
    teaserCountdownStart,
    teaserCountdownUntil,
    teaserHideExpiredCountdown,
    placementHotZones,
    placementHotZonesSettings,
    isShoppableImage,
    toLink,
    productData,
    itemHasTermsAndCondition,
    isFrameAdvisor,
    isVideo,
    media,
  } = useCmsTeaserBanner({ item, viewType }) || {}

  const playerBanner = usePlayerBanner(media! as IVideoMedia)

  const controlCtaEnabled =
    teaserLXCallToActionSettings.filter(
      (callToAction) => callToAction.callToActionEnabled
    ).length > 0
  const paddingBottomEnabled = itemHasTermsAndCondition && controlCtaEnabled

  const content = (
    <>
      {teaserIcon && (
        <IconContentSquadBanner>
          <CmsIcon teaserIcon={teaserIcon} />
        </IconContentSquadBanner>
      )}
      {teaserCountdownStart &&
        teaserCountdownUntil &&
        teaserHideExpiredCountdown && (
          <CmsCountdown
            teaserCountdownStart={teaserCountdownStart}
            teaserCountdownUntil={teaserCountdownUntil}
            teaserHideExpiredCountdown={teaserHideExpiredCountdown}
          />
        )}
      <>
        {teaserPreTitle && (
          <>
            <StyledSquatBannerPreTitle
              data-cm-metadata={'[{"_":"properties.teaserPreTitle"}]'}
              variant={promoteToH1 ? 'h1' : 'h2'}
              fontWeight={'600'}
            >
              {teaserPreTitle}
            </StyledSquatBannerPreTitle>
          </>
        )}
        {teaserTitleValue && (
          <StyledSquatBannerTitle
            data-cm-metadata={`[{"_":"properties.${teaserTitle}"}]`}
            variant={promoteToH1 ? 'h1' : 'h2'}
          >
            {teaserTitleValue}
          </StyledSquatBannerTitle>
        )}
        {teaserTextValue && (
          <StyledSquatBannerTextContainer
            data-cm-metadata={`[{"_":"properties.${teaserText}"}]`}
            dangerouslySetInnerHTML={{ __html: teaserTextValue }}
          />
        )}
      </>
      {productData !== null && (
        <CmsProductTile product={productData} tileIndex={0} />
      )}
      {!!teaserLXCallToActionSettings &&
        teaserLXCallToActionSettings?.length > 0 && (
          <StyledSquatBannerCTAContainer
            id="SquatPageCtaContainer"
            data-element-id={isFrameAdvisor && 'X_X_Footer_FrameGenius_Open'}
          >
            {teaserLXCallToActionSettings?.map((actionSettings, index) => (
              <CmsCta
                actionSettings={actionSettings}
                dataElementId={`X_X_${teaserIndex}Placement_Banner${bannerIndex}_CTA${
                  index + 1
                }`}
                key={`cms-content__text-module--cta-${index + 1}`}
              />
            ))}
          </StyledSquatBannerCTAContainer>
        )}
    </>
  )

  return (
    <>
      {isShoppableImage && (
        <CmsShoppable
          hotZones={placementHotZones}
          hotZonesSettings={placementHotZonesSettings}
        />
      )}
      <CmsFullBannerAnchor
        toLink={toLink}
        bannerIndex={bannerIndex}
        teaserIndex={teaserIndex}
      >
        <SquatMediaOverlap
          crop={crop}
          playerBannerHook={playerBanner}
          teaser={item!}
          media={media!}
          viewType={viewType}
          teaserIndex={teaserIndex}
        />
      </CmsFullBannerAnchor>
      <StyledSquatBannerWrapper
        overlaysettings={teaserOverlaySettingsValue}
        istextoverlay={true}
      >
        <StyledSquatBannerArticle
          overlaysettings={teaserOverlaySettingsValue}
          overlaytxtalign={getTeaserOverlayTextAlign(
            teaserOverlayTextAlignValue
          )}
          teaseroverlaystyle={teaserOverlayStyleValue}
          itemHasTermsAndCondition={paddingBottomEnabled}
        >
          {content}
        </StyledSquatBannerArticle>
      </StyledSquatBannerWrapper>
      {isVideo && <CmsVideoController playerBanner={playerBanner} />}
      {itemHasTermsAndCondition && (
        <SquatBannerTermsAndCondition item={item as ILXTeaser} />
      )}
    </>
  )
}
