import React from 'react'
import {
  ProductTileSecondaryBadge,
  StyledProductTileHeader,
  ProductTilePrimaryBadge,
} from '@components/ProductTile/components/ProductTileHeader/ProductTileHeader.style'
import ProductTileUtils from '@utils/ProductAlgolia/ProductTileAlgoliaUtils'
import { useTranslation } from 'react-i18next'
import { ProductAlgolia } from '../../../../types/ProductAlgolia/ProductAlgolia'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import { SmartGlassesIcon } from '@components/UI/Icons'

export interface ProductTileHeaderProps {
  clusterSelected: ProductAlgolia
}
const ProductTileHeader: React.FC<ProductTileHeaderProps> = ({
  clusterSelected,
}) => {
  const { t } = useTranslation()
  const attributes = clusterSelected.attributes_translated

  const { badges } = ProductTileUtils.getProductTileAttributes(attributes)
  const { primaryBadge, secondaryBadge } = badges

  const { productType } = ProductAlgoliaUtils.getProductAttributes(attributes)
  const { isElectronics } = ProductAlgoliaUtils.getLxProductTypes(productType) //meta sunglasses

  return (
    <StyledProductTileHeader>
      <ProductTilePrimaryBadge>
        {isElectronics ? (
          <>
            <SmartGlassesIcon />
            <>{t('ProductTile.Labels.SmartGlasses')}</>
          </>
        ) : (
          <>
            {primaryBadge ? t(`ProductTile.Labels.${primaryBadge}`) : '\u00A0'}
          </>
        )}
      </ProductTilePrimaryBadge>
      <ProductTileSecondaryBadge>
        {secondaryBadge ? t(`ProductTile.Labels.${secondaryBadge}`) : '\u00A0'}
      </ProductTileSecondaryBadge>
    </StyledProductTileHeader>
  )
}

export default ProductTileHeader
