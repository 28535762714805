//Standard libraries
import React from 'react'
import { useTranslation } from 'react-i18next'
//Foundation libraries
import { useSite } from '../../foundation/hooks/useSite'

export interface FormattedPriceDisplayProps {
  min?: number
  max?: number
  currency?: string
  locale?: string
}

const normalizeSymbol = (currency: string, value: string) => {
  if (currency === 'EUR') {
    return `€${value.replace('€', '').trimEnd()}`
  }
  return value
}

/**
 * Formatted Price or Price Range Display component
 * displays a price or price range between min price to max price that is formatted to the currency and locale or show price pending if price is not available
 * @param props
 */
const FormattedPriceDisplay: React.FC<FormattedPriceDisplayProps> = ({
  max = 0,
  min = 0,
  currency: currencyProp,
}) => {
  const { mySite } = useSite()
  const { i18n } = useTranslation()

  const currency = currencyProp || mySite.defaultCurrencyID

  const { t } = useTranslation()

  if (!mySite) {
    return <></>
  }

  const minCurrency = Intl.NumberFormat(i18n.languages[0], {
    style: 'currency',
    currency,
  }).format(min)

  const maxCurrency = Intl.NumberFormat(i18n.languages[0], {
    style: 'currency',
    currency,
  }).format(max)

  if (max === 0) {
    if (min === null) {
      return <>{t('PriceDisplay.Labels.Pending')}</>
    } else if (min === 0) {
      return <>{normalizeSymbol(currency, minCurrency.slice(0, 4))}</>
    }

    return <>{normalizeSymbol(currency, minCurrency)}</>
  }

  return (
    <>{`${normalizeSymbol(currency, minCurrency)} - ${normalizeSymbol(
      currency,
      maxCurrency
    )}`}</>
  )
}

export default FormattedPriceDisplay
