//Standard libraries
import React from 'react'
//Custom libraries
import { ForgotPasswordLayout } from './ForgotPasswordLayout'
// import { SectionContent } from '../../layouts/sectionContentType'

const ForgotPassword: React.FC<{
  handleClose: () => void
  openStepResetPassword: boolean
}> = ({ handleClose, openStepResetPassword }) => {
  // const forgotPassword: SectionContent[] = [
  //   {
  //     key: 'forgot-password-page-forgotpassword',
  //     CurrentComponent: () => {
  //       return <ForgotPasswordLayout handleClose={handleClose} />
  //     },
  //   },
  // ]

  return (
    <ForgotPasswordLayout
      handleClose={handleClose}
      {...{ openStepResetPassword }}
    />
  )
}

export { ForgotPassword }
