import { styled } from '@mui/material/styles'

export const LoginContainer = styled('div', {
  name: 'Login',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(6),
}))

export const FastLoginContainer = styled('div', {
  name: 'FastLogin',
  slot: 'Container',
})(({ theme }) => ({
  height: theme.spacing(6),
  alignSelf: 'stretch',
  flexGrow: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(4),
  padding: 0,
  marginBottom: theme.spacing(4),
}))

export const FastLoginContent = styled('span', {
  name: 'FastLogin',
  slot: 'Content',
})(({ theme }) => ({
  flexGrow: 0,
  fontSize: 16,
  lineHeight: 1.5,
  color: theme.palette.primary.dark,
}))

export const LineDiv = styled('div', {
  name: 'SignIn',
  slot: 'LineDiv',
})(({ theme }) => ({
  height: 1,
  flexGrow: 1,
  backgroundColor: theme.palette.primary.main,
}))

export const ErrorLogin = styled('div', {
  name: 'ErrorLogin',
  slot: 'Content',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: 14,
  color: theme.palette.error.main,

  span: {
    textTransform: 'uppercase',
    marginBottom: theme.spacing(4),
    fontSize: 16,
    fontWeight: 600,
  },
}))
