import React from 'react'
import { StyledPlacementContainer } from './CmsPlacementContainer.style'
import CollectionContentText from './components/CollectionContentText'
import CollectionCta from './components/CollectionCta'
import WithIsInViewport from '../../../WithIsInViewport/WithIsInViewport'
import {
  IPlacement,
  isCMCollection,
  isLXTeaser,
} from '../../../../types/cmsPlacement/Placement'

export interface PlacementContainerProps {
  placement?: IPlacement
  children?: React.ReactNode
}

const CmsPlacementContainer: React.FC<PlacementContainerProps> = ({
  placement,
  children,
  ...rest
}) => {
  const placementName = placement?.name || ''
  const marginVerticalSize = placement?.marginVertical || ''
  const haveMarginLateral = placement?.marginLateral || false
  const viewType = placement?.viewtype || ''

  const item =
    placement?.viewtype !== 'default'
      ? placement?.items?.find(isLXTeaser)
      : placement?.items?.find(isCMCollection)

  const teaser =
    item?.type === 'LXTeaser' ? item : item?.teasableItems.find(isLXTeaser)

  const backGroundColor =
    placement?.backgroundColor || teaser?.teaserBackground || ''

  const isCollection = item?.type === 'CMCollection'

  const metaData = {
    id: placementName,
    'data-lxcm-lv': `${viewType}`,
    'data-cm-metadata': `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`,
  }

  const placeholder = () => {
    return (
      <div
        style={{
          height: '400px',
        }}
      ></div>
    )
  }
  return (
    <WithIsInViewport placeHolder={placeholder} {...metaData}>
      {isCollection && (
        <CollectionContentText
          item={item}
          backgroundColor={backGroundColor}
          marginLateral={haveMarginLateral}
        />
      )}
      <StyledPlacementContainer
        marginVertical={isCollection ? 'X' : marginVerticalSize}
        marginLateral={haveMarginLateral}
        bgcolor={backGroundColor}
        {...rest}
      >
        {children}
      </StyledPlacementContainer>
      {isCollection && (
        <CollectionCta
          item={item}
          backgroundColor={backGroundColor}
          marginVertical={marginVerticalSize}
          marginLateral={haveMarginLateral}
        />
      )}
    </WithIsInViewport>
  )
}

export default CmsPlacementContainer
