import { styled } from '@mui/material/styles'
import { Z_INDEX_LV3 } from '../../../../../constants/ui'
import {
  ITeaserOverlayStyle,
  ITeaserOverlaySettings,
  ITeaserOverlayTextAlign,
} from '../../../../../types/cmsPlacement/LXTeaser'
import {
  getRatioBanner,
  overLayTextAlignToCSS,
  teaserOverlayStyleToColor,
} from '@utils/placements'
import CmsBroadContentWrapper from '../../../CmsComponents/CmsBroadContentWrapper'
import { CmsBroadContentWrapperProps } from '../../../CmsComponents/CmsBroadContentWrapper/CmsBroadContentWrapper'
import { CmsCountdown } from '../../../CmsComponents/CmsCountdown'
import CmsTeaserDetailText from '../../../CmsComponents/CmsTeaserDetailText'
import CmsTeaserTitle from '../../../CmsComponents/CmsTeaserTitle'
import { CmsTermsAndConditionsButton } from '../../../CmsComponents/CmsTermsAndConditions'
import { StyledTypography } from '../../../../UI'
import { IViewType } from '../../../../../types/cmsPlacement/ViewType'

export const StyledLandscapeBannerWrapper = styled(CmsBroadContentWrapper, {
  name: 'LandscapeBanner',
  slot: 'Wrapper',
})<CmsBroadContentWrapperProps>(({ theme }) => ({
  padding: theme.spacing(0, 4, 10),
  [theme.breakpoints.up('md')]: {
    maxWidth: '50%',
    height: 'auto',
    padding: theme.spacing(12, 44),
  },
}))

export const StyledLandscapeBannerArticle = styled('article', {
  name: 'LandscapeBanner',
  slot: 'Article',
  shouldForwardProp: (prop) =>
    prop !== 'overlaysettings' &&
    prop !== 'overlaytxtalign' &&
    prop !== 'teaseroverlaystyle',
})<{
  overlaysettings?: ITeaserOverlaySettings
  overlaytxtalign?: ITeaserOverlayTextAlign
  teaseroverlaystyle?: ITeaserOverlayStyle
}>(({ theme, overlaytxtalign, teaseroverlaystyle }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: 'unset',
  height: '100%',
  //width: '100%',
  //width: 'auto',
  gap: theme.spacing(2),
  paddingTop: theme.spacing(6),
  ...teaserOverlayStyleToColor(theme, teaseroverlaystyle),
  //cta always centered on mobile
  '& > *:not(#ctaContainer)': {
    ...overLayTextAlignToCSS(overlaytxtalign),
  },
  [theme.breakpoints.up('md')]: {
    padding: 'unset',
    justifyContent: 'center',
    '& > *': {
      ...overLayTextAlignToCSS(overlaytxtalign),
    },
  },
}))

export const StyledLandscapeBannerPreTitle = styled(StyledTypography, {
  name: 'LandscapeBanner',
  slot: 'PreTitle',
})(() => ({
  fontSize: 16,
}))

export const StyledLandscapeBannerTitle = styled(CmsTeaserTitle, {
  name: 'LandscapeBanner',
  slot: 'Title',
})(() => ({}))

export const StyledLandscapeBannerTextContainer = styled(CmsTeaserDetailText, {
  name: 'LandscapeBanner',
  slot: 'TextContainer',
})(() => ({
  width: '100%',
}))

export const StyledLandscapeBannerCTAContainer = styled('div', {
  name: 'LandscapeBanner',
  slot: 'CTAContainer',
})(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexFlow: 'column wrap',
  gap: theme.spacing(4),
  marginTop: theme.spacing(4),
  //ctas are centered on mobile
  alignItems: 'center',
  '& > a': {
    //height: 'auto',
    maxWidth: 285,
    [theme.breakpoints.up('md')]: {
      maxWidth: 343,
    },
  },
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(2),
    flexDirection: 'row',
    '& > a': {
      width: 'auto',
    },
  },
}))

export const MediaOverlapLandscapeContainer = styled('div', {
  name: ' ImageOverlap',
  slot: 'LandscapeContainer',
  shouldForwardProp: (prop) => prop !== 'viewType',
})<{ viewType: IViewType }>(({ viewType, theme }) => ({
  width: '100%',
  position: 'relative',
  ...getRatioBanner(viewType, theme),
}))

export const LandscapeBannerTermsConditionButton = styled(
  CmsTermsAndConditionsButton,
  {
    name: ' LandscapeBanner',
    slot: 'TermsConditionButton',
  }
)(() => ({}))

export const LandscapeBannerTermsConditionButtonContainer = styled('div', {
  name: ' LandscapeBanner',
  slot: 'TermsConditionButtonContainer ',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(4, 0, 8),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(6),
    zIndex: Z_INDEX_LV3,
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
}))

export const LandscapeBannerCountdown = styled(CmsCountdown, {
  name: ' LandscapeBanner',
  slot: 'Countdown',
})(({ theme }) => ({
  marginBottom: theme.spacing(2),
  h3: {
    margin: 'unset',
  },
}))

export const IconContentLandscapeBanner = styled('div', {
  name: 'GridOfBoards',
  slot: 'IconContent',
})(({}) => ({
  display: 'flex',

  svg: {
    height: 48,
    width: 48,
  },
}))
