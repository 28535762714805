import React from 'react'
import {
  StyledProductBrandName,
  StyledProductDescriptionAnchor,
  StyledProductName,
  StyledProductTileDescription,
  StyledProductTileFooter,
  StyledProductTileFooterWrapper,
} from '@components/ProductTile/components/ProductTileFooter/ProductTileFooter.style'
import { Product, SupplyData } from '../../../../types/product'
import ProductTileUtils from '@components/ProductTile/ProductTileUtilsOld'
import config from '@configs/index'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import ProductPriceAlgoliaUtils from '@utils/ProductAlgolia/ProductPriceAlgoliaUtils'
import ProductPriceFinal from '@components/ProductPriceFinal'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'

export interface ProductTileFooterProps {
  isPDP?: boolean
  tileDataElementId: string
  mobileListingLayoutBasedOnPages?: string
  annualSupplyData?: SupplyData
  clusterSelected: Product
  productLinkTo: string
  productPrimaryBadge: string
  isTrendingProduct?: boolean
}

const siteName = config.REACT_APP_STORENAME

const ProductTileFooterOld: React.FC<ProductTileFooterProps> = ({
  isPDP,
  tileDataElementId,
  clusterSelected,
  productLinkTo,
  productPrimaryBadge,
}) => {
  const { country } = useStoreIdentity()

  const { modelName, brand, displaySku, lxSearchPageProductType } =
    ProductAlgoliaUtils.getProductAttributes(clusterSelected.productAttributes!)
  const { isFrames, isContactLensesAccessories, isContactLenses } =
    ProductTileUtils.getLxProductTypes(lxSearchPageProductType)

  const isContactLensesOrCLAccessories =
    isContactLenses || isContactLensesAccessories

  const productPrice = clusterSelected?.x_price
  return (
    <StyledProductTileFooterWrapper>
      <StyledProductTileFooter>
        <StyledProductTileDescription isPDP={isPDP!}>
          <StyledProductDescriptionAnchor
            aria-label={productPrimaryBadge || `${modelName}_${displaySku}`}
            to={productLinkTo}
            data-element-id={tileDataElementId}
            data-description={encodeURIComponent(
              `${siteName}_${modelName}_${displaySku}`
            )}
            isClRelatedTile={!isFrames}
          >
            <StyledProductName type={lxSearchPageProductType}>
              {modelName ? modelName : '\u00A0'}
            </StyledProductName>
            {!isContactLensesOrCLAccessories ? (
              <StyledProductBrandName>
                {brand || 'Brand name'}
              </StyledProductBrandName>
            ) : null}
            {productPrice && (
              <ProductPriceFinal
                pageType={'plp'}
                productType={lxSearchPageProductType}
                price={
                  ProductPriceAlgoliaUtils.getDeterminatePrice(
                    productPrice,
                    country
                  )!
                }
              />
            )}
          </StyledProductDescriptionAnchor>
        </StyledProductTileDescription>
      </StyledProductTileFooter>
    </StyledProductTileFooterWrapper>
  )
}

export default ProductTileFooterOld
