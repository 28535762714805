import { find } from 'lodash'
import { useSelector } from 'react-redux'
import { cmsApi } from 'src/features/cms/query'
import { useStoreIdentity } from 'src/foundation/hooks/useStoreIdentity'
import Log from 'src/services/Log'
import { ICMChannel } from 'src/types/cmsPlacement/CMChannel'

/**
Global external library functions hook.
it's purpose is to append functions to the window objest in order to
make all functions coming from external library to be executed (eg: Utag)
*/
export const useGlobalExternalActions = () => {
  const { basePath } = useStoreIdentity()
  try {
    const footerPlacements = useSelector(cmsApi.endpoints.getFooter.select())
      ?.data?.footerPlacements
    const navigation = find(footerPlacements, { name: 'footer_navigation' })
    const legalInfo = find(navigation?.items, {
      name: 'Informazioni_legali',
    }) as ICMChannel
    const cookiesPolicyPrivacyUrl = legalInfo?.children
      ?.find((child) => child?.name === 'WW_cookies_policy')
      ?.teaserTargets?.find((teaser) => !!teaser)?.target?.formattedUrl

    const termsAndContiditionsUrl = legalInfo?.children
      ?.find((child) => child?.name === 'WW_termini_e_condizioni')
      ?.teaserTargets?.find((teaser) => !!teaser)?.target?.formattedUrl

    window.TealiumConsentPrivacyLink = () => {
      try {
        if (!!cookiesPolicyPrivacyUrl) {
          window.location.href = `${basePath}${cookiesPolicyPrivacyUrl}`
        }
      } catch (e: any) {
        Log.error('error while directing to cookies policy page', e)
      }
    }

    window.TealiumTermsOfUseLink = () => {
      try {
        if (!!termsAndContiditionsUrl) {
          window.location.href = `${basePath}${termsAndContiditionsUrl}`
        }
      } catch (e: any) {
        Log.error('error while directing to terms and condition page', e)
      }
    }
  } catch (e: any) {
    Log.error('error while appending new global function to window object', e)
  }
}
