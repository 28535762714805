import {
  PLP_LINK_CONTACT_LENSES,
  PLP_LINK_GLASSES,
  PLP_LINK_SUNGLASSES,
} from 'src/constants/ui'

export const PRODUCT_TYPES = {
  sun: 'Sun',
  optical: 'Optics',
  optics: 'Optics',
  'contact lenses': 'CONTACT LENSES',
  'contact lenses accessories': 'ACCESSORIES',
  electronics: 'Electronics',
} as const

export const PAGE_SECTION_MAPPING = {
  [PLP_LINK_SUNGLASSES]: 'sun',
  [PLP_LINK_GLASSES]: 'optics',
  [PLP_LINK_CONTACT_LENSES]: 'contact-lenses',
}

export const PRODUCT_TAX_RATE = {
  prescriptionCL: 0.04,
  frames: 0.22,
}
