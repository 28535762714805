import React, { useState } from 'react'
import {
  ContentNewsLetterBanner,
  ContentTextFieldsNewsLetterBanner,
  ContentTextNewsLetterBanner,
  WrapperNewsLetterBanner,
} from '@components/Cms/CmsPlacement/CmsBanner/NewsLetterBanner/NewsLetterBanner.style'
import {
  Button,
  ButtonAsLink,
  StyledAnchor,
  StyledTypography,
  TextField,
} from '@components/UI'
import { Trans, useTranslation } from 'react-i18next'
import { sendNewsletterSubscriptionEvent } from '../../../../../foundation/analytics/tealium/lib'
import { useStoreIdentity } from '../../../../../foundation/hooks/useStoreIdentity'
import { useSelector } from 'react-redux'
import { storeCfgServiceLinksSelector } from '../../../../../redux/selectors/site'
import { useSubscribeToNewsletterMutation } from '../../../../../features/newsLetter/query'

type ResultObject = {
  error?: boolean
  text?: string
}

const NewsLetterBanner: React.FC = () => {
  const { t } = useTranslation()
  //const mySite = useSelector(siteSelector)!
  const [error, setError] = useState<boolean>(false)
  const [result, setResult] = useState<ResultObject>({})
  const [email, setEmail] = useState('')
  const { basePath } = useStoreIdentity()
  const serviceLinks = useSelector(storeCfgServiceLinksSelector)
  const privacyLink = `${basePath}${serviceLinks?.privacy || ''}`

  const [subscribeToNewsletter, { isLoading }] =
    useSubscribeToNewsletterMutation()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(false)
    setResult({})
    setEmail(e.target.value)
  }

  const handleSignUpAgain = () => {
    setResult({})
    setEmail('')
  }

  const subscribe = async () => {
    const emailRegExp = new RegExp(
      '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])'
    )

    if (emailRegExp.test(email)) {
      try {
        const data = await subscribeToNewsletter({
          email,
          from: 'footer',
        }).unwrap()

        if (data.code === '200') {
          setResult({
            error: false,
            text: t('Newsletter.Success'),
          })
          sendNewsletterSubscriptionEvent(email)
        } else if (data.code === '409') {
          setResult({
            error: true,
            text: t('NewsLetterDrawerContent.Errors.Duplicate'),
          })
          sendNewsletterSubscriptionEvent(email)
        } else {
          setResult({
            error: true,
            text: t('NewsLetterDrawerContent.Errors.Generic'),
          })
        }
      } catch (e: any) {
        setResult({
          error: true,
          text: t('NewsLetterDrawerContent.Errors.Generic'),
        })
      }
    } else {
      setError(true)
    }
  }

  return (
    <WrapperNewsLetterBanner>
      {Object.keys(result).length === 0 || result.error ? (
        <ContentNewsLetterBanner>
          <ContentTextNewsLetterBanner>
            <StyledTypography
              fontSize={16}
              fontWeight={'bold'}
              style={{ textTransform: 'uppercase' }}
            >
              {t('NewsLetterDrawerContent.MyInfo')}
            </StyledTypography>
            <StyledTypography fontSize={14}>
              {t('NewsLetterDrawerContent.MandatoryFields')}
            </StyledTypography>
          </ContentTextNewsLetterBanner>
          <ContentTextFieldsNewsLetterBanner>
            <TextField
              error={error || result.error}
              helperText={
                error && Object.keys(result).length === 0
                  ? t('NewsLetterDrawerContent.Errors.Input')
                  : result.error
                  ? result.text
                  : null
              }
              showclearbutton
              variant="outlined"
              margin="none"
              fullWidth
              name="email"
              label="Email"
              type="email"
              id="newsletter-email"
              value={email}
              required
              showvalidationstatus
              onChange={onChange}
              isfilled={email.length > 0}
              onClearButtonClick={() => setEmail('')}
            />
          </ContentTextFieldsNewsLetterBanner>
          <StyledTypography fontSize={14}>
            <Trans
              i18nKey="NewsLetterDrawerContent.NewsLetterConsentText"
              components={{
                link1: <StyledAnchor to={privacyLink} />,
              }}
            />
          </StyledTypography>
          <Button
            data-element-id={'X_X_Newsletter_Subscribe'}
            onClick={subscribe}
            variant={'primary'}
            labelText={t('NewsLetterDrawerContent.SubscribeCTA')}
            style={{ marginRight: 'auto' }}
            loading={isLoading}
            disabled={isLoading}
          />
        </ContentNewsLetterBanner>
      ) : (
        <ContentNewsLetterBanner>
          <ContentTextNewsLetterBanner>
            <StyledTypography
              fontSize={16}
              fontWeight={'bold'}
              style={{ textTransform: 'uppercase' }}
            >
              {t('NewsLetterDrawerContent.ThankYouTitle')}
            </StyledTypography>
            <StyledTypography fontSize={16}>
              {t('NewsLetterDrawerContent.ThankYouSubtitle')}
            </StyledTypography>
            <div>
              <ButtonAsLink onClick={handleSignUpAgain}>
                {t('NewsLetterDrawerContent.NewsLetterSignUpAgainCTA')}
              </ButtonAsLink>
            </div>
          </ContentTextNewsLetterBanner>
        </ContentNewsLetterBanner>
      )}
    </WrapperNewsLetterBanner>
  )
}

export default NewsLetterBanner
