import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useNavigate } from 'react-router-dom'
import { ContactLensesData } from 'src/types/prescription'
import { ImageryType } from '@components/PrescriptionLenses/PrescriptionLenses'
import { RX_CONF_SELECTOR } from '../../../constants/common'
import { PRODUCT_URL_SEARCH_PARAMS } from '../../../constants/product'
import { CART } from '../../../constants/routes'
import { useDeleteOrderItemMutation } from '../../../features/order/query'
import { addItem } from '../../../features/order/thunks'
import addContactLenses, {
  ContactLensesAddItemArgs,
} from '../../../features/order/thunks/addContactLenses'
import addLensItem, {
  IAddLensArgs,
} from '../../../features/order/thunks/addLensItem'

import {
  productContactLensesDataSelector,
  productAddToCartEnabledSelector,
} from '../../../features/product/selector'
import useRouteQuery from '../../../foundation/hooks/useRouteQuery'
import { useSite } from '../../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { OrderItem } from '../../../types/order'
import { Product, ServerProduct } from '../../../types/product'
import { LensObject } from '../../../types/rxConfigurator'
import {
  isFormattedProductCLAccessories,
  isFormattedProductContactLenses,
} from '@utils/product'
import { storageSessionHandler } from 'src/foundation/utils/storageUtil'
import { getGuestIdentity } from 'src/features/user/thunks'
interface AddToCartProps {
  isClItem?: boolean
  isLenses?: boolean
  product?: ServerProduct | Product
  fromConfigurator?: boolean
  lensObject?: LensObject
  images?: ImageryType
  quantity?: string[]
}

export const useAddOrderItem = (
  productInCart?: OrderItem,
  currentProduct?: Product,
  partNumber?: string,
  productQuantity?: string[]
) => {
  const { mySite } = useSite()
  const navigate = useNavigate()
  const { langId, langCode } = useStoreIdentity()
  const dispatch = useDispatch()
  const contactLensData = useSelector(productContactLensesDataSelector)
  const addToCartEnabled = useSelector(productAddToCartEnabledSelector)
  const query = useRouteQuery()
  const [removeOrderItem] = useDeleteOrderItemMutation()
  const isClAccessory = isFormattedProductCLAccessories(
    currentProduct?.productAttributes['PRODUCT_TYPE'] || ''
  )
  const isCl = isFormattedProductContactLenses(
    currentProduct?.productAttributes['PRODUCT_TYPE'] || ''
  )
  const isEditingContactLens = !!query.get(
    PRODUCT_URL_SEARCH_PARAMS.EDIT_CONTACT_LENS
  )
  const isEditingLenses = !!query.get(PRODUCT_URL_SEARCH_PARAMS.EDIT_LENS)

  const buildContactLensesPayload = (
    contactLensData: ContactLensesData
  ): ContactLensesAddItemArgs['items'] => {
    const clPayload: ContactLensesAddItemArgs['items'] = !!contactLensData
      ? Object.values(contactLensData).map((entry) => {
          const singleLensData = { ...entry }
          let { quantity } = singleLensData
          delete singleLensData.quantity
          return {
            x_contactLens: {
              ...singleLensData,
            },
            quantity: quantity || '',
          }
        })
      : []
    return clPayload
  }

  const removeItemFromCart = useCallback(
    async (orderItem: OrderItem) => {
      await removeOrderItem({
        orderItem,
      })
    },
    [isEditingContactLens]
  )

  const addItemToCart = ({
    isClItem,
    isLenses,
    fromConfigurator,
    lensObject,
    images,
    quantity,
  }: AddToCartProps) => {
    if (isClItem) {
      const contactLensesParams: ContactLensesAddItemArgs = {
        items: contactLensData
          ? buildContactLensesPayload(contactLensData)
          : [],
        product: currentProduct,
        callback: () => {
          navigate(generatePath(`/:country/${CART}`, { country: langCode }))
        },
        langId,
      }
      dispatch(addContactLenses(contactLensesParams))
    } else if (isLenses) {
      const params: IAddLensArgs = {
        params: {
          catentryId: lensObject?.lensPackage.catEntryId || '',
          langId,
          partnumber: partNumber || '',
          product: currentProduct,
          quantity: ['1'],
          images,
        },
        callback: () => {
          if (window.RXC) {
            window.RXC.rxcWidget.close(`#${RX_CONF_SELECTOR}`)
          }
          navigate(generatePath(`/:country/${CART}`, { country: langCode }))
        },
        siteInfo: mySite,
      }
      dispatch(addLensItem(params))
    } else {
      const params = {
        partnumber: partNumber || '',
        quantity: isClAccessory ? quantity || productQuantity || [''] : ['1'],
        langId,
        product: currentProduct,
        callback: fromConfigurator
          ? () => {
              if (window.RXC) {
                window.RXC.rxcWidget.close(`#${RX_CONF_SELECTOR}`)
              }

              navigate(generatePath(`/:country/${CART}`, { country: langCode }))
            }
          : () => {
              navigate(generatePath(`/:country/${CART}`, { country: langCode }))
            },
      }
      dispatch(addItem(params))
    }
  }

  const addToCart = async ({
    isClItem = isCl,
    isLenses,
    product,
    fromConfigurator,
    lensObject,
    images,
  }: AddToCartProps = {}) => {
    if (!addToCartEnabled) {
      return
    }

    const productInCartQuantity = +(productInCart?.quantity || 0)
    const newProductQuantity = +(productQuantity?.[0] || 0)

    const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()
    if (!currentUser) {
      await dispatch(getGuestIdentity())
    }

    if (isEditingContactLens || isEditingLenses || isClAccessory) {
      !!productInCart && (await removeItemFromCart(productInCart))
    }

    addItemToCart({
      isClItem,
      isLenses,
      product,
      fromConfigurator,
      lensObject,
      images,
      quantity: isClAccessory
        ? [(productInCartQuantity + newProductQuantity).toString()]
        : undefined,
    })
  }

  const handleAddToCartLenses = (_, lensObject: LensObject, ...args) => {
    // when we call the configurator with the cartMode object
    // the position of the image arg, isn't the same as the usual
    const images: ImageryType = args.find(
      (arg) => !!arg?.fallbackImage || !!arg?.productImage
    )

    if (lensObject.lensPackage.type === 'Frame Only') {
      addToCart({ fromConfigurator: true })
    } else {
      addToCart({ isLenses: true, lensObject, images })

      // @TODO add to cart RX analytics
      // if (currentProduct) {
      //   tealiumService.sendAddToCartEvent({
      //     ...getProductWithSoldoutStatusForAnalytics(currentProduct, soldOutStatus!),
      //     rxPrice: +lensObject.lensPackage.listPrice,
      //   })
      // }
    }
  }

  return {
    addToCart,
    handleAddToCartLenses,
  }
}
