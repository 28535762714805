import React from 'react'
import { WrapperGridOfBoards } from './GridOfBoards.style'

import GridOfBoardsItem from './components/GridOfBoardItem'
import {
  IPlacement,
  isLXTeaser,
} from '../../../../../types/cmsPlacement/Placement'
import { ICMCollection } from '../../../../../types/cmsPlacement/CMCollection'

interface IProps {
  columnAmount: 2 | 3
  placement: IPlacement | ICMCollection
  placementCenter: boolean
  teaserIndex?: number
}

const GridOfBoards: React.FC<IProps> = ({
  placement,
  columnAmount,
  placementCenter,
  teaserIndex,
}) => {
  const items =
    'items' in placement
      ? placement.items.filter(isLXTeaser) || []
      : placement.teasableItems.filter(isLXTeaser) || []

  return (
    <WrapperGridOfBoards>
      {items.map((item, i) => (
        <React.Fragment key={item.id}>
          <GridOfBoardsItem
            item={item}
            viewType={placement.viewtype}
            columnAmount={columnAmount}
            placementCenter={placementCenter}
            teaserIndex={teaserIndex}
            bannerIndex={i + 1}
          />
        </React.Fragment>
      ))}
    </WrapperGridOfBoards>
  )
}

export default GridOfBoards
