import isEmpty from 'lodash/isEmpty'
import React, { useEffect } from 'react'
import Log from '../../services/Log'
import { SuggesterInputProps } from '../../types/inputFields'
import { StyledTextField } from './AddressForm.style'
import useAddressSuggestion from './useAddressSuggestion'

const ZipCodeSuggester: React.FC<SuggesterInputProps> = ({
  gMapLoaded,
  cid,
  value,
  fieldName,
  fieldLabel,
  mandatoryField,

  formErrors,
  error,
  onSuggestedFieldValueChanged,

  country,
  addressFormFields,
  ...props
}) => {
  const addressFormFieldsNames = addressFormFields?.map((field) => {
    return field.fieldName
  })
  const { getCityAndStateByZipcode, init } = useAddressSuggestion(
    country.toLowerCase(),
    addressFormFieldsNames
  )

  useEffect(() => {
    gMapLoaded && init()
  }, [gMapLoaded, country])

  return (
    <StyledTextField
      InputLabelProps={{ required: false }}
      {...props}
      onChange={(e) => {
        onSuggestedFieldValueChanged(fieldName, e.target.value)
        getCityAndStateByZipcode(e.target.value, country)
          .then((cityStateInfo) => {
            const { city, state } = cityStateInfo || {}
            city && onSuggestedFieldValueChanged('city', city)
            state && onSuggestedFieldValueChanged('state', state)
          })
          .catch((error) => {
            Log.error('Could not retrieve city details', error)
          })
      }}
      value={value?.toUpperCase()}
      required={mandatoryField}
      id={`${cid}-${fieldName}`}
      type="text"
      name={fieldName}
      label={fieldLabel}
      error={error}
      helperText={<>{formErrors[fieldName]?.message}</>}
      showvalidationstatus
      isvalid={!error && !isEmpty(value)}
      inputProps={{ maxLength: 10 }}
    />
  )
}

export { ZipCodeSuggester }
