import { styled } from '@mui/material/styles'
export const CmsIconContainer = styled('div', {
  name: 'TextModule',
  slot: 'CmsIconContainer',
  shouldForwardProp: (prop) => prop !== 'justifyContent',
})<{ justifyContent: React.CSSProperties['justifyContent'] }>(() => ({
  display: 'flex',
}))

export const ContentCmsIcon = styled('div', {
  name: 'PlacementContainer',
  slot: 'ContentCmsIcon',
  shouldForwardProp: (prop) => prop !== 'alignSelf' && prop !== 'isLandscape',
})<{ alignSelf?: React.CSSProperties['alignSelf']; isLandscape?: boolean }>(
  ({ theme, isLandscape }) => ({
    height: theme.spacing(12),
    width: theme.spacing(12),
    marginTop: isLandscape ? theme.spacing(4) : 0,
    marginBottom: isLandscape ? theme.spacing(4) : theme.spacing(9),
    flex: '0 1 auto',
    /*[theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(13),
      alignSelf:
        alignSelf === 'center'
          ? 'center'
          : alignSelf === 'left'
          ? 'flex-start'
          : 'flex-end',
    },*/
  })
)
