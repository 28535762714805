import {
  StyledGridOfProductsContainer,
  StyledGridOfProductsItem,
} from './GridOfProducts.style'

import CmsProductTile from '../../../CmsComponents/CmsProductTile'
import React from 'react'
import { IPlacement } from '../../../../../types/cmsPlacement/Placement'
import { ICMProductTeaser } from '../../../../../types/cmsPlacement/CMProductTeaser'
import { ICMExternalProduct } from '../../../../../types/cmsPlacement/CMExternalProduct'
import { ICMCollection } from '../../../../../types/cmsPlacement/CMCollection'
import { ICMProductList } from '../../../../../types/cmsPlacement/CMProductList'

interface IProps {
  placement: IPlacement | ICMCollection | ICMProductList
  teaserIndex?: number
}
type IProductType = (ICMExternalProduct | ICMProductTeaser)[]

const GridOfProducts: React.FC<IProps> = ({ placement, teaserIndex }) => {
  let productData
  if ('items' in placement) {
    const items =
      (placement.items.filter((item) =>
        ['CMProductTeaser', 'CMExternalProduct'].includes(item.type)
      ) as IProductType) || []
    productData =
      items
        .map((x) => x.productData)
        .filter((product) => product !== undefined) || []
  } else {
    if (placement.type === 'CMCollection') {
      const teasableItems =
        (placement.teasableItems.filter((item) =>
          ['CMProductTeaser', 'CMExternalProduct'].includes(item.type)
        ) as IProductType) || []

      productData =
        teasableItems
          .map((x) => x.productData)
          .filter((product) => product !== undefined) || []
    } else {
      productData = placement.productData || []
    }
  }

  return (
    <StyledGridOfProductsContainer>
      {productData.map(
        (product, index) =>
          product && (
            <StyledGridOfProductsItem key={product.uniqueID}>
              <CmsProductTile
                isLazyLoadEnabled={true}
                product={product}
                tileIndex={index}
                teaserIndex={teaserIndex}
              />
            </StyledGridOfProductsItem>
          )
      )}
    </StyledGridOfProductsContainer>
  )
}

export default GridOfProducts
