import React from 'react'

import {
  StyledCheckoutProgressContainer,
  StyledCheckoutProgressBar,
  StyledCheckoutProgressBarElement,
  StyledCheckoutProgressBarElementLine,
  StyledCheckoutProgressBarElementStepname,
  StyledCheckoutProgressBarElementDot,
  StyledCheckoutProgressBarElementWrapper,
} from './CheckoutProgress.style'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { useCheckoutSteps } from '@hooks/useCheckoutSteps'
import { useTheme } from '@mui/material/styles'
import { CheckmarkOutlinedIcon } from '@components/UI/Icons'
import { useNavigate } from 'react-router'
import { CHECKOUT } from '../../../constants/routes'
import { CHECKOUT_STEPS } from '../../../constants/checkout'

export const CheckoutProgress: React.FC<{
  activeStep?: string
}> = () => {
  const { activeStep, previousSteps, checkoutSteps } = useCheckoutSteps()
  const { isMobile } = useBreakpoints()
  const theme = useTheme()
  const navigate = useNavigate()

  return activeStep !== CHECKOUT_STEPS.ORDER_CONFIRMATION ? (
    <StyledCheckoutProgressContainer>
      <StyledCheckoutProgressBar>
        {checkoutSteps.map((step, index) => {
          const isActive = step.stepId === activeStep ? 1 : 0
          const isCompleted = previousSteps.includes(step) ? 1 : 0
          const isLastStep = index + 1 === checkoutSteps.length

          return (
            <StyledCheckoutProgressBarElement
              key={step.stepId}
              active={isActive}
              completed={isCompleted}
              last={isLastStep ? 1 : 0}
              onClick={() =>
                isCompleted && navigate(`${CHECKOUT}/${step.stepId}`)
              }
            >
              <StyledCheckoutProgressBarElementWrapper completed={isCompleted}>
                <StyledCheckoutProgressBarElementDot
                  completed={isCompleted}
                  active={isActive}
                >
                  {isCompleted === 1 && (
                    <CheckmarkOutlinedIcon
                      htmlColor={theme.palette.text.dark}
                    />
                  )}
                  {isMobile && (
                    <StyledCheckoutProgressBarElementStepname
                      isActive={!!isActive}
                    >
                      {step.stepTitle.replace('verification', '').trim()}
                    </StyledCheckoutProgressBarElementStepname>
                  )}
                </StyledCheckoutProgressBarElementDot>
                {!isMobile && (
                  <StyledCheckoutProgressBarElementStepname
                    isActive={!!isActive}
                  >
                    {step.stepTitle}
                  </StyledCheckoutProgressBarElementStepname>
                )}
              </StyledCheckoutProgressBarElementWrapper>
              {!isLastStep && (
                <StyledCheckoutProgressBarElementLine completed={isCompleted} />
              )}
            </StyledCheckoutProgressBarElement>
          )
        })}
      </StyledCheckoutProgressBar>
    </StyledCheckoutProgressContainer>
  ) : null
}
