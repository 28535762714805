import { styled } from '@mui/material/styles'
import { Button, StyledTypography } from '../UI'
import { FindStoreInput } from './components/FindStoreInput'

const INPUT_HEIGHT = 12

export const FindStoreInputStyled = styled(FindStoreInput, {
  name: 'SearchStore',
  slot: 'TextField',
})(() => ({
  marginBottom: '0',
}))

export const SearchContainer = styled('div', {
  name: 'SearchStore',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.common.white,
  padding: theme.spacing(6),
  border: `solid 1px ${theme.palette.color.grey.light}`,
}))

export const InputContainer = styled('div', {
  name: 'SearchStore',
  slot: 'InputContainer',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  maxWidth: theme.spacing(131),
  height: theme.spacing(30),
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(6),
}))

export const InputWithIconContainer = styled('div', {
  name: 'SearchStore',
  slot: 'InputWithIconContainer',
})(() => ({
  width: '100%',
  position: 'relative',
}))

export const IconContainer = styled('div', {
  name: 'SearchStore',
  slot: 'IconContainer',
})(({ theme }) => ({
  position: 'absolute',
  right: '3%',
  top: '50%',
  transform: 'translateY(-50%)',
  cursor: 'pointer',
  width: theme.spacing(4),
  height: theme.spacing(4),
}))

export const IconPositionContainer = styled('div', {
  name: 'SearchStore',
  slot: 'IconPositionContainer',
})(({ theme }) => ({
  cursor: 'pointer',
  width: theme.spacing(4),
  height: theme.spacing(4),
}))
export const IconDangerContainer = styled('div', {
  name: 'SearchStore',
  slot: 'IconDangerContainer',
})(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  svg: {
    color: theme.palette.error.main,
  },
}))
export const InputIconWrapper = styled('div', {
  name: 'SearchStore',
  slot: 'InputIconWrapper',
})(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  display: 'flex',
  right: 0,
  gap: theme.spacing(4),
  padding: theme.spacing(0, 4),
  zIndex: 1,
}))

export const PlaceHolderLabel = styled('label', {
  name: 'Placeholder',
  slot: 'Label',
})(({ theme }) => ({
  margin: theme.spacing(0, 4),
  transition: 'all 300ms ease 0s;',
  transform: 'none;',
  opacity: 1,
  whiteSpace: 'nowrap',
  position: 'absolute',
  top: theme.spacing(-4.5),
  left: theme.spacing(-3),
}))

export const StyledInputWrapper = styled('div', {
  name: 'SearchStore',
  slot: 'InputWrapper',
})(({ theme }) => ({
  position: 'relative',
  width: '100%',

  '> .pac-container': {
    left: '0 !important',
    top: `${theme.spacing(INPUT_HEIGHT)} !important`,
  },
}))

export const StyledInput = styled('input', {
  name: 'SearchStore',
  slot: 'SearchTextField',
  shouldForwardProp: (prop) => prop !== 'error',
})<{
  error: boolean
}>(({ theme, error }) => ({
  fontSize: 16,
  height: theme.spacing(INPUT_HEIGHT),
  width: '100%',
  padding: theme.spacing(4, 3),
  borderRadius: theme.spacing(1),
  border: `solid 1px ${
    error ? theme.palette.error.main : theme.palette.color.grey.light
  }`,
  backgroundColor: theme.palette.common.white,
  paddingRight: error ? 70 : 40,

  ':focus': {
    outlineColor: theme.palette.primary.main,
  },
}))

export const InputErrorMessage = styled('span', {
  name: 'SearchStore',
  slot: 'InputErrorText',
})(({ theme }) => ({
  color: theme.palette.error.main,
  padding: theme.spacing(1, 0, 0, 4),
  fontSize: 12,
}))

export const StyledButton = styled(Button, {
  name: 'SearchStore',
  slot: 'SearchStoreButton',
})(({ theme }) => ({
  marginTop: theme.spacing(4),
  width: 110,
  height: 40,
  [theme.breakpoints.up('sm')]: {
    width: 110,
  },
}))

export const SearchTitle = styled('h2', {
  name: 'SearchStore',
  slot: 'SearchTextTitle',
})(({ theme }) => ({
  margin: 0,
  marginBottom: theme.spacing(2),
  lineHeight: 1.5,
  fontWeight: 600,
  color: theme.palette.text.dark,
  textTransform: 'uppercase',
  fontSize: 16,
}))

export const SearchSubTitle = styled(StyledTypography, {
  name: 'SearchStore',
  slot: 'SearchTextSubTitle',
})(({ theme }) => ({
  width: '100%',
  fontSize: 16,
  color: theme.palette.text.dark,
}))

export const SearchTitleContainer = styled('div', {
  name: 'SearchStore',
  slot: 'SearchTitleContainer',
})(() => ({
  width: '100%',
}))
