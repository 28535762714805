import { styled } from '@mui/material/styles'

export const TrendingProductsContainer = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridAutoRows: 'max-content',
  gridGap: theme.spacing(3),
  paddingBottom: theme.spacing(3),

  [theme.breakpoints.up('sm')]: {
    overflow: 'auto',
  },
}))

export const TrendingProductsTitle = styled('div')(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 600,
  color: theme.palette.text.dark,
  marginBottom: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(6),
  },
}))
