import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import {
  SocialItem,
  OauthIdentityResponse,
  LoginIdentityResponse,
  UpdatePersonResponse,
} from '../../types/account'
import { PersonResponse } from 'src/types/user'
import { AddressFormData } from 'src/types/form'

export const accountApi = createApi({
  reducerPath: 'accountApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/',
  }),
  endpoints: (build) => ({
    getOauthInfo: build.mutation<
      OauthIdentityResponse,
      {
        storeId: string
        socialItem: SocialItem
        redirectUrl: string
      }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, socialItem, redirectUrl } = args
        const { authorizationProvider } = socialItem
        const result = await fetchWithBQ({
          url: `store/${storeId}/loginidentity/oauth`,
          method: 'POST',
          body:
            {
              authorizationProvider,
              redirectUrl,
            } || {},
        })

        if (result.error) {
          return { error: result.error as FetchBaseQueryError }
        }
        return { data: result.data as OauthIdentityResponse }
      },
    }),
    oauthValidate: build.mutation<
      LoginIdentityResponse,
      {
        storeId: string
        id?: string
        accessToken?: string
        clientId?: string
        email?: string
        birthday?: string
        name?: string
        socialItem: SocialItem
        redirectUrl: string
      }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, socialItem, redirectUrl } = args
        const { authorizationProvider, code, accessToken, clientId } =
          socialItem
        const result = await fetchWithBQ({
          url: `store/${storeId}/loginidentity/oauth_validate`,
          method: 'POST',
          body:
            {
              ...args,
              authorizationProvider,
              code,
              accessToken,
              clientId,
              redirectUrl,
            } || {},
        })
        if (result.error) {
          return { error: result.error }
        }
        return { data: result.data as LoginIdentityResponse }
      },
    }),
    findPersonBySelf: build.query<PersonResponse, void>({
      async queryFn(args, queryApi, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'store/{storeId}/person/@self',
          method: 'GET',
        })

        if (result.error) {
          return { error: result.error as FetchBaseQueryError }
        }
        return { data: result.data }
      },
    }),
    deletePersonContact: build.mutation<
      {
        addressId: string[]
        resourceName: string
      },
      {
        nickName: string
      }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        /* const state = queryApi.getState() as RootReducerState
        const { storeID } = state.site.currentSite!*/
        const { nickName } = args
        const result = await fetchWithBQ({
          url: `store/{storeId}/person/@self/contact/${nickName}`,
          method: 'DELETE',
        })

        if (result.error) {
          return { error: result.error as FetchBaseQueryError }
        }
        return { data: result.data }
      },
    }),
    updatePersonContact: build.mutation<
      UpdatePersonResponse,
      {
        nickName: string
        addressData: AddressFormData
      }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        //const state = queryApi.getState() as RootReducerState
        //const { storeID } = state.site.currentSite!
        const { nickName, addressData } = args
        const result = await fetchWithBQ({
          url: `store/{storeId}/person/@self/contact/${nickName}`,
          method: 'PUT',
          body:
            {
              ...addressData,
            } || {},
        })

        if (result.error) {
          return { error: result.error as FetchBaseQueryError }
        }
        return { data: result.data }
      },
    }),
  }),
})

export const {
  useFindPersonBySelfQuery,
  useDeletePersonContactMutation,
  useUpdatePersonContactMutation,
} = accountApi
