import { styled } from '@mui/material/styles'
import { Swiper } from 'swiper/react'
import { ButtonAsLink, StyledAnchor } from '@components/UI'
import { Z_INDEX_LV2 } from '../../../../../../constants/ui'
import CmsTeaserDetailText from '@components/Cms/CmsComponents/CmsTeaserDetailText'
import CmsTeaserTitle from '@components/Cms/CmsComponents/CmsTeaserTitle'

export const WrapperMiniSlider = styled('div', {
  name: 'MiniSlider',
  slot: 'Wrapper',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(4),
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(10),
  [theme.breakpoints.up('sm')]: {
    marginTop: 0,
  },
}))

export const ContentCmsMedia = styled('div', {
  name: 'MiniSlider',
  slot: 'ContentCmsMedia',
})({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
})

export const ContentTitle = styled('div', {
  name: 'MiniSlider',
  slot: 'ContentTitle',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    width: '50%',
  },
}))

export const MiniSliderSwiper = styled(Swiper, {
  name: 'MiniSlider',
  slot: 'Swiper',
})(({ theme }) => ({
  maxWidth: '100vw',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  '.swiper-wrapper': {
    minWidth: '100%',
  },
  '.swiper-slide': {
    width: '90%',
    maxHeight: 700,
  },
  '.custom-progressbar': {
    width: '46%',
    height: '2px',
    position: 'relative',
    backgroundColor: theme.palette.color.grey.light,
    order: '1',
    span: {
      left: '0',
      position: 'absolute',
      width: '100%',
      height: '100%',
      transformOrigin: 'left',
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  '.swiper-scrollbar.swiper-scrollbar-horizontal': {
    position: 'relative',
    order: 1,
    borderRadius: 2,
    height: 2,
    width: 200,
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    background: theme.palette.color.grey.light,
    marginTop: theme.spacing(2),

    '.swiper-scrollbar-drag': {
      height: 2,
      background: theme.palette.primary.main,
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}))

export const CollectionTitleMiniSlider = styled(CmsTeaserTitle, {
  name: 'MiniSlider',
  slot: 'CollectionTitleMiniSlider',
})(({ theme }) => ({
  color: theme.palette.text.dark,
  textAlign: 'center',
  marginBottom: theme.spacing(2),
}))

export const TitleMiniSlider = styled(CmsTeaserTitle, {
  name: 'MiniSlider',
  slot: 'Title',
})(({ theme }) => ({
  fontSize: 24,
  [theme.breakpoints.up('md')]: {
    fontSize: 16,
  },
}))
export const TextMiniSlider = styled(CmsTeaserDetailText, {
  name: 'MiniSlider',
  slot: 'Text',
})(() => ({
  textAlign: 'center',
}))

export const StyledSlider = styled('div', {
  name: 'MiniSlider',
  slot: 'StyledSlider',
})({
  maxWidth: '100%',
})

export const StyledSlides = styled('div', {
  name: 'MiniSlider',
  slot: 'StyledSlides',
})({
  display: 'flex',
  overflowX: 'auto',
  scrollSnapType: 'x mandatory',
  scrollBehavior: 'smooth',
  '--webkit-overflow-scrolling': 'touch',
})

export const ContentCta = styled('div', {
  name: 'MiniSlider',
  slot: 'ContentCta',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(4),
  gap: theme.spacing(4),
  padding: theme.spacing(0, 4),
  boxSizing: 'border-box',

  [theme.breakpoints.up('md')]: {
    padding: 0,
    a: {
      width: 'auto',
    },
  },
}))

export const ContentCarousel = styled('div', {
  name: 'MiniSlider',
  slot: 'ContentCarousel',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: theme.spacing(4, 0),
  gap: theme.spacing(2),
  color: theme.palette.text.dark,
  wordBreak: 'break-word',
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(4, 0),
  },
}))

export const StyledButtonAsLink = styled(ButtonAsLink, {
  name: 'MiniSlider',
  slot: 'StyledButtonAsLink',
})(({ theme }) => ({
  fontSize: 16,
  textTransform: 'uppercase',
  fontWeight: 600,
  lineHeight: 1.33,
  color: theme.palette.text.dark,
  '&:after': {
    display: 'none',
  },
}))

export const ContentButtonNavigation = styled('div', {
  name: 'MiniSlider',
  slot: 'ContentButtonNavigation',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: Z_INDEX_LV2,
  minHeight: 40,
  padding: theme.spacing(2.5, 5),
  width: 80,
  borderTop: `2px solid ${theme.palette.text.dark}`,
  marginBottom: theme.spacing(14),
}))
export const MediaOverlapMiniSliderAnchor = styled(StyledAnchor, {
  name: ' MediaOverlapMiniSliderImageOverlap',
  slot: 'Anchor',
})(() => ({
  display: 'block',
  height: '100%',
  fontSize: 0,

  '&::after': {
    backgroundColor: 'transparent',
  },
  '& > span': {
    display: 'flex !important',
  },
}))
