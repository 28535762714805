import React from 'react'
import useBreakpoints from '../../../../../hooks/useBreakpoints'

import { TopPageMobileBanner } from './Mobile'
import { TopPageDesktopBanner } from './Desktop'
import { IPlacement } from '../../../../../types/cmsPlacement/Placement'
import { ICMCollection } from '../../../../../types/cmsPlacement/CMCollection'

export interface ITopPageBannerProps {
  placement: IPlacement | ICMCollection
  teaserIndex?: number
  bannerIndex?: number
}
const TopPageBanner: React.FC<ITopPageBannerProps> = ({
  placement,
  teaserIndex,
}) => {
  const { isMobile, isTabletPortrait } = useBreakpoints()

  return (
    <>
      {isMobile || isTabletPortrait ? (
        <TopPageMobileBanner
          placement={placement}
          teaserIndex={teaserIndex}
          bannerIndex={1}
        />
      ) : (
        <TopPageDesktopBanner
          placement={placement}
          teaserIndex={teaserIndex}
          bannerIndex={1}
        />
      )}
    </>
  )
}

export default TopPageBanner
