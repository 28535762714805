import { GetShipModes, ShippingMode } from '../types/order'
import { formatEstimatedDeliveryDate } from '@utils/getEstimatedDeliveryDate'
import { IXStoreConfig } from '../redux/reducers'
class GetShippingModesUtils {
  getShippingMethods = (
    shipModes: GetShipModes[],
    availableShippingMethods: IXStoreConfig['availableShippingMethods']
  ) => {
    const shippingMethods = shipModes?.at(0)?.dedicatedShippingMode || []
    const cloneShippingMethods = [...shippingMethods]

    return cloneShippingMethods?.sort((a, b) => {
      const aSequence = availableShippingMethods?.find(
        (item) => item.name === a.shipModeCode
      )?.sequence

      const bSequence = availableShippingMethods?.find(
        (item) => item.name === b.shipModeCode
      )?.sequence
      return Number(aSequence) - Number(bSequence)
    })
  }

  formatDeliveryDate = ({
    maxLeadTime,
    langCode,
  }: {
    maxLeadTime: number
    langCode: string
  }) => {
    const current = new Date()
    current.setDate(current.getDate() + maxLeadTime)

    return formatEstimatedDeliveryDate(
      current.toLocaleDateString(langCode) || '',
      langCode
    )
  }
  getEstimatedDeliveryDate = (shipMode: ShippingMode, langCode: string) => {
    return {
      shipModeCode: shipMode?.shipModeCode,
      estimatedDeliveryDate: this.formatDeliveryDate({
        maxLeadTime: Number(shipMode?.maxLeadTime),
        langCode,
      }),
    }
  }

  filterClOrClaGetShipModes = (getShipModesData: GetShipModes[]) => {
    const getShipModesProductType = [
      'Contact Lenses',
      'Contact Lenses Accessories',
    ]

    return getShipModesData.reduce((accumulator: GetShipModes[], current) => {
      //remove duplicate CL catendryId and filter for Cl and Cla
      if (
        !accumulator.find((item) => item.catentryId === current.catentryId) &&
        getShipModesProductType.includes(current.productType)
      ) {
        accumulator.push(current)
      }
      return accumulator
    }, [])
  }
}
export default new GetShippingModesUtils()
