import { styled, Theme } from '@mui/material/styles'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'

// This function is used to overwrite the styles of the mui/date-picker component,
// because at the moment there is no way to fully customize the component header.
export const getPopperSX = ({
  theme,
  showMonthController,
}: {
  theme: Theme
  showMonthController: boolean
}) => ({
  boxShadow: theme.shadows[1],

  ['.MuiPaper-root']: {
    ['& > div:first-of-type']: {
      width: 'fit-content',

      ['& > div:first-of-type']: {
        width: 'fit-content',
      },
    },

    '.MuiPickersCalendarHeader-label': {
      display: 'none',
    },
  },

  ['.MuiCalendarPicker']: {
    ['&-viewTransitionContainer']: {
      maxWidth: 226,

      ['.PrivatePickersSlideTransition-root']: {
        minHeight: 160,

        ['& div[role=row]']: {
          margin: 0,
        },
      },
    },

    ['&-root']: {
      maxWidth: `calc(226px + ${theme.spacing(8)})`,
      padding: theme.spacing(3, 4),

      ['& > div:first-of-type']: {
        padding: 0,
        margin: theme.spacing(0, 0, 3.5),
      },
    },
  },

  ['.MuiPickersDay-root']: {
    width: 32,
    height: 32,
    margin: 0,
  },

  ['.MuiPickersArrowSwitcher-spacer']: {
    display: 'none',
  },

  ['div[role=presentation]']: {
    order: 2,

    ['& > .PrivatePickersFadeTransitionGroup-root']: {
      display: 'none',
    },

    ['& + .MuiPickersArrowSwitcher-root']: {
      flex: 1,
      visibility: `${showMonthController ? 'visible' : 'hidden'} !important`,
      opacity: `${+showMonthController} !important`,
    },
  },

  ['& .Mui-selected']: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.text.light} !important`,
    fontSize: 14,
    fontWeight: 600,
  },

  ['.MuiYearPicker-root']: {
    margin: 0,
  },

  ['.MuiMonthPicker-root']: {
    width: 'fit-content',
  },

  ['.PrivatePickersMonth-root, .PrivatePickersYear-yearButton']: {
    height: 32,
    width: 56,
    margin: 0,

    ['&:hover, &:focus']: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.light,
    },
  },

  ['.PrivatePickersSlideTransition-root, .MuiCalendarPicker-viewTransitionContainer']:
    {
      ['&::-webkit-scrollbar']: {
        width: '2px',
      },

      ['&::-webkit-scrollbar-track']: {
        backgroundColor: theme.palette.color.grey.light,
      },

      ['&::-webkit-scrollbar-thumb']: {
        backgroundColor: theme.palette.primary.main,
      },
    },
})

export const StyledCurrentDate = styled('span')<{ type: 'month' | 'year' }>(
  ({ theme, type }) => ({
    margin: theme.spacing(0, type === 'month' ? 4 : 2),
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.dark,
  })
)

export const StyledDatePickerDays = styled(PickersDay)<{
  isDateSelected: boolean
}>(({ theme, isDateSelected = false }) => ({
  ['&.Mui-selected']: {
    backgroundColor: `${
      isDateSelected ? theme.palette.primary.dark : 'transparent'
    } !important`,
    fontWeight: isDateSelected ? 'bold' : 'normal',

    ['&:hover']: {
      backgroundColor: theme.palette.primary[isDateSelected ? 'main' : 'light'],
    },
  },

  ['&:focus']: {
    backgroundColor: 'transparent',
  },

  ['&:hover, &:focus-visible']: {
    backgroundColor: theme.palette.primary.light,
    fontWeight: 600,
  },

  ['&.MuiPickersDay-today']: {
    border: `1px solid ${theme.palette.text.dark}`,
    fontWeight: 600,
  },

  ['&, &.Mui-selected']: {
    color: theme.palette.text.dark,
    fontSize: 14,
  },
}))
