import { Attachment, Product, ProductSeo, ServerProduct } from './product'
import {
  ORDER_EXTEND_ATTRIBUTE_NAMES,
  ORDER_ITEM_EXTEND_ATTRIBUTE_NAMES,
} from '../constants/order'
import { RecordSetResponse } from '../services/RequestService'
import { UsableAddress } from './checkout'
import { CancelToken } from 'axios'
import { AddressFormData } from './form'
import { ShippingMethods } from './cart'
import { RxPrescriptionDetails } from './prescription'
import { OrderTotalSummaryProps } from '@components/OrderTotalSummary/OrderTotalSummary'

export type OrderAdjustment = {
  currency?: string
  value?: string
}

export enum OrderAdjustmentCode {
  COD_SURCHARGE = 'COD Surcharge',
  DISCOUNT = 'Discount',
  SHIPPING_ADJUSTMENT = 'Shipping Adjustment',
}

export type Adjustment = {
  amount: string
  code: string
  currency: string
  description: string
  descriptionLanguage: string
  displayLevel: string
  language: string
  usage: string
}

type OrderExtendAttributeName =
  (typeof ORDER_EXTEND_ATTRIBUTE_NAMES)[keyof typeof ORDER_EXTEND_ATTRIBUTE_NAMES]

type OrderItemExtendAttributeName =
  (typeof ORDER_ITEM_EXTEND_ATTRIBUTE_NAMES)[keyof typeof ORDER_ITEM_EXTEND_ATTRIBUTE_NAMES]

export type ShippingChargePolicy = {
  name: string
  type: string
  uniqueID: string
}

export type ShippingMode = {
  carrier?: string
  description: string
  language: string
  shipModeCode: ShippingMethods
  shipModeId: string
  maxLeadTime: string
  minLeadTime: string
  sequence: string
}

export type ShippingInfo = {
  orderId: string
  orderItem: {
    orderItemId: string
    usableShippingAddress: UsableAddress[]
    usableShippingChargePolicy: ShippingChargePolicy[]
    usableShippingMode: ShippingMode[]
    shipModeId?: string
  }[]
  resourceId: string
  resourceName: string
  usableShippingAddress: UsableAddress[]
}

export interface PaymentInstruction {
  addressLine: string[]
  billing_address_id: string
  city: string
  country: string
  email1: string
  email2: string
  fax1: string
  fax2: string
  firstName: string
  lastName: string
  middleName: string
  nickName: string
  payMethodId: string
  personTitle: string
  phone1: string
  phone1Publish: string
  phone2: string
  phone2Publish: string
  piAmount: string
  piCurrency: string
  piDescription: string
  piId: string
  piLanguage: string
  piStatus: string
  postalCode: string
  protocolData: { name: string; value?: string }[]
  state: string
  stateOrProvinceName: string
  xpaym_policyId: string
  xpaym_punchoutPayment: string
  xpaym_tokenization: string
  zipCode: string
}

export type Attribute<Name extends string> = {
  attributeName: Name
  attributeType: string
  attributeValue: string
}

export interface Order {
  adjustment: Adjustment[]
  buyerDistinguishedName: string
  buyerId: string
  buyerPONumber: string
  channel: {
    channelIdentifer: {
      channelName: string
      uniqueID: string
    }
    description: {
      language: string // e.g. "-1"
      value: string
    }
    userData: any
  }
  estimatedTaxes: string
  grandTotal: string
  grandTotalCurrency: string
  IS_RX: string // "true" / "false"
  lastUpdateDate: string
  locked: string // "true" / "false"
  orderExtendAttribute: Attribute<OrderExtendAttributeName>[]
  orderId: string
  orderStatus: string
  orderTypeCode?: string
  parsedOrderItems: ParsedOrderItemsMapByItemType
  placedDate?: string
  prepareIndicator: string // "true" / "false"
  recordSetComplete: string // "true" / "false"
  recordSetCount: string
  recordSetStartNumber: string
  recordSetTotal: string
  resourceId: string
  resourceName: string
  shipAsComplete: string // "true" / "false"
  storeNameIdentifier: string
  storeUniqueID: string
  totalAdjustment: string
  totalAdjustmentCurrency: string
  totalProductPrice: string
  totalProductPriceCurrency: string
  totalSalesTax: string
  totalSalesTaxCurrency: string
  totalShippingCharge: string
  totalShippingChargeCurrency: string
  totalShippingTax: string
  totalShippingTaxCurrency: string
  x_isPersonalAddressesAllowedForShipping: string // "true" / "false"
  x_isPurchaseOrderNumberRequired: string // "true" / "false"
  x_promoHasAdjustment: string // "true" / "false"
  x_trackingIds: string
  xinvoiceUrls: { [orderItemId: string]: string }
  x_orderItemsCount: string
  xtrackingUrls: { [orderItemId: string]: string }
}

export interface OrderItem {
  adjustment: Adjustment[]
  addressId: string[]
  addressLine?: string[]
  addressType?: AddressFormData['addressType']
  attachments: ServerProduct['attachments']
  attributes: ServerProduct['attributes']
  // added property to fix typescript problems caused by product model refactoring
  productAttributes: Product['productAttributes']
  carrier: string
  city?: string
  contractId: string
  correlationGroup: string
  country?: string
  createDate: string
  currency: string
  description: string
  email1: string
  email2: string
  expectedShipDate: string
  firstName?: string
  freeGift: string
  fulfillmentCenterId: string
  fulfillmentCenterName: string
  isExpedited: string
  language: string
  lastName?: string
  lastUpdateDate: string
  name: string
  offerID: string
  orderItemExtendAttribute: Attribute<OrderItemExtendAttributeName | string>[]
  orderItemFulfillmentStatus: string
  orderItemId: string
  groupedOrderItemsId?: string[]
  orderItemInventoryStatus: string
  orderItemPrice: string
  orderItemStatus: string
  parentCatalogGroupID: string | string[]
  phone1?: string
  partNumber: string
  productId: string
  quantity: string
  roxableAttributes?: RoxableAttributes
  roxableServices?: any[]
  prescriptionDetails?: {
    data?: RxPrescriptionDetails | null
    file?: {
      content?: any | null
      type?: string
    }
  }
  salesTax: string
  salesTaxCurrency: string
  seo: ProductSeo
  shipModeCode: string
  shipModeDescription: string
  shipModeId: string
  shipModeLanguage: string
  shippingCharge: string
  shippingChargeCurrency: string
  shippingTax: string
  shippingTaxCurrency: string
  sibilingOrderItem: OrderItem | null
  totalAdjustment: OrderAdjustment
  unitPrice: string
  unitQuantity: string
  unitUom: string
  UOM: string
  usableShippingChargePolicy: ShippingChargePolicy[]
  xitem_isPersonalAddressesAllowedForShipping: string
  xitem_display_catentry_id: string
  xitem_field1?: string
  xitem_field2?: string
  xitem_estimatedDeliveryDate?: string
  xitem_product_id: string
  x_offerpriceRx?: string
  x_offerDiscountpriceRx?: string
  x_priceBadge?: string
  zipCode?: string
  groupedItem?: boolean
  prescriptionUploaded?: boolean
  groupedOrderItemAttributes?: {
    attrName: string
    attrValue: string | OrderItem['orderItemExtendAttribute']
  }[]
  physicalStoreId: string
  physicalStoreExternalId: string
  stateOrProvinceName: string
}

export type OrderItemWithRoxProps = OrderItem & {
  roxableAttributes: RoxableAttributes
  roxableServices: OrderItem[]
}

export type RoxableAttributes = {
  correctionType: string
  lensBrand: string
  lensType: string
  lensTreatment: string
  lensThickness: string
  lensColor: string
  polarized: boolean
}

export type OrderItemsAttachments = Record<string, Attachment[]>

export interface IOrderDetails extends Order {
  detailedOrderItems: OrderItem[]
  orderComplete: boolean
  orderItem: OrderItem[]
  paymentInstruction: PaymentInstruction[]
  x_displayinvoice: boolean
  x_data: { productDetails: ServerProduct[] }
  prescriptionPeriodExpired?: boolean
  buyerPONumber: string
}

export type Promotion = {
  description: string
  promotionId: string
}

export type PromotionCode = {
  associatedPromotion: Promotion[]
  code: string
}

export interface Cart extends RecordSetResponse<Omit<Order, 'orderTypeCode'>> {
  orderItem: OrderItem[]
  paymentInstruction: PaymentInstruction[]
  prepareIndicator: string
  promotionCode?: PromotionCode[]
  resourceName: 'cart'
  x_promoHasAdjustment: string
}

export type AddItemActionPayload = {
  callback?(): void
  params: {
    cancelToken: CancelToken
    catentryId?: string | string[]
    contractId: string
    product?: ServerProduct
    langId: string
    lensId?: string
    partnumber: string | string[]
    quantity: string[]
    widget?: string
  }
}

export interface OrderRecapItemProps {
  orderItem: OrderItem
  order?: OrderTotalSummaryProps['order']
  onDelete: (item: OrderItem) => void
  readonly?: boolean
  onItemUpdated?: (quantity: string, orderItemId: string) => void
  onImageClicked?: () => void
}

export interface OrderRecapRxItemProps {
  orderItem: OrderItemWithRoxProps
  onDelete: (item: OrderItem) => void
  readonly?: boolean
  onItemUpdated?: (quantity: string, orderItemId: string) => void
  onImageClicked?: () => void
}

export interface ShippingChargesWithoutPromotions {
  freeDeliveryPromotion: boolean
  shipModeCharges: number
  shipModeId: string
  shipModeName: ShippingMethods
}

export type PrescriptionItemType = 'rx' | 'cl' | 'sun' | 'cl-acc' | 'default'
export type ParsedOrderItemsMapByItemType = Record<
  PrescriptionItemType,
  OrderItem[] | OrderItemWithRoxProps[] | null
> | null

export interface PrescriptionFileStatus {
  file: File | null
  error: any
  errorType: string | null
  isPristine?: boolean
}

export type GetShipModes = {
  dedicatedShippingMode: ShippingMode[]
  catentryId: string
  productType: string
  upc: string
}
export interface OrderExtAttributePayload {
  orderExtendAttribute: {
    attributeName: string
    attributeType: string
    attributeValue: string
  }[]
}
