import React from 'react'
import {
  StyledSuggestedProductAnchor,
  StyledSuggestedProductBrandName,
  StyledSuggestedProductName,
  StyledSuggestedProductTileDescription,
  StyledSuggestedProductTileFooter,
  StyledSuggestedProductTileFooterWrapper,
} from '@components/SuggestedProducts/components/SuggestedProductTile/SuggestedProductTile.style'
import ProductTileUtils from '@components/ProductTile/ProductTileUtilsOld'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import ProductPriceFinal from '@components/ProductPriceFinal'
import ProductPriceAlgoliaUtils from '@utils/ProductAlgolia/ProductPriceAlgoliaUtils'
import { useStoreIdentity } from '../../../../../../foundation/hooks/useStoreIdentity'
import { Product, SupplyData } from '../../../../../../types/product'

export interface ProductTileFooterProps {
  annualSupplyData?: SupplyData
  clusterSelected: Product
  metaData: { [key: string]: string }
}

const SuggestedProductTileFooter: React.FC<ProductTileFooterProps> = ({
  clusterSelected,
  metaData,
}) => {
  const { country, langCode } = useStoreIdentity()

  const { modelName, brand, lxSearchPageProductType } =
    ProductAlgoliaUtils.getProductAttributes(clusterSelected.productAttributes)

  const { isContactLenses } = ProductTileUtils.getLxProductTypes(
    lxSearchPageProductType
  )
  const productLinkTo =
    ProductTileUtils.getMocoLinkTo(clusterSelected!, langCode) || ''

  const productPrice = clusterSelected?.x_price

  return (
    <StyledSuggestedProductTileFooterWrapper>
      <StyledSuggestedProductAnchor {...metaData} to={productLinkTo}>
        <StyledSuggestedProductTileFooter>
          {!isContactLenses ? (
            <StyledSuggestedProductTileDescription>
              <StyledSuggestedProductName productType={lxSearchPageProductType}>
                {modelName ? modelName : '\u00A0'}
              </StyledSuggestedProductName>
              <StyledSuggestedProductBrandName>
                {brand || 'Brand name'}
              </StyledSuggestedProductBrandName>
              {clusterSelected?.x_price && (
                <ProductPriceFinal
                  pageType={'suggested'}
                  productType={lxSearchPageProductType}
                  price={
                    ProductPriceAlgoliaUtils.getDeterminatePrice(
                      clusterSelected?.x_price,
                      country
                    )!
                  }
                />
              )}
            </StyledSuggestedProductTileDescription>
          ) : (
            <StyledSuggestedProductTileDescription>
              <StyledSuggestedProductName productType={lxSearchPageProductType}>
                {modelName ? modelName : '\u00A0'}
              </StyledSuggestedProductName>
              {productPrice && (
                <ProductPriceFinal
                  pageType={'suggested'}
                  productType={lxSearchPageProductType}
                  price={
                    ProductPriceAlgoliaUtils.getDeterminatePrice(
                      productPrice,
                      country
                    )!
                  }
                />
              )}
            </StyledSuggestedProductTileDescription>
          )}
        </StyledSuggestedProductTileFooter>
      </StyledSuggestedProductAnchor>
    </StyledSuggestedProductTileFooterWrapper>
  )
}

export default SuggestedProductTileFooter
