import { StyledAnchor } from '../../../UI'

import { styled } from '@mui/material/styles'
import { Z_INDEX_LV1 } from '../../../../constants/ui'

export const StyledProductSubWrapper = styled('div', {
  name: 'StyledProduct',
  slot: 'SubWrapper',
  shouldForwardProp: (prop) => prop !== 'isBoxWMargin',
})<{ isBoxWMargin?: boolean }>(({ isBoxWMargin, theme }) => ({
  backgroundColor: isBoxWMargin
    ? 'transparent'
    : theme.palette.color.grey.lightest,
  position: 'relative',
  cursor: 'pointer',
  maxWidth: isBoxWMargin ? 416 : 'unset',
  margin: isBoxWMargin ? 'auto' : 'unset',
  '&::before': {
    content: isBoxWMargin ? 'none' : '""',
    paddingTop: '100%',
  },
}))

export const StyledProductAnchor = styled(StyledAnchor, {
  name: 'StyledProduct',
  slot: 'Anchor',
})(({ theme }) => ({
  backgroundColor: theme.palette.color.grey.lightest,
  width: '100%',
  justifyContent: 'center',
  '&::after': {
    height: '0 !important',
  },
}))

export const StyledSquare = styled('div', {
  name: 'StyledSquare',
})(({ theme }) => ({
  justifyContent: 'center',
  //paddingBottom: theme.spacing(5),
  [theme.breakpoints.up('sm')]: {
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: theme.spacing(4),
    //aspectRatio: '1 / 1',
  },
}))

export const StyledProductTileWrapper = styled('div', {
  name: 'StyledProductTile',
  slot: 'Wrapper',
})(({ theme }) => ({
  color: theme.palette.text.dark,
  fontSize: 12,
  fontWeight: 'normal',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '100%',
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
    '& > div': {
      backgroundColor: theme.palette.color.grey.lightest,
    },

    [theme.breakpoints.up('md')]: {
      fontSize: 14,
    },

    '&:hover': {
      '& > div': {
        visibility: 'visible',
      },
    },
  },
}))

export const StyledProductTileHeader = styled('div', {
  name: 'StyledProductTile',
  slot: 'Header',
})({
  zIndex: Z_INDEX_LV1,
  position: 'absolute',
  top: 0,
  left: '1rem',
  right: '1rem',
  flexDirection: 'column',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

export const CmsProductTilePrimaryBadge = styled('div', {
  name: 'CmsProductTile',
  slot: 'PrimaryBadge',
})(({ theme }) => ({
  display: 'flex',
  fontSize: 10,
  gap: theme.spacing(2),
  fontWeight: 600,
  lineHeight: 1.43,
  textTransform: 'uppercase',
  alignItems: 'center',

  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
    textAlign: 'center',
    marginTop: theme.spacing(0),
  },
}))

export const CmsProductTileSecondaryBadge = styled('div', {
  name: 'CmsProductTile',
  slot: 'SecondaryBadge',
})(({ theme }) => ({
  fontSize: 10,
  lineHeight: 1.6,

  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
    textAlign: 'center',
  },
}))

export const StyledProductTileBadgeIcons = styled('div', {
  name: 'StyledProductTile',
  slot: 'BadgeIcons',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& > svg:not(:last-child)': {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(0.156),
    },
    marginRight: 0,
  },

  svg: {
    color: theme.palette.color.black.main,
  },
}))

export const StyledProductTileImageContainer = styled('div', {
  name: 'StyledProductTile',
  slot: 'ImageContainer',
})(() => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
}))

export const StyledProductTileFooter = styled('div', {
  name: 'StyledProductTile',
  slot: 'Footer',
})(({ theme }) => ({
  color: theme.palette.text.dark,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'row',

  '& > div': {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    flexDirection: 'column',
  },

  '& > div:nth-of-type(2)': {
    justifyContent: 'center',
    flex: 0,

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
}))

export const StyledProductTileDescription = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isPDP',
  name: 'ProductTile',
  slot: 'Description',
})(() => ({
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  justifyContent: 'center',
}))

export const StyledProductName = styled('div', {
  name: 'StyledProductTile',
  slot: 'ProductName',
})(({ theme }) => ({
  fontSize: 9.8,
  wordBreak: 'break-all',
  whiteSpace: 'pre-line',
  marginBottom: theme.spacing(0.5),

  [theme.breakpoints.up('md')]: {
    fontSize: 10,
  },
}))

export const StyledProductBrandName = styled('div', {
  name: 'StyledProductTile',
  slot: 'BrandName',
})(({ theme }) => ({
  fontSize: 12,
  lineHeight: 1,
  fontWeight: 600,

  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
    lineHeight: 2,
  },
}))
