import { AnyAction, createSlice } from '@reduxjs/toolkit'
import { Store } from '../../types/Tab/store'
import { tabApi } from './query'

export interface StoresState {
  data?: Store[]
  isFetching: boolean
  error: Error | null
}

export const initialStoresState: StoresState = {
  data: [],
  isFetching: false,
  error: null,
}

const storesSlice = createSlice({
  name: 'stores',
  initialState: initialStoresState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(tabApi.endpoints.getBookingStores.matchPending, (state) => {
        state.isFetching = true
        state.error = null
      })
      .addMatcher(
        tabApi.endpoints.getBookingStores.matchFulfilled,
        (state, action) => {
          state.isFetching = false
          state.error = null
          state.data = action.payload
        }
      )
      .addMatcher(
        tabApi.endpoints.getBookingStores.matchRejected,
        (state, action: AnyAction) => {
          state.isFetching = false
          state.error = action.error
        }
      )
  },
})

export default storesSlice.reducer
