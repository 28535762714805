import {
  StyledLoadingWrapper,
  StyledProductSubWrapper,
  StyledProductTileWrapper,
  StyledSkeletonWrapper,
} from '../ProductTile.style'
import {
  StyledProductBrandName,
  StyledProductName,
  StyledProductTileDescription,
  StyledProductTileFooter,
} from '@components/ProductTile/components/ProductTileFooter/ProductTileFooter.style'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { CircularProgress } from '@mui/material'
import React from 'react'
import useBreakpoints from '../../../hooks/useBreakpoints'

export const ProductTileLoading = () => {
  const { isMobile } = useBreakpoints()

  return (
    <StyledProductTileWrapper>
      <StyledProductSubWrapper>
        <StyledLoadingWrapper>
          <CircularProgress size={isMobile ? 42 : 84} />
        </StyledLoadingWrapper>
      </StyledProductSubWrapper>
      <StyledProductTileFooter>
        <StyledProductTileDescription isPDP={false}>
          <SkeletonTheme
            baseColor="transparent"
            highlightColor={'rgba(0, 0, 0, 0.1)'}
          >
            <StyledSkeletonWrapper>
              <StyledProductName>
                <Skeleton
                  style={{
                    width: '20%',
                    borderRadius: 0,
                  }}
                />
              </StyledProductName>
              <StyledProductBrandName>
                <Skeleton
                  style={{
                    width: '30%',
                    borderRadius: 0,
                  }}
                />
              </StyledProductBrandName>
              <Skeleton
                style={{
                  width: '50%',
                  borderRadius: 0,
                }}
              />
            </StyledSkeletonWrapper>
          </SkeletonTheme>
        </StyledProductTileDescription>
      </StyledProductTileFooter>
    </StyledProductTileWrapper>
  )
}
