import React, { useState } from 'react'
import { ILXTeaser } from '../../../../../../types/cmsPlacement/LXTeaser'
import { CmsTermsAndConditionPanel } from '../../../../CmsComponents/CmsTermsAndConditions'
import { ToastProp } from '../../../../../UI/Toast/Toast.type'
import {
  LandscapeBannerTermsConditionButtonContainer,
  LandscapeBannerTermsConditionButton,
} from '../LandscapeBanner.style'

export const LandscapeBannerTermsAndCondition: React.FC<{
  item: ILXTeaser
}> = ({ item }) => {
  const [isTermsAndConditionOpen, setIsTermsAndConditionOpen] =
    useState<ToastProp['open']>(false)
  return (
    <>
      <LandscapeBannerTermsConditionButtonContainer>
        <LandscapeBannerTermsConditionButton
          teaser={item}
          onClick={() => setIsTermsAndConditionOpen(!isTermsAndConditionOpen)}
        />
      </LandscapeBannerTermsConditionButtonContainer>
      <CmsTermsAndConditionPanel
        teaser={item}
        open={isTermsAndConditionOpen}
        onClose={() => setIsTermsAndConditionOpen(!isTermsAndConditionOpen)}
      />
    </>
  )
}
