import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { trustPilotSelector } from '../../redux/selectors/site'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'

const TrustpilotWidgetMicroCombo: React.FC = () => {
  const { langCode } = useStoreIdentity()
  const getTrustPilot = useSelector(trustPilotSelector)!
  const widgetRef = useRef(null)

  const { trustpilotWidgetEnabled_Footer, trustpilotWidgetEnabled } =
    getTrustPilot

  if (!(trustpilotWidgetEnabled && trustpilotWidgetEnabled_Footer)) return null

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(widgetRef.current, true)
    }
  }, [])

  return (
    <div
      ref={widgetRef}
      className="trustpilot-widget"
      data-locale={langCode}
      data-template-id="5419b6ffb0d04a076446a9af"
      data-businessunit-id={'5b9761527647540001ad4ceb'}
      data-style-height="20px"
      data-style-width="100%"
      data-theme="dark"
      data-text-color="#FFFFFF"
    >
      <a
        href="https://it.trustpilot.com/review/www.grandvision.it"
        target="_blank"
        rel="noopener"
      >
        Trustpilot
      </a>
    </div>
  )
}

export default TrustpilotWidgetMicroCombo
