import { createApi } from '@reduxjs/toolkit/query/react'
import omit from 'lodash/omit'
import { PaginationResponse } from '../../services/RequestService'
import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'
import { RootReducerState } from '../../redux/reducers'
import { PRODUCT_LIST_FIELDS } from '@configs/catalog'
import {
  transformResponseProductList,
  transformResponseUpdateFacets,
} from './utils'
import { Product } from '../../types/product'
import { BREADCRUMB_PROFILE } from '../../constants/common'

export interface IPlpProductResponse {
  facets: IFacet[]
  productList: Product[]
  productListTotal: number
  priceMode: string
  breadcrumbs: any[]
  facetsAlwaysEmpty: string[]
  currentPLPFacets: IFacet[]
  selectedFacets: Record<
    string,
    { facetName: string; label: string; value: string }
  >
}

export interface IFacet {
  name: string
  entry: IEntry[]
  value: string
  extendedData: any
}

export interface IEntry {
  label: string
  value: string
  attributeValueIdentifier: string
  count: number | string
  extendedData: {
    sequence: string
    uniqueId: string
  }
}

export interface IPlpProductArgs {
  currency?: string
  _fields?: string
  offset?: number
  limit?: number
  profileName?: string
  categoryId?: string
  searchTerm?: string
  orderBy?: string
  facet?: string[]
  priceFrom?: number
  priceTo?: number
  plpStyle?: string
  discount?: string
}

// Used for `useGetUpdatedFacetQuery` hook
export interface GetUpdatedFacetQueryParams extends IPlpProductArgs {
  categoryIdentifier: string // matches `Category['identifier']`, example value: 'dc_contactlenses'
}

export interface IPlpBreadCrumb {
  label: string
  seo: {
    href: string
  }
  value: string
  categoryIdentifier: string
}

export interface BreadCrumbResponse {
  breadCrumbTrailEntryView: IPlpBreadCrumb[]
}
export const plpApi = createApi({
  reducerPath: 'plpApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/v2/',
  }),
  endpoints: (build) => ({
    getProduct: build.query<IPlpProductResponse, IPlpProductArgs>({
      queryFn: async (args, queryApi, _extraOptions, fetchWithBQ) => {
        const state = queryApi.getState() as RootReducerState
        const defaultCurrencyID = state.site.currentSite?.defaultCurrencyID!
        const params: IPlpProductArgs = {
          ...args,
          currency: defaultCurrencyID,
          _fields: PRODUCT_LIST_FIELDS,
        }

        const result = await fetchWithBQ({
          url: 'products',
          method: 'get',
          queryParams: params || {},
          //body: body || {},
          extraParams: { siteContextKey: 'search' },
        })

        return (result?.data as PaginationResponse)
          ? {
              data: transformResponseProductList(
                result.data,
                args
              ) as IPlpProductResponse,
            }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getUpdatedFacet: build.query<
      IPlpProductResponse,
      GetUpdatedFacetQueryParams
    >({
      queryFn: async (args, queryApi, _extraOptions, fetchWithBQ) => {
        const state = queryApi.getState() as RootReducerState
        const defaultCurrencyID = state.site.currentSite?.defaultCurrencyID!
        const plpFacet = state.site.currentSite?.xStoreCfg?.plpFacet!
        const plpPriceFacet = state.site.currentSite?.xStoreCfg?.plpPriceFacets!

        const params: IPlpProductArgs = {
          currency: defaultCurrencyID,
          _fields: PRODUCT_LIST_FIELDS,
          ...omit(args, 'categoryIdentifier'),
        }

        const result = await fetchWithBQ({
          url: 'products',
          method: 'get',
          queryParams: params,
          extraParams: { siteContextKey: 'search' },
        })

        return (result?.data as PaginationResponse)
          ? {
              data: transformResponseUpdateFacets(
                result.data,
                plpFacet,
                plpPriceFacet,
                args
              ) as IPlpProductResponse,
            }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getBreadCrumb: build.query<IPlpBreadCrumb[], IPlpProductArgs>({
      queryFn: async (args, queryApi, _extraOptions, fetchWithBQ) => {
        const params: IPlpProductArgs = {
          ...args,
          profileName: BREADCRUMB_PROFILE,
        }

        const result = await fetchWithBQ({
          url: 'products',
          method: 'get',
          queryParams: params || {},
          extraParams: { siteContextKey: 'search' },
        })

        return (result?.data as BreadCrumbResponse)
          ? {
              data: (result?.data?.breadCrumbTrailEntryView! ||
                []) as IPlpBreadCrumb[],
            }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
  }),
})

export const {
  useGetProductQuery,
  useGetUpdatedFacetQuery,
  useGetBreadCrumbQuery,
} = plpApi
