//TODO: this file must be renamed in productAttributes after product model porting is completed
import { get, pickBy } from 'lodash-es'
import { TFunction } from 'react-i18next'
import { PRODUCT_TYPES_MAP } from 'src/constants/product'
import { IBadges } from 'src/redux/reducers'
import { Product, ServerProduct } from 'src/types/product'
import {
  PRODUCT_ATTRIBUTE_BLUE_LIGHT_FILTER_VALUE,
  PRODUCT_ATTRIBUTE_CHILD_VALUE,
  PRODUCT_ATTRIBUTE_PHOTOCHROMIC_VALUE,
  ProductBadges,
} from '@utils/ProductAlgolia/ProductTileAlgoliaUtils'

export const PRODUCT_ATTRIBUTE_TRUE_VALUE = 'True' as const

type ProductType = Pick<Product, 'productAttributes' | 'clusters'>

export const PRODUCT_SIZES_NLS_BASE = 'ProductTile.Sizes.'

export const getServerProductAttribute = (
  p?: ServerProduct,
  key?: string,
  subKey?: string
) => {
  const attributes = p?.attributes || []
  let attribute = attributes.find((attr) => attr.identifier === key)
  if (!attribute) {
    const clusters = p?.cluster
    if (clusters && clusters.length) {
      const cluster = clusters[0]
      const clusterAttributes = cluster?.attributes || []
      attribute = clusterAttributes.find((attr) => attr.identifier === key)
    }
  }
  if (subKey) {
    const item = attribute?.values?.find((v) => v.identifier === subKey)
    return item ? item.value : ''
  } else {
    return get(attribute, 'values[0].value', '')
  }
}

export const getNormalizedProductTypeFromServerProduct = (
  p?: ServerProduct
) => {
  const pt: string = getServerProductAttribute(p, 'PRODUCT_TYPE')
  return pt && PRODUCT_TYPES_MAP[pt.toLowerCase()]
}

const getPriorityBadgeText = (badges: ProductBadges, t: TFunction): string => {
  switch (true) {
    case badges.isAvantPremiere:
      return t('ProductTile.Labels.avantPremiere')
    case badges.isLimitedEdition:
      return t('ProductTile.Labels.limitedEdition')
    case badges.isExclusive:
      return t('ProductTile.Labels.exclusive')
    case badges.isOnlineExclusive:
    case badges.clIsExclusive:
      return t('ProductTile.Labels.onlineExclusive')
    case badges.isNew:
    case badges.clIsNewArrival:
      return t('ProductTile.Labels.new')
    case badges.isMostPopular:
      return t('ProductTile.Labels.mostPopular')
    default:
      return ''
  }
}

export const getBadges = (
  product: Pick<Product, 'productAttributes'>,
  t: TFunction,
  plpBadges: IBadges = {} as IBadges,
  isRXEnabled = false
): {
  // `badges: IBadges` can also be exported in case of need
  primaryBadge: string
  secondaryBadges: string
} => {
  const getAttribute = (attribute: string) => {
    return product?.productAttributes[attribute]
  }
  const controlBadge = (plpBadge, attribute: string) => {
    return (
      !!plpBadge &&
      !!getAttribute(attribute) &&
      plpBadge.toLowerCase() === getAttribute(attribute).toLowerCase()
    )
  }

  const badges: ProductBadges = {
    isBadge: controlBadge(plpBadges.BADGE, 'BADGE'),
    isNew: controlBadge(plpBadges.IS_NEW, 'IS_NEW'),
    clIsNewArrival: controlBadge(
      plpBadges.CL_IS_NEWARRIVAL,
      'CL_IS_NEWARRIVAL'
    ),
    isLimitedEdition: controlBadge(
      plpBadges.LIMITED_EDITION,
      'LIMITED_EDITION'
    ),
    isOnlineExclusive: controlBadge(
      plpBadges['WEB EXCLUSIVE'],
      'WEB EXCLUSIVE'
    ),
    isExclusive: controlBadge(plpBadges.EXCLUSIVE, 'EXCLUSIVE'),
    clIsExclusive: controlBadge(plpBadges.CL_IS_EXCLUSIVE, 'CL_IS_EXCLUSIVE'),
    isAvantPremiere: controlBadge(plpBadges.AVANT_PREMIERE, 'AVANT_PREMIERE'),
    isRoxable: controlBadge(plpBadges.ROXABLE, 'ROXABLE') && isRXEnabled,
    isCustomizable: controlBadge(plpBadges.CUSTOMIZABLE, 'CUSTOMIZABLE'),
    isPolarized: controlBadge(plpBadges.POLARIZED, 'POLARIZED'),
    isMostPopular: controlBadge(
      plpBadges.LX_IS_MOST_POPULAR_TRUE,
      'LX_IS_MOST_POPULAR'
    ),
    isSustainable: controlBadge(
      plpBadges.SUSTAINABILITY_CLAIM,
      'SUSTAINABILITY_CLAIM'
    ),
    isKids:
      getAttribute('GENDER')?.toUpperCase() === PRODUCT_ATTRIBUTE_CHILD_VALUE,
    isPhotochromic: PRODUCT_ATTRIBUTE_PHOTOCHROMIC_VALUE.includes(
      getAttribute('LENS_TREATMENT')?.toUpperCase()
    ),
    /*isTransitions:
      getAttribute('LENS_TREATMENT')?.toUpperCase() ===
      PRODUCT_ATTRIBUTE_TRANSITION_VALUE,*/
    isBlueLightFilter:
      getAttribute('LENS_TREATMENT')?.toUpperCase() ===
      PRODUCT_ATTRIBUTE_BLUE_LIGHT_FILTER_VALUE,
  }

  const primaryBadge = getPriorityBadgeText(badges, t)

  //const currentLensTreatment = getAttribute('LENS_TREATMENT_FACET')
  const activeBadges = Object.keys(
    pickBy(
      {
        Polarised: badges.isPolarized,
        Sustainable: badges.isSustainable,
        Photochromic: badges.isPhotochromic,
        /*isLensTreatment:
          currentLensTreatment === 'Photochromic' ||
          currentLensTreatment === 'Transitions®',*/
      },
      (cb) => cb
    )
  )

  const secondaryBadges = (
    activeBadges.length > 2 ? activeBadges.slice(0, 2) : activeBadges
  )
    /*  .map((el) =>
      t(
        `ProductTile.Labels.${el === 'isLensTreatment' ? 'Light-Adaptive' : el}`
      )
    )*/
    .join(' | ')

  // `badges` can also be exported in case of need
  return { primaryBadge, secondaryBadges }
}

//GETTERS

export const getIsRoxable = (p: ProductType) => {
  return p?.productAttributes
    ? p?.productAttributes['ROXABLE'] === PRODUCT_ATTRIBUTE_TRUE_VALUE
    : false
}

export const getSize = (
  p: ProductType,
  t?: TFunction<'translation'>
): string => {
  const frameSizeDisplay = p?.productAttributes['FRAME_SIZE_DISPLAY']
  const productSizeNls = PRODUCT_SIZES_NLS_BASE + frameSizeDisplay

  return t && productSizeNls ? t(productSizeNls) : frameSizeDisplay
}
