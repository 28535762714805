import React from 'react'
import {
  StyledProductBrandName,
  StyledProductDescriptionAnchor,
  StyledProductName,
  StyledProductTileDescription,
  StyledProductTileFooter,
  StyledProductTileFooterWrapper,
} from '@components/ProductTile/components/ProductTileFooter/ProductTileFooter.style'
import { SupplyData } from '../../../../types/product'
import { ProductAlgolia } from '../../../../types/ProductAlgolia/ProductAlgolia'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import ProductPriceFinal from '@components/ProductPriceFinal'

export interface ProductTileFooterProps {
  annualSupplyData?: SupplyData
  clusterSelected: ProductAlgolia
  metaData: { [key: string]: string }
  isCompact: boolean
}

const ProductTileFooter: React.FC<ProductTileFooterProps> = ({
  clusterSelected,
  metaData,
}) => {
  const { langCode } = useStoreIdentity()
  const { getLxProductTypes, getProductAttributes } = ProductAlgoliaUtils
  const productPrice = clusterSelected?.prices
  const productLinkTo = `/${langCode}${clusterSelected.url || ''}`
  const attributes = clusterSelected?.attributes_translated

  const { modelName, brand, lxSearchPageProductType } =
    getProductAttributes(attributes)

  const { isFrames, isContactLensesAccessories, isContactLenses } =
    getLxProductTypes(lxSearchPageProductType)

  return (
    <StyledProductTileFooterWrapper>
      <StyledProductTileFooter>
        <StyledProductTileDescription>
          <StyledProductDescriptionAnchor
            to={productLinkTo}
            isClRelatedTile={!isFrames}
            {...metaData}
          >
            <StyledProductName
              type={lxSearchPageProductType}
              aria-describedby={modelName ? modelName : ''}
            >
              {modelName ? modelName : '\u00A0'}
            </StyledProductName>
            <StyledProductBrandName>
              {!(isContactLenses || isContactLensesAccessories) ? (
                <StyledProductBrandName>
                  {brand || 'Brand name'}
                </StyledProductBrandName>
              ) : null}
            </StyledProductBrandName>
            {productPrice ? (
              <ProductPriceFinal
                pageType={'plp'}
                productType={lxSearchPageProductType}
                price={productPrice}
              />
            ) : null}
          </StyledProductDescriptionAnchor>
        </StyledProductTileDescription>
      </StyledProductTileFooter>
    </StyledProductTileFooterWrapper>
  )
}

export default ProductTileFooter
