import { useTranslation } from 'react-i18next'

export const useShippingLabel = () => {
  const { i18n, t } = useTranslation()

  const getShippingCompleteLabel = (shipModeCode: string) => {
    if (shipModeCode === 'Standard') {
      return shipModeCode
    }
    const keyTranslate = `CartShippingMethodSelection.Shipping.${shipModeCode}`

    if (i18n.exists(keyTranslate)) {
      return t(keyTranslate)
    }
    return t('Order.Shipping') + ' ' + shipModeCode
  }

  const getShippingLabel = (shipModeCode: string) => {
    if (shipModeCode === 'Standard') {
      return shipModeCode
    }

    const keyTranslate = `CartShippingMethodSelection.Labels.${shipModeCode}`

    if (i18n.exists(keyTranslate)) {
      return t(keyTranslate)
    }
    return shipModeCode
  }

  return { getShippingCompleteLabel, getShippingLabel }
}
