import { styled } from '@mui/material/styles'
import { StyledAnchor } from '@components/UI'
import { CheckedRadioWithBorderIcon } from '@components/UI/Icons'
import { Button } from '@components/UI'

export const AccountStoresBodyWrapper = styled('div', {
  name: 'AccountStores',
  slot: 'BodyWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(6),
  marginTop: theme.spacing(4),
  minHeight: '100%',

  paddingTop: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingBottom: theme.spacing(8),

  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(4),
    paddingLeft: 67,
    paddingRight: theme.spacing(16),
    paddingBottom: 65,
  },
}))

export const AccountStoresTitle = styled('div', {
  name: 'AccountStores',
  slot: 'Title',
})(({ theme }) => ({
  fontSize: 20,
  fontWeight: 600,
  lineHeight: 1.4,
  color: theme.palette.text.dark,
}))

export const AccountStoresList = styled('div', {
  name: 'AccountStores',
  slot: 'List',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  // alignItems: 'center',
  rowGap: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    alignItems: 'flex-start',
  },
}))

export const AccountStoresCard = styled('div', {
  name: 'AccountStores',
  slot: 'Card',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  border: `1px solid ${theme.palette.color.grey.light}`,
  backgroundColor: theme.palette.color.white.main,
  padding: theme.spacing(4),
  paddingBottom: theme.spacing(6),
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5.5, 6, 8, 6),
    alignItems: 'flex-start',
  },
}))

export const AccountStoresCardHeader = styled('div', {
  name: 'AccountStores',
  slot: 'CardHeader',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

export const AccountStoresCardName = styled('div', {
  name: 'AccountStores',
  slot: 'CardName',
})(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  lineHeight: 1.5,
  color: theme.palette.text.dark,
  marginBottom: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    marginBottom: 'unset',
  },
}))

export const AccounStoresCardContent = styled('div', {
  name: 'AccountStores',
  slot: 'CardContent',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'flex-start',
    justifyContent: 'start',
  },
}))

export const MapContainer = styled('div', {
  name: 'AccountStores',
  slot: 'MapContainer',
})(({ theme }) => ({
  height: '234px',
  position: 'relative',
  marginBottom: theme.spacing(4),
  overflow: 'hidden',

  [theme.breakpoints.up('sm')]: {
    height: 'unset',
    marginRight: theme.spacing(8),
    marginBottom: 'unset',
    width: '40%',
  },

  [theme.breakpoints.up('md')]: {
    width: '50%',
  },
  [theme.breakpoints.up('lg')]: {
    width: 'auto',
  },
}))

export const MapImage = styled('img', {
  name: 'AccountStores',
  slot: 'MapImage',
})(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: `1px solid ${theme.palette.color.grey.main}`,

  [theme.breakpoints.up('lg')]: {
    width: '100%',
    height: '100%',
    position: 'relative',
    top: 0,
    left: 0,
    transform: 'unset',
  },
}))

export const AccountStoreInfo = styled('div', {
  name: 'AccountStores',
  slot: 'StoreInfo',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    marginRight: theme.spacing(4),
    width: '60%',
  },
  [theme.breakpoints.up('md')]: {
    width: '50%',
  },
  [theme.breakpoints.up('lg')]: {
    width: 'auto',
    flexGrow: '1',
    maxWidth: '392px',
  },
}))

export const AccountStoreAddress = styled('div', {
  name: 'AccountStores',
  slot: 'Address',
})(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.text.dark,
}))

export const LinksContainer = styled('div', {
  name: 'AccountStores',
  slot: 'LinksContainer',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: theme.spacing(3.5, 0, 0),

  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(2, 0, 0),
  },

  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(4, 0, 0),
  },
}))

export const LinkStoreInfo = styled(StyledAnchor, {
  name: 'AccountStores',
  slot: 'Link',
})(({ theme }) => ({
  color: theme.palette.text.dark,
  fontWeight: 600,
  '&::after': {
    background: theme.palette.text.dark,
  },
  '&:hover': {
    color: theme.palette.text.dark,
  },
}))

export const StyledDivider = styled('div', {
  name: 'AccountStores',
  slot: 'Divider',
})(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  width: '100%',
  height: 1,
  backgroundColor: theme.palette.color.grey.light,

  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(3.75),
    marginBottom: theme.spacing(4),
  },
}))

export const StoreHoursTitle = styled('div', {
  name: 'AccountStores',
  slot: 'StoreHoursTitle',
})(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  lineHeight: 1.33,
  color: theme.palette.text.dark,
  textTransform: 'uppercase',
  marginBottom: theme.spacing(1.5),
}))

export const BookEyeTestButton = styled(Button, {
  name: 'AccountStores',
  slot: 'BookEyeTestButton',
})(({ theme }) => ({
  padding: theme.spacing(0, 4),
  margin: theme.spacing(4, 'auto', 0, 'auto'),
  width: 'auto',
  [theme.breakpoints.up('md')]: {
    width: 220,
    margin: theme.spacing(2, 0, 0, 0),
  },
}))

export const StyledCheckmarkIcon = styled(CheckedRadioWithBorderIcon, {
  name: 'AccountStores',
  slot: 'StyledCheckmarkIcon',
})(({ theme }) => ({
  height: theme.spacing(5),
  width: theme.spacing(5),
}))

export const DefaultStoreText = styled('div', {
  name: 'AccountStores',
  slot: 'DefaultStoreText',
})(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  color: theme.palette.text.dark,
  textTransform: 'uppercase',
  marginLeft: theme.spacing(4),
}))

export const ToggleDefaultStore = styled('div', {
  name: 'AccountStores',
  slot: 'Toggle',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
}))

export const AccountStoresCheckboxContainer = styled('span', {
  name: 'AccountStores',
  slot: 'CheckboxContainer',
})(({ theme }) => ({
  span: {
    borderColor: theme.palette.grey[400],
  },
}))

export const StyledButton = styled(Button, {
  name: 'AccountStores',
  slot: 'RemoveButton',
})(({ theme }) => ({
  maxWidth: 110,
  [theme.breakpoints.up('sm')]: {
    width: 110,
  },
}))

export const AccountStoresMarkerIconContainer = styled('div', {
  name: 'AccountStores',
  slot: 'MarkerIconContainer',
})(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
}))

export const StoreOpeningHoursWrapper = styled('div', {
  name: 'AccountStores',
  slot: 'StoreOpeningHoursWrapper',
})(({ theme }) => ({
  paddingBottom: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    paddingBottom: theme.spacing(6.75),
  },
}))
