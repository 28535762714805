import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'

type SocialData = {
  firstName: string
  lastName: string
  email: string
  dob: string
  error: string
}

export type LoginSocialContextType = {
  socialData: SocialData | null
  setSocialData: React.Dispatch<React.SetStateAction<SocialData | null>>
}

const _throw = (m: string) => {
  throw new Error(m)
}

const SocialLoginContext = createContext<LoginSocialContextType | undefined>(
  undefined
)

const SocialLoginProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [socialData, setSocialData] = useState<SocialData | null>(null)

  return (
    <SocialLoginContext.Provider
      value={{
        socialData,
        setSocialData,
      }}
    >
      {children}
    </SocialLoginContext.Provider>
  )
}

const useSocialLogin = () => {
  const context = useContext(SocialLoginContext)

  return context || _throw('Init SocialLoginContext first.')
}

export default SocialLoginProvider

export { useSocialLogin }
