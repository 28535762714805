import React from 'react'
import { ITeaserOverlayTextAlign } from '../../../../../../types/cmsPlacement/LXTeaser'
import { replaceTextMediaCmsUrl } from '../../../../../../utils/placements'
import {
  ContentTextModule,
  TextTextModule,
  TitleTextModule,
  WrapperTextModule,
} from '../TextModule.style'
import { styled } from '@mui/material/styles'
import { ICMArticle } from '../../../../../../types/cmsPlacement/CMArticle'

const TitleArticle = styled(TitleTextModule)<{
  textAlign: ITeaserOverlayTextAlign
}>(({ textAlign }) => ({
  width: '100%',
  textAlign,
}))

const DetailTextArticle = styled(TextTextModule)(() => ({
  width: '100%',
  textAlign: 'left',
}))

const TextModuleArticle: React.FC<{ item: ICMArticle }> = ({ item }) => {
  if (!item) return null

  const title = item.title || ''
  const detailText = replaceTextMediaCmsUrl(item.detailText || '')
  const articleTitleAlign = item.articleTitleAlign || 'center'

  return (
    <WrapperTextModule>
      <ContentTextModule>
        {title && (
          <TitleArticle
            data-cm-metadata={`[{"_":"properties.${title}"}]`}
            textAlign={articleTitleAlign}
          >
            {title}
          </TitleArticle>
        )}
        {detailText && (
          <DetailTextArticle
            data-cm-metadata={`[{"_":"properties.${detailText}"}]`}
            dangerouslySetInnerHTML={{ __html: detailText }}
          />
        )}
      </ContentTextModule>
    </WrapperTextModule>
  )
}

export default TextModuleArticle
