import { ProductAlgoliaPrice } from '../../types/ProductAlgolia/ProductAlgolia'
import { DASH_CHARACTER, PERCENTAGE_CHARACTER } from '../../constants/ui'
import config from '@configs/index'
import Cookies from 'js-cookie'

class ProductPriceAlgoliaUtils {
  getDeterminatePrice(
    prices: { [key: string]: ProductAlgoliaPrice },
    country: string
  ): ProductAlgoliaPrice | undefined {
    if (!prices) return undefined

    const pricesArray = Object.values(prices)
    if (!pricesArray.length) return undefined

    const {
      algolia: { guestSegment, basePriceListSufix },
      storePrefix,
    } = config

    const priceListKey: string = `${storePrefix.toUpperCase()}_${country.toUpperCase()}${basePriceListSufix}`
    const backupPrice: ProductAlgoliaPrice | undefined = prices[priceListKey]
    let validPrice: ProductAlgoliaPrice | undefined
    const customerSegments: string[] = Cookies.get('userg')?.split('|') || []

    customerSegments.forEach((segment) => {
      if (!validPrice) {
        const currentSegmentPrice = pricesArray.find(
          (price) => price !== backupPrice && price.segment === segment
        )
        validPrice = currentSegmentPrice
          ? this.checkForValidPrice(currentSegmentPrice)
          : undefined
      }
    })

    const guestSegmentPrice: ProductAlgoliaPrice | undefined = pricesArray.find(
      (price) => price !== backupPrice && price.segment === guestSegment
    )

    const validGuestPrice: ProductAlgoliaPrice | undefined = guestSegmentPrice
      ? this.checkForValidPrice(guestSegmentPrice)
      : undefined

    return validPrice
      ? validPrice
      : validGuestPrice
      ? validGuestPrice
      : this.checkForValidPrice(backupPrice)
  }

  formatDate = (date: string): Date => {
    const date1 = date
      .replace(/-/g, '/')
      .slice(0, -6)
      .replace(/\..{4}/, '')

    return new Date(date1)
  }

  sortByPrecedenceAndOfferPrice = (
    a: ProductAlgoliaPrice,
    b: ProductAlgoliaPrice
  ) => {
    if (a.precedence === b.precedence) {
      return a.offerPrice - b.offerPrice
    }
    return b.precedence - a.precedence
  }

  sortByPriceListPrecedenceAndOfferPrice = (
    a: ProductAlgoliaPrice | undefined,
    b: ProductAlgoliaPrice | undefined
  ) => {
    if (!a || !b) {
      return 0
    }

    if (a.priceListPrecedence === b.priceListPrecedence) {
      if (a.precedence === b.precedence) {
        return a.offerPrice - b.offerPrice
      }
      return b.precedence - a.precedence
    }

    return b.priceListPrecedence - a.priceListPrecedence
  }
  isDateValid = (start: string, end: string): boolean => {
    const currentDate = new Date()
    const startDate = this.formatDate(start)
    const endDate = this.formatDate(end)

    if (endDate < startDate) {
      endDate.setFullYear(endDate.getFullYear() + 100)
    }
    return currentDate > startDate && currentDate < endDate
  }

  checkForValidPrice = (
    price: ProductAlgoliaPrice
  ): ProductAlgoliaPrice | undefined => {
    if (!price) {
      return undefined
    }
    const { startDate, endDate, futurePrices, offerPrice } = price
    if (this.isDateValid(startDate, endDate) && offerPrice > 0) {
      return price
    } else {
      if (futurePrices && futurePrices.length) {
        return futurePrices
          .filter(
            ({ startDate, endDate, offerPrice }) =>
              this.isDateValid(startDate, endDate) && offerPrice > 0
          )
          .sort(this.sortByPrecedenceAndOfferPrice)[0]
      } else {
        return undefined
      }
    }
  }

  getClAndClaTotalPrice = (price: number, quantity: number) => {
    return price * (quantity || 0)
  }

  getProductPriceData = (price: ProductAlgoliaPrice) => {
    const listPrice = Number(price?.listPrice) || 0
    const offerPrice = Number(price?.offerPrice) || 0
    const currency = price?.currency || ''
    const percentageDiscount = Number(price?.percentageDiscount) || 0
    const badgeDiscount = price?.badge
      ? DASH_CHARACTER + price.badge
      : DASH_CHARACTER + Math.round(percentageDiscount) + PERCENTAGE_CHARACTER

    const hasInitialPriceDiscount = Boolean(
      percentageDiscount && listPrice && listPrice > 0
    )

    return {
      listPrice,
      offerPrice,
      currency,
      percentageDiscount,
      badgeDiscount,
      hasInitialPriceDiscount,
    }
  }
}

export default new ProductPriceAlgoliaUtils()
