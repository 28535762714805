import React from 'react'
import { css, styled, Theme } from '@mui/material/styles'
import { PopperUnstyled } from '@mui/base'
import { PopperProps, Tooltip, tooltipClasses } from '@mui/material'

import type { CustomTooltipProps, ColorVariant } from './Tooltip.type'

type StyledPopperProps = PopperProps & { colorVariant: ColorVariant }

type ColorVariantMapped = {
  background: string
  text: string
}

const mapColorVariantPopper = (
  theme: Theme,
  variant: ColorVariant
): ColorVariantMapped => {
  const themeStyles: {
    [K in ColorVariant]: ColorVariantMapped
  } = {
    lightgray: {
      background: theme.palette.primary.light,
      text: theme.palette.text.dark,
    },
    darkgray: {
      background: theme.palette.primary.main,
      text: theme.palette.text.light,
    },
    darkestgray: {
      background: theme.palette.color.grey.dark,
      text: theme.palette.text.light,
    },
    dark: {
      background: theme.palette.primary.main,
      text: theme.palette.text.light,
    },
    primary: {
      background: theme.palette.primary.light,
      text: theme.palette.text.dark,
    },
  }
  return themeStyles[variant]
}

export const PopperStyle = ({ theme, colorVariant }) =>
  css({
    [`.${tooltipClasses.tooltip}`]: {
      maxWidth: '16.5rem',
      backgroundColor: mapColorVariantPopper(theme, colorVariant).background,
      color: mapColorVariantPopper(theme, colorVariant).text,
      fontSize: '0.75rem',
      lineHeight: 1.33,
      fontFamily: 'Poppins',
      fontWeight: 'normal',
      padding: theme.spacing(2, 4),
      boxShadow: '15px 15px 32px 0px rgba(0, 0, 0, 0.15)',
      '.popover-body-container': {
        '&__title': {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          fontWeight: 600,
          margin: 0,
        },
        '&__body': {
          margin: 0,
          '&--spacing-up': {
            margin: theme.spacing(1, 0, 0, 0),
          },
        },
        '&--footer-container': {
          marginTop: theme.spacing(4),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontWeight: 600,
          ' &__cta > a': {
            color: mapColorVariantPopper(theme, colorVariant).text,
            textDecoration: 'underline',
          },
        },
      },
    },
    [`.${tooltipClasses.arrow}`]: {
      color: mapColorVariantPopper(theme, colorVariant).background,
    },
  })

const PopperWithStyle = styled(PopperUnstyled)<StyledPopperProps>(PopperStyle)

export const TooltipWithStyle = ({
  colorVariant,
  ...props
}: Omit<CustomTooltipProps, 'contentVariant'>) => (
  <Tooltip
    PopperComponent={(props) => (
      <PopperWithStyle colorVariant={colorVariant} {...props} />
    )}
    arrow
    {...props}
  />
)
