import React from 'react'
import {
  OrderDetailsCLInfoContainer,
  OrderDetailsProductInfoItem,
  OrderDetailsProductInfoListItem,
  OrderDetailsProductInfoRow,
} from './OrderDetails.style'

import { useTranslation } from 'react-i18next'
import { OrderRecapClItemLensDetailsContent } from '@components/order-recap/OrderRecapClItemLensDetailsContent'
import { useContactLensCartItemData } from '@hooks/useContactLensCartItemData/useContactLensCartItemData'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { OrderRecapClItemLensDetailsContentMobile } from '@components/order-recap/OrderRecapClItemLensDetailsContentMobile'
import { getContactLensesParams, getQuantity } from '@utils/order'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import { Divider } from '@mui/material'
import { OrderItem } from '../../../types/order'

interface OrderInfoProps {
  //product?: any
  orderItem: OrderItem
  //orderDetails?: IOrderDetails
}
export const OrderInfo: React.FC<OrderInfoProps> = ({
  //product,
  orderItem,
  //orderDetails,
}) => {
  const { t } = useTranslation()
  const { isDesktop } = useBreakpoints()

  const rxServices = orderItem?.roxableServices
  /*const rxLens = rxServices?.find((x) => isRxLens(x.orderItemExtendAttribute))
  const LENS_OBJECT = orderDetails?.x_data?.productDetails?.find(
    ({ id }) => id === rxLens?.productId
  )*/

  const productAttributes = orderItem?.productAttributes

  const {
    lxSearchPageProductType,
    lensColor,
    modelName,
    correctionType,
    lensThickness,
    antiReflective,
    antiBlue,
    lensTreatment,
  } = ProductAlgoliaUtils.getProductAttributes(productAttributes!)

  const { isAccessories, isContactLensesAccessories, isContactLenses } =
    ProductAlgoliaUtils.getLxProductTypes(lxSearchPageProductType)

  const rxLensAttributesArray: string[] = [
    correctionType,
    modelName,
    lensThickness,
    antiReflective,
    lensTreatment,
    antiBlue,
  ]

  const nonRxLensAttributesArray: string[] = [lensColor]

  const FrameInfo = () => (
    <>
      <OrderDetailsProductInfoRow>
        <OrderDetailsProductInfoListItem>
          {t('CartRecap.Labels.Frame')}:
        </OrderDetailsProductInfoListItem>
        <OrderDetailsProductInfoItem>{modelName}</OrderDetailsProductInfoItem>
      </OrderDetailsProductInfoRow>
      <Divider style={{ width: '100%' }} />
      <OrderDetailsProductInfoRow>
        <OrderDetailsProductInfoListItem>
          {t('CartRecap.Labels.Lenses')}:
        </OrderDetailsProductInfoListItem>
        {rxServices ? (
          <OrderDetailsProductInfoItem>
            {rxLensAttributesArray.map((a) => (
              <span>{a}</span>
            ))}
          </OrderDetailsProductInfoItem>
        ) : (
          <OrderDetailsProductInfoItem>
            {nonRxLensAttributesArray.map((a) => (
              <span>{a}</span>
            ))}
          </OrderDetailsProductInfoItem>
        )}
      </OrderDetailsProductInfoRow>
    </>
  )

  const ContactLensesInfo = () => {
    const lensesInfo = getContactLensesParams(orderItem)
    if (!lensesInfo) return null

    const { orderItemClData } = useContactLensCartItemData({
      orderItem: orderItem,
    })

    return (
      <>
        <OrderDetailsProductInfoRow>
          <OrderDetailsProductInfoListItem isClProduct={isContactLenses}>
            {modelName}
          </OrderDetailsProductInfoListItem>
        </OrderDetailsProductInfoRow>
        <Divider style={{ width: '100%' }} />
        <OrderDetailsProductInfoRow>
          {orderItemClData && (
            <OrderDetailsCLInfoContainer>
              {isDesktop ? (
                <OrderRecapClItemLensDetailsContent
                  orderItemClData={orderItemClData}
                  showBoxesNumber
                />
              ) : (
                <OrderRecapClItemLensDetailsContentMobile
                  orderItemClData={orderItemClData}
                />
              )}
            </OrderDetailsCLInfoContainer>
          )}
        </OrderDetailsProductInfoRow>
      </>
    )
  }

  const AccessoriesInfo = () => (
    <>
      <OrderDetailsProductInfoRow>
        <OrderDetailsProductInfoListItem>
          {modelName}
        </OrderDetailsProductInfoListItem>
      </OrderDetailsProductInfoRow>
    </>
  )
  const ContactLensesAccessoriesInfo = () => (
    <>
      <OrderDetailsProductInfoRow>
        <OrderDetailsProductInfoListItem>
          {modelName}
        </OrderDetailsProductInfoListItem>
      </OrderDetailsProductInfoRow>
      <Divider style={{ width: '100%' }} />
      <OrderDetailsProductInfoRow>
        <OrderDetailsProductInfoListItem>
          {t('OrderRecap.Labels.Quantity')}
        </OrderDetailsProductInfoListItem>
        <OrderDetailsProductInfoItem>
          {getQuantity(orderItem)}
        </OrderDetailsProductInfoItem>
      </OrderDetailsProductInfoRow>
    </>
  )

  switch (true) {
    case isContactLenses:
      return <ContactLensesInfo />
    case isAccessories:
      return <AccessoriesInfo />
    case isContactLensesAccessories:
      return <ContactLensesAccessoriesInfo />
    default:
      return <FrameInfo />
  }
}
