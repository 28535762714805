import algoliasearch, { SearchClient } from 'algoliasearch'
import Cookies from 'js-cookie'
import config from '@configs/index'
import { INDICES_MAP } from '../../constants/productAlgolia'
import recommend, { RecommendClient } from '@algolia/recommend'

export const searchClient: SearchClient = algoliasearch(
  config?.algolia?.id,
  config?.algolia?.apiKey
)

const customerSegments: string[] = Cookies.get('userg')?.split('|') || []
const customerSegment = customerSegments[0] || config?.algolia?.guestSegment

const indexNameBuilder = (
  indexName: string,
  groupingSufix: string,
  sortOptionSufix: string = '',
  orderSufix: string = ''
): string => `${indexName}${groupingSufix}${sortOptionSufix}${orderSufix}`

export const initIndexName = (
  locale: string,
  sortOption?: number,
  isGrouped: boolean = true
): string => {
  const {
    indexName,
    groupedSufix,
    ungroupedSufix,
    newestSufix,
    querySuggestionsSufix,
    ascSufix,
    descSufix,
  } = config.algolia

  const priceSufix = `__${customerSegment}`
  let indexNameLocale = `${indexName}_${locale}`
  const groupingSufix = isGrouped ? groupedSufix : ungroupedSufix

  switch (sortOption) {
    case INDICES_MAP.BEST_SELLERS:
      return indexNameBuilder(indexNameLocale, groupingSufix)
    case INDICES_MAP.NEW_ARRIVALS:
      return indexNameBuilder(
        indexNameLocale,
        groupingSufix,
        newestSufix,
        descSufix
      )
    case INDICES_MAP.PRICE_ASC:
      return indexNameBuilder(
        indexNameLocale,
        groupingSufix,
        priceSufix,
        ascSufix
      )
    case INDICES_MAP.PRICE_DESC:
      return indexNameBuilder(
        indexNameLocale,
        groupingSufix,
        priceSufix,
        descSufix
      )
    case INDICES_MAP.QUERY_SUGGESTIONS:
      return (indexNameLocale += querySuggestionsSufix)
    default:
      return indexNameBuilder(indexNameLocale, groupingSufix)
  }
}

export const recommendClient: RecommendClient = recommend(
  config?.algolia?.id,
  config?.algolia?.apiKey,
  {
    headers: {
      'Content-Type': 'application/json',
    },
  }
)
