import { RootReducerState } from 'src/redux/reducers'
import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'
import { RequestProps } from '../../services/RequestService'

export type EligibleContractResponse = {
  contracts: Record<string, string>
}
export const contractApi = createApi({
  reducerPath: 'contractApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '',
  }),

  endpoints: (build) => ({
    getContract: build.query<
      EligibleContractResponse,
      {
        queryParams?: RequestProps['queryParams']
      }
    >({
      async queryFn(args, queryApi, _extraOptions, fetchWithBQ) {
        try {
          const {
            site: { currentSite },
          } = queryApi.getState() as RootReducerState
          const { queryParams } = args
          const result = await fetchWithBQ({
            url: `/store/${currentSite?.storeID}/contract`,
            method: 'get',
            queryParams,
          })
          return {
            data: result.data,
          }
        } catch (error) {
          return { error: error }
        }
      },
    }),
    changeContract: build.query<
      any,
      {
        queryParams?: RequestProps['queryParams']
      }
    >({
      async queryFn(args, queryApi, _extraOptions, fetchWithBQ) {
        try {
          const {
            site: { currentSite },
          } = queryApi.getState() as RootReducerState
          const { queryParams } = args
          const result = await fetchWithBQ({
            url: `/store/${currentSite?.storeID}/switchContract/switchTo`,
            method: 'put',
            queryParams,
          })
          return {
            data: result,
          }
        } catch (error) {
          return { error: error }
        }
      },
    }),
  }),
})

export const { useGetContractQuery } = contractApi
