import config from '../../configs'
import {
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import { Store } from '../../types/Tab/store'
import { TabStore } from 'src/types/tabStores'
import { RootReducerState } from '../../redux/reducers'
import { storeLocatorUtils } from '@utils/storeLocatorUtils'
import { IAppointmentTypes } from '../../types/Tab/appointmentTypes'

export interface IStoreQueryParams {
  latitude: string
  longitude: string
  radius?: string
  limit?: string
  offset?: string
  officeTypes?: string
}
export const tabApi = createApi({
  reducerPath: 'tabApi',
  baseQuery: fetchBaseQuery({ baseUrl: config.tabApiUrl }),
  endpoints: (builder) => ({
    getBookingStores: builder.query<Store[], { storeNumbers: string[] }>({
      async queryFn(args, queryApi, _extraOptions, baseQuery) {
        const { storeNumbers } = args
        /**
         * If storeNumbers is empty, return empty array
         * to avoid error in the component
         */
        if (storeNumbers.length === 0) {
          return { data: [] }
        }
        const state = queryApi.getState() as RootReducerState
        const { storeName, xStoreCfg } = state.site.currentSite!
        const url =
          xStoreCfg?.TAB_STORE_DETAILS_URL || 'tl-booking/api/v1/GV/office'

        const storeNumbersList = storeNumbers.map((id) =>
          baseQuery({
            url,
            method: 'get',
            params: {
              storeNumber: id,
            },
          })
        )

        let result = await Promise.all(storeNumbersList)

        const data = result.map((res) => res.data).filter((element) => element)

        const mappedData =
          data &&
          storeLocatorUtils.getMapBookingStoresData({
            storeName,
            stores: data as TabStore[],
          })

        return data.length
          ? {
              data: mappedData as Store[],
            }
          : { error: result[0].error as FetchBaseQueryError }
      },
    }),
    getBookingStore: builder.query<Store, { storeNumber: string }>({
      async queryFn(args, queryApi, _extraOptions, baseQuery) {
        const state = queryApi.getState() as RootReducerState
        const { storeName, xStoreCfg } = state.site.currentSite!

        const url =
          xStoreCfg?.TAB_STORE_DETAILS_URL || 'tl-booking/api/v1/GV/office'

        const result = await baseQuery({
          url,
          method: 'get',
          params: args,
        })

        return result.data
          ? {
              data: storeLocatorUtils.getMapBookingStoreData({
                store: result.data as TabStore,
                storeName,
              }) as Store,
            }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getBookingAppointmentTypes: builder.query<
      IAppointmentTypes[],
      { storeNumber: string }
    >({
      query: (args) => ({
        url: 'tl-booking/api/v1/GV/office/appointmentTypes',
        method: 'get',
        params: args,
      }),
    }),
    getStoreLocator: builder.query<Store[], IStoreQueryParams>({
      queryFn: async (args, queryApi, _extraOptions, baseQuery) => {
        const state = queryApi.getState() as RootReducerState
        const { storeName, xStoreCfg } = state.site.currentSite!

        const url =
          xStoreCfg?.TAB_STORE_LOCATOR_URL ||
          'tl-store-locator/api/v1/GV/offices'

        const params: IStoreQueryParams = {
          limit: '30',
          offset: '0',
          officeTypes: 'BOUTIQUE',
          ...args,
        }
        const result = await baseQuery({
          url,
          method: 'get',
          params,
        })

        return result.data
          ? { data: formattedStores(result.data, storeName) }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
  }),
})

const formattedStores = (stores, storeName) => {
  const storesList = stores.records || []
  return storeLocatorUtils.getMapStoreLocatorData({
    storeName,
    stores: storesList as TabStore[],
  })
}

export const {
  useGetBookingStoresQuery,
  useGetBookingStoreQuery,
  useGetBookingAppointmentTypesQuery,
  useGetStoreLocatorQuery,
} = tabApi
