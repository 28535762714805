import React from 'react'
import { useDispatch } from 'react-redux'
import { useStoreIdentity } from '../../../../../../foundation/hooks/useStoreIdentity'
import {
  setCloseDrawerMegaMenu,
  setHamburgerMenuOpenStatus,
} from '../../../../../../features/ui/action'
import BrandIcon from '../../../../../BrandIcon'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { ICMExternalChannel } from '../../../../../../types/cmsPlacement/CMExternalChannel'

const BrandLink = styled(Link, {
  name: 'ListOfBrandIcon',
  slot: 'BrandLink',
  shouldForwardProp: (prop) => prop !== 'width',
})<{ width?: string; height?: string }>(({ width, height }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: width ? width : '100%',
  height: height ? height : 'auto',
}))

const BrandIconLink: React.FC<{
  teasableItem: ICMExternalChannel
  width?: string
  height?: string
  isTabletLandscape?: boolean
}> = ({ teasableItem, width, height, isTabletLandscape }) => {
  const dispatch = useDispatch()
  const { basePath } = useStoreIdentity()

  let brandName = teasableItem?.externalId?.split('_').pop() || ''
  if (brandName.toLowerCase() === 'bvlgari') {
    brandName = brandName.replace('v', 'u')
  }
  const formattedUrl = teasableItem?.formattedUrl || ''
  const link = `${basePath}${formattedUrl || ''}`

  const closeMegaMenu = () => {
    dispatch(setCloseDrawerMegaMenu())
    dispatch(setHamburgerMenuOpenStatus(false))
  }

  return (
    <BrandLink
      data-element-id="MainNav_Brand"
      data-description={brandName}
      to={link}
      onClick={closeMegaMenu}
      width={width}
      aria-label={brandName}
      height={height}
    >
      <BrandIcon
        name={brandName}
        width={142}
        isTabletLandscape={isTabletLandscape}
      />
    </BrandLink>
  )
}

export default BrandIconLink
