import React, { useCallback, useState } from 'react'
import {
  StyledImageGalleryWrapper,
  StyledImageWrapper,
  StyledSquare,
  StyledSuggestedProductAnchor,
  StyledSuggestedProductSubWrapper,
  StyledSuggestedProductTileImageContainer,
  StyledSuggestedProductTileWrapper,
} from '../../../SuggestedProducts/components/SuggestedProductTile/SuggestedProductTile.style'
import { useAppDispatch } from 'src/hooks/redux'
import { useStoreIdentity } from 'src/foundation/hooks/useStoreIdentity'
import useBreakpoints from 'src/hooks/useBreakpoints'
import Swiper from 'swiper'
import { setLastSelectedProduct } from 'src/features/ui/action'
import SuggestedProductTileGalleryAlgolia from './components/SuggestedProductTileGalleryAlgolia/SuggestedProductTileGalleryAlgolia'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import useProductTile from '@components/ProductTile/useProductTile'
import ProductTileAlgoliaUtils from '@utils/ProductAlgolia/ProductTileAlgoliaUtils'
import SuggestedProductTileHeaderAlgolia from '@components/SuggestedProductAlgolia/components/SuggestedProductTileAlgolia/components/SuggestedProductTileHeaderAlgolia/SuggestedProductTileHeaderAlgolia'
import { ProductImageUsage } from '../../../../types/product'
import ProductImage, {
  ProductImageProps,
} from '@components/ProductImage/ProductImage'
import SuggestedProductTileFooterAlgolia from '@components/SuggestedProductAlgolia/components/SuggestedProductTileAlgolia/components/SuggestedProductTileFooterAlgolia/SuggestedProductTileFooterAlgolia'
import config from '@configs/index'
import { ProductAlgolia } from '../../../../types/ProductAlgolia/ProductAlgolia'

export interface SuggestedProductTileProps {
  onClick?: () => void
  tileIndex: number
  variant?: string
  onWishlistButtonClick?: (productId: string) => void
  isClustered?: boolean
  product: ProductAlgolia
  isLazyLoadEnabled?: boolean
  isImgWithShadow?: boolean
  isImgFront?: boolean
}

const siteName = config.REACT_APP_STORENAME

const SuggestedProductTileAlgolia = ({
  product,
  onClick,
  tileIndex,
  isLazyLoadEnabled,
}: SuggestedProductTileProps) => {
  const dispatch = useAppDispatch()
  const { langCode } = useStoreIdentity()
  const { isMobile, isTabletPortrait } = useBreakpoints()
  const [carouselMode, setCarouselMode] = useState<boolean>(false)

  const { getProductAttributes, getLxProductTypes } = ProductAlgoliaUtils
  const {
    getTileDataElementId,
    getProductImageWidth,
    selectedClusterIndex,
    setSelectedClusterIndex,
    getClusterData,
    productsMobileListingLayout,
  } = useProductTile(product, true)

  const tileDataElementId = getTileDataElementId(tileIndex)
  const { clusters, clusterSelected, clusterLength } = getClusterData
  const attributes = clusterSelected.attributes_translated
  const productLinkTo = `/${langCode}${clusterSelected.url || ''}`

  const { badges } =
    ProductTileAlgoliaUtils.getProductTileAttributes(attributes)
  const { modelName, lxSearchPageProductType } =
    getProductAttributes(attributes)
  const { isAccessories, isFrames } = getLxProductTypes(lxSearchPageProductType)

  const isMoCoSliderRendered = isFrames || (isAccessories && clusterLength > 1)
  const isMoCoSliderDisplayed =
    isMoCoSliderRendered &&
    (carouselMode || productsMobileListingLayout === 'full')
  const onProductTileMouseEnter = () => {
    if (isMobile || isTabletPortrait) return
    if (clusterLength > 0) setCarouselMode(true)
  }

  const onProductTileMouseLeave = () => {
    if (isMobile || isTabletPortrait) return
    if (carouselMode) setCarouselMode(false)
  }

  const onImageClick = () =>
    dispatch(setLastSelectedProduct(product?.productId || ''))

  const commonProductImageProps = {
    alt: modelName,
    draggable: false,
    sequence: '1',
    usage: 'PLP' as ProductImageUsage,
    width: getProductImageWidth,
    onClick: onImageClick,
  }

  const commonGalleryProductImageProps: ProductImageProps = {
    sequence: isAccessories ? '1' : '2',
    usage: 'PLP',
  }

  const onProductChange = useCallback((swiper: Swiper) => {
    if (Number.isInteger(swiper.realIndex)) {
      setSelectedClusterIndex(swiper.realIndex)
    }
  }, [])

  const metaData = {
    'aria-describedby': badges.primaryBadge || `${modelName}`,
    'data-element-id': tileDataElementId,
    'data-description': encodeURIComponent(`${siteName}_${modelName}`),
  }

  return (
    product && (
      <StyledSuggestedProductTileWrapper
        onMouseEnter={onProductTileMouseEnter}
        onMouseLeave={onProductTileMouseLeave}
        {...metaData}
      >
        <StyledSuggestedProductSubWrapper>
          <StyledSquare onClick={onClick}>
            <>
              <SuggestedProductTileHeaderAlgolia
                clusterSelected={clusterSelected}
              />
              {isMoCoSliderDisplayed ? (
                <StyledImageGalleryWrapper>
                  <SuggestedProductTileGalleryAlgolia
                    commonImageProps={commonGalleryProductImageProps}
                    currentProductIndex={selectedClusterIndex}
                    onProductChange={onProductChange}
                    cluster={clusters}
                    metaData={metaData}
                  />
                </StyledImageGalleryWrapper>
              ) : (
                <StyledSuggestedProductTileImageContainer>
                  <StyledSuggestedProductAnchor
                    to={productLinkTo}
                    aria-label={modelName}
                    {...metaData}
                  >
                    <StyledImageWrapper>
                      <div>
                        <ProductImage
                          isLazyLoadEnabled={isLazyLoadEnabled}
                          attachments={clusterSelected.attachments}
                          {...commonProductImageProps}
                        />
                      </div>
                    </StyledImageWrapper>
                  </StyledSuggestedProductAnchor>
                </StyledSuggestedProductTileImageContainer>
              )}
            </>
          </StyledSquare>
        </StyledSuggestedProductSubWrapper>
        <SuggestedProductTileFooterAlgolia
          clusterSelected={clusterSelected}
          metaData={metaData}
          //isCompact={productsMobileListingLayoutPages !== 'full'}
        />
      </StyledSuggestedProductTileWrapper>
    )
  )
}

export default SuggestedProductTileAlgolia
