import React, { useCallback, useEffect, useRef } from 'react'
import {
  InputContainer,
  StyledButton,
  IconContainer,
  InputWithIconContainer,
  InputErrorMessage,
  StyledInput,
} from '../FindStore.style'
import { useNavigate } from 'react-router'
import { useFindLocation } from '../useFindLocation'
import { GeopositionIcon } from '../../UI/Icons'
import { useTranslation } from 'react-i18next'
import { STORELOCATOR } from '../../../constants/routes'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { formattedParams } from '@utils/url'
import { HASH } from '../../../constants/ui'

export interface FindStoreInputProps {
  onSearch?: () => void
  dataElementId?: string
  dataDescription?: string
  className?: string
}

export const FindStoreInput: React.FC<FindStoreInputProps> = ({
  onSearch,
  className,
  dataElementId,
  dataDescription,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const {
    value,
    selectedPlace,
    error,
    onChange,
    onHandleSearch,
    getGeoposition,
  } = useFindLocation(inputRef.current)

  const { langCode } = useStoreIdentity()

  const navigateTo = useCallback(
    (to: string) => {
      const search = formattedParams({
        location: to,
      })
      const url = `/${langCode}/${STORELOCATOR}${HASH}/${search}`
      navigate(url)
    },
    [navigate]
  )

  useEffect(() => {
    if (selectedPlace && selectedPlace.formatted_address && !error) {
      navigateTo(selectedPlace.formatted_address)
      onSearch && onSearch()
    }
  }, [selectedPlace, error, navigateTo, onSearch])

  const onClickSearch = useCallback(() => {
    onHandleSearch()
    if (value && !selectedPlace) {
      navigateTo(value)
      onSearch && onSearch()
    }
  }, [onHandleSearch, onSearch, navigateTo, selectedPlace, value])

  return (
    <InputContainer className={className}>
      <InputWithIconContainer>
        <IconContainer onClick={getGeoposition}>
          <GeopositionIcon />
        </IconContainer>
        <StyledInput
          value={value}
          onChange={onChange}
          ref={inputRef}
          error={!!error}
          placeholder={t('findStore.searchTownPlaceholder')}
        />
      </InputWithIconContainer>
      {error && <InputErrorMessage>{error}</InputErrorMessage>}
      <StyledButton
        onClick={onClickSearch}
        data-element-id={dataElementId}
        data-description={dataDescription}
      >
        {t('findStore.searchButton')}
      </StyledButton>
    </InputContainer>
  )
}
