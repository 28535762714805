import { loadAsyncScript } from '@utils/loadAsyncScript'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { googleApiKeySelector } from 'src/redux/selectors/site'

export function loadGoogleMapsScript(
  googleApiKey: string,
  onLoad?: () => void,
  onError?: (e: unknown) => void
) {
  return loadAsyncScript(
    `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`,
    'dw-gtag-gmap',
    onLoad,
    onError
  )
}

export function useLoadGoogleMapsScript(
  onLoad?: () => void,
  onError?: (e: unknown) => void
) {
  const [loaded, setIsLoaded] = useState(!!window?.google?.maps)
  const [error, setError] = useState<unknown>()
  const googleApiKey = useSelector(googleApiKeySelector)

  useEffect(() => {
    if (googleApiKey && !loaded) {
      loadGoogleMapsScript(
        googleApiKey,
        () => {
          setIsLoaded(true)
          setError(undefined)
          onLoad?.()
        },
        (e) => {
          setIsLoaded(false)
          setError(e)
          onError?.(e)
        }
      )
    }
  }, [loaded, googleApiKey])

  return { loaded, error }
}
