import { styled } from '@mui/material/styles'
import { GridContainer, GridItem, StyledAnchor } from '@components/UI'
import { ItemContent } from '../NewsLetter/NewsLetter.style'

export const SocialProofWrapper = styled(GridContainer, {
  name: 'SocialProof',
  slot: 'Wrapper',
})(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.color.grey.light}`,
  gridColumnGap: theme.spacing(4),
  width: '100vw',
  marginLeft: 'calc(-50vw + 50%)',
  padding: theme.spacing(4),
  gap: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    borderBottom: `1px solid ${theme.palette.color.grey.light}`,
    padding: theme.spacing(0, 4),
  },

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2, 16),
  },
}))

export const SocialProofItem = styled(GridItem, {
  name: 'SocialProof',
  slot: 'Item',
})({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})

export const LeftItemContent = styled(ItemContent, {
  name: 'SocialProof',
  slot: 'LeftItemContent',
})(({ theme }) => ({
  minHeight: 'unset',

  '.trustpilot-widget': {
    width: 200,
    padding: theme.spacing(2, 0),
  },

  [theme.breakpoints.up('sm')]: {
    '.trustpilot-widget': {
      width: 500,
    },
  },

  [theme.breakpoints.up('md')]: {
    '.trustpilot-widget': {
      width: 446,
    },
  },
}))
export const RightItemContent = styled(ItemContent, {
  name: 'SocialProof',
  slot: 'RightItemContent',
})(({ theme }) => ({
  justifyContent: 'flex-start',
  minHeight: 'unset',

  [theme.breakpoints.up('sm')]: {
    justifyContent: 'flex-end',
    minHeight: 'unset',
  },
}))

export const CertificationsWrapper = styled('div', {
  name: 'SocialProof',
  slot: 'CertificationsWrapper',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  gap: theme.spacing(2),

  [theme.breakpoints.up('sm')]: {
    justifyContent: 'flex-end',
  },
}))

export const CertificationImg = styled('img', {
  name: 'SocialProof',
  slot: 'CertificationImg',
})(() => ({
  width: 59.5,
  height: 38.2,
}))

export const RayBanCertificationImg = styled('img', {
  name: 'SocialProof',
  slot: 'RaybanCertificationImg',
})(() => ({
  width: 82.5,
  height: 38.2,
}))

export const StyledCertificationAnchor = styled(StyledAnchor, {
  name: 'SocialProof',
  slot: 'Anchor',
})(() => ({
  display: 'inherit',
  '&::after': {
    height: '0 !important',
  },
}))
