import React from 'react'
import useBreakpoints from '../../../../../hooks/useBreakpoints'

import { SquatMobileBanner } from './Mobile'
import { SquatDesktopBanner } from './Desktop'
import { IPlacement } from '../../../../../types/cmsPlacement/Placement'
import { ICMCollection } from '../../../../../types/cmsPlacement/CMCollection'

export interface ISquatBannerProps {
  placement: IPlacement | ICMCollection
  teaserIndex?: number
  bannerIndex?: number
}

const SquatBanner: React.FC<ISquatBannerProps> = ({
  placement,
  teaserIndex,
}) => {
  const { isMobile, isTabletPortrait } = useBreakpoints()

  return (
    <>
      {isMobile || isTabletPortrait ? (
        <SquatMobileBanner
          placement={placement}
          teaserIndex={teaserIndex}
          bannerIndex={1}
        />
      ) : (
        <SquatDesktopBanner
          placement={placement}
          teaserIndex={teaserIndex}
          bannerIndex={1}
        />
      )}
    </>
  )
}

export default SquatBanner
