import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'
import { SeoResponse, ISeo } from '../../types/seo'

export const seoApi = createApi({
  reducerPath: 'seo',
  baseQuery: axiosBaseQuery({
    baseUrl: '/',
  }),
  endpoints: (build) => ({
    getSeo: build.query<
      SeoResponse<ISeo>,
      {
        identifier: string
      }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'api/v2/urls',
          method: 'get',
          queryParams: args || {},
          body: args['body'] || {},
          extraParams: { siteContextKey: 'search' },
        })

        return (result.data as SeoResponse<ISeo>)
          ? { data: result.data as SeoResponse<ISeo> }
          : { error: result }
      },
    }),
    getAlternateUrl: build.query<
      any,
      {
        brand?: string
        pageId?: string
      }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'store/{storeId}/seo/alternateUrl',
          method: 'get',
          queryParams: args || {},
        })

        return result ? { data: result.data } : { error: result }
      },
    }),
  }),
})

export const { useGetAlternateUrlQuery, useGetSeoQuery } = seoApi
