import first from 'lodash/first'
import { ServerProduct } from '../types/product'

// TODO implement all logic for cluster selection
export const getProductPrices = (product: ServerProduct) => {
  let currentSku
  const clusters = product?.cluster ?? product?.items //items when products are not clustered

  if (clusters && clusters.length > 0 && clusters[0]?.sKUs) {
    currentSku = first(clusters[0]?.sKUs)
  } else {
    currentSku = product
  }

  if (!currentSku?.x_prices || !currentSku?.x_prices.list) return

  const fullPrice = currentSku?.x_prices?.list.price
  const discountPrice = currentSku?.x_prices?.offer
    ? currentSku?.x_prices?.offer.price
    : currentSku?.x_prices?.list.price

  return {
    full: fullPrice || '0',
    discount: discountPrice || '0',
    currency: currentSku?.x_prices.list.currency,
  }
}

export const getClItemAccessoryPrice = (
  priceValue: string,
  productQuantity?: number,
  totalBoxes?: number,
  isCL?: boolean,
  isClAccessory?: boolean
): number => {
  const price = parseFloat(priceValue)
  let quantity = 0

  switch (true) {
    case isClAccessory:
      quantity = productQuantity || 0
      break
    case isCL:
      quantity = totalBoxes || 0
      break
    default:
      quantity = 1
      break
  }

  return price * quantity
}
