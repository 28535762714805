import { useMemo } from 'react'
import { createAnalyticsDataSelector } from '../selectors/analyticsData'
import { useSelector } from 'react-redux'

export const useAnalyticsData = (pageType?: string) => {
  const analyticsDataSelector = useMemo(
    () => createAnalyticsDataSelector(pageType),
    [pageType]
  )
  return useSelector(analyticsDataSelector)
}
