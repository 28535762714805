import { GoogleIcon } from '@components/UI/Icons'
import React, { useRef, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { GoogleButton } from '../../styles/SocialLogin.style'
import { useGoogleOAuth } from './index'
import {
  ClienCode,
  ClienToken,
  CodeResponse,
  NonOAuthError,
  OverridableTokenClientConfig,
  TokenResponse,
} from './types'

interface GoogleLogin {
  flow: 'token' | 'auth-code'
  scope: string
  overrideScope?: boolean
  onSuccess: (res: TokenResponse | CodeResponse) => void
  onError: (
    error: Pick<TokenResponse, 'error' | 'error_description' | 'error_uri'>
  ) => void
  onNonOAuthError?: (err: NonOAuthError) => void
}

const useGoogleLogin = ({
  flow = 'token',
  scope = 'email',
  overrideScope,
  onSuccess,
  onError,
  onNonOAuthError,
}: GoogleLogin) => {
  const { clientId, scriptLoaded } = useGoogleOAuth()
  const clientRef = useRef<ClienToken | ClienCode>()

  const onSuccessRef = useRef(onSuccess)
  onSuccessRef.current = onSuccess

  const onErrorRef = useRef(onError)
  onErrorRef.current = onError

  const onNonOAuthErrorRef = useRef(onNonOAuthError)
  onNonOAuthErrorRef.current = onNonOAuthError

  const clientMethod = flow === 'token' ? 'initTokenClient' : 'initCodeClient'

  const isClientCode = (client?: ClienToken | ClienCode): client is ClienCode =>
    clientMethod === 'initCodeClient'

  const isClientToken = (
    client?: ClienToken | ClienCode
  ): client is ClienToken => clientMethod === 'initTokenClient'

  useEffect(() => {
    if (!scriptLoaded) return

    const client = window?.google?.accounts.oauth2[clientMethod]({
      client_id: clientId,
      scope: overrideScope ? scope : `openid profile email ${scope}`,
      callback: (response: TokenResponse | CodeResponse) => {
        if (response.error) {
          return onErrorRef.current(response)
        }
        onSuccessRef.current(response)
      },
      error_callback: (nonOAuthError: NonOAuthError) => {
        onNonOAuthErrorRef.current?.(nonOAuthError)
      },
    })

    clientRef.current = client
  }, [clientMethod, scope, clientId, scriptLoaded])

  const loginImplicitFlow = useCallback(
    (overrideConfig?: OverridableTokenClientConfig) => {
      if (!isClientToken(clientRef.current)) return
      clientRef.current?.requestAccessToken(overrideConfig)
    },
    []
  )

  const loginAuthCodeFlow = useCallback(() => {
    if (!isClientCode(clientRef.current)) return
    clientRef.current?.requestCode()
  }, [])

  return flow === 'token' ? loginImplicitFlow : loginAuthCodeFlow
}

interface GoogleSocialLoginBtn extends GoogleLogin {}

export const GoogleSocialLoginBtn: React.FC<GoogleSocialLoginBtn> = ({
  flow,
  scope,
  onError,
  onSuccess,
  onNonOAuthError,
}) => {
  const login = useGoogleLogin({
    flow,
    scope,
    onSuccess,
    onError,
    onNonOAuthError,
  })
  const { t } = useTranslation()

  const onLogin = useCallback(() => {
    login()
  }, [login])

  return (
    <>
      <GoogleButton onClick={onLogin}>
        <GoogleIcon />
        <span>{t('SignInPage.Socials.Google')}</span>
      </GoogleButton>
    </>
  )
}
