import { GridContainer, GridItem } from '../../../UI'

import { styled } from '@mui/material/styles'

export const ProductsWrapper = styled('div', {
  name: 'ProductGrid',
  slot: 'Wrapper',
})(() => ({}))

export const StyledProductsCount = styled('div', {
  name: 'ProductGrid',
  slot: 'ProductsCount',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(8),
}))

export const StyledLoaderContainer = styled('div', {
  name: 'ProductGrid',
  slot: 'LoaderContainer',
})(() => ({
  display: 'flex',
  justifyContent: 'center',
}))

export const StyledProductGridContainer = styled(GridContainer, {
  name: 'ProductGrid',
  slot: 'Container',
})(({ theme }) => ({
  padding: theme.spacing(0, 4, 4),

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 16, 8),
    gridTemplateColumns: 'repeat(12, 1fr)',
  },
}))

export const StyledGridProductLayout = styled(GridItem, {
  name: 'ProductGrid',
  slot: 'Item',
})(({ theme }) => ({
  gridColumn: 'span 4',
  aspectRatio: 'initial',
  maxWidth: '100%',
  boxSizing: 'border-box',
  position: 'relative',
  backgroundColor: theme.palette.color.white.main,
  /* for older browser's versions that don't supprt aspetc-ratio */
  '@supports not (aspect-ratio: 1 / 1)': {
    '&::before': {
      content: '""',
      display: 'block',
      paddingTop: '150%',
      [theme.breakpoints.up('sm')]: {
        paddingTop: '100%',
      },
    },
  },
}))

export const StyledNoProductsFundLabel = styled(GridItem, {
  name: 'ProductGrid',
  slot: 'NoProductsFundLabel',
})(({ theme }) => ({
  padding: theme.spacing(0, 4, 4),
}))

export const StyledProductWrap = styled('div', {
  name: 'ProductGrid',
  slot: 'Wrap',
})(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  },
  /* for older browser's versions that don't supprt aspetc-ratio */
  '@supports not (aspect-ratio: 1 / 1)': {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
}))
