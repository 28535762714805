import { Product, SupplyData } from '../../types/product'
import { getProductPrices } from '@utils/productPrice'
import CurrencyPriceUtils from '@utils/CurrencyPriceUtils'
import { seoUrlConcat } from '@utils/url'
import { PRODUCT_TYPES_MAP } from '../../constants/product'
import {
  isAccessories,
  isCLAccessories,
  isContactLenses,
  isFrames,
} from '@utils/product'

class ProductTileUtilsOld {
  getAttributes = (product: Product) => {
    const attributes = product?.productAttributes
    const productType =
      PRODUCT_TYPES_MAP[attributes && attributes['PRODUCT_TYPE']?.toLowerCase()]
    const lxProductType =
      PRODUCT_TYPES_MAP[
        attributes && attributes['LX_SEARCH_PAGE_PRODUCT_TYPE']?.toLowerCase()
      ]
    const name = attributes && attributes['MODEL_NAME']
    const brand = attributes && attributes['BRAND']
    const modelCode = attributes && attributes['DISPLAYSKU']

    const prices = getProductPrices(product as any)
    const currency = CurrencyPriceUtils.getSymbolByName(prices?.currency || '')
    const colorLabel = this.getColorLabel(product)
    const annualSupply = this.getAnnualSupply(product).find(
      (data) => data.timePeriod === '12'
    )
    return {
      name,
      brand,
      modelCode,
      productType,
      lxProductType,
      prices,
      currency,
      colorLabel,
      annualSupply,
    }
  }
  getMocoLinkTo = (product: Product, langCode: string) => {
    let href = product?.seo?.href || ''
    href = href?.startsWith('/') ? href : '/' + href
    return seoUrlConcat(langCode, href)
  }

  getLxProductTypes = (lxProductType: string) => {
    return {
      isFrames: isFrames(lxProductType),
      isContactLenses: isContactLenses(lxProductType),
      isContactLensesAccessories: isCLAccessories(lxProductType),
      isAccessories: isAccessories(lxProductType),
    }
  }

  getColorLabel = (product: Product) => {
    const attributes = product?.productAttributes
    const lxProductType =
      attributes && attributes['PRODUCT_TYPE']?.toLowerCase()

    switch (lxProductType) {
      case PRODUCT_TYPES_MAP.accessories:
      case PRODUCT_TYPES_MAP.optical:
        return attributes['FRONT_COLOR']
      case PRODUCT_TYPES_MAP.sun:
        const frontColor = attributes['FRONT_COLOR']
        const lensColor =
          attributes['LENS_COLOR'] || attributes['LENS_COLOR_FACET']
        return frontColor === lensColor
          ? frontColor
          : `${frontColor} ${lensColor}`.trim()
      default:
        return ''
    }
  }

  getAnnualSupply = (product: Product) => {
    const attributes = product?.productAttributes
    const annualSupplyValue =
      attributes && attributes['CL_ANNUAL_SUPPLY_BADGES']

    if (!annualSupplyValue) return []

    const supplyAttributeDataConfMap: Record<number, keyof SupplyData> = {
      0: 'quantity',
      1: 'discountAmount',
      2: 'originalBoxPrice',
      3: 'discountedBoxPrice',
      4: 'timePeriod',
    }

    let supplyTempData: SupplyData[] = []
    let temp: SupplyData = {}
    annualSupplyValue.split('|').forEach((supplyDataItem, i) => {
      if (!!supplyDataItem && !!supplyAttributeDataConfMap[i]) {
        temp[supplyAttributeDataConfMap[i]] = supplyDataItem
      }
    })
    if (temp) supplyTempData.push(temp)

    return supplyTempData
  }
}
export default new ProductTileUtilsOld()
