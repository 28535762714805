import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import initStates from '../../redux/reducers/initStates'
import { ContractReducerState, RootReducerState } from '../../redux/reducers'
import { contractApi, EligibleContractResponse } from './query'
import listenerMiddleware from '../../redux/listener'
import {
  GUEST_LOGIN_SUCCESS_ACTION,
  LOGIN_SUCCESS_ACTION,
  LOGOUT_SUCCESS_ACTION,
  REGISTRATION_SUCCESS_ACTION,
} from '../../redux/actions/user'
import { FETCH_CONTRACT_REQUESTED_ACTION } from '../../redux/actions/contract'
import { contextApi } from '../context/query'

const contractSlice = createSlice({
  name: 'contract',
  initialState: initStates.contract,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      contractApi.endpoints?.getContract.matchFulfilled,
      (
        state: ContractReducerState | any,
        { payload }: PayloadAction<EligibleContractResponse>
      ) => {
        for (let variableKey in state) {
          if (state.hasOwnProperty(variableKey)) {
            delete state[variableKey]
          }
        }
        Object.assign(state, payload?.contracts)
      }
    )
  },
})

export const contractListeners = (
  startListening: typeof listenerMiddleware.startListening
) => {
  startListening({
    matcher: isAnyOf(
      LOGIN_SUCCESS_ACTION,
      LOGOUT_SUCCESS_ACTION,
      GUEST_LOGIN_SUCCESS_ACTION,
      REGISTRATION_SUCCESS_ACTION,
      FETCH_CONTRACT_REQUESTED_ACTION
    ),
    effect: async (action, listenerApi) => {
      listenerApi.dispatch(
        contractApi.endpoints?.getContract.initiate({
          queryParams: {
            q: 'eligible',
            id: Date.now(),
          },
        })
      )

      //listenerApi.unsubscribe()
    },
  })
}

export const preselectContractListener = (
  startListening: typeof listenerMiddleware.startListening
) => {
  startListening({
    matcher: isAnyOf(contractApi.endpoints?.getContract.matchFulfilled),
    effect: async (action, listenerApi) => {
      const state = listenerApi.getState() as RootReducerState
      const isLogin = state.user.userLoggedIn || false
      const contracts = state.contract
      const entitledContracts: string[] =
        state.context.entitlement?.currentTradingAgreementIds || []

      if (isLogin && entitledContracts.length !== 1) {
        const contract = Object.keys(contracts)[0]
        listenerApi.dispatch(
          contractApi.endpoints?.changeContract.initiate({
            queryParams: { contractId: String(contract) },
          })
        )

        listenerApi.dispatch(
          contextApi.endpoints?.getContext.initiate({
            queryParams: {
              id: Date.now(),
            },
          })
        )
      }

      //listenerApi.unsubscribe()
    },
  })
}

const { reducer } = contractSlice
export default reducer
