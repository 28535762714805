import React from 'react'
import {
  OurShopsContainer,
  OurShopsTitle,
  OurShopsLeftColumn,
  OurShopsRightColumn,
  OurShopsDescription,
  OurShopsImageContainer,
  OurShopsImage,
} from './OurShops.style'
import { useTranslation } from 'react-i18next'
import config from '@configs/config.base'

const OurShops: React.FC<{ storeNumber: string }> = ({ storeNumber }) => {
  const { t } = useTranslation()
  const imagePreview = `${config.PUBLIC_URL}/images/common/image-preview.png`
  const urlStoreImage = `${config.defaultDamDomain}/sdp/${storeNumber}/store.jpg`

  return (
    <OurShopsContainer>
      <OurShopsLeftColumn>
        <OurShopsTitle>{t('StoreDetails.Labels.OurShops')}</OurShopsTitle>
        <OurShopsDescription>
          {t('StoreDetails.Labels.OurShopsDesc')}
        </OurShopsDescription>
      </OurShopsLeftColumn>
      <OurShopsRightColumn>
        <OurShopsImageContainer>
          <OurShopsImage
            src={urlStoreImage}
            alt="storeImage"
            onError={(e) => (e.currentTarget.src = imagePreview)}
          />
        </OurShopsImageContainer>
      </OurShopsRightColumn>
    </OurShopsContainer>
  )
}

export default OurShops
