import React from 'react'
import SuggestedProducts from '../../../../SuggestedProducts'
import { StyledSuggestionContainer } from './DcwProducts.style'
import {
  IPlacement,
  isCMAlgolia,
} from '../../../../../types/cmsPlacement/Placement'
import { ICMProductTeaser } from '../../../../../types/cmsPlacement/CMProductTeaser'
import { ICMExternalProduct } from '../../../../../types/cmsPlacement/CMExternalProduct'
import { ICMCollection } from '../../../../../types/cmsPlacement/CMCollection'
import { ICMProductList } from '../../../../../types/cmsPlacement/CMProductList'
import { getPlpProductList } from '../../../../../features/plp/utils'
import { useStoreIdentity } from '../../../../../foundation/hooks/useStoreIdentity'
import { localeLangCountryUtil } from '@utils/countryUtil'
import {
  initIndexName,
  searchClient,
} from '../../../../../foundation/algolia/algoliaConfig'
import { Configure, InstantSearch } from 'react-instantsearch-hooks-web'
import DcwProductsAlgolia from '@components/Cms/CmsPlacement/CmsBanner/DcwProductsAlgolia'

interface IProps {
  placement: IPlacement | ICMCollection | ICMProductList
  teaserIndex?: number
}
type IProductType = (ICMExternalProduct | ICMProductTeaser)[]

const DcwProducts: React.FC<IProps> = ({ placement }) => {
  let isImgWithShadow = false
  let isImgFront = true

  let productData
  if ('items' in placement) {
    const items =
      (placement.items.filter((item) =>
        ['CMProductTeaser', 'CMExternalProduct'].includes(item.type)
      ) as IProductType) || []

    productData =
      items
        .map((x) => x.productData)
        .filter((product) => product !== undefined) || []
  } else {
    if (placement.type === 'CMCollection') {
      isImgWithShadow = ['front-quart-shad', 'quart-front-shad'].includes(
        placement.collectionProductStyle!
      )
      isImgFront = ['front-quart-shad', 'front-quart-noshad'].includes(
        placement.collectionProductStyle!
      )

      const teasableItems =
        (placement.teasableItems.filter((item) =>
          ['CMProductTeaser', 'CMExternalProduct'].includes(item.type)
        ) as IProductType) || []

      productData =
        teasableItems
          .map((x) => x.productData)
          .filter((product) => product !== undefined) || []
    } else {
      isImgWithShadow = ['front-quart-shad', 'quart-front-shad'].includes(
        placement.collectionProductStyle!
      )
      isImgFront = ['front-quart-shad', 'front-quart-noshad'].includes(
        placement.collectionProductStyle!
      )
      productData = placement.productData || []
    }
  }
  const formattedProductData = getPlpProductList(productData)

  //ALGOLIA DCW PRODUCTS SETTINGS
  const { langCode } = useStoreIdentity()
  const langCountry = localeLangCountryUtil(langCode)

  const item =
    'items' in placement
      ? placement.items?.find(isCMAlgolia)!
      : placement.teasableItems?.find(isCMAlgolia)!

  const groupedRule = item?.groupedRule || ''
  const ungroupedRule = item?.ungroupedRule || ''

  const isEmptyString = (rule: string): boolean => rule === ''
  const isGrouped = () => !isEmptyString(groupedRule)

  const rules = isEmptyString(groupedRule) ? [ungroupedRule] : [groupedRule]
  const isPLPMethod =
    !isEmptyString(groupedRule) && !isEmptyString(ungroupedRule)

  const indexName = initIndexName(langCountry, undefined, isGrouped())
  const displayProductsCount = Number(item?.displayProductsCount) || 3

  return item ? (
    <InstantSearch searchClient={searchClient} indexName={indexName}>
      <Configure
        facets={['*']}
        clickAnalytics={true}
        hitsPerPage={displayProductsCount}
        ruleContexts={rules}
        attributesToRetrieve={isPLPMethod ? ['x_groupkey'] : ['*']}
      />
      <DcwProductsAlgolia
        item={item}
        searchClient={searchClient}
        isPlpMethod={isPLPMethod}
      />
    </InstantSearch>
  ) : (
    <StyledSuggestionContainer>
      <SuggestedProducts
        products={formattedProductData}
        isImgWithShadow={isImgWithShadow}
        isImgFront={isImgFront}
      />
    </StyledSuggestionContainer>
  )
}

export default DcwProducts
