//Standard libraries
import { createAction } from '@reduxjs/toolkit'
//Redux
import * as ACTIONTYPES from '../action-types/site'

export const INIT_SITE_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.INIT_SITE_SUCCESS
)

export const SET_FORTER_WEB_ID_ACTION = createAction<any, string>(
  ACTIONTYPES.SET_FORTER_WEB_ID
)

export const SET_ALGOLIA_BASED_PLP_ACTION = createAction<any, string>(
  ACTIONTYPES.SET_PLP_BASED_PLP
)
