import React from 'react'
import { ILXTeaser } from '../../../../../types/cmsPlacement/LXTeaser'
import { teaserPropsByView } from '../../../../../utils/placements'
import { InfoOutlinedIcon } from '../../../../UI/Icons'
import { StyledDetailsInfo } from '../CmsTermsAndConditions.style'

export interface CmsTermsAndConditionsButtonProps {
  onClick?: () => void
  teaser: ILXTeaser
}

const CmsTermsAndConditionsButton: React.FC<
  CmsTermsAndConditionsButtonProps
> = ({ teaser, onClick, ...rest }) => {
  const targetsTermsAndConditions = teaser?.targetsTermsAndConditions

  const text = targetsTermsAndConditions?.text || ''
  const target = targetsTermsAndConditions?.target
  const { teaserOverlayStyle } = teaserPropsByView(
    teaser?.viewtype || 'default'
  )

  if (!target) return null

  const teaserOverlayStyleValue = teaser[teaserOverlayStyle]

  return (
    <StyledDetailsInfo
      onClick={onClick}
      aria-label="Terms and conditions"
      data-element-id="X_X_TermsAndConditions"
      teaseroverlaystyle={teaserOverlayStyleValue}
      {...rest}
    >
      <InfoOutlinedIcon />
      {text}
    </StyledDetailsInfo>
  )
}

export default CmsTermsAndConditionsButton
