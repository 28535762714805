import { styled } from '@mui/material/styles'
import { Z_INDEX_LV1 } from '../../../../constants/ui'

export const StyledProductTileHeader = styled('div', {
  name: 'ProductTile',
  slot: 'Header',
})(({ theme }) => ({
  zIndex: Z_INDEX_LV1,
  width: '100%',
  top: '1rem',
  left: '1rem',
  right: '1rem',
  flexDirection: 'column',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.up('xs')]: {
    marginTop: theme.spacing(3),
    alignSelf: 'end',
  },

  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(4),
  },

  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(8),
  },
}))

export const StyledProductTileContactLensesAnnualSupplyWrapper = styled('div', {
  name: 'ProductTile',
  slot: 'ContactLensesAnnualSupplyWrapper',
  shouldForwardProp: (prop) => prop !== 'isFull',
})<{ isFull?: boolean }>(({ theme, isFull }) => ({
  fontSize: isFull ? 16 : 10,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),

  [theme.breakpoints.up('xs')]: {
    flexDirection: isFull ? 'row' : 'column',
  },

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}))

export const StyledProductTileContactLensesAnnualSupplySaveAmount = styled(
  'div',
  {
    name: 'ProductTile',
    slot: 'ContactLensesAnnualSupplySaveAmount',
  }
)(() => ({
  fontWeight: 600,
  textTransform: 'uppercase',
}))

export const ProductTilePrimaryBadge = styled('div', {
  name: 'ProductTile',
  slot: 'PrimaryBadge',
})(({ theme }) => ({
  fontSize: 10,
  textTransform: 'uppercase',
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),

  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
    textAlign: 'center',
    marginTop: theme.spacing(0),
  },
}))
export const ProductTileSecondaryBadge = styled('div', {
  name: 'ProductTile',
  slot: 'SecondaryBadge',
})(({ theme }) => ({
  fontSize: 10,
  lineHeight: 1.6,
  textAlign: 'center',
  whiteSpace: 'nowrap',
  overflow: 'auto',

  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
    paddingTop: theme.spacing(2),
  },
}))
