import * as React from 'react'
import { styled } from '@mui/material/styles'
import { CustomAutoCompleteProps } from '../../../types/inputFields'
import Autocomplete from '@mui/material/Autocomplete'
import { useTheme } from '@mui/material/styles'
import { ArrowDownIcon, CloseCircleIcon } from '../../UI/Icons'
import { Paper } from '@mui/material'

const StyledPaper = styled(Paper, {
  name: 'MyThemeComponent',
  slot: 'Root',
})(({ theme }) => ({
  marginTop: '-2px',
  '.MuiAutocomplete-listbox': {
    border: `2px solid ${theme.palette.primary.main}`,
    '.MuiAutocomplete-option': {
      color: theme.palette.text.dark,
      fontFamily: 'inherit',
      padding: theme.spacing(3, 4),
      '&:hover,&.Mui-focused': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
}))

export function StyledAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  ...props
}: CustomAutoCompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const theme = useTheme()
  return (
    <Autocomplete
      disablePortal
      PaperComponent={StyledPaper}
      popupIcon={<ArrowDownIcon></ArrowDownIcon>}
      clearIcon={<CloseCircleIcon htmlColor={theme.palette.text.light} />}
      {...props}
    />
  )
}
