import { CommonData, PageTypes, ProductForAnalyticsFields } from '../interfaces'
import { PAGE_TYPES } from '../pageTypes'

/**
 * Restricted fields for each page type.
 */
export const RESTRICTED_PRODUCT_FIELDS: Partial<
  Record<PageTypes, Array<keyof ProductForAnalyticsFields>>
> = {
  [PAGE_TYPES.PDP]: ['Units', 'Warranty'],
}

export const RESTRICTED_PRODUCT_FIELDS_BY_EVENT: Record<
  string,
  Array<keyof ProductForAnalyticsFields> | undefined
> = {
  'Prods-Delete': [
    'Status',
    'Brand',
    'Price',
    'PriceFull',
    'Sku',
    'ModelName',
    'LensTechnology',
    'FrameTechnology',
    'Shape',
    'Visibility',
    'Units',
    'Warranty',
    'Preorder',
  ],
}

/**
 * Restricted common fields for each event.
 */
export const RESTRICTED_COMMON_FIELDS_BY_EVENT: Record<
  string,
  Array<keyof CommonData>
> = {
  Login: ['Page_Brand'],
}

export const ALLOWED_PAGES_BY_PRODUCT_FIELDS: Partial<
  Record<keyof ProductForAnalyticsFields, PageTypes[]>
> = {
  TaxRate: [PAGE_TYPES.PAYMENT, PAGE_TYPES.THANK_YOU],
}
