import {
  TrendingProductsContainer,
  TrendingProductsTitle,
} from './TrendingProducts.style'

import { ProductTileAlgolia, ProductTileOld } from '@components/ProductTile'
import React from 'react'
import { Product } from '../../../../../types/product'
import { useTranslation } from 'react-i18next'
import { ProductAlgolia } from '../../../../../types/ProductAlgolia/ProductAlgolia'

type Props = {
  products: Product[] | ProductAlgolia[]
  isProductAlgolia?: boolean
}
const ProductsResult: React.FC<Props> = ({ products, isProductAlgolia }) => {
  const { t } = useTranslation()

  return (
    <>
      <TrendingProductsTitle>{t('search.trendingNow')}</TrendingProductsTitle>
      <TrendingProductsContainer>
        {products?.map((product, index) => (
          <React.Fragment key={`${product?.partNumber}_${index}`}>
            {isProductAlgolia ? (
              <ProductTileAlgolia
                product={product}
                tileIndex={index}
                key={`productTile-${product?.partNumber}-${index}`}
              />
            ) : (
              <ProductTileOld
                isClustered={true}
                product={product}
                tileIndex={index}
                variant={'plp'}
                isTrendingProduct={true}
              />
            )}
          </React.Fragment>
        ))}
      </TrendingProductsContainer>
    </>
  )
}

export default ProductsResult
