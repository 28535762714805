import isDevice from 'ismobilejs'

const useIsDevice = (navigator: Navigator) => {
  const isDeviceResult = isDevice(navigator)

  return {
    isMobileDevice: isDeviceResult.phone,
    isTabletDevice: isDeviceResult.tablet,
    isDesktopDevice: !isDeviceResult.phone && !isDeviceResult.tablet,
  }
}

export default useIsDevice
