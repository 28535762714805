import { ModalDialog, ModalDialogTitle } from '../UI'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SignInFormDialog from './components/SignInFormDialog'
import { openModalSignInSelector } from '../../features/ui/selector'
import { setOpenModalSignIn } from '../../features/ui/action'

import { useTranslation } from 'react-i18next'
import RegistrationFormDialog from '@components/RegistrationDialog/components/RegistrationFormDialog'
import {
  SignInFormDialogCTAContainer,
  SignInFormDialogAuthCTA,
} from '@components/SignInDialog/components/SignInFormDialog.style'

const SIGN_IN = 'SignIn'
const REGISTRATION = 'Registration'

const SignInDialog: React.FC = () => {
  const [activeForm, setActiveForm] = useState(SIGN_IN)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const open = useSelector(openModalSignInSelector)

  const handleClose = () => {
    dispatch(setOpenModalSignIn(false))
    setActiveForm(SIGN_IN)
  }

  const showSignInForm = () => setActiveForm(SIGN_IN)
  const showRegistrationForm = () => setActiveForm(REGISTRATION)

  return (
    <ModalDialog
      open={open}
      onClose={handleClose}
      onCloseButton={handleClose}
      widthSize={'md'}
    >
      {activeForm === SIGN_IN ? (
        <>
          <ModalDialogTitle>
            {t('SignInPage.TitleInYouAccount')}
          </ModalDialogTitle>
          <SignInFormDialog />
          <SignInFormDialogCTAContainer>
            {t('SignInPage.DontYouHaveAnAccount')}
            {'\u00A0'}
            <SignInFormDialogAuthCTA onClick={showRegistrationForm}>
              {t('SignInPage.CTA.SignUp')}
            </SignInFormDialogAuthCTA>
          </SignInFormDialogCTAContainer>
        </>
      ) : (
        <>
          <ModalDialogTitle>{t('RegistrationLayout.Title')}</ModalDialogTitle>
          <RegistrationFormDialog />
          <SignInFormDialogCTAContainer>
            {t('SignInPage.AlreadyRegistered')}
            {'\u00A0'}
            <SignInFormDialogAuthCTA onClick={showSignInForm}>
              {t('SignInPage.CTA.SignIn')}
            </SignInFormDialogAuthCTA>
          </SignInFormDialogCTAContainer>
        </>
      )}
    </ModalDialog>
  )
}

export default SignInDialog
