import { styled } from '@mui/material/styles'

export const OurShopsContainer = styled('div', {
  name: 'StoreDetails',
  slot: 'OurShopsContainer',
})(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column-reverse',
  fontFamily: theme.typography.fontFamily,
  //margin: theme.spacing(8, 0),
  gap: theme.spacing(4),
  //padding: theme.spacing(4, 0),

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))

export const OurShopsLeftColumn = styled('div', {
  name: 'StoreDetails',
  slot: 'OurShopsLeftColumn',
})(({ theme }) => ({
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  fontFamily: theme.typography.fontFamily,

  [theme.breakpoints.up('md')]: {
    width: '60%',
  },
}))

export const OurShopsRightColumn = styled('div', {
  name: 'StoreDetails',
  slot: 'OurShopsRightColumn',
})(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: theme.typography.fontFamily,

  [theme.breakpoints.up('md')]: {
    width: '30%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
}))

export const OurShopsTitle = styled('span', {
  name: 'StoreDetails',
  slot: 'OurShopsTitle',
})(({ theme }) => ({
  fontSize: 28,
  fontWeight: 600,
  color: theme.palette.text.dark,
  marginBottom: theme.spacing(4),
}))

export const OurShopsDescription = styled('span', {
  name: 'StoreDetails',
  slot: 'OurShopsDescription',
})(({ theme }) => ({
  fontSize: 16,
  fontWeight: 300,
  color: theme.palette.text.dark,
}))

export const OurShopsImageContainer = styled('div', {
  name: 'StoreDetails',
  slot: 'OurShopsImageContainer',
})(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const OurShopsImage = styled('img', {
  name: 'StoreDetails',
  slot: 'OurShopsImage',
})(() => ({
  objectFit: 'cover',
  width: '100%',
}))
