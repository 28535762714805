import { Z_INDEX_LV3, Z_INDEX_LV5 } from '../../constants/ui'
import { styled } from '@mui/material/styles'

export const WrapperHeader = styled('header', {
  shouldForwardProp: (prop) => prop !== 'isSticky',
  name: 'Header',
  slot: 'Wrapper',
})<{ isSticky: boolean | null }>(({ theme, isSticky }) => ({
  fontFamily: theme.typography.fontFamily,
  position: 'sticky',
  visibility: isSticky || isSticky === null ? 'visible' : 'hidden',
  top: 0,
  left: 0,
  zIndex: Z_INDEX_LV5,
  transform:
    isSticky || isSticky === null ? 'translateY(0%)' : 'translateY(-100%)',
  transition: `all ${theme.transitions.duration.standard}ms ease-in-out`,
}))

export const BenefitContainer = styled('div', {
  name: 'Header',
  slot: 'BenefitContainer',
})(() => ({}))

export const HamburgerMenuGreyBackground = styled('div', {
  name: 'HamburgerMenu',
  slot: 'OpacityLayer',
})(({ theme }) => ({
  zIndex: Z_INDEX_LV3,
  width: '100vw',
  height: '100vh',
  position: 'absolute',
  background: theme.palette.common.black,
  opacity: 0.5,
  top: 0,
}))
