import { useStoreIdentity } from '../foundation/hooks/useStoreIdentity'
import { useGetShipModesQuery } from '../features/order/query'
import { useSelector } from 'react-redux'
import { cartSelector } from '../features/order/selector'
import { IOrderDetails, OrderItem } from '../types/order'
import GetShippingModesUtils from '@utils/GetShippingModesUtils'
import { availableShippingMethodsSelector } from '../redux/selectors/site'
import { useCallback, useMemo } from 'react'

const useLiveStock = (orderDetails?: IOrderDetails) => {
  const { langCode } = useStoreIdentity()
  const orderDetailsData = orderDetails || useSelector(cartSelector)

  const availableShippingModes = useSelector(availableShippingMethodsSelector)
  const currentOrderItem = orderDetailsData?.orderItem?.find(
    (orderItem) => !!orderItem
  )!
  const currentShipModeCode = currentOrderItem?.shipModeCode
  const {
    data = [],
    refetch,
    isLoading,
  } = useGetShipModesQuery(
    {
      orderId: orderDetailsData?.orderId,
    },
    { skip: !orderDetailsData?.orderId }
  )
  const shippingMethods = useMemo(
    () =>
      GetShippingModesUtils.getShippingMethods(data, availableShippingModes),
    [data, availableShippingModes]
  )

  const getEstimatedDeliveryDateClOrCla = useMemo(() => {
    const filterClOrClaGetShipModes =
      GetShippingModesUtils.filterClOrClaGetShipModes(data)

    if (!filterClOrClaGetShipModes.length) return ''
    const getCurrentShippingModesClOrCla = filterClOrClaGetShipModes
      .map((item) => {
        return item?.dedicatedShippingMode?.find(
          (item) => item.shipModeCode === currentShipModeCode
        )
      })
      .filter((item) => !!item)

    if (!getCurrentShippingModesClOrCla.length) return ''
    const getMaxLeadTimeShippingMode = getCurrentShippingModesClOrCla?.reduce(
      (prev, current) => {
        return Number(prev?.maxLeadTime!) > Number(current?.maxLeadTime!)
          ? prev
          : current
      }
    )

    return (
      GetShippingModesUtils.getEstimatedDeliveryDate(
        getMaxLeadTimeShippingMode!,
        langCode
      ).estimatedDeliveryDate || ''
    )
  }, [currentShipModeCode, data])

  const getEstimatedDeliveryDateFrames = useCallback(
    (productId: OrderItem['xitem_product_id']) => {
      const getShippingMode = data.find((item) => item.catentryId === productId)

      const estimatedDeliveryDateLiveStock =
        getShippingMode?.dedicatedShippingMode.map((item) =>
          GetShippingModesUtils.getEstimatedDeliveryDate(item, langCode)
        ) || []

      return (
        estimatedDeliveryDateLiveStock?.find(
          (item) => item.shipModeCode === currentShipModeCode
        )?.estimatedDeliveryDate || ''
      )
    },
    [currentShipModeCode, data]
  )

  return {
    getEstimatedDeliveryDateFrames,
    getEstimatedDeliveryDateClOrCla,
    shippingMethods,
    refetchShippingModes: refetch,
    isLoadingShippingModes: isLoading,
  }
}
export default useLiveStock
