//Redux
import * as ACTIONS from '../../action-types/user'

import {
  EXPIRED_PASSWORD_PAGE_ERROR,
  WC_PREVIEW_TOKEN,
} from '../../../foundation/constants/common'
import {
  FETCH_USER_DETAILS_PENDING_ACTION,
  FETCH_USER_DETAILS_SUCCESS_ACTION,
  GUEST_LOGIN_SUCCESS_ACTION,
  INIT_USER_FROM_STORAGE_SUCCESS_ACTION,
  LOGIN_SUCCESS_ACTION,
  LOGON_AND_CHANGE_PASSWORD_FAIL_ACTION,
  LOGOUT_SUCCESS_ACTION,
  REGISTRATION_PENDING_ACTION,
  REGISTRATION_SUCCESS_ACTION,
  SESSION_ERROR_LOGIN_ERROR_ACTION,
} from '../../actions/user'
//Standard libraries
import { call, put, select } from 'redux-saga/effects'
import {
  localStorageUtil,
  storageSessionHandler,
} from '../../../foundation/utils/storageUtil'
import { sendRegistrationEvent } from '../../../foundation/analytics/tealium/lib'

import { AppPayloadWithWidgetAction } from '../../store'
import Log from '../../../services/Log'
//Foundation libraries
import { PERSONALIZATION_ID } from '../../../foundation/constants/user'
import { USER_CONTEXT_REQUEST_ACTION } from '../../actions/context'
import loginIdentity from '../../../foundation/apis/transaction/loginIdentity.service'
import personService from '../../../foundation/apis/transaction/person.service'
import { userLastUpdatedSelector } from '../../selectors/user'
import { AxiosResponse } from 'axios'
import { PersonResponse } from '../../../types/user'
import get from 'lodash/get'
import { createAnalyticsDataSelector } from 'src/foundation/analytics/selectors/analyticsData'
import { languageSelector } from 'src/redux/selectors/site'
import { navigate } from '@components/GlobalNavigate/GlobalNavigate'
import { ACCOUNT, ACCOUNT_CHILDREN, CART } from 'src/constants/routes'
import { generatePath } from 'react-router'
import { getCart } from './cart'
import { Cart } from 'src/types/order'
import { accountApi } from 'src/features/account/query'

const analyticsDataSelector = createAnalyticsDataSelector()

/**
 *
 */
function* loginAndFetchDetail(payload: any) {
  const response = yield call(loginIdentity.login, payload)
  let loginPayload = response.data
  if (payload?.widget) {
    loginPayload['widget'] = payload.widget
  }

  yield put(FETCH_USER_DETAILS_PENDING_ACTION())
  yield put(LOGIN_SUCCESS_ACTION(loginPayload))

  const response2: AxiosResponse<PersonResponse> = yield call(
    personService.findPersonBySelf,
    {
      widget: payload.widget,
    }
  )
  let loginPayload2 = response2.data

  if (!payload.preventRedirect) {
    const cart: Cart = yield call(getCart)
    const cartItems = cart.orderItem
    const langCode = yield select(languageSelector)
    navigate(
      generatePath(`/:country/${cartItems.length ? CART : ACCOUNT}`, {
        page: cartItems.length ? null : ACCOUNT_CHILDREN.DASHBOARD,
        country: langCode.toLowerCase().replace('_', '-'),
      })
    )
  }

  yield put(FETCH_USER_DETAILS_SUCCESS_ACTION(loginPayload2))
}

const preProcessLogonAndChangePasswordError = (error: any) => {
  if (
    error?.isAxiosError &&
    error.response?.data?.errors &&
    error.response.data.errors[0]
  ) {
    return {
      ...error.response.data.errors[0],
      [EXPIRED_PASSWORD_PAGE_ERROR]: true,
    }
  } else {
    return {
      errorMessage: error.toLocaleString(),
      [EXPIRED_PASSWORD_PAGE_ERROR]: true,
    }
  }
}

/**
 *
 */
export function* logonAndChangePassword(action: any) {
  try {
    const payload = action.payload
    yield* loginAndFetchDetail(payload)
  } catch (error) {
    yield put(
      LOGON_AND_CHANGE_PASSWORD_FAIL_ACTION(
        preProcessLogonAndChangePasswordError(error)
      )
    )
  }
}

/**
 *
 */
export function* login({ payload }: any) {
  try {
    yield* loginAndFetchDetail(payload)
  } catch (error) {
    yield put({ type: ACTIONS.LOGIN_ERROR, error })
  }
}

/**
 *
 */
export function* sessionErrorReLogin(action: any) {
  try {
    const payload = action.payload
    storageSessionHandler.removeCurrentUser()
    yield* loginAndFetchDetail(payload)
  } catch (error: any) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors &&
      error.response.data.errors[0]
    ) {
      yield put(SESSION_ERROR_LOGIN_ERROR_ACTION(error.response.data.errors[0]))
    }
  }
}

export function* fetchUserDetails(action: AppPayloadWithWidgetAction) {
  try {
    const payload = action.payload

    const userDetailsResponse: AxiosResponse<PersonResponse> = yield call(
      personService.findPersonBySelf,
      payload
    )

    const addressList = userDetailsResponse.data?.contact?.filter((contact) =>
      ['shipping'].includes(contact.addressType.toLowerCase())
    )
    const defaultAddress = addressList?.filter(
      (contact) => contact.primary === 'true'
    )

    if (
      defaultAddress?.length === 0 &&
      addressList?.length &&
      addressList?.length > 0
    ) {
      const newDefaultAddress = { ...addressList[0], primary: 'true' }

      const updatePerson = yield put(
        accountApi.endpoints.updatePersonContact.initiate({
          nickName: newDefaultAddress.nickName!,
          addressData: newDefaultAddress,
        })
      )

      // Wait for getCartResult to finish
      yield updatePerson
      const updatedContactListResponse: AxiosResponse<PersonResponse> =
        yield call(personService.findPersonBySelf, payload)
      yield put(
        FETCH_USER_DETAILS_SUCCESS_ACTION(updatedContactListResponse.data)
      )
    } else {
      yield put(FETCH_USER_DETAILS_SUCCESS_ACTION(userDetailsResponse.data))
    }
  } catch (error) {
    Log.error('error: ' + error)
  }
}

/**
 *
 */
export function* logout(action: any) {
  const payload = action.payload
  try {
    yield call(loginIdentity.logout, payload)
    yield put(LOGOUT_SUCCESS_ACTION(payload))
  } catch (error) {
    yield put({ type: ACTIONS.LOGOUT_ERROR, error })
    //still need to clear user token, event though logout fail to avoid infinite loop
    yield put(LOGOUT_SUCCESS_ACTION(payload))
  }
}

/**
 *
 */
export function* registration(action: any) {
  try {
    yield put(REGISTRATION_PENDING_ACTION())
    const payload = action.payload
    const response = yield call(personService.registerPerson, payload)
    let registrationPayload = response.data
    if (payload?.widget) {
      registrationPayload['widget'] = payload.widget
    }
    yield put(REGISTRATION_SUCCESS_ACTION(registrationPayload))
    const acceptedNewsletterFlag = get(
      payload.body,
      'x_optIn.x_newsLetter',
      false
    )

    const response2 = yield call(personService.findPersonBySelf, {
      widget: payload.widget,
    })

    let registrationPayload2 = response2.data
    yield put(FETCH_USER_DETAILS_SUCCESS_ACTION(registrationPayload2))

    sendRegistrationEvent(
      payload.body.email1,
      yield select(analyticsDataSelector),
      acceptedNewsletterFlag
    )
  } catch (error) {
    yield put({ type: ACTIONS.REGISTRATION_ERROR, error })
  }
}

/**
 *
 */
export function* initStateFromStorage(action: AppPayloadWithWidgetAction) {
  Log.info('INIT STATE FROM STORAGE')
  try {
    let currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()
    if (currentUser === null) {
      // if we have both previewtoken and newPreviewSession, the current user is removed in inistates.ts
      // then we should get new personalizationID from preview session
      const previewToken = storageSessionHandler.getPreviewToken()
      if (!previewToken || !previewToken[WC_PREVIEW_TOKEN]) {
        let personalizationID = localStorageUtil.get(PERSONALIZATION_ID)
        if (personalizationID !== null) {
          currentUser = { personalizationID }
        }
      }
    }
    yield put(INIT_USER_FROM_STORAGE_SUCCESS_ACTION(currentUser))
    if (currentUser && currentUser.WCToken) {
      const response2 = yield call(personService.findPersonBySelf, {
        ...action.payload,
      })
      let loginPayload2 = response2.data
      yield put(FETCH_USER_DETAILS_SUCCESS_ACTION(loginPayload2))
      yield put(USER_CONTEXT_REQUEST_ACTION({ ...action.payload }))
    }
  } catch (e) {
    Log.error('error: ' + e)
  }
}

/**
 *
 */
export function* updateStateFromStorage() {
  try {
    let currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()
    if (currentUser && currentUser.forUserId) {
      return
    }
    const userLastUpdated = yield select(userLastUpdatedSelector)
    if (
      currentUser &&
      currentUser.lastUpdated &&
      (!userLastUpdated || userLastUpdated < currentUser.lastUpdated)
    ) {
      yield put(INIT_USER_FROM_STORAGE_SUCCESS_ACTION(currentUser))
      if (currentUser.isGuest) {
        yield put(GUEST_LOGIN_SUCCESS_ACTION(null))
      } else {
        yield put(LOGIN_SUCCESS_ACTION(null))
      }
    }
  } catch (e) {
    Log.error('error: ' + e)
  }
}
