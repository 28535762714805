import React from 'react'
import {
  ICON_ADDRESS,
  ICON_AFTER_CARE,
  ICON_ANTI_STATIC_COATING,
  ICON_BABY_SMILE,
  ICON_BETTER_CLARITY,
  ICON_CALENDAR,
  ICON_CAT_EYE_GLASSES_FILLED,
  ICON_CHAT,
  ICON_CHECKMARK_ROUNDED,
  ICON_CHOOSE,
  ICON_CLOCK,
  ICON_CONTACT_LENS_FINGER,
  ICON_CONTACT_LENS_SPARK,
  ICON_CONTACT_LENS,
  ICON_CREDIT_CARD,
  ICON_DROP,
  ICON_EYE_CARE,
  ICON_EYE,
  ICON_EYEWEAR_RANGE,
  ICON_FACE_GLASSES_SMILE,
  ICON_FACE_OVAL_THICK,
  ICON_FACE_OVAL,
  ICON_FACE_RECTANGLE_SLIM,
  ICON_FACE_RECTANGLE_THICK,
  ICON_FACE_RECTANGLE,
  ICON_FACE_ROUND_THICK,
  ICON_FACE_ROUND,
  ICON_FACE_SCAN,
  ICON_FACE_SLIM,
  ICON_FACE_SQUARE,
  ICON_FACE_TRIANGLE,
  ICON_FRAME_ADVISOR,
  ICON_FRAME_FRAMED,
  ICON_FREE_RETURN,
  ICON_GLASSES_CASE,
  ICON_GLASSES,
  ICON_GLOBAL_CARE,
  ICON_GREEN_SHIPPING,
  ICON_HEART_EMPTY,
  ICON_HEART_FILLED,
  ICON_INCLINATION_ALIGNMENT,
  ICON_INFO,
  ICON_LENS_CLEANER,
  ICON_LENS_GLASS,
  ICON_LUXURY_GLASSES_PILOT,
  ICON_LUXURY_GLASSES,
  ICON_MAGNIFYING_GLASS,
  ICON_MASK,
  ICON_MOBILE,
  ICON_OAKLEY_GLASS,
  ICON_OFFER,
  ICON_OPTICIAN,
  ICON_OPTOMETRIST,
  ICON_PADLOCK,
  ICON_PILOT_DARK_GLASSES,
  ICON_PILOT_GLASSES_METER,
  ICON_PRESCRIPTION,
  ICON_PRICE_TAG,
  ICON_QUESTION,
  ICON_RAYBAN_GLASS,
  ICON_REPLACE,
  ICON_RETINAL_SCREENING,
  ICON_ROUND_GLASSES,
  ICON_SEARCH,
  ICON_SHIELD_CHECKMARK,
  ICON_SHOPPING,
  ICON_SMILE,
  ICON_SMOOTH_RECTANGLE_GLASSES,
  ICON_SOCIAL_DISTANCE,
  ICON_STORE_MANAGER,
  ICON_STORES,
  ICON_TEMPLE_SIZE,
  ICON_TEST,
  ICON_TRIANGLE_DANGER,
  ICON_UV_PROTECTION,
  ICON_KLARNA,
  ICON_ONESIGHT,
  ICON_SHIPPING_TRUCK,
  ICON_PRESCRIPTION_PAPER,
  ICON_GROUP_PEOPLE,
  ICON_HAND_GLASSES,
  ICON_SHOPPING_TO,
  ICON_QUESTION_THIN,
  ICON_PHONE_RINGING,
  ICON_PRESCRIPTION_PEN,
  ICON_CHAT_FILLED,
  ICON_CREDIT_CARD_LOCK,
  ICON_CHECKMARK_THIN,
} from '../../../../constants/ui'
import {
  AddressIcon,
  AfterCareIcon,
  AntiStaticCoatingIcon,
  BabySmileIcon,
  BetterClarityIcon,
  BlankIcon,
  CalendarIcon,
  CatEyeGlassesFilledIcon,
  ChatFilledIcon,
  ChatIcon,
  CheckmarkOutlinedIcon,
  CheckmarkThinIcon,
  ChooseIcon,
  ClockIcon,
  ContactLensFingerIcon,
  ContactLensIcon,
  ContactLensSparkIcon,
  CreditCardIcon,
  CreditCardLockIcon,
  DropIcon,
  EyeCareIcon,
  EyeIcon,
  EyewearRangeIcon,
  FaceGlassesSmileIcon,
  FaceOvalIcon,
  FaceOvalIconThick,
  FaceRectangleIcon,
  FaceRectangleIconSlim,
  FaceRectangleIconThick,
  FaceRoundIcon,
  FaceRoundIconThick,
  FaceScanIcon,
  FaceSlimIcon,
  FaceSquareIcon,
  FaceTriangleIcon,
  FrameAdvisorIcon,
  FramedFramesIcon,
  FreeReturnIcon,
  GlassesCase,
  GlassesIcon,
  GlobalCareIcon,
  GreenShippingIcon,
  GroupPeopleIcon,
  HandGlassesIcon,
  HeartIcon,
  HeartIconSlim,
  InclinationAlignmentIcon,
  InfoIcon,
  KlarnaBigIcon,
  LensCleanerIcon,
  LensGlassesIcon,
  LensGlassIcon,
  LuxuryGlassesIcon,
  LuxuryGlassesPilotIcon,
  MagnifyingGlassIcon,
  MaskIcon,
  MobileIcon,
  OakleyGlassIcon,
  OfferIcon,
  OnesightIcon,
  OpticianIcon,
  OptometristIcon,
  PadlockIcon,
  PhoneRingingIcon,
  PilotDarkGlassesIcon,
  PilotGlassesMeterIcon,
  PrescriptionIcon,
  PrescriptionPaperIcon,
  PrescriptionPenIcon,
  PriceTagIcon,
  QuestionMarkThinIcon,
  RayBanGlassIcon,
  ReplaceIcon,
  RetinalScreeningIcon,
  RoundGlassesIcon,
  SearchIcon,
  ShieldWithCheckMarkIcon,
  ShippingTruckIcon,
  ShoppingIcon,
  ShoppingToIcon,
  SmileIcon,
  SmoothRectangleGlassesIcon,
  SocialDistanceIcon,
  StoreManagerIcon,
  StoresIcon,
  TempleSizeIcon,
  TestIcon,
  TriangleDangerIcon,
  UVProtectionIcon,
} from '../../../UI/Icons'

interface ICmsIcon {
  teaserIcon: string
  height?: number
  width?: number
  htmlColor?: string
}

const CmsIcon: React.FC<ICmsIcon> = ({
  teaserIcon,
  height,
  width,
  htmlColor,
}) => {
  const setIcon = {
    height,
    width,
    htmlColor,
  }
  switch (teaserIcon) {
    case ICON_ADDRESS:
      return <AddressIcon {...setIcon} />
    case ICON_AFTER_CARE:
      return <AfterCareIcon {...setIcon} />
    case ICON_ANTI_STATIC_COATING:
      return <AntiStaticCoatingIcon {...setIcon} />
    case ICON_BABY_SMILE:
      return <BabySmileIcon {...setIcon} />
    case ICON_BETTER_CLARITY:
      return <BetterClarityIcon {...setIcon} />
    case ICON_CALENDAR:
      return <CalendarIcon {...setIcon} />
    case ICON_CAT_EYE_GLASSES_FILLED:
      return <CatEyeGlassesFilledIcon {...setIcon} />
    case ICON_CHAT:
      return <ChatIcon {...setIcon} />
    case ICON_CHECKMARK_ROUNDED:
      return <CheckmarkOutlinedIcon {...setIcon} />
    case ICON_CHECKMARK_THIN:
      return <CheckmarkThinIcon {...setIcon} />
    case ICON_CHOOSE:
      return <ChooseIcon {...setIcon} />
    case ICON_CLOCK:
      return <ClockIcon {...setIcon} />
    case ICON_CONTACT_LENS:
      return <ContactLensIcon {...setIcon} />
    case ICON_CONTACT_LENS_FINGER:
      return <ContactLensFingerIcon {...setIcon} />
    case ICON_CONTACT_LENS_SPARK:
      return <ContactLensSparkIcon {...setIcon} />
    case ICON_CREDIT_CARD:
      return <CreditCardIcon {...setIcon} />
    case ICON_CREDIT_CARD_LOCK:
      return <CreditCardLockIcon {...setIcon} />
    case ICON_DROP:
      return <DropIcon {...setIcon} />
    case ICON_EYE_CARE:
      return <EyeCareIcon {...setIcon} />
    case ICON_EYE:
      return <EyeIcon {...setIcon} />
    case ICON_EYEWEAR_RANGE:
      return <EyewearRangeIcon {...setIcon} />
    case ICON_FACE_GLASSES_SMILE:
      return <FaceGlassesSmileIcon {...setIcon} />
    case ICON_FACE_OVAL_THICK:
      return <FaceOvalIconThick {...setIcon} />
    case ICON_FACE_OVAL:
      return <FaceOvalIcon {...setIcon} />
    case ICON_FACE_RECTANGLE_SLIM:
      return <FaceRectangleIconSlim {...setIcon} />
    case ICON_FACE_RECTANGLE_THICK:
      return <FaceRectangleIconThick {...setIcon} />
    case ICON_FACE_RECTANGLE:
      return <FaceRectangleIcon {...setIcon} />
    case ICON_FACE_ROUND_THICK:
      return <FaceRoundIconThick {...setIcon} />
    case ICON_FACE_ROUND:
      return <FaceRoundIcon {...setIcon} />
    case ICON_FACE_SCAN:
      return <FaceScanIcon {...setIcon} />
    case ICON_FACE_SLIM:
      return <FaceSlimIcon {...setIcon} />
    case ICON_FACE_SQUARE:
      return <FaceSquareIcon {...setIcon} />
    case ICON_FACE_TRIANGLE:
      return <FaceTriangleIcon {...setIcon} />
    case ICON_FRAME_ADVISOR:
      return <FrameAdvisorIcon {...setIcon} />
    case ICON_FRAME_FRAMED:
      return <FramedFramesIcon {...setIcon} />
    case ICON_FREE_RETURN:
      return <FreeReturnIcon {...setIcon} />
    case ICON_GLASSES_CASE:
      return <GlassesCase {...setIcon} />
    case ICON_GLASSES:
      return <GlassesIcon {...setIcon} />
    case ICON_GLOBAL_CARE:
      return <GlobalCareIcon {...setIcon} />
    case ICON_GREEN_SHIPPING:
      return <GreenShippingIcon {...setIcon} />
    case ICON_GROUP_PEOPLE:
      return <GroupPeopleIcon {...setIcon} />
    case ICON_HAND_GLASSES:
      return <HandGlassesIcon {...setIcon} />
    case ICON_HEART_EMPTY:
      return <HeartIconSlim {...setIcon} />
    case ICON_HEART_FILLED:
      return <HeartIcon {...setIcon} />
    case ICON_INCLINATION_ALIGNMENT:
      return <InclinationAlignmentIcon {...setIcon} />
    case ICON_INFO:
      return <InfoIcon {...setIcon} />
    case ICON_KLARNA:
      return <KlarnaBigIcon {...setIcon} />
    case ICON_LENS_CLEANER:
      return <LensCleanerIcon {...setIcon} />
    case ICON_LENS_GLASS:
      return <LensGlassIcon {...setIcon} />
    case ICON_LUXURY_GLASSES:
      return <LuxuryGlassesIcon {...setIcon} />
    case ICON_LUXURY_GLASSES_PILOT:
      return <LuxuryGlassesPilotIcon {...setIcon} />
    case ICON_MAGNIFYING_GLASS:
      return <MagnifyingGlassIcon {...setIcon} />
    case ICON_MASK:
      return <MaskIcon {...setIcon} />
    case ICON_MOBILE:
      return <MobileIcon {...setIcon} />
    case ICON_OAKLEY_GLASS:
      return <OakleyGlassIcon {...setIcon} />
    case ICON_OFFER:
      return <OfferIcon {...setIcon} />
    case ICON_ONESIGHT:
      return <OnesightIcon {...setIcon} />
    case ICON_OPTICIAN:
      return <OpticianIcon {...setIcon} />
    case ICON_OPTOMETRIST:
      return <OptometristIcon {...setIcon} />
    case ICON_PADLOCK:
      return <PadlockIcon {...setIcon} />
    case ICON_PHONE_RINGING:
      return <PhoneRingingIcon {...setIcon} />
    case ICON_PILOT_DARK_GLASSES:
      return <PilotDarkGlassesIcon {...setIcon} />
    case ICON_PILOT_GLASSES_METER:
      return <PilotGlassesMeterIcon {...setIcon} />
    case ICON_PRESCRIPTION:
      return <PrescriptionIcon {...setIcon} />
    case ICON_PRESCRIPTION_PAPER:
      return <PrescriptionPaperIcon {...setIcon} />
    case ICON_PRESCRIPTION_PEN:
      return <PrescriptionPenIcon {...setIcon} />
    case ICON_PRICE_TAG:
      return <PriceTagIcon {...setIcon} />
    case ICON_QUESTION:
      return <LensGlassesIcon {...setIcon} />
    case ICON_QUESTION_THIN:
      return <QuestionMarkThinIcon {...setIcon} />
    case ICON_RAYBAN_GLASS:
      return <RayBanGlassIcon {...setIcon} />
    case ICON_REPLACE:
      return <ReplaceIcon {...setIcon} />
    case ICON_RETINAL_SCREENING:
      return <RetinalScreeningIcon {...setIcon} />
    case ICON_ROUND_GLASSES:
      return <RoundGlassesIcon {...setIcon} />
    case ICON_SEARCH:
      return <SearchIcon {...setIcon} />
    case ICON_SHIELD_CHECKMARK:
      return <ShieldWithCheckMarkIcon {...setIcon} />
    case ICON_SHIPPING_TRUCK:
      return <ShippingTruckIcon {...setIcon} />
    case ICON_SHOPPING:
      return <ShoppingIcon {...setIcon} />
    case ICON_SHOPPING_TO:
      return <ShoppingToIcon {...setIcon} />
    case ICON_SMILE:
      return <SmileIcon {...setIcon} />
    case ICON_SMOOTH_RECTANGLE_GLASSES:
      return <SmoothRectangleGlassesIcon {...setIcon} />
    case ICON_SOCIAL_DISTANCE:
      return <SocialDistanceIcon {...setIcon} />
    case ICON_STORE_MANAGER:
      return <StoreManagerIcon {...setIcon} />
    case ICON_STORES:
      return <StoresIcon {...setIcon} />
    case ICON_TEMPLE_SIZE:
      return <TempleSizeIcon {...setIcon} />
    case ICON_TEST:
      return <TestIcon {...setIcon} />
    case ICON_TRIANGLE_DANGER:
      return <TriangleDangerIcon {...setIcon} />
    case ICON_UV_PROTECTION:
      return <UVProtectionIcon {...setIcon} />
    case ICON_CHAT_FILLED:
      return <ChatFilledIcon {...setIcon} />
    default:
      const defaultCaseToReturn =
        teaserIcon === '' ? null : (
          <BlankIcon {...setIcon} className={teaserIcon} />
        )
      return defaultCaseToReturn
  }
}

export default CmsIcon
