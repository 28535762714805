import { styled } from '@mui/material/styles'
import { StyledTypography } from '@components/UI'

export const StoreDetailsContainer = styled('div', {
  name: 'StoreDetails',
  slot: 'StoreDetailsContainer',
})(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  fontFamily: theme.typography.fontFamily,
  padding: theme.spacing(10, 4),

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(16),
  },
}))

export const StoreDetailsTitle = styled(StyledTypography, {
  name: 'StoreDetails',
  slot: 'StoreDetailsTitle',
})(({ theme }) => ({
  margin: theme.spacing(4),
  fontSize: 32,
  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(8, 16),
  },
}))

export const StyledSeparator = styled('hr', {
  name: 'StoreDetails',
  slot: 'Separator',
})(({ theme }) => ({
  height: 1,
  margin: theme.spacing(0, 4),
  border: 'none',
  alignSelf: 'stretch',
  flexGrow: 0,
  backgroundColor: theme.palette.color.grey.light,

  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(0, 16),
  },
}))
