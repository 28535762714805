import {
  ADDRESS_BILLING,
  ADDRESS_BOOK,
  ADDRESS_SHIPPING,
  ADDRESS_SHIPPING_BILLING,
} from '../constants/common'
import {
  googleApiEnabledSelector,
  googleApiKeySelector,
} from '../redux/selectors/site'
import { useEffect, useState } from 'react'

import { ACCOUNT_CHILDREN } from '../constants/routes'
import { StatesProvinces } from '../types/common'
import { countriesListSelector } from '../redux/selectors/account'
import { useSelector } from 'react-redux'
import { useStoreIdentity } from '../foundation/hooks/useStoreIdentity'
import { loadGoogleMapsScript } from './useLoadGoogleMapsScript'

export const useGoogleSuggestions = (props: {
  addressType: string
  country: string
  page: string
  state: string
}) => {
  const storeCountry = useStoreIdentity().country

  const countryDefaultValue = props.country || storeCountry

  const [gmapLoaded, setGmapLoaded] = useState<boolean>(false)
  const [gmapCountry, setGmapCountry] = useState<string>(storeCountry)
  const [state, setState] = useState<string>('')
  const googleApiEnabled = useSelector(googleApiEnabledSelector)
  const googleApiKey = useSelector(googleApiKeySelector)
  const [googleAPISuggestionEnabled, setGoogleAPISuggestionEnabled] =
    useState<boolean>(false)
  const countriesList = useSelector(countriesListSelector)
  const [provinces, setProvinces] = useState<StatesProvinces[]>([])

  const onGoogleScriptLoaded = () => {
    setGmapLoaded(true)
  }

  useEffect(() => {
    if (!googleApiEnabled) {
      return
    }

    if (props.page === ADDRESS_BOOK) {
      if (googleApiEnabled.isEnabledOnAddressBook === 'true') {
        setGoogleAPISuggestionEnabled(true)
      }
    } else {
      if (
        ((props.addressType === ADDRESS_SHIPPING ||
          props.addressType === ADDRESS_SHIPPING_BILLING) &&
          googleApiEnabled.isEnabledOnShipping === 'true') ||
        (props.addressType === ADDRESS_BILLING &&
          googleApiEnabled.isEnabledOnBilling === 'true')
      ) {
        setGoogleAPISuggestionEnabled(true)
      }
    }
    if (props.page === ACCOUNT_CHILDREN.STORES) {
      setGoogleAPISuggestionEnabled(true)
    }
  }, [googleApiEnabled])

  useEffect(() => {
    if (googleAPISuggestionEnabled && googleApiKey) {
      if (!window.google) {
        loadGoogleMapsScript(googleApiKey, onGoogleScriptLoaded)
      } else {
        onGoogleScriptLoaded()
      }
    }
  }, [googleAPISuggestionEnabled])

  useEffect(() => {
    const addressFormDataCountry = props?.country || countryDefaultValue
    const gmapCountry = addressFormDataCountry || storeCountry

    setGmapCountry(gmapCountry)

    if (countriesList?.length) {
      const statesProvinces = countriesList?.find(
        (countryEl) => countryEl.code === addressFormDataCountry
      )?.states

      const firstProvinceOption =
        statesProvinces && statesProvinces?.length > 0
          ? statesProvinces[0]
          : null
      const stateProvinceDefaultValue = firstProvinceOption?.code || ''

      setState(stateProvinceDefaultValue)

      if (statesProvinces) {
        setProvinces(statesProvinces)
      }
    }
  }, [countriesList, props.country])

  return {
    gmapCountry,
    gmapLoaded,
    googleAPISuggestionEnabled,
    state,
    provinces,
  }
}
