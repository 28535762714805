import React from 'react'
//COMPONENTS
import { CheckoutProgress } from './components/CheckoutProgress'
import NavigationLogo from '../Header/components/NavigationBar/components/NavigationLogo'
import { ArrowLeftIcon } from '@components/UI/Icons'
//STYLES
import {
  ReducedHeaderWrapper,
  ReducedHeaderNavigationLogoWrapper,
  ReducedHeaderCheckoutDisclaimer,
  ReducedHeaderMobileWrapper,
  ReducedHeaderMobileBottom,
  ReducedHeaderMobileTop,
  ReducedHeaderMobileBack,
} from './reducedheader.style'
//HOOKS
import useBreakpoints from '../../hooks/useBreakpoints'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
//CONSTANTS
import { CART } from '../../constants/routes'

const ReducedHeader: React.FC = () => {
  const { isMobile } = useBreakpoints()
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      {isMobile ? (
        <ReducedHeaderMobileWrapper>
          <ReducedHeaderMobileTop>
            <ReducedHeaderMobileBack>
              <ArrowLeftIcon
                htmlColor={theme.palette.text.dark}
                onClick={() => navigate(CART)}
              />
            </ReducedHeaderMobileBack>
            <NavigationLogo />
            <ReducedHeaderCheckoutDisclaimer>
              <span>{t('ReducedHeader.Labels.SecureCheckout')}</span>
            </ReducedHeaderCheckoutDisclaimer>
          </ReducedHeaderMobileTop>
          <ReducedHeaderMobileBottom>
            <CheckoutProgress />
          </ReducedHeaderMobileBottom>
        </ReducedHeaderMobileWrapper>
      ) : (
        <ReducedHeaderWrapper>
          <ReducedHeaderNavigationLogoWrapper>
            <NavigationLogo />
          </ReducedHeaderNavigationLogoWrapper>
          <CheckoutProgress />
          <ReducedHeaderCheckoutDisclaimer>
            <span>{t('ReducedHeader.Labels.SecureCheckout')}</span>
          </ReducedHeaderCheckoutDisclaimer>
        </ReducedHeaderWrapper>
      )}
    </>
  )
}

export default ReducedHeader
