import { StyledAnchor } from '../../../UI'

import { styled } from '@mui/material/styles'

export const StyledSuggestedProductSubWrapper = styled('div', {
  name: 'SuggestedProductTile',
  slot: 'ProductSubWrapper',
})(({ theme }) => ({
  backgroundColor: theme.palette.color.grey.lightest,
  position: 'relative',
  cursor: 'pointer',
  '&::before': {
    content: '""',
    paddingTop: '50%',

    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
}))

export const StyledSuggestedProductAnchor = styled(StyledAnchor, {
  name: 'SuggestedProductTile',
  slot: 'ProductAnchor',
})(({ theme }) => ({
  backgroundColor: theme.palette.color.grey.lightest,
  width: '100%',
  justifyContent: 'center',
  '&::after': {
    height: '0 !important',
  },
}))

export const StyledSquare = styled('div', {
  name: 'SuggestedProductTile',
  slot: 'StyledSquare',
})(({ theme }) => ({
  justifyContent: 'center',

  [theme.breakpoints.up('sm')]: {
    backgroundColor: theme.palette.color.grey.lightest,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))

export const StyledSuggestedProductTileWrapper = styled('div', {
  name: 'SuggestedProductTile',
  slot: 'ProductTileWrapper',
})(({ theme }) => ({
  color: theme.palette.text.dark,
  fontSize: 12,
  fontWeight: 'normal',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    fontSize: 16,

    '&:hover': {
      '& > div': {
        visibility: 'visible',
      },
    },
  },

  '&.product-tile-ymal': {
    position: 'relative',
    aspectRatio: '1/1',

    '@supports not (aspect-ratio: 1 / 1)': {
      minHeight: 356,
    },
  },
}))

export const StyledSuggestedProductTileHeader = styled('div', {
  name: 'SuggestedProductTile',
  slot: 'ProductTileHeader',
})(() => ({
  flexDirection: 'column',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const SuggestedProductTilePrimaryBadge = styled('div', {
  name: 'SuggestedProductTile',
  slot: 'PrimaryBadge',
})(({ theme }) => ({
  display: 'flex',
  fontSize: 10,
  gap: theme.spacing(2),
  fontWeight: 600,
  lineHeight: 1.43,
  textTransform: 'uppercase',
  alignItems: 'center',

  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
    textAlign: 'center',
    marginTop: theme.spacing(0),
  },
}))

export const SuggestedProductTileSecondaryBadge = styled('div', {
  name: 'SuggestedProductTile',
  slot: 'ProductTileAttributes',
})(({ theme }) => ({
  fontSize: 10,
  lineHeight: 1.6,

  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
    textAlign: 'center',
  },
}))

export const StyledSuggestedProductTileImageContainer = styled('div', {
  name: 'SuggestedProductTile',
  slot: 'ImageContainer',
})(() => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  //marginTop: theme.spacing(3.25),
}))

export const StyledSuggestedProductTileFooterWrapper = styled('div', {
  name: 'SuggestedProductTile',
  slot: 'FooterWrapper',
})(({ theme }) => ({
  color: theme.palette.text.dark,
  position: 'relative',
  marginTop: theme.spacing(2),
}))

export const StyledSuggestedProductTileFooter = styled('div', {
  name: 'SuggestedProductTile',
  slot: 'ProductTileFooter',
})(({ theme }) => ({
  color: theme.palette.text.dark,

  [theme.breakpoints.up('sm')]: {
    '& > div': {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      flex: 0,
    },
  },
}))

export const StyledSuggestedProductTileDescription = styled('div', {
  name: 'SuggestedProductTile',
  slot: 'Description',
  shouldForwardProp: (prop) => prop !== 'isPDP',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: 0,
  alignItems: 'center',
  whiteSpace: 'nowrap',
  marginTop: theme.spacing(4),
}))

export const StyledSuggestedProductName = styled('div', {
  name: 'SuggestedProductTile',
  slot: 'ProductName',
  shouldForwardProp: (prop) => prop !== 'productType',
})<{
  productType: string | null
}>(({ productType, theme }) => ({
  fontSize:
    productType !== 'contact-lenses' ? theme.spacing(2.5) : theme.spacing(3),
  lineHeight: productType !== 'contact-lenses' ? 1.6 : 1.33,
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
  textAlign: 'center',

  [theme.breakpoints.up('sm')]: {
    fontSize: productType === 'contact-lenses' && theme.spacing(3.5),
    lineHeight: productType === 'contact-lenses' && 1.43,
  },
}))

export const StyledSuggestedProductBrandName = styled('div', {
  name: 'SuggestedProductTile',
  slot: 'ProductBrandName',
})(({ theme }) => ({
  lineHeight: 1.33,
  fontSize: theme.spacing(3),
  fontWeight: 600,

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(3.5),
    lineHeight: 1.43,
  },
}))

export const StyledSuggestedProductVariants = styled('div', {
  name: 'SuggestedProductTile',
  slot: 'ProductVariants',
})(({ theme }) => ({
  width: '100%',
  height: 100,
  marginBottom: theme.spacing(4),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  textAlign: 'center',
}))

export const StyledLoadingWrapper = styled('div', {
  name: 'SuggestedProductTile',
  slot: 'LoadingWrapper',
})(({ theme }) => ({
  flex: 1,
  display: 'flex',
  aspectRatio: '1 / 1',
  backgroundColor: theme.palette.color.grey.lightest,
  alignItems: 'center',
  justifyContent: 'center',
}))

export const StyledSkeletonWrapper = styled('div', {
  name: 'SuggestedProductTile',
  slot: 'SkeletonWrapper',
})({
  flex: 0,
  minHeight: 100,
  width: '100%',

  '& > div': {
    display: 'flex',
    flexDirection: 'column',
  },
})

export const StyledImageWrapper = styled('div', {
  name: 'SuggestedProductTile',
  slot: 'ImageWrapper',
})(() => ({
  overflow: 'hidden',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  div: {
    height: '100%',
    width: '100%',
    transition: 'transform .5s',
  },

  '&:hover div, &:focus div': {
    transform: 'scale(1.2)',
  },
}))

export const StyledImageGalleryWrapper = styled('div', {
  name: 'SuggestedProductTile',
  slot: 'ImageGalleryWrapper',
})(({ theme }) => ({
  padding: theme.spacing(2, 10, 8),
}))
