/* eslint-disable */
import * as userAction from '../../../redux/actions/user'
import config from '@configs/config.base'
import {
  SocialLoginContainer,
} from '../styles/SocialLogin.style'
import {
  LoginIdentityResponse,
  OauthIdentityResponse,
} from '../../../types/account'
import React, { useEffect, useState, useCallback } from 'react'

import Log from '../../../services/Log'
import { accountApi } from '../../../features/account/query'
import {useDispatch, useSelector} from 'react-redux'
import { useSite } from '../../../foundation/hooks/useSite'
import { useTranslation } from 'react-i18next'
import { useSocialLogin } from '../../SignUp/provider'
import { generatePath, useLocation, useNavigate } from 'react-router'
import { SIGNIN } from '../../../constants/routes'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { GoogleSocialLoginBtn } from './GoogleSocial/GoogleBtn'
import { GoogleAuthProvider } from './GoogleSocial'
import { FacebookBtn } from './FacebookSocial/FacebookBtn'
import { FacebookAuthProvider } from './FacebookSocial'
import DateService from '../../../services/DateService'
import { FastLoginContainer, FastLoginContent, LineDiv } from '../styles/SignInLayout.style'
import useBreakpoints from '@hooks/useBreakpoints'
import {isSocialLoginEnabledSelector} from '../../../redux/selectors/site'

type Props = {
  onProvidersReady: Function
}

type FacebookReq = {
  accessToken: string
  data_access_expiration_time: number
  email?: string
  expiresIn: number
  graphDomain: string
  id: string
  name: string
  signedRequest: string
  userID: string
}

const SocialLogin = ({ onProvidersReady }: Props) => {
  const { mySite } = useSite()
  const { langCode } = useStoreIdentity()
  const { t } = useTranslation()
  const isSocialLoginEnabled = useSelector(isSocialLoginEnabledSelector)
  const [GoogleClientId, setGoogleClientId] = useState<string>('')
  const [GoogleRedirectUrl, setGoogleRedirectUrl] = useState<string>('')
  const isGoogleOn = isSocialLoginEnabled?.google === 'true'
  const IsGoogleLoginButtonVisible = isGoogleOn &&
    !!GoogleRedirectUrl.length && !!GoogleClientId.length
  const [FacebookClientId, setFacebookClientId] = useState<string>('')
  const [FacebookRedirectUrl, setFacebookRedirectUrl] = useState<string>('')
  const [secondPassed, setSecondPassed] = useState<boolean>(false)
  const isFacebookOn = isSocialLoginEnabled?.facebook === 'true'
  const IsFacebookLoginButtonVisible = isFacebookOn &&
    !!FacebookRedirectUrl.length && !!FacebookClientId.length
  const [getOauthInfo] = accountApi.endpoints.getOauthInfo.useMutation()
  const [oauthValidate] = accountApi.endpoints.oauthValidate.useMutation()
  const dispatch = useDispatch()
  const { isDesktop } = useBreakpoints()

  const { setSocialData } = useSocialLogin()
  const location = useLocation()
  const isSignInPage = location.pathname.includes(SIGNIN)
  const navigate = useNavigate()

  const redirectToSignInPage = useCallback(() => {
    if (isSignInPage) return

    navigate(generatePath(`/:country/${SIGNIN}`, { country: langCode }))
  }, [isSignInPage, langCode, navigate])

  useEffect(() => {
    setTimeout(() => {
      setSecondPassed(true)
    }, 1e3)
  }, [])

  // there is a bug with chrome autocomplete
  // the bug is caused by social login request and button render
  // so if the providers are ready or 1 second passed, I set the provider to Ready
  useEffect(() => {
    if ((!!FacebookClientId && !!GoogleClientId) || secondPassed) {
      onProvidersReady()
    }
  }, [FacebookClientId, GoogleClientId, secondPassed])

  const currentUrl = window.location.protocol + '//' + window.location.host

  const setGoogleOauthInfos = async () => {
    const oauthRes = await getOauthInfo({
      storeId: mySite.storeID,
      socialItem: {
        authorizationProvider: 'Google',
      },
      redirectUrl: config.REACT_APP_SOCIAL_LOGIN_REDIRECT_URL || currentUrl,
    })
    const clientId = (oauthRes['data'] as OauthIdentityResponse)?.clientId
    const redirectUrl = (oauthRes['data'] as OauthIdentityResponse)?.redirectUrl
    clientId && setGoogleClientId(clientId)
    redirectUrl && setGoogleRedirectUrl(redirectUrl)

    if ('error' in oauthRes) {
      Log.error('Error while logging in with Google', oauthRes.error)
    }
  }

  const setFacebookOauthInfos = async () => {
    const oauthRes = await getOauthInfo({
      storeId: mySite.storeID,
      socialItem: {
        authorizationProvider: 'Facebook',
      },
      redirectUrl: config.REACT_APP_SOCIAL_LOGIN_REDIRECT_URL || currentUrl,
    })
    const clientId = (oauthRes['data'] as OauthIdentityResponse)?.clientId
    const redirectUrl = (oauthRes['data'] as OauthIdentityResponse)?.redirectUrl
    clientId && setFacebookClientId(clientId)
    redirectUrl && setFacebookRedirectUrl(redirectUrl)

    if ('error' in oauthRes) {
      Log.error('Error while logging in with Facebook', oauthRes.error)
    }
  }

  const onGoogleLoginSuccess = async (res) => {
    Log.info('Login Success: currentUser ', res?.code)
    if (!res?.code) return

    const oauthRes = await oauthValidate({
      storeId: mySite.storeID,
      socialItem: {
        authorizationProvider: 'Google',
        clientId: GoogleClientId,
        code: res.code,
      },
      redirectUrl: config.REACT_APP_SOCIAL_LOGIN_REDIRECT_URL || currentUrl,
    })
    const response = oauthRes['data'] as LoginIdentityResponse
    Log.info(response && response.WCToken ? response.WCToken : '')

    dispatch(userAction.LOGIN_SUCCESS_ACTION(response))

    if ('error' in oauthRes) {
      const date = new Date()
      // TODO: now we are not getting user data, provide when it will be ready
      setSocialData({
        firstName: '',
        lastName: '',
        email: '',
        dob: `${date}`,
        error: t('SignInPage.Msgs.SocialLoginError', { social: 'Google' }),
      })
      redirectToSignInPage()
      Log.error('Error while logging in with Google', oauthRes.error)
    }
  }

  const onGoogleLoginFailure = (res) => {
    Log.info('Login failed: res:', res)
  }

  const responseFacebook = async (res: FacebookReq | any) => {
    Log.info('Login Success: currentUser ', res?.userID)
    if (!res?.accessToken) return

    const birthDate = res.birthday && new Date(res.birthday)
    const formattedBirthDate = birthDate
      ? DateService.format(birthDate, 'yyyy-MM-dd') : ''

    const oauthRes = await oauthValidate({
      storeId: mySite.storeID,
      birthday: formattedBirthDate,
      email: res.email,
      id: res.id,
      name: res.name,
      accessToken: res?.accessToken,
      socialItem: {
        authorizationProvider: 'Facebook',
        clientId: FacebookClientId,
        accessToken: res?.accessToken,
      },
      redirectUrl: config.REACT_APP_SOCIAL_LOGIN_REDIRECT_URL || currentUrl,
    })
    const response = oauthRes['data'] as LoginIdentityResponse
    Log.info(response && response.WCToken ? response.WCToken : '')

    dispatch(userAction.LOGIN_SUCCESS_ACTION(response))

    if ('error' in oauthRes) {
      const [firstName, lastName] = res.name.split(' ')
      const date = new Date()
      setSocialData({
        firstName: firstName,
        lastName: lastName,
        email: res.email || '',
        dob: `${date}`,
        error: t('SignInPage.Msgs.SocialLoginError', { social: 'Facebook' }),
      })
      redirectToSignInPage()
      Log.error('Error while logging in with Facebook', oauthRes.error)
    }
  }

  useEffect(() => {
    setGoogleOauthInfos()
    setFacebookOauthInfos()
  }, [])

  if (!IsFacebookLoginButtonVisible && !IsGoogleLoginButtonVisible) return null

  return (
    <>
      <FastLoginContainer>
        {isDesktop && <LineDiv />}
        <FastLoginContent>
          {t('SignInPage.FastLoginWith')}
        </FastLoginContent>
        {isDesktop && <LineDiv />}
      </FastLoginContainer>
      <SocialLoginContainer>

        {IsFacebookLoginButtonVisible && (
          <FacebookAuthProvider clientId={FacebookClientId}>
            <FacebookBtn onSuccess={responseFacebook} fields="name,email,picture,birthday" />
          </FacebookAuthProvider>
        )}
        {IsGoogleLoginButtonVisible && (
          <GoogleAuthProvider clientId={GoogleClientId}>
            <GoogleSocialLoginBtn flow='auth-code' scope='email' onSuccess={onGoogleLoginSuccess} onError={onGoogleLoginFailure} />
          </GoogleAuthProvider>
        )}
      </SocialLoginContainer>
    </>
  )
}

export default SocialLogin
