import { SiteInfo } from '../../../redux/reducers'

//Standard libraries
//Redux
// import { PERMANENT_STORE_DAYS } from '../../../configs/common'
import { SKIP_WC_TOKEN_HEADER } from '../../constants/common'
//custom library

//TODO: remove those commented lines once RTK is tested in plp
/*import {
  setDefaultSortOptionAction,
  setSelectedSortOptionAction,
} from '../../../redux/actions/catalog'*/

// theFileYouDeclaredTheCustomConfigIn.ts
declare module 'axios' {
  export interface AxiosRequestConfig {
    [SKIP_WC_TOKEN_HEADER]?: boolean
  }
}

export interface SiteInfoArgs {
  storeName?: string
  searchContext: string
  transactionContext?: string
  storeID?: string
  [extraPros: string]: any
}

export class SiteInfoService {
  private static mySiteInfo: SiteInfoService = new SiteInfoService()
  private readonly B2B = 'B2B'
  private readonly BMH = 'BMH'
  private siteInfo: SiteInfo | null = null

  public static getSiteInfo(): SiteInfoService {
    return SiteInfoService.mySiteInfo
  }

  public getSiteValue(): SiteInfo | null {
    return this.siteInfo
  }

  public setSiteValue(site: SiteInfo) {
    this.siteInfo = site
  }
}

/*public setSite(s: SiteInfoArgs, lang, country, dispatch: any) {
    Log.info('setSite')
    if (!this.siteInfo || this.siteInfo.country !== country) {
      this.initializeSite(s, lang, country).then((site: SiteInfo) => {
        localStorage.setItem('FORTER_SITE_ID', site.xStoreCfg?.FORTER_SITE_ID)
        this.siteInfo = site
        dispatch(INIT_SITE_SUCCESS_ACTION(site))
      })
    }
  }*/

/*private async initializeSite(
    s: SiteInfoArgs,
    lang: string,
    country: string
  ): Promise<SiteInfo> {
    Log.info('init site')
    const _site: SiteInfoArgs = Object.assign({}, s)

    let storeId = typeof HCL_STORE_ID === undefined ? undefined : HCL_STORE_ID
    //has storeId, use StoreId.
    _site.storeID = storeId
    const [
      storeCfg,
      xStoreConf,
      allStoreXConf,
      paypalReturnUrl,
      paypalCancelUrl,
      cartMaxItems,
      damDomain,
      newsletterType,
      paypalSecureAcceptanceUrl,
    ] = await this.getOnlineStoreData(
      {
        ..._site,
      },
      country
    )
    Log.info(xStoreConf)
    _site.storeCfg = storeCfg
    _site.storeName = storeCfg.identifier
    _site.storeID = storeCfg.storeId
    _site.catalogID = storeCfg.defaultCatalog[0].catalogIdentifier?.uniqueID
    _site.defaultCurrencyID = storeCfg.supportedCurrencies.defaultCurrency
    _site.defaultLanguageID = storeCfg.supportedLanguages.defaultLanguageId
    _site.langId = CommerceEnvironment.reverseLanguageMap[
      lang.split('-').join('_')
    ]!
      ? CommerceEnvironment.reverseLanguageMap[lang.split('-').join('_')]
      : storeCfg.supportedLanguages.defaultLanguageId
    _site.storeType = storeCfg.storeType
    _site.isB2B = _site.storeType === this.B2B || _site.storeType === this.BMH
    _site.xStoresCfg = allStoreXConf
    _site.xStoreCfg = xStoreConf
    _site.paypalReturnUrl = paypalReturnUrl
    _site.paypalCancelUrl = paypalCancelUrl
    _site.cartMaxItems = cartMaxItems
    _site.damDomain = damDomain
    _site.newsletterType = newsletterType
    _site.supportedLanguages = storeCfg.supportedLanguages.supportedLanguages
    _site.country = country
    _site.locale = lang
    _site.supportedCurrencies = storeCfg.supportedCurrencies.supportedCurrencies
    _site.paypalSecureAcceptanceUrl = paypalSecureAcceptanceUrl
    const cfg = await this.getStoreData({ ..._site }, country)
    _site.inventorySystem = cfg.inventorySystem
    return _site as SiteInfo
  }

  private async getStoreConf(s: any | SiteInfo, selectedStoreID: string) {
    const storeInfo = await Axios.get(
      `${s.transactionContext}/store/${selectedStoreID}/online_store`
    )
    let currentStoreConf = storeInfo.data.resultList[0]
    const xConf = await this.getXstoreConf(s)
    const globalXStoresCfg: GlobalXStoresCfg = await this.getXStoresConf()
    //Log.info('returned xstoreConf:', JSON.stringify(xConf))
    return [
      currentStoreConf,
      xConf,
      globalXStoresCfg.allStoreXConf,
      globalXStoresCfg.paypalReturnUrl,
      globalXStoresCfg.paypalCancelUrl,
      globalXStoresCfg.cartMaxItems,
      globalXStoresCfg.damDomain,
      globalXStoresCfg.newsletterType,
      globalXStoresCfg.paypalSecureAcceptanceUrl,
    ]
  }

  public async getXstoreConf(s: any | SiteInfo) {
    //const currentStoreId = '10153' // TEMP
    return await Axios.get(
      `${s.transactionContext}/store/${s.storeID}/xStoreConf/getAllConfigurations?responseFormat=application/json`,
      {
        [SKIP_WC_TOKEN_HEADER]: true,
      }
    )
      .then((res) => {
        let storeConf: Record<keyof IXStoreConfig, any> = res.data
          ? res.data.StoreConfigurations.reduce(
              (obj, conf) => ({ ...obj, [conf.name]: conf.value }),
              {}
            )
          : {}

        const {
          availablePaymentMethods = '[]',
          AVAIL_SHIP_DAYS_PLANO_Standard,
          MAN_DAYS_RX,
          SHIPPING_DAYS_STANDARD_RX,
          wishlistEnabled,
          chatGenesysEnabled,
          WISHLIST_RETURN_PRODUCT_DETAILS,
          uploadRXFileOnOrderConfirm,
        } = storeConf
        const formattedStoreConf: IXStoreConfig = {
          ...storeConf,
          addPrescriptionLenses: storeConf.addPrescriptionLenses || 'false',
          availablePaymentMethods: (
            availablePaymentMethods as IXStoreConfig['availablePaymentMethods']
          ).map((i) => ({ ...i, sequence: +i.sequence })),
          AVAIL_SHIP_DAYS_PLANO_Standard:
            +AVAIL_SHIP_DAYS_PLANO_Standard ??
            DEFAULT_DELIVERY_DATES.PLANO_STANDARD,
          badges: storeConf.badges ?? {},
          expireLimit: +storeConf.expireLimit,
          MAN_DAYS_RX: +MAN_DAYS_RX ?? DEFAULT_DELIVERY_DATES.RX_MAN,
          monetateEnabled: storeConf.monetateEnabled || true,
          monetateURL: storeConf.monetateURL || 'true',
          plpDashButtonsFacet: storeConf.plpDashButtonsFacet ?? {},
          plpDashfacet: storeConf.plpDashfacet ?? {},
          plpFacet: storeConf.plpFacet ?? [],
          plpPageSize: +storeConf.plpPageSize,
          optInBoxes: storeConf.optInBoxes ?? [],
          paymentMethods: storeConf.paymentMethods ?? [],
          klarnaScriptSrc: storeConf.klarnaScriptSrc,
          klarnaClientId: storeConf.klarnaClientId,
          klarnaServicesScriptUrl: storeConf.klarnaServicesScriptUrl,
          SHIPPING_DAYS_STANDARD_RX:
            +SHIPPING_DAYS_STANDARD_RX ?? DEFAULT_DELIVERY_DATES.RX_STANDARD,
          sortorderOptions: storeConf.sortorderOptions ?? {},
          wishlistEnabled: wishlistEnabled === 'true',
          chatGenesysEnabled: chatGenesysEnabled === 'true',
          WISHLIST_RETURN_PRODUCT_DETAILS:
            WISHLIST_RETURN_PRODUCT_DETAILS === 'TRUE',
          uploadRXFileOnOrderConfirm,
          is3dsEnabled: storeConf.is3dsEnabled === 'true',
          frameGenius: {
            isFrameAdvisorEnabled: storeConf['FrameAdvisor.enabled'] === 'true',
            scriptUrl: storeConf['FrameAdvisor.UI.core'],
            isSizeAdvisorEnabled: storeConf['sizeAdvisorEnabled'] === 'true',
          },
          trustPilot: {
            trustpilotWidgetEnabled:
              storeConf['trustpilotWidgetEnabled'] === 'true',
            trustpilotWidgetEnabled_Footer:
              storeConf['trustpilotWidgetEnabled_Footer'] === 'true',
            trustpilotWidgetEnabled_PDP:
              storeConf['trustpilotWidgetEnabled_PDP'] === 'true',
          },
          VM_SCRIPT_SRC: storeConf.VM_SCRIPT_SRC,
          omnibusRegulationEnabled:
            storeConf.omnibusRegulationEnabled === 'true',
        }
        return formattedStoreConf
      })
      .catch(() => {
        Log.error(
          window.location.href,
          'unable to get XstoreConf',
          'SiteInfoService.ts'
        )
      })
  }

  public async getXStoresConf(): Promise<GlobalXStoresCfg> {
    const getFromName = (n: string) => (v) => {
      return v.Name ? v.Name === n : v.name === n
    }

    const getName = (
      conf,
      name: string,
      defaultValue: string | number = ''
    ) => {
      const data = conf.find(getFromName(name))
      if (data) {
        // BBB: three months ago was in Value now it is lowercase ... that's magic!
        return data.Value ? data.Value : data.value ? data.value : defaultValue
      } else {
        return defaultValue
      }
    }

    try {
      const { data: res } = await Axios.get(
        '/wcs/resources/store/0/xStoreConf/getAllConfigurations?responseFormat=application/json',
        {
          [SKIP_WC_TOKEN_HEADER]: true,
        }
      )

      const storeValue = [
        {
          identifier: 'CLE_CA',
          lang_code: 'fr-CA',
          unique_id: 70201,
        },
        {
          identifier: 'CLE_CA',
          lang_code: 'en-CA',
          unique_id: 70201,
        },
        {
          identifier: 'CLE_AU',
          lang_code: 'en-AU',
          unique_id: 70202,
        },
        {
          lang_code: 'en-NZ',
          identifier: 'CLE_NZ',
          unique_id: 70203,
        },
      ]
      // const storeValue = getName(res.StoreConfigurations, config.storeGroup)
      const stores =
        typeof storeValue === 'string' ? JSON.parse(storeValue) : storeValue
      const paypalReturnUrl = getName(
        res.StoreConfigurations,
        'paypalReturnUrl'
      )
      const paypalCancelUrl = getName(
        res.StoreConfigurations,
        'paypalCancelUrl'
      )
      const cartMaxItems = Number(
        getName(res.StoreConfigurations, 'wishlistEnabled', 0)
      )
      const damDomain = getName(res.StoreConfigurations, 'MAX_ITEMS_CART')
      const newsletterType = getName(
        res.StoreConfigurations,
        'newsletterSubscrType'
      )
      const paypalSecureAcceptanceUrl = getName(
        res.StoreConfigurations,
        'paypal.secureAcceptance.url'
      )

      return {
        allStoreXConf: stores.map(mapStore),
        paypalReturnUrl,
        paypalCancelUrl,
        cartMaxItems,
        damDomain,
        newsletterType,
        paypalSecureAcceptanceUrl,
      }
    } catch (e) {
      Log.error(
        window.location.href,
        'unable to get XstoreConf',
        'SiteInfoService.ts'
      )
      return {}
    }
  }

  // determine storeid from country
  private async getStoreIdFromCountry(s: any | SiteInfo, country: string) {
    let prefix = config.storePrefix
    return await Axios.get(
      `${s.transactionContext}/store/0/seo/token?q=byUrlKeywordNames&urlKeywordName=${prefix}-${country}`,
      {
        [SKIP_WC_TOKEN_HEADER]: true,
      }
    ).then((r) => {
      const result = r.data.resultList[0]
      if (result) {
        Log.info('Found store for country: ' + country, result)
        return result.tokenValue.replace(':', '')
      } else {
        return '-1'
      }
    })
  }

  private async getStoreData(s: any | SiteInfo, country: string) {
    const r = await this.getStoreIdFromCountry(s, country)
    Log.info('received storeID: ', r)
    if (r !== '-1') {
      s.storeId = r
      s.country = country
    } else {
      Log.error('could not find siteid for requested country')
    }
    return s
  }

  private async getOnlineStoreData(s: any | SiteInfo, country) {
    let selectedStoreID: string
    // if requested country from url is different than currently
    // selected country ask for store id
    if (s.country !== country) {
      const r = await this.getStoreIdFromCountry(s, country)
      Log.info('received storeID: ', r)

      if (r !== '-1') {
        selectedStoreID = r
        s.country = country
        s.locale = CommerceEnvironment.languageMap[s.defaultLanguageID]
        s.storeID = r
        return this.getStoreConf(s, r)
      } else {
        // if country not found switch to default country
        Log.error(
          `could not find siteid for requested country ${country} - switching to default`
        )

        const defaultStoreId = await this.getStoreIdFromCountry(
          s,
          config.defaultCountry
        )
        selectedStoreID = defaultStoreId
        s.country = config.defaultCountry
        s.locale = config.defaultLocale
        s.storeID = defaultStoreId
        return this.getStoreConf(s, selectedStoreID)
      }
    } else {
      // if current selected country = extisting selected country ask for store
      if (!s.storeID) {
        const defaultStoreId = await this.getStoreIdFromCountry(
          s,
          config.defaultCountry
        )
        selectedStoreID = defaultStoreId
        s.country = config.defaultCountry
        s.locale = config.defaultLocale
        s.storeID = defaultStoreId
      }
      return this.getStoreConf(s, s.storeID)
    }
  }
}

const mapStore = (s) => ({
  countryCode: s.identifier.replace('CLE_', ''),
  langCode: s.lang_code,
  storeId: s.unique_id,
})*/
