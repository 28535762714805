import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setChatReady, setOpenChat } from '../../features/ui/action'
import {
  chatGenesysDeploymentIdSelector,
  chatGenesysEnabledSelector,
} from '../../redux/selectors/site'

const useGenesys = () => {
  const dispatch = useDispatch()
  const chatGenesysEnabled = useSelector(chatGenesysEnabledSelector)
  const chatGenesysDeploymentId = useSelector(chatGenesysDeploymentIdSelector)
  const deploymentId =
    chatGenesysDeploymentId || '90303d04-f445-415b-b968-783b9b904308'

  useEffect(() => {
    if (!chatGenesysEnabled) {
      return
    }
    window['_genesysJs'] = 'Genesys'
    window['Genesys'] =
      window['Genesys'] ||
      (function () {
        const genArray = (window['Genesys']?.q as any[]) || []
        genArray.push(arguments)
        return {
          q: genArray,
          t: new Date().getTime(),
          c: {
            environment: 'prod-euw1',
            deploymentId,
          },
        }
      })()

    const script = document.createElement('script')
    script.src = 'https://apps.mypurecloud.ie/genesys-bootstrap/genesys.min.js'
    script.async = true
    script.charset = 'utf-8'

    script.onload = () => {
      let country = 'IT'
      let language = 'it-IT'
      let timezone = 'Europe/Berlin'
      window['Genesys']('command', 'Database.set', {
        messaging: {
          customAttributes: {
            webmsg_brandcode: 'GV',
            webmsg_country: country,
            webmsg_language: language,
            webmsg_timezone: timezone,
          },
        },
      })

      window['Genesys']('subscribe', 'Messenger.opened', function () {
        dispatch(setOpenChat(true))
      })

      window['Genesys']('subscribe', 'Messenger.closed', function () {
        dispatch(setOpenChat(false))
      })

      window['Genesys']('subscribe', 'Messenger.ready', function () {
        dispatch(setChatReady(true))
      })
    }

    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [chatGenesysEnabled])
}

export default useGenesys
