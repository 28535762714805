import { styled } from '@mui/material/styles'
import { Z_INDEX_LV1 } from '../../../../../constants/ui'
import {
  ITeaserOverlayStyle,
  ITeaserOverlaySettings,
  ITeaserOverlayTextAlign,
} from '../../../../../types/cmsPlacement/LXTeaser'
import {
  getRatioBanner,
  overLayTextAlignToCSS,
  teaserOverlayStyleToColor,
} from '../../../../../utils/placements'
import CmsBroadContentWrapper from '../../../CmsComponents/CmsBroadContentWrapper'
import { CmsBroadContentWrapperProps } from '../../../CmsComponents/CmsBroadContentWrapper/CmsBroadContentWrapper'
import { CmsCountdown } from '../../../CmsComponents/CmsCountdown'
import CmsTeaserDetailText from '../../../CmsComponents/CmsTeaserDetailText'
import CmsTeaserTitle from '../../../CmsComponents/CmsTeaserTitle'
import { CmsTermsAndConditionsButton } from '../../../CmsComponents/CmsTermsAndConditions'
import { StyledTypography } from '../../../../UI'
import { IViewType } from '../../../../../types/cmsPlacement/ViewType'
import { PropsWithChildren } from 'react'

export const StyledTopPageBannerWrapper = styled(CmsBroadContentWrapper, {
  name: 'TopPageBanner',
  slot: 'Wrapper',
})<PropsWithChildren<CmsBroadContentWrapperProps>>(({ theme }) => ({
  padding: theme.spacing(0, 4),
  [theme.breakpoints.up('md')]: {
    maxWidth: '36%',
    padding: theme.spacing(6, 16),
  },
}))

export const StyledTopPageBannerArticle = styled('article', {
  name: 'TopPageBanner',
  slot: 'Article',
  shouldForwardProp: (prop) =>
    prop !== 'overlaysettings' &&
    prop !== 'overlaytxtalign' &&
    prop !== 'teaseroverlaystyle',
})<{
  overlaysettings?: ITeaserOverlaySettings
  overlaytxtalign?: ITeaserOverlayTextAlign
  teaseroverlaystyle?: ITeaserOverlayStyle
}>(({ theme, overlaytxtalign, teaseroverlaystyle }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: 'auto',
  gap: theme.spacing(2),
  paddingTop: theme.spacing(6),
  //cta always centered on mobile
  ...teaserOverlayStyleToColor(theme, teaseroverlaystyle),
  '& > *:not(#TopPageCtaContainer)': {
    ...overLayTextAlignToCSS(overlaytxtalign),
  },
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(4),
  },
  [theme.breakpoints.up('lg')]: {
    padding: 'unset',
    justifyContent: 'center',
    //...overLaySettingsToVerticalCSS(overlaysettings || ''),
    '& > *': {
      ...overLayTextAlignToCSS(overlaytxtalign),
    },
  },
}))

export const StyledTopPageBannerCountDown = styled(CmsCountdown, {
  name: 'TopPageBanner',
  slot: 'CountDown',
})(({ theme }) => ({
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    marginBottom: 'unset',
    '& > h3': {
      margin: 'unset',
    },
  },
}))

export const StyledTopPageBannerPreTitle = styled(StyledTypography, {
  name: 'TopPageBanner',
  slot: 'PreTitle',
})(() => ({
  fontSize: 16,
}))

export const StyledTopPageBannerTitle = styled(CmsTeaserTitle, {
  name: 'TopPageBanner',
  slot: 'Title',
})(() => ({}))

export const StyledTopPageBannerTextContainer = styled(CmsTeaserDetailText, {
  name: 'TopPageBanner',
})(() => ({
  width: '100%',
}))

export const StyledTopPageBannerCTAContainer = styled('div', {
  name: 'TopPageBanner',
  slot: 'CTAContainer',
})(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexFlow: 'column wrap',
  gap: theme.spacing(4),
  marginTop: theme.spacing(4),
  //ctas are centered on mobile
  alignItems: 'center',
  '& > a': {
    height: 'auto',
    maxWidth: 285,
    [theme.breakpoints.up('md')]: {
      maxWidth: 343,
    },
  },
  [theme.breakpoints.up('md')]: {
    marginTop: 'unset',
    alignItems: 'unset',
    flexDirection: 'row',
    '& > a': {
      width: 'auto',
    },
  },
}))

export const MediaOverlapTopPageContainer = styled('div', {
  name: ' ImageOverlap',
  slot: 'TopPageContainer',
  shouldForwardProp: (prop) => prop !== 'viewType',
})<{ viewType: IViewType }>(({ viewType, theme }) => ({
  width: '100%',
  position: 'relative',
  ...getRatioBanner(viewType, theme),
}))

export const TopPageBannerTermsConditionButton = styled(
  CmsTermsAndConditionsButton,
  {
    name: ' TopPageBanner',
    slot: 'TermsConditionButton',
  }
)(() => ({}))

export const TopPageBannerTermsConditionButtonContainer = styled('div', {
  name: ' TopPageBanner',
  slot: 'TermsConditionButtonContainer ',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(4, 0, 8),
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(6),
    zIndex: Z_INDEX_LV1,
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
}))

export const IconContentTopBanner = styled('div', {
  name: 'GridOfBoards',
  slot: 'IconContent',
})(({}) => ({
  display: 'flex',

  svg: {
    height: 48,
    width: 48,
  },
}))
