import '../../styles/index.scss'

import {
  APP_MAIN_CONTAINER_CLASSNAME,
  APP_MAIN_CONTAINER_ID,
} from '../../constants/ui'
import { AppMain, AppWrapper } from './components/BaseLayout.style'
import React, {
  PropsWithChildren,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ErrorMessageSnackbar from '../../components/message-snackbar/ErrorMessageSnackbar'
import Footer from '../Footer'
import FrameAdvisor from '../../components/FrameGenius/FrameAdvisor'
import FrameAdvisorUtil from '../../utils/FrameGenius/FrameAdvisorUtil'
import Header from '../Header'
import { Helmet } from 'react-helmet'
import { useForter } from '../../foundation/forter/useForter'
import { IFRAME_RESIZER } from '../../foundation/constants/csr'
import LoginGuard from '../../foundation/guard/LoginGuard'
import { PAGE_TYPES } from '../../foundation/analytics/tealium/pageTypes'
import ReducedHeader from '../ReducedHeader'
import SizeAdvisor from '../../components/FrameGenius/SizeAdvisor'
//UI
import { TealiumService } from '../../foundation/analytics/tealium'
import TopBar from '../../components/TopBar/TopBar'
import config from '../../configs'
import { getPreviewSelector } from '../../features/preview/selector'
import {
  openDrawerMegaMenuSelector,
  openDrawerSearchSelector,
} from '../../features/ui/selector'
//Foundation libraries
//Custom libraries
import { routes } from '@configs/routes'
import { CHECKOUT_STEPS } from '../../constants/checkout'
import { setOpenDrawerNewsLetter, setPageType } from '../../features/ui/action'
import { styled } from '@mui/material/styles'
// Tealium
import { useAnalyticsData } from '../../foundation/analytics/hooks/useAnalyticsData'
import { useFrameGenius } from '@components/FrameGenius/FrameGeniusContext'
//hooks
import { useLocation } from 'react-router-dom'
import { useMonetateScript } from '../../foundation/monetate/useMonetateScript'
import { usePageType } from '../../foundation/hooks/usePageType'
import { useRoutes } from 'react-router-dom'
import { useSite } from '../../foundation/hooks/useSite'
import { useTranslation } from 'react-i18next'
import { usePrescriptionLenses } from '@components/PrescriptionLenses/PrescriptionLensesContext'
import { Prescription } from '@components/PrescriptionLenses/Prescription'
import SocialLoginProvider from '../../pages/SignUp/provider'
import { MonetateHelper } from '../../foundation/monetate/MonetateHelper'
import { BOOKAPPOINTMENT } from '../../constants/routes'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import ChatBotButton from '@components/ChatBot'
import { useGlobalExternalActions } from '@hooks/useGlobalExternalActions'
import useGenesys from '@hooks/useGenesys'
import { trustPilotSelector } from '../../redux/selectors/site'
import { useTheme } from '@mui/material'

const StyledContent = styled('div', {
  name: 'App',
  slot: 'Content',
  shouldForwardProp: (prop) =>
    prop !== 'pageType' && prop !== 'orderConfirmationPage',
})<{ pageType?: string; orderConfirmationPage?: boolean }>(
  ({ theme, pageType, orderConfirmationPage }) => ({
    width: '100%',
    minHeight: pageType === 'cart' ? 'calc(100vh - 15rem)' : '100vh',
    backgroundColor:
      pageType === 'checkout' && !orderConfirmationPage
        ? theme.palette.color.grey.lightest
        : 'unset',

    [theme.breakpoints.up('sm')]: {
      minHeight:
        pageType === 'cart' ? 'calc(100vh - 40rem)' : 'calc(100vh - 56px)',
    },

    [theme.breakpoints.up('md')]: {
      minHeight:
        pageType === 'cart' ? 'calc(100vh - 35rem)' : 'calc(100vh - 56px)',
    },
  })
)

const AppMainWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const isSearchDrawerOpen = useSelector(openDrawerSearchSelector)
  const megaMenuDrawer = useSelector(openDrawerMegaMenuSelector)

  return (
    <AppMain
      id={APP_MAIN_CONTAINER_ID}
      className={APP_MAIN_CONTAINER_CLASSNAME}
      openMegaMenu={megaMenuDrawer.isOpen}
      openSearchDrawer={isSearchDrawerOpen}
    >
      {children}
    </AppMain>
  )
}

const BaseLayout: React.FC = () => {
  const location = useLocation()
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const getPreview = useSelector(getPreviewSelector)
  const getTrustPilot = useSelector(trustPilotSelector)
  const { trustpilotWidgetEnabled } = getTrustPilot || {}
  const { mySite } = useSite()
  const { pageType } = usePageType()

  const { ...analyticsCommonData } = useAnalyticsData(PAGE_TYPES.HOME)
  // public url path for accessing discoverui.js file.
  const publicUrlPath = window.location.origin

  const [scriptLoaded] = useState(window && window.tealium_data2track)
  const [discover] = React.useState<boolean>(false)
  const { langCode } = useStoreIdentity()

  const isIOSDevice = useMemo(
    () => navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent),
    [navigator.userAgent]
  )

  const orderConfirmationPage =
    location.pathname.indexOf(CHECKOUT_STEPS.ORDER_CONFIRMATION) !== -1

  const isBookAppointmentPage =
    location.pathname === `/${langCode}/${BOOKAPPOINTMENT}`

  const isSupportPage =
    location.pathname ===
      `/${langCode}/c/informazioni-utili/domande-frequenti` ||
    location.pathname === `/${langCode}/c/informazioni-utili/contatti` ||
    location.pathname === `/${langCode}/c/acquisti-online/resi`

  // init analytics
  const Analytics = () => {
    return new TealiumService(
      mySite,
      Date.now().toString(),
      analyticsCommonData
    )
  }

  useGenesys()
  useForter()
  useMonetateScript()
  useGlobalExternalActions()

  const { isOpenPrescriptionLensesForm } = usePrescriptionLenses()

  useEffect(() => {
    document
      .getElementsByClassName(APP_MAIN_CONTAINER_CLASSNAME)[0]
      .scrollTo(0, 0)
  }, [location])

  // set page type for customerSegment refetch on page change
  useEffect(() => {
    if (pageType && pageType !== 'page404') {
      dispatch(setPageType(pageType))
    }
  }, [pageType, location.pathname])

  useEffect(() => {
    if (!scriptLoaded && config.enableAnalytics && mySite) {
      Analytics()
      window.dispatchEvent(new Event('tealiumInit'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite, scriptLoaded])

  useEffect(() => {
    dispatch(setOpenDrawerNewsLetter(false))
  }, [mySite])

  const renderedRoutes = useRoutes(routes)
  const fgData = useFrameGenius()

  useEffect(() => {
    if (FrameAdvisorUtil.isUserInExcludedPage()) {
      fgData.closeFrameAdvisor()
    }
  }, [location.pathname])

  return (
    <AppWrapper
      data-testid="app-wrapper"
      theme={theme}
      isPreview={getPreview.isEnabled}
    >
      <TopBar />
      {/* <BaseLayoutTopBars />*/}
      <AppMainWrapper>
        {pageType === 'checkout' &&
        location.pathname.indexOf(CHECKOUT_STEPS.ORDER_CONFIRMATION) === -1 ? (
          <ReducedHeader />
        ) : (
          <Header />
        )}

        <section className="full-viewport-height" id="main-viewport">
          <LoginGuard />
          <Helmet>
            <meta charSet="utf-8" />
            {isIOSDevice && (
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1"
              />
            )}
            <title>{t('CommerceEnvironment.Title')}</title>
            <meta
              name="description"
              content={t('CommerceEnvironment.Description')}
            />
            <link
              rel="canonical"
              href={`${window.location.origin}${location.pathname}`}
            />
            <script>{`{MonetateHelper = ${MonetateHelper}}`}</script>
            {discover && (
              <script
                src={`${publicUrlPath}/discover/discoverui.js?q=${Date.now()}`}
                type="text/javascript"
                async
              />
            )}
            {window[IFRAME_RESIZER] && (
              <script
                src="/iframeResizer.contentWindow.min.js"
                type="text/javascript"
                async
              />
            )}
            {trustpilotWidgetEnabled && (
              <script
                type="text/javascript"
                src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
                async
              />
            )}
          </Helmet>
          {getPreview?.isEnabled && (
            <Helmet>
              <html lang={`${getPreview.locale}`} dir="ltr" />
              <title data-cm-metadata='[{"_":"properties.htmlTitle"}]'>{`${
                getPreview.name || 'CMS Preview'
              }`}</title>
              <meta name="generator" content="CoreMedia Content Cloud" />
              <script>
                {
                  (document.documentElement.className =
                    document.documentElement.className.replace(/no-js/g, 'js'))
                }
              </script>
              <link
                rel="stylesheet"
                href="/cms-preview/coremedia.preview.blueprint.css"
              />

              <script src="/cms-preview/coremedia.preview.js" />
              <script src="/cms-preview/coremedia.preview.$.js" />
              <script src="/cms-preview/coremedia.preview.blueprint.js" />
              <body
                id="top"
                className="cm-page"
                data-cm-metadata={
                  '[{"cm_responsiveDevices":{"mobile_portrait":{"width":375,"height":667,"order":1},"tablet_landscape":{"width":1024,"height":768,"order":4},"mobile_landscape":{"width":667,"height":375,"order":2},"tablet_portrait":{"width":768,"height":1024,"order":3}},"cm_preferredWidth":1500}]'
                }
              />
            </Helmet>
          )}
          <StyledContent
            pageType={pageType}
            orderConfirmationPage={orderConfirmationPage}
            className={'main'}
          >
            <SocialLoginProvider>
              <Suspense fallback={<div />}>{renderedRoutes}</Suspense>
            </SocialLoginProvider>
          </StyledContent>
        </section>

        {!isBookAppointmentPage && <Footer />}
        {isSupportPage ? <ChatBotButton /> : null}

        <ErrorMessageSnackbar />
        {fgData.isFrameAdvisorOpen && <FrameAdvisor />}
        {fgData.isSizeAdvisorOpen && <SizeAdvisor />}
        {isOpenPrescriptionLensesForm && <Prescription />}
      </AppMainWrapper>
    </AppWrapper>
  )
}

export default BaseLayout
