import React from 'react'
import { ILXTeaser } from '../../../../../types/cmsPlacement/LXTeaser'

import {
  IconContentLandscapeBanner,
  LandscapeBannerCountdown,
  StyledLandscapeBannerArticle,
  StyledLandscapeBannerCTAContainer,
  StyledLandscapeBannerPreTitle,
  StyledLandscapeBannerTextContainer,
  StyledLandscapeBannerTitle,
  StyledLandscapeBannerWrapper,
} from './LandscapeBanner.style'
import CmsProductTile from '../../../CmsComponents/CmsProductTile'

import LandscapeMediaOverlap from './components/LandscapeMediaOverlap'
import CmsCta from '../../../CmsComponents/CmsCta'
import useCmsTeaserBanner from '../../../../../hooks/useCmsBanner'
import { CmsShoppable } from '../../../CmsComponents/CmsShoppable/CmsShoppableImage'
import { LandscapeBannerTermsAndCondition } from './components/LanscapeTermsAndCondition'
import { getTeaserOverlayTextAlign } from '@utils/placements'
import usePlayerBanner from '../../../../../hooks/useBannerPlayer'
import { ILandscapeBannerProps } from './index'
import CmsFullBannerAnchor from '../../../CmsComponents/CmsFullBannerAnchor'
import CmsIcon from '../../../CmsComponents/CmsIcon'
import CmsVideoController from '@components/Cms/CmsComponents/CmsCommonMedia/CmsVideoController'
import { isLXTeaser } from '../../../../../types/cmsPlacement/Placement'
import { IVideoMedia } from '../../../../../types/cmsPlacement/Media'

export const LandscapeDesktopBanner: React.FC<ILandscapeBannerProps> = ({
  placement,
  teaserIndex,
  bannerIndex,
}) => {
  const crop = 'LANDSCAPE_BANNER'
  const viewType = placement.viewtype

  const item =
    'items' in placement
      ? placement?.items?.find(isLXTeaser)
      : placement?.teasableItems?.find(isLXTeaser)

  const {
    teaserTitle,
    teaserText,
    teaserTitleValue,
    teaserTextValue,
    teaserPreTitle,
    teaserOverlaySettingsValue,
    teaserOverlayTextAlignValue,
    teaserOverlayStyleValue,
    promoteToH1,
    teaserLXCallToActionSettings,
    teaserIcon,
    teaserCountdownStart,
    teaserCountdownUntil,
    teaserHideExpiredCountdown,
    placementHotZones,
    placementHotZonesSettings,
    isShoppableImage,
    toLink,
    productData,
    itemHasTermsAndCondition,
    isFrameAdvisor,
    media,
    isVideo,
  } =
    useCmsTeaserBanner({
      item,
      viewType,
    }) || {}

  const playerBanner = usePlayerBanner(media! as IVideoMedia)

  const content = (
    <>
      {teaserIcon && (
        <IconContentLandscapeBanner>
          <CmsIcon teaserIcon={teaserIcon} />
        </IconContentLandscapeBanner>
      )}
      {teaserCountdownStart &&
        teaserCountdownUntil &&
        teaserHideExpiredCountdown && (
          <LandscapeBannerCountdown
            teaserCountdownStart={teaserCountdownStart}
            teaserCountdownUntil={teaserCountdownUntil}
            teaserHideExpiredCountdown={teaserHideExpiredCountdown}
          />
        )}
      <>
        {teaserPreTitle && (
          <>
            <StyledLandscapeBannerPreTitle
              data-cm-metadata={'[{"_":"properties.teaserPreTitle"}]'}
              variant={promoteToH1 ? 'h1' : 'h2'}
              fontWeight={'600'}
            >
              {teaserPreTitle}
            </StyledLandscapeBannerPreTitle>
          </>
        )}
        {teaserTitleValue && (
          <StyledLandscapeBannerTitle
            data-cm-metadata={`[{"_":"properties.${teaserTitle}"}]`}
            variant={promoteToH1 ? 'h1' : 'h2'}
          >
            {teaserTitleValue}
          </StyledLandscapeBannerTitle>
        )}
        {teaserTextValue && (
          <StyledLandscapeBannerTextContainer
            data-cm-metadata={`[{"_":"properties.${teaserText}"}]`}
            dangerouslySetInnerHTML={{ __html: teaserTextValue }}
          />
        )}
      </>
      {productData !== null && (
        <CmsProductTile product={productData} tileIndex={0} />
      )}
      {!!teaserLXCallToActionSettings &&
        teaserLXCallToActionSettings?.length > 0 && (
          <StyledLandscapeBannerCTAContainer
            id="ctaContainer"
            data-element-id={isFrameAdvisor && 'Footer_FrameGenius_Open'}
          >
            {teaserLXCallToActionSettings?.map((actionSettings, index) => (
              <CmsCta
                actionSettings={actionSettings}
                dataElementId={`${teaserIndex}Placement_Banner${bannerIndex}_CTA${
                  index + 1
                }`}
                key={`cms-content__text-module--cta-${index + 1}`}
              />
            ))}
          </StyledLandscapeBannerCTAContainer>
        )}
    </>
  )

  return (
    <>
      <CmsFullBannerAnchor
        toLink={toLink}
        bannerIndex={bannerIndex}
        teaserIndex={teaserIndex}
      >
        <LandscapeMediaOverlap
          teaserIndex={teaserIndex}
          playerBannerHook={playerBanner}
          teaser={item!}
          media={media!}
          viewType={viewType!}
          crop={crop}
        />
      </CmsFullBannerAnchor>
      <StyledLandscapeBannerWrapper
        overlaysettings={teaserOverlaySettingsValue}
        istextoverlay
      >
        <StyledLandscapeBannerArticle
          overlaysettings={teaserOverlaySettingsValue}
          overlaytxtalign={getTeaserOverlayTextAlign(
            teaserOverlayTextAlignValue
          )}
          teaseroverlaystyle={teaserOverlayStyleValue}
        >
          {content}
        </StyledLandscapeBannerArticle>
      </StyledLandscapeBannerWrapper>
      {isVideo && <CmsVideoController playerBanner={playerBanner} />}
      {isShoppableImage && (
        <CmsShoppable
          hotZones={placementHotZones}
          hotZonesSettings={placementHotZonesSettings}
        />
      )}
      {itemHasTermsAndCondition && (
        <LandscapeBannerTermsAndCondition item={item as ILXTeaser} />
      )}
    </>
  )
}
