import { styled } from '@mui/material/styles'
//SWIPER
import {
  ITeaserOverlayStyle,
  ITeaserOverlaySettings,
  ITeaserOverlayTextAlign,
} from '../../../../../types/cmsPlacement/LXTeaser'
import {
  getRatioBanner,
  overLayTextAlignToCSS,
  teaserBackGroundColorToBg,
  teaserOverlayStyleToColor,
} from '@utils/placements'

import CmsTeaserDetailText from '../../../CmsComponents/CmsTeaserDetailText'
import CmsTeaserTitle from '../../../CmsComponents/CmsTeaserTitle'
import { CmsTermsAndConditionsButton } from '../../../CmsComponents/CmsTermsAndConditions'
import CmsProductTile from '../../../CmsComponents/CmsProductTile'
import { StyledAnchor, StyledTypography } from '../../../../UI'
import { IViewType } from '../../../../../types/cmsPlacement/ViewType'

export const BoxWithMarginContainer = styled('div', {
  name: ' BoxWithMargin',
  slot: 'Container',
  shouldForwardProp: (prop) =>
    prop !== 'termsAndConditionsVisible' && prop !== 'order',
})<{
  termsAndConditionsVisible?: boolean
  order?: number
}>(({ theme, order, termsAndConditionsVisible }) => ({
  position: 'relative',
  overflow: termsAndConditionsVisible ? 'hidden' : 'none',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
  padding: theme.spacing(10, 4),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(8),
  },

  [theme.breakpoints.up('md')]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    padding: theme.spacing(16),
    gap: 0,
    'article:first-of-type': {
      order,
    },
  },
  [theme.breakpoints.up('lg')]: {
    gap: theme.spacing(16),
    padding: theme.spacing(16),
  },
}))

export const StyledBoxWithMarginArticle = styled('article', {
  name: 'BoxWithMargin',
  slot: 'Article',
  shouldForwardProp: (prop) =>
    prop !== 'overlaysettings' &&
    prop !== 'overlaytxtalign' &&
    prop !== 'teaseroverlaystyle',
})<{
  overlaysettings?: ITeaserOverlaySettings
  overlaytxtalign?: ITeaserOverlayTextAlign
  teaseroverlaystyle?: ITeaserOverlayStyle
  teaserIndex?: number
}>(({ theme, overlaytxtalign, teaseroverlaystyle }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '& > *': {
    ...teaserOverlayStyleToColor(theme, teaseroverlaystyle),
  },

  '& > *:not(#boxWithMarginCta,[id^=boxWithMarginTermsCondition])': {
    ...overLayTextAlignToCSS(overlaytxtalign),
  },

  [theme.breakpoints.up('md')]: {
    '& > *': {
      ...overLayTextAlignToCSS(overlaytxtalign),
    },
    padding: theme.spacing(16),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 16),
  },
  [theme.breakpoints.up('xl')]: {
    padding: theme.spacing(16),
  },
}))

export const StyledBoxWithMarginPreTitle = styled(StyledTypography, {
  name: 'BoxWithMargin',
  slot: 'PreTitle',
  shouldForwardProp: (prop) => prop !== 'marginProductEnabled',
})<{ marginProductEnabled: boolean }>(({ marginProductEnabled, theme }) => ({
  fontSize: 16,
  marginBottom: theme.spacing(marginProductEnabled ? 2 : 4),
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(marginProductEnabled ? 2 : 6),
  },
}))

export const StyledBoxWithMarginTitle = styled(CmsTeaserTitle, {
  name: 'BoxWithMargin',
  slot: 'Title',
  shouldForwardProp: (prop) => prop !== 'marginProductEnabled',
})<{ marginProductEnabled: boolean }>(({ marginProductEnabled, theme }) => ({
  fontSize: 28,
  textTransform: 'none',
  marginBottom: theme.spacing(marginProductEnabled ? 2 : 4),
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(marginProductEnabled ? 2 : 6),
  },
}))

export const StyledBoxWithMarginTextContainer = styled(CmsTeaserDetailText, {
  name: 'BoxWithMargin',
  slot: 'TextContainer',
})(() => ({
  width: 'unset',
}))

export const StyledBoxWithMarginCTAContainer = styled('div', {
  name: 'BoxWithMargin',
  slot: 'CTAContainer',
  shouldForwardProp: (prop) => prop !== 'marginProductEnabled',
})<{ marginProductEnabled: boolean }>(({ marginProductEnabled, theme }) => ({
  position: 'relative',
  display: 'flex',
  flexFlow: 'column wrap',
  gap: theme.spacing(4),
  marginTop: theme.spacing(6),

  //ctas always centered on mobile
  //alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(marginProductEnabled ? 0 : 8),
  },
}))

export const MediaOverlapBoxWithMarginContainer = styled('div', {
  name: ' ImageOverlap',
  slot: 'BoxWithMarginContainer',
  shouldForwardProp: (prop) => prop !== 'viewType',
})<{ viewType: IViewType }>(({ viewType, theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    margin: 'unset',
  },
  ...getRatioBanner(viewType, theme),
}))

export const MediaOverlapBoxWithMarginAnchor = styled(StyledAnchor, {
  name: 'BoxWithMarginImageOverlap',
  slot: 'Anchor',
})(() => ({
  display: 'block',
  fontSize: 0,
  '&::after': {
    backgroundColor: 'transparent',
  },
  '& > span': {
    display: 'flex !important',
  },
}))

export const BoxWithMarginTermsConditionButton = styled(
  CmsTermsAndConditionsButton,
  {
    name: 'BoxWithMargin',
    slot: 'TermsConditionButton',
  }
)(() => ({}))

export const BoxWithMarginTermsConditionButtonContainer = styled('div', {
  name: ' BoxWithMargin',
  slot: 'TermsConditionButtonContainer ',
})(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  paddingTop: 20,
  alignItems: 'unset',
  [theme.breakpoints.up('md')]: {
    padding: 'unset',
    position: 'absolute',
    bottom: 0,
    width: `calc(100% - ${theme.spacing(24)})`,
  },
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${theme.spacing(32)})`,
  },
  [theme.breakpoints.up('xl')]: {
    width: `calc(100% - ${theme.spacing(32)})`,
  },
}))

export const BoxWithMarginCmsProductTile = styled(CmsProductTile, {
  name: 'BoxWithMargin',
  slot: 'CmsProductTile',
  shouldForwardProp: (prop) => prop !== 'teaserOverlayStyle',
})<{ teaserOverlayStyle?: ITeaserOverlayStyle; backgroundColor?: string }>(
  ({ teaserOverlayStyle, backgroundColor, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    maxWidth: 416,
    'div , a': {
      ...teaserBackGroundColorToBg(backgroundColor, theme),
      ...teaserOverlayStyleToColor(theme, teaserOverlayStyle),
    },
  })
)

export const ProductTileContentBoxWithMargin = styled('div', {
  name: 'BoxWithMargin',
  slot: 'ProductTileContent',
})(() => ({
  display: 'flex',
}))

export const IconContentBoxWithMargin = styled('div', {
  name: 'BoxWithMargin',
  slot: 'IconContent',
  shouldForwardProp: (prop) => prop !== 'marginProductEnabled',
})<{ marginProductEnabled: boolean }>(({ marginProductEnabled, theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(marginProductEnabled ? 1 : 8),
  svg: {
    height: 'inherit',
    width: 'inherit',
  },
}))
