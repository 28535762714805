// Main libraries
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

// Components
import AddressForm from '../../../components/AddressForm'

// Constants
import { ADDRESS_BOOK } from '../../../constants/common'

// Custom Hooks
import { useCheckoutForm } from '../../../hooks/useCheckoutForm'

// Redux
import { shippingFormFieldsSelector } from '../../../redux/selectors/site'

// Services

// Styles
import {
  AddAddressFormButtonContainer,
  AddressFormWrapper,
  EditAddressFormButton,
  EditAddressSubmitSuccessMessage,
  EditAddressSubmitSuccessMessageWrapper,
  EditAddressTitle,
} from '../EditAddress.style'

// Types
import { AddressFormData } from '../../../types/form'
import { CheckoutAddressFormField } from '../../../types/checkout'
import { CheckmarkRoundedValidIcon } from '@components/UI/Icons'
import { useTheme } from '@mui/material'

// Utils

interface IProps {
  formData: AddressFormData | null
  onAddressSaveClick?: (contact: AddressFormData) => Promise<void>
  onAddressFormDataChanged?: ((data: AddressFormData) => void) | undefined
  onFormValidaTionStatusChanged?: ((isValid?: boolean) => void) | undefined
  loading?: boolean
  success?: boolean
  isValid?: boolean
}

const EditAddressForm: React.FC<IProps> = ({
  formData,
  onAddressSaveClick,
  onAddressFormDataChanged,
  onFormValidaTionStatusChanged,
  loading,
  isValid,
  success,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const shippingFormFieldsConf = useSelector(shippingFormFieldsSelector)

  const shippingFormFields: CheckoutAddressFormField[] =
    shippingFormFieldsConf?.filter(
      (i) => i.fieldName !== 'email1' && i.fieldName !== 'fiscalCode'
    ) || []

  const form = useCheckoutForm({
    defaultValues: formData || {},
    fields: shippingFormFields,
  })

  return (
    <AddressFormWrapper>
      <EditAddressTitle>{t('EditAddress.Title')}</EditAddressTitle>
      <AddressForm
        addressFormFields={shippingFormFields}
        addressType={formData?.addressType}
        cid="edit-address"
        form={form}
        page={ADDRESS_BOOK}
        onFormDataChanged={(_, data) => {
          !!onAddressFormDataChanged && onAddressFormDataChanged(data)
        }}
        onFormValidaTionStatusChanged={(type, isValid) => {
          !!onFormValidaTionStatusChanged &&
            onFormValidaTionStatusChanged(isValid)
        }}
      />
      <AddAddressFormButtonContainer>
        <EditAddressFormButton
          data-element-id={'X_X_AddressBook_Edit'}
          variant="primary"
          onClick={() =>
            !!formData && !!onAddressSaveClick && onAddressSaveClick(formData)
          }
          loading={loading}
          disabled={!isValid}
        >
          {t('EditAddress.SaveChanges')}
        </EditAddressFormButton>
        {success && (
          <EditAddressSubmitSuccessMessageWrapper>
            <CheckmarkRoundedValidIcon
              width={16}
              height={16}
              htmlColor={theme.palette.success.main}
            ></CheckmarkRoundedValidIcon>
            <EditAddressSubmitSuccessMessage>
              {t('PersonalInformationSection.Msgs.SuccessLabel')}
            </EditAddressSubmitSuccessMessage>
          </EditAddressSubmitSuccessMessageWrapper>
        )}
      </AddAddressFormButtonContainer>
    </AddressFormWrapper>
  )
}

export default EditAddressForm
