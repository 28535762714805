import { styled } from '@mui/material/styles'
import { Swiper } from 'swiper/react'

export const StyledControlsWrapper = styled('div', {
  name: 'ProductTileGallery',
  slot: 'ControlsWrapper',
  shouldForwardProp: (prop) => prop !== 'isDisplayed' && prop !== 'isCompact',
})<{ isDisplayed?: boolean; isCompact?: boolean }>(
  ({ isDisplayed = true, theme, isCompact = true }) => ({
    zIndex: 1,
    display: isDisplayed ? 'flex' : 'none',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 12),
    paddingBottom: theme.spacing(isCompact ? 8 : 0),
    color: theme.palette.text.dark,
    fontSize: 16,
    '.icon-container': {
      fontSize: 0,
      cursor: 'pointer',
    },

    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(16.5),
      padding: theme.spacing(0, 15),
    },
  })
)

export const StyledControlsHoverWrapper = styled(
  StyledControlsWrapper,
  {}
)(({ theme }) => ({
  justifyContent: 'center',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 7.5),
  },
}))

export const StyledColorLabelContainer = styled('div', {
  name: 'ProductTileGallery',
  slot: 'ColorLabelContainer',
})(() => ({
  width: '90%',
  margin: '0 auto',
}))

export const StyledControlsWrapperColorLabel = styled('div', {
  name: 'ProductTileGallery',
  slot: 'ControlsWrapperColorLabel',
})(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
  textAlign: 'center',
}))

export const StyledSwiper = styled(Swiper, {
  name: 'ProductTileGallery',
  slot: 'Swiper',
  shouldForwardProp: (prop) => prop !== 'isDisplayed',
})<{ isDisplayed: boolean }>(({ isDisplayed, theme }) => ({
  width: isDisplayed ? '100%' : 0,
  flex: isDisplayed ? 1 : 0,
  paddingBottom: theme.spacing(4),

  '.swiper-slide': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
