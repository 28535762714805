import { GridContainer, GridItem, Button } from '../../components/UI'
import ProductImagesSlider from './components/ProductImagesSlider/ProductImagesSlider'
import { Theme, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { gridColumns } from '../../constants/ui'
import { ProductNameWrapper as CLProductNameWrapper } from './components/ProductNameCLSection.style'
import { ProductNameWrapper } from './components/ProductNameSection.style'
import { ProductSizeWrapper } from './components/ProductSize.style'
import { StyledSpecialFrameInfoWrapper } from './components/StyledSpecialFrameInfo'
import { ProductColorSelectorContainer } from './components/ProductColorSelector/ProductColorSelector.style'

const PdpContainer = styled(GridContainer, {
  name: 'PdpContainer',
  slot: 'MainContainer',
})(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  minHeight: '100vh',

  gridTemplateColumns: `repeat(${gridColumns['xs']}, 1fr)`,

  '& > div:nth-of-type(5)': {
    width: '100%',
    backgroundColor: theme.palette.color.grey.lightest,
  },

  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),

    '.pdp-container__breadcrumbs': {
      gridColumn: 'span 12',
      marginLeft: 0,
    },
  },
}))

const PdpLeftColumn = styled(GridItem, {
  name: 'PdpContainer',
  slot: 'LeftColumn',
})(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up(1920)]: {
    gridColumn: 'span 9 / auto',
  },
}))

const StyledTitleContent = styled('div', {
  name: 'PdpContainer',
  slot: 'Title',
})(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}))

const StyledProductName = styled(Typography, {
  name: 'PdpContainer',
  slot: 'ProductName',
})(() => ({}))

const StyledSeparator = styled('hr', {
  name: 'ProductDetails',
  slot: 'Separator',
})(({ theme }) => ({
  height: 1,
  margin: 0,
  border: 'none',
  alignSelf: 'stretch',
  flexGrow: 0,
  backgroundColor: theme.palette.color.grey.light,
}))

const productNameComponentClassNames = [
  ProductNameWrapper,
  CLProductNameWrapper,
]
  .filter((c) => c)
  .join(', ')

const pdpRightColumnFramesContentSorting = (theme: Theme) => ({
  // divider line #1
  [`& > ${StyledSeparator}:nth-of-type(1)`]: {
    display: 'none',
  },

  [theme.breakpoints.down('md')]: {
    '& > *': { order: 6 },
    // product name
    [productNameComponentClassNames]: { order: 1 },
    // divider line #1
    [`${StyledSeparator}:nth-of-type(1)`]: { display: 'initial', order: 2 },
    // product size
    [`& > ${ProductSizeWrapper}`]: { order: 5 },
    // special frame info
    [`& > ${StyledSpecialFrameInfoWrapper}`]: { order: 6 },
    // divider line #2
    [`${StyledSeparator}:nth-of-type(2)`]: { order: 4 },
    // colors (MoCos) selector
    [`& > ${ProductColorSelectorContainer}`]: { order: 3 },
  },
})

const PdpRightColumn = styled(GridItem, {
  name: 'PdpContainer',
  slot: 'RightColumn',
  shouldForwardProp: (prop) =>
    prop !== 'enableFramesContentSorting' && prop !== 'visible',
})<{ enableFramesContentSorting?: boolean; visible?: boolean }>(
  ({ enableFramesContentSorting = false, theme, visible }) => ({
    display: visible ? 'flex' : 'none',
    flexDirection: 'column',
    gap: theme.spacing(4),

    [theme.breakpoints.up('md')]: {
      borderLeft: `1px solid ${theme.palette.color.grey.light}`,
      marginTop: theme.spacing(13),
      paddingLeft: theme.spacing(10),
      width: '100%',
    },

    [theme.breakpoints.up(1920)]: {
      gridColumn: 'span 3 / auto',
    },

    ...(enableFramesContentSorting
      ? pdpRightColumnFramesContentSorting(theme)
      : {}),
  })
)

const StyledLeftColumnContainer = styled('div', {
  name: 'PdpContainer',
  slot: 'LeftColumContainer',
  shouldForwardProp: (prop) => prop === 'visible' || prop === 'children',
})<{
  visible?: 1 | 0
}>(({ visible, theme }) => ({
  display: visible ? 'flex' : 'none',
  flexDirection: 'column',
  width: '100%',
  marginBottom: theme.spacing(20),

  [theme.breakpoints.up('md')]: {
    position: 'sticky',
    top: theme.spacing(3),
  },
}))

const SoldOutImagePlaceholderContainer = styled('div', {
  name: 'PdpContainer',
  slot: 'SoldOutImagePlaceholder',
})(({ theme }) => ({
  background: theme.palette.color.grey.light,
  width: 672,
  height: 336,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(8),
  marginRight: theme.spacing(19.5),
  marginTop: theme.spacing(21),
  padding: theme.spacing(18, 0),
}))

const SoldOutTitle = styled('div', {
  name: 'ProductDetails',
  slot: 'SoldOutTitle',
})(({ theme }) => ({
  fontSize: theme.spacing(10),
  color: theme.palette.text.light,
}))

const SoldOutDescription = styled('div', {
  name: 'ProductDetails',
  slot: 'SoldOutDescription',
})(({ theme }) => ({
  width: 304,
  fontSize: theme.spacing(5),
  color: theme.palette.text.light,
}))

const SliderWrapper = styled('div', {
  name: 'PdpDetails',
  slot: 'SliderWrapper',
})(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignSelf: 'center',
  width: '100%',
  height: 354,
}))

const ProductFullPageGalleryThumbsSlider = styled(ProductImagesSlider, {
  name: 'ProductFullPageGallery',
  slot: 'ThumbsSlider',
})(({ theme }) => ({
  width: '100%',
  '.swiper': {
    padding: theme.spacing(0, 4, 6, 4),
  },
  '.swiper-slide': {
    cursor: 'pointer',
    position: 'relative',
    width: `calc(${theme.spacing(20)} - 2px )`,
    height: `calc(${theme.spacing(14)} - 2px )`,
    border: `1px solid  ${theme.palette.text.light}`,
    paddingBottom: '2px',
    '&:hover': {
      border: `1px solid  ${theme.palette.text.dark}`,
    },
    '&.selected': {
      border: `1px solid  ${theme.palette.text.dark}`,
    },
    img: {
      objectFit: 'contain',
      height: '100%',
    },
    'span.lazy-load-image-background': {
      width: '100%',
      height: 56,
      lineHeight: '0',
    },
  },
  '.swiper-horizontal .swiper-pagination-progressbar': {
    width: '50vw',
    left: '25vw',
    height: '2px',
    top: '60%',
    bottom: '0',
    backgroundColor: theme.palette.color.grey.light,
    '&-fill': {
      backgroundColor: theme.palette.color.black.main,
    },
  },
}))

const ProductFullPageGalleryImagesSlider = styled(ProductImagesSlider, {
  name: 'ProductFullPageGallery',
  slot: 'ImagesSlider',
})(({ theme }) => ({
  marginBottom: theme.spacing(15),
}))

const ProductImagesMainSlider = styled(ProductImagesSlider, {
  name: 'ProductImages',
  slot: 'MainSlider',
})(({ theme }) => ({
  paddingTop: theme.spacing(8),
  minHeight: theme.spacing(52.5),
  position: 'relative',

  [theme.breakpoints.up('md')]: {
    width: `calc(100% - ${theme.spacing(32)})`,
    paddingRight: theme.spacing(16),
    paddingLeft: theme.spacing(16),
    flex: '1 0 auto',

    '.swiper': {
      cursor:
        "url(\"data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%238afjeh34ua)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20.436 19.73a6.5 6.5 0 1 0-.707.707l4.418 4.417.707-.707-4.418-4.418z' fill='%23fff'/%3E%3C/g%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20.436 19.73a6.5 6.5 0 1 0-.707.707l4.418 4.417.707-.707-4.418-4.418zM15.5 21a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zM12 15.002v1h3V19h1v-2.998h3v-1h-3V12h-1v3.002h-3z' fill='%2300303C'/%3E%3Cdefs%3E%3Cfilter id='8afjeh34ua' x='6' y='7' width='21.855' height='21.854' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='1'/%3E%3CfeGaussianBlur stdDeviation='1.5'/%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0'/%3E%3CfeBlend in2='BackgroundImageFix' result='effect1_dropShadow_1587_230429'/%3E%3CfeBlend in='SourceGraphic' in2='effect1_dropShadow_1587_230429' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E\") 32 32, pointer",
    },
  },
}))

const ProductZoomImagesSlider = styled(ProductImagesSlider, {
  name: 'ProductZoom',
  slot: 'ImagesSlider',
})(({ theme }) => ({
  padding: '0',
  maxHeight: 'inherit',
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(16),
  },

  // ProductImageZoomMagnifier
  '& + div': {
    visibility: 'hidden',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(16),
    },
  },

  // ProductImageZoomMagnifier
  '&:hover + div': {
    visibility: 'visible',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(16),
    },
  },
}))

const StyledShippingTimesContainer = styled('div', {
  name: 'ShippingTimesContainer',
  slot: 'ShippingTimesContainer',
})(({ theme }) => ({
  padding: theme.spacing(4),
  color: theme.palette.text.dark,
  backgroundColor: theme.palette.color.grey.lightest,
  fontSize: 12,
  borderRadius: 16,
}))

const StyledSectionContainer = styled('div', {
  shouldForwardProp: (prop) => prop === 'visible' || prop === 'children',
})<{
  visible?: boolean
}>(({ visible }) => ({
  display: visible ? 'flex' : 'none',
}))

const StyledTrustPilotSectionContainer = styled('div')(() => ({
  gridColumn: '1 / -1',
}))

const StyledLoaderContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}))

const StyledSuggestionContainer = styled('div')(({ theme }) => ({
  gridColumn: '1 / -1',
  // minHeight: '351px',
  backgroundColor: theme.palette.color.grey.lightest,
  [theme.breakpoints.up('md')]: {
    minHeight: '451px',
  },
}))

const StyledSelectLensesCTA = styled(Button)(({ theme }) => ({
  minWidth: 163,
  height: 40,
  flexGrow: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  whiteSpace: 'nowrap',
}))

const StyledRelatedProduct = styled(Link, {
  name: 'PdpDetails',
  slot: 'RelatedProduct',
})(({ theme }) => ({
  display: 'flex',
  fontSize: 12,
  textDecoration: 'none',
  color: theme.palette.text.dark,
  fontWeight: 600,
  alignItems: 'center',
  cursor: 'pointer',

  span: {
    textDecoration: 'underline',
  },
}))

const StyledImageWrapper = styled('div', {
  name: 'PdpDetails',
  slot: 'ImageWrapper',
})(() => ({
  height: 35,
  width: 66,
}))

const StyledCLAccessoriesContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'CLAccessoriesContainer',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  color: theme.palette.text.dark,
}))

const StyledCLAccessoriesPrice = styled('div', {
  name: 'PdpDetails',
  slot: 'CLAccessoriesPrice',
})({
  display: 'flex',
  alignItems: 'flex-end',
})

const StyledCLAccessoriesLabel = styled('div', {
  name: 'PdpDetails',
  slot: 'CLAccessoriesLabel',
})({
  fontSize: 12,
  fontWeight: 600,
  display: 'flex',
  flexGrow: 2,
})

const StyledCLAccessoriesInitialLabel = styled('span', {
  name: 'PdpDetails',
  slot: 'CLAccessoriesInitialLabel',
})(({ theme }) => ({
  fontSize: 12,
  textDecoration: 'line-through',
  paddingRight: theme.spacing(1.5),
}))

const StyledCLAccessoriesSelect = styled('div', {
  name: 'PdpDetails',
  slot: 'CLAccessoriesSelect',
})(({ theme }) => ({
  fontSize: 12,
  display: 'flex',
  flexGrow: 1,
  '.MuiSelect-icon': {
    top: `${theme.spacing(1.8)} !important`,
  },
  '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.color.grey.dark,
  },
  '.MuiSelect-iconOpen': {
    transform: 'rotate(0deg)',
  },
  '.is-select': {
    minHeight: '33px',
  },
}))

const StyledCLAccessoriesWrapper = styled('div', {
  name: 'PdpDetails',
  slot: 'CLAccessoriesWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: 14,
  gap: theme.spacing(4),
  color: theme.palette.text.dark,
}))

const StyledProductBoxesQuantity = styled('div', {
  name: 'PdpDetails',
  slot: 'BoxesQuantity',
})(() => ({
  display: 'flex',
  justifyContent: 'start',
}))

const FramesPdpRightColumnInfoContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'FrameInfoContainer',
})(({ theme }) => ({
  padding: theme.spacing(3, 0),
  fontSize: 12,
}))

const FramesPdpRightColumnInfo = styled('div', {
  name: 'PdpDetails',
  slot: 'FrameInfo',
})(({ theme }) => ({
  marginBottom: theme.spacing(2),
  'span:first-of-type': {
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(1),
  },
}))

const VirtualMirrorButton = styled(Button, {
  name: 'PdpDetails',
  slot: 'VirtualMirrorButton',
})(({ theme }) => ({
  width: 'auto',
  margin: theme.spacing(5, 'auto'),

  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(10, 'auto', 0),
  },
}))

export {
  PdpContainer,
  PdpLeftColumn,
  PdpRightColumn,
  VirtualMirrorButton,
  SoldOutImagePlaceholderContainer,
  SoldOutTitle,
  SoldOutDescription,
  StyledTitleContent,
  StyledProductName,
  StyledSectionContainer,
  StyledTrustPilotSectionContainer,
  StyledLeftColumnContainer,
  StyledSeparator,
  ProductImagesMainSlider,
  ProductFullPageGalleryThumbsSlider,
  ProductFullPageGalleryImagesSlider,
  ProductZoomImagesSlider,
  SliderWrapper,
  StyledLoaderContainer,
  StyledSuggestionContainer,
  StyledSelectLensesCTA,
  StyledRelatedProduct,
  StyledImageWrapper,
  StyledCLAccessoriesContainer,
  StyledCLAccessoriesLabel,
  StyledCLAccessoriesSelect,
  StyledCLAccessoriesPrice,
  StyledCLAccessoriesInitialLabel,
  StyledCLAccessoriesWrapper,
  StyledShippingTimesContainer,
  StyledProductBoxesQuantity,
  FramesPdpRightColumnInfo,
  FramesPdpRightColumnInfoContainer,
}
