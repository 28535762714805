import { styled } from '@mui/material/styles'
//SWIPER
import { Swiper } from 'swiper/react'
import {
  ITeaserOverlayStyle,
  ITeaserOverlaySettings,
  ITeaserOverlayTextAlign,
} from '../../../../../types/cmsPlacement/LXTeaser'
import {
  getRatioBanner,
  overLayTextAlignToCSS,
  teaserOverlayStyleToColor,
} from '@utils/placements'

import CmsTeaserDetailText from '../../../CmsComponents/CmsTeaserDetailText'
import CmsTeaserTitle from '../../../CmsComponents/CmsTeaserTitle'
import { CmsShoppable } from '../../../CmsComponents/CmsShoppable/CmsShoppableImage'
import { StyledAnchor, StyledTypography } from '../../../../UI'
import { IViewType } from '../../../../../types/cmsPlacement/ViewType'

export const BoardWithFieldsCarouselContainer = styled('div', {
  name: 'BoardWithFields',
  slot: 'CarouselContainer',
})(() => ({
  display: 'flex',
  position: 'relative',
}))

export const BoardWithFieldsContainer = styled('div', {
  name: 'BoardWithFields',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    padding: theme.spacing(0, 16),
    gap: theme.spacing(2),
  },
}))

export const BoardWithFieldsItem = styled('div', {
  name: 'BoardWithFields',
  slot: 'item',
})(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    flex: '1 1',
    img: {
      verticalAlign: 'bottom',
    },
  },
}))

export const BoardWithFieldsSlider = styled(Swiper, {
  name: 'BoardWithFields',
  slot: 'Slider',
})(({ theme }) => ({
  maxWidth: '100vw',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  '.swiper-wrapper': {
    minWidth: '100%',
  },
  '.swiper-pagination': {
    marginTop: theme.spacing(8),

    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(6.5),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(10),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(10),
    },
  },
  '.swiper-slide': {
    display: 'flex',
    width: '90%',
    [theme.breakpoints.up('sm')]: {
      width: '90%',
      '&:first-of-type': {
        marginLeft: theme.spacing(8),
      },
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      maxWidth: 'calc(100% / 3.2)',
      '&:first-of-type': {
        marginLeft: theme.spacing(8),
      },
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      maxWidth: 'calc(100% / 3.2)',
      '&:first-of-type': {
        marginLeft: theme.spacing(16),
      },
    },
  },
  '.custom-progressbar': {
    width: '200px',
    height: '2px',
    position: 'relative',
    backgroundColor: theme.palette.color.grey.light,
    order: '1',
    span: {
      left: '0',
      position: 'absolute',
      width: '100%',
      height: '100%',
      transformOrigin: 'left',
      backgroundColor: theme.palette.primary.main,
    },
  },
  '.swiper-scrollbar.swiper-scrollbar-horizontal': {
    position: 'relative',
    order: 1,
    borderRadius: 2,
    height: 2,
    width: 200,
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: theme.palette.color.grey.light,

    marginTop: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(6.5),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(10),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(10),
    },

    '.swiper-scrollbar-drag': {
      height: 2,
      backgroundColor: theme.palette.primary.main,
    },
  },
}))

export const StyledBoardWithFieldsArticle = styled('article', {
  name: 'BoardWithFields',
  slot: 'Article',
  shouldForwardProp: (prop) =>
    prop !== 'overlaysettings' &&
    prop !== 'overlaytxtalign' &&
    prop !== 'teaseroverlaystyle',
})<{
  overlaysettings?: ITeaserOverlaySettings
  overlaytxtalign?: ITeaserOverlayTextAlign
  teaseroverlaystyle?: ITeaserOverlayStyle
}>(({ theme, overlaytxtalign, teaseroverlaystyle }) => ({
  //margin: 'unset',
  //width: 'auto',
  padding: theme.spacing(4),
  display: 'flex',
  gap: theme.spacing(2),
  flexDirection: 'column',
  //justifyContent: 'flex-end',
  '& > *': {
    textAlign: 'center',
    ...teaserOverlayStyleToColor(theme, teaseroverlaystyle),
  },

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(6, 16, 0),
    '& > *': {
      ...overLayTextAlignToCSS(overlaytxtalign),
    },
  },
}))

export const StyledBoardWithFieldsPreTitle = styled(StyledTypography, {
  name: 'BoardWithFields',
  slot: 'PreTitle',
})(() => ({
  fontSize: 16,
}))

export const StyledBoardWithFieldsTitle = styled(CmsTeaserTitle, {
  name: 'BoardWithFields',
  slot: 'Title',
})(() => ({}))

export const StyledBoardWithFieldsTextContainer = styled(CmsTeaserDetailText, {
  name: 'BoardWithFields',
  slot: 'TextContainer',
})(() => ({
  width: 'unset',
  '& > ul, & > ol': {
    listStylePosition: 'inside',
  },
}))

export const StyledBoardWithFieldsCTAContainer = styled('div', {
  name: 'BoardWithFields',
  slot: 'CTAContainer',
})(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexFlow: 'column wrap',
  width: '100%',
  gap: theme.spacing(4),
  marginTop: theme.spacing(4),
  boxSizing: 'border-box',
}))

export const MediaOverlapBoardWithFieldsContainer = styled('div', {
  name: 'ImageOverlap',
  slot: 'BoardWithFieldsContainer',
  shouldForwardProp: (prop) => prop !== 'viewType' && prop !== 'countItems',
})<{ viewType: IViewType; countItems: number }>(
  ({ viewType, countItems, theme }) => ({
    width: '100%',
    position: 'relative',
    ...getRatioBanner(viewType, theme, countItems),
  })
)

export const MediaOverlapBoardWithFieldsAnchor = styled(StyledAnchor, {
  name: ' MediaOverlapBoardWithFieldsImageOverlap',
  slot: 'Anchor',
})(() => ({
  display: 'block',
  height: '100%',
  fontSize: 0,

  '&::after': {
    backgroundColor: 'transparent',
  },
  '& > span': {
    display: 'flex !important',
  },
}))

export const BoardWithFieldsCmsShoppable = styled(CmsShoppable, {
  name: 'BoardWithFields',
  slot: 'ShoppbaleImg',
})(({ theme }) => ({
  left: theme.spacing(6),
}))
