import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  StyledSuggestedProductTileHeader,
  SuggestedProductTilePrimaryBadge,
  SuggestedProductTileSecondaryBadge,
} from '@components/SuggestedProducts/components/SuggestedProductTile/SuggestedProductTile.style'
import { ProductAlgolia } from '../../../../../../types/ProductAlgolia/ProductAlgolia'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import ProductTileUtils from '@utils/ProductAlgolia/ProductTileAlgoliaUtils'
import { SmartGlassesIcon } from '@components/UI/Icons'

export interface ProductTileHeaderProps {
  clusterSelected: ProductAlgolia
}
const SuggestedProductTileHeaderAlgolia: React.FC<ProductTileHeaderProps> = ({
  clusterSelected,
}) => {
  const { t } = useTranslation()
  const attributes = clusterSelected.attributes_translated

  const { badges } = ProductTileUtils.getProductTileAttributes(attributes)
  const { primaryBadge, secondaryBadge } = badges

  const { productType } = ProductAlgoliaUtils.getProductAttributes(attributes)
  const { isElectronics } = ProductAlgoliaUtils.getLxProductTypes(productType)

  return (
    <StyledSuggestedProductTileHeader>
      <SuggestedProductTilePrimaryBadge>
        {isElectronics ? (
          <>
            <SmartGlassesIcon />
            <>{t('ProductTile.Labels.SmartGlasses')}</>
          </>
        ) : (
          <>
            {primaryBadge ? t(`ProductTile.Labels.${primaryBadge}`) : '\u00A0'}
          </>
        )}
      </SuggestedProductTilePrimaryBadge>
      <SuggestedProductTileSecondaryBadge>
        {secondaryBadge
          ? t(`ProductTile.Labels.${badges.secondaryBadge}`)
          : '\u00A0'}
      </SuggestedProductTileSecondaryBadge>
    </StyledSuggestedProductTileHeader>
  )
}

export default SuggestedProductTileHeaderAlgolia
