import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { CmsShoppableImageIcon, CmsShoppableImageOverlay } from './index'
import {
  IHotzone,
  IHotzoneSettings,
} from '../../../../../types/cmsPlacement/LXTeaser'
import { Z_INDEX_LV2 } from '../../../../../constants/ui'

export const ShoppableContainer = styled('div', {
  name: 'Shoppable',
  slot: 'Container',
  shouldForwardProp: (prop) => prop !== 'isLightContrast',
})<{ isLightContrast?: boolean }>(({ theme, isLightContrast }) => ({
  cursor: 'pointer',
  position: 'absolute',
  left: theme.spacing(4),
  top: theme.spacing(4),
  backgroundColor: isLightContrast
    ? theme.palette.primary.dark
    : theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'center',
  //overflow: 'hidden',
  borderRadius: '50vmax',
  zIndex: Z_INDEX_LV2,
}))

// TODO: ANIMATION MUST BE COMPLETED
const CmsShoppable: React.FC<{
  hotZones?: IHotzone[]
  hotZonesSettings?: IHotzoneSettings
}> = ({ hotZones, hotZonesSettings, ...rest }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const isLightContrast = hotZonesSettings?.lightContrast
  return (
    <ShoppableContainer isLightContrast={isLightContrast} {...rest}>
      <CmsShoppableImageIcon
        isLightContrast={isLightContrast}
        onIconHover={() => setVisible(true)}
      />
      <CmsShoppableImageOverlay
        onIconOut={() => setVisible(false)}
        visible={visible}
        hotZones={hotZones}
      />
    </ShoppableContainer>
  )
}

export default CmsShoppable
