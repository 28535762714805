import * as ROUTES from '../../constants/routes'
import { generatePath } from 'react-router-dom'

export const useCheckoutPaths = (country: string) => {
  return {
    checkout: generatePath(`/:country/${ROUTES.CHECKOUT}`, { country }),
    cart: generatePath(`/:country/${ROUTES.CART}`, { country }),
    shipping: generatePath(
      `/:country/${ROUTES.CHECKOUT}/${ROUTES.CHECKOUT_CHILDREN.SHIPPING}`,
      {
        country,
      }
    ),
    payment: generatePath(
      `/:country/${ROUTES.CHECKOUT}/${ROUTES.CHECKOUT_CHILDREN.PAYMENT}`,
      {
        country,
      }
    ),
    'order-confirmation': generatePath(
      `/:country/${ROUTES.CHECKOUT}/${ROUTES.CHECKOUT_CHILDREN.ORDER_CONFIRMATION}`,
      { country }
    ),
    uploadPrescription: generatePath(
      `/:country/${ROUTES.CHECKOUT}/${ROUTES.CHECKOUT_CHILDREN.UPLOAD_PRESCRIPTION}`,
      { country }
    ),
  }
}
