import { styled } from '@mui/material/styles'
import {
  ProductPriceCurrentPriceFinal,
  ProductPriceInitialPriceFinal,
} from '@components/ProductPriceFinal/ProductPriceFinal.style'

export const ProductPriceCurrentPriceCart = styled(
  ProductPriceCurrentPriceFinal,
  {
    name: 'ProductPrice',
    slot: 'CurrentPriceCart',
  }
)(({ theme }) => ({
  fontWeight: 'bold',
  [theme.breakpoints.up('sm')]: {
    fontSize: 16,
  },
}))

export const ProductPriceCurrentPriceCartPerBox = styled(
  ProductPriceCurrentPriceFinal,
  {
    name: 'ProductPrice',
    slot: 'CurrentPriceCartPerBox',
  }
)(({ theme }) => ({
  fontSize: 12,
  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
}))
export const ProductPriceInitialPriceCartPerBox = styled(
  ProductPriceInitialPriceFinal,
  {
    name: 'ProductPrice',
    slot: 'InitialPriceCartPerBox',
  }
)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.dark,
  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
}))
