import { BASE } from '../constants/routes'
import { cmsImageCrops } from '../constants/ui'
import config from '../configs'
import qs from 'qs'
import queryString from 'query-string'

export const formatPathWithBase = (path: string, basePath: string): string => {
  return path.includes(BASE)
    ? path.replace(BASE, basePath)
    : basePath + (path.startsWith('/') ? path : `/${path}`)
}

/**
 * Update URL query string values
 * @returns {string}
 */
export const updateUrlParameter = (
  url: string,
  key: string,
  value: number | boolean | string | undefined | null
): string => {
  const [baseurl, search] = url.split('?')
  const querystring = qs.parse(search)
  querystring[key] = value == null ? '' : value.toString()

  return `${baseurl}?${qs.stringify(querystring)}`
}

export const replaceUrl = (url) => {
  window.history.replaceState({}, '', url)
}

export const updateUrl = (url, parameters?) => {
  const updatedUrl = queryString.stringifyUrl(
    {
      url,
      query: parameters,
    },
    {
      encode: false,
    }
  )
  window.history.pushState({}, '', updatedUrl)
}

export const updateUrlWithParams = (url, parameters?) => {
  const updatedUrl = queryString.stringifyUrl(
    {
      url,
      query: parameters,
    },
    {
      encode: false,
    }
  )
  window.history.replaceState({}, '', updatedUrl)
}

export const formattedParams = (params, encode: boolean = false) => {
  return queryString.stringifyUrl(
    {
      url: '',
      query: params,
    },
    {
      encode,
    }
  )
}

export const fromUrlParamsToObject = (url: string, decode = false) => {
  return queryString.parse(url, { decode })
}

export const getImageFromCMS = (
  url: string,
  crop?: string,
  width?: number | string
) => {
  if (!url) {
    return ''
  }
  if (crop) {
    const rep = url.replace(
      '/{cropName}/{width}',
      `/${crop || 'NOTFOUND'}/${width || 'NOTFOUND'}`
    )
    return `${config.cmsImageServerUrl}${rep}`
  }
  return undefined
}

export const getSrcSetsImageFromCms = (type: string, url: string) => {
  const bannerCropOption = cmsImageCrops[type]
  const { mobile, deskL, deskS, tabletL, tabletP } = bannerCropOption || {}

  return {
    mobile: getImageFromCMS(url, mobile?.crop || '', mobile?.width),
    deskL: getImageFromCMS(url, deskL?.crop || '', deskL?.width),
    deskS: getImageFromCMS(url, deskS?.crop || '', deskS?.width),
    tabletL: getImageFromCMS(url, tabletL?.crop || '', tabletL?.width),
    tabletP: getImageFromCMS(url, tabletP?.crop || '', tabletP?.width),
  }
}

export const getVideoFromCMS = (url: string) =>
  `${config.cmsImageServerUrl}/${url}`

/**
 * removeStoreSegment remove the store segment of the url
 * retrieved from CMS
 * @param  url the url to parse
 *
 */
export const removeStoreSegment = (url) => {
  return url
    .split('/')
    .filter((item) => item.length > 0)
    .join('/')
    .replace('//', '/')
}

export const seoUrlConcat = (
  langCode: string,
  seoHref: string,
  absoluteUrl = true
) => {
  let url = !langCode.startsWith('/') && absoluteUrl ? `/${langCode}` : langCode
  let divider = '/'

  if (url && url.endsWith('/')) {
    url = url.slice(0, -1)
  }

  if (seoHref && seoHref.startsWith('/')) {
    divider = ''
  }

  return [url, seoHref].join(divider)
}
