import { styled } from '@mui/material/styles'
import { Z_INDEX_LV4 } from '../../../../constants/ui'
import { TPlayerBannerHook } from '../../../../hooks/useBannerPlayer'
import { IconButton } from '@components/UI'
import {
  PauseIcon,
  PlayIcon,
  SoundOffIcon,
  SoundOnIcon,
} from '@components/UI/Icons'
import React from 'react'
import { useTheme } from '@mui/material/styles'

export const ControllerWrapper = styled('div', {
  name: 'CMSCommonMedia',
  slot: 'ControllerWrapper',
})(({ theme }) => ({
  position: 'absolute',
  top: 24,
  right: 24,
  borderRadius: 50,
  padding: theme.spacing(2, 4),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4),
  backdropFilter: 'blur(16px)',
  backgroundColor: 'rgba(31, 31, 36, 0.4)',
  zIndex: Z_INDEX_LV4,
}))

const CmsVideoController: React.FC<{ playerBanner: TPlayerBannerHook }> = ({
  playerBanner,
}) => {
  const theme = useTheme()
  return (
    <ControllerWrapper>
      {playerBanner.isPlaying ? (
        <IconButton
          aria-label="Pause"
          onClick={() => playerBanner.setIsPlaying((prev) => !prev)}
        >
          <PauseIcon htmlColor={theme.palette.color.white.main} />
        </IconButton>
      ) : (
        <IconButton
          aria-label="Play"
          onClick={() => playerBanner.setIsPlaying((prev) => !prev)}
        >
          <PlayIcon htmlColor={theme.palette.color.white.main} />
        </IconButton>
      )}
      {playerBanner.muted ? (
        <IconButton
          aria-label="Mute"
          onClick={() => playerBanner.setMuted((prev) => !prev)}
        >
          <SoundOffIcon htmlColor={theme.palette.color.white.main} />
        </IconButton>
      ) : (
        <IconButton
          aria-label="Unmute"
          onClick={() => playerBanner.setMuted((prev) => !prev)}
        >
          <SoundOnIcon htmlColor={theme.palette.color.white.main} />
        </IconButton>
      )}
    </ControllerWrapper>
  )
}

export default CmsVideoController
