import React, { PropsWithChildren } from 'react'
import {
  ITeaserOverlaySettings,
  ITeaserOverlayTextAlign,
} from '../../../../types/cmsPlacement/LXTeaser'
import { StyledCmsBroadContentWrapper } from './CmsBroadContentWrapper.style'
/**
 *
      THIS IS THE MAIN WRAPPER FOR BROAD CONTENT BANNERS.
      DO NOT MOFIDY THIS COMPONENT.
      CREATE EXCEPTIONS INSTEAD
 */
export interface CmsBroadContentWrapperProps {
  istextoverlay?: boolean
  overlaytxtalign?: ITeaserOverlayTextAlign
  overlaysettings?: ITeaserOverlaySettings
}

const CmsBroadContentWrapper: React.FC<
  PropsWithChildren<CmsBroadContentWrapperProps>
> = ({ istextoverlay, overlaysettings, children, ...rest }) => {
  return (
    <StyledCmsBroadContentWrapper
      istextoverlay={istextoverlay}
      overlaysettings={overlaysettings}
      {...rest}
    >
      {children}
    </StyledCmsBroadContentWrapper>
  )
}

export default CmsBroadContentWrapper
