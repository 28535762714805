import { styled } from '@mui/material/styles'
import { Button, StyledAnchor, StyledTypography } from '@components/UI'

export const StoreInfoContainer = styled('div', {
  name: 'StoreDetails',
  slot: 'StoreInfoContainer',
})(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column-reverse',
  fontFamily: theme.typography.fontFamily,

  [theme.breakpoints.up('md')]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    justifyContent: 'space-between',
    gap: theme.spacing(4),
    maxHeight: 550,
  },
}))
export const StoreInfoLeftColumn = styled('div', {
  name: 'StoreDetails',
  slot: 'StoreInfoLeftColumn',
})(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'auto',
  display: 'flex',
  gap: theme.spacing(6),
  flexDirection: 'column',
  fontFamily: theme.typography.fontFamily,
  [theme.breakpoints.up('md')]: {
    maxWidth: '54%',
    justifyContent: 'space-between',
  },
}))

export const StoreInfoRightColumn = styled('div', {
  name: 'StoreDetails',
  slot: 'StoreInfoRightColumn',
})(() => ({
  position: 'relative',
  width: '100%',
  height: '100%',
}))

export const StoreBadgeName = styled(StyledTypography, {
  name: 'StoreDetails',
  slot: 'StoreBadgeName',
})(() => ({}))

export const StoreBadgeAddress = styled('span', {
  name: 'StoreDetails',
  slot: 'StoreBadgeAddress',
})(({ theme }) => ({
  fontSize: 14,
  fontWeight: 300,
  color: theme.palette.text.dark,
}))

export const StoreBadgeText = styled(StyledTypography, {
  name: 'StoreDetails',
  slot: 'StoreBadgeAddress',
})(() => ({}))

export const StoreBadgePhone = styled(StyledAnchor, {
  name: 'StoreDetails',
  slot: 'StoreBadgePhone',
})(() => ({
  fontWeight: 600,
}))

export const IndicationCTAContainer = styled('div', {
  name: 'StoreDetails',
  slot: 'IndicationCTAContainer',
})(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  fontFamily: theme.typography.fontFamily,
  fontSize: 14,
  margin: theme.spacing(4, 0, 3),
}))

export const DirectionsCTALink = styled(StyledAnchor, {
  name: 'StoreDetails',
  slot: 'DirectionsCTALink',
})(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
  color: theme.palette.text.dark,
}))

export const MapImage = styled('img', {
  name: 'StoreDetails',
  slot: 'MapImage',
})(({ theme }) => ({
  width: '100%',
  height: '100%',
  marginBottom: theme.spacing(6),
  aspectRatio: '1/1',
  objectFit: 'cover',

  [theme.breakpoints.up('md')]: {
    aspectRatio: '86/55',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    position: 'relative',
    top: 0,
    left: 0,
    transform: 'unset',
  },
}))

export const MarkerImage = styled('img', {
  name: 'StoreDetails',
  slot: 'MarkerImage',
})(() => ({
  position: 'absolute',
  bottom: '50%',
  right: '50%',
  width: 40,
  height: 50,
}))

export const StoreDetailsBookAppointmentButton = styled(Button, {
  name: 'StoreDetails',
  slot: 'BookAppointmentButton',
})(() => ({
  fontSize: 14,
  width: 'auto',
}))

export const OpeningHoursContainer = styled('div', {
  name: 'StoreDetails',
  slot: 'OpeningHoursContainer',
})(({ theme }) => ({
  table: {
    width: 'fit-content',
    maxHeight: 'fit-content',
    fontSize: 12,
    gap: theme.spacing(2),
    tr: {
      gap: theme.spacing(4),
    },
    'th, td': {
      textTransform: 'capitalize',
      fontSize: 'inherit',
    },
  },
}))
