import React from 'react'
import { ILXTeaser } from '../../../../../types/cmsPlacement/LXTeaser'

import {
  IconContentTopBanner,
  StyledTopPageBannerArticle,
  StyledTopPageBannerCountDown,
  StyledTopPageBannerCTAContainer,
  StyledTopPageBannerPreTitle,
  StyledTopPageBannerTextContainer,
  StyledTopPageBannerTitle,
  StyledTopPageBannerWrapper,
} from './TopPageBanner.style'
import CmsProductTile from '../../../CmsComponents/CmsProductTile'
import TopPageMediaOverlap from './components/TopPageMediaOverlap'
import CmsCta from '../../../CmsComponents/CmsCta'
import useCmsTeaserBanner from '../../../../../hooks/useCmsBanner'
import { CmsShoppable } from '../../../CmsComponents/CmsShoppable/CmsShoppableImage'
import { TopPageBannerTermsAndCondition } from './components/TopPageBannerAndCondition'
import { ITopPageBannerProps } from './index'
import usePlayerBanner from '../../../../../hooks/useBannerPlayer'
import CmsFullBannerAnchor from '../../../CmsComponents/CmsFullBannerAnchor'
import { getTeaserOverlayTextAlign } from '@utils/placements'
import CmsIcon from '../../../CmsComponents/CmsIcon'
import CmsVideoController from '@components/Cms/CmsComponents/CmsCommonMedia/CmsVideoController'
import { isLXTeaser } from '../../../../../types/cmsPlacement/Placement'
import { IVideoMedia } from '../../../../../types/cmsPlacement/Media'

export const TopPageDesktopBanner: React.FC<ITopPageBannerProps> = ({
  placement,
  teaserIndex,
  bannerIndex,
}) => {
  const crop = 'TOP_PAGE_BANNER'
  const viewType = placement.viewtype
  const item =
    'items' in placement
      ? placement?.items?.find(isLXTeaser)
      : placement?.teasableItems?.find(isLXTeaser)
  const {
    teaserTitle,
    teaserText,
    teaserTitleValue,
    teaserTextValue,
    teaserPreTitle,
    teaserOverlaySettingsValue,
    teaserOverlayTextAlignValue,
    teaserOverlayStyleValue,
    promoteToH1,
    teaserLXCallToActionSettings,
    teaserIcon,
    teaserCountdownStart,
    teaserCountdownUntil,
    teaserHideExpiredCountdown,
    placementHotZones,
    placementHotZonesSettings,
    isShoppableImage,
    toLink,
    productData,
    itemHasTermsAndCondition,
    isFrameAdvisor,
    isVideo,
    media,
  } =
    useCmsTeaserBanner({
      item,
      //placement,
      viewType,
    }) || {}

  const playerBanner = usePlayerBanner(media! as IVideoMedia)

  const content = (
    <>
      {teaserIcon && (
        <IconContentTopBanner>
          <CmsIcon teaserIcon={teaserIcon} />
        </IconContentTopBanner>
      )}
      {teaserCountdownStart &&
        teaserCountdownUntil &&
        teaserHideExpiredCountdown && (
          <StyledTopPageBannerCountDown
            teaserCountdownStart={teaserCountdownStart}
            teaserCountdownUntil={teaserCountdownUntil}
            teaserHideExpiredCountdown={teaserHideExpiredCountdown}
          />
        )}
      <>
        {teaserPreTitle && (
          <>
            <StyledTopPageBannerPreTitle
              data-cm-metadata={'[{"_":"properties.teaserPreTitle"}]'}
              variant={promoteToH1 ? 'h1' : 'h2'}
              fontWeight={'600'}
            >
              {teaserPreTitle}
            </StyledTopPageBannerPreTitle>
          </>
        )}
        {teaserTitleValue && (
          <StyledTopPageBannerTitle
            data-cm-metadata={`[{"_":"properties.${teaserTitle}"}]`}
            variant={promoteToH1 ? 'h1' : 'h2'}
          >
            {teaserTitleValue}
          </StyledTopPageBannerTitle>
        )}
        {teaserTextValue && (
          <StyledTopPageBannerTextContainer
            data-cm-metadata={`[{"_":"properties.${teaserText}"}]`}
            dangerouslySetInnerHTML={{ __html: teaserTextValue }}
          />
        )}
      </>
      {productData !== null && (
        <CmsProductTile product={productData} tileIndex={0} />
      )}
      {!!teaserLXCallToActionSettings &&
        teaserLXCallToActionSettings?.length > 0 && (
          <StyledTopPageBannerCTAContainer
            id="TopPageCtaContainer"
            data-element-id={isFrameAdvisor && 'Footer_FrameGenius_Open'}
          >
            {teaserLXCallToActionSettings?.map((actionSettings, index) => (
              <CmsCta
                actionSettings={actionSettings}
                dataElementId={`${teaserIndex}Placement_Banner${bannerIndex}_CTA${
                  index + 1
                }`}
                key={`cms-content__text-module--cta-${index + 1}`}
              />
            ))}
          </StyledTopPageBannerCTAContainer>
        )}
    </>
  )

  return (
    <>
      <CmsFullBannerAnchor
        toLink={toLink}
        bannerIndex={bannerIndex}
        teaserIndex={teaserIndex}
      >
        <TopPageMediaOverlap
          crop={crop}
          playerBannerHook={playerBanner}
          teaser={item!}
          media={media!}
          viewType={viewType}
          teaserIndex={teaserIndex}
        />
      </CmsFullBannerAnchor>
      <StyledTopPageBannerWrapper
        overlaysettings={teaserOverlaySettingsValue}
        istextoverlay={true}
      >
        <StyledTopPageBannerArticle
          overlaysettings={teaserOverlaySettingsValue}
          overlaytxtalign={getTeaserOverlayTextAlign(
            teaserOverlayTextAlignValue
          )}
          teaseroverlaystyle={teaserOverlayStyleValue}
        >
          {content}
        </StyledTopPageBannerArticle>
      </StyledTopPageBannerWrapper>
      {isVideo && <CmsVideoController playerBanner={playerBanner} />}
      {isShoppableImage && (
        <CmsShoppable
          hotZones={placementHotZones}
          hotZonesSettings={placementHotZonesSettings}
        />
      )}
      {itemHasTermsAndCondition && (
        <TopPageBannerTermsAndCondition item={item as ILXTeaser} />
      )}
    </>
  )
}
