import { createListenerMiddleware } from '@reduxjs/toolkit'
import { contextListeners } from '../features/context/slice'
import {
  contractListeners,
  preselectContractListener,
} from '../features/contract/slice'
import { siteListeners } from '../features/site/slice'
import {
  customerSegmentsCampaignListener,
  customerSegmentsRetrievedListener,
} from '../features/userSegments/slice'

const listenerMiddleware = createListenerMiddleware()

export default listenerMiddleware

contextListeners(listenerMiddleware.startListening)
contractListeners(listenerMiddleware.startListening)
siteListeners(listenerMiddleware.startListening)
preselectContractListener(listenerMiddleware.startListening)
customerSegmentsRetrievedListener(listenerMiddleware.startListening)
customerSegmentsCampaignListener(listenerMiddleware.startListening)
//customerSegmentsUserListeners(listenerMiddleware.startListening)
