import React from 'react'
import { styled } from '@mui/material/styles'
import { IHotzone } from '../../../../../../types/cmsPlacement/LXTeaser'
import ProductImage from '../../../../../ProductImage/ProductImage'
import { useStoreIdentity } from '../../../../../../foundation/hooks/useStoreIdentity'
import { Z_INDEX_LV3 } from '../../../../../../constants/ui'
import { Link } from 'react-router-dom'
import { ServerProduct } from '../../../../../../types/product'
import CmsShoppableProductPrice from './CmsShoppableProductPrice'

export const ShoppableOverlay = styled('div', {
  name: 'ShoppableOverlay',
  slot: 'Container',
  shouldForwardProp: (prop) => prop !== 'visible',
})<{
  visible?: boolean
}>(({ visible, theme }) => ({
  position: 'absolute',
  left: 0,
  //overflow: 'hidden',
  display: visible ? 'grid' : 'none',
  gridTemplateColumns: '1fr 1fr',
  gap: theme.spacing(2),
  zIndex: Z_INDEX_LV3,
}))

export const ContentImagePrice = styled('div', {
  name: 'ShoppableOverlay',
  slot: 'ContainerImagePrice',
})(({ theme }) => ({
  width: 100,
  height: 90,
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.color.grey.lightest,
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  boxShadow:
    '0 5.9px 11.8px 0 rgba(0, 0, 0, 0.16), 0 0 2.9px 0 rgba(0, 0, 0, 0.04)',

  span: {
    fontSize: 10,
  },
}))

// TODO: WAIT FOR THE BE TO SEND PRODUCT IMAGES
const CmsShoppableImageOverlay: React.FC<{
  visible?: boolean
  hotZones?: IHotzone[]
  onIconOut?: (e: React.MouseEvent<HTMLDivElement>) => void
}> = ({ visible, hotZones, onIconOut }) => {
  const { basePath } = useStoreIdentity()

  const productDataList = hotZones
    ?.map((item) => item.productData?.find((item) => !!item))
    .filter((item) => !!item) as ServerProduct[]

  const getLinkProduct = (items) => {
    const href = items?.find((x) => x.seo)?.seo?.href || ''
    return `${basePath}${href}`
  }
  return (
    <ShoppableOverlay
      onMouseLeave={(e) => onIconOut && onIconOut(e)}
      visible={visible}
    >
      {productDataList?.map((product) => (
        <Link to={getLinkProduct(product.items)} key={product.id}>
          <ContentImagePrice>
            <ProductImage
              attachments={product.attachments}
              draggable={false}
              sequence={'1.0'}
              usage={'PLP'}
            />
            <CmsShoppableProductPrice price={product.price} />
          </ContentImagePrice>
        </Link>
      ))}
    </ShoppableOverlay>
  )
}

export default CmsShoppableImageOverlay
