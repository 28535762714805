import { styled } from '@mui/material/styles'
import { Pagination, PaginationItem } from '@mui/material'
import { ArrowLeftIcon, ArrowRightIcon } from '@components/UI/Icons'
import React from 'react'

export const StyledPagination = styled(Pagination, {
  name: 'PaginationQueryList',
  slot: 'Pagination',
})(({ theme }) => ({
  '.MuiPaginationItem-root': {
    padding: 0,
    margin: 0,
    color: theme.palette.text.dark,
  },

  '.MuiPaginationItem-root.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.light,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },

  '.MuiPagination-ul': {
    gap: theme.spacing(4),
  },

  '.MuiPaginationItem-root:hover': {
    backgroundColor: theme.palette.primary.light,
  },

  '.MuiPaginationItem-previousNext:hover, .MuiPaginationItem-ellipsis:hover': {
    backgroundColor: 'transparent',
  },

  button: {
    fontSize: 10,
    fontWeight: 300,
    width: 20,
    height: 20,
    minWidth: 20,
    borderRadius: '50%',
    color: theme.palette.text.light,
  },
}))

interface IProps {
  page: number
  onChange: (_event: React.ChangeEvent<unknown>, value: number) => void
  count: number
}
const PaginationQueryList: React.FC<IProps> = ({ page, count, onChange }) => {
  const nextPag = page === count || count <= 4
  const prevPag = page === 1 || count <= 4

  return (
    <StyledPagination
      count={count}
      page={page}
      onChange={onChange}
      hidePrevButton={prevPag}
      hideNextButton={nextPag}
      renderItem={(item) => (
        <PaginationItem
          components={{ previous: ArrowLeftIcon, next: ArrowRightIcon }}
          {...item}
        />
      )}
    />
  )
}
export default PaginationQueryList
