import { IHeader } from '../../../../../features/cms/cms.type'
import { isCMCollection } from '../../../../../types/cmsPlacement/Placement'

class SearchLayerUtils {
  getLeftSideMenu = (headerPlacements: IHeader['headerPlacements'] = []) => {
    const searchData = headerPlacements.find((p) => p.name === 'header_search')
    if (searchData) {
      const headerSearch = searchData.items.filter(isCMCollection) || []
      return headerSearch.map((item) => {
        return {
          title: item['collectionTitle'],
          menuVoices: this.getLeftSideMenuVoices(item.teasableItems),
        }
      })
    }
    return []
  }

  getLeftSideMenuVoices = (items) => {
    return items.map((item) => ({
      title: item.title,
      link: item.formattedUrl,
    }))
  }
}

export default new SearchLayerUtils()
