import { Collapse, Fade, Grow } from '@mui/material'
import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react'
import { TransitionGroup } from 'react-transition-group'
import useIsInViewport from '../../hooks/useIsInViewport'

interface WithIsInViewportProps {
  placeHolder?: React.ElementType
  animationDuration?: number
  intObserverOptions?: IntersectionObserverInit
  animationType?: 'fade' | 'grow' | 'collapse'
}

/** HOC to display elements once provided placeholder is in viewport */
/**
 * @param { React.ElementType }  placeHolder content to show while the component is not in viewport
 * @param { number } animationDuration component animation delay
 * @param { IntersectionObserverInit } intObserverOptions Intersection observer options
 * @param { 'fade' | 'grow' | 'collapse' } animationType animation type
 */

const WithIsInViewport: FC<PropsWithChildren<WithIsInViewportProps>> = ({
  children,
  placeHolder,
  intObserverOptions,
  animationDuration = 500,
  animationType,
  ...rest
}) => {
  const PlaceHolderElement = placeHolder as React.ElementType
  const [show, setShow] = useState<boolean>(false)
  const placeholderRef = useRef<any>(null)
  const viewportRef = useRef<boolean>(false)
  viewportRef.current = useIsInViewport(placeholderRef, intObserverOptions)

  const getAnimation = (
    animationType: WithIsInViewportProps['animationType']
  ) => {
    switch (animationType) {
      case 'fade':
        return (
          <Fade timeout={{ enter: animationDuration }} in={show}>
            <div {...rest}>{children}</div>
          </Fade>
        )
      case 'grow':
        return (
          <Grow timeout={{ enter: animationDuration }} in={show}>
            <div {...rest}>{children}</div>
          </Grow>
        )
      case 'collapse':
        return (
          <Collapse timeout={{ enter: animationDuration }} in={show}>
            <div {...rest}>{children}</div>
          </Collapse>
        )
      default:
        return <div {...rest}>{children}</div>
    }
  }
  const animation = getAnimation(animationType)

  useEffect(() => {
    if (viewportRef.current) {
      setShow(true)
    }
  }, [viewportRef.current])

  return show ? (
    <TransitionGroup component={null}>{animation}</TransitionGroup>
  ) : (
    <div ref={placeholderRef} className="placeholder-wrapper" {...rest}>
      <PlaceHolderElement> </PlaceHolderElement>
    </div>
  )
}

export default WithIsInViewport
