import React from 'react'
import { useTranslation } from 'react-i18next'
import { StoresIcon } from '@components/UI/Icons'
import { useFavoriteStores } from '@hooks/useFavoriteStores'
import { Store } from '../../../types/Tab/store'
import {
  StoreInfoStyled,
  StoreName,
  StoreInfoWrapper,
  StoreInfoHeader,
  FindStoreInputStyled,
  DashBoardStoresInfoHeader,
} from './DashboardMyStores.styles'
import {
  CTATextContainer,
  DashboardMyStoresStyled,
  DashboardTileHeaderWrapper,
  ShopNowAccountButton,
} from '../AccountDashboard.style'
import * as ROUTES from '../../../constants/routes'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { generateAccountPagePath } from '../../Account/Account'
import { useGoogleSuggestions } from '@hooks/useGoogleSuggestions'
import { useTheme } from '@mui/material'
import { dashboardAnalyticsBaseCtaElementId } from '../AccountDashboard'

const DashboardMyStoresEmpty: React.FC = () => {
  const { t } = useTranslation()
  const storeCountry = useStoreIdentity().country.toUpperCase()

  const { gmapLoaded, googleAPISuggestionEnabled } = useGoogleSuggestions({
    addressType: '',
    country: storeCountry,
    page: ROUTES.ACCOUNT_CHILDREN.STORES,
    state: '',
  })

  return (
    <>
      <DashboardMyStoresStyled>
        <CTATextContainer>{t('AccountDashboard.FindAStore')}</CTATextContainer>
        {gmapLoaded && googleAPISuggestionEnabled && (
          <FindStoreInputStyled
            dataElementId={dashboardAnalyticsBaseCtaElementId}
            dataDescription="ViewAllStores"
          />
        )}
      </DashboardMyStoresStyled>
    </>
  )
}

interface DashboardMyStoresWithStoresInfo {
  store?: Store
  qnt: number
}

const DashboardMyStoresWithStoresInfo: React.FC<
  DashboardMyStoresWithStoresInfo
> = ({ store, qnt }) => {
  const { t } = useTranslation()
  const { langCode } = useStoreIdentity()

  if (!store) return <DashboardMyStoresEmpty />

  return (
    <>
      <StoreInfoHeader>
        {t('AccountDashboard.FavouriteStores', { count: qnt })}
      </StoreInfoHeader>
      <StoreInfoWrapper>
        <StoreName>{t('StoreDetails.Labels.StoreBrand')}</StoreName>
        <StoreInfoStyled
          store={store}
          hideBookEyeBtn={true}
          isBoldStoreHours={false}
        />
      </StoreInfoWrapper>
      <ShopNowAccountButton
        dataElementId={dashboardAnalyticsBaseCtaElementId}
        data-description="ViewAllStores"
        to={generateAccountPagePath(langCode, ROUTES.ACCOUNT_CHILDREN.STORES)}
      >
        {t('AccountDashboard.ViewAllStores').toUpperCase()}
      </ShopNowAccountButton>
    </>
  )
}

export const DashboardMyStores: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { favoriteStores, defaultFavoriteStoreId } = useFavoriteStores()

  const store =
    favoriteStores &&
    (favoriteStores.find((s) => `${s.storeId}` === defaultFavoriteStoreId) ||
      favoriteStores[0])
  return (
    <>
      <DashboardTileHeaderWrapper>
        {!store && (
          <StoresIcon
            width={48}
            height={48}
            htmlColor={theme.palette.text.dark}
          />
        )}
        <DashBoardStoresInfoHeader>
          {t('AccountDashboard.MyStores')}
        </DashBoardStoresInfoHeader>
      </DashboardTileHeaderWrapper>

      <DashboardMyStoresWithStoresInfo
        store={store || undefined}
        qnt={favoriteStores ? (favoriteStores as any).length : 0}
      />
    </>
  )
}
