// Import Swiper styles
import 'swiper/swiper-bundle.min.css'

import React, { FC, memo, useRef } from 'react'
import { EffectFade, Navigation } from 'swiper'
import { Swiper as SwiperClass } from 'swiper/types'
import { Swiper as SwiperDef } from 'swiper/swiper'
import { SwiperSlide } from 'swiper/react'

import ProductImage, {
  ProductImageProps,
} from '../../../ProductImage/ProductImage'
import {
  StyledColorLabelContainer,
  StyledControlsWrapper,
  StyledControlsWrapperColorLabel,
  StyledSwiper,
} from './ProductTileGallery.style'
import { ArrowLeftIcon, ArrowRightIcon } from '@components/UI/Icons'

import { ProductAlgolia } from '../../../../types/ProductAlgolia/ProductAlgolia'
import ProductTileUtils from '@utils/ProductAlgolia/ProductTileAlgoliaUtils'

type ProductTileGalleryProps = {
  isDisplayed?: boolean
  currentProductIndex: number
  cluster: ProductAlgolia[]
  commonImageProps?: ProductImageProps
  onProductChange: (s: SwiperDef) => void
}

const ProductTileGallery: FC<ProductTileGalleryProps> = ({
  isDisplayed = true,
  cluster,
  currentProductIndex,
  commonImageProps,
  onProductChange,
}) => {
  const swiperRef = useRef<SwiperClass | null>(null)
  const currentProduct = cluster[currentProductIndex]
  const attributes = currentProduct?.attributes_translated
  const { colorLabel } = ProductTileUtils.getProductTileAttributes(attributes)

  const slidePrev = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    const prevSlideIndex = (swiperRef.current?.realIndex || 0) - 1

    swiperRef.current?.slideTo(
      prevSlideIndex < 0 ? cluster.length - 1 : prevSlideIndex
    )
  }

  const slideNext = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    const nextSlideIndex = (swiperRef.current?.realIndex || 0) + 1

    swiperRef.current?.slideTo(
      nextSlideIndex > cluster.length - 1 ? 0 : nextSlideIndex
    )
  }

  // Prevents the click event from bubbling up to the parent element so it won't be tracked by Analytics
  const handleMouseDownCapture = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  if (!isDisplayed) {
    return null
  }

  return (
    <>
      <StyledSwiper
        isDisplayed={isDisplayed}
        effect="fade"
        touchMoveStopPropagation={true}
        touchStartPreventDefault={false}
        navigation={false}
        modules={[Navigation, EffectFade]}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        initialSlide={currentProductIndex}
        onActiveIndexChange={onProductChange}
      >
        {cluster.map((c, index) => (
          <SwiperSlide key={index}>
            <ProductImage attachments={c.attachments} {...commonImageProps} />
          </SwiperSlide>
        ))}
      </StyledSwiper>

      <StyledControlsWrapper isDisplayed={isDisplayed}>
        {cluster.length > 1 ? (
          <>
            <div className="icon-container">
              <ArrowLeftIcon
                onClick={slidePrev}
                onMouseDownCapture={handleMouseDownCapture}
              />
            </div>
            <StyledControlsWrapperColorLabel>{`${colorLabel}`}</StyledControlsWrapperColorLabel>
            <div className="icon-container">
              <ArrowRightIcon
                onClick={slideNext}
                onMouseDownCapture={handleMouseDownCapture}
              />
            </div>
          </>
        ) : (
          <StyledColorLabelContainer>
            <StyledControlsWrapperColorLabel>{`${colorLabel}`}</StyledControlsWrapperColorLabel>
          </StyledColorLabelContainer>
        )}
      </StyledControlsWrapper>
    </>
  )
}

export default memo(ProductTileGallery)
