import React from 'react'
import CmsIcon from '../CmsIcon'
import { CmsIconContainer, ContentCmsIcon } from './CmsIcon.style'
export const CmsModuleIcon: React.FC<{
  justifyContent?: React.CSSProperties['justifyContent']
  isLandscape?: boolean
  teaserIcon?: string
}> = ({ justifyContent, isLandscape, teaserIcon }) => {
  return (
    <CmsIconContainer justifyContent={justifyContent}>
      <ContentCmsIcon isLandscape={isLandscape}>
        {teaserIcon && <CmsIcon teaserIcon={teaserIcon} />}
      </ContentCmsIcon>
    </CmsIconContainer>
  )
}
