import React, { useMemo } from 'react'
import find from 'lodash/find'
import loadable from '@loadable/component'
import { paymentMethodsSelector } from '../../redux/selectors/site'
import { styled } from '@mui/material/styles'
import { useGetFooterQuery } from '../../features/cms/query'
import { useSelector } from 'react-redux'
import { useIsAccountPage } from '@hooks/useIsAccountPage'
import { usePageType } from '../../foundation/hooks/usePageType'
import { orderDetailsSelector } from '../../features/order/selector'

import PromoBarSkeleton from '../Header/components/HeaderSkeleton/PromoBarSkeleton'
import WithSkeleton from '@components/WithSkeleton/WithSkeleton'
import { Skeleton, useTheme } from '@mui/material'
import { IPlacement } from '../../types/cmsPlacement/Placement'
import SocialProof from './components/SocialProof'
import { CHECKOUT_STEPS } from '../../constants/checkout'
import { useSearchParams } from 'react-router-dom'
import { useDynamicCmsContent } from '../../foundation/hooks/useDynamicCmsContent'
import { FooterLogoIcon } from '@components/UI/Icons'

const FooterWrapper = styled('footer', {
  name: 'Footer',
  slot: 'Wrapper',
})(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(0, 4),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 8),
  },

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 16),
  },
}))

const CopyrightWrapper = styled('div', {
  name: 'Footer',
  slot: 'CopyrightWrapper',
})(({ theme }) => ({
  backgroundColor: theme.palette.color.grey.lightest,
  padding: theme.spacing(0, 4),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 8),
  },

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 16),
  },
}))

const LogoWrapper = styled('div', {
  name: 'Footer',
  slot: 'LogoWrapper',
})(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  padding: theme.spacing(4, 0),
  justifyContent: 'center',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(6, 0),
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'unset',
  },
  '&:before': {
    content: "''",
    display: 'block',
    position: 'absolute',
    width: `calc(100vw + ${theme.spacing(2)})`,
    bottom: 0,
    height: '1px',
    right: `-${theme.spacing(4)}`,
    backgroundColor: `${theme.palette.color.grey.light}`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100vw + ${theme.spacing(4)})`,
      right: `-${theme.spacing(8)}`,
    },

    [theme.breakpoints.up('md')]: {
      width: `calc(100vw + ${theme.spacing(8)})`,
      right: `-${theme.spacing(16)}`,
    },
  },
}))

const contentsFooter = (footerPlacements: IPlacement[]) => {
  const navigation = find(footerPlacements, { name: 'footer_navigation' })
  const newsLetter = find(footerPlacements, { name: 'footer_newsletter' })
  const newsLetterPopup = find(footerPlacements, {
    name: 'footer_newsletter_overlay',
  })
  const newsLetterSideBanner = find(footerPlacements, {
    name: 'footer_newsletter_side_banner',
  })
  const crossSiteLinks = find(footerPlacements, {
    name: 'footer_cross_site_links',
  })
  const copyRight = find(footerPlacements, { name: 'footer_copyright' })
  const checkoutFooter = find(footerPlacements, { name: 'footer_checkout' })
  return {
    navigation,
    crossSiteLinks,
    copyRight,
    newsLetter,
    newsLetterPopup,
    newsLetterSideBanner,
    checkoutFooter,
  }
}

const Footer: React.FC = () => {
  const { pageType } = usePageType()
  const footerPaymentMethods = useSelector(paymentMethodsSelector)
  const isAccountPage = useIsAccountPage()
  const isCartPage = pageType === 'cart'
  const isCheckoutPage = pageType === 'checkout'
  const isSignInPage = pageType === 'sign-in'
  const orderDetails = useSelector(orderDetailsSelector)
  const theme = useTheme()

  const [searchParams, _setSearchParams] = useSearchParams()

  const previewDate = searchParams.get('previewDate') || undefined
  const filterRulesLocaleOverride =
    searchParams.get('filterRulesLocaleOverride') || undefined

  const params =
    previewDate || filterRulesLocaleOverride
      ? { previewDate, filterRulesLocaleOverride }
      : undefined

  const { data, isLoading } = useGetFooterQuery(params)

  const FooterNavigation = useMemo(() => {
    return loadable(() => import('./components/FooterNavigation'), {
      fallback: <PromoBarSkeleton />,
    })
  }, [isLoading])
  const NewsLetter = useMemo(() => {
    return loadable(() => import('./components/NewsLetter'))
  }, [isLoading])
  const OtherLinks = useMemo(() => {
    return loadable(() => import('./components/OtherLinks'))
  }, [isLoading])
  const PreFooter = useMemo(() => {
    return loadable(() => import('./components/PreFooter'))
  }, [isLoading])
  const PaymentMethod = useMemo(() => {
    return loadable(() => import('./components/PaymentMethod'))
  }, [isLoading])

  const dynamicContentBanners = useDynamicCmsContent(data?.footerPlacements)

  if (!data) return null
  const {
    copyRight,
    crossSiteLinks,
    navigation,
    newsLetter,
    newsLetterPopup,
    checkoutFooter,
    newsLetterSideBanner,
  } = contentsFooter(
    data?.footerPlacements || dynamicContentBanners?.dynamicContentBanners
  )

  return (
    <>
      {!isAccountPage &&
        !isCartPage &&
        !isCheckoutPage &&
        !isSignInPage &&
        !orderDetails && (
          <WithSkeleton
            isLoaded={!isLoading}
            renderSkeletonElement={() => {
              return <Skeleton variant={'rectangular'} height={280} />
            }}
          >
            <PreFooter />
          </WithSkeleton>
        )}
      {!isCheckoutPage ||
      location.pathname.indexOf(CHECKOUT_STEPS.ORDER_CONFIRMATION) !== -1 ? (
        <>
          <FooterWrapper data-element-id="X_X_Footer">
            <WithSkeleton
              animationDuration={500}
              animationType="fade"
              isLoaded={!isLoading}
              renderSkeletonElement={() => {
                return <Skeleton variant={'rectangular'} height={50} />
              }}
            >
              <LogoWrapper>
                <FooterLogoIcon
                  primaryFill={theme.palette.color.white.main}
                  secondaryFill={theme.palette.color.orange.dark}
                ></FooterLogoIcon>
              </LogoWrapper>
            </WithSkeleton>
            <WithSkeleton
              animationDuration={500}
              animationType="fade"
              isLoaded={!isLoading}
              renderSkeletonElement={() => {
                return <Skeleton variant={'rectangular'} height={90} />
              }}
            >
              <NewsLetter
                newsLetter={newsLetter!}
                newsLetterPopup={newsLetterPopup!}
                newsLetterSideBanner={newsLetterSideBanner!}
              />
            </WithSkeleton>
            <WithSkeleton
              animationDuration={500}
              animationType="fade"
              isLoaded={!isLoading}
              renderSkeletonElement={() => {
                return <Skeleton variant={'rectangular'} height={400} />
              }}
            >
              <FooterNavigation navigation={navigation!} />
            </WithSkeleton>
            <WithSkeleton
              animationDuration={500}
              animationType="fade"
              isLoaded={!isLoading}
              renderSkeletonElement={() => {
                return <Skeleton variant={'rectangular'} height={400} />
              }}
            >
              <SocialProof />
            </WithSkeleton>
            <WithSkeleton
              animationDuration={500}
              animationType="fade"
              isLoaded={!isLoading}
              renderSkeletonElement={() => {
                return <Skeleton variant={'rectangular'} height={60} />
              }}
            >
              <PaymentMethod availablePaymentMethods={footerPaymentMethods!} />
            </WithSkeleton>
          </FooterWrapper>
          <CopyrightWrapper data-element-id="X_X_Copyright">
            <OtherLinks
              crossSiteLinks={crossSiteLinks!}
              copyright={copyRight!}
            />
          </CopyrightWrapper>
        </>
      ) : (
        <FooterWrapper data-element-id="X_X_Footer">
          <WithSkeleton
            animationDuration={500}
            animationType="fade"
            isLoaded={!isLoading}
            renderSkeletonElement={() => {
              return <Skeleton variant={'rectangular'} height={50} />
            }}
          >
            <LogoWrapper>
              <FooterLogoIcon
                primaryFill={theme.palette.color.white.main}
                secondaryFill={theme.palette.color.orange.dark}
              ></FooterLogoIcon>
            </LogoWrapper>
          </WithSkeleton>
          <WithSkeleton
            animationDuration={500}
            animationType="fade"
            isLoaded={!isLoading}
            renderSkeletonElement={() => {
              return <Skeleton variant={'rectangular'} height={60} />
            }}
          >
            <PaymentMethod
              availablePaymentMethods={footerPaymentMethods!}
              checkoutFooter={checkoutFooter}
              copyright={copyRight}
            />
          </WithSkeleton>
        </FooterWrapper>
      )}
    </>
  )
}

export default Footer
