import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    htmlFontSize: 16,
    body1: {
      fontSize: '1rem',
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#005abb',
      light: '#c8e1f6',
      dark: '#013365',
    },
    secondary: {
      main: '#ff9c65',
      light: '#f6ddd0',
      dark: '#ff6d29',
    },
    success: {
      main: '#247534',
      light: '#d6f3e9',
      dark: '#035630',
    },
    error: {
      main: '#d32113',
      light: '#fde1e1',
      dark: '#7b0000',
    },
    warning: {
      main: '#dc9f03',
      light: '#fcf9db',
      dark: '#b68405',
    },
    info: {
      main: '#1465b4',
      light: '#dcedfc',
      dark: '#083867',
    },
    text: {
      dark: '#2b2e31',
      light: '#ffffff',
    },
    color: {
      grey: {
        dark: '#767676',
        main: '#b0b0b0',
        light: '#d8d8d8',
        lightest: '#f7f6f4',
      },
      black: {
        main: '#000000',
        secondary: '#2B2E31',
      },
      white: {
        main: '#ffffff',
        transparent: 'rgba(255, 255, 255, 0.2)',
      },
      orange: {
        main: '#ff9c65',
        light: '#f6ddd0',
        dark: '#ff6d29',
      },
      backgrounds: {
        color1: '#ecdff1',
        color2: '#f5e6ea',
        color3: '#faf5e9',
        color4: '#f0eae2',
        color5: '#f7f6f4',
        color6: '#e6f9f5',
      },
      crm: {
        main: '#909090',
      },
    },
  },
  spacing: 4,
  breakpoints: {
    values: {
      xs: 375,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 1440,
    },
  },
  shadows: [
    'none',
    '0 5.9px 11.8px 0 rgba(96, 97, 112, 0.16), 0 0 2.9px 0 rgba(40, 41, 61, 0.04)',
    '0px 1px 4px 2px rgba(0,0,0,0.15), 0px 2px 1px 0px rgba(0,0,0,0.13)',
    '0px 1px 2px 2px rgba(0,0,0,0.1), 0px 2px 1px 0px rgba(0,0,0,0.1)',
    'inset 0px 1px 2px 2px rgba(0,0,0,0.1), inset 0px 2px 1px 0px rgba(0,0,0,0.1)',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: 16,
        },
        body: {
          fontSize: 16,
        },
      },
    },
  },
})

export default theme
