import { styled } from '@mui/material/styles'

export const AppWrapper = styled('div', {
  name: 'BaseLayout',
  slot: 'AppWrapper',
  shouldForwardProp: (prop) => prop !== 'isPreview',
})<{ isPreview?: boolean }>(({ isPreview, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: isPreview ? '' : '100vh',
  minHeight: '100vh',
  maxHeight: isPreview ? '' : '100vh',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.default,
}))

export const AppMain = styled('main', {
  name: 'BaseLayout',
  slot: 'Main',
  shouldForwardProp: (prop) =>
    prop !== 'openMegaMenu' && prop !== 'openSearchDrawer',
})<{ openMegaMenu: boolean; openSearchDrawer?: boolean }>(
  ({ openMegaMenu, openSearchDrawer }) => ({
    maxWidth: '100vw',
    overflow: openMegaMenu || openSearchDrawer ? 'hidden' : 'hidden auto',
    flex: 1,
  })
)
