import { styled } from '@mui/material/styles'
import {
  cmsModuleLateralMargin,
  cmsModuleVerticalMargin,
  teaserBackGroundColorToBg,
} from '../../../../utils/placements'
import { ITeaserBackGround } from '../../../../types/cmsPlacement/LXTeaser'

export const StyledPlacementContainer = styled('div', {
  name: 'PlacementContainer',
  slot: 'Container',
  shouldForwardProp: (prop) =>
    prop !== 'marginLateral' && prop !== 'marginVertical',
})<{
  bgcolor: React.CSSProperties['backgroundColor'] | ITeaserBackGround
  marginVertical: string
  marginLateral: boolean
}>(({ bgcolor, theme, marginLateral, marginVertical }) => ({
  //display: 'flex',
  // for terms and condition panel
  overflow: 'hidden',
  position: 'relative',
  ...teaserBackGroundColorToBg(bgcolor, theme),
  ...cmsModuleVerticalMargin(marginVertical, theme),
  ...cmsModuleLateralMargin(marginLateral, theme),
}))
