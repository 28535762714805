import React from 'react'
import { ProductAlgoliaPrice } from '../../../types/ProductAlgolia/ProductAlgolia'
import {
  ProductPriceContainerFinal,
  ProductPriceInitialPriceFinal,
  ProductPriceWrapper,
} from '@components/ProductPriceFinal/ProductPriceFinal.style'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import ProductPriceAlgoliaUtils from '@utils/ProductAlgolia/ProductPriceAlgoliaUtils'
import FormattedPriceDisplay from '@components/formatted-price-display'
import {
  ProductPriceCurrentPriceCart,
  ProductPriceCurrentPriceCartPerBox,
  ProductPriceInitialPriceCartPerBox,
} from './ProductPriceCart.style'

interface ProductPriceCart {
  price: ProductAlgoliaPrice
  productType: string
  totalBoxes: number
  productQuantity: number
  isPerBox: boolean
  promoCodeDiscount: number
}
const ProductPriceCart: React.FC<ProductPriceCart> = ({
  price,
  productType,
  totalBoxes,
  productQuantity,
  isPerBox,
  promoCodeDiscount,
}) => {
  const { getLxProductTypes } = ProductAlgoliaUtils
  const { isContactLenses, isFrames } = getLxProductTypes(productType)
  const boxesNr = isContactLenses ? totalBoxes : productQuantity

  const updatePrice = Boolean(promoCodeDiscount)
    ? {
        ...price,
        offerPrice:
          Number(price.offerPrice) -
          promoCodeDiscount / (Boolean(boxesNr) ? boxesNr : 1),
        percentageDiscount: Math.round(
          ((Number(price.percentageDiscount) + 100) * promoCodeDiscount) /
            (Boolean(boxesNr) ? boxesNr : 1) /
            Number(price.offerPrice)
        ),
      }
    : price

  const { listPrice, offerPrice, currency, hasInitialPriceDiscount } =
    ProductPriceAlgoliaUtils.getProductPriceData(updatePrice)

  const currentPriceControl = isFrames
    ? offerPrice
    : ProductPriceAlgoliaUtils.getClAndClaTotalPrice(offerPrice, boxesNr)

  const initialPriceControl = isFrames
    ? listPrice
    : ProductPriceAlgoliaUtils.getClAndClaTotalPrice(listPrice, boxesNr)

  return (
    <ProductPriceWrapper>
      <ProductPriceContainerFinal>
        {Boolean(listPrice) && hasInitialPriceDiscount && (
          <>
            {isPerBox ? (
              <ProductPriceInitialPriceCartPerBox>
                <FormattedPriceDisplay min={listPrice} currency={currency} />
              </ProductPriceInitialPriceCartPerBox>
            ) : (
              <ProductPriceInitialPriceFinal>
                <FormattedPriceDisplay
                  min={initialPriceControl}
                  currency={currency}
                />
              </ProductPriceInitialPriceFinal>
            )}
          </>
        )}
        {Boolean(offerPrice) && (
          <>
            {isPerBox ? (
              <ProductPriceCurrentPriceCartPerBox>
                <FormattedPriceDisplay min={offerPrice} currency={currency} />
              </ProductPriceCurrentPriceCartPerBox>
            ) : (
              <ProductPriceCurrentPriceCart>
                <FormattedPriceDisplay
                  min={currentPriceControl}
                  currency={currency}
                />
              </ProductPriceCurrentPriceCart>
            )}
          </>
        )}
      </ProductPriceContainerFinal>
    </ProductPriceWrapper>
  )
}

export default ProductPriceCart
