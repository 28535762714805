//Standard libraries
import React from 'react'
//Custom libraries
import { RemoveAddressDialogLayout } from './RemoveAddressDialogLayout'
import { StandardPageHero2BlocksLayout } from '../../layouts/standard-page-hero-2-blocks'
import { SectionContent } from '../../layouts/sectionContentType'

const RemoveAddressDialog: React.FC<{
  handleClose: () => void
  handleOk: () => void
  userAddress
}> = ({ handleClose, userAddress, handleOk }) => {
  const removeAddress: SectionContent[] = [
    {
      key: 'address-page-removeaddress',
      CurrentComponent: () => {
        return (
          <RemoveAddressDialogLayout
            handleClose={handleClose}
            handleOk={handleOk}
            userAddress={userAddress}
          />
        )
      },
    },
  ]

  return (
    <StandardPageHero2BlocksLayout
      cid="remove-address-page"
      sectionOne={removeAddress}
    />
  )
}

export { RemoveAddressDialog }
