import React from 'react'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { ChatFilledIcon } from '@components/UI/Icons'
import { Z_INDEX_LV5 } from '../../constants/ui'
import { useDispatch, useSelector } from 'react-redux'
import { chatReadySelector } from '../../features/ui/selector'
import { setOpenChat } from '../../features/ui/action'

type Props = {}

const ChatBotContainerStyle = styled('div', {
  name: 'ChatBotButton',
  slot: 'Container',
})({
  position: 'fixed',
  bottom: 200,
  left: -55,
  maxWidth: 150,
  zIndex: Z_INDEX_LV5,
  transform: 'rotate(90deg)',
})

const ChatBotButtonStyle = styled('button', {
  name: 'ChatBotButton',
  slot: 'Button',
})(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px 8px 0 0',
  padding: theme.spacing(2, 4),
  boxShadow: '0 2px 20px rgba(34,34,34,0.5)',
  border: 'none',
  cursor: 'pointer',
}))

const ChatBotIconContainer = styled('div', {
  name: 'ChatBotButton',
  slot: 'ImageContainer',
})(({ theme }) => ({
  width: 26,
  height: 15,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.color.white.main,
}))

const ChatBotLabel = styled('span', {
  name: 'ChatBotButton',
  slot: 'label',
})(({ theme }) => ({
  color: theme.palette.color.white.main,
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: 12,
  paddingLeft: 8,
}))

const ChatBotButton: React.FC<Props> = () => {
  const { t } = useTranslation()
  const isReady = useSelector(chatReadySelector)
  const dispatch = useDispatch()

  const safeToggleChatBot = () => {
    if (isReady) {
      dispatch(setOpenChat(true))
    } else {
      return
    }
  }

  if (!isReady) {
    return null
  }

  return (
    <ChatBotContainerStyle>
      <ChatBotButtonStyle onClick={safeToggleChatBot}>
        <ChatBotIconContainer>
          <ChatFilledIcon />
        </ChatBotIconContainer>
        <ChatBotLabel>{t('ChatBot.Help')}</ChatBotLabel>
      </ChatBotButtonStyle>
    </ChatBotContainerStyle>
  )
}

export default ChatBotButton
