import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { LinkAsButton } from '@components/UI'

export const DashboardWishlistItems = styled(Typography, {
  name: 'DashboardWishlist',
  slot: 'Typography',
})(({ theme }) => ({
  fontSize: theme.spacing(4),
  lineHeight: 1.5,
  color: theme.palette.text.dark,
}))

export const DashboardWishlistItemContainer = styled('div', {
  name: 'DashboardWishlist',
  slot: 'DashboardGridItemContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 0,
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(8),
  borderRadius: 16,
  border: `solid 1px ${theme.palette.color.grey.light}`,
  backgroundColor: theme.palette.color.white.main,
  height: '100%',
  minHeight: 400,
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    rowGap: theme.spacing(2),
    padding: theme.spacing(8, 6),
    minHeight: 340,
  },

  [theme.breakpoints.up('md')]: {
    width: 360,
    minHeight: 360,

    '& img': {
      width: '75%',
      height: 'auto',
    },
  },

  [theme.breakpoints.up('lg')]: {
    minHeight: 439,
    width: 439,
    rowGap: theme.spacing(4),
    padding: theme.spacing(10, 12),

    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
}))

export const DashboardWishlistProductDetails = styled('div', {
  name: 'DashboardWishlist',
  slot: 'DashboardWishlistProductDetails',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 0,
  rowGap: theme.spacing(0.5),
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.color.white.main,
  marginTop: theme.spacing(-2),
  marginBottom: theme.spacing(-1),
}))

export const DashboardWishlistProductName = styled('span', {
  name: 'DashboardWishlist',
  slot: 'DashboardWishlistProductName',
})(({ theme }) => ({
  fontSize: 10,
  lineHeight: 1.6,
  color: theme.palette.text.dark,
}))

export const DashboardWishlistBrandName = styled('span', {
  name: 'DashboardWishlist',
  slot: 'DashboardWishlistBrandName',
})(({ theme }) => ({
  fontSize: 14,
  lineHeight: 1.43,
  fontWeight: 600,
  color: theme.palette.text.dark,
}))

export const DashboardWishlistItemHeaderWrapper = styled('div', {
  name: 'Dashboard',
  slot: 'WishlistGridItemHeaderWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  flexGrow: 0,
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: 0,
  marginBottom: 0,
}))

export const StyledLinkAsButton = styled(LinkAsButton, {
  name: 'Dashboard',
  slot: 'StyledLinkAsButton',
})(() => ({
  marginTop: 'auto',
}))
