import * as userAction from '../../../redux/actions/user'

import React, {
  ChangeEvent,
  FC,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import {
  StyledErrorLogin,
  StyledFYPLinkWrapper,
  StyledForgotYourPasswordLink,
  StyledTextFieldSignIn,
} from '../styles/SignInForm.style'
import { useDispatch, useSelector } from 'react-redux'

import { ForgotPassword } from '@components/ForgotPassword'
import { IconButton } from '@components/UI'
import { ModalDialog } from '@components/UI'
import { PasswordShowVisibleIcon, PasswordHideIcon } from '@components/UI/Icons'
import isEmpty from 'lodash/isEmpty'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  StyledLoginErrorContainer,
  StyledSignInCheckoutButton,
  StyledSignInCheckoutButtonWrapper,
  StyledSignInCheckoutContainer,
  StyledSingInFormCheckout,
  StyledTextFieldCheckoutWrapper,
} from './SignInFormCheckout.style'
import addressUtil from '../../../utils/addressUtil'
import { useCSRForUser } from '../../../foundation/hooks/useCSRForUser'
import { genericErrorSelector } from '../../../redux/selectors/error'
import {
  orderDetailsSelector,
  orderItemsSelector,
} from '../../../features/order/selector'
import { useTheme } from '@mui/material/styles'
import { ORDER_EXTEND_ATTRIBUTE_NAMES } from 'src/constants/order'

interface IProps {
  socialLoginReady?: boolean
  setCurrentEmail: (e: string) => void
  payloadBase: object
}

interface FormData {
  email: string
  password: string
}

const SignInFormCheckout: FC<IProps> = ({
  socialLoginReady,
  setCurrentEmail,
  payloadBase,
}) => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const theme = useTheme()
  const { isAuthenticationError } = useCSRForUser()
  const errorSelector = useSelector(genericErrorSelector)
  const isErrorLogin = isAuthenticationError(errorSelector)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [inputType, setInputType] = useState<string>('password')
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] =
    useState<boolean>(false)
  const [isStepResetPassWordOpen, SetIsStepResetPassWordOpen] =
    useState<boolean>(false)
  const handleOpen = () => setIsForgotPasswordOpen(true)
  const handleOpenStepResetPassWord = () => {
    setIsForgotPasswordOpen(true)
    SetIsStepResetPassWordOpen(true)
  }

  const handleClose = () => setIsForgotPasswordOpen(false)
  const orderItems = useSelector(orderItemsSelector)
  const orderDetails = useSelector(orderDetailsSelector)

  const userEmail =
    orderItems?.find((item) => !!item)?.email1 ||
    orderDetails?.orderExtendAttribute?.find(
      (a) => a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES.RecipientEmail
    )?.attributeValue

  const [email, setEmail] = useState<string>(userEmail || '')
  const [password, setPassword] = useState<string>('')
  const [isEmailError, setIsEmailError] = useState<boolean>(false)
  const [isPasswordError, setIsPasswordError] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)

  const handleEmail = (evt: ChangeEvent<HTMLInputElement>) => {
    if (isError) setIsError(false)
    if (isEmailError) setIsEmailError(false)
    setEmail(evt.target.value)
  }

  const handlePassword = (evt: ChangeEvent<HTMLInputElement>) => {
    if (isError) setIsError(false)
    if (isPasswordError) setIsPasswordError(false)
    setPassword(evt.target.value)
  }

  const validationUrlParams = searchParams.get('validation')

  const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })

  const { handleSubmit } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  })

  useLayoutEffect(() => {
    setInputType(showPassword ? 'text' : 'password')
  }, [showPassword])

  useEffect(() => {
    validationUrlParams !== null && handleOpenStepResetPassWord()
  }, [validationUrlParams])

  const onSubmit = () => {
    password === '' && setIsPasswordError(true)
    if (!addressUtil.validateEmail(email)) {
      setIsEmailError(true)
      return
    }
    if (!isEmpty(email) && !isEmpty(password)) {
      dispatch(
        userAction.LOGIN_REQUESTED_ACTION({
          body: {
            logonId: email,
            logonPassword: password,
          },
          preventRedirect: true,
          ...payloadBase,
        })
      )
    }
    setCurrentEmail(email)
  }

  return (
    <StyledSignInCheckoutContainer>
      <ModalDialog
        open={isForgotPasswordOpen}
        onClose={handleClose}
        onCloseButton={handleClose}
        widthSize="md-lg"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ForgotPassword
          handleClose={handleClose}
          openStepResetPassword={isStepResetPassWordOpen}
        />
      </ModalDialog>
      <div>
        {socialLoginReady && (
          <StyledSingInFormCheckout
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <StyledTextFieldCheckoutWrapper>
              <StyledTextFieldSignIn
                label={t('SignInPage.Labels.EmailAddress')}
                name="email"
                type={'email'}
                value={email}
                onChange={handleEmail}
                error={isEmailError || isError}
                isvalid={
                  !isEmailError &&
                  email.length > 0 &&
                  !isError &&
                  !(isErrorLogin && email !== '')
                }
                showvalidationstatus={
                  !isEmailError && !(isErrorLogin && email !== '')
                }
                helperText={
                  isEmailError && isEmpty(email)
                    ? t('SignInPage.Msgs.EmptyMail')
                    : isEmailError && !isEmpty(email)
                    ? t('SignInPage.Msgs.InvalidMail')
                    : null
                }
              />
            </StyledTextFieldCheckoutWrapper>
            <StyledTextFieldCheckoutWrapper>
              <StyledTextFieldSignIn
                name="password"
                label={t('SignInPage.Labels.Password')}
                type={inputType}
                value={password}
                onChange={handlePassword}
                error={isPasswordError || isError}
                isvalid={
                  !isPasswordError &&
                  password.length > 0 &&
                  !isError &&
                  !(isErrorLogin && email !== '')
                }
                showvalidationstatus={
                  !isEmailError && !(isErrorLogin && email !== '')
                }
                helperText={
                  ((isPasswordError && !!password?.length) ||
                    (isPasswordError && isEmpty(password))) &&
                  t('SignInPage.Msgs.EmptyPassword')
                }
                customInputProps={{
                  endAdornment: (
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <PasswordShowVisibleIcon
                          htmlColor={theme.palette.primary.main}
                        />
                      ) : (
                        <PasswordHideIcon
                          htmlColor={theme.palette.primary.main}
                        />
                      )}
                    </IconButton>
                  ),
                }}
              />
            </StyledTextFieldCheckoutWrapper>

            <StyledFYPLinkWrapper isPasswordError={isPasswordError}>
              <StyledForgotYourPasswordLink onClick={handleOpen}>
                {t('SignInPage.CTA.ForgotPassword')}
              </StyledForgotYourPasswordLink>
            </StyledFYPLinkWrapper>
            {(isEmailError || (isErrorLogin && email !== '')) && (
              <StyledLoginErrorContainer>
                <StyledErrorLogin>
                  {t('SignInPage.Msgs.ErrorLogin')}
                </StyledErrorLogin>
                <StyledErrorLogin>
                  {t('SignInPage.Msgs.ErrorLogin2')}
                </StyledErrorLogin>
              </StyledLoginErrorContainer>
            )}

            <StyledSignInCheckoutButtonWrapper>
              <StyledSignInCheckoutButton
                data-element-id="X_X_LoginForm_Login"
                type="submit"
                variant="primary"
                onClick={onSubmit}
              >
                {t('SignInPage.CTA.SignIn')}
              </StyledSignInCheckoutButton>
            </StyledSignInCheckoutButtonWrapper>
          </StyledSingInFormCheckout>
        )}
      </div>
    </StyledSignInCheckoutContainer>
  )
}

export default SignInFormCheckout
