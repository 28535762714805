/*const getQueryParams = (name: string) => {
  const url = new URL(window.location.href)
  return url.searchParams.get(name)
}

const enableAnalytics = () => {
  // enable/disable analytics by query string
  const flag = getQueryParams('analytics')
  if (flag) {
    return flag === 'true'
  }
  return boolean(process.env.REACT_APP_ENABLE_ANALYTICS) || true
}

const enableMonetate = () => {
  // enable/disable monetate by query string
  const flag = getQueryParams('monetate')
  if (flag) {
    return flag === 'true'
  }
  return boolean(process.env.REACT_APP_ENABLE_MONETATE) || false
}*/

declare const __N1_DEFAULT_CONFIG__

export const defaultConfig = __N1_DEFAULT_CONFIG__

const config = defaultConfig

export type AppConfig = typeof config

export default config
