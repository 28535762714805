export const MonetateHelper = `{
  pushTrackData: ${() => {
    const monetateQ = window.monetateQ || []
    monetateQ.push(['trackData'])
  }},
  setPage: ${() => {
    const getPageType = document.getElementsByClassName('monetate-page-type')
    const pageType = getPageType?.[0]?.getAttribute('value') || 'unavailable'
    const monetateQ = window.monetateQ || []
    monetateQ.push(['setPageType', pageType])
  }},
  addProducts: ${(type = 'all') => {
    if (type == 'all' || type == 'plp') {
      const getProductsIds = document.getElementsByClassName('monetate-product')
      const productsIdsValue = getProductsIds?.[0]?.getAttribute('value')

      const productsIds = productsIdsValue
        ? JSON.parse(productsIdsValue)
        : ['unavailable']
      const monetateQ = window.monetateQ || []
      monetateQ.push(['addProducts', productsIds])
    }
  }},
  addProductDetails: ${() => {
    const getProductDetails = document.getElementsByClassName(
      'monetate-product-details'
    )

    const productDetailsValue = getProductDetails?.[0]?.getAttribute('value')
    const productDetails = productDetailsValue
      ? JSON.parse(productDetailsValue!)
      : ['unavailable']

    const monetateQ = window.monetateQ || []
    monetateQ.push(['addProductDetails', [productDetails]])
  }},
  addCartRows: ${() => {
    const getCartRows = document.getElementsByClassName('monetate-add-cart-row')
    const cartRowsValue = getCartRows?.[0]?.getAttribute('value')
    const cartRows = cartRowsValue ? JSON.parse(cartRowsValue) : 'unavailable'

    const monetateQ = window.monetateQ || []
    monetateQ.push(['addCartRows', cartRows])
  }},
    addPurchaseRows: ${() => {
      const getPurchaseRows = document.getElementsByClassName(
        'monetate-add-purchase-row'
      )
      const purchaseRowsValue = getPurchaseRows?.[0]?.getAttribute('value')
      const purchaseRows = purchaseRowsValue
        ? JSON.parse(purchaseRowsValue)
        : 'unavailable'

      const monetateQ = window.monetateQ || []
      monetateQ.push(['addPurchaseRows', purchaseRows])
    }},
    addCategories: ${() => {
      const getCategories = document.getElementsByClassName(
        'monetate-add-categories'
      )

      const categoriesValue = getCategories?.[0]?.getAttribute('value')
      const categories = categoriesValue
        ? JSON.parse(categoriesValue)
        : ['unavailable']

      const monetateQ = window.monetateQ || []
      monetateQ.push(['addCategories', categories])
    }},
    addBreadcrumbs: ${() => {
      const getBreadcrumbs = document.getElementsByClassName(
        'monetate-add-breadcrumbs'
      )

      const breadcrumbsValue = getBreadcrumbs?.[0]?.getAttribute('value')
      const breadcrumbs = breadcrumbsValue
        ? JSON.parse(breadcrumbsValue)
        : ['unavailable']

      const monetateQ = window.monetateQ || []
      monetateQ.push(['addBreadcrumbs', breadcrumbs])
    }},
  reTrack: () => {
    MonetateHelper.setPage()
    MonetateHelper.addProducts()
    MonetateHelper.addProductDetails()
    MonetateHelper.addCartRows()
    MonetateHelper.addPurchaseRows()
    MonetateHelper.addCategories()
    MonetateHelper.addBreadcrumbs()
    MonetateHelper.pushTrackData()
  },
}`
