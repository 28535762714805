import { IconButton } from '@components/UI'
import { ProductFullPageGalleryProps } from '../ProductFullPageGallery'
import ProductImage from '../../../../components/ProductImage/ProductImage'
import { Swiper } from 'swiper/react'
import { styled } from '@mui/material/styles'
import { Z_INDEX_LV2, Z_INDEX_LV4, Z_INDEX_LV5 } from '../../../../constants/ui'

const ProductImageSlider = styled(Swiper, {
  name: 'PdpDetails',
  slot: 'ImageSlider',
})(({ theme }) => ({
  height: '100%',

  '.swiper-slide .lazy-load-image-background': {
    height: '100%',
  },

  '.swiper-slide img': {
    objectFit: 'contain',
    height: '100%',
    backgroundColor: theme.palette.color.white.main, // white background because the image is transparent
  },
}))

const SliderThumbNailsWrapper = styled(Swiper, {
  name: 'PdpDetails',
  slot: 'SliderThumbNailsWrapper',
  shouldForwardProp: (prop) => prop !== 'isGalleryMode',
})<{ isGalleryMode?: 1 | 0 }>(({ isGalleryMode }) => ({
  marginTop: isGalleryMode ? 0 : 16,
  marginBottom: 8,
  height: 345,
}))

const WrapperThumbnails = styled('div', {
  name: 'PdpDetails',
  slot: 'WrapperThumbnail',
  shouldForwardProp: (prop) => prop !== 'isGalleryMode',
})<{ isGalleryMode?: 1 | 0 }>(({ isGalleryMode, theme }) => ({
  display: 'block',
  marginLeft: 0,
  marginTop: isGalleryMode === 1 ? theme.spacing(20.5) : theme.spacing(6),
}))

const ThumbnailButtonContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'SliderNavigationContainer',
})(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.25),
  width: `calc(${theme.spacing(20)} - 2px )`,
  height: `calc(${theme.spacing(14)} - 2px )`,
}))

const SliderContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'SliderContainer',
})(() => ({
  width: '100%',
  position: 'relative',
}))

const SliderThumbNailContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'SliderThumbNailContainer',
  shouldForwardProp: (prop) => prop !== 'selected',
})<{
  selected?: boolean
}>(({ selected, theme }) => ({
  cursor: 'pointer',
  position: 'relative',
  width: `calc(${theme.spacing(20)} - 2px )`,
  height: `calc(${theme.spacing(14)} - 2px )`,
  border: `1.1px solid  ${
    selected ? theme.palette.primary.main : theme.palette.color.white.main
  }`,
  borderRadius: 8,
  overflow: 'hidden',

  '&:hover': {
    border: `1.1px solid  ${theme.palette.primary.dark}`,
  },

  'span.lazy-load-image-background': {
    width: '100%',
    height: 56,
    lineHeight: 0,
  },
}))

const ThumbNailBrandContainer = styled(SliderThumbNailContainer, {
  name: 'PdpDetails',
  slot: 'SliderContainer',
  shouldForwardProp: (prop) => prop !== 'isNative',
})<{ isNative: boolean }>(({ theme, isNative }) => ({
  cursor: 'auto',

  [theme.breakpoints.up('sm')]: {
    border: 'none',
    '&:hover': {
      border: 'none',
    },
    span: {
      padding: isNative ? theme.spacing(0, 3.5) : theme.spacing(1),
    },
  },
}))

const SliderThumbNail = styled(ProductImage, {
  name: 'PdpDetails',
  slot: 'SliderThumbNail',
})(() => ({
  objectFit: 'contain',
  width: '100%',
  height: '100%',
}))

const SliderNavigationContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'SliderNavigationContainer',
})(({ theme }) => ({
  zIndex: Z_INDEX_LV2,
  position: 'absolute',
  top: `calc(50% + ${theme.spacing(4)})`,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  transform: 'translate(0, -50%)',
  [theme.breakpoints.up('sm')]: {
    width: `calc(100% - ${theme.spacing(32)})`,
  },
}))

const SliderNavigationButton = styled(IconButton, {
  name: 'PdpDetails',
  slot: 'SliderNavigationButton',
  shouldForwardProp: (prop) =>
    prop !== 'positionStart' && prop !== 'positionEnd',
})<{ positionStart?: boolean; positionEnd?: boolean }>(
  ({ positionStart, positionEnd }) => ({
    padding: 0,
    visibility: positionStart || positionEnd ? 'hidden' : 'visible',
  })
)

// FULL PAGE GALLERY SLIDER
const ProductFullPageGalleryContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'ProductFullPageGalleryContainer',
  shouldForwardProp: (prop) => prop === 'isvisible' || prop === 'children',
})<ProductFullPageGalleryProps>(({ isvisible, theme }) => ({
  position: 'fixed',
  display: isvisible === 1 ? 'flex' : 'none',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: theme.palette.color.white.main,
  width: '100%',
  height: '100%',
  zIndex: Z_INDEX_LV5,
  left: 0,
  top: 0,
}))

const ProductFullPageGalleryCloseIcon = styled(IconButton, {
  name: 'ProductFullPageGallery',
  slot: 'CloseIcon',
})(({ theme }) => ({
  borderRadius: 0,
  boxShadow:
    'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
  padding: theme.spacing(4),
  position: 'absolute',
  zIndex: Z_INDEX_LV4,
  top: 0,

  right: 0,
  svg: {
    width: theme.spacing(6),
  },
}))

export {
  SliderThumbNailsWrapper,
  SliderContainer,
  SliderThumbNailContainer,
  SliderThumbNail,
  SliderNavigationContainer,
  SliderNavigationButton,
  ProductFullPageGalleryContainer,
  ProductImageSlider,
  ProductFullPageGalleryCloseIcon,
  ThumbNailBrandContainer,
  WrapperThumbnails,
  ThumbnailButtonContainer,
}
