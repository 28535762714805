import { styled } from '@mui/material/styles'

export const ProductPriceWrapper = styled('div', {
  name: 'ProductPrice',
  slot: 'Wrapper',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  fontSize: 14,
}))

export const ProductPriceContainerFinal = styled('div', {
  name: 'ProductPrice',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  alignItems: 'inherit',
}))
export const ProductPriceInitialPriceFinal = styled('span', {
  name: 'ProductPrice',
  slot: 'InitialPrice',
})(({ theme }) => ({
  color: theme.palette.text.dark,
  fontSize: 10,
  textDecoration: 'line-through',

  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
  },
}))

export const ProductPriceCurrentPriceFinal = styled('span', {
  name: 'ProductPrice',
  slot: 'CurrentPrice',
})(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,

  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
  },
}))

export const ProductPriceDiscountBoxFinal = styled('span', {
  name: 'ProductPrice',
  slot: 'DiscountBox',
})(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(0, 2),
  fontSize: 12,
  fontWeight: 600,
  border: `1px solid ${theme.palette.secondary.dark}`,
  borderRadius: 25,

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 2),
  },
}))
