//TODO: remove this until product model is normalized everywhere
// @ts-nocheck
import { Product, ProductsResData } from '../../types/FrameGenius/frameAdvisor'
import { ServerProduct } from '../../types/product'
import merge from 'lodash/merge'
import { SiteInfo } from '../../redux/reducers'
import frameGeniusService from '../../foundation/apis/frame-genius/frame-genius.service'
import {
  getBrand,
  getFrontColor,
  getHingeDistance,
  getIsRoxable,
  getLensesColor,
  getModelCodeDisplay,
  getModelName,
} from '../productAttributes'
import { getProductPrices } from '../productPrice'
import brandList from '../../components/BrandIcon/brandList'
import { generateProductImagePath } from '../product'
import CurrencyPriceUtils from '@utils/CurrencyPriceUtils'
import { CART, CHECKOUT, SIGNIN } from '../../constants/routes'
import { addCookie, getCookieByName } from '../cookie'
import queryString from 'query-string'
import ProductUtils from '@utils/ProductUtils'

export enum FrameAdvisorKeys {
  PROFILE_DATA = 'frameAdvisorProfileData',
  SESSION_DATA = 'frameAdvisorSessionData',
  FLOATING_BUTTON_MINIMIZED = 'frameAdvisorFloatingButtonMinimized',
  INITIALIZED = 'frameAdvisorInitialized',
}

const BLOCKED_ROUTES_FOR_FRAME_ADVISOR = [CHECKOUT, CART, SIGNIN]

export interface IFrameAdvisorUtil {
  saveSessionData: (data: object) => void
  // TODO: change any to SessionData type
  getSessionData: () => any
  wasFrameAdvisorPreviouslyInitialized: () => boolean
  setFrameAdvisorInitializedStatus: (status: boolean) => void
  setFloatingButtonMinimizedState: (isMinimized: boolean) => void
  isFloatingButtonMinimized: () => boolean
  needsReinit: () => boolean
  addPictureModeHashPath: () => void
  mapServerProductForFrameAdvisor: (
    basePath: string,
    product: ServerProduct,
    imgPath: string
  ) => Product
  mergeProductsByUpc: (
    mappedProducts: Product[],
    suggestedProducts: Product[]
  ) => Product[]
  mockResultsCallback: (data: ProductsResData) => Promise<unknown>
  fetchProducts: (
    data: ProductsResData,
    siteInfo: SiteInfo,
    langId: string
  ) => Promise<ServerProduct[]>
  isUserInExcludedPage: () => boolean
}

class FrameAdvisorUtil implements IFrameAdvisorUtil {
  private FRAME_ADVISOR_PICTURE_MODE_HASH_PATH = '#/picture-mode'

  saveSessionData = (data: Object) => {
    const encodedData = JSON.stringify(data)
    sessionStorage.setItem(FrameAdvisorKeys.SESSION_DATA, encodedData)
    localStorage.setItem(FrameAdvisorKeys.SESSION_DATA, encodedData)
  }

  getSessionData = () => {
    const sessionStorageSessionData = sessionStorage.getItem(
      FrameAdvisorKeys.SESSION_DATA
    )
    const localStorageSessionData = localStorage.getItem(
      FrameAdvisorKeys.SESSION_DATA
    )
    return (
      (sessionStorageSessionData && JSON.parse(sessionStorageSessionData)) ||
      (localStorageSessionData && JSON.parse(localStorageSessionData))
    )
  }

  wasFrameAdvisorPreviouslyInitialized = () => {
    const sessionStoragFrameAdvisorInitialized = sessionStorage.getItem(
      FrameAdvisorKeys.INITIALIZED
    )
    const localStorageStoragFrameAdvisorInitialized = localStorage.getItem(
      FrameAdvisorKeys.INITIALIZED
    )
    const cookieFrameAdvisorInitialized = getCookieByName(
      FrameAdvisorKeys.INITIALIZED
    )

    const wasItInitialized =
      sessionStoragFrameAdvisorInitialized ||
      localStorageStoragFrameAdvisorInitialized ||
      cookieFrameAdvisorInitialized
    return wasItInitialized === 'true'
  }

  setFrameAdvisorInitializedStatus = (status: boolean) => {
    sessionStorage.setItem(FrameAdvisorKeys.INITIALIZED, `${status}`)
    localStorage.setItem(FrameAdvisorKeys.INITIALIZED, `${status}`)
    addCookie(FrameAdvisorKeys.INITIALIZED, `${status}`)
  }

  setFloatingButtonMinimizedState = (isMinimized: boolean) => {
    sessionStorage.setItem(
      FrameAdvisorKeys.FLOATING_BUTTON_MINIMIZED,
      `${isMinimized}`
    )
    localStorage.setItem(
      FrameAdvisorKeys.FLOATING_BUTTON_MINIMIZED,
      `${isMinimized}`
    )
    addCookie(FrameAdvisorKeys.FLOATING_BUTTON_MINIMIZED, `${isMinimized}`)
  }

  isFloatingButtonMinimized = () => {
    const sessionStorageFloatingButton = sessionStorage.getItem(
      FrameAdvisorKeys.FLOATING_BUTTON_MINIMIZED
    )
    const localStorageFloatingButton = localStorage.getItem(
      FrameAdvisorKeys.FLOATING_BUTTON_MINIMIZED
    )
    const cookieFloatingButton = getCookieByName(
      FrameAdvisorKeys.FLOATING_BUTTON_MINIMIZED
    )
    const isMinimized =
      sessionStorageFloatingButton ||
      localStorageFloatingButton ||
      cookieFloatingButton
    return isMinimized === 'true'
  }

  isUserInExcludedPage = () => {
    const pathname = location.pathname
    return BLOCKED_ROUTES_FOR_FRAME_ADVISOR.some((route) =>
      pathname.includes(route)
    )
  }

  needsReinit = () => {
    const isFloatingButtonFeatureEnabled = true
    const wasFrameAdvisorInitializedAndNotExcludedPage =
      this.wasFrameAdvisorPreviouslyInitialized() &&
      !this.isUserInExcludedPage()

    return isFloatingButtonFeatureEnabled
      ? this.isFloatingButtonMinimized() &&
          wasFrameAdvisorInitializedAndNotExcludedPage
      : wasFrameAdvisorInitializedAndNotExcludedPage
  }

  addPictureModeHashPath = () => {
    window.location.hash = this.FRAME_ADVISOR_PICTURE_MODE_HASH_PATH
  }
  mapServerProductForFrameAdvisor = (
    basePath: string,
    product: ServerProduct,
    imgPath: string
  ): Product => {
    const prices = getProductPrices(product)
    const url = `${basePath}${product?.seo?.href}`
    const currency = CurrencyPriceUtils.getSymbolByName(
      prices?.currency ||
        ProductUtils.getCurrentPriceModel(product.price)?.currency ||
        ''
    )
    const brandName = getBrand(product)
    const brand = brandList.find(
      (b) => b.name.toLowerCase() === brandName?.toLowerCase()
    )
    const formattedPrice = ({ currency, price }) =>
      `${currency}${(+price).toFixed(2)}`.replace('.', ',')

    const imagePath = generateProductImagePath(
      imgPath,
      'Thumbnail',
      product,
      undefined,
      product.attachments,
      '1.0'
    )

    const getImageUrl = (width: number) => {
      return queryString.stringifyUrl({
        url: imagePath.toString(),
        query: {
          impolicy: 'GVI_resize',
          wid: width,
          bgc: '#f7f6f4',
        },
      })
    }

    return {
      brandlogoUrl: brand?.logo || '',
      brandName: brandName,
      frameColor: getFrontColor(product),
      hingeDistance: parseFloat(getHingeDistance(product)),
      imageUrl: getImageUrl(200),
      lensColor: getLensesColor(product),
      model: getModelCodeDisplay(product),
      price: formattedPrice({
        currency,
        price: prices?.discount || ProductUtils.getCurrentPrice(product.price),
      }),
      priceFull: formattedPrice({
        currency,
        price: prices?.full || ProductUtils.getInitialPrice(product.price),
      }),
      productName: getModelName(product),
      rxAvailable: getIsRoxable(product),
      upc: product.partNumber,
      url,
    }
  }
  mergeProductsByUpc = (
    mappedProducts: Product[],
    suggestedProducts: Product[]
  ): Product[] => {
    const upcs = suggestedProducts.map((product) => product.upc)
    return mappedProducts
      .filter((product) => upcs.includes(product.upc))
      .map((product) => {
        const found = suggestedProducts.find(
          (whiteLabelProduct) => whiteLabelProduct?.upc === product?.upc
        )
        const mergedProduct = merge(found, product)
        return mergedProduct
      })
  }

  mockResultsCallback = (data: ProductsResData) => {
    return new Promise((resolve) => {
      const products = data.products.map(
        (baseProduct) =>
          ({
            ...baseProduct,
            brandName: 'Ray-Ban',
            brandlogoUrl:
              'https://assets.sunglasshut.com/extra/image/LuxotticaRetail/SGH_brands_logos/ray-ban_wt_18.png?impolicy=SGH_bgtransparent&width=400',
            price: '108 €',
            priceFull: '135 €',
            productName: `RB3625 NEW AVIATOR ${baseProduct.upc}`,
            frameColor: 'Legend Gold',
            lensColor: 'Green',
            rxAvailable: true,
            imageUrl:
              'https://assets.sunglasshut.com/is/image/LuxotticaRetail/8056597650298__STD__shad__qt.png?impolicy=SGH_bgtransparent&width=200',
            url: 'https://www.sunglasshut.com/fr/ray-ban/rb3625-8056597650298',
          } as Product)
      )
      resolve(products)
    })
  }
  fetchProducts = async (
    data: ProductsResData,
    siteInfo: SiteInfo,
    langId: string
  ) => {
    const storeId = +siteInfo.storeID
    const contractId = siteInfo.contractId
    const catalogId = siteInfo.catalogID
    const suggestedProducts = data.products
    const upcs = suggestedProducts.map((product) => +product.upc)
    const products = await frameGeniusService.loadFrameAdvisorProducts(
      storeId,
      +langId,
      +catalogId,
      contractId,
      upcs
    )
    if (!products) {
      return []
    }
    return products
  }
}

export default new FrameAdvisorUtil()
