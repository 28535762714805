import {
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import { RootReducerState } from '../../redux/reducers'
import {
  PhysicalStore,
  PhysicalStoreResponse,
  shipToStoreUtils,
} from '@utils/shipToStoreUtils'
export interface IStoreQueryParams {
  latitude: string
  longitude: string
  radius?: string
  limit?: string
  offset?: string
  officeTypes?: string
}
export const physicalStoreApi = createApi({
  reducerPath: 'physicalStoreApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  endpoints: (builder) => ({
    getPhysicalStores: builder.query<PhysicalStore[], IStoreQueryParams>({
      queryFn: async (args, queryApi, _extraOptions, baseQuery) => {
        const state = queryApi.getState() as RootReducerState
        const { storeID, defaultLanguageID, transactionContext } =
          state.site.currentSite!

        const params = {
          siteLevelStoreSearch: false,
          langId: defaultLanguageID,
          ...args,
        }

        const result = await baseQuery({
          url: `${transactionContext}/store/${storeID}/storelocator/filtered/sts`,
          method: 'get',
          params,
        })

        return result.data
          ? {
              data: formattedPhysicalStores(
                result.data! as PhysicalStoreResponse
              ),
            }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
  }),
})

const formattedPhysicalStores = (data) => {
  const storeList: PhysicalStoreResponse[] = data?.PhysicalStore! || []
  return shipToStoreUtils.getMapPhysicalStoresData(storeList)
}
export const { useGetPhysicalStoresQuery, useLazyGetPhysicalStoresQuery } =
  physicalStoreApi
