import React, { useEffect, useMemo, useRef, useState } from 'react'
import { IPlacement } from '../../../../../types/cmsPlacement/Placement'
import { ICMCollection } from '../../../../../types/cmsPlacement/CMCollection'
import {
  WrapperWebFormModule,
  ContentWebFormModule,
  PreTitleWebFormModule,
  TextWebFormModule,
  IconContentWebFormModule,
  TitleWebFormModule,
  FormCollapseButton,
  FormCollapseButtonIcon,
  FormCollapseButtonTextIconWrapper,
  FormCollapseButtonUnderline,
  ContainerWebFormModule,
} from './WebFormModule.style'
import { Helmet } from 'react-helmet'
import CmsIcon from '@components/Cms/CmsComponents/CmsIcon/CmsIcon'
import { ICON_CHECKMARK_THIN, ICON_PRESCRIPTION_PEN } from 'src/constants/ui'
import { getTeaserOverlayTextAlign } from '@utils/placements'
import { Trans, useTranslation } from 'react-i18next'
import Collapse from '@mui/material/Collapse/Collapse'
import useBreakpoints from '@hooks/useBreakpoints'
import { useTheme } from '@mui/material'
import Log from 'src/services/Log'
import { StyledAnchor } from '@components/UI'
import { useSelector } from 'react-redux'
import {
  storeCfgServiceLinksSelector,
  webFormsLibsSelector,
} from 'src/redux/selectors/site'
import { useStoreIdentity } from 'src/foundation/hooks/useStoreIdentity'

const WebFormModule: React.FC<{
  placement: IPlacement | ICMCollection
  teaserIndex?: number
}> = () => {
  const { basePath } = useStoreIdentity()
  const serviceLinks = useSelector(storeCfgServiceLinksSelector)
  const webFormLibs = useSelector(webFormsLibsSelector)
  const { t } = useTranslation()
  const { isMobile } = useBreakpoints()
  const [toggleForm, setToggleForm] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const theme = useTheme()
  const webFormRef = useRef<HTMLElement | null>(null)

  const privacyLink = basePath + serviceLinks?.privacy

  const WebformStyles = `
  :root {
    --lxl-webform-font-family: Poppins,sans-serif;
    --lxl-webform-display: block;
    --lxl-webform-field-border-color: ${theme.palette.color.grey.light};
    --lxl-webform-field-border-radius : 4px;
    --lxl-webform-label-font-size: 12px;
    --lxl-webform-label-color:${theme.palette.text.dark};
    --lxl-webform-field-padding: 12px;
    --lxl-webform-valid-color: ${theme.palette.success.main};
    --lxl-webform-error-color: ${theme.palette.error.main};
    --lxl-webform-cta-bg-color: ${theme.palette.primary.main};
    --lxl-webform-title-color: ${theme.palette.text.dark};
    --lxl-webform-paragraph-color:${theme.palette.text.dark};
    --lxl-webform-icon-success:url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuMzMzMzMgOS4wNTdMNS40NzEzMyA3LjE5NTMzTDQuNTI4NjcgOC4xMzhMNy4zMzMzMyAxMC45NDNMMTEuODA0NyA2LjQ3MTMzTDEwLjg2MiA1LjUyODY3TDcuMzMzMzMgOS4wNTdaIiBmaWxsPSIjMjQ3NTM0Ii8+CjxwYXRoIGQ9Ik04IDBDMy41ODg2NyAwIDAgMy41ODg2NyAwIDhDMCAxMi40MTEzIDMuNTg4NjcgMTYgOCAxNkMxMi40MTEzIDE2IDE2IDEyLjQxMTMgMTYgOEMxNiAzLjU4ODY3IDEyLjQxMTMgMCA4IDBaTTggMTQuNjY2N0M0LjMyNCAxNC42NjY3IDEuMzMzMzMgMTEuNjc2IDEuMzMzMzMgOEMxLjMzMzMzIDQuMzI0IDQuMzI0IDEuMzMzMzMgOCAxLjMzMzMzQzExLjY3NiAxLjMzMzMzIDE0LjY2NjcgNC4zMjQgMTQuNjY2NyA4QzE0LjY2NjcgMTEuNjc2IDExLjY3NiAxNC42NjY3IDggMTQuNjY2N1oiIGZpbGw9IiMyNDc1MzQiLz4KPC9zdmc+Cg==);
    --lxl-webform-icon-error:url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzExNTI3XzE5MzMzKSI+CjxwYXRoIGQ9Ik04LjY2NjY4IDUuMzMzMjhINy4zMzMzNVYxMC42NjY2SDguNjY2NjhWNS4zMzMyOFoiIGZpbGw9IiNCQjI1MUEiLz4KPHBhdGggZD0iTTguNjY2NjggMTJINy4zMzMzNVYxMy4zMzMzSDguNjY2NjhWMTJaIiBmaWxsPSIjQkIyNTFBIi8+CjxwYXRoIGQ9Ik0xNS43NjMzIDEyLjkxNkw5Ljc4MjM1IDEuMTY3NjJDOS40MTEzNSAwLjQzOTI4NSA4LjcwNTY4IDAuMDc0OTUxMiA4LjAwMDAxIDAuMDc0OTUxMkM3LjI5NDM1IDAuMDc0OTUxMiA2LjU4ODY4IDAuNDM5Mjg1IDYuMjE3NjggMS4xNjc2MkwwLjIzNjY4IDEyLjkxNkMtMC40ODE5ODYgMTQuMzI3MyAwLjU0MzM0NyAxNiAyLjEyNzM1IDE2SDEzLjg3M0MxNS40NTY3IDE2IDE2LjQ4MiAxNC4zMjczIDE1Ljc2MzMgMTIuOTE2Wk0xNC41NDQ3IDE0LjI5MDNDMTQuNDc1MyAxNC40MDM2IDE0LjI3MSAxNC42NjY2IDEzLjg3MjcgMTQuNjY2NkgyLjEyNzM1QzEuNzI5MDEgMTQuNjY2NiAxLjUyNDY4IDE0LjQwMzYgMS40NTUzNSAxNC4yOTAzQzEuMzg2MDEgMTQuMTc3MyAxLjI0NDM1IDEzLjg3NTYgMS40MjUwMSAxMy41MjFMNy40MDYwMSAxLjc3MjYyQzcuNTczMzUgMS40NDM5NSA3Ljg3NzAxIDEuNDA4MjggOC4wMDAwMSAxLjQwODI4QzguMTIzMDEgMS40MDgyOCA4LjQyNjY4IDEuNDQzNjIgOC41OTQwMSAxLjc3MjYyTDE0LjU3NSAxMy41MjFDMTQuNzU1NyAxMy44NzU5IDE0LjYxNCAxNC4xNzczIDE0LjU0NDcgMTQuMjkwM1oiIGZpbGw9IiNCQjI1MUEiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF8xMTUyN18xOTMzMyI+CjxyZWN0IHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K);
    --lxl-webform-cta-border-radius: 100px;
    --lxl-webform-cta-text-align: center;

  }
`

  const onFormSubmit = (event) => {
    try {
      event.detail.success && setFormSubmitted(true)
    } catch (e) {
      Log.error('error while submitting form', e as any)
    }
  }
  const createFormElement = () => {
    try {
      const children = [
        React.createElement(
          'footer',
          { footer: '', key: 'lxl-app-contact-form-footer' },
          <div
            style={{
              marginBottom: 24,
              fontSize: 14,
            }}
          >
            <Trans i18nKey="webForm.footerLabel">
              <StyledAnchor to={privacyLink} target="_blank" />
            </Trans>
          </div>
        ),
        <style key={'lxl-app-contact-form-style'}>{WebformStyles}</style>,
      ]
      return (
        <>
          {React.createElement<{
            id: string
            brand: string
            locale: string
            country: string
          }>(
            'lxl-app-contact-form',
            {
              id: 'form',
              brand: 'GVI',
              locale: 'it',
              country: 'IT',
            },
            children
          )}

          <Helmet>
            <script src={webFormLibs?.js} />
          </Helmet>
        </>
      )
    } catch (e) {
      return null
    }
  }

  const webForm = useMemo(() => {
    return createFormElement()
  }, [isMobile])

  useEffect(() => {
    setToggleForm(!isMobile)
  }, [isMobile])

  useEffect(() => {
    window.document
      .getElementById('form')
      ?.addEventListener('lxlformsubmitted', onFormSubmit)
    return () => {
      window.document
        .getElementById('form')
        ?.removeEventListener('lxlformsubmitted', onFormSubmit)
    }
  }, [])

  useEffect(() => {
    formSubmitted &&
      webFormRef.current?.scrollIntoView({
        behavior: 'smooth',
      })
  }, [formSubmitted])

  return (
    <WrapperWebFormModule overlayTextAlign={getTeaserOverlayTextAlign('left')}>
      {!!webFormLibs && (
        <Helmet>
          <link rel="stylesheet" href={webFormLibs.css} />
        </Helmet>
      )}

      <ContentWebFormModule ref={webFormRef}>
        <IconContentWebFormModule>
          <CmsIcon
            teaserIcon={
              formSubmitted ? ICON_CHECKMARK_THIN : ICON_PRESCRIPTION_PEN
            }
            htmlColor={formSubmitted ? theme.palette.success.main : undefined}
          />
        </IconContentWebFormModule>
        <TitleWebFormModule variant={'h2'} textAlign={'left'}>
          {formSubmitted ? t('webForm.successTitle') : t('webForm.title')}
        </TitleWebFormModule>
        {!formSubmitted && (
          <PreTitleWebFormModule
            data-cm-metadata={'[{"_":"properties.teaserPreTitle"}]'}
            variant={'h1'}
            fontWeight={'600'}
          >
            {t('webForm.subTitle')}
          </PreTitleWebFormModule>
        )}
        <TextWebFormModule>
          {formSubmitted ? t('webForm.successText') : t('webForm.text')}
        </TextWebFormModule>
        <ContainerWebFormModule>
          <TextWebFormModule>
            {
              <>
                {!formSubmitted ? (
                  isMobile ? (
                    <FormCollapseButton
                      onClick={() => setToggleForm(!toggleForm)}
                    >
                      <FormCollapseButtonTextIconWrapper>
                        {t('webForm.collapseText')}
                        <FormCollapseButtonIcon
                          rotated={toggleForm}
                        ></FormCollapseButtonIcon>
                        <FormCollapseButtonUnderline />
                      </FormCollapseButtonTextIconWrapper>
                    </FormCollapseButton>
                  ) : null
                ) : null}
                {!formSubmitted ? (
                  <Collapse in={toggleForm}>{webForm}</Collapse>
                ) : null}
              </>
            }
          </TextWebFormModule>
        </ContainerWebFormModule>
      </ContentWebFormModule>
    </WrapperWebFormModule>
  )
}

export default WebFormModule
