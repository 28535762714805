import React, { useState } from 'react'
import { Product } from '../../../types/product'
import { styled } from '@mui/material/styles'
import { InfoOutlinedIcon } from '@components/UI/Icons'
import { StyledTooltip } from '@components/UI'
import { useTranslation } from 'react-i18next'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { ClickAwayListener } from '@mui/material'
import useBreakpoints from '../../../hooks/useBreakpoints'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'

interface IProductDetailsList {
  currentProduct: Product
  isLoading?: boolean
}

export const StyledSpecialFrameInfoWrapper = styled('div', {
  name: 'SpecialFrameInfo',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  fontSize: 12,
  color: theme.palette.text.dark,
}))

const StyledProductSpecialAttributeSpan = styled('span', {
  name: 'SpecialFrameInfo',
  slot: 'AttributeSpan',
})(() => ({
  display: 'flex',
  margin: 0,

  span: {
    fontWeight: 600,
    marginRight: 0,
  },
}))

const StyledProductSpecialAttribute = ({ attr }: any) => {
  const { t } = useTranslation()

  if ((!attr && attr.name !== 'GEOFIT') || attr.value === 'False') {
    return null
  }
  const attrName = () => {
    switch (true) {
      case attr.name === 'GEOFIT' && attr.value === 'Fit universale':
        return 'UNIVERSALFIT'
      case attr.name === 'GEOFIT' && attr.value === 'Fit a ponte basso':
        return 'LOWBRIDGEFIT'
      case attr.name === 'GEOFIT' && attr.value === 'Fit a ponte alto':
        return 'HIGHBRIDGEFIT'
      case attr.name === 'GEOFIT' && attr.value === 'Naselli regolabili':
        return 'ADJUSTABLENOSEPAD'
      default:
        return attr.name
    }
  }

  const { isMobile, isTabletPortrait, isTabletLandscape } = useBreakpoints()
  const touchableDevices = isMobile || isTabletPortrait || isTabletLandscape

  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => setOpen(false)
  const handleTooltipOpen = () => setOpen(true)
  const toggleTooltipOpen = () => setOpen(!open)

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <StyledProductSpecialAttributeSpan>
        <span>{t(`ProductDetails.SpecialFrameInfo.Label.${attrName()}`)}</span>
        {'\u00A0'}
        <StyledTooltip
          placement="top"
          contentVariant={{
            type: 'textonly',
            body: t(`ProductDetails.SpecialFrameInfo.Tooltip.${attrName()}`),
          }}
          colorVariant={'dark'}
          enterTouchDelay={0}
          open={open}
          onClick={touchableDevices ? toggleTooltipOpen : undefined}
          onMouseEnter={!touchableDevices ? handleTooltipOpen : undefined}
          onMouseLeave={!touchableDevices ? handleTooltipClose : undefined}
        >
          <div>
            <InfoOutlinedIcon />
          </div>
        </StyledTooltip>
      </StyledProductSpecialAttributeSpan>
    </ClickAwayListener>
  )
}

const attributesKeyToDisplay = ['GEOFIT', 'PHOTOCHROMIC', 'POLARIZED']

export const StyledSpecialFrameInfo = ({
  currentProduct,
  isLoading,
}: IProductDetailsList) => {
  const { t } = useTranslation()

  const { lxSearchPageProductType } = ProductAlgoliaUtils.getProductAttributes(
    currentProduct?.productAttributes!
  )
  const { isFrames } = ProductAlgoliaUtils.getLxProductTypes(
    lxSearchPageProductType
  )

  const attributes =
    currentProduct &&
    currentProduct.productAttributes &&
    Object.keys(currentProduct?.productAttributes)
      .filter((key) => attributesKeyToDisplay.includes(key))
      .reduce((cur, key) => {
        return Object.assign(cur, {
          [key]: currentProduct?.productAttributes[key],
        })
      }, {})

  return isLoading ? (
    <SkeletonTheme baseColor="transparent" highlightColor="rgba(0, 0, 0, 0.1)">
      <Skeleton style={{ borderRadius: 0 }} />
    </SkeletonTheme>
  ) : isFrames &&
    attributes &&
    Object.entries(attributes).length > 0 &&
    !Object.values(attributes).every((value) => value === 'False') ? (
    <StyledSpecialFrameInfoWrapper>
      {t('ProductDetails.SpecialFrameInfo.Label.modelFeatures')}
      {Object.keys(attributes)?.map((key, index, array) => {
        const value = attributes[key]
        const attributeObject = {
          name: key,
          value: value,
        }

        if (value === 'False') return null
        const nextAttributeIsNotFalse =
          array[index + 1] && attributes[array[index + 1]] !== 'False'

        return (
          <React.Fragment key={index}>
            {'\u00A0'}
            <StyledProductSpecialAttribute
              key={`product-attribute-special-${index}`}
              attr={attributeObject}
            />
            {nextAttributeIsNotFalse ? '\u00A0e \u00A0' : null}
          </React.Fragment>
        )
      })}
    </StyledSpecialFrameInfoWrapper>
  ) : null
}
