import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  AlliboWrapper,
  AutoCandContainer,
  AutoCandGrid,
  AutoCandSubtitle,
  AutoCandTitle,
  PageContainer,
  PageTitle,
} from './Careers.style'
import { Helmet } from 'react-helmet'
import { useGetHclPageQuery } from '../../features/cms/query'
import PlacementLayout from '@components/Cms/PlacementLayout'

const Careers: React.FC = () => {
  const { t } = useTranslation()
  const { data, isLoading } = useGetHclPageQuery('lavora-con-noi')
  const placements = data?.commercePlacements || []
  const isPageActive = true //TODO: page deactivated, waiting for approval

  return (
    <>
      <Helmet>
        <script src="https://joblink.allibo.com/ats2/Widget/jq" />
        <script src="https://joblink.allibo.com/ats2/Widget/js" />
        <title>{t('Careers.SEO.Title')}</title>
        <meta name="description" content={t('Careers.SEO.Description')} />
      </Helmet>
      {!isPageActive ? (
        <PageTitle>{t('Careers.Labels.Title')}</PageTitle>
      ) : null}
      <PageContainer isActive={isPageActive}>
        <PlacementLayout placements={placements} loading={isLoading} />
        <AlliboWrapper>
          <a
            href="https://allibo.com"
            data-allibo="https://joblink.allibo.com/ats2/Widget/connector.aspx?DM=PERnMB4RJB4x7UJlP79v78SWbp%2BuJ%2FBk0uRLYenz4QM%3D&SG=2&FT=448&LN=IT"
            rel="follow"
            style={{ textDecoration: 'none', cursor: 'default' }}
          >
            -
          </a>
          <AutoCandGrid>
            <AutoCandContainer>
              <AutoCandTitle>{t('Careers.Labels.AutoCandTitle')}</AutoCandTitle>
              <AutoCandSubtitle>
                {t('Careers.Labels.AutoCandSubtitle')}
              </AutoCandSubtitle>
              <a
                href="https://allibo.com"
                data-allibo="https://joblink.allibo.com/ats2/Widget/connector.aspx?DM=PERnMB4RJB4x7UJlP79v78SWbp%2BuJ%2FBk0uRLYenz4QM%3D&SG=2&FT=551&LN=IT"
                rel="follow"
                style={{ textDecoration: 'none', cursor: 'default' }}
              >
                -
              </a>
            </AutoCandContainer>
          </AutoCandGrid>
        </AlliboWrapper>
      </PageContainer>
    </>
  )
}

export default Careers
