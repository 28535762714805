import { styled } from '@mui/material/styles'
import {
  ProductPriceContainerFinal,
  ProductPriceCurrentPriceFinal,
  ProductPriceInitialPriceFinal,
} from '@components/ProductPriceFinal/ProductPriceFinal.style'

export const ProductPriceLabelStickyBar = styled('span', {
  name: 'ProductPrice',
  slot: 'LabelStickyBar',
})(({ theme }) => ({
  fontSize: 12,
  fontWeight: 'bold',
  textTransform: 'uppercase',
  gap: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    fontSize: 16,
  },
}))

export const ProductPriceContainerStickyBar = styled(
  ProductPriceContainerFinal,
  {
    name: 'ProductPrice',
    slot: 'ContainerStickyBar',
  }
)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(2),
  },
}))

export const ProductPriceCurrentPriceStickyBar = styled(
  ProductPriceCurrentPriceFinal,
  {
    name: 'ProductPrice',
    slot: 'CurrentPriceStickyBar',
  }
)(({ theme }) => ({
  fontWeight: 'bold',
  [theme.breakpoints.up('sm')]: {
    fontSize: 16,
  },
}))

export const ProductPriceInitialPriceStickyBar = styled(
  ProductPriceInitialPriceFinal,
  {
    name: 'ProductPrice',
    slot: 'InitialPriceStickyBar',
  }
)(({ theme }) => ({
  color: theme.palette.text.dark,
}))

export const ProductPriceDetailsContainerStickyBar = styled('div', {
  name: 'ProductPrice',
  slot: 'DetailsContainerStickyBar',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
}))
