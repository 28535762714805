import { Maybe } from './common'
import { PRODUCT_SOLDOUT_STATUS, PRODUCT_TYPES_MAP } from '../constants/product'
import { ProductAlgoliaPrice } from './ProductAlgolia/ProductAlgolia'

export type BooleanString = 'true' | 'false'

export type ProductSeo = {
  href?: Maybe<string>
}

export const PRODUCT_PRICE_USAGE_NAMES = {
  CURRENT: 'Offer', // current price of product, can be initial or discounted
  INITIAL: 'Display', // initial price of product, before applied discount
} as const

type UsageNames = keyof typeof PRODUCT_PRICE_USAGE_NAMES
type ProductPriceUsageName = (typeof PRODUCT_PRICE_USAGE_NAMES)[UsageNames]

export interface ServerProductPrice {
  contractId?: string
  currency: string
  description?: string
  usage: ProductPriceUsageName
  value: string
}

export interface DetailedRxPrice {
  type: string
  initialPrice: ServerProductPrice
  currentPrice: ServerProductPrice
}

export interface ServerProductXPrice {
  endDate?: string
  startDate?: string
  price: string
  currency: string
  priority?: string
}

export type ProductImage = {
  name: string
  sequence?: string
}

export type Attachment = {
  usage: string
  sequence: string
  name: string
  attachmentAssetPathRaw: string
  image?: string
  identifier?: string
  catentry_id?: string
  shortdesc?: string
  attachmentAssetID?: string
  mimeType: string
  attachmentAssetPath: string
}

// TODO: evaluate the idea of having an extra layer of normalized attributes, instead of calling getters all the time for attributes since they're aready properties of the object
export interface ServerProduct {
  attachments: Attachment[]
  images: {
    sequence: string
    name: string
  }[]
  attributes: ServerProductAttribute[]
  buyable: string
  cluster?: ServerProduct[]
  clusteredItems?: ServerProduct[]
  hasSingleSKU: boolean
  id?: string
  items?: ServerProduct[]
  longDescription?: string
  merchandisingAssociations?: ClusteredAssociatedProduct[]
  catalogEntryTypeCode?: string
  name: string
  parentCatalogGroupID: string | string[]
  parentCatalogEntryID?: string
  partNumber: string
  price: ServerProductPrice[]
  x_prices: {
    offer?: ServerProductXPrice
    list?: ServerProductXPrice
  }
  seo?: ProductSeo
  shortDescription?: string
  numberOfSKUs?: number
  sKUs?: ServerProduct[]
  storeID: string
  type?: string
  uniqueID?: string
  x_offerpriceRx?: string
  resourceId?: string
  manufacturer?: string
  'relationship.item.id'?: string
  'relationship.item.sequence'?: string
  _ignored?: string
  quantity?: string
  orderItemExtendAttribute: Attribute[]
  associationType?: string
  x_price: { [key: string]: ProductAlgoliaPrice }
}

export interface ServerProductAttribute {
  identifier: string
  'attribute.natural': string
  usage: string
  values: [
    {
      sequence: string
      identifier: string
      value: string
      uniqueID: string
      unitOfMeasure?: string
      unitID?: string
    }
  ]
  displayable: BooleanString
  merchandisable: BooleanString
  searchable: BooleanString
  sequence: string
  storeDisplay?: BooleanString
  name: string
  facetable: BooleanString
  comparable: BooleanString
  key?: string
  uniqueID: string
  swatchable: BooleanString
}

export interface ProductAnalyticsRX extends ServerProduct {
  rxPrice?: number
}

export type ProductSoldOutStatus =
  (typeof PRODUCT_SOLDOUT_STATUS)[keyof typeof PRODUCT_SOLDOUT_STATUS]

export type DefaultProductCtaStatus = Extract<
  ProductSoldOutStatus,
  typeof PRODUCT_SOLDOUT_STATUS.NONE
>
export type SoldOutProductCtaStatus = Exclude<
  ProductSoldOutStatus,
  typeof PRODUCT_SOLDOUT_STATUS.NONE
>

export interface Product {
  name: string
  type: string
  id: string
  uniqueID: string
  clusters?: ClusterProduct[]
  items?: ClusterProduct[]
  resourceId: string
  partNumber: string
  purchasable: boolean
  hasSingleSku: boolean
  numberOfSKUs: number
  manufacturer?: string
  storeID: string
  catalogEntryTypeCode?: string
  seo: {
    href: string
  }
  attributes?: ServerProductAttribute[]
  productAttributes: ProductAttributes
  parentCatalogGroupId: string | string[]
  prices: Price[]
  x_prices: {
    offer?: ServerProductXPrice
    list?: ServerProductXPrice
  }
  attachments: ServerProductAttachment[]
  merchandisingAssociations?: AssociatedProduct[]
  images?: []
  longDescription?: string
  x_price: { [key: string]: ProductAlgoliaPrice }
}

export interface ServerProductAttachment {
  image: string
  identifier: string
  sequence: string
  catentry_id: string
  usage: string
  name: string
  shortdesc: string
  attachmentAssetPathRaw: string
  attachmentAssetID: string
  mimeType: string
  attachmentAssetPath: string
}

export interface AssociatedProduct extends Product {
  associationType: string
}

export interface ClusterProduct extends Product {
  items?: Product[] // sKUs?
}

export interface ClusteredProduct {
  // NO PRODUCT
  cluster: Product[]
}

export interface ClusteredAssociatedProduct extends AssociatedProduct {
  // NO PRODUCT
  cluster: ServerProduct[]
}

export interface Price {
  usage: string
  contractId: string
  description: string
  currency: string
  value: string
}

export interface ProductAttributes {
  [key: string]: string
}

export type ProductType =
  (typeof PRODUCT_TYPES_MAP)[keyof typeof PRODUCT_TYPES_MAP]

export type Facet = {
  name: String
  entry: any[]
}

export interface SupplyData {
  quantity?: string
  discountAmount?: string
  originalBoxPrice?: string
  discountedBoxPrice?: string
  timePeriod?: string
}

export enum ProductTypesEnum {
  ContactLensesAccessories = 'Contact Lenses Accessories',
  Accessories = 'Accessories',
  ContactLenses = 'Contact Lenses',
}

export type Attribute = {
  attributeName: string
  attributeType: string
  attributeValue: string
}

export interface Size {
  size: string
  frameSize: string
  bridgeWidth: string
  sizeOrder: Sizes
  uniqueID: string
  hingeDistance: string
}

export enum Sizes {
  XXS,
  XS,
  S,
  M,
  L,
  XL,
  XXL,
}

export type PictureType =
  | 'quarter'
  | 'front'
  | 'lateral'
  | 'closed front'
  | 'back'
  | 'alternative'
  | 'folding'
  | 'folding group'
  | 'group'
  | 'OnModel'
  | 'adv'

export type ProductImageUsage = 'PDP' | 'PLP' | 'Thumbnail'

export interface PriceModel {
  price: string
  currency: string
}
