import { styled } from '@mui/material/styles'
export const CountdownContainer = styled('div', {
  name: 'TextModule',
  slot: 'CountdownContainer',
  shouldForwardProp: (prop) => prop !== 'textAlign',
})<{
  textAlign: React.CSSProperties['textAlign']
}>(() => ({
  maxWidth: '100%',
  width: '100%',

  /* h3: {
    marginBottom: theme.spacing(2),
  },*/

  /*[theme.breakpoints.between('md', 1280)]: {
    h3: {
      marginBottom: theme.spacing(8),
    },
  },*/
}))

export const StyledCountdownContainer = styled('h3', {
  name: 'TextModule',
  slot: 'Countdown',
})(() => ({
  fontSize: 32,
  fontWeight: 600,
  lineHeight: 1.25,
  margin: 0,
}))
