import React, { FC } from 'react'
import usePlayerBanner from '../../../../../hooks/useBannerPlayer'
import {
  LeftComboMiniSlider,
  RightComboMiniSlider,
  StyledRightContainer,
  WrapperComboMiniSlider,
} from './ComboMiniSlider.style'
import MiniSlider from './components/MiniSlider'
import SquareBoardWithoutSplit from '../SquareBoardWithoutSplit'
import {
  IPlacement,
  isCMCollection,
  isLXTeaser,
  isVideoMedia,
} from '../../../../../types/cmsPlacement/Placement'
import { ICMCollection } from '../../../../../types/cmsPlacement/CMCollection'

interface ComboMiniSliderProps {
  placement: IPlacement | ICMCollection
  reflect?: boolean
  teaserIndex?: number
}

const ComboMiniSlider: FC<ComboMiniSliderProps> = ({
  placement,
  reflect,
  teaserIndex,
}) => {
  const viewType = placement?.viewtype || 'default'
  const banner =
    'items' in placement
      ? placement.items.find(isLXTeaser)
      : placement.teasableItems.find(isLXTeaser)

  const media = banner?.media?.[0] ?? undefined
  const video = isVideoMedia(media!) ? media : undefined
  const playerBanner = usePlayerBanner(video)

  const comboMiniItems =
    'items' in placement
      ? placement.items.find(isCMCollection)
      : placement.teasableItems.find(isCMCollection)

  const flexDirection: string = reflect ? 'row-reverse' : 'row'

  return (
    <WrapperComboMiniSlider flexDirection={flexDirection}>
      <LeftComboMiniSlider {...playerBanner.events}>
        <MiniSlider
          items={comboMiniItems!}
          teaserIndex={teaserIndex}
          viewType={viewType}
        />
      </LeftComboMiniSlider>
      <RightComboMiniSlider>
        <StyledRightContainer {...playerBanner.events}>
          <SquareBoardWithoutSplit
            placement={placement}
            teaserIndex={teaserIndex}
          />
        </StyledRightContainer>
      </RightComboMiniSlider>
    </WrapperComboMiniSlider>
  )
}

export default ComboMiniSlider
