import React, { useState } from 'react'
import { ILXTeaser } from '../../../../../../types/cmsPlacement/LXTeaser'
import { CmsTermsAndConditionPanel } from '../../../../CmsComponents/CmsTermsAndConditions'
import { ToastProp } from '../../../../../UI/Toast/Toast.type'
import {
  SquatBannerTermsConditionButton,
  SquatBannerTermsConditionButtonContainer,
} from '../SquatBanner.style'

export const SquatBannerTermsAndCondition: React.FC<{
  item: ILXTeaser
}> = ({ item }) => {
  const [isTermsAndConditionOpen, setIsTermsAndConditionOpen] =
    useState<ToastProp['open']>(false)
  return (
    <>
      <SquatBannerTermsConditionButtonContainer>
        <SquatBannerTermsConditionButton
          teaser={item}
          onClick={() => setIsTermsAndConditionOpen(!isTermsAndConditionOpen)}
        />
      </SquatBannerTermsConditionButtonContainer>
      <CmsTermsAndConditionPanel
        teaser={item}
        open={isTermsAndConditionOpen}
        onClose={() => setIsTermsAndConditionOpen(!isTermsAndConditionOpen)}
      />
    </>
  )
}
