import React, { FC, PropsWithChildren } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { styled } from '@mui/material/styles'

interface WithLoadingProps {
  className?: string
  height?: string
  isLoading: boolean
  skeletonCount?: number
}

const StyledSkeletonContainer = styled('div', {
  name: 'WithLoading',
  slot: 'SkeletonContainer',
  shouldForwardProp: (prop) => prop !== 'heightProp',
})<{ heightProp?: string }>(({ heightProp }) => ({
  height: heightProp ?? '100%',

  '& > div': {
    display: 'flex',
    flex: 1,

    '& > span': {
      flex: 1,
    },
  },

  '@supports not (aspect-ratio: 1 / 1)': {
    display: 'block',
    paddingTop: '25%',
    paddingBottom: '25%',
  },
}))

/** HOC to display Skeleton depending on `isLoading` prop instead of children component */
const WithLoading: FC<PropsWithChildren<WithLoadingProps>> = ({
  children,
  height,
  isLoading,
  // skeletonCount = 1,
}) => (
  <>
    {isLoading ? (
      <>
        {/* {[...Array(skeletonCount).keys()].map((_, index) => ( */}
        <StyledSkeletonContainer
          // key={index}
          aria-describedby="Breadcrumbs skeleton"
        >
          <SkeletonTheme
            baseColor="transparent"
            highlightColor="rgba(0, 0, 0, 0.1)"
          >
            <Skeleton style={{ borderRadius: 0, height: height || '100%' }} />
          </SkeletonTheme>
        </StyledSkeletonContainer>
        {/* ))} */}
      </>
    ) : (
      children
    )}
  </>
)

export default WithLoading
