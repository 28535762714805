export const PAGE_TYPES = {
  HOME: 'Home',
  PRODUCTS: 'Plp',
  CART: 'CartPage',
  WISHLIST: 'Wishlist',
  PDP: 'Pdp',
  PAYMENT: 'Payment',
  THANK_YOU: 'Thankyou',
  CHECKOUT: 'Delivery',
  RB: 'Custompage',
  OO: 'Custompage',
  STORELOCATOR: 'StoreLocator',
} as const

export const PAGE_SECTION_1 = {
  home: 'Home',
  plp: 'Catalog',
  pdp: 'Catalog',
  wishlist: 'Account',
  cart: 'CartPage',
  'thank-you': 'Checkout',
  checkout: 'Checkout',
}

export const PAGE_SECTION_2 = {
  [PAGE_TYPES.THANK_YOU]: 'Standard',
  [PAGE_TYPES.PDP]: window.location.pathname,
}

export const PAGE_DESIGN = {
  [PAGE_TYPES.HOME]: '2020',
  [PAGE_TYPES.PDP]: 'VM',
}
