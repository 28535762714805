import React from 'react'
import { IVideoMedia } from '../../../../../../types/cmsPlacement/Media'
import CMSCommonMedia from '../../../../CmsComponents/CmsCommonMedia'
import { useStoreIdentity } from '../../../../../../foundation/hooks/useStoreIdentity'
import { placementHasTextShadow } from '@utils/placements'

import {
  SquareBoardWithShoppbaleImg,
  SquareBoardWithSplitMediaOverlapAnchor,
  SquareBoardWithSplitMediaOverlapContainer,
} from '../SquareBoardWithSplit.style'
import useCmsTeaserBanner from '../../../../../../hooks/useCmsBanner'
import usePlayerBanner from '../../../../../../hooks/useBannerPlayer'
import { ImageOverlapProps } from '@components/Cms/CmsPlacement/CmsBanner/CMBanner.type'
import CmsVideoController from '@components/Cms/CmsComponents/CmsCommonMedia/CmsVideoController'

const SquareBoardsWithSplitMediaOverlap: React.FC<ImageOverlapProps> = ({
  viewType,
  teaser,
  crop,
  children,
  bannerIndex,
  teaserIndex,
}) => {
  const { basePath } = useStoreIdentity()

  const {
    placementHotZones,
    placementHotZonesSettings,
    isShoppableImage,
    media,
    isVideo,
    toLink,
  } =
    useCmsTeaserBanner({
      item: teaser,
      viewType,
    }) || {}

  const playerBanner = usePlayerBanner(media! as IVideoMedia)

  const bannerImageCommonProps = {
    'aria-label': `Placement_Banner${bannerIndex}_IMG link. Teaser №${teaserIndex}`,
    'data-element-id': `${teaserIndex}Placement_Banner${bannerIndex}_IMG`,
  }

  return (
    <SquareBoardWithSplitMediaOverlapContainer viewType={viewType!}>
      {children}
      {toLink ? (
        <SquareBoardWithSplitMediaOverlapAnchor
          {...bannerImageCommonProps}
          to={`${basePath}${toLink}`}
        >
          <CMSCommonMedia
            isLazy={true}
            viewType={viewType!}
            type={crop || ''}
            media={media}
            playerBannerHook={playerBanner}
            teaser={teaser}
            isBackgroundGradientNeeded={placementHasTextShadow({
              teaser,
              viewType,
            })}
            isFetchPriority={teaserIndex === 1}
          />
        </SquareBoardWithSplitMediaOverlapAnchor>
      ) : (
        <CMSCommonMedia
          isLazy={true}
          viewType={viewType!}
          type={crop || ''}
          media={media}
          playerBannerHook={playerBanner}
          teaser={teaser}
          isBackgroundGradientNeeded={placementHasTextShadow({
            teaser,
            viewType,
          })}
          isFetchPriority={teaserIndex === 1}
        />
      )}
      {isShoppableImage && (
        <SquareBoardWithShoppbaleImg
          hotZones={placementHotZones}
          hotZonesSettings={placementHotZonesSettings}
        />
      )}
      {isVideo && <CmsVideoController playerBanner={playerBanner} />}
    </SquareBoardWithSplitMediaOverlapContainer>
  )
}

export default SquareBoardsWithSplitMediaOverlap
