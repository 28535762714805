import React from 'react'
import { CustomWrapper, Title } from './Playground.style'
import { Button } from '@components/UI'
import { DownloadIcon, HomeIcon } from '@components/UI/Icons'
const Playground: React.FC = () => {
  return (
    <>
      <Title>
        This is a Playground area where you can test your components
      </Title>

      <CustomWrapper>
        <Button variant={'primary'} fillType={'fill'}>
          Custom content
        </Button>
        <Button variant={'primary'} fillType={'fill'}>
          No Icon
        </Button>
        <Button
          variant={'primary'}
          fillType={'fill'}
          endIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
        >
          Right Icon
        </Button>
        <Button
          variant={'primary'}
          fillType={'fill'}
          endIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
          disabled
        >
          Disabled
        </Button>
        <Button
          variant={'primary'}
          fillType={'fill'}
          startIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
        >
          Left Icon
        </Button>
        <Button
          variant={'primary'}
          fillType={'fill'}
          startIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
          size={'big'}
        >
          Left Icon big
        </Button>
      </CustomWrapper>

      <CustomWrapper>
        <Button variant={'secondary'} fillType={'fill'}>
          Custom content
        </Button>
        <Button variant={'secondary'} fillType={'fill'}>
          No Icon
        </Button>
        <Button
          variant={'secondary'}
          fillType={'fill'}
          endIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
        >
          Right Icon
        </Button>
        <Button
          variant={'secondary'}
          fillType={'fill'}
          endIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
          disabled
        >
          Disabled
        </Button>
        <Button
          variant={'secondary'}
          fillType={'fill'}
          startIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
        >
          Left Icon
        </Button>
        <Button
          variant={'secondary'}
          fillType={'fill'}
          startIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
          size={'big'}
        >
          Left Icon big
        </Button>
      </CustomWrapper>

      <CustomWrapper>
        <Button variant={'tertiary'} fillType={'fill'}>
          Custom content
        </Button>
        <Button variant={'tertiary'} fillType={'fill'}>
          No Icon
        </Button>
        <Button
          variant={'tertiary'}
          fillType={'fill'}
          endIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
        >
          Right Icon
        </Button>
        <Button
          variant={'tertiary'}
          fillType={'fill'}
          endIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
          disabled
        >
          Disabled
        </Button>
        <Button
          variant={'tertiary'}
          fillType={'fill'}
          startIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
        >
          Left Icon
        </Button>
        <Button
          variant={'tertiary'}
          fillType={'fill'}
          startIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
          size={'big'}
        >
          Left Icon big
        </Button>
      </CustomWrapper>

      <CustomWrapper>
        <Button variant={'quaternary'} fillType={'fill'}>
          Custom content
        </Button>
        <Button variant={'quaternary'} fillType={'fill'}>
          No Icon
        </Button>
        <Button
          variant={'quaternary'}
          fillType={'fill'}
          endIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
        >
          Right Icon
        </Button>
        <Button
          variant={'quaternary'}
          fillType={'fill'}
          endIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
          disabled
        >
          Disabled
        </Button>
        <Button
          variant={'quaternary'}
          fillType={'fill'}
          startIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
        >
          Left Icon
        </Button>
        <Button
          variant={'quaternary'}
          fillType={'fill'}
          startIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
          size={'big'}
        >
          Left Icon big
        </Button>
      </CustomWrapper>

      <CustomWrapper>
        <Button variant={'primary'} fillType={'outline'}>
          Custom content
        </Button>
        <Button variant={'primary'} fillType={'outline'}>
          No Icon
        </Button>
        <Button
          variant={'primary'}
          fillType={'outline'}
          endIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
        >
          Right Icon
        </Button>
        <Button
          variant={'primary'}
          fillType={'outline'}
          endIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
          disabled
        >
          Disabled
        </Button>
        <Button
          variant={'primary'}
          fillType={'outline'}
          startIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
        >
          Left Icon
        </Button>
        <Button
          variant={'primary'}
          fillType={'outline'}
          startIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
          size={'big'}
        >
          Left Icon big
        </Button>
      </CustomWrapper>

      <CustomWrapper>
        <Button variant={'secondary'} fillType={'outline'}>
          Custom content
        </Button>
        <Button variant={'secondary'} fillType={'outline'}>
          No Icon
        </Button>
        <Button
          variant={'secondary'}
          fillType={'outline'}
          endIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
        >
          Right Icon
        </Button>
        <Button
          variant={'secondary'}
          fillType={'outline'}
          endIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
          disabled
        >
          Disabled
        </Button>
        <Button
          variant={'secondary'}
          fillType={'outline'}
          startIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
        >
          Left Icon
        </Button>
        <Button
          variant={'secondary'}
          fillType={'outline'}
          startIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
          size={'big'}
        >
          Left Icon big
        </Button>
      </CustomWrapper>

      <CustomWrapper>
        <Button variant={'tertiary'} fillType={'outline'}>
          Custom content
        </Button>
        <Button variant={'tertiary'} fillType={'outline'}>
          No Icon
        </Button>
        <Button
          variant={'tertiary'}
          fillType={'outline'}
          endIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
        >
          Right Icon
        </Button>
        <Button
          variant={'tertiary'}
          fillType={'outline'}
          endIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
          disabled
        >
          Disabled
        </Button>
        <Button
          variant={'tertiary'}
          fillType={'outline'}
          startIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
        >
          Left Icon
        </Button>
        <Button
          variant={'tertiary'}
          fillType={'outline'}
          startIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
          size={'big'}
        >
          Left Icon big
        </Button>
      </CustomWrapper>

      <CustomWrapper>
        <Button variant={'quaternary'} fillType={'outline'}>
          Custom content
        </Button>
        <Button variant={'quaternary'} fillType={'outline'}>
          No Icon
        </Button>
        <Button
          variant={'quaternary'}
          fillType={'outline'}
          endIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
        >
          Right Icon
        </Button>
        <Button
          variant={'quaternary'}
          fillType={'outline'}
          endIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
          disabled
        >
          Disabled
        </Button>
        <Button
          variant={'quaternary'}
          fillType={'outline'}
          startIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
        >
          Left Icon
        </Button>
        <Button
          variant={'quaternary'}
          fillType={'outline'}
          startIcon={<HomeIcon htmlColor={'inherit'} className="icon" />}
          size={'big'}
        >
          Left Icon big
        </Button>
      </CustomWrapper>
      <CustomWrapper>
        <DownloadIcon />
      </CustomWrapper>
    </>
  )
}

export default Playground
