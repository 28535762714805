import { styled } from '@mui/material/styles'

const FRAME_ADVISOR = {
  WRAPPER_WIDTH: 376,
  WRAPPER_HEIGHT: 668,
  MINIMIZED_CLASS: 'fa-minimized',
  SELECTOR: 'frameadv-ui',
}

export const StyledFrameAdvisorWrapper = styled('div')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,

  '.fa__control-color-palette': {
    height: 90,
  },

  '.fa__page-container .fa__switch': {
    height: '100%',
    backgroundColor: theme.palette.color.white.main,
    button: {
      fontWeight: 600,
    },
  },

  '.fa__carousel-card, .fa__carousel-card--active': {
    borderRadius: 8,
    '.fa__image-wrapper': {
      borderRadius: '4px 4px 0 0',
    },
  },

  '.fa__carousel-checkmark, .gPHSst, .imBLxH': {
    borderRadius: 4,
  },

  '.hBfgsu': {
    borderRadius: '0 0 8px 8px !important',
  },

  '.fa__progress-group-button span': {
    fontFamily: theme.typography.fontFamily,
  },

  '.fa__profile .fa__progress': {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 0),
    marginBottom: theme.spacing(1),
  },

  '.css-16ggq00  .sc-fTQvRK.cAaFPp.fa__carousel-cardCopy': {
    borderRadius: '0 0 8px 8px',
  },

  '.lblihu': {
    borderRadius: 80,
  },

  '#bipa-record-consent-text': {
    fontFamily: theme.typography.fontFamily,
  },
  '#PRIVACY_IMPLICIT_AGREEMENT, #PRIVACY_AGREEMENT_TITLE, #PRIVACY_BUTTON_SUBMIT, #PRIVACY_BUTTON_CANCEL, #PRIVACY_IMPLICIT_TERMS_AND_CONDITIONS':
    {
      fontFamily: theme.typography.fontFamily,
    },

  '.MobileH4medium, .MsoNormal, .fa__face-text, .fa__temple-text, .fa__temple-label, .fa__temple-caption, .fa__your-style-label, .fa__your-style-text':
    {
      fontFamily: theme.typography.fontFamily,
    },

  '.fa__accordion-content div': {
    fontFamily: theme.typography.fontFamily,
  },

  '.fa__pdp-wrapper-flex': {
    fontFamily: theme.typography.fontFamily,
  },

  '.fa__loadingModal-brand, .fa__loadingModal-name, .fa__loadingModal-frame, .fa__loadingModal-title, .fa__loadingModal-description':
    {
      fontFamily: theme.typography.fontFamily,
    },

  '.fa__bullet-points-element-title, .fa__bullet-points-element-description': {
    fontFamily: theme.typography.fontFamily,
  },

  '.fa__frames-container': {
    fontFamily: theme.typography.fontFamily,
  },
  '.fa__share-container': {
    fontFamily: theme.typography.fontFamily,
  },
  '.fa__loaderCopy': {
    color: theme.palette.text.dark,
  },
  '.fa__share .fa__product-image': {
    objectFit: 'contain',
    background: theme.palette.grey[100],
  },
  '.fa__priceFull': {
    marginRight: theme.spacing(1),
    fontSize: 14,
  },
  '.fa__priceWrapper': {
    '.fa__price, fa__loadingModal-name': {
      fontFamily: theme.typography.fontFamily,
      fontSize: 14,
      fontWeight: 600,
    },
  },
  '.fa__live-wrapper': {
    '.fa__loadingModal-name': {
      fontFamily: theme.typography.fontFamily,
      fontSize: 14,
      fontWeight: 600,
    },
    '.fa__priceWrapper': {
      '~ button': {
        flex: 'initial',
      },
    },
  },
  '.fa__product-img': {
    marginBottom: theme.spacing(1),
  },
  '.fa__product-brand': {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  '.fa__product-name': {
    fontFamily: theme.typography.fontFamily,
    marginBottom: theme.spacing(1),
  },
  '.Mobilebodymedium': {
    fontFamily: theme.typography.fontFamily,
  },
}))

export const StyledFrameAdvisorOverlay = styled('div')({
  backgroundColor: '#000',
  inset: '0',
  opacity: '0.3',
  position: 'fixed',
  zIndex: '9998',
})

export const StyledFrameAdvisor = styled('div')(({ theme }) => ({
  backgroundColor: '#f4f4f4',
  height: FRAME_ADVISOR.WRAPPER_HEIGHT,
  left: '50%',
  overflow: 'hidden',
  position: 'fixed',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: FRAME_ADVISOR.WRAPPER_WIDTH,
  zIndex: '9999',
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.text.dark,
  '--fasa-switch-active-text': theme.palette.color.white.main,
  '--fasa-switch-inactive-text': theme.palette.color.grey.dark,
  '--fasa-font': theme.typography.fontFamily,
  '--fasa-font-light': theme.typography.fontFamily,

  '.fa__take-tips-wrapper': {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.dark,

    'svg > path': {
      fill: theme.palette.text.dark,
    },

    'svg > circle': {
      stroke: theme.palette.text.dark,
    },
  },

  '.fa__take-tips-title': {
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
  },

  '.fa__take-tips-list-element': {
    fontFamily: theme.typography.fontFamily,
  },

  '.fa__button': {
    borderRadius: '0',
  },

  '.fa__take-tips-proceed-button': {
    width: 'auto',
  },

  '.fa__button[color="primary"]': {
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(25),
    color: theme.palette.color.white.main,
    textTransform: 'uppercase',
    fontWeight: 600,
    padding: theme.spacing(3, 1),
  },

  '#bipa-overlay-root-style.bipa-overlay #bipa-component-container': {
    padding: theme.spacing(6),
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.dark,

    button: {
      fontWeight: 600,
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      textTransform: 'uppercase',

      '&:hover': {
        border: `1px solid ${theme.palette.primary.dark}`,
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.light,
      },

      '&.confirm': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.color.white.main,
        border: 'none',
        '&:hover': {
          background: theme.palette.primary.dark,
        },
      },
    },

    b: {
      textDecoration: 'underline',
    },
  },

  '#bipa-overlay-root-style #privacy-agreement .footer-bar .footer-content #PRIVACY_IMPLICIT_TERMS_AND_CONDITIONS':
    {
      color: theme.palette.text.dark,
    },

  '.fa__profile-picture-description': {
    fontFamily: theme.typography.fontFamily,

    button: {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.dark,
      fontWeight: 600,
    },
  },

  '.fa__profile-picture-landing': {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.dark,
  },

  '.fa__switch': {
    backgroundColor: theme.palette.color.white.main,
    '.fa__switch-slider': {
      backgroundColor: theme.palette.primary.main,
    },

    button: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 600,
    },
  },

  '.fa__playlist': {
    '&--active': {
      border: `2px solid ${theme.palette.color.grey.main}`,
    },

    '&-image-box': {
      '.checked': {
        border: `1px solid ${theme.palette.color.grey.main}`,
        backgroundColor: theme.palette.color.grey.main,
      },
      div: {
        border: `1px solid ${theme.palette.color.grey.main}`,
      },
      svg: {
        color: theme.palette.primary.main,
      },
    },
    '&-title': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.dark,
    },
    '&-description': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.dark,
    },
  },

  '.fa__menuitem-label': {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    '&-active': {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 600,
    },
  },

  '.fa__picture-product-info': {
    fontFamily: theme.typography.fontFamily,

    '.fa__loadingModal-price': {
      fontWeight: 600,
    },
    '.fa__loadingModal-name': {
      fontWeight: 600,
    },
  },

  // Profile
  '.fa__profile': {
    '.fa__profile-title, .fa__progress-group-text': {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 600,
      color: theme.palette.text.dark,
    },
    '.fa__progress-group-text': {
      color: theme.palette.text.dark,
    },
    '.fa__navigation': {
      backgroundColor: theme.palette.color.grey.main,
      '.fa__navigation-circle': {
        backgroundColor: theme.palette.primary.main,
      },
    },

    '.fa__progress': {
      boxShadow: 'none',
    },

    '.fa__progress-slider': {
      backgroundColor: theme.palette.color.grey.main,
    },

    '.fa__progress-value, .fa__control-text, .fa__control-value, .fa__control-description, .fa__status-action, .fa__reset-profile':
      {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.dark,
      },
    '.fa__progress-group-button': {
      'button, a': {
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.primary.main,
        fontWeight: 600,
        padding: theme.spacing(2),
      },

      a: {
        textTransform: 'uppercase',
      },

      span: {
        textTransform: 'none',
        fontWeight: 600,
      },
    },

    '.fa__account-button': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.primary.main,
      fontWeight: 600,
      textTransform: 'uppercase',
      borderRadius: theme.spacing(25),
      border: `1px solid ${theme.palette.primary.main}`,
    },

    '.fa__search-info-item': {
      svg: {
        color: theme.palette.primary.main,
      },
    },

    '.fa__status-checked-icon': {
      backgroundColor: theme.palette.color.grey.main,
    },

    '.fa__reset-profile': {
      color: theme.palette.primary.main,
      borderRadius: theme.spacing(25),
      border: `1px solid ${theme.palette.primary.main}`,
      fontWeight: 600,
      textTransform: 'uppercase',
      width: '100%',
      justifyContent: 'center',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),

      svg: {
        display: 'none',
      },

      span: {
        margin: 0,
      },
    },

    '.fa__profile-alert': {
      svg: {
        backgroundColor: theme.palette.color.grey.main,
        color: theme.palette.primary.main,
        borderColor: theme.palette.color.grey.main,
      },

      span: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.primary.main,
      },
    },
  },

  //Reset modal
  '.fa__reset-profile': {
    '&-text, &-dismiss-button, &-reset-button': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.primary.main,
    },
  },

  '.fa__reset-profile-reset-button': {
    backgroundColor: theme.palette.color.grey.main,
    fontWeight: 600,
    textTransform: 'uppercase',
  },

  // Header
  '.fa__header': {
    '.fa__header-flex': {
      h1: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 700,
        color: theme.palette.text.dark,
      },

      svg: {
        color: theme.palette.text.dark,
      },
    },

    '.fa__title-large': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.dark,
    },
  },

  // Content
  '.fa__page-content': {
    p: {
      fontFamily: theme.typography.fontFamily,
    },
    '.fa__title-large, .fa__temple-text, .fa__temple-caption, .fa__page-text': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.dark,
    },

    '.fa__toggle-style p': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.dark,
    },
    '.fa__frame': {
      '&-label': {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.dark,
      },

      '&-container': {
        svg: {
          color: theme.palette.primary.main,
        },
      },
    },

    '.fa__page-alert': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.dark,
    },
  },

  '.MobileCTACTA-minimedium': {
    fontFamily: theme.typography.fontFamily,
  },

  //Control
  '.fa__control': {
    svg: {
      color: theme.palette.primary.main,
    },
    '&-description': {
      textTransform: 'uppercase',
      fontWeight: 600,
    },
    '&-value, &-description': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.dark,
    },
  },

  // Face shape
  '.fa__face-shape-content': {
    '.fa__face-shape-description': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.dark,
    },
  },

  //FA Onboarding
  '.fa__boarding': {
    '.fa__title-large': {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 600,
    },
    '.fa__description': {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 400,
    },

    '.fa__button': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.spacing(25),
      color: theme.palette.color.white.main,
    },

    '.fa__carousel-onboarding-container': {
      '.fa__title-large': {
        marginBottom: 16,
      },
    },

    '.on-boarding-navigation': {
      bottom: '40%',
    },
  },

  // FA Card
  '.fa__card': {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.dark,

    '.fa__card-caption': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.dark,
    },

    '&.fa__your-style-card--selected': {
      border: `1px solid ${theme.palette.color.grey.main}`,
    },

    '.fa__card-input-icon': {
      '&--default': {
        border: `1px solid ${theme.palette.color.grey.main}`,
      },
      '&--selected': {
        border: `1px solid ${theme.palette.color.grey.main}`,
        backgroundColor: theme.palette.color.grey.main,

        svg: {
          color: theme.palette.primary.main,
        },
      },
    },
  },

  // Navigation
  '.fa__navigation': {
    '.fa__navigation-circle': {
      backgroundColor: theme.palette.color.grey.main,
    },

    '.fa__navigation-button .active': {
      backgroundColor: theme.palette.color.grey.lightest,
    },

    '.fa__navigation-label': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      fontWeight: 600,
    },

    svg: {
      color: theme.palette.primary.main,
    },
  },

  // Size Advisor
  '.fa__frame-size-content': {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.dark,

    '.fa__frame-size-accordion': {
      border: `1px solid ${theme.palette.color.grey.main}`,
    },

    '.fa__accordion-title': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.dark,
    },

    '.fa__advisor-interval-size': {
      color: theme.palette.color.grey.main,
    },

    '.fa__advisor-interval-active': {
      backgroundColor: theme.palette.secondary.main,
    },

    '.fa__advisor-interval-rectangle-ellipse': {
      backgroundColor: theme.palette.color.grey.dark,
      '&.fa__advisor-interval-active': {
        backgroundColor: theme.palette.color.grey.main,
      },
    },
  },

  // Frame color wrapper
  '.fa__page-frame-color-wrapper': {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.dark,
    '.fa__frame-color-description': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.dark,
    },

    '.fa__control-skin-undertone': {
      backgroundColor: theme.palette.color.grey.main,
    },

    '.fa__color-palette-wrapper': {
      span: {
        fontSize: 14,
      },
    },

    '.fa__color-palette': {
      span: {
        fontFamily: theme.typography.fontFamily,
        fontSize: 14,
        color: theme.palette.text.dark,
      },
    },

    '.fa__header': {
      backgroundColor: theme.palette.color.grey.lightest,

      '.fa__header-flex h1': {
        fontSize: 28,
      },
    },

    '.fa__control-description': {
      color: theme.palette.text.dark,
      textTransform: 'none',
    },
  },

  // FA skin
  '.fa__skin': {
    '&-label': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.dark,
    },

    '.fa__card[class$="--selected"]': {
      border: `1px solid ${theme.palette.color.grey.main}`,
    },

    '.fa__header': {
      backgroundColor: theme.palette.color.grey.lightest,

      '.fa__header-flex h1': {
        fontSize: 28,
      },
    },
  },

  // FA your shades
  '.fa__your-shades-content': {
    p: {
      fontFamily: theme.typography.fontFamily,
    },

    '.fa__your-shades-styled-card--selected': {
      border: `1px solid ${theme.palette.color.grey.main}`,

      '.fa__card-caption': {
        color: theme.palette.color.grey.main,
      },
    },
  },

  // FA mood playlist
  '.fa__mood-playlist': {
    p: {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.dark,
    },

    '.fa__carousel-card': {
      '&--active': {
        border: `2px solid ${theme.palette.color.grey.main}`,

        '.checked': {
          border: `1px solid ${theme.palette.color.grey.main}`,
          backgroundColor: theme.palette.color.grey.main,
          svg: {
            color: theme.palette.primary.main,
          },
        },
      },

      '.fa__carousel-checkmark': {
        border: `1px solid ${theme.palette.color.grey.main}`,
      },
    },

    '.fa__header': {
      backgroundColor: theme.palette.color.grey.lightest,

      '.fa__header-flex h1': {
        fontSize: 28,
      },
    },
  },

  // FA frames
  '.fa__frames': {
    fontFamily: theme.typography.fontFamily,

    '.fa__frames-card-container': {
      '.fa__frames-card': {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,

        '&--selected': {
          color: theme.palette.color.white.main,
          backgroundColor: theme.palette.primary.main,
        },
      },
    },

    '.fa__product-image': {
      backgroundColor: theme.palette.color.grey.lightest,
    },
  },

  // Share modal
  '.fa__shared-modal-action': {
    color: theme.palette.text.dark,
    fontFamily: theme.typography.fontFamily,

    svg: {
      color: theme.palette.primary.main,
    },

    '.fa__shared-modal-left-label': {
      color: theme.palette.text.dark,
      fontFamily: theme.typography.fontFamily,
    },
  },

  // FA share
  '.fa__share': {
    '.fa__product-icon': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.color.grey.main,

      '&:first-of-type': {
        backgroundColor: theme.palette.color.white.main,
      },
    },
  },

  // PDP
  '.fa__pdp-modal-content-wrapper': {
    color: theme.palette.text.dark,
    fontFamily: theme.typography.fontFamily,

    '.fa__loadingModal-row': {
      color: theme.palette.text.dark,

      svg: {
        color: theme.palette.text.dark,
      },
    },

    '.fa__bullet-points': {
      svg: {
        color: theme.palette.color.white.main,
        backgroundColor: theme.palette.primary.main,
      },
    },

    '.fa__go2pdp_callback': {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: 'transparent',
    },
  },

  '.fa__page-footer-label': {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 600,
  },

  '.fa__color-palette-wrapper': {
    span: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 16,
      fontWeight: 600,
    },
  },

  '&.fa-minimized': {
    height: 0,
    left: 0,
    overflow: 'visible',
    top: 0,
    transform: 'none',
    width: 0,

    '+ .frameadv-ui-overlay': {
      display: 'none',
      '.fa__floating-overlay &': {
        display: 'block',
      },
    },

    '.fa__floating-button-button': {
      border: `2px solid ${theme.palette.color.grey.main}`,
    },

    '.fa__floating-button-info-icon': {
      color: theme.palette.color.grey.main,
    },

    '.fa__floating-button-info-label': {
      color: theme.palette.text.dark,
      fontFamily: theme.typography.fontFamily,
    },

    '.fa__floating-button-info-hide-button': {
      color: theme.palette.text.dark,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 600,
    },
  },

  '.fa__no-favorites-title': {
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
  },

  '.fa__no-favorites-paragraph': {
    fontFamily: theme.typography.fontFamily,
  },

  '.fa__product-icon': {
    background: 'none',
    svg: {
      color: theme.palette.primary.main,
    },
  },

  '.vm-nudging-unknown': {
    ellipse: {
      stroke: theme.palette.color.white.main,
    },
  },
  '.vm-nudging-directions, .vm-nudging-stay-still': {
    ellipse: {
      stroke: '#6ee484',
    },
  },

  '.fa__frames-spinner': {
    borderColor: theme.palette.color.grey.main,
  },
}))
