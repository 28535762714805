import React from 'react'
import {
  SearchContainer,
  SearchTitle,
  SearchTitleContainer,
  SearchSubTitle,
  FindStoreInputStyled,
} from './FindStore.style'
import { useTranslation } from 'react-i18next'
import { ACCOUNT_CHILDREN } from '../../constants/routes'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useGoogleSuggestions } from '@hooks/useGoogleSuggestions'
import { StyledLoader } from '../UI'

export const FindStore: React.FC = () => {
  const { t } = useTranslation()

  const storeCountry = useStoreIdentity().country.toUpperCase()

  const { googleAPISuggestionEnabled } = useGoogleSuggestions({
    addressType: '',
    country: storeCountry,
    page: ACCOUNT_CHILDREN.STORES,
    state: '',
  })

  return (
    <SearchContainer>
      <SearchTitleContainer>
        <SearchTitle>{t('findStore.title')}</SearchTitle>
        <SearchSubTitle>{t('findStore.subTitle')}</SearchSubTitle>
        {googleAPISuggestionEnabled && <FindStoreInputStyled />}
        {!googleAPISuggestionEnabled && <StyledLoader size={40} />}
      </SearchTitleContainer>
    </SearchContainer>
  )
}
