import React, { useEffect, useRef, useState } from 'react'
import loadable from '@loadable/component'
import { useDispatch, useSelector } from 'react-redux'
import Fade from '@mui/material/Fade/Fade'
import { TransitionGroup } from 'react-transition-group'
//types
//components
//redux
import {
  hamburgerMenuOpenSelector,
  openDrawerSearchSelector,
} from '../../features/ui/selector'
import { menuLoadedSelector } from 'src/features/cms/selector'
//queries
import { useGetHeaderQuery } from '../../features/cms/query'
import {
  BenefitContainer,
  HamburgerMenuGreyBackground,
  WrapperHeader,
} from './Header.style'
//skeletons
import BenefitBarSkeleton from './components/HeaderSkeleton/BenefitBarSkeleton'
import NavigationBarSkeleton from './components/HeaderSkeleton/NavigationBarSkeleton'
import PromoBarSkeleton from './components/HeaderSkeleton/PromoBarSkeleton'
import useScrollingUp from '../../hooks/useScrollingUp/useScrollingUp'
import { fetchWishlistAction } from '../../features/wishList/action'
import { wishlistEnabledSelector } from '../../features/wishList/selector'
import { useSite } from '../../foundation/hooks/useSite'
import { wcTokenSelector } from '../../redux/selectors/user'
import { Helmet } from 'react-helmet'
import config from '../../configs'

// ALGOLIA
import { SearchClient } from 'algoliasearch'
import {
  initIndexName,
  searchClient as algoliaClient,
} from '../../foundation/algolia/algoliaConfig'
import SearchLayerAlgolia from './components/SearchLayer/SearchLayerAlgolia'
import { getContentHeader } from './utils'
import { Configure, InstantSearch } from 'react-instantsearch-hooks-web'
import { SEARCH_RESULTS_LIMIT } from '@configs/catalog'
import { localeLangCountryUtil } from '@utils/countryUtil'
import { INDICES_MAP } from '../../constants/productAlgolia'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useLocation } from 'react-router'
import { BOOKAPPOINTMENT } from '../../constants/routes'
import { useSearchParams } from 'react-router-dom'

const NavigationBar = loadable(() => import('./components/NavigationBar'), {
  fallback: <NavigationBarSkeleton />,
})
const BenefitBar = loadable(() => import('./components/BenefitBar'), {
  fallback: <BenefitBarSkeleton />,
})
const PromotionBar = loadable(() => import('./components/PromotionBar'), {
  fallback: <PromoBarSkeleton />,
})
const HamburgerMenu = loadable(() => import('./components/HamburgerMenu'))

// If you don’t want to perform a search request when the query is empty (""), you first need to detect it.
// When an empty search is detected, you must return a formatted response as an array of objects of the same length as the requests array.
// Each object requires at least these properties: hits, nbHits, and processingTimeMS.
// https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/react-hooks/#detecting-empty-search-requests
const searchClient: SearchClient = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params, query }) => !query && !params?.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: '',
          params: '',
        })),
      })
    }

    return algoliaClient.search(requests)
  },
}

const Header: React.FC = () => {
  const dispatch = useDispatch()
  const { mySite } = useSite()
  const isHamburgerDrawerOpen = useSelector(hamburgerMenuOpenSelector)
  const isSearchDrawerOpen = useSelector(openDrawerSearchSelector)
  const wcToken = useSelector(wcTokenSelector)
  const isWishlistEnabled = useSelector(wishlistEnabledSelector)
  const menuLoaded = useSelector(menuLoadedSelector)
  const location = useLocation()

  const scrolled = useScrollingUp()
  const { langCode } = useStoreIdentity()
  const langCountry = localeLangCountryUtil(langCode)
  const indexName = initIndexName(langCountry, INDICES_MAP.BEST_SELLERS)
  const [searchParams, _setSearchParams] = useSearchParams()

  const [height, setHeight] = useState(0)
  const ref = useRef<HTMLDivElement>(null)

  const previewDate = searchParams.get('previewDate') || undefined
  const filterRulesLocaleOverride =
    searchParams.get('filterRulesLocaleOverride') || undefined

  const params =
    previewDate || filterRulesLocaleOverride
      ? { previewDate, filterRulesLocaleOverride }
      : undefined

  const { data, isLoading } = useGetHeaderQuery(params)

  const { benefitBar, promoBar } = getContentHeader(data?.headerPlacements!)
  const extraCSS = data?.extraCSS || []
  const extraJS = data?.extraJS || []

  const isBookAppointmentPage =
    location.pathname === `/${langCode}/${BOOKAPPOINTMENT}`

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight)
    }
  })

  useEffect(() => {
    // `wcToken` (loggedIn or guest user) is required for wishlist requests
    if (mySite && isWishlistEnabled && !!wcToken) {
      dispatch(fetchWishlistAction())
    }
  }, [isWishlistEnabled, mySite, wcToken])

  const [isHeaderVisible, setHeaderVisibility] = useState<boolean | null>(null)

  useEffect(() => {
    if (scrolled == null) {
      setHeaderVisibility(null)
    } else {
      setHeaderVisibility(scrolled || isSearchDrawerOpen)
    }
  }, [scrolled, isSearchDrawerOpen])

  const cssLink = (link: string) => (
    <link
      key={link}
      rel="stylesheet"
      type="text/css"
      href={[config.cmsImageServerUrl, link].join('')}
    />
  )

  const jsTag = (src: string) => (
    <script
      key={src}
      src={[config.cmsImageServerUrl, src].join('')}
      type="text/javascript"
    />
  )

  return (
    <>
      <Helmet>
        {extraJS && extraJS.map((el) => jsTag(el))}
        {extraCSS && extraCSS.map((el) => cssLink(el))}
      </Helmet>
      {isHamburgerDrawerOpen && <HamburgerMenuGreyBackground />}
      <WrapperHeader isSticky={isHeaderVisible}>
        <TransitionGroup component={null}>
          {!isLoading ? (
            <Fade timeout={{ enter: 1000 }} in={!isLoading}>
              <BenefitContainer ref={ref}>
                <BenefitBar hasScrolled={scrolled!} data={benefitBar!} />
              </BenefitContainer>
            </Fade>
          ) : (
            <BenefitBarSkeleton />
          )}
          {menuLoaded ? (
            <Fade timeout={{ enter: 500 }} in={menuLoaded}>
              <div className="navbar-animation-wrapper">
                <NavigationBar
                  hasPromobar={!!promoBar}
                  hasScrolled={!!scrolled}
                />
              </div>
            </Fade>
          ) : (
            <NavigationBarSkeleton />
          )}
        </TransitionGroup>
        <HamburgerMenu
          open={isHamburgerDrawerOpen}
          plusMargin={isHamburgerDrawerOpen ? height : 0}
        />
        <TransitionGroup component={null}>
          {!isLoading ? (
            <Fade timeout={{ enter: 500 }} in={!isLoading}>
              <div className="promobar-animation-wrapper">
                {!isSearchDrawerOpen && !isBookAppointmentPage && (
                  <PromotionBar data={promoBar!} />
                )}
              </div>
            </Fade>
          ) : (
            <PromoBarSkeleton />
          )}
        </TransitionGroup>
        <InstantSearch searchClient={searchClient} indexName={indexName}>
          <Configure hitsPerPage={SEARCH_RESULTS_LIMIT} />
          <SearchLayerAlgolia
            hasScrolled={scrolled!}
            drawerprops={{
              variant: 'temporary',
              ModalProps: {
                keepMounted: true,
              },
            }}
            open={isSearchDrawerOpen}
            searchClient={searchClient}
            headerPlacements={data?.headerPlacements!}
          />
        </InstantSearch>
      </WrapperHeader>
    </>
  )
}

export default Header
