import { CART } from '../../../constants/routes'
import { ORDER_CONFIGS } from '../../../configs/order'
import { SUCCESS_MSG_PREFIX } from '../../../constants/common'
import type { SiteInfo } from '../../../redux/reducers'
import cartService from '../../../foundation/apis/transaction/cart.service'
import { createAsyncThunk } from '@reduxjs/toolkit'
import fetchCart from './fetchCart'
import last from 'lodash/last'
import { localStorageUtil } from '../../../foundation/utils/storageUtil'
import rxService from '../../../foundation/apis/rx-config/rx.service'
import { sendAddToCartEvent } from '../../../foundation/analytics/tealium/lib'
import { sendSuccessMessage } from '../../success/slice'
import { ImageryType } from '../../../components/PrescriptionLenses/PrescriptionLenses'

export interface IAddLensArgs {
  params: {
    partnumber: string | string[]
    quantity: string | string[]
    catentryId?: string | string[]
    contractId?: string
    widget?: string
    langId: string
    product?: any
    images?: ImageryType
  }
  callback?: any
  siteInfo: SiteInfo
}

export interface ICommonArgs {
  partnumbers: string[]
  quantities: any[]
  langId: string
  widget?: string
  contractId?: string
}

export interface IAddSingleLensArgs extends ICommonArgs {
  catentryIds: string[]
  images?: ImageryType
}

export interface IAddFrameArgs extends ICommonArgs {
  orderItemId: string
}

const addFrame = createAsyncThunk<any, IAddFrameArgs>(
  'order/addFrame',
  async (args) => {
    const { orderItemId, partnumbers, quantities, langId, widget, contractId } =
      args
    const _orderFrameExtendAttribute: any[] = []
    const _orderFrameExtendAttributes: any[] = []
    const _orderFrameItems: any[] = []

    for (const i in partnumbers) {
      if (orderItemId) {
        _orderFrameExtendAttribute.push(
          ...[
            {
              attributeName: 'IsRox',
              attributeType: 'String',
              attributeValue: 'true',
            },
            {
              attributeName: 'RxLensId',
              attributeType: 'String',
              attributeValue: orderItemId,
            },
          ]
        )
      }
      _orderFrameItems[i] = {
        quantity: quantities[i].toString(),
        partNumber: partnumbers[i],
        contractId,
        orderItemExtendAttribute: orderItemId
          ? _orderFrameExtendAttribute
          : undefined,
      }
      _orderFrameExtendAttributes[i] = {
        attributeName: 'LanguageId',
        attributeType: 'string',
        attributeValue: langId,
      }
    }

    let bodyFrame = {
      body: {
        orderId: '.',
        x_calculateOrder: ORDER_CONFIGS.calculateOrder,
        orderItem: _orderFrameItems,
        x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
        orderExtendAttribute: _orderFrameExtendAttributes,
        x_calculationUsage: ORDER_CONFIGS.calculationUsage,
      },
      widget,
    }

    return (await cartService.addOrderItem(bodyFrame)).data
  }
)

const addLens = createAsyncThunk<any, IAddSingleLensArgs>(
  'order/addSingleLens',
  async (args, { rejectWithValue }) => {
    try {
      const { quantities, catentryIds, langId, widget, contractId, images } =
        args

      const _orderLensExtendAttribute: any[] = []
      const _orderLensExtendAttributes: any[] = []
      const _orderLensItems: any[] = []

      const imagesArray = [
        {
          attributeName: 'rxProductImage',
          attributeType: 'String',
          attributeValue: images?.productImage || '',
        },
        {
          attributeName: 'rxFallbackImage',
          attributeType: 'String',
          attributeValue: images?.fallbackImage || '',
        },
      ]

      for (const i in catentryIds) {
        _orderLensExtendAttribute.push(
          ...[
            {
              attributeName: 'IsRox',
              attributeType: 'String',
              attributeValue: 'true',
            },
            {
              attributeName: 'IsRoxLens',
              attributeType: 'String',
              attributeValue: 'true',
            },
            ...imagesArray,
          ]
        )
        _orderLensItems[i] = {
          quantity: quantities[i].toString(),
          productId: catentryIds[i],
          contractId: contractId,
          orderItemExtendAttribute: _orderLensExtendAttribute,
        }
        _orderLensExtendAttributes[i] = {
          attributeName: 'LanguageId',
          attributeType: 'string',
          attributeValue: langId,
        }
      }

      let bodyLens = {
        body: {
          orderId: '.',
          x_calculateOrder: ORDER_CONFIGS.calculateOrder,
          orderItem: _orderLensItems,
          x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
          orderExtendAttribute: _orderLensExtendAttributes,
          x_calculationUsage: ORDER_CONFIGS.calculationUsage,
        },
        widget,
      }

      return (await cartService.addOrderItem(bodyLens)).data
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

const addLensItem = createAsyncThunk<any, IAddLensArgs>(
  'order/addLens',
  async (args, { dispatch, rejectWithValue }) => {
    try {
      const {
        partnumber,
        catentryId,
        quantity,
        langId,
        contractId,
        widget,
        product,
        images,
      } = args.params
      const addToCartSuccessCallback = args.callback
      const mySite = args.siteInfo

      const savedPrescription = localStorageUtil.get('prescriptionObject')

      let catentryIds: string[] = []
      let partnumbers: string[] = []
      let quantities: any[] = []

      if (partnumber) {
        partnumbers = partnumber instanceof Array ? partnumber : [partnumber]
      }

      if (catentryId) {
        catentryIds = catentryId instanceof Array ? catentryId : [catentryId]
      }

      quantities = quantity instanceof Array ? quantity : [quantity]

      const common: ICommonArgs = {
        langId,
        partnumbers,
        quantities,
        contractId,
        widget,
      }

      const responseLens = await dispatch(
        addLens({ ...common, catentryIds, images })
      ).unwrap()

      const orderItemId = (last(responseLens.orderItem) as any).orderItemId
      await dispatch(addFrame({ ...common, orderItemId })).unwrap()

      sendAddToCartEvent(product ? [product] : [])

      dispatch(
        fetchCart({
          ...args,
          widget,
          contractId,
        })
      )

      dispatch(
        sendSuccessMessage({
          key: SUCCESS_MSG_PREFIX + 'ITEM_ADD_SUCCESS',
          link: {
            url: CART,
            textKey: SUCCESS_MSG_PREFIX + 'ViewCart',
          },
        })
      )

      if (savedPrescription) {
        if (savedPrescription?.prescriptionFlow === 'MANUAL') {
          const prescriptionObject = {
            orderId: responseLens.orderId,
            orderItemId,
            prescription: {
              pupillaryDistance: savedPrescription?.PD?.OD
                ? String(savedPrescription.PD.OD)
                : undefined,
              rightSphere: savedPrescription?.SPH?.OD
                ? String(savedPrescription.SPH.OD)
                : undefined,
              rightAdd: savedPrescription?.ADD?.OD
                ? String(savedPrescription.ADD.OD)
                : undefined,
              rightAxis: savedPrescription?.AX?.OD
                ? String(savedPrescription.AX.OD)
                : undefined,
              rightCyl: savedPrescription?.CYL?.OD
                ? String(savedPrescription.CYL.OD)
                : undefined,
              leftSphere: savedPrescription?.SPH?.OS
                ? String(savedPrescription.SPH.OS)
                : undefined,
              leftAdd: savedPrescription?.ADD?.OS
                ? String(savedPrescription.ADD.OS)
                : undefined,
              leftAxis: savedPrescription?.AX?.OS
                ? String(savedPrescription.AX.OS)
                : undefined,
              leftCyl: savedPrescription?.CYL?.OS
                ? String(savedPrescription.CYL.OS)
                : undefined,
              lPupDistance: savedPrescription?.PD?.OS
                ? String(savedPrescription.PD.OS)
                : undefined,
              rPupDistance: savedPrescription?.PD?.OD
                ? String(savedPrescription.PD.OD)
                : undefined,
            },
          }

          const responsePrescription = await rxService.addPrescriptionDetails(
            mySite,
            prescriptionObject
          )

          if (responsePrescription.data?.successCode !== '200') {
            throw new Error(responsePrescription.data?.successMessage)
          }
        }
        if (savedPrescription?.prescriptionFlow === 'UPLOAD') {
          const prescriptionObject = {
            orderId: responseLens.orderId,
            orderItemId,
            rxFileStorageId: savedPrescription?.savedFileName,
          }

          const responsePrescription = await rxService.linkRXFileWithOrder(
            mySite,
            prescriptionObject
          )

          if (responsePrescription.data?.successCode !== '200') {
            throw new Error(responsePrescription.data?.successMessage)
          }
        }
        localStorageUtil.remove('prescriptionObject')
      }

      if (addToCartSuccessCallback) {
        addToCartSuccessCallback()
      }

      return true
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export default addLensItem

export { addFrame, addLens }
