import { styled } from '@mui/material/styles'
import { StyledTypography } from '@components/UI'

export const StyledGridOfProductsContainer = styled('div', {
  name: 'DcwProducts',
  slot: 'StyledGridOfProductsContainer',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  a: {
    flexGrow: 0,
    whiteSpace: 'nowrap',
  },
}))

export const StyledGridOfProductsItem = styled('div', {
  name: 'DcwProducts',
  slot: 'StyledGridOfProductsContainer',
  shouldForwardProp: (prop) => prop !== 'columnAmount',
})(({ theme }) => ({
  display: 'flex',
  flex: '0 0 50%',
  [theme.breakpoints.up('sm')]: {
    flex: '0 0 25%',
  },
}))

export const StyledSuggestionContainer = styled('div', {
  name: 'DcwProducts',
  slot: 'StyledSuggestionContainer',
})(({ theme }) => ({
  gridColumn: '1 / -1',
  backgroundColor: theme.palette.color.grey.lightest,
}))

export const WrapperDcwProductsTitleText = styled('div', {
  name: 'DcwProducts',
  slot: 'WrapperTitleText',
  shouldForwardProp: (prop) => prop !== 'paddingBottomEnabled',
})<{ paddingBottomEnabled: boolean }>(({ paddingBottomEnabled, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(10, 0, paddingBottomEnabled ? 6 : 0),

  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(4),
    padding: theme.spacing(16, 0, paddingBottomEnabled ? 6 : 0),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(16, 44, paddingBottomEnabled ? 6 : 0),
  },
}))

export const DcwProductsTitle = styled(StyledTypography, {
  name: 'DcwProducts',
  slot: 'Title',
})(() => ({}))

export const DcwProductsPreTitle = styled(StyledTypography, {
  name: 'DcwProducts',
  slot: 'PreTitle',
})(() => ({}))

export const DcwProductsText = styled('div', {
  name: 'DcwProducts',
  slot: 'Text',
})(() => ({
  fontSize: 16,
  p: {
    margin: '0',
  },
}))

export const WrapperDcwProductsCta = styled('div', {
  name: 'DcwProducts',
  slot: 'WrapperCta',
  shouldForwardProp: (prop) => prop !== 'paddingTopEnabled',
})<{ paddingTopEnabled: boolean }>(({ theme, paddingTopEnabled }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing(4),
  padding: theme.spacing(paddingTopEnabled ? 6 : 0, 11, 10),

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(paddingTopEnabled ? 6 : 0, 11, 16),
  },
}))
