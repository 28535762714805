import { useSite } from '../../foundation/hooks/useSite'
import { userDetailsSelector } from '../../redux/selectors/user'
import { useSelector } from 'react-redux'
import { PersonResponse } from '../../types/user'
import { useGetBookingStoresQuery } from '../../features/stores/query'

export const getStoresByAttribute = (
  attributeName: string,
  storeId: string,
  userDetails?: PersonResponse | null
) => {
  return (
    (userDetails?.contextAttribute &&
      userDetails?.contextAttribute
        .find((attribute) => attribute.attributeName === attributeName)
        ?.attributeValue.find((value) => value.storeId === storeId)
        ?.value[0].split(',')) ||
    []
  )
}

export const useFavoriteStores = () => {
  const userDetails = useSelector(userDetailsSelector)
  const { mySite } = useSite()

  const favoriteStoreIds = getStoresByAttribute(
    'StoreLocator.favorites',
    `${mySite.storeID}`,
    userDetails
  )

  const { data } = useGetBookingStoresQuery({
    storeNumbers: favoriteStoreIds,
  })

  const defaultFavoriteStoreId = getStoresByAttribute(
    'StoreLocator.default',
    `${mySite.storeID}`,
    userDetails
  )[0]

  return { favoriteStores: data, defaultFavoriteStoreId, favoriteStoreIds }
}
