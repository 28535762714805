import React from 'react'
import { ILXTeaser } from '../../../../../types/cmsPlacement/LXTeaser'

import {
  FullWidthBannerMobileWrapper,
  IconContentFullWidthBanner,
  StyledFullWidthBannerArticle,
  StyledFullWidthBannerCountDown,
  StyledFullWidthBannerCTAContainer,
  StyledFullWidthBannerPreTitle,
  StyledFullWidthBannerTextContainer,
  StyledFullWidthBannerTitle,
  StyledFullWidthBannerWrapper,
} from './FullWidthBanner.style'
import CmsProductTile from '../../../CmsComponents/CmsProductTile'
import FullWidthMediaOverlap from './components/FullWidthMediaOverlap'
import CmsCta from '../../../CmsComponents/CmsCta'
import useCmsTeaserBanner from '../../../../../hooks/useCmsBanner'
import { CmsShoppable } from '../../../CmsComponents/CmsShoppable/CmsShoppableImage'
import { FullWidthTermsAndCondition } from './components/FullWidthTermsAndCondition'
import { getTeaserOverlayTextAlign } from '@utils/placements'
import usePlayerBanner from '../../../../../hooks/useBannerPlayer'
import CmsFullBannerAnchor from '../../../CmsComponents/CmsFullBannerAnchor'
import CmsIcon from '../../../CmsComponents/CmsIcon'
import CmsVideoController from '@components/Cms/CmsComponents/CmsCommonMedia/CmsVideoController'
import { isLXTeaser } from '../../../../../types/cmsPlacement/Placement'
import { IVideoMedia } from '../../../../../types/cmsPlacement/Media'
import { IFullWidthBannerProps } from '@components/Cms/CmsPlacement/CmsBanner/FullWidthBanner/FullWidthBanner'

export const FullWidthMobileBanner: React.FC<IFullWidthBannerProps> = ({
  placement,
  teaserIndex,
  bannerIndex,
}) => {
  const item =
    'items' in placement
      ? placement?.items?.find(isLXTeaser)
      : placement?.teasableItems?.find(isLXTeaser)
  const viewType = placement.viewtype
  const {
    teaserTitle,
    teaserText,
    teaserTitleValue,
    teaserTextValue,
    teaserOverlaySettingsValue,
    teaserOverlayTextAlignValue,
    teaserOverlayStyleValue,
    promoteToH1,
    teaserLXCallToActionSettings,
    teaserIcon,
    teaserCountdownStart,
    teaserCountdownUntil,
    teaserPreTitle,
    teaserHideExpiredCountdown,
    placementHotZones,
    placementHotZonesSettings,
    isShoppableImage,
    toLink,
    productData,
    itemHasTermsAndCondition,
    isVideo,
    isFrameAdvisor,
    media,
  } =
    useCmsTeaserBanner({
      item,
      viewType,
    }) || {}

  const playerBanner = usePlayerBanner(media! as IVideoMedia)

  const content = (
    <>
      {teaserIcon && (
        <IconContentFullWidthBanner>
          <CmsIcon teaserIcon={teaserIcon} />
        </IconContentFullWidthBanner>
      )}
      {teaserCountdownStart &&
        teaserCountdownUntil &&
        teaserHideExpiredCountdown && (
          <StyledFullWidthBannerCountDown
            teaserCountdownStart={teaserCountdownStart}
            teaserCountdownUntil={teaserCountdownUntil}
            teaserHideExpiredCountdown={teaserHideExpiredCountdown}
          />
        )}
      <>
        {teaserPreTitle && (
          <>
            <StyledFullWidthBannerPreTitle
              data-cm-metadata={'[{"_":"properties.teaserPreTitle"}]'}
              variant={promoteToH1 ? 'h1' : 'h2'}
              fontWeight={'600'}
            >
              {teaserPreTitle}
            </StyledFullWidthBannerPreTitle>
          </>
        )}
        {teaserTitleValue && (
          <StyledFullWidthBannerTitle
            data-cm-metadata={`[{"_":"properties.${teaserTitle}"}]`}
            variant={promoteToH1 ? 'h1' : 'h2'}
          >
            {teaserTitleValue}
          </StyledFullWidthBannerTitle>
        )}
        {teaserTextValue && (
          <StyledFullWidthBannerTextContainer
            data-cm-metadata={`[{"_":"properties.${teaserText}"}]`}
            dangerouslySetInnerHTML={{ __html: teaserTextValue }}
          />
        )}
      </>
      {productData !== null && (
        <CmsProductTile product={productData} tileIndex={0} />
      )}
    </>
  )

  return (
    <FullWidthBannerMobileWrapper>
      <CmsFullBannerAnchor
        toLink={toLink}
        bannerIndex={bannerIndex}
        teaserIndex={teaserIndex}
      >
        <FullWidthMediaOverlap
          crop={'FULL_WIDTH_BANNER'}
          playerBannerHook={playerBanner}
          teaser={item!}
          media={media!}
          viewType={viewType}
          teaserIndex={teaserIndex}
        >
          <StyledFullWidthBannerWrapper
            overlaysettings={teaserOverlaySettingsValue}
            istextoverlay={true}
          >
            <StyledFullWidthBannerArticle
              overlaysettings={teaserOverlaySettingsValue}
              overlaytxtalign={getTeaserOverlayTextAlign(
                teaserOverlayTextAlignValue
              )}
              teaseroverlaystyle={teaserOverlayStyleValue}
            >
              {content}
            </StyledFullWidthBannerArticle>
          </StyledFullWidthBannerWrapper>
        </FullWidthMediaOverlap>
      </CmsFullBannerAnchor>
      {!!teaserLXCallToActionSettings &&
        teaserLXCallToActionSettings?.length > 0 && (
          <StyledFullWidthBannerCTAContainer
            data-element-id={isFrameAdvisor && 'Footer_FrameGenius_Open'}
          >
            {teaserLXCallToActionSettings?.map((actionSettings, index) => (
              <CmsCta
                actionSettings={actionSettings}
                dataElementId={`${teaserIndex}Placement_Banner${bannerIndex}_CTA${
                  index + 1
                }`}
                key={`cms-content__text-module--cta-${index}`}
              />
            ))}
          </StyledFullWidthBannerCTAContainer>
        )}
      {isVideo && <CmsVideoController playerBanner={playerBanner} />}
      {isShoppableImage && (
        <CmsShoppable
          hotZones={placementHotZones}
          hotZonesSettings={placementHotZonesSettings}
        />
      )}
      {itemHasTermsAndCondition && (
        <FullWidthTermsAndCondition item={item as ILXTeaser} />
      )}
    </FullWidthBannerMobileWrapper>
  )
}
