//Standard libraries
import React, { FC, useMemo } from 'react'
//UI
import { generatePath, useParams } from 'react-router-dom'
import {
  ACCOUNT,
  ACCOUNT_CHILDREN,
  BASE,
  WISHLIST,
} from '../../constants/routes'
import AccountSummary from '../AccountSummary/AccountSummary'
import AccountSettings from '../AccountSettings/AccountSettings'
import AccountDashboard from '../AccountDashboard/AccountDashboard'
import AccountStores from '../AccountStores/AccountStores'
import AddressBook from '../AddressBook/AddressBook'
import OrderHistory from '../OrderHistory/OrderHistory'
import AccountPageLayout from './components/AccountPageLayout'
import EditAddress from '../EditAddress'

export type IAccountActiveComponent = FC<{
  onSetAccountNavDisplay(display: boolean): void
}>

const ACCOUNT_PAGE_COMPONENT_MAP: Record<string, IAccountActiveComponent> = {
  [ACCOUNT_CHILDREN.DASHBOARD]: AccountDashboard,
  [ACCOUNT_CHILDREN.PERSONAL_INFORMATION]: AccountSummary,
  [ACCOUNT_CHILDREN.ADDRESS_BOOK]: AddressBook,
  [ACCOUNT_CHILDREN.EDIT_ADDRESS]: EditAddress,
  [ACCOUNT_CHILDREN.STORES]: AccountStores,
  [ACCOUNT_CHILDREN.ORDER_HISTORY]: OrderHistory,
  [ACCOUNT_CHILDREN.SETTINGS]: AccountSettings,
}

/**
 * Generates react-router path of children account page. (`/:country/account/:page`).
 * To get account page itself page param should be omitted.
 *
 * @param langCode string
 * @param page string. Name of children page path (e.g. address-book, wishlist, order-history)
 */
export const generateAccountPagePath = (
  langCode: string,
  page?: string,
  queryParams?: Record<string, string>
) => {
  const query = queryParams
    ? `?${new URLSearchParams(queryParams).toString()}`
    : ''
  return page?.toLocaleLowerCase() !== WISHLIST.toLowerCase()
    ? generatePath(`/:country/${ACCOUNT}`, {
        country: langCode,
        page: page || '',
      }) + query
    : generatePath(`${BASE}/${WISHLIST}`, { country: langCode })
}

const Account: FC = () => {
  const { page: pageRouterParam } = useParams<{ page: string }>()
  const ActiveChildComponent = useMemo<IAccountActiveComponent | null>(() => {
    if (
      pageRouterParam &&
      Object.prototype.hasOwnProperty.call(
        ACCOUNT_PAGE_COMPONENT_MAP,
        pageRouterParam
      )
    ) {
      return ACCOUNT_PAGE_COMPONENT_MAP[pageRouterParam]
    }

    return null
  }, [pageRouterParam])

  return (
    <AccountPageLayout
      ActiveComponent={ActiveChildComponent || AccountDashboard}
    />
  )
}

export default Account
