import React, { CSSProperties, FC, PropsWithChildren } from 'react'
import { styled } from '@mui/material/styles'
import CSS from 'csstype'

type FlexboxProps = Pick<CSSProperties, 'width' | 'height' | 'gap' | 'flex'> &
  Pick<
    CSS.StandardLonghandPropertiesHyphen,
    'align-items' | 'flex-direction' | 'justify-content'
  >

const FlexDiv = styled('div')<FlexboxProps>`
  display: flex;
  ${(props) => {
    let resultString = ''

    for (const key in props) {
      const propValue = props[key]
      if (!propValue || typeof propValue !== 'string') {
        continue
      }

      resultString = resultString.concat(`${key}: ${propValue};`)
    }

    return resultString
  }}
`

export const Flexbox: FC<PropsWithChildren<FlexboxProps>> = ({
  children,
  ...rest
}) => {
  return <FlexDiv {...rest}>{children}</FlexDiv>
}
