import config from '@configs/index'
import ProductImage from '../../../ProductImage/ProductImage'
import React, { useCallback, useState } from 'react'
import {
  StyledImageGalleryWrapper,
  StyledImageWrapper,
  StyledSquare,
  StyledSuggestedProductAnchor,
  StyledSuggestedProductSubWrapper,
  StyledSuggestedProductTileImageContainer,
  StyledSuggestedProductTileWrapper,
} from './SuggestedProductTile.style'
import { Product, ProductImageUsage } from '../../../../types/product'
import SuggestedProductTileGallery from './components/SuggestedProductTileGallery'
import { Swiper } from 'swiper'
import { pdpFrameImageOrderSelector } from '../../../../redux/selectors/site'
import { productsMobileListingLayoutSelector } from '../../../../features/ui/selector'
import { setLastSelectedProduct } from '../../../../features/ui/action'
import { useAppDispatch } from '@hooks/redux'
import useBreakpoints from '@hooks/useBreakpoints'
import { shallowEqual, useSelector } from 'react-redux'
import { useProductImages } from '@hooks/useProductImages/useProductImages'
import { sortImageByConf } from '@utils/attachmentsUtils'
import useProductTileOld from '@components/ProductTile/useProductTileOld'
import SuggestedProductTileFooter from './components/SuggestedProductTileFooter'
import SuggestedProductTileHeader from './components/SuggestedProductTileHeader'
import ProductTileAlgoliaUtils from '@utils/ProductAlgolia/ProductTileAlgoliaUtils'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'

export interface SuggestedProductTileProps {
  footerElementsDisplay?: {
    colors: boolean
    inWishlistAdded: boolean
  }
  product: Product
  onClick?: () => void
  tileIndex: number
  variant?: string
  onWishlistButtonClick?: (productId: string) => void
  isClustered?: boolean
  isPDP?: boolean
  isLazyLoadEnabled?: boolean
  isImgWithShadow?: boolean
  isImgFront?: boolean
}

const siteName = config.REACT_APP_STORENAME

const SuggestedProductTile: React.FC<SuggestedProductTileProps> = ({
  product,
  onClick,
  tileIndex,
  variant,
  isLazyLoadEnabled,
  isImgWithShadow,
  isImgFront,
}) => {
  const dispatch = useAppDispatch()
  const productsMobileListingLayout = useSelector(
    productsMobileListingLayoutSelector
  )
  const pdpFrameImageOrder = useSelector(
    pdpFrameImageOrderSelector,
    shallowEqual
  )
  const { isViewportWidthUnder426, isMobile, isTabletPortrait } =
    useBreakpoints()
  const [carouselMode, setCarouselMode] = useState<boolean>(false)

  const filterCluster = product?.clusters?.filter(
    (cluster) => cluster.partNumber === product.partNumber
  )!

  const {
    getTileDataElementId,
    getProductImageWidth,
    getClustersData,
    selectedClusterIndex,
    setSelectedClusterIndex,
  } = useProductTileOld({ ...product, clusters: filterCluster }, true)

  const { clusters, clusterLength, clusterSelected, productLinkTo } =
    getClustersData

  const { badges } = ProductTileAlgoliaUtils.getProductTileAttributes(
    clusterSelected.productAttributes
  )

  const { modelName, lxSearchPageProductType } =
    ProductAlgoliaUtils.getProductAttributes(clusterSelected.productAttributes)

  const { isContactLenses } = ProductAlgoliaUtils.getLxProductTypes(
    lxSearchPageProductType
  )

  const tileDataElementId = getTileDataElementId(tileIndex)
  const productImageWidth = getProductImageWidth(variant)

  const { images: currentImages } = useProductImages(clusterSelected)
  const sortedImageByConf = sortImageByConf(currentImages, pdpFrameImageOrder)

  const [_first, _second] = sortedImageByConf

  const imgFrontalProps = {
    sequence: isImgWithShadow ? _first?.sequence : '1.0',
    usage: isImgWithShadow ? (_first?.usage as ProductImageUsage) : 'PLP',
  }

  const imgQuarterProps = {
    sequence: isImgWithShadow ? _second?.sequence : '2.0',
    usage: isImgWithShadow ? (_second?.usage as ProductImageUsage) : 'PLP',
  }

  const imgProps = isImgFront ? imgFrontalProps : imgQuarterProps
  const imgHoverProps = isImgFront ? imgQuarterProps : imgFrontalProps

  const onProductTileMouseEnter = () => {
    if (isViewportWidthUnder426 || isMobile || isTabletPortrait) return
    if (clusterLength > 0) setCarouselMode(true)
  }

  const onProductTileMouseLeave = () => {
    if (isViewportWidthUnder426 || isMobile || isTabletPortrait) return
    if (carouselMode) setCarouselMode(false)
  }
  const onImageClick = () => dispatch(setLastSelectedProduct(product?.id || ''))

  const commonProductImageProps = {
    alt: modelName,
    draggable: false,
    sequence: '1.0',
    usage: 'PLP' as 'PLP',
    width: productImageWidth,
    onClick: onImageClick,
  }

  const onProductChange = useCallback((swiper: Swiper) => {
    if (Number.isInteger(swiper.realIndex)) {
      setSelectedClusterIndex(swiper.realIndex)
    }
  }, [])

  const metaData = {
    'aria-describedby': badges.primaryBadge || `${modelName}`,
    'data-element-id': tileDataElementId,
    'data-description': encodeURIComponent(`${siteName}_${modelName}`),
  }
  return (
    product && (
      <StyledSuggestedProductTileWrapper
        onMouseEnter={onProductTileMouseEnter}
        onMouseLeave={onProductTileMouseLeave}
        {...metaData}
      >
        <StyledSuggestedProductSubWrapper>
          <StyledSquare onClick={onClick}>
            <>
              <SuggestedProductTileHeader clusterSelected={clusterSelected} />
              {(carouselMode || productsMobileListingLayout === 'full') &&
              clusters &&
              !isContactLenses ? (
                <StyledImageGalleryWrapper>
                  <SuggestedProductTileGallery
                    mocoLink={productLinkTo}
                    tileDataElementId={tileDataElementId}
                    priorityBadgeText={badges.primaryBadge}
                    commonImageProps={imgHoverProps}
                    currentProductIndex={selectedClusterIndex}
                    onProductChange={onProductChange}
                    cluster={clusters}
                    clusterViewLength={clusterLength!}
                  />
                </StyledImageGalleryWrapper>
              ) : (
                <StyledSuggestedProductTileImageContainer>
                  <StyledSuggestedProductAnchor
                    aria-label={modelName}
                    to={productLinkTo}
                    {...metaData}
                  >
                    <StyledImageWrapper>
                      <div>
                        <ProductImage
                          isLazyLoadEnabled={isLazyLoadEnabled}
                          attachments={clusterSelected?.attachments}
                          {...commonProductImageProps}
                          {...imgProps}
                        />
                      </div>
                    </StyledImageWrapper>
                  </StyledSuggestedProductAnchor>
                </StyledSuggestedProductTileImageContainer>
              )}
            </>
          </StyledSquare>
        </StyledSuggestedProductSubWrapper>
        <SuggestedProductTileFooter
          clusterSelected={clusterSelected}
          metaData={metaData}
        />
      </StyledSuggestedProductTileWrapper>
    )
  )
}

export { SuggestedProductTile }
