import { IMedia } from './Media'
import { ILXTeaser } from './LXTeaser'
import { IBasePlacementItem } from './Placement'
import { ICMCollection } from './CMCollection'

export interface LXDynamicContent extends IBasePlacementItem {
  dynamicRules: IDynamicRule[]
  dynamicDefault: ILXTeaser | ICMCollection
  type: 'LXDynamicContent'
  media: IMedia[]
}

export const DYNAMIC_CONTENT_KEYS = {
  ANTI_REFLECTIVE: 'ANTI_REFLECTIVE',
  ANTI_SCRATCH: 'ANTI_SCRATCH',
  FACE_SHAPE: 'FACE_SHAPE',
  FRAME_SHAPE: 'FRAME_SHAPE',
  GENDER: 'GENDER',
  GEOFIT: 'GEOFIT',
  IS_NEW: 'IS_NEW',
  LIMITED_EDITION: 'LIMITED_EDITION',
  PHOTOCHROMIC: 'PHOTOCHROMIC',
  POLARIZED: 'POLARIZED',
  PRODUCT_TYPE: 'PRODUCT_TYPE',
  ROXABLE: 'ROXABLE',
  SPECIAL_PROJECT_FLAG: 'SPECIAL_PROJECT_FLAG',
  SPECIAL_PROJECT_DESCRIPTION: 'SPECIAL_PROJECT_DESCRIPTION',
  SPECIAL_PROJECT_SPONSOR: 'SPECIAL_PROJECT_SPONSOR',
  SPECIAL_PROJECT_TYPE: 'SPECIAL_PROJECT_TYPE',
  SUSTAINABILITY_CLAIM: 'SUSTAINABILITY_CLAIM',
  UV_PROTECTION: 'UV_PROTECTION',
  FRAME_SHAPE_FACET: 'FRAME_SHAPE_FACET',
  FRONT_COLOR_FACET: 'FRONT_COLOR_FACET',
  LENS_COLOR_FACET: 'LENS_COLOR_FACET',
  LENS_TREATMENT_FACET: 'LENS_TREATMENT_FACET',
} as const

export const {
  ANTI_REFLECTIVE,
  ANTI_SCRATCH,
  FACE_SHAPE,
  FRAME_SHAPE,
  GENDER,
  GEOFIT,
  IS_NEW,
  LIMITED_EDITION,
  PHOTOCHROMIC,
  POLARIZED,
  PRODUCT_TYPE,
  ROXABLE,
  SPECIAL_PROJECT_FLAG,
  SPECIAL_PROJECT_DESCRIPTION,
  SPECIAL_PROJECT_SPONSOR,
  SPECIAL_PROJECT_TYPE,
  SUSTAINABILITY_CLAIM,
  UV_PROTECTION,
  FRAME_SHAPE_FACET,
  FRONT_COLOR_FACET,
  LENS_COLOR_FACET,
  LENS_TREATMENT_FACET,
} = DYNAMIC_CONTENT_KEYS

export type IDynamicContentKey =
  (typeof DYNAMIC_CONTENT_KEYS)[keyof typeof DYNAMIC_CONTENT_KEYS]

export const dynamicContentKeys: IDynamicContentKey[] = [
  ANTI_REFLECTIVE,
  ANTI_SCRATCH,
  FACE_SHAPE,
  FRAME_SHAPE,
  GENDER,
  GEOFIT,
  IS_NEW,
  LIMITED_EDITION,
  PHOTOCHROMIC,
  POLARIZED,
  PRODUCT_TYPE,
  ROXABLE,
  SPECIAL_PROJECT_FLAG,
  SPECIAL_PROJECT_DESCRIPTION,
  SPECIAL_PROJECT_SPONSOR,
  SPECIAL_PROJECT_TYPE,
  SUSTAINABILITY_CLAIM,
  UV_PROTECTION,
  FRAME_SHAPE_FACET,
  FRONT_COLOR_FACET,
  LENS_COLOR_FACET,
  LENS_TREATMENT_FACET,
]

export type IDynamicContentRule = {
  key: IDynamicContentKey
  value: string
}

export type IDynamicRule = {
  rules: IDynamicContentRule[]
  target: ILXTeaser | ICMCollection
}
