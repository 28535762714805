import { styled } from '@mui/material/styles'
import CmsBroadContentWrapper from '@components/Cms/CmsComponents/CmsBroadContentWrapper'

export const WrapperNewsLetterBanner = styled(CmsBroadContentWrapper, {
  name: 'NewsletterBanner',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: theme.spacing(0, 4, 16),

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 44, 16),
  },
}))

export const ContentNewsLetterBanner = styled('article', {
  name: 'NewsletterBanner',
  slot: 'Content',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),

  [theme.breakpoints.up('md')]: {
    //width: '50%',
    maxWidth: 540,
  },
}))

export const ContentTextNewsLetterBanner = styled('div', {
  name: 'NewsletterBanner',
  slot: 'ContentText',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

export const ContentTextFieldsNewsLetterBanner = styled('div', {
  name: 'NewsletterBanner',
  slot: 'ContentTextFields',
})(({ theme }) => ({
  padding: theme.spacing(4, 0),
  width: '100%',
}))
