import React from 'react'
import { ProductAlgoliaPrice } from '../../types/ProductAlgolia/ProductAlgolia'
import ProductPricePdp from './components/ProductPricePdp'
import ProductPricePdpStickyBar from './components/ProductPricePdpStickyBar'
import ProductPricePlp from './components/ProductPricePlp'
import ProductPriceCart from '@components/ProductPriceFinal/components/ProductPriceCart'
import ProductPriceSuggested from '@components/ProductPriceFinal/components/ProductPriceSuggested'
import { PriceTotalProps } from 'src/types/pdp'
type ProductPriceFinal = PriceTotalProps & {
  pageType: 'pdp' | 'search' | 'cart' | 'plp' | 'stickyBar' | 'suggested'
  productType: string
  price: ProductAlgoliaPrice
  totalBoxes?: number
  productQuantity?: number
  isPerBox?: boolean
  promoCodeDiscount?: number
}
const ProductPriceFinal: React.FC<ProductPriceFinal> = ({
  pageType,
  productType,
  price,
  totalBoxes = 0, // for cl
  productQuantity = 0, // for cla
  isPerBox = false, // for cart
  promoCodeDiscount = 0, // for cart
  priceTooltipActive = false,
  showTotalTooltipBtn = false,
  showTotalTooltip = false,
  hideTotal = false,
  onTotalTooltipBtnClick,
  onTotalTooltipClose,
}) => {
  switch (pageType) {
    case 'pdp':
      return (
        <ProductPricePdp
          price={price}
          totalBoxes={totalBoxes}
          productQuantity={productQuantity}
          productType={productType}
          priceTooltipActive={priceTooltipActive}
          hideTotal={hideTotal}
          showTotalTooltip={showTotalTooltip}
          showTotalTooltipBtn={showTotalTooltipBtn}
          onTotalTooltipBtnClick={onTotalTooltipBtnClick}
          onTotalTooltipClose={onTotalTooltipClose}
        />
      )
    case 'stickyBar':
      return (
        <ProductPricePdpStickyBar
          price={price}
          productType={productType}
          totalBoxes={totalBoxes}
          productQuantity={productQuantity}
          hideTotal={hideTotal}
        />
      )
    case 'search':
      return <div>ProductPriceSearch</div>
    case 'cart':
      return (
        <ProductPriceCart
          price={price}
          productType={productType}
          totalBoxes={totalBoxes}
          productQuantity={productQuantity}
          isPerBox={isPerBox}
          promoCodeDiscount={promoCodeDiscount}
        />
      )
    case 'plp':
      return <ProductPricePlp price={price} productType={productType} />
    case 'suggested':
      return <ProductPriceSuggested price={price} productType={productType} />
    default:
      return <ProductPricePlp price={price} productType={productType} />
  }
}

export default ProductPriceFinal
