import { styled } from '@mui/material/styles'

export const SearchWrapper = styled('div', {
  name: 'FaqsSearch',
  slot: 'SearchWrapper',
})(({ theme }) => ({
  backgroundColor: theme.palette.color.grey.lightest,
  margin: theme.spacing(6, 4, 10),
  padding: theme.spacing(2, 4),
  borderRadius: 100,
  display: 'flex',
  fontSize: theme.spacing(3.5),

  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(16, 24, 10),
  },

  [theme.breakpoints.up('lg')]: {
    margin: theme.spacing(16, 44, 10),
  },
}))

export const SearchIconWrapper = styled('div', {
  name: 'FaqsSearch',
  slot: 'SearchIconWrapper',
})(({ theme }) => ({
  display: 'flex',
  color: theme.palette.color.grey.main,

  '& > span': {
    marginLeft: theme.spacing(2.5),
  },

  '& > svg': {
    marginLeft: theme.spacing(7),
    width: 14,
    height: 'auto',
  },
}))

export const CustomInput = styled('input', {
  name: 'FaqsSearch',
  slot: 'CustomInput',
})(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.color.grey.lightest,
  border: 'none',
  outline: 'none',
  marginLeft: theme.spacing(2),
}))
