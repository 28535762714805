import { Button } from '../../UI'

import { EMPTY_STRING } from '../../../constants/common'
import React from 'react'
import { WarningIcon } from '../../UI/Icons'
import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  ButtonSubmitContent,
  CustomTextField,
  EmailFormContent,
  TextFieldWrapper,
  WarningIconWrapper,
} from '@components/ForgotPassword/components/ForgotPasswordEmail.style'
interface ForgotPasswordEmailProps {
  handleSubmit: (e: any) => void
  email: string
  isEmailError: boolean
  isLoading?: boolean
  isError: boolean
  handleEmail: (e: any) => void
}
const ForgotPasswordEmail: React.FC<ForgotPasswordEmailProps> = ({
  handleSubmit,
  email,
  isEmailError,
  isError,
  isLoading,
  handleEmail,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <EmailFormContent>
      {t('ForgotPassword.HelperTextEmailVerificationForm')}
      <TextFieldWrapper>
        <CustomTextField
          margin="normal"
          name="email"
          label={t('ForgotPassword.EmailLabel')}
          type={'email'}
          value={email}
          onChange={handleEmail}
          error={isEmailError || isError}
          inputProps={{ maxLength: 100 }}
          isLongHelper={true}
          helperText={
            isEmailError && email !== EMPTY_STRING
              ? t('ForgotPassword.Msgs.InvalidFormat')
              : isEmailError && email === EMPTY_STRING
              ? t('ForgotPassword.Msgs.EmptyMail')
              : isError && email !== EMPTY_STRING
              ? t('ForgotPassword.Msgs.EmailNotFound')
              : EMPTY_STRING
          }
          customInputProps={{
            endAdornment: isEmailError && (
              <WarningIconWrapper>
                <WarningIcon htmlColor={theme.palette.error.main} />
              </WarningIconWrapper>
            ),
          }}
        />
      </TextFieldWrapper>
      <ButtonSubmitContent>
        <Button
          fullWidth={true}
          variant="primary"
          // disabled={!canContinue()}
          onClick={handleSubmit}
          loading={isLoading}
        >
          {t('ForgotPassword.SubmitButton')}
        </Button>
      </ButtonSubmitContent>
    </EmailFormContent>
  )
}

export default ForgotPasswordEmail
