//Standard libraries
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

//UI
import {
  TitleNotFound,
  ContentBodyNotFound,
  SubTitleNotFound,
  WrapperNotFound,
  ContentButtonsNotFound,
  UnderParagraphNotFound,
  ParagraphNotFound,
} from './NotFound.style'
import { LinkAsButton } from '@components/UI'
import { sendNotFoundErrorEvent } from '../../foundation/analytics/tealium/lib'
import { useSelector } from 'react-redux'
import { loginStatusSelector } from '../../redux/selectors/user'
import { useLocation } from 'react-router-dom'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useGetHclPageQuery } from '../../features/cms/query'
import { useTranslation } from 'react-i18next'
import useBreakpoints from '../../hooks/useBreakpoints/index'
import { bookAnEyeUrlSelector } from '../../redux/selectors/site'
import PlacementLayout from '../../components/Cms/PlacementLayout'
import { usePageType } from '../../foundation/hooks/usePageType'
import { orderItemsSelector } from '../../features/order/selector'
import MonetateUtils from '../../foundation/monetate/MonetateUtils'
import {
  PLP_LINK_CONTACT_LENSES,
  PLP_LINK_SUNGLASSES,
} from '../../constants/ui'

const NotFound: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { isMobile } = useBreakpoints()
  const { basePath } = useStoreIdentity()
  const loginStatus = useSelector(loginStatusSelector)
  const bookAnEyeUrl = useSelector(bookAnEyeUrlSelector) || ''
  const orderItems = useSelector(orderItemsSelector)
  const { pageType } = usePageType()

  const { data, isLoading } = useGetHclPageQuery('ops--page')
  const placements = data?.commercePlacements || []

  useEffect(() => {
    sendNotFoundErrorEvent({
      loginStatus,
      requestedUrl: location.pathname,
    })
  }, [])

  useEffect(() => {
    const cartRows = MonetateUtils.mapCartRows(orderItems)

    MonetateUtils.reTrack({
      setPage: pageType,
      addCartRows: cartRows,
    })
  }, [])
  return (
    <>
      <WrapperNotFound>
        <Helmet>
          <meta name="prerender-status-code" content="404" />
        </Helmet>
        <ContentBodyNotFound>
          <TitleNotFound
            fontSize={100}
            fontWeight={'bold'}
            lineHeight={'1'}
            component={'h2'}
          >
            {t('NotFound.Msgs.TitleContent')}
          </TitleNotFound>
          <SubTitleNotFound
            fontSize={24}
            component={'p'}
            lineHeight={'1.33'}
            isUppercased
          >
            {t('NotFound.Msgs.TextContent')}
          </SubTitleNotFound>
          <ParagraphNotFound fontSize={14} component={'p'} lineHeight={'1.43'}>
            {t('NotFound.Msgs.TextParagraph')}
          </ParagraphNotFound>
          <ContentButtonsNotFound>
            <LinkAsButton external={true} to={`${bookAnEyeUrl}`}>
              {t('NotFound.Msgs.BookAnEyeTest')}
            </LinkAsButton>
            <LinkAsButton to={`${basePath}/${PLP_LINK_SUNGLASSES}`}>
              {t('NotFound.Msgs.ShopSunglasses')}
            </LinkAsButton>
            <LinkAsButton to={`${basePath}/${PLP_LINK_CONTACT_LENSES}`}>
              {t('NotFound.Msgs.ShopContactLenses')}
            </LinkAsButton>
          </ContentButtonsNotFound>
          <UnderParagraphNotFound
            dangerouslySetInnerHTML={{
              __html: isMobile
                ? t('NotFound.Msgs.NeedHelpMobile')
                : t('NotFound.Msgs.NeedHelp'),
            }}
          />
        </ContentBodyNotFound>
      </WrapperNotFound>
      <PlacementLayout placements={placements} loading={isLoading} />
    </>
  )
}

export default NotFound
