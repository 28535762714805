import React from 'react'
import { useTranslation } from 'react-i18next'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import ProductPriceAlgoliaUtils from '@utils/ProductAlgolia/ProductPriceAlgoliaUtils'
import { ProductPriceWrapper } from '@components/ProductPriceFinal/ProductPriceFinal.style'
import FormattedPriceDisplay from '@components/formatted-price-display'
import { ProductAlgoliaPrice } from '../../../types/ProductAlgolia/ProductAlgolia'
import {
  ProductPriceContainerStickyBar,
  ProductPriceCurrentPriceStickyBar,
  ProductPriceDetailsContainerStickyBar,
  ProductPriceInitialPriceStickyBar,
  ProductPriceLabelStickyBar,
} from '@components/ProductPriceFinal/components/ProductPricePdpStickyBar.style'

interface ProductPricePdpStickyBar {
  price: ProductAlgoliaPrice
  productType: string
  totalBoxes: number
  productQuantity: number
  hideTotal?: boolean
  showTotalTooltip?: boolean
}
const ProductPricePdpStickyBar: React.FC<ProductPricePdpStickyBar> = ({
  price,
  productType,
  totalBoxes,
  productQuantity,
  hideTotal,
}) => {
  const { t } = useTranslation()
  const { getLxProductTypes } = ProductAlgoliaUtils
  const { isContactLensesAccessories, isContactLenses, isFrames } =
    getLxProductTypes(productType)

  const { listPrice, offerPrice, currency, hasInitialPriceDiscount } =
    ProductPriceAlgoliaUtils.getProductPriceData(price)

  const boxesNr = isContactLenses ? totalBoxes : productQuantity

  const currentPriceControl = isFrames
    ? offerPrice
    : ProductPriceAlgoliaUtils.getClAndClaTotalPrice(offerPrice, boxesNr)

  const initialPriceControl = isFrames
    ? listPrice
    : ProductPriceAlgoliaUtils.getClAndClaTotalPrice(listPrice, boxesNr)

  const pricePerBoxCurrent = Boolean(offerPrice) && (
    <ProductPriceCurrentPriceStickyBar>
      <FormattedPriceDisplay min={offerPrice} currency={currency} />
    </ProductPriceCurrentPriceStickyBar>
  )
  const pricePerBoxDiscounted = Boolean(listPrice) &&
    hasInitialPriceDiscount && (
      <ProductPriceInitialPriceStickyBar>
        <FormattedPriceDisplay min={listPrice} currency={currency} />
      </ProductPriceInitialPriceStickyBar>
    )
  const totalPriceCurrent = Boolean(offerPrice) && (
    <ProductPriceCurrentPriceStickyBar>
      <FormattedPriceDisplay min={currentPriceControl} currency={currency} />
    </ProductPriceCurrentPriceStickyBar>
  )

  const totalPriceDiscounted = Boolean(listPrice) &&
    hasInitialPriceDiscount && (
      <ProductPriceInitialPriceStickyBar>
        <FormattedPriceDisplay min={initialPriceControl} currency={currency} />
      </ProductPriceInitialPriceStickyBar>
    )

  return (
    <ProductPriceWrapper>
      <ProductPriceDetailsContainerStickyBar>
        <ProductPriceLabelStickyBar>
          {isContactLensesAccessories || isContactLenses
            ? hideTotal
              ? t('ContactLenses.Labels.PerBox')
              : t('ProductTile.Labels.Total')
            : t('ProductDetails.SpecialFrameInfo.Label.Frame')}
        </ProductPriceLabelStickyBar>

        <ProductPriceContainerStickyBar>
          {isContactLenses ? (
            !hideTotal ? (
              <>
                {totalPriceDiscounted} {totalPriceCurrent}
              </>
            ) : (
              <>
                {pricePerBoxDiscounted} {pricePerBoxCurrent}
              </>
            )
          ) : (
            <>
              {totalPriceDiscounted} {totalPriceCurrent}
            </>
          )}
        </ProductPriceContainerStickyBar>
      </ProductPriceDetailsContainerStickyBar>
    </ProductPriceWrapper>
  )
}

export default ProductPricePdpStickyBar
