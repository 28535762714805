import { Divider, Modal, ModalProps, useTheme } from '@mui/material'

import { CloseIcon } from '../Icons'
import { IconButton } from '../IconButton'
import React, { PropsWithChildren } from 'react'
import { styled } from '@mui/material/styles'
const calWidthSize = (value: ModalDialogProps['widthSize']) => {
  switch (value) {
    case 'xs':
      return '30%'
    case 'sm':
      return '45%'
    case 'md':
      return '65%'
    case 'md-lg':
      return '864px'
    case 'lg':
      return '85%'
    default:
      return '30%'
  }
}
export interface ModalDialogProps extends ModalProps {
  onCloseButton?: () => void
  widthSize?: 'xs' | 'sm' | 'md' | 'lg' | 'md-lg'
  roundBorders?: boolean
  maxWidth?: number
  fullScreen?: boolean
}
const WrapperModalDialog = styled('div', {
  name: 'ModalDialog',
  slot: 'Wrapper',
  shouldForwardProp: (prop) =>
    prop !== 'widthSize' &&
    prop !== 'roundBorders' &&
    prop !== 'maxWidth' &&
    prop !== 'fullScreen',
})<{
  roundBorders: ModalDialogProps['roundBorders']
  widthSize: ModalDialogProps['widthSize']
  maxWidth: ModalDialogProps['maxWidth']
  fullScreen: ModalDialogProps['fullScreen']
}>(({ theme, fullScreen, widthSize, roundBorders, maxWidth }) => {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: fullScreen ? '100%' : 'calc(100% - 32px)',
    maxHeight: fullScreen ? '100%' : '80%',
    height: fullScreen ? '100%' : undefined,
    overflowY: 'auto',
    backgroundColor: theme.palette.color.white.main,
    borderRadius: roundBorders ? '4px' : 0,
    [theme.breakpoints.up('md')]: {
      width: widthSize ? calWidthSize(widthSize) : 'auto',
      maxWidth: maxWidth ? maxWidth : '',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  }
})

const ContentModalDialog = styled('div', {
  name: 'ModalDialog',
  slot: 'Content',
})(({ theme }) => ({
  padding: theme.spacing(8, 4),
  height: '100%',

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4, 4, 6, 4),
    width: '100%',
  },
}))

const TitleDivider = styled(Divider, {
  name: 'ModalDialog',
  slot: 'TitleDivider',
})(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  color: theme.palette.text.dark,
  textTransform: 'uppercase',
  justifyContent: 'center',
  paddingBottom: theme.spacing(6),

  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(13),
  },

  '& .MuiDivider-wrapper': {
    padding: theme.spacing(0, 4),
  },

  '&::before, &::after': {
    borderColor: 'inherit',
    borderWidth: 1,

    [theme.breakpoints.up('sm')]: {
      width: '10%',
    },
  },
}))

export const Title = styled('div', {
  name: 'ModalDialog',
  slot: 'Title',
})(({ theme }) => ({
  display: 'flex',
  fontSize: 16,
  fontWeight: 600,
  color: theme.palette.text.dark,
  textTransform: 'uppercase',
  justifyContent: 'center',

  paddingBottom: theme.spacing(6),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(13),
  },
}))

const StyledIconButton = styled(IconButton, {
  name: 'ModalDialog',
  slot: 'CloseButton',
})(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(4),
  top: theme.spacing(4),
}))

export const ModalDialogTitle: React.FC<
  PropsWithChildren<{ divider?: boolean }>
> = ({ children, divider = true }) => {
  return divider ? (
    <TitleDivider>{children}</TitleDivider>
  ) : (
    <Title>{children}</Title>
  )
}

export const ModalDialog: React.FC<ModalDialogProps> = ({
  onCloseButton,
  children,
  widthSize,
  roundBorders,
  maxWidth,
  fullScreen,
  ...rest
}) => {
  const theme = useTheme()
  return (
    <Modal {...rest}>
      <WrapperModalDialog
        widthSize={widthSize}
        roundBorders={roundBorders}
        maxWidth={maxWidth}
        fullScreen={fullScreen}
        className="ModalDialog-Wrapper"
      >
        <ContentModalDialog className="ModalDialog-Content">
          {onCloseButton && (
            <StyledIconButton
              className="ModalDialog--close"
              aria-label="close"
              onClick={onCloseButton}
            >
              <CloseIcon htmlColor={theme.palette.text.dark} />
            </StyledIconButton>
          )}
          {children}
        </ContentModalDialog>
      </WrapperModalDialog>
    </Modal>
  )
}
