import { ADDRESS_BILLING, CHECKOUT } from '../../constants/common'
//UI
import {
  AddressFormWrapper,
  AddressLine2Button,
  CountryText,
  CountryTitle,
  StyledAddressForm,
  StyledGridItem,
  StyledGridItemPostcode,
  StyledGridItemAddressLine1,
  StyledGridItemAdditionalText,
  StyledTextField,
  WrapperCountry,
  TaxCodeMessage,
  TaxCodeOptionalText,
  StyledGridItemFiscalCode,
  FiscalCodeErrorMsg,
  StyledGridItemPhoneNumber,
  StyledGridItemGeneric,
} from './AddressForm.style'
import { Collapse, MenuItem } from '@mui/material'
import { CloseFilledIcon, RoundWarningIcon } from '../UI/Icons'
//Custom libs
import { Controller, UseFormReturn, useFormState } from 'react-hook-form'
//Standard libraries
import React, { FC, useEffect, useMemo, useState } from 'react'

import { AddressFormData } from '../../types/form'
import { AddressSuggester } from './AddressSuggester'
import { CheckoutAddressFormField } from '../../types/checkout'
import { ZipCodeSuggester } from './ZipCodeSuggester'
import { countriesListSelector } from '../../redux/selectors/account'
import { useGoogleSuggestions } from '@hooks/useGoogleSuggestions'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import { Autocomplete } from '../UI'
import { mostUsedEmailDomainsSelector } from '../../redux/selectors/site'
import AddressFormUtils from '@components/AddressForm/AddressFormUtils'
import { setCfAsInvalid } from '../../features/ui/action'

interface AddressFormProps {
  addressFormFields: CheckoutAddressFormField[]
  addressType?: AddressFormData['addressType']
  cid: string
  edit?: boolean
  form: UseFormReturn
  formName?: string
  page?: string // page name
  resetForm?: boolean
  onFormValidaTionStatusChanged?: (
    type: AddressFormData['addressType'],
    isValid: boolean
  ) => void
  onFormDataChanged?: (
    type: AddressFormData['addressType'],
    data: AddressFormData
  ) => void
}

/**
 * Address Form component
 * to be resued to display form inputs on a shipping or billing address form
 * @param props
 */

const AddressForm: FC<AddressFormProps> = ({
  addressFormFields,
  addressType,
  cid,
  form,
  formName,
  page = CHECKOUT, // Default page is checkout
  onFormValidaTionStatusChanged,
  onFormDataChanged,
}) => {
  const storeCountry = useStoreIdentity().country.toUpperCase()
  const { t } = useTranslation()
  const mostUsedEmailDomains = useSelector(
    mostUsedEmailDomainsSelector,
    shallowEqual
  )
  // const theme = useTheme()
  const countriesList = useSelector(countriesListSelector)
  const [checkedAddressLine2, setCheckedAddressLine2] = useState<boolean>(false)
  const [cfValidationError, setCfValidationError] = useState(false)

  const dispatch = useDispatch()

  const nameRegex =
    /^[a-zA-Z\sñàäãæçèéëêìïòõöœùüûÀÄÆÇŒÈÉÌÏÒÖÙÜÛčšžåøðþşğāēģīķļņūłŵŷ]+$/

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = form
  const addressFormData = getValues()
  const { isValid } = useFormState({ control })

  const email = watch('email1')
  const googleSuggestions = useGoogleSuggestions({
    addressType: addressType || '',
    country: storeCountry || '',
    page,
    state: addressFormData?.state || '',
  })

  const emailAutocompleteOptions = useMemo(
    () =>
      AddressFormUtils.getEmailAutocompleteOptions(email, mostUsedEmailDomains),
    [email, mostUsedEmailDomains]
  )

  const { googleAPISuggestionEnabled, gmapCountry, gmapLoaded, provinces } =
    googleSuggestions
  //const toggleProp65 = () => setIsProp65Open((v) => !v)

  useEffect(() => {
    const subscription = watch((value) => {
      setTimeout(() => {
        onFormDataChanged && onFormDataChanged(addressType, { ...value })
      }, 300)
    })

    return () => subscription.unsubscribe()
  }, [watch])

  useMemo(() => {
    setTimeout(() => {
      onFormValidaTionStatusChanged &&
        onFormValidaTionStatusChanged(addressType, isValid)
    }, 300)
  }, [isValid])

  /*  const IS_COUNTRY_SELECT_DISABLED = [
      ADDRESS_SHIPPING,
      ADDRESS_SHIPPING_BILLING,
      ADDRESS_BILLING,
    ].includes(addressType || '')*/

  const getEmailPrediction = (option: string) => {
    if (option.includes('@')) {
      const domain = option.split('@')[1]
      const email = option.split('@')[0]
      return (
        <>
          {email}
          <strong>@{domain}</strong>
        </>
      )
    }
    return option
  }

  return (
    <StyledAddressForm
      id={`address_form_5_${cid}`}
      name={formName || `address_form_5_${cid}`}
      noValidate
    >
      <AddressFormWrapper>
        {addressFormFields?.map(({ fieldName, ...fieldData }, i) => {
          const key = `${cid}-${fieldName}-${i}`
          let fieldType: string

          const isZipCodeField = fieldName === 'zipCode'
          const isAddressLine1 = fieldName === 'addressLine1'
          const isPhoneNumber = fieldName === 'phone1'
          const isAddressLine2 = fieldName === 'addressLine2'
          const isCountry = fieldName === 'country'
          const isState = fieldName === 'state'
          const isFiscalCode = fieldName === 'fiscalCode'

          switch (fieldName) {
            case 'email1':
              fieldType = 'email'
              break
            case 'phone1':
              fieldType = 'tel'
              break
            case 'fiscalCode':
              fieldType = 'fiscalCode'
              break
            default:
              fieldType = 'text'
              break
          }

          if (isAddressLine1 && googleAPISuggestionEnabled && !!window.google) {
            const actualFieldName =
              addressType === ADDRESS_BILLING
                ? 'billingAddressLine1'
                : fieldName
            return (
              <StyledGridItemAddressLine1
                xs={12}
                md={12}
                lg={12}
                key={key}
                maxWidth={569}
              >
                <Controller
                  key={key}
                  name={fieldName}
                  control={control}
                  render={({
                    field: { value, ref, ...rest },
                    fieldState: { error, isTouched },
                  }) => (
                    <AddressSuggester
                      addressFormFields={addressFormFields}
                      country={gmapCountry}
                      inputRef={ref}
                      mandatoryField={fieldData.mandatory === 'true'}
                      gMapLoaded={gmapLoaded}
                      value={value}
                      formControl={control}
                      cid={`${fieldName}-${addressType}`}
                      fieldName={fieldName}
                      fieldLabel={t(`AddressForm.Labels.${actualFieldName}`)}
                      error={!!error}
                      formErrors={errors}
                      onSuggestedFieldValueChanged={(fieldName, value) => {
                        setValue(fieldName, value, {
                          shouldValidate: true,
                        })
                      }}
                      ispristine={!isTouched}
                      {...rest}
                    />
                  )}
                />
              </StyledGridItemAddressLine1>
            )
          } else if (
            isZipCodeField &&
            googleAPISuggestionEnabled &&
            !!window.google
          ) {
            return (
              <StyledGridItemPostcode
                xs={6}
                md={4}
                lg={4}
                key={key}
                maxWidth={201}
              >
                <Controller
                  key={key}
                  name={fieldName}
                  control={control}
                  render={({
                    field: { value, ref, ...rest },
                    fieldState: { error, isTouched },
                  }) => (
                    <ZipCodeSuggester
                      addressFormFields={addressFormFields}
                      country={gmapCountry}
                      inputRef={ref}
                      mandatoryField={fieldData.mandatory === 'true'}
                      gMapLoaded={gmapLoaded}
                      value={value}
                      formControl={control}
                      cid={`${fieldName}-${addressType}`}
                      fieldName={fieldName}
                      fieldLabel={t(`AddressForm.Labels.${fieldName}`)}
                      error={!!error}
                      formErrors={errors}
                      ispristine={!isTouched}
                      onSuggestedFieldValueChanged={(fieldName, value) => {
                        setValue(fieldName, value, {
                          shouldValidate: true,
                        })
                      }}
                      {...rest}
                    />
                  )}
                />
              </StyledGridItemPostcode>
            )
          } else if (fieldName === 'email1') {
            return (
              <StyledGridItem xs={12} md={4} lg={4} maxWidth={569} key={key}>
                <Controller
                  key={key}
                  name={fieldName}
                  control={control}
                  render={({
                    field: { value, ref, onChange, onBlur },
                    fieldState: { error },
                  }) => {
                    return (
                      <Autocomplete
                        options={emailAutocompleteOptions}
                        filterOptions={AddressFormUtils.filterEmailOptions}
                        autoHighlight
                        onChange={(_event, values, reason) =>
                          AddressFormUtils.onAutoCompleteChange(
                            values,
                            reason,
                            onChange,
                            email
                          )
                        }
                        value={value}
                        freeSolo
                        renderOption={(props, option) => (
                          <MenuItem
                            component="li"
                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {getEmailPrediction(option)}
                          </MenuItem>
                        )}
                        clearIcon={<CloseFilledIcon />}
                        renderInput={(params) => (
                          <StyledTextField
                            onBlur={onBlur}
                            onChange={onChange}
                            inputRef={ref}
                            helperText={
                              error && !value?.length
                                ? t('AddressForm.Msgs.Empty.email1')
                                : error?.message
                            }
                            inputProps={params.inputProps}
                            label="Email"
                            error={!!error}
                            showvalidationstatus
                            customInputProps={{
                              ...params.InputProps,
                            }}
                            isvalid={!error && !isEmpty(value)}
                          />
                        )}
                      />
                    )
                  }}
                />
              </StyledGridItem>
            )
          } else if (isState && provinces && provinces.length > 0) {
            return (
              <StyledGridItem xs={12} md={12} lg={12} key={key} maxWidth={569}>
                <Controller
                  name={fieldName}
                  control={control}
                  render={({
                    field: { value, ref, ...rest },
                    fieldState: { error },
                  }) => {
                    return (
                      <StyledTextField
                        select
                        {...rest}
                        key={`${cid}-${fieldName}`}
                        inputRef={ref}
                        value={value}
                        required={fieldData.mandatory === 'true'}
                        id={`${cid}-${fieldName}`}
                        type={fieldType}
                        label={t(`AddressForm.Labels.${fieldName}`)}
                        error={!!error}
                        fullWidth
                        isvalid={!error && !isEmpty(value)}
                        onChange={(event) => {
                          setValue(fieldName, event.target.value, {
                            shouldValidate: true,
                          })
                        }}
                        ispristine={
                          value ? (value as string).length <= 0 : true
                        }
                      >
                        {(!provinces || provinces.length <= 0) && (
                          <option>&nbsp;</option>
                        )}
                        {provinces?.map((provinceState) => (
                          <MenuItem
                            key={provinceState.code}
                            value={provinceState.code || ''}
                          >
                            {provinceState.displayName}
                          </MenuItem>
                        ))}
                      </StyledTextField>
                    )
                  }}
                />
              </StyledGridItem>
            )
          } else if (isCountry) {
            const currentCountryInfo =
              countriesList &&
              countriesList.find((val) => val.code === getValues('country'))
            const currentCountry = currentCountryInfo?.displayName || ''
            return (
              <StyledGridItem xs={12} md={12} lg={12} key={key}>
                <WrapperCountry>
                  <CountryTitle>
                    {t(`AddressForm.Labels.${fieldName}`)}
                  </CountryTitle>
                  <CountryText>{currentCountry}</CountryText>
                </WrapperCountry>
              </StyledGridItem>
            )
          } else if (
            isAddressLine2 &&
            googleAPISuggestionEnabled &&
            !!window.google
          ) {
            const actualFieldName =
              addressType === ADDRESS_BILLING
                ? 'billingAddressLine2'
                : fieldName

            return (
              <StyledGridItemAddressLine1
                xs={12}
                md={12}
                lg={12}
                key={key}
                maxWidth={569}
              >
                <AddressLine2Button
                  onClick={() => setCheckedAddressLine2((prev) => !prev)}
                  isOpen={checkedAddressLine2}
                >
                  {t('AddressForm.Labels.addressLine2ToggleButtonLabel')}
                </AddressLine2Button>
                <Collapse in={checkedAddressLine2}>
                  <Controller
                    name={fieldName}
                    control={control}
                    render={({
                      field: { value, ref, ...rest },
                      fieldState: { error, isTouched },
                    }) => (
                      <StyledTextField
                        InputLabelProps={{ required: false }}
                        {...rest}
                        id={`${fieldName}-${addressType}`}
                        inputRef={ref}
                        value={value}
                        required={fieldData.mandatory === 'true'}
                        label={t(`AddressForm.Labels.${actualFieldName}`)}
                        error={!!error}
                        helperText={<>{errors[fieldName]?.message}</>}
                        showvalidationstatus={true}
                        ispristine={!isTouched}
                        isvalid={
                          !error && !isEmpty(value) && !cfValidationError
                        }
                      />
                    )}
                  />
                </Collapse>
              </StyledGridItemAddressLine1>
            )
          } else if (isFiscalCode) {
            return (
              <StyledGridItemFiscalCode
                xs={12}
                md={12}
                key={key}
                maxWidth={569}
              >
                <Controller
                  name={fieldName}
                  control={control}
                  render={({
                    field: { value, ref, onChange, ...rest },
                    fieldState: { error, isTouched },
                  }) => (
                    <StyledTextField
                      InputLabelProps={{ required: false }}
                      {...rest}
                      id={`${cid}-${fieldName}`}
                      inputRef={ref}
                      value={value}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          dispatch(setCfAsInvalid(false))
                          setCfValidationError(false)
                          return
                        }
                        const fiscalCode =
                          !AddressFormUtils.fiscalCodeValidation(e.target.value)
                        setCfValidationError(fiscalCode)
                        dispatch(setCfAsInvalid(fiscalCode))
                      }}
                      onChange={(e) => onChange(e.target.value.toUpperCase())}
                      required={fieldData.mandatory === 'false'}
                      type={fieldType}
                      label={t(`AddressForm.Labels.${fieldName}`)}
                      error={!!error || cfValidationError}
                      helperText={
                        <>
                          {cfValidationError ? (
                            <FiscalCodeErrorMsg>
                              {t('AddressForm.Msgs.InvalidFiscalCode')}
                            </FiscalCodeErrorMsg>
                          ) : (
                            errors[fieldName]?.message
                          )}
                        </>
                      }
                      fullWidth
                      showvalidationstatus={value.length}
                      ispristine={!isTouched}
                      isvalid={!error && !isEmpty(value) && !cfValidationError}
                    />
                  )}
                />
                <TaxCodeOptionalText>
                  {t('AddressForm.Labels.geographicalTaxCodeOptional')}
                </TaxCodeOptionalText>
                <TaxCodeMessage>
                  <div>
                    <RoundWarningIcon width={24} height={24} />
                  </div>
                  {t('AddressForm.Msgs.TaxCodeMessage')}
                </TaxCodeMessage>
              </StyledGridItemFiscalCode>
            )
          } else if (isPhoneNumber) {
            return (
              <StyledGridItemPhoneNumber xs={12} md={12} key={key}>
                <Controller
                  name={fieldName}
                  control={control}
                  render={({
                    field: { value, ref, ...rest },
                    fieldState: { error, isTouched },
                  }) => (
                    <StyledTextField
                      InputLabelProps={{ required: false }}
                      {...rest}
                      id={`${cid}-${fieldName}`}
                      inputRef={ref}
                      value={value}
                      required={fieldData.mandatory === 'true'}
                      type={fieldType}
                      inputProps={
                        fieldType === 'tel' ? { maxLength: 16 } : undefined
                      }
                      label={t(`AddressForm.Labels.${fieldName}`)}
                      error={!!error}
                      helperText={
                        <>
                          <StyledGridItemAdditionalText>
                            {t('AddressForm.Msgs.AdditionalMsgs.phone1')}
                          </StyledGridItemAdditionalText>
                          {errors[fieldName]?.message}
                        </>
                      }
                      fullWidth
                      showvalidationstatus={true}
                      ispristine={!isTouched}
                      isvalid={!error && !isEmpty(value)}
                    />
                  )}
                />
              </StyledGridItemPhoneNumber>
            )
          } else {
            return (
              <StyledGridItemGeneric xs={12} md={12} key={key} maxWidth={569}>
                <Controller
                  name={fieldName}
                  control={control}
                  render={({
                    field: { value, ref, ...rest },
                    fieldState: { error, isTouched },
                  }) => (
                    <StyledTextField
                      InputLabelProps={{ required: false }}
                      {...rest}
                      id={`${cid}-${fieldName}`}
                      inputRef={ref}
                      value={value}
                      required={fieldData.mandatory === 'true'}
                      type={fieldType}
                      inputProps={
                        fieldType === 'tel' ? { maxLength: 16 } : undefined
                      }
                      label={t(`AddressForm.Labels.${fieldName}`)}
                      error={!!error && !nameRegex.test(value)}
                      helperText={
                        <>
                          {fieldType === 'tel' ? (
                            <>
                              <StyledGridItemAdditionalText>
                                {t('AddressForm.Msgs.AdditionalMsgs.phone1')}
                              </StyledGridItemAdditionalText>
                              {errors[fieldName]?.message}
                            </>
                          ) : fieldName === 'addressLine1' ? (
                            <>
                              <StyledGridItemAdditionalText>
                                {t(
                                  'AddressForm.Msgs.AdditionalMsgs.addressLine1'
                                )}
                              </StyledGridItemAdditionalText>
                              {errors[fieldName]?.message}
                            </>
                          ) : (
                            error &&
                            !nameRegex.test(value) &&
                            t('RegistrationLayout.Msgs.LettersOnly')
                          )}
                        </>
                      }
                      fullWidth
                      showvalidationstatus={true}
                      ispristine={!isTouched}
                      isvalid={
                        (!error && !isEmpty(value)) || nameRegex.test(value)
                      }
                    />
                  )}
                />
              </StyledGridItemGeneric>
            )
          }
        })}
      </AddressFormWrapper>
    </StyledAddressForm>
  )
}

export default AddressForm
