import { CartRecuringOrderSectionProps } from '../pages/Cart/components/CartRecuringOrderSection'
import { OrderTotalSummaryProps } from '@components/OrderTotalSummary/OrderTotalSummary'
import { PromotionCode } from './order'
import { ServerProduct } from './product'
import { CheckoutPayload } from './checkout'
import { ContactLensData } from './prescription'

export interface ICartItemsCountMap {
  [productId: string]: {
    count: number
    product: Omit<
      ServerProduct,
      | 'hasSingleSKU'
      | 'images'
      | 'storeID'
      | 'buyable'
      | 'price'
      | 'x_prices'
      | 'x_price'
    >
  }
}

export interface CartViewProps {
  cartItemsCountMap: ICartItemsCountMap
  hasOrderItems: boolean
  isApplePayLoading: boolean
  isCheckoutEnabled: boolean
  isRXProductsLimitExceeded: boolean
  lastRemovedItem: any
  orderTotalSummaryProps: OrderTotalSummaryProps['order']
  payloadBase: any
  promotionCodes: PromotionCode[]
  recuringOrderProps: CartRecuringOrderSectionProps
  isRecurringOrderFeatureEnabled?: boolean
  rxParsedCartItems: any[]
  onCheckoutClick(): void
  onRemoveItem: React.Dispatch<any>
  onRemoveItemUndo(): void
}

export interface CartPayload extends CheckoutPayload {
  currency?: string
  contractId?: string
  checkInventory?: boolean
  fetchCatentries?: boolean
  //fetchShippingInfo?: boolean
  filterPrescriptionItemType?: 'cl' | 'rx'
  filterPrescriptionNeededItems?: boolean
  refetch?: boolean
  fromOrderId?: any
  sortOrderItemBy?: string
  body?: CheckoutPayload['body'] & {
    x_calculateOrder?: string
    x_calculationUsage?: string
    x_inventoryValidation?: boolean
    fromOrderId_1?: string
    toOrderId?: string
    copyOrderItemId_1?: string
    orderItemId?: string
    orderItemId_1?: string
    orderItemId_2?: string
    orderItemId_3?: string
    orderItemId_4?: string
    orderItem?: {
      quantity?: string
      orderItemId?: string
    }[]
  }
}

export interface OrderItemEyeContactLensData {
  data: ContactLensData | null
  quantity: string | null
  orderItemId: string | null
}

export type OrderItemContactLensData = Record<
  string,
  OrderItemEyeContactLensData
>

export interface ShippingChargesPayload {
  orderId: string
}

export enum ShippingsMethodsEnum {
  Green = 'Green',
  Standard = 'Standard',
  ShipToStore = 'ShipToStore',
}

export type ShippingMethods =
  | ShippingsMethodsEnum.Green
  | ShippingsMethodsEnum.Standard
  | ShippingsMethodsEnum.ShipToStore
