import { styled } from '@mui/material/styles'

export const SocialLoginContainer = styled('div')(({ theme }) => ({
  alignSelf: 'stretch',
  flexGrow: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(6),
  },
  padding: 0,
}))

export const GoogleButton = styled('button', {
  name: 'SocialLogin',
  slot: 'GoogleButton',
})(({ theme }) => ({
  display: 'flex',
  fontSize: 14,
  padding: 0,
  width: 100,
  height: 30,
  ' & >svg': {
    height: 17,
    width: 17,
    marginRight: theme.spacing(3),
  },
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    width: 132,
    height: 40,
    fontSize: 20,
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    ' & >svg': {
      height: 24,
      width: 24,
      marginRight: theme.spacing(4),
    },
  },
  color: 'rgba(0, 0, 0, 0.54)',
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  fontWeight: 500,
  alignItems: 'center',
  border: 'none',
  borderRadius: '10px',
  backgroundColor: theme.palette.color.grey.lightest,
  boxShadow: theme.shadows[3],
  cursor: 'pointer',
}))

export const FacebookButton = styled('button', {
  name: 'SocialLogin',
  slot: 'FacebookButton',
})(({ theme }) => ({
  display: 'flex',
  padding: 0,
  color: theme.palette.text.light,
  fontFamily: 'Helvetica, Arial, sans-serif',
  fontWeight: 600,
  fontSize: 14,
  width: 120,
  height: 30,
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  ' & >svg': {
    height: 17,
    width: 17,
    marginRight: theme.spacing(3),
  },
  [theme.breakpoints.up('md')]: {
    width: 164,
    height: 40,
    fontSize: 20,
    ' & >svg': {
      height: 23,
      width: 23,
      marginRight: theme.spacing(4),
    },
  },
  alignItems: 'center',
  border: 'none',
  borderRadius: '10px',
  backgroundColor: '#1977F3',
  cursor: 'pointer',
}))
