import React from 'react'
import { SwiperSlide } from 'swiper/react'
import { Pagination, Navigation, Scrollbar } from 'swiper'

import { isCMExternalChannel } from '../../../../../types/cmsPlacement/Placement'
import {
  WrapperWallOfBrands,
  WallOfBrandsSwiper,
  ContentWallOfBrands,
} from './WallOfBrands.style'
import BrandIconLink from '../../CmsMegaMenuBanner/ListOfBrandIcons/components/BrandIconLink'
import useBreakpoints from '../../../../../hooks/useBreakpoints'
import { IPlacement } from '../../../../../types/cmsPlacement/Placement'
import { ICMExternalChannel } from '../../../../../types/cmsPlacement/CMExternalChannel'
import { ICMCollection } from '../../../../../types/cmsPlacement/CMCollection'

const pair = (arr, number = 2) => {
  return arr.reduce(
    (acc, cur, i) =>
      i % number
        ? Object.assign([...acc], {
            [acc.length - 1]: [...acc[acc.length - 1], cur],
          })
        : [...acc, [cur]],
    []
  )
}

const WallOfBrands: React.FC<{ placement: IPlacement | ICMCollection }> = ({
  placement,
}) => {
  const { isMobile } = useBreakpoints()

  const items =
    'items' in placement
      ? placement.items.filter(isCMExternalChannel) || []
      : placement.teasableItems.filter(isCMExternalChannel) || []

  return (
    <WrapperWallOfBrands>
      {isMobile ? (
        <WallOfBrandsSwiper
          slidesPerView={2}
          modules={[Navigation, Pagination, Scrollbar]}
          /*    pagination={{
            modifierClass: 'custom-',
            progressbarFillClass: 'custom-progressbar-fill',
            type: 'progressbar',
          }}*/
          scrollbar={{
            hide: false,
          }}
        >
          {pair(items).map((pairItem) => (
            <SwiperSlide key={pairItem.id}>
              {pairItem.map((item: ICMExternalChannel) => (
                <BrandIconLink height={'100px'} teasableItem={item} />
              ))}{' '}
            </SwiperSlide>
          ))}
        </WallOfBrandsSwiper>
      ) : (
        <ContentWallOfBrands>
          {items.map((item) => (
            <React.Fragment key={item.id}>
              <BrandIconLink teasableItem={item} width={'25%'} />
            </React.Fragment>
          ))}
        </ContentWallOfBrands>
      )}
    </WrapperWallOfBrands>
  )
}

export default WallOfBrands
