import React from 'react'
import useBreakpoints from '../../../../../hooks/useBreakpoints'

import { LandscapeMobileBanner } from './Mobile'
import { LandscapeDesktopBanner } from './Desktop'
import { IPlacement } from '../../../../../types/cmsPlacement/Placement'
import { ICMCollection } from '../../../../../types/cmsPlacement/CMCollection'

export interface ILandscapeBannerProps {
  placement: IPlacement | ICMCollection
  teaserIndex?: number
  bannerIndex?: number
}
const LandscapeBanner: React.FC<ILandscapeBannerProps> = ({
  placement,
  teaserIndex,
}) => {
  const { isMobile, isTabletPortrait } = useBreakpoints()

  return (
    <>
      {isMobile || isTabletPortrait ? (
        <LandscapeMobileBanner
          placement={placement}
          teaserIndex={teaserIndex}
          bannerIndex={1}
        />
      ) : (
        <LandscapeDesktopBanner
          placement={placement}
          teaserIndex={teaserIndex}
          bannerIndex={1}
        />
      )}
    </>
  )
}

export default LandscapeBanner
