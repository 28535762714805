import Cookies from 'js-cookie'
import {
  USER_SEGMENT_COOKIE_NAME,
  USER_SEGMENT_COOKIE_VALUES_SEPARATOR,
  USER_SEGMENT_DEFAULT_COOKIE_EXPIRY,
  USER_SEGMENT_GUEST,
} from '../constants/common'
import { addHours, secondsToHours } from 'date-fns'
import Log from '../services/Log'

export const setCustomerSegmentCookie = (
  customerSegments: string[],
  customerSegmentCookieExpiry?: number
) => {
  try {
    //let cookieSegments: string[] = [USER_SEGMENT_GUEST]
    const cookieExpiry: number =
      !!customerSegmentCookieExpiry && customerSegmentCookieExpiry > 0
        ? customerSegmentCookieExpiry
        : USER_SEGMENT_DEFAULT_COOKIE_EXPIRY

    const hoursForCookieToExpiry = secondsToHours(cookieExpiry)
    const expireDate = addHours(Date.now(), hoursForCookieToExpiry)

    Cookies.set(
      USER_SEGMENT_COOKIE_NAME,
      customerSegments.join(USER_SEGMENT_COOKIE_VALUES_SEPARATOR),
      { expires: expireDate }
    )
  } catch (e: any) {
    Log.error('error while setting segments cookie', e)
  }
}
export const getCustomerSegmentsfromCookie = (): string[] => {
  const customerSegmentsCookie: string | undefined = Cookies.get(
    USER_SEGMENT_COOKIE_NAME
  )
  return customerSegmentsCookie
    ? Array.from(
        new Set([
          ...customerSegmentsCookie.split(USER_SEGMENT_COOKIE_VALUES_SEPARATOR),
          USER_SEGMENT_GUEST,
        ])
      )
    : [USER_SEGMENT_GUEST]
}

export const sortSegments = (
  segmentPriorities: string[],
  newSegments: string[]
) => {
  let newSegmentOrder: string[] = []
  newSegmentOrder = segmentPriorities.filter((segment) =>
    newSegments.includes(segment)
  )
  newSegmentOrder.push(
    ...newSegments.filter((segment) => !segmentPriorities.includes(segment))
  )
  return newSegmentOrder
}
