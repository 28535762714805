import { styled } from '@mui/material/styles'

export const WrapperComboMiniSlider = styled('div', {
  name: 'ComboMiniSlider',
  slot: 'Wrapper',
  shouldForwardProp: (prop) => prop !== 'flexDirection',
})<{ flexDirection?: string }>(({ theme, flexDirection }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',

  padding: theme.spacing(10, 0),
  [theme.breakpoints.up('md')]: {
    flexDirection: flexDirection,
    padding: theme.spacing(8, 0),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(16, 0),
  },
}))

export const LeftComboMiniSlider = styled('div', {
  name: 'ComboMiniSlider',
  slot: 'Left',
})(({ theme }) => ({
  width: '100%',
  // maxWidth: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  //paddingTop: theme.spacing(10),

  [theme.breakpoints.up('md')]: {
    width: '50%',
    maxWidth: '50%',
    paddingTop: 0,
  },
}))

export const RightComboMiniSlider = styled('div', {
  name: 'ComboMiniSlider',
  slot: 'Right',
})(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('md')]: {
    width: '50%',
    maxWidth: '50%',
  },
}))

export const StyledRightContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'toggleMuted',
})(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '90%',
    margin: '0 auto',
  },
  video: {
    maxHeight: 'unset',
  },
}))
