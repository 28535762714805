import Log from 'src/services/Log'
import RequestService from '../../../services/RequestService'
import {
  AddItemToWishlistResponse,
  CreateWishlistResponse,
  IWishlist,
  IWishlistItem,
  UpdateWishlistResponse,
} from '../../../types/wishlist'

/**
 * @deprecated Use Redux Toolkit Query instead
 */
const WishlistService = {
  /**
   * Gets the wishlist
   * `@method WishList#getWishlist`
   */
  getWishlist(langId?: string): Promise<IWishlist> {
    return RequestService.request<IWishlist>({
      path: '/store/{storeId}/wishlist/@default',
      method: 'GET',
      queryParams: { langId },
    })
  },

  async createWishlist() {
    try {
      const response = await RequestService.request<CreateWishlistResponse>({
        body: {
          description: 'Wish List',
          descriptionName: 'Wish List',
          registry: 'false',
        },
        path: '/store/{storeId}/wishlist',
        method: 'POST',
      })
      return response.uniqueID
    } catch (error: any) {
      Log.error('Create wishlist error: ', error)
      return ''
    }
  },

  addItemToWishlist(
    wishListId: string,
    item: IWishlistItem[],
    langId?: string
  ) {
    return RequestService.request<AddItemToWishlistResponse>({
      body: {
        item: item,
      },
      path: `/store/{storeId}/wishlist/${wishListId}?addItem=true`,
      queryParams: { langId },
      method: 'PUT',
    })
  },

  /**
   * Deletes a wishlist
   *
   * `@method WishList#delete`
   *
   ** `@argument {string} externalId` - wishlist external identifier
   ** `@argument {string} itemId` - specifies the ID of the wishlist item to delete
   */
  delete(externalId: string) {
    return RequestService.request({
      method: 'DELETE',
      path: '/store/{storeId}/wishlist/{externalId}',
      queryParams: { externalId },
    })
  },

  /**
   * Deletes an item from a wishlist.
   * Specify an itemId or a productId to delete only that item from the wishlist,
   * otherwise the entire wishlist will be deleted.
   * When both itemId and productId are provided, itemId will be used ONLY.
   *
   * `@method`
   * `@name WishList#deleteItemFromWishlist`
   *
   ** `@argument {string} externalId` - wishlist external identifier
   ** `@argument {string} itemId` - specifies the ID of the wishlist item to delete
   *
   * `@returns {UpdateWishlistResponse}` - `item` contains successfully deleted item
   */
  deleteItemFromWishlist({
    externalId,
    itemId,
    productId,
  }: {
    externalId: string
    itemId?: string
    productId?: string
  }) {
    return RequestService.request<UpdateWishlistResponse>({
      method: 'DELETE',
      path: '/store/{storeId}/wishlist/{externalId}',
      pathParams: { externalId },
      queryParams: { itemId, productId },
    })
  },
}

export default WishlistService
