import React, { useEffect } from 'react'
import useExternalScript from '../../hooks/useExternalScript'
import { StyledLoader } from '../UI'
import { useSelector } from 'react-redux'
import { siteSelector } from '../../redux/selectors/site'

interface Props {
  grandTotal: string | number
  variant?:
    | 'credit-promotion-badge'
    | 'credit-promotion-standard'
    | 'credit-promotion-auto-size'
}

const KlarnaOnSiteMessage: React.FC<Props> = ({
  grandTotal,
  variant = 'credit-promotion-auto-size',
}) => {
  const mySite = useSelector(siteSelector)!
  const hostname = window.location.hostname
  const isUatEnv = hostname.includes('uat')

  const attributes = {
    id: 'klarna-on-site-message',
    'data-clientId': mySite.xStoreCfg.klarnaClientId,
    defer: true,
    async: false,
  }

  if (isUatEnv) {
    attributes['data-environment'] = 'playground'
  }

  const [isLoaded, KlarnaOnsiteService] = useExternalScript(
    mySite.xStoreCfg.klarnaServicesScriptUrl,
    'KlarnaOnsiteService',
    attributes
  )

  const dataLocale = mySite.locale.replace('_', '-')

  useEffect(() => {
    if (isLoaded && grandTotal) {
      KlarnaOnsiteService?.push({
        eventName: 'refresh-placements',
      })
    }
  }, [isLoaded, grandTotal])

  const grandTotalWithCents = (
    (typeof grandTotal !== 'number'
      ? parseFloat(grandTotal || '0')
      : grandTotal) * 100
  ).toFixed(2)

  //if (!isLoaded || !grandTotal) { // TODO ADD LOADER
  if (false) {
    return <StyledLoader size={50} />
  }

  return (
    //   @ts-ignore
    <klarna-placement
      data-key={variant}
      data-locale={dataLocale}
      data-purchase-amount={`${grandTotalWithCents}`}
    />
  )
}

export default KlarnaOnSiteMessage
