import { styled } from '@mui/material/styles'
import Badge, { BadgeProps } from '@mui/material/Badge'
import { Pill, StyledAnchor, ButtonAsLink, LinkAsButton } from '@components/UI'
import { getDesktopThemeBreakpoint } from '../../constants/ui'
import { Link } from 'react-router-dom'
import { Z_INDEX_LV3, Z_INDEX_LV4 } from '../../constants/ui'

export const StyledBadge = styled(Badge, {
  name: 'OrderHistory',
  slot: 'StyledBadge',
})<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    width: 32,
    height: 32,
    backgroundColor: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.5,
    borderRadius: 16,
    color: theme.palette.text.light,
  },
}))

export const OrderHistoryTitle = styled('div', {
  name: 'OrderHistory',
  slot: 'Title',
})(({ theme }) => ({
  display: 'flex',
  whiteSpace: 'nowrap',
  fontWeight: 600,
  fontSize: 20,
  lineHeight: 1.4,
  color: theme.palette.text.dark,

  [theme.breakpoints.up('sm')]: {
    lineHeight: 1.5,
  },
}))

export const OrdersMadeIn = styled('div', {
  name: 'OrderHistory',
  slot: 'OrdersMadeIn',
})(({ theme }) => ({
  display: 'flex',
  fontSize: 10,
  fontWeight: 600,
  color: theme.palette.text.dark,
  textAlign: 'left',
  whiteSpace: 'nowrap',
  maxWidth: 134,
}))

export const OrderHistoryHeader = styled('div', {
  name: 'OrderHistory',
  slot: 'Header',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(8),

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

export const OrderHistoryEmptyContainer = styled('div', {
  name: 'OrderHistory',
  slot: 'EmptyContainer',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const OrderHistoryEmpty = styled('span', {
  name: 'OrderHistory',
  slot: 'Empty',
})(({ theme }) => ({
  textAlign: 'center',

  [theme.breakpoints.up('sm')]: {
    textAlign: 'unset',
    fontSize: 16,
    lineHeight: 1.5,
    color: theme.palette.text.dark,
    maxWidth: 276,
  },
}))

export const OrderHistoryClickBelow = styled('span', {
  name: 'OrderHistory',
  slot: 'ClickBelow',
})(({ theme }) => ({
  textAlign: 'center',

  [theme.breakpoints.up('sm')]: {
    textAlign: 'unset',
    fontSize: 16,
    lineHeight: 1.5,
    color: theme.palette.text.dark,
    maxWidth: 400,
  },
}))

export const OrderHistoryWrapper = styled('div', {
  name: 'OrderHistory',
  slot: 'Wrapper',
})(({ theme }) => ({
  backgroundColor: theme.palette.color.grey.lightest,
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  rowGap: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
    paddingTop: theme.spacing(8),
  },
}))

export const StyledBackToOrderHistoryWrapper = styled(Link, {
  name: 'OrderHistory',
  slot: 'BackToOrderHistory',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.dark,
  fontSize: 14,
}))

export const OrderDetailsHeader = styled('div', {
  name: 'OrderHistory',
  slot: 'OrderDetailsHeader',
})(({ theme }) => ({
  color: theme.palette.text.dark,
  fontWeight: 600,
  fontSize: 20,
  lineHeight: 1.4,
}))

export const OrderHistoryEmptyWrapper = styled('div', {
  name: 'OrderHistory',
  slot: 'EmptyWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  border: `1px solid ${theme.palette.color.grey.light}`,
  backgroundColor: theme.palette.color.white.main,
  padding: theme.spacing(6),
  rowGap: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    alignItems: 'flex-start',
  },
}))

export const OrderHistoryEmptyCTAs = styled('div', {
  name: 'OrderHistory',
  slot: 'EmptyCTAs',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(6),
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    width: 'unset',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    columnGap: theme.spacing(6),
    whiteSpace: 'nowrap',
    '& a': {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
}))

export const OrderHistoryContainerWrapper = styled('div', {
  name: 'OrderHistory',
  slot: 'ContainerWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  rowGap: theme.spacing(6),
  marginBottom: theme.spacing(16),
}))

export const ProductImageWrapper = styled('div', {
  name: 'OrderHistory',
  slot: 'ProductImageWrapper',
})(({ theme }) => ({
  backgroundColor: theme.palette.color.white.main,
  marginBottom: 0,
  '& img': {
    maxWidth: 100,
  },
  [getDesktopThemeBreakpoint(theme)]: {
    backgroundColor: theme.palette.color.white.main,
  },
}))

// For mobile screens only
export const OrderHistoryItem = styled('div', {
  name: 'OrderHistory',
  slot: 'Item',
})(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  border: `1px solid ${theme.palette.color.grey.light}`,
  backgroundColor: theme.palette.color.white.main,
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),

  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(8),
  },
}))

export const OrderHistoryItemTitleWrapper = styled('div', {
  name: 'OrderHistory',
  slot: 'ItemTitleWrapper',
})(({ theme }) => ({
  display: 'flex',

  [theme.breakpoints.up('sm')]: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: theme.spacing(10),
  },
}))

export const OrderHistoryItemTitle = styled('div', {
  name: 'OrderHistory',
  slot: 'ItemTitle',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(4),

  fontSize: 20,
  fontWeight: 600,
  lineHeight: 1.6,
  letterSpacing: 0.5,
  color: theme.palette.text.dark,

  '& span': {
    fontWeight: 'normal',
  },

  [getDesktopThemeBreakpoint(theme)]: {
    flexDirection: 'row',
    columnGap: theme.spacing(10),
    alignItems: 'center',
  },
}))

export const OrderHistoryItemSeparator = styled('div', {
  name: 'OrderHistory',
  slot: 'ItemSeparator',
})(({ theme }) => ({
  height: 1,
  width: '100%',
  backgroundColor: theme.palette.color.grey.light,
  marginTop: 15,
  marginBottom: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(8),
  },
}))

export const OrderHistoryItemInfo = styled('div', {
  name: 'OrderHistory',
  slot: 'ItemInfo',
})(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  columnGap: theme.spacing(8),
  rowGap: theme.spacing(4),
  fontSize: 16,
  lineHeight: 1.5,
  color: theme.palette.text.dark,
  marginBottom: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    columnGap: theme.spacing(10),
  },
}))

export const OrderHistoryStatusContainer = styled('div', {
  name: 'OrderHistory',
  slot: 'StatusContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    columnGap: theme.spacing(4),
  },
}))

export const OrderHistorySortByWrapper = styled('div', {
  name: 'OrderHistory',
  slot: 'SortByWrapper',
})(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  ' > div:nth-of-type(2)': {
    position: 'absolute',
    zIndex: Z_INDEX_LV3,
    right: 0,

    [theme.breakpoints.up('sm')]: {
      right: 'auto',
    },
  },

  [theme.breakpoints.up('sm')]: {
    justifyContent: 'flex-start',
    columnGap: theme.spacing(6),
  },
}))

export const OrderHistorySortByContainer = styled('div', {
  name: 'OrderHistory',
  slot: 'SortByContainer',
})(({ theme }) => ({
  padding: theme.spacing(3, 4, 5, 4),

  '*': {
    whiteSpace: 'nowrap',
  },
}))

export const OrderHistorySortByCTA = styled(Pill)(() => ({
  fontSize: 10,
}))

export const OrderHistoryStyledAnchorCTA = styled(StyledAnchor, {
  name: 'OrderHistory',
  slot: 'StyledAnchorCTA',
})(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  textDecoration: 'underline',
  '&::after': {
    width: '0%',
  },

  [theme.breakpoints.up('sm')]: {
    textDecoration: 'none',
    '&::after': {
      width: '100%',
    },
  },
}))

export const OrderHistoryStyledButtonCTA = styled(LinkAsButton, {
  name: 'OrderHistory',
  slot: 'StyledButtonCTA',
})(() => ({
  fontWeight: 600,
}))

export const OrderHistoryStyledLinkCTA = styled(ButtonAsLink, {
  name: 'OrderHistory',
  slot: 'StyledLinkCTA',
})(() => ({
  fontSize: 16,
  fontWeight: 600,
  width: 'fit-content',
}))

export const OrderHistoryPrescriptionLabel = styled('span', {
  name: 'OrderHistory',
  slot: 'PrescriptionLabel',
})(({ theme }) => ({
  color: theme.palette.error.main,
}))

export const OrderHistoryShippedLabel = styled('span', {
  name: 'OrderHistory',
  slot: 'ShippedLabel',
})(({ theme }) => ({
  color: theme.palette.success.main,
}))

export const OrderHistoryList = styled('div')(() => ({}))

// For mobile screens only
export const OrderHistoryListItem = styled('div', {
  name: 'OrderHistory',
  slot: 'ListItem',
})<{ themeMode: string }>(({ theme }) => ({
  padding: theme.spacing(1, 1, 1.5),
  display: 'flex',
  flexDirection: 'column',
  fontSize: 12,

  '&:not(:first-of-type)': {
    marginTop: 10,
  },

  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.text.primary}`,
  },
}))

export const OrderHistoryImage = styled('img', {
  name: 'OrderHistory',
  slot: 'Image',
})(() => ({
  height: 57,
  width: 'auto',
}))

export const OrderHistorySortByContent = styled('div', {
  name: 'OrderHistory',
  slot: 'SortByContent',
  shouldForwardProp: (prop) => prop !== 'isVisible',
})<{
  isVisible: boolean
}>(({ isVisible, theme }) => ({
  marginTop: theme.spacing(41),
  zIndex: Z_INDEX_LV4,
  width: '95%',
  display: isVisible ? 'block' : 'none',
  boxShadow: theme.shadows[1],
  backgroundColor: theme.palette.color.grey.lightest,
  '& .MuiFormGroup-root': {
    gap: theme.spacing(4),
    '& .MuiRadio-root': {
      padding: theme.spacing(1, 2, 0, 4),
    },
  },
  '& .MuiFormGroup-root span': {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.43,
    color: theme.palette.text.dark,
  },
  '& .MuiFormGroup-root label': {
    marginBottom: 0,
  },

  [theme.breakpoints.up('sm')]: {
    width: 278,
  },
}))

export const OrderHistoryPopup = styled('div', {
  name: 'OrderHistory',
  slot: 'Popup',
})(() => ({
  position: 'relative',
  animation: 'SlideDown .3s ease',
  '@keyframes SlideDown': {
    '0%': {
      transform: 'translateY(-30px)',
    },
    '100%': {
      transform: 'translateY(0px)',
    },
  },
}))

export const LoaderWrapper = styled('div', {
  name: 'Loader',
  slot: 'Wrapper',
})({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
})
