import { useFrameGenius } from '@components/FrameGenius/FrameGeniusContext'
import { useDispatch, useSelector } from 'react-redux'
import { chatReadySelector } from '../features/ui/selector'
import { setOpenChat } from '../features/ui/action'

export const useExternalsActions = (): {
  [action: string]: () => void
} => {
  const { openFrameAdvisor, openSizeAdvisor } = useFrameGenius()
  const isReady = useSelector(chatReadySelector)
  const dispatch = useDispatch()
  const toggleChatBot = () => {
    if (isReady) {
      dispatch(setOpenChat(true))
    } else {
      return
    }
  }

  const mappedActions = {
    'frame-advisor': openFrameAdvisor,
    'size-advisor': openSizeAdvisor,
    'chat-bot': toggleChatBot,
  }

  return mappedActions
}
