import React from 'react'
import { ProductAlgoliaPrice } from '../../../types/ProductAlgolia/ProductAlgolia'
import {
  ProductPriceContainerFinal,
  ProductPriceDiscountBoxFinal,
  ProductPriceInitialPriceFinal,
  ProductPriceWrapper,
} from '@components/ProductPriceFinal/ProductPriceFinal.style'
import ProductPriceAlgoliaUtils from '@utils/ProductAlgolia/ProductPriceAlgoliaUtils'
import FormattedPriceDisplay from '@components/formatted-price-display'
import { styled } from '@mui/material/styles'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import { useTranslation } from 'react-i18next'
import useBreakpoints from '@hooks/useBreakpoints'

export const ProductPriceContainerSuggested = styled(
  ProductPriceContainerFinal,
  {
    name: 'ProductPriceSuggested',
    slot: 'Wrapper',
  }
)(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'center',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}))

export const ProductPriceWrapperSuggested = styled(ProductPriceWrapper, {
  name: 'ProductPriceSuggested',
  slot: 'Wrapper',
})(({ theme }) => ({
  fontSize: 10,

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
}))
export const ProductPriceCurrentPriceSuggested = styled('span', {
  name: 'ProductPriceSuggested',
  slot: 'CurrentPrice',
})(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,

  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
  },
}))

export const ProductPriceContainerCurrentPriceSuggested = styled('div', {
  name: 'ProductPriceSuggested',
  slot: 'ContainerCurrentPrice',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  alignItems: 'baseline',
}))
export const ProductPriceDiscountBoxSuggested = styled(
  ProductPriceDiscountBoxFinal,
  {
    name: 'ProductPriceSuggested',
    slot: 'DiscountBox',
  }
)(() => ({
  fontSize: 14,
}))

interface ProductPriceSuggested {
  price: ProductAlgoliaPrice
  productType: string
}
const ProductPriceSuggested: React.FC<ProductPriceSuggested> = ({
  price,
  productType,
}) => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpoints()

  const {
    offerPrice,
    listPrice,
    currency,
    percentageDiscount,
    badgeDiscount,
    hasInitialPriceDiscount,
  } = ProductPriceAlgoliaUtils.getProductPriceData(price)

  const { getLxProductTypes } = ProductAlgoliaUtils
  const { isContactLensesAccessories, isContactLenses } =
    getLxProductTypes(productType)

  return (
    <ProductPriceWrapperSuggested>
      {isContactLenses &&
        hasInitialPriceDiscount &&
        t('ProductTile.Labels.from') + '\u00A0'}
      <ProductPriceContainerSuggested>
        <ProductPriceContainerCurrentPriceSuggested>
          {Boolean(listPrice) && hasInitialPriceDiscount && (
            <ProductPriceInitialPriceFinal>
              <FormattedPriceDisplay min={listPrice} currency={currency} />
            </ProductPriceInitialPriceFinal>
          )}
          {Boolean(offerPrice) && (
            <>
              {(isContactLenses || isContactLensesAccessories) &&
                !percentageDiscount && <>{t('ProductTile.Labels.from')}</>}
              <ProductPriceCurrentPriceSuggested>
                <FormattedPriceDisplay min={offerPrice} currency={currency} />
              </ProductPriceCurrentPriceSuggested>
            </>
          )}
        </ProductPriceContainerCurrentPriceSuggested>
        {(isContactLenses || isContactLensesAccessories) &&
          t(`ContactLenses.Labels.${isMobile ? 'perBoxMobile' : 'perBox'}`)}
        {Boolean(percentageDiscount) && (
          <ProductPriceDiscountBoxSuggested>
            {badgeDiscount}
          </ProductPriceDiscountBoxSuggested>
        )}
      </ProductPriceContainerSuggested>
    </ProductPriceWrapperSuggested>
  )
}

export default ProductPriceSuggested
