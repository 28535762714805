export const TRACKER_EVENT = 'Event'
export const TRACKER_ADDTOCART = 'AddToCart'
export const TRACKER_ADDTOWISHLIST = 'ProdFavAdd'
export const TRACKER_PRODS_DELETE = 'Prods-Delete'
export const TRACKER_PRODS_CHANGE_UPC = 'VirtualPage-View'
export const TRACKER_MINI_WISHLIST_OVERLAY_OPEN = 'MiniWishOverlay-Open'
export const TRACKER_MINICART_OVERLAY_OPEN = 'MinicartOverlay-Open'
export const TRACKER_OPEN_VIRTUAL_MIRROR = 'VirtualMirror'
export const TRACKER_CHECKOUT_STEP_GATEWAY = 'Checkout-Step-Gateway'

export const TRACKER_NEWSLETTER_SIGNUP = 'SignupForm'
export const TRACKER_REGISTRATION = 'Account-Create'
export const TRACKER_SEARCH = 'SearchPageReady'
export const TRACKER_PROMOCODE_TOGGLE = 'X_X_PromoCode_Open'
export const TRACKER_SEARCH_RUN = 'Events_SearchRun'

// Server' error generated or managed by server side, 'User' error generated by user and properly managed by code (i.e. for filling errors), 'Client' error generated by code in page usually for a catched JS error, "Anomaly" unexpected error/behaviour of page/content, "Product" error caused by product DB
export const TRACKER_ERROR_SOURCE_USER = 'User'
export const TRACKER_ERROR_SOURCE_404 = '404'
export const TRACKER_ERROR_SOURCE_SERVER = 'Server'
export const TRACKER_ERROR_SOURCE_CLIENT = 'Client'
export const TRACKER_ERROR_SOURCE_PRODUCT = 'Product'
export const TRACKER_ERROR_SOURCE_ANOMALY = 'Anomaly'
