import React from 'react'
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import { CheckmarkIcon, IndeterminateCheckBoxIcon } from '../Icons'
import { styled } from '@mui/material/styles'

interface CustomCheckboxProps extends CheckboxProps {
  error?: boolean
}

const Icon = styled('div', {
  name: 'Checkbox',
  slot: 'Icon',
})(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  backgroundColor: 'transparent',
  borderWidth: 1,
  borderColor: theme.palette.text.dark,
  borderStyle: 'solid',
  'input:disabled ~ &': {
    boxShadow: 'none',
    borderColor: theme.palette.primary.main,
    background: 'transparent',
  },
}))

const CheckedIconContainer = styled(Icon, {
  name: 'Checkbox',
  slot: 'IconContainer',
})<{
  disabled?: boolean
}>(({ theme }) => ({
  borderRadius: 4,
  color: theme.palette.color.white.main,
  width: 16,
  height: 16,
  backgroundColor: theme.palette.primary.main,
  display: 'inherit',
  borderWidth: 1,
  borderColor: theme.palette.primary.main,
  borderStyle: 'solid',
  position: 'relative',
  'input:disabled ~ &': {
    boxShadow: 'none',
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.light,
    background: 'transparent',
  },
  '& > svg': {
    position: 'absolute',
    height: '24px',
    width: '24px',
    right: '-5px',
    top: '-5px',
  },
}))

export const StyledCheckbox = ({ ...other }: CustomCheckboxProps) => {
  return (
    <Checkbox
      icon={<Icon />}
      disableRipple
      color="default"
      checkedIcon={
        <CheckedIconContainer>
          <CheckmarkIcon />
        </CheckedIconContainer>
      }
      indeterminateIcon={
        <span>
          <IndeterminateCheckBoxIcon />
        </span>
      }
      style={{ borderRadius: 4 }}
      {...other}
    />
  )
}
