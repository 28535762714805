import { styled } from '@mui/material/styles'

const mapPinIcon =
  "data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.00021 0C4.69321 0 2.01221 2.681 2.01221 5.988C2.01221 9.45933 6.05087 14.1943 7.49454 15.7763C7.63087 15.9253 7.81554 16 8.00021 16C8.18487 16 8.36954 15.9253 8.50587 15.7763C9.94954 14.1943 13.9882 9.45933 13.9882 5.988C13.9882 2.681 11.3072 0 8.00021 0ZM8.01321 15.327H7.98687C6.74154 13.9627 2.67854 9.257 2.67854 5.988C2.67887 3.05367 5.06587 0.666667 8.00021 0.666667C10.9345 0.666667 13.3215 3.05367 13.3215 5.988C13.3215 9.257 9.25854 13.9627 8.01321 15.327Z' fill='%232B2E31'/%3E%3Cpath d='M8.00021 3.33333C6.52987 3.33333 5.33354 4.52967 5.33354 6C5.33354 7.47033 6.52987 8.66667 8.00021 8.66667C9.47054 8.66667 10.6669 7.47033 10.6669 6C10.6669 4.52967 9.47054 3.33333 8.00021 3.33333ZM8.00021 8C6.89721 8 6.00021 7.103 6.00021 6C6.00021 4.897 6.89721 4 8.00021 4C9.10321 4 10.0002 4.897 10.0002 6C10.0002 7.103 9.10321 8 8.00021 8Z' fill='%232B2E31'/%3E%3C/svg%3E%0A"
export const PageContainer = styled('div', {
  name: 'Careers',
  slot: 'Container',
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive?: boolean }>(({ theme, isActive }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0, 4),
  opacity: isActive ? 1 : 0,

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 16),
  },
}))

export const PageTitle = styled('h1', {
  name: 'Careers',
  slot: 'Title',
})(({ theme }) => ({
  width: '100%',
  fontSize: 32,
  color: theme.palette.text.dark,
  margin: theme.spacing(6, 0),

  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(10, 0),
  },
}))

export const AlliboWrapper = styled('div', {
  name: 'Allibo',
  slot: 'Wrapper',
})(({ theme }) => ({
  width: '100%',
  '.aw_jobList': {
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.up('sm')]: {
      display: 'grid',
      gridTemplateColumns: '1fr',
    },

    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 3fr',
      gap: theme.spacing(8),
    },

    '.aw_searchBox': {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(10, 6),
      gap: theme.spacing(8),
      background: theme.palette.color.grey.lightest,
      height: 'fit-content',
      borderRadius: 16,
      '.aw_searchButton': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        backgroundColor: theme.palette.primary.main,
        width: 'auto',
        order: 3,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 600,
        color: theme.palette.text.light,
        borderRadius: 100,

        '&:hover': {
          background: theme.palette.primary.dark,
        },
      },
      '.aw_select': {
        width: 'auto',
        border: `1px solid ${theme.palette.color.grey.light}`,
        color: theme.palette.color.grey.dark,
        backgroundColor: theme.palette.color.white.main,
        height: 48,
        borderRadius: 4,
        padding: theme.spacing(3, 4),
        fontSize: 14,
        margin: 'initial',
        backgroundSize: theme.spacing(2.5, 4),
        appearance: 'none',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 16px center',
        backgroundImage:
          'url(\'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="none" stroke="%23222222" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2 5l6 6 6-6"/></svg>\')',
      },
    },
    '.aw_viewport': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      '.aw_pages': {
        marginTop: theme.spacing(8),
        [theme.breakpoints.up('md')]: {
          marginTop: 0,
        },

        '.aw_page': {
          display: 'flex',
          gap: theme.spacing(4),
          flexDirection: 'column',

          '.aw_jobPosting': {
            display: 'flex',
            gap: theme.spacing(2),
            flexDirection: 'column',
            borderRadius: 16,
            border: `1px solid ${theme.palette.color.grey.light}`,
            padding: theme.spacing(8, 6),
            margin: 'initial',
            [theme.breakpoints.up('lg')]: {
              maxWidth: 752,
            },

            '.aw_rush': {
              padding: theme.spacing(2, 4),
              background: theme.palette.color.backgrounds.color4,
              color: theme.palette.text.dark,
              borderRadius: 14,
              textTransform: 'initial',
              fontSize: 12,
              fontWeight: 600,
              lineHeight: 1.3,
              margin: 0,
              width: 'fit-content',
            },
            '.aw_title': {
              fontWeight: 600,
              fontSize: 16,
              lineHeight: 1.5,
              letterSpacing: 0.8,
              margin: 0,
            },
            '.aw_location': {
              fontSize: 16,
              lineHeight: 1.5,
              letterSpacing: 0.8,
              margin: 0,
              backgroundImage: `url("${mapPinIcon}") !important`,
              backgroundSize: 'auto !important',
              backgroundRepeat: 'no-repeat !important',
              backgroundPosition: 'left !important',
              paddingLeft: theme.spacing(6),
            },
            '.fa-map-marker-alt:before': {
              content: '""',
            },
          },
        },
      },
      '.aw_pager': {
        display: 'flex',
        justifyContent: 'center',
        border: 0,
        margin: 0,

        [theme.breakpoints.up('lg')]: {
          maxWidth: 712,
        },

        '.aw_pagerLink': {
          color: theme.palette.text.dark,
          padding: theme.spacing(0.5, 3),
        },
      },
    },
  },
  '.aw_application': {
    '.aw_applyLink': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 48,
      backgroundColor: theme.palette.primary.main,
      width: 'auto',
      order: 3,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 600,
      color: theme.palette.text.light,
      borderRadius: 100,

      '&:hover': {
        background: theme.palette.primary.dark,
      },
    },
  },
}))

export const AutoCandGrid = styled('div', {
  name: 'Careers',
  slot: 'AutoCandGridContainer',
})(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: theme.spacing(8),

  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '1fr 3fr',
  },
}))

export const AutoCandContainer = styled('div', {
  name: 'Careers',
  slot: 'AutoCandContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.spacing(3),
  backgroundColor: theme.palette.color.grey.lightest,
  padding: theme.spacing(6),
  borderRadius: 16,
  marginBottom: theme.spacing(8),

  [theme.breakpoints.up('lg')]: {
    maxWidth: 752,
    gridColumnStart: 2,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(10),
    a: {
      maxWidth: 215,
    },
  },
}))

export const AutoCandTitle = styled('span', {
  name: 'Careers',
  slot: 'AutoCandTitle',
})(() => ({
  fontWeight: 600,
  lineHeight: 1.2,
}))

export const AutoCandSubtitle = styled('span', {
  name: 'Careers',
  slot: 'AutoCandSubtitle',
})(({ theme }) => ({
  lineHeight: 1.4,
  marginBottom: theme.spacing(2),
}))
