import { styled } from '@mui/material'

export const ProductNameWrapper = styled('div', {
  name: 'ProductNameCLSection',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

export const ProductBrandWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const ProdcuctName = styled('span')(() => ({
  flexGrow: 0,
  fontSize: 14,
  fontWeight: 600,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.43,
  letterSpacing: 'normal',
  textAlign: 'left',
  textTransform: 'uppercase',
}))

export const ProdcuctBrand = styled('span')(() => ({
  flexGrow: 0,
  fontSize: 12,
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.33,
  letterSpacing: 'normal',
  textAlign: 'left',
  textTransform: 'uppercase',
}))
