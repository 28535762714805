import { Accordion, AccordionSummary } from '../../../../UI'

import { styled } from '@mui/material/styles'
import CmsTeaserDetailText from '../../../CmsComponents/CmsTeaserDetailText'
import CmsTeaserTitle from '../../../CmsComponents/CmsTeaserTitle'

export const WrapperFaqs = styled('div', {
  name: 'Faqs',
  slot: 'Wrapper',
})(({ theme }) => ({
  ul: {
    paddingLeft: theme.spacing(5), // see: https://abstractsrl.atlassian.net/browse/DCW-4527
    ['& ::marker']: {
      fontSize: 8,
    },
  },

  color: theme.palette.text.dark,
  padding: theme.spacing(0, 4),
  marginBottom: theme.spacing(10),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 24),
    marginBottom: theme.spacing(10),
  },

  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 44),
  },
}))

export const TitleFaqs = styled(CmsTeaserTitle, {
  name: 'Faqs',
  slot: 'Title',
})<{
  visible?: boolean
}>(({ visible }) => ({
  visibility: visible ? 'visible' : 'hidden',
}))

export const ContentFaqs = styled('div', {
  name: 'Faqs',
  slot: 'Content',
})(() => ({}))

export const StyledAccordion = styled(Accordion, {
  name: 'Faqs',
  slot: 'Accordion',
})(({ theme }) => ({
  '&.MuiAccordion-root': {
    borderBottom: `1px solid ${theme.palette.text.dark}`,
  },
}))

export const StyledAccordionSummary = styled(AccordionSummary, {
  name: 'Faqs',
  slot: 'AccordionSummary',
})(({ theme }) => ({
  borderBottom: 'none',
  fontSize: 16,
  fontWeight: 600,
  lineHeight: 1.5,
  minHeight: 0,
  margin: theme.spacing(4, 0),

  '& .MuiAccordionSummary-content': {
    padding: theme.spacing(1, 0),
  },
}))

export const StyledAccordionDetails = styled(CmsTeaserDetailText, {
  name: 'Faqs',
  slot: 'AccordionDetails',
})(({ theme }) => ({
  marginBottom: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    marginTop: 0,
  },
}))

export const NoResultsMessage = styled('h2', {
  name: 'Faqs',
  slot: 'NoResultsMessage',
})(() => ({
  textAlign: 'center',
}))
