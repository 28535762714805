export function removeRestrictedFields<T extends {}>(
  source: T,
  restrictedFields?: string[]
) {
  return Object.keys(source).reduce((acc, key) => {
    if (!restrictedFields?.includes(key)) {
      Object.assign(acc, { [key]: source[key] })
    }
    return acc
  }, {} as T)
}
