import React from 'react'
import config from '@configs/config.base'

import {
  CertificationImg,
  CertificationsWrapper,
  LeftItemContent,
  RayBanCertificationImg,
  RightItemContent,
  SocialProofItem,
  SocialProofWrapper,
  StyledCertificationAnchor,
} from './SocialProof.style'
import { useTranslation } from 'react-i18next'
import TrustpilotWidgetMicroCombo from '@components/Trustpilot/TrustpilotWidgetMicroCombo'

const SocialProof: React.FC = () => {
  const { t } = useTranslation()
  const migliorInsegnaImgPath =
    config.PUBLIC_URL + '/images/common/miglior-insegna.png'

  const rayBanCertificationImgPath =
    config.PUBLIC_URL + '/images/common/ray-ban-certification2024.jpg'

  const rayBanCertificationLink =
    'https://www.ray-ban.com/italy/c/store-locator'

  return (
    <SocialProofWrapper>
      <SocialProofItem xs={12} sm={4} md={4} lg={10}>
        <LeftItemContent>
          <TrustpilotWidgetMicroCombo />
        </LeftItemContent>
      </SocialProofItem>
      <SocialProofItem xs={12} sm={4} md={4} lg={2}>
        <RightItemContent>
          <CertificationsWrapper>
            <CertificationImg
              src={migliorInsegnaImgPath}
              alt={t('SocialProof.BestSign')}
              loading={'lazy'}
            />
            <StyledCertificationAnchor
              to={rayBanCertificationLink}
              aria-label={t('SocialProof.RayBanCertified')}
              external
            >
              <RayBanCertificationImg
                src={rayBanCertificationImgPath}
                alt={t('SocialProof.RayBanCertified')}
                loading={'lazy'}
              />
            </StyledCertificationAnchor>
          </CertificationsWrapper>
        </RightItemContent>
      </SocialProofItem>
    </SocialProofWrapper>
  )
}

export default SocialProof
