import { styled } from '@mui/material/styles'
import { Z_INDEX_LV1 } from '../../../../constants/ui'
import { teaserOverlaySettingsToCss } from '@utils/placements'
import { CmsBroadContentWrapperProps } from './CmsBroadContentWrapper'

export const StyledCmsBroadContentWrapper = styled('div', {
  name: 'CmsBroadContent',
  slot: 'Wrapper',
  shouldForwardProp: (prop) =>
    prop !== 'istextoverlay' && prop !== 'overlaysettings',
})<CmsBroadContentWrapperProps>(
  ({ istextoverlay, overlaysettings, theme }) => ({
    width: '100%',
    height: '100%',
    position: istextoverlay ? 'absolute' : 'static',
    zIndex: istextoverlay ? Z_INDEX_LV1 : 'unset',
    top: 0,
    wordBreak: 'break-word',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      ...teaserOverlaySettingsToCss(overlaysettings || ''),
    },
  })
)
