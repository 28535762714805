import {
  PayloadAction,
  createSlice,
  isAnyOf,
  AnyAction,
} from '@reduxjs/toolkit'
import { SiteInfo } from 'src/redux/reducers'
import { siteApi } from './query'
import initStates from 'src/redux/reducers/initStates'
import { CommerceEnvironment } from 'src/constants/common'
import { SiteInfoService } from 'src/foundation/hooks/useSite/SiteInfoService'
import { storageSessionHandler } from 'src/foundation/utils/storageUtil'
import i18n from 'src/i18n'
import {
  INIT_STATE_FROM_STORAGE_ACTION,
  LISTEN_USER_FROM_STORAGE_ACTION,
} from 'src/redux/actions/user'
import Log from 'src/services/Log'
import listenerMiddleware from 'src/redux/listener'
import {
  SET_ALGOLIA_BASED_PLP_ACTION,
  SET_FORTER_WEB_ID_ACTION,
} from '../../redux/actions/site'

export type SiteInfoType = SiteInfo | Record<any, never>
export interface SiteReducerState {
  currentSite: SiteInfo | null
}

const initialState = initStates.site
export type SiteApiState = Partial<SiteReducerState>
export const { reducer } = createSlice({
  name: 'site',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      SET_ALGOLIA_BASED_PLP_ACTION,
      (state: any | any, action: PayloadAction<string>) => {
        Object.assign(state, {
          currentSite: {
            ...state.currentSite,
            storeCfg: {
              ...state.currentSite.storeCfg,
              userData: {
                ...state.currentSite.storeCfg.userData,
                ['algolia.based.plp']: action.payload,
              },
            },
          },
        })
      }
    )
    builder.addCase(
      SET_FORTER_WEB_ID_ACTION,
      (state: any | any, action: AnyAction) => {
        Object.assign(state, {
          currentSite: { ...state.currentSite, forterWebId: action.payload },
        })
      }
    )
    builder.addMatcher(
      siteApi.endpoints.initializeSite.matchFulfilled,
      (state, { payload }: PayloadAction<SiteInfo>) => {
        Object.assign(state, { currentSite: { ...payload } })
      }
    )
  },
})

export const siteListeners = (
  startListening: typeof listenerMiddleware.startListening
) => {
  startListening({
    matcher: isAnyOf(siteApi.endpoints.initializeSite.matchFulfilled),
    effect: async (action, listenerApi) => {
      const { payload: siteInfo } = action
      SiteInfoService.getSiteInfo().setSiteValue(siteInfo)
      localStorage.setItem('FORTER_SITE_ID', siteInfo.xStoreCfg?.FORTER_SITE_ID)
      listenerApi.dispatch(
        INIT_STATE_FROM_STORAGE_ACTION({
          widget: 'StoreAppContainer',
        })
      )

      storageSessionHandler.triggerUserStorageListener(() =>
        listenerApi.dispatch(
          LISTEN_USER_FROM_STORAGE_ACTION({
            widget: 'StoreAppContainer',
          })
        )
      )

      // SET TRANSLATE
      const locale =
        //localStorageUtil.get(LOCALE)?.split('_').join('-') ||
        CommerceEnvironment.languageMap[siteInfo?.defaultLanguageID]
          .split('_')
          .join('-')
      //Log.info('MYSITE LOCALE: ', mySite.locale)
      Log.info('LANGUAGEMAP LOCALE: ', locale)
      if (locale !== i18n.languages[0]) {
        i18n.changeLanguage(locale)
      }
      Log.info('SET LOCALE in storeAppContainer ', locale)

      listenerApi.unsubscribe()
    },
  })
}

export default reducer
