import { styled } from '@mui/material/styles'

export const WrapperRegistration = styled('div', {
  name: 'Registration',
  slot: 'Wrapper',
})(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export const StyledForm = styled('form', {
  name: 'Registration',
  slot: 'Form',
})(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: '70%',
  },
}))

export const ContentTextField = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(10),
  padding: theme.spacing(4, 0),
  flexDirection: 'column',
}))

export const ContentButton = styled('div', {
  name: 'Registration',
  slot: 'ContentButton',
})(({ theme }) => ({
  marginTop: theme.spacing(10),
}))
