import React, { useRef, useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Navigation } from 'swiper'
// types
import type { SizeValuesType } from './SizeGuide.type'
// hooks
import useBreakpoints from '../../../../hooks/useBreakpoints'
// utils
// components
import * as S from './SizeGuide.style'
import { StyledSwitch } from '@components/UI'
import {
  SizeGuideGlassesFrontal,
  SizeGuideGlassesThreeQuarters,
} from './SizeGuideGlasses'
import { useTranslation } from 'react-i18next'
import { Product } from 'src/types/product'

import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'

type Props = {
  currentProduct: Product
}

SwiperCore.use([Pagination, Navigation])

const SizeGuide: React.FC<Props> = ({ currentProduct }) => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpoints()
  const paginationSwiperRef = useRef<HTMLDivElement>(null)

  const [swiper, setSwiper] = useState<SwiperCore>()
  const [activeSlider, setActiveSlider] = useState<number>(0)
  const [activeSwitch, setActiveSwitch] = useState<boolean>(false)

  if (!currentProduct) return null
  const attributes = currentProduct?.productAttributes

  const {
    //productType,
    frameSizeDisplay,
    frameSize,
    bridgeWidth,
    templeLength,
    lensHeight,
    hingeDistance,
    lxSearchPageProductType,
  } = ProductAlgoliaUtils.getProductAttributes(attributes)

  const { isFrames } = ProductAlgoliaUtils.getLxProductTypes(
    lxSearchPageProductType
  )

  if (!isFrames) return null

  const SHOW_FRONTAL_BUTTON = activeSlider === 0
  const SHOW_THREE_QUARTERS_BUTTON = activeSlider === 1

  const handleNextSlide = () => swiper?.slideNext()

  const handlePrevSlide = () => swiper?.slidePrev()

  const handleActiveSlider = (swiper: SwiperCore) =>
    setActiveSlider(swiper.activeIndex)

  const mmToInches = (size: string) =>
    size && !isNaN(Number.parseFloat(size))
      ? (Number.parseFloat(size) / 25.4).toFixed(2)
      : ''

  const sizeValuesMm: SizeValuesType = {
    lensWidth: frameSize || '',
    bridgeWidth: bridgeWidth || '',
    templeWidth: templeLength || '',
    lensHeight: lensHeight || '',
    frameWidth: hingeDistance || '',
  }

  const sizeValuesInch: SizeValuesType = {
    lensWidth: mmToInches(sizeValuesMm.lensWidth),
    bridgeWidth: mmToInches(sizeValuesMm.bridgeWidth),
    templeWidth: mmToInches(sizeValuesMm.templeWidth),
    lensHeight: mmToInches(sizeValuesMm.lensHeight),
    frameWidth: mmToInches(sizeValuesMm.frameWidth),
  }

  return (
    <S.StyledWrapper>
      <h3 id="size-guide">{t('SizeGuide.Labels.SizeGuide')}</h3>
      <S.StyledContainer>
        <S.StyledGuideBody>
          <h4>{t('SizeGuide.Labels.FrameSize').toUpperCase()}</h4>
          <S.StyledSizeData>
            {t('ProductTile.Sizes.' + frameSizeDisplay)} (
            {activeSwitch
              ? `${sizeValuesInch.lensWidth}/${sizeValuesInch.bridgeWidth}/${sizeValuesInch.templeWidth}/${sizeValuesInch.lensHeight}`
              : `${sizeValuesMm.lensWidth}/${sizeValuesMm.bridgeWidth}/${sizeValuesMm.templeWidth}/${sizeValuesMm.lensHeight}`}
            )
          </S.StyledSizeData>
          <S.StyledSizeData>
            {t('SizeGuide.Labels.FrameSizeDefinition')}
          </S.StyledSizeData>
          <S.StyledConverterContainer>
            <span>{t('SizeGuide.Labels.millimeters')}</span>
            <label>
              <StyledSwitch
                checked={activeSwitch}
                onChange={() => setActiveSwitch(!activeSwitch)}
                data-element-id="X_X_Prod_SizeFitGuidePanel"
                aria-label="X_X_Prod_SizeFitGuidePanel"
              />
            </label>
            <span>{t('SizeGuide.Labels.inches')}</span>
          </S.StyledConverterContainer>
          <S.StyledSizeHelper>
            {t('SizeGuide.Labels.FrameSizeReference')}{' '}
            {activeSwitch
              ? `${mmToInches('50')}/${mmToInches('22')}/${mmToInches(
                  '150'
                )}/${mmToInches('50')}`
              : '50/22/150/50'}
            ).
          </S.StyledSizeHelper>
        </S.StyledGuideBody>

        <S.StyledSwiperContainer>
          <Swiper
            modules={[Pagination, Navigation]}
            pagination={{
              el: paginationSwiperRef.current,
              clickable: false,
              type: 'progressbar',
              renderProgressbar: (className) =>
                `<div class="${className}"></div>`,
            }}
            onSwiper={setSwiper}
            onSlideChange={handleActiveSlider}
          >
            <SwiperSlide>
              <SizeGuideGlassesThreeQuarters
                sizeValues={activeSwitch ? sizeValuesInch : sizeValuesMm}
              />
            </SwiperSlide>
            <SwiperSlide>
              <SizeGuideGlassesFrontal
                sizeValues={activeSwitch ? sizeValuesInch : sizeValuesMm}
              />
            </SwiperSlide>
          </Swiper>
          {isMobile ? (
            <S.StyledProgressContainer secondSlide={activeSlider > 0}>
              <div />
            </S.StyledProgressContainer>
          ) : null}
        </S.StyledSwiperContainer>
        <S.StyledNavigationContainer>
          <S.StyledNavigationButtons
            onClick={handleNextSlide}
            shouldShowButton={SHOW_FRONTAL_BUTTON}
            data-element-id="X_X_Prod_SizeFitGuidePanel"
          >
            {t('SizeGuide.Labels.FrontalView')} {'>'}
          </S.StyledNavigationButtons>
          <S.StyledNavigationButtons
            onClick={handlePrevSlide}
            shouldShowButton={SHOW_THREE_QUARTERS_BUTTON}
            data-element-id="X_X_Prod_SizeFitGuidePanel"
          >
            {t('SizeGuide.Labels.ThreeQuartersView')} {'>'}
          </S.StyledNavigationButtons>
        </S.StyledNavigationContainer>
      </S.StyledContainer>
    </S.StyledWrapper>
  )
}

export default SizeGuide
