import { styled } from '@mui/material/styles'
import CmsBroadContentWrapper from '../../../CmsComponents/CmsBroadContentWrapper'
import {
  ITeaserOverlayStyle,
  ITeaserOverlaySettings,
  ITeaserOverlayTextAlign,
} from '../../../../../types/cmsPlacement/LXTeaser'
import {
  overLaySettingsToVerticalCSS,
  overLayTextAlignToCSS,
  teaserOverlayStyleToColor,
} from '@utils/placements'
import { StyledTypography } from '../../../../UI'
import CmsTeaserTitle from '../../../CmsComponents/CmsTeaserTitle'
import CmsTeaserDetailText from '../../../CmsComponents/CmsTeaserDetailText'
import { PropsWithChildren } from 'react'

export const WrapperTextModule = styled(CmsBroadContentWrapper, {
  name: 'TextModule',
  slot: 'Content',
  shouldForwardProp: (prop) =>
    prop !== 'overlayTextAlign' &&
    prop !== 'overlaySettings' &&
    prop !== 'teaserOverlayStyle',
})<
  PropsWithChildren<{
    overlaySettings?: ITeaserOverlaySettings
    overlayTextAlign?: ITeaserOverlayTextAlign
    teaserOverlayStyle?: ITeaserOverlayStyle
  }>
>(({ overlaySettings, overlayTextAlign, teaserOverlayStyle, theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    ...overLaySettingsToVerticalCSS(overlaySettings || ''),
  },

  '& > *': {
    ...overLayTextAlignToCSS(overlayTextAlign),
    ...teaserOverlayStyleToColor(theme, teaserOverlayStyle),
  },
}))

export const ContentTextModule = styled('article', {
  name: 'TextModule',
  slot: 'Content',
  shouldForwardProp: (prop) => prop !== 'removePaddingTop',
})<{ removePaddingTop?: boolean }>(({ removePaddingTop, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  padding: theme.spacing(removePaddingTop ? 0 : 10, 4, 10),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(removePaddingTop ? 0 : 10, 24, 10),
  },

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(removePaddingTop ? 0 : 12, 44, 12),
  },

  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(removePaddingTop ? 0 : 16, 44, 16),
  },
}))

export const PreTitleTextModule = styled(StyledTypography, {
  name: 'TextModules',
  slot: 'PreTitle',
})(() => ({
  fontSize: 16,
}))

export const TitleTextModule = styled(CmsTeaserTitle, {
  name: 'TextModules',
  slot: 'Title',
})(({}) => ({
  fontSize: 28,
  lineHeight: 1.14,
  textTransform: 'none',
}))

export const TextTextModule = styled(CmsTeaserDetailText, {
  name: 'TextModules',
  slot: 'Text',
})(({}) => ({}))

export const CtaContentTextModule = styled('div', {
  name: 'TextModules',
  slot: 'CtaContent',
})(({ theme }) => ({
  display: 'flex',
  width: '100%',
  marginTop: theme.spacing(2),
  justifyContent: 'inherit',
  gap: theme.spacing(4),
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}))

export const IconContentTextModule = styled('div', {
  name: 'TextModules',
  slot: 'IconContent',
})(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(2),
  svg: {
    height: 48,
    width: 48,
  },
}))
