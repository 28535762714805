import '../../index.scss'
import { EffectFade, Lazy, Navigation } from 'swiper'
import React, { memo, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperClass } from 'swiper/types'
import { Swiper as SwiperDef } from 'swiper/swiper'
import { StyledSuggestedProductAnchor } from '@components/SuggestedProducts/components/SuggestedProductTile/SuggestedProductTile.style'
import { ProductAlgolia } from '../../../../../../types/ProductAlgolia/ProductAlgolia'
import { useStoreIdentity } from '../../../../../../foundation/hooks/useStoreIdentity'
import ProductImage, {
  ProductImageProps,
} from '@components/ProductImage/ProductImage'

type SuggestedProductTileGalleryProps = {
  currentProductIndex: number
  cluster: ProductAlgolia[]
  onProductChange: (s: SwiperDef) => void
  commonImageProps?: ProductImageProps
  clusterViewLength?: number
  metaData: { [key: string]: string }
}

const SuggestedProductTileGallery = ({
  cluster,
  currentProductIndex,
  onProductChange,
  commonImageProps,
  metaData,
}: SuggestedProductTileGalleryProps) => {
  const { langCode } = useStoreIdentity()
  const swiperRef = useRef<SwiperClass | null>(null)
  const currentProduct = cluster[currentProductIndex]
  const mocoLink = `/${langCode}${currentProduct.url || ''}`
  const ariaLabel = currentProduct?.attributes_translated['MODEL_NAME'] || ''

  return (
    <Swiper
      onSwiper={(swiper) => (swiperRef.current = swiper)}
      onActiveIndexChange={onProductChange}
      initialSlide={currentProductIndex}
      effect={'fade'}
      className={'product-tile-swiper'}
      touchMoveStopPropagation={true}
      lazy={true}
      loop={true}
      navigation={false}
      modules={[Navigation, EffectFade, Lazy]}
    >
      {cluster.map((c, index) => (
        <SwiperSlide key={`product_${index}`}>
          <StyledSuggestedProductAnchor
            to={mocoLink}
            aria-label={ariaLabel}
            {...metaData}
          >
            <ProductImage attachments={c.attachments} {...commonImageProps} />
          </StyledSuggestedProductAnchor>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default memo(SuggestedProductTileGallery)
