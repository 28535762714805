import { styled } from '@mui/material/styles'
import { Button, StyledAnchor } from '@components/UI'
import Typography from '@mui/material/Typography'

export const EditAddressWrapper = styled('div', {
  name: 'EditAddress',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(8, 4),
  rowGap: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(8, 16),
  },
}))

export const BackToAddressBookLink = styled(StyledAnchor, {
  name: 'EditAddress',
  slot: 'BackToAddressBookLink',
})(() => ({
  display: 'flex',
  alignItems: 'center',
  '& > span': {
    textDecoration: 'underline',
    fontSize: 14,
  },
}))

export const EditAddressTitle = styled('div', {
  name: 'EditAddress',
  slot: 'Title',
})(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  color: theme.palette.text.dark,
  textTransform: 'uppercase',
}))

export const AddressFormWrapper = styled('div', {
  name: 'AddressForm',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.color.white.main,
  border: `1px solid ${theme.palette.color.grey.light}`,
  rowGap: theme.spacing(6),
  padding: theme.spacing(6, 4),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(6),
  },
}))

export const AddAddressFormButtonContainer = styled('div', {
  name: 'AddAddressForm',
  slot: 'ButtonContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  columnGap: theme.spacing(8),
  alignItems: 'center',

  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column-reverse',
    rowGap: theme.spacing(6),
    alignItems: 'flex-start',
  },

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    columnGap: theme.spacing(8),
  },
}))

export const EditAddressFormButton = styled(Button, {
  name: 'EditAddressForm',
  slot: 'Button',
})(() => ({}))

export const AddressDetailsBottomLinkWrapper = styled('div', {
  name: 'AddressBox',
  slot: 'DetailsBottomLinkWrapper',
})(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const EditAddressSubmitSuccessMessageWrapper = styled('div', {
  name: 'EditAddressSubmit',
  slot: 'SuccessMessageWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  columnGap: theme.spacing(1),

  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(2.5),
  },
}))

export const EditAddressSubmitSuccessMessage = styled(Typography)(
  ({ theme }) => ({
    fontSize: theme.spacing(3),
    fontWeight: 600,
    lineHeight: 1.5,
    color: theme.palette.success.main,
  })
)
