import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import {
  Button,
  StyledAnchor,
  StyledFormControlLabel,
  StyledTypography,
  TextField,
} from '@components/UI'

export const RegistrationContainer = styled('div', {
  name: 'RegistrationLayout',
  slot: 'RegistrationContainer',
})(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    width: 569,
  },
}))

export const StyledRegistrationTitle = styled(StyledTypography, {
  name: 'RegistrationLayout',
  slot: 'RegistrationContainer',
})(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  textTransform: 'uppercase',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: theme.palette.text.dark,

  [theme.breakpoints.up('md')]: {
    fontSize: 40,
    textTransform: 'none',
    fontWeight: 'normal',
  },
}))

export const StyledRegistrationSubtitle = styled('div', {
  name: 'RegistrationLayout',
  slot: 'StyledRegistrationSubtitle',
})(({ theme }) => ({
  fontSize: 12,
  lineHeight: 1.33,
  paddingBottom: theme.spacing(4),
  color: theme.palette.text.dark,

  [theme.breakpoints.up('md')]: {
    fontSize: 16,
    lineHeight: 1.5,
    paddingBottom: 0,
  },
}))

export const StyledMobileSeparator = styled('div', {
  name: 'RegistrationLayout',
  slot: 'StyledMobileSeparator',
})(({ theme }) => ({
  height: 2,
  flexGrow: 1,
  backgroundColor: theme.palette.color.grey.light,
  margin: theme.spacing(4, 1.5),
}))

export const HeaderPaper = styled('div', {
  name: 'RegistrationLayout',
  slot: 'HeaderPaper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2),
  },

  h1: {
    fontSize: 32,
    color: theme.palette.primary.main,
  },

  h2: {
    fontSize: 16,
    color: theme.palette.primary.main,
  },
}))

export const StyledPolicyLink = styled(StyledAnchor, {
  name: 'RegistrationLayout',
  slot: 'StyledPolicyLink',
})({
  fontWeight: 600,
  whiteSpace: 'nowrap',
})

export const RegistrationFormControlLabel = styled(StyledFormControlLabel, {
  name: 'RegistrationLayout',
  slot: 'RegistrationFormControlLabel',
})({
  alignItems: 'flex-start',
  '.MuiFormControlLabel-label': {
    maxWidth: 569,
  },
})

export const RegistrationFormWrapper = styled('div', {
  name: 'RegistrationLayout',
  slot: 'RegistrationFormWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(11),
  paddingTop: theme.spacing(8),

  '& > div:nth-of-type(5)': {
    '.MuiFormHelperText-root.Mui-error': {
      position: 'static',
      display: 'flex',
      flexDirection: 'column',
    },
  },
}))

export const DateHelperText = styled(Typography, {
  name: 'Date',
  slot: 'HelperText',
})(({ theme }) => ({
  fontSize: 12,
  lineHeight: 1.33,
  color: theme.palette.text.dark,
}))

export const StyledPrivacyCheckboxes = styled(StyledTypography, {
  name: 'RegistrationLayout',
  slot: 'StyledPrivacyCheckboxes',
})({
  fontSize: 16,
  lineHeight: 1.5,
})

export const SubmitButton = styled(Button, {
  name: 'RegistrationLayout',
  slot: 'SubmitButton',
})(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: 221,
  },
}))

export const PrivacyPolicyErrorMessage = styled('div', {
  name: 'RegistrationLayout',
  slot: 'PrivacyPolicyErrorMsg',
})(({ theme }) => ({
  paddingTop: theme.spacing(1),
  fontSize: 12,
  color: theme.palette.error.main,
}))

export const DateTextField = styled(TextField, {
  name: 'Date',
  slot: 'TextField',
})(() => ({
  '*::placeholder': {
    textTransform: 'uppercase',
  },
}))
