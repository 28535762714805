import { styled } from '@mui/material/styles'

export const StyledCheckoutProgressContainer = styled('div', {
  name: 'CheckoutProgress',
  slot: 'Container',
})(({ theme }) => ({
  padding: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    flex: 2,
    padding: 0,
  },

  [theme.breakpoints.up('lg')]: {
    flex: '1 0 15%',
  },
}))

export const StyledCheckoutProgressBar = styled('ul', {
  name: 'CheckoutProgressBar',
  slot: 'Ul',
})(() => ({
  display: 'flex',
  margin: '0',
  padding: '0',
  fontSize: '10px',
  height: '1rem',
}))

export const StyledCheckoutProgressBarElementDot = styled('div', {
  name: 'CheckoutProgressBar',
  slot: 'ElementDot',
})<{
  active?: 1 | 0
  completed?: 1 | 0
}>(({ active, completed, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  flex: '0 0 1rem',
  width: '1rem',
  height: '1rem',
  borderRadius: '50vmax',
  border:
    active === 1
      ? `1px solid ${theme.palette.primary.light}`
      : completed === 1
      ? 'none'
      : `1px solid ${theme.palette.color.grey.light}`,
  backgroundColor: active === 1 ? theme.palette.primary.light : 'none',
}))

export const StyledCheckoutProgressBarElementStepname = styled('div', {
  name: 'CheckoutProgressBar',
  slot: 'ElementStepname',
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive?: boolean }>(({ theme, isActive }) => ({
  position: 'absolute',
  top: theme.spacing(4),
  flex: '1',
  alignSelf: 'center',
  textAlign: 'center',
  minWidth: '100px',
  whiteSpace: 'nowrap',
  fontSize: 11,
  fontWeight: isActive ? 600 : 500,

  [theme.breakpoints.up('sm')]: {
    position: 'static',
    paddingLeft: theme.spacing(),
    textAlign: 'unset',
    minWidth: 'unset',
    flex: 'unset',
  },
}))

export const StyledCheckoutProgressBarElementLine = styled('div', {
  name: 'CheckoutProgressBar',
  slot: 'ElementLine',
})<{
  completed?: 1 | 0
}>(({ completed, theme }) => ({
  backgroundColor:
    completed === 1 ? theme.palette.text.dark : theme.palette.color.grey.light,
  height: '1px',
  width: '100%',
  marginLeft: theme.spacing(),
  marginRight: theme.spacing(),
}))

export const StyledCheckoutProgressBarElementWrapper = styled('div', {
  name: 'CheckoutProgressBar',
  slot: 'ElementWrapper',
})<{
  completed?: 1 | 0
}>(({ completed }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: completed === 1 ? 'pointer' : '',
}))

export const StyledCheckoutProgressBarElement = styled('li', {
  name: 'CheckoutProgressBar',
  slot: 'Element',
})<{
  active?: 1 | 0
  completed?: 1 | 0
  last?: 1 | 0
}>(({ active, last, theme, completed }) => ({
  lineHeight: '1rem',
  listStyleType: 'none',
  display: 'flex',
  flex: last === 1 ? '0 0 1rem' : '1',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  color:
    completed !== 1 && active !== 1
      ? theme.palette.color.grey.dark
      : theme.palette.text.dark,
  fontWeight: active === 1 ? 'bold' : 'normal',
  [theme.breakpoints.up('sm')]: {
    flex: last === 1 ? '0 1 auto' : '1',
  },
}))
