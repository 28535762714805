import React from 'react'
import Switch from '@mui/material/Switch'
import { StyledFormControlLabel } from './StyledFormControlLabel'
import { styled } from '@mui/material/styles'

interface CustomSwitchProps {
  checked: boolean
  setChecked: Function
  label?: string
  [key: string]: any
}

const CustomSwitch = React.forwardRef<any, CustomSwitchProps>(
  (props: any, ref: any) => {
    const { checked, setChecked, label, ...other } = props

    return label ? (
      <StyledFormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={setChecked}
            {...other}
            ref={ref}
          />
        }
        label={label}
      />
    ) : (
      <Switch checked={checked} onChange={setChecked} {...other} ref={ref} />
    )
  }
)

export const StyledSwitch = styled(CustomSwitch)(({ theme }) => ({
  width: 44,
  height: 24,
  padding: 0,
  borderRadius: 12,
  display: 'flex',

  '& .MuiSwitch-switchBase': {
    '&.Mui-disabled': {
      color: theme.palette.text.light,
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 1,
    },
    padding: 2,
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.text.light,
  },
  '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled': {
    color: theme.palette.text.dark,
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 20,
    height: 20,
    borderRadius: 12,
  },
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: theme.palette.color.grey.dark,
    boxSizing: 'border-box',
  },
}))
