import * as ROUTES from '../constants/routes'

import {
  ACTIVITY_TOKEN_ERROR_KEY,
  EXPIRED_ACTIVITY_TOKEN_ERROR,
  EXPIRED_COOKIE_ERROR_KEY,
  INVALID_COOKIE_ERROR_KEY,
} from '../constants/errors'
import Axios, { Canceler } from 'axios'
import { LOGOUT_REQUESTED_ACTION } from '../redux/actions/user'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import React, { Dispatch, useCallback, useEffect } from 'react'
import { useSite } from '../foundation/hooks/useSite'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import BaseLayout, { BaseLayoutSkeleton } from './BaseLayout'
import { CommerceEnvironment } from '../constants/common'
import Log from '../services/Log'
import { SIGNIN } from '../constants/routes'
import config from '../configs'
import { countryUtil } from '@utils/countryUtil'
import { generatePath } from 'react-router'
import getDisplayName from 'react-display-name'
import maintenanceService from '../foundation/apis/maintenance/maintenance.service'
import {
  genericErrorSelector,
  sessionErrorSelector,
} from '../redux/selectors/error'
import { site } from '../foundation/constants/site'
import { useStoreIdentity } from '../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'react-i18next'
import { guestStatusSelector } from 'src/redux/selectors/user'
import { CANCEL_SESSION_ERROR_ACTION } from 'src/redux/actions/error'
import { useLazyInitializeSiteQuery } from '../features/site/query'

export const StoreAppContainer: React.FC = () => {
  const widgetName = getDisplayName(StoreAppContainer)
  const dispatch = useDispatch<Dispatch<any>>()
  const { langCode } = useStoreIdentity()
  const navigate = useNavigate()
  const { mySite } = useSite()
  const { country } = useParams<{ country: string }>()
  const { currentCountry } = countryUtil(country)
  const { i18n } = useTranslation()
  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []

  const countryValues = CommerceEnvironment.localeLangMap
  const controlUrlCountry = countryValues.find((t) => {
    const { currentLangCode } = countryUtil('', t.langCode)
    return country === currentLangCode
  })

  const sessionError = useSelector(sessionErrorSelector, shallowEqual)
  const error: any = useSelector(genericErrorSelector)
  const userIsGuest = !!useSelector(guestStatusSelector)
  const payloadBase: any = {
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }
  const [getSite] = useLazyInitializeSiteQuery()
  useEffect(() => {
    if (
      [INVALID_COOKIE_ERROR_KEY, ACTIVITY_TOKEN_ERROR_KEY].includes(
        sessionError.errorKey
      ) ||
      sessionError.errorCode === EXPIRED_ACTIVITY_TOKEN_ERROR ||
      error.errorKey === EXPIRED_COOKIE_ERROR_KEY
    ) {
      let payload = {
        ...payloadBase,
      }
      dispatch(CANCEL_SESSION_ERROR_ACTION(payload))
      dispatch(LOGOUT_REQUESTED_ACTION(payload))

      if (sessionError.errorKey !== ACTIVITY_TOKEN_ERROR_KEY && !userIsGuest) {
        resetNavigation()
      }
    }
  }, [sessionError, error, userIsGuest])

  const resetNavigation = useCallback(() => {
    const basePath = generatePath(`/:country/${SIGNIN}`, { country: langCode })
    navigate(`${basePath}?sessionExpired=true`)
  }, [])

  useEffect(() => {
    const interval = setInterval(async () => {
      const maintenanceStatus =
        await maintenanceService.checkMaintenanceStatus()
      if (maintenanceStatus === config.maintenanceStatus) {
        navigate(ROUTES.MAINTENANCE, { replace: true })
      }
    }, 20e3)

    return () => {
      clearInterval(interval)
    }
  }, [])

  Log.info('StoreAppContainer')

  useEffect(() => {
    let cancels: Canceler[] = []
    if (controlUrlCountry) {
      if (mySite) {
        Log.info('INIT STATE FROM STORAGE SITE')

        // SET TRANSLATE
        const locale =
          //localStorageUtil.get(LOCALE)?.split('_').join('-') ||
          //CommerceEnvironment.languageMap[mySite.defaultLanguageID]
          controlUrlCountry.langCode.split('_').join('-')
        Log.info('MYSITE LOCALE: ', mySite.locale)
        Log.info('LANGUAGEMAP LOCALE: ', locale)
        if (locale !== i18n.languages[0]) {
          i18n.changeLanguage(locale)
        }
        Log.info('SET LOCALE in storeAppContainer ', locale)
      } else {
        Log.info('SET LOCALE in storeAppContainer ', currentCountry)
        getSite({
          siteInfo: site,
          country: currentCountry,
        })
      }
    }
    return () => {
      cancels.forEach((cancel) => cancel())
    }
  }, [mySite, dispatch, i18n, widgetName, CancelToken, country])

  Log.info(
    'App routing setup in StoreAppContainer, selected country: ',
    country
  )
  if (!controlUrlCountry) return <Navigate to="/" />

  return mySite ? <BaseLayout /> : <BaseLayoutSkeleton />
}
