import {
  GetUpdatedFacetQueryParams,
  IFacet,
  IPlpProductArgs,
  IPlpProductResponse,
} from './query'
import { IPlpFacets, IXStoreConfig } from '../../redux/reducers'
import { LX_SEARCH_PAGE_PRODUCT_TYPE, SKU_TYPE } from '../../constants/product'
import intersectionWith from 'lodash/intersectionWith'
import uniq from 'lodash/uniq'
import Log from '../../services/Log'
import { PaginationResponse } from '../../services/RequestService'
import ProductUtils from '@utils/ProductUtils'
import i18n from 'i18next'
import queryString from 'querystring'
import { Product, ServerProduct } from '../../types/product'
import omit from 'lodash/omit'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'

const getLabelsFromSkuTypeWithLxDefault = (
  facets: IFacet[],
  lxDefaultTypeLabels: string[]
): string[] => {
  const skuType = facets.find((f) => f.name === SKU_TYPE)
  const skuTypeLabels = skuType?.entry.map((e) => e.label)

  const getLabels = (skuTypeLabel?: string) => {
    switch (skuTypeLabel) {
      case 'Sunglasses': {
        return [
          ...lxDefaultTypeLabels,
          `${lxDefaultTypeLabels[0]}_${skuTypeLabel}`,
        ]
      }
      case 'Glasses': {
        return [`${lxDefaultTypeLabels[0]}_${skuTypeLabel}`]
      }
      default: {
        return lxDefaultTypeLabels
      }
    }
  }

  const labelsFromSkuType = skuTypeLabels?.reduce<string[]>((acc, label) => {
    return [...acc, ...getLabels(label)]
  }, [])

  return labelsFromSkuType || lxDefaultTypeLabels
}

const formatFacetsFromApiResponse = (rawFacets?: IFacet[]) => {
  const facets = rawFacets || []

  // [Start] Convert labels from price facet to readable format
  const priceFacetIndex = facets.findIndex((i) =>
    ProductUtils.findPriceFacetByName(i.name)
  )

  if (priceFacetIndex !== -1) {
    const priceFacet = facets[priceFacetIndex]

    facets[priceFacetIndex] = ProductUtils.formatPriceFacet(
      priceFacet,
      i18n.t('ProductFilter.Labels.PriceRangeStartPrefix'),
      i18n.t('ProductFilter.Labels.PriceRangeEndPrefix')
    )
  }
  // [End] Convert labels from price facet to readable format

  return facets
}

export const transformResponseUpdateFacets = (
  data: PaginationResponse,
  plpFacet: IPlpFacets,
  plpPriceFacet: IXStoreConfig['plpPriceFacets'],
  args: GetUpdatedFacetQueryParams
) => {
  const facets = formatFacetsFromApiResponse(data.facets)

  const facetsAlwaysEmpty = getFacetsAlwaysEmpty(facets) || []
  const currentPLPFacets =
    getCurrentPLPFacets(args, facets, plpFacet, plpPriceFacet) || []
  const selectedFacets = getFacetFromUrl2(args.facet!, facets)

  return {
    facets,
    productList: data.contents || [],
    productListTotal: data.total || 0,
    priceMode: data.metaData?.price! || '',
    breadcrumbs: data.breadCrumbTrailEntryView! || [],
    facetsAlwaysEmpty,
    currentPLPFacets,
    selectedFacets,
  } as IPlpProductResponse
}

export const transformResponseProductList = (
  data: PaginationResponse,
  args: IPlpProductArgs
) => {
  const facets = formatFacetsFromApiResponse(data.facets)
  const selectedFacets = getFacetFromUrl2(args.facet!, facets) || {}

  return {
    facets,
    productList: getPlpProductList(data.contents || []),
    productListTotal: data.total || 0,
    priceMode: data.metaData?.price! || '',
    breadcrumbs: data.breadCrumbTrailEntryView! || [],
    selectedFacets,
  } as IPlpProductResponse
}

const getFacetsAlwaysEmpty = (baseFacets: IFacet[]) => {
  const retValue: string[] = []

  if (baseFacets) {
    baseFacets.forEach((filter) => {
      filter.entry.forEach((entry) => {
        if (entry.count === 0) {
          retValue.push(entry.value)
        }
      })
    })
  }

  return retValue
}

/**
 * @param args
 * @param facets array of all (base) facets
 * @param plpFacets Prop from xStoreCfg with map of categories and facets to display
 * @param categoriesToHidePriceFacet List of Categories where to hide price facet
 *
 * @returns array of facets `IFacet[]` to display for given category
 */
const getCurrentPLPFacets = (
  args: GetUpdatedFacetQueryParams,
  facets: IFacet[],
  plpFacets: IPlpFacets,
  categoriesToHidePriceFacet: IXStoreConfig['plpPriceFacets']
) => {
  const categoryIdentifier = args.categoryIdentifier
  const lxProductTypeFacet = facets.find(
    (catalog) => catalog.name === LX_SEARCH_PAGE_PRODUCT_TYPE
  )
  const shouldPriceFacetBePresent = !categoriesToHidePriceFacet?.find(
    (c) => c === categoryIdentifier
  )
  const qs = queryString.decode(window.location.search)
  const isSearch = Boolean(qs['searchTerm'])

  const includedProductTypes =
    isSearch && args.facet?.length
      ? intersectionWith(
          lxProductTypeFacet?.entry || [],
          args.facet || [],
          ({ value }, v) => v === value
        ).map((e) => e.label)
      : []

  if (!lxProductTypeFacet) {
    if (!shouldPriceFacetBePresent) {
      const facetsCopy: IFacet[] = Object.assign([], facets)

      const priceFacetIndex = facetsCopy.findIndex((f) =>
        ProductUtils.findPriceFacetByName(f.name)
      )

      if (priceFacetIndex !== -1) {
        // remove price facet from list of PLP facet to be displayed on UI
        facetsCopy.splice(priceFacetIndex, 1)
      }

      return facetsCopy
    }

    return facets
  }

  //get labels from lx_search_page_product_type
  const lxProductTypeLabels = lxProductTypeFacet.entry
    ?.map((e) => e.label)
    .filter((e) => {
      if (!includedProductTypes.length) return e
      return includedProductTypes.includes(e)
    })

  const productTypeLabels = getLabelsFromSkuTypeWithLxDefault(
    facets,
    lxProductTypeLabels
  )

  // PLP facet names that should be displayed on UI
  const plpFacetNamesToDisplay =
    uniq<string>(
      Object.keys(plpFacets ?? {})
        .filter((facet) => productTypeLabels.includes(facet))
        .reduce((acc, key) => acc.concat(plpFacets?.[key]), [])
    ) ?? []

  // [Start] Logic of handling presence of price facet within current PLP facets
  if (
    shouldPriceFacetBePresent &&
    !plpFacetNamesToDisplay.find(ProductUtils.findPriceFacetByName)
  ) {
    const priceFacetName = facets.find((f) =>
      ProductUtils.findPriceFacetByName(f.name)
    )?.name

    if (priceFacetName) {
      plpFacetNamesToDisplay.push(priceFacetName)
    }
  } else if (!shouldPriceFacetBePresent) {
    /** if price facet should be displayed (`shouldPriceFacetBeHidden === false`),
     * but xStoreCfg's `plpFacet` doesn't include its name,
     * include manually */
    const priceFacetNameIndex = plpFacetNamesToDisplay.findIndex(
      ProductUtils.findPriceFacetByName
    )

    if (priceFacetNameIndex !== -1) {
      // remove price facet name from list of PLP facet names to be displayed on UI
      plpFacetNamesToDisplay.splice(priceFacetNameIndex, 1)
    }
  }
  // [End] Logic of handling presence of price facet within current PLP facets

  // filter facets with `plpFacetNamesToDisplay`
  return plpFacetNamesToDisplay.reduce((prev, facetName) => {
    const found = facets.find((v) => v.name === facetName)

    return found ? [...prev, found] : prev
  }, [] as IFacet[])
}

export const getSuggestedPlpDashFacets = (
  facets: IFacet[],
  plpDashFacet: IXStoreConfig['plpDashfacet'],
  facetsAlwaysEmpty: string[],
  identifiers: string[]
): IFacet[] => {
  const getFirstAvailableCategory = (
    plpDashFacet: IXStoreConfig['plpDashfacet'],
    identifiers: string[]
  ) => {
    if (identifiers.length === 0) return null
    const firstIdentifier = identifiers[0]
    if (plpDashFacet[firstIdentifier]) {
      return firstIdentifier
    }
    const identifier = Object.keys(plpDashFacet).find((facet) =>
      facet.includes(firstIdentifier)
    )
    if (identifier) {
      return identifier
    } else return getFirstAvailableCategory(plpDashFacet, identifiers.slice(1))
  }

  const suggestedCategory =
    identifiers.length && getFirstAvailableCategory(plpDashFacet, identifiers)

  const categoryPlpDashFacets = plpDashFacet[suggestedCategory] || []
  // Make a map with facet name as key, facet entry values as value, e.g., `{ BRAND: ["Persol"], LENS_COLOR: ["Red", "Blue"] }`
  const plpDashFacetsMap = categoryPlpDashFacets.reduce((acc, dashFacet) => {
    Object.keys(dashFacet).forEach((facetName) => {
      acc[facetName] = [...(acc[facetName] || []), dashFacet[facetName]]
    })

    return acc
  }, {} as Record<string, string[]>)

  return Object.keys(plpDashFacetsMap).reduce((prev, facetName) => {
    let foundFacet = facets.find((v) => v.name === facetName)

    if (foundFacet && plpDashFacetsMap[facetName]) {
      const entryTrue = foundFacet.entry.find((v) =>
        plpDashFacetsMap[facetName].includes(v.label)
      )

      if (!entryTrue || facetsAlwaysEmpty.includes(entryTrue.value)) {
        foundFacet = undefined
      }
    }

    const filterName = foundFacet ? [...prev, foundFacet] : prev

    return filterName.map((facet) => {
      return {
        ...facet,
        entry: facet.entry.filter((v) =>
          plpDashFacetsMap[facet.name].includes(v.label)
        ),
      }
    })
  }, [] as IFacet[])
}

const getFacetFromUrl2 = (
  queryFacet: string[] | string,
  baseFacets: IFacet[]
) => {
  if (!queryFacet) return {}

  if (Array.isArray(queryFacet)) {
    return queryFacet.reduce((acc, facetEntryValue) => {
      //returns an object, I need the first key
      const decodedFacet = queryString.decode(facetEntryValue)
      const firstKey = Object.keys(decodedFacet)[0]
      const splitDecodedFacet = String(firstKey).split(':')
      Log.info('facet', splitDecodedFacet.toString())

      const value = splitDecodedFacet[1].replaceAll('"', '')
      const facet = baseFacets.find((f) => f.value === splitDecodedFacet[0])
      const facetEntry = facet?.entry.find((f) => f.value === facetEntryValue)

      acc[facetEntryValue] = {
        facetName: facet?.name || '',
        label: facetEntry?.label || value,
        value,
      }

      return acc
    }, {})
  } else {
    const decodedFacet = queryString.decode(queryFacet)

    if (decodedFacet !== undefined) {
      const firstKey = Object.keys(decodedFacet)[0]
      const splitDecodedFacet = String(firstKey).split(':')

      const value = splitDecodedFacet[1].replaceAll('"', '')
      const facet = baseFacets.find((f) => f.value === splitDecodedFacet[0])
      const facetEntry = facet?.entry.find((f) => f.value === queryFacet)

      return {
        [queryFacet]: {
          facetName: facet?.name || '',
          label: facetEntry?.label || value,
          value,
        },
      }
    }

    return {}
  }
}

export const getPlpProductList = (products: ServerProduct[]): Product[] => {
  return products.map((product) => {
    return getFormattedPlpProduct(product)
  })
}

export const getFormattedPlpProduct = (
  product: ServerProduct,
  type?: string
) => {
  switch (type) {
    case 'ItemBean':
      return {
        ...omit(product, ['attributes']),
        productAttributes:
          ProductAlgoliaUtils.getFormattedServerProductAttributes(
            product.attributes
          ),
      }
    case 'ProductBean':
      return {
        seo: getProductSeo(product),
        items: product.items
          ? product.items.map((item) =>
              getFormattedPlpProduct(item, 'ItemBean')
            )
          : product.sKUs?.map((sku) =>
              getFormattedPlpProduct(
                sku,
                sku.catalogEntryTypeCode || 'ItemBean'
              )
            ),
        ...omit(product, ['attributes', 'sKUs']),
        productAttributes:
          ProductAlgoliaUtils.getFormattedServerProductAttributes(
            product.attributes
          ),
      }
    default:
      const newCluster = product?.cluster || [product]
      return {
        id: product.id || product.uniqueID,
        partNumber: product.partNumber,
        clusters: newCluster.map((clusterItem) => {
          return getFormattedPlpProduct(
            clusterItem,
            clusterItem.catalogEntryTypeCode || 'ProductBean'
          )
        }),
      }
  }
}

const getProductSeo = (product: ServerProduct) => {
  if (product.seo) return product.seo
  else {
    return product.sKUs ? product.sKUs?.[0]?.seo : product.items?.[0]?.seo
  }
}
