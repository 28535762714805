import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { Button, StyledAnchor } from '@components/UI'

export const AccountSettingsBodyWrapper = styled('div', {
  name: 'AccountSettings',
  slot: 'BodyWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(6),
  marginTop: theme.spacing(4),

  paddingTop: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingBottom: theme.spacing(8),

  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(4),
    paddingLeft: 67,
    paddingRight: theme.spacing(16),
    paddingBottom: 65,
  },
}))

export const AccountSettingsTitle = styled('div', {
  name: 'AccountSettings',
  slot: 'Title',
})(({ theme }) => ({
  fontSize: theme.spacing(5),
  fontWeight: 600,
  lineHeight: 1.4,
  color: theme.palette.text.dark,
}))

export const ChangePasswordWrapper = styled('div', {
  name: 'AccountSettings',
  slot: 'ChangePasswordWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(8),
  backgroundColor: theme.palette.color.white.main,
  padding: theme.spacing(4),
  border: `1px solid ${theme.palette.color.grey.light}`,

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(6),
  },
}))

export const NewsLetterWrapper = styled('div', {
  name: 'AccountSettings',
  slot: 'NewsLetterWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(2),
  backgroundColor: theme.palette.color.white.main,
  padding: theme.spacing(6),
  border: `1px solid ${theme.palette.color.grey.light}`,
}))

export const NewsletterSubscribedWrapper = styled('div', {
  name: 'AccountSettings',
  slot: 'NewsletterSubscribedWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(6),
}))

export const NewsletterSubscribedText = styled(Typography, {
  name: 'AccountSettings',
  slot: 'NewsletterSubscribedText',
})(({ theme }) => ({
  fontSize: theme.spacing(4),
  lineHeight: 1.5,
  color: theme.palette.text.dark,
}))

export const NewsletterEmailWrapper = styled('div', {
  name: 'AccountSettings',
  slot: 'NewsletterEmailWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}))

export const NewsletterEmailText = styled(Typography, {
  name: 'AccountSettings',
  slot: 'NewsletterEmailText',
})(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 600,
  lineHeight: 1.5,
  color: theme.palette.text.dark,
}))

export const ChangePasswordForm = styled('form', {
  name: 'AccountSettings',
  slot: 'ChangePasswordForm',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(8),
  maxWidth: 381,

  '& > div:first-of-type': {
    '.MuiFormHelperText-root.Mui-error': {
      position: 'static',
      display: 'flex',
      flexDirection: 'column',
    },
  },

  '& > div:nth-of-type(3)': {
    '.MuiFormHelperText-root.Mui-error': {
      position: 'static',
      display: 'flex',
      flexDirection: 'column',
    },
  },
}))

export const ChangePasswordTitle = styled('div', {
  name: 'AccountSettings',
  slot: 'ChangePasswordTitle',
})(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 600,
  lineHeight: 1.5,
  color: theme.palette.text.dark,
  textTransform: 'uppercase',
}))

export const NewsletterTitle = styled('div', {
  name: 'AccountSettings',
  slot: 'NewsletterTitle',
})(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 600,
  lineHeight: 1.5,
  color: theme.palette.text.dark,
  textTransform: 'uppercase',
}))

export const StyledErrorMessage = styled('p', {
  name: 'AccountSettings',
  slot: 'StyledErrorMessage',
})(() => ({
  // marginTop: 0,
  // marginBottom: 19,
  // color: theme.palette.error
}))

export const UpdateButtonWrapper = styled('div', {
  name: 'UpdatePassword',
  slot: 'UpdateButtonWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  rowGap: theme.spacing(6),
  alignItems: 'flex-start',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    columnGap: theme.spacing(8),
    alignItems: 'center',
  },
}))

export const UpdateButton = styled(Button, {
  name: 'AccountSettings',
  slot: 'UpdateButton',
})(({ theme }) => ({
  color: theme.palette.text.light,
  background: theme.palette.primary.main,
  whiteSpace: 'nowrap',
  // maxWidth: 163,
}))

export const NewsletterUnsubscribeButton = styled(Button, {
  name: 'AccountSettings',
  slot: 'NewsletterUnsubscribeButton',
})(({ theme }) => ({
  margin: theme.spacing(4, 0),
  whiteSpace: 'nowrap',

  [theme.breakpoints.up('sm')]: {
    margin: 0,
  },
}))

export const SubmitSuccessMessage = styled(Typography, {
  name: 'AccountSettings',
  slot: 'SubmitSuccessMessage',
})(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 600,
  lineHeight: 1.5,
  color: theme.palette.success.main,
}))

export const SubmitSuccessMessageWrapper = styled('div', {
  name: 'AccountSettings',
  slot: 'SubmitSuccessMessageWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  columnGap: theme.spacing(1),
}))

export const NewsletterSubscriptionFormWrapper = styled('div', {
  name: 'AccountSettings',
  slot: 'NewsletterSubscriptionWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))

export const NewsletterSubscriptionTextFieldWrapper = styled('div', {
  name: 'AccountSettings',
  slot: 'NewsletterSubscriptionWrapper',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginRight: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    width: '60%',
    maxWidth: 381,
  },

  [theme.breakpoints.up('md')]: {
    width: '50%',
    maxWidth: 381,
  },
}))

export const AccountNewsletterSubscriptionCertification = styled('p', {
  name: 'AccountSettings',
  slot: 'NewsletterSubscriptionCertification',
})(({ theme }) => ({
  display: 'flex',
  fontSize: 12,
  color: theme.palette.text.light,
  lineHeight: 1.33,
}))

export const AccountDataProtectionPolicyWrapper = styled('div', {
  name: 'AccountSettings',
  slot: 'DataProtectionPolicyWrapper',
})(({ theme }) => ({
  width: 'auto',
  display: 'flex',
  justifyContent: 'center',

  [theme.breakpoints.up('sm')]: {
    justifyContent: 'flex-start',
  },
}))

export const AccountDataProtectionPolicy = styled(StyledAnchor, {
  name: 'AccountSettings',
  slot: 'DataProtectionPolicy',
})(({ theme }) => ({
  textAlign: 'center',
  fontSize: 16,
  fontWeight: 600,
  color: theme.palette.text.dark,
  lineHeight: 1.5,

  [theme.breakpoints.up('sm')]: {
    textAlign: 'left',
  },
}))
