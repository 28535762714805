export const getImageHeightByWidthAndRatio = ({
  width,
  ratios,
}: {
  width: number
  ratios: number[]
}): number | null => {
  try {
    let rat1 = ratios[0]
    let rat2 = ratios[1]

    let ratio = width / rat1
    let calculated_height = ratio * rat2

    return calculated_height
  } catch {
    return null
  }
}

export const getImageRatioandWidthFromUrl = (
  url: string
): {
  ratio: number[]
  width: number
} | null => {
  try {
    const splittedUrl = url.split('/')
    const imageRatioValueIndex = splittedUrl.findIndex(
      (split) => split.indexOf('ratio') > -1
    )
    const imageRatioValues = splittedUrl[imageRatioValueIndex]
      ?.split('ratio')[1]
      ?.split('x')
      ?.map((value) => {
        return parseFloat(value)
      })
    const imageWidthValue = parseFloat(splittedUrl[imageRatioValueIndex + 1])
    return {
      ratio: imageRatioValues,
      width: imageWidthValue,
    }
  } catch (e) {
    return null
  }
}

export const getImageResizedHeightByResizedWidth = (
  resizedWith: number,
  originalWidth: number,
  originalHeight: number
): number | null => {
  try {
    const resizedHeight = (originalHeight / originalWidth) * resizedWith
    if (isNaN(resizedHeight)) {
      return null
    }
    return resizedHeight
  } catch (e) {
    return null
  }
}

export const getCoreMediaImageDimensions = (
  coreMediaImageUrl: string
): {
  height: number | null | undefined
  width: number | null | undefined
} | null => {
  try {
    const imageDataFromImageUrl = getImageRatioandWidthFromUrl(
      coreMediaImageUrl || ''
    )
    const imageWidth = imageDataFromImageUrl?.width || null
    const imageHeight = getImageHeightByWidthAndRatio({
      ratios: imageDataFromImageUrl?.ratio!,
      width: imageDataFromImageUrl?.width!,
    })
    return {
      width: imageWidth,
      height: imageHeight,
    }
  } catch (e) {
    return null
  }
}
