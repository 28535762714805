import React from 'react'
import { useStoreIdentity } from '../../../../../../foundation/hooks/useStoreIdentity'
import useMediaByDeviceType from '../../../../../../hooks/useMediaByDeviceType'
import {
  ContentElementQueryList,
  ImageQueryList,
  LinkElementQueryList,
  Text,
  Title,
  WrapperElementQueryList,
} from './ElementQueryList.style'
import CMSCommonMedia from '../../../../CmsComponents/CmsCommonMedia'
import { ICMChannel } from '../../../../../../types/cmsPlacement/CMChannel'

const ElementQueryList: React.FC<{ item: ICMChannel }> = ({ item }) => {
  const { basePath } = useStoreIdentity()

  if (!item) return null
  const title = item.title || ''
  const text = item.teaserText?.replace(/<.*?>/gi, '') || ''
  const media = useMediaByDeviceType(item?.media!)
  const formattedUrl = item.formattedUrl || ''
  const link = `${basePath}${formattedUrl}`

  return (
    <WrapperElementQueryList>
      <LinkElementQueryList data-element-id={`X_X_${item.id}`} to={link}>
        <ImageQueryList>
          <CMSCommonMedia type={'QUERY_LIST'} media={media} />
        </ImageQueryList>
        <ContentElementQueryList>
          <Title
            fontSize={14}
            fontWeight={'bold'}
            isUppercased
            component={'h2'}
          >
            {title}
          </Title>
          <Text fontSize={14}>{text}</Text>
        </ContentElementQueryList>
      </LinkElementQueryList>
    </WrapperElementQueryList>
  )
}

export default ElementQueryList
