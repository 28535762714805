/*global google*/
import { useState, useRef, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useLoadGoogleMapsScript } from '@hooks/useLoadGoogleMapsScript'

type Coords = {
  lat: number
  lng: number
}

interface IUseFindLocationOptions {
  renderDropdownInInputContainer: boolean
}

export const useFindLocation = (
  input: HTMLInputElement | null,
  options: Partial<IUseFindLocationOptions> = {}
) => {
  const { t } = useTranslation()
  const storeCountry = useStoreIdentity().country.toUpperCase()
  const { renderDropdownInInputContainer = false } = options

  const [value, setValue] = useState('')
  const [error, setError] = useState('')

  const geocodeService = useRef<google.maps.Geocoder | null>(null)
  const autoCompleteService =
    useRef<google.maps.places.AutocompleteService | null>(null)
  const autoComplete = useRef<google.maps.places.Autocomplete | null>(null)

  const [places, setPlaces] = useState<
    google.maps.places.AutocompletePrediction[] | null
  >(null)
  const [selectedPlace, setSelectedPlace] = useState<
    google.maps.places.PlaceResult | undefined
  >(undefined)

  const { loaded } = useLoadGoogleMapsScript()
  const mutationObserver = useRef<MutationObserver | null>(null)

  const getPlaceByGeocode = useCallback(
    (request: google.maps.GeocoderRequest) => {
      geocodeService.current?.geocode(request, (result, status) => {
        if (status !== 'OK' || !result || !result[0])
          return setError(t('findStore.unexpectedSearchError'))
        setSelectedPlace(result[0])
      })
    },
    [t]
  )

  const retrievePlacePredictions = useCallback(
    (input: string) => {
      return new Promise<google.maps.places.AutocompletePrediction[] | null>(
        (resolve) => {
          autoCompleteService.current?.getPlacePredictions(
            {
              input,
              componentRestrictions: { country: storeCountry },
              types: ['geocode'],
            },
            (places) => resolve(places)
          )
        }
      )
    },
    [storeCountry]
  )

  const getPlacePredictions = useCallback(
    async (input: string) => {
      const places = await retrievePlacePredictions(input)
      if (!places || !places[0])
        return setError(t('findStore.unexpectedPlaceSearchError'))
      getPlaceByGeocode({
        address: places[0].description,
      })
    },
    [retrievePlacePredictions, t, getPlaceByGeocode]
  )

  useEffect(() => {
    if (error && !!value.length) {
      setError('')
    }
  }, [error, value])

  useEffect(() => {
    const options = {
      fields: ['formatted_address', 'geometry', 'name'],
      strictBounds: false,
      types: ['geocode'],
      componentRestrictions: { country: storeCountry },
    }

    if (!window.google?.maps || !input) return

    autoComplete.current = new google.maps.places.Autocomplete(input, options)
    const onPlaceChange = () => {
      const selectedPlace = autoComplete.current?.getPlace()
      setSelectedPlace(selectedPlace)
    }
    autoComplete.current?.addListener('place_changed', onPlaceChange)
  }, [storeCountry, input])

  useEffect(() => {
    if (!selectedPlace || !selectedPlace.geometry?.location) return
    const formattedAddress = selectedPlace?.formatted_address || ''
    setValue(formattedAddress)
  }, [selectedPlace])

  useEffect(() => {
    if (loaded) {
      autoCompleteService.current = new google.maps.places.AutocompleteService()
      geocodeService.current = new google.maps.Geocoder()
    }
  }, [loaded])

  /**
   * This is a workaround to attach the autocomplete dropdown to the input element so it will scroll with the input.
   * The dropdown is appended to the body by default, but we want to append it to the input's parent element instead.
   */
  useEffect(() => {
    if (!renderDropdownInInputContainer || !input) {
      return
    }

    mutationObserver.current = new MutationObserver((mutationsList) => {
      const autocompleteDropdownAddedMutation = mutationsList.find(
        (mutation) =>
          mutation.type === 'childList' &&
          mutation.addedNodes.length &&
          (mutation.addedNodes[0] as Element | null)?.classList.contains(
            'pac-container'
          )
      )
      if (autocompleteDropdownAddedMutation) {
        const autocompleteDropdown = autocompleteDropdownAddedMutation
          .addedNodes[0] as Element

        if (autocompleteDropdown && input) {
          input.parentElement?.append(autocompleteDropdown)
        }
      }
    })
    mutationObserver.current.observe(document.body, { childList: true })

    return () => {
      mutationObserver.current?.disconnect()
      mutationObserver.current = null
    }
  }, [options.renderDropdownInInputContainer, input])

  const onHandleSearch = useCallback(() => {
    if (!value) return setError(t('findStore.emptySearchError'))
    getPlacePredictions(value)
  }, [t, value, getPlacePredictions])

  const getGeoposition = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      async (geo) => {
        const coords: Coords = {
          lat: geo.coords.latitude,
          lng: geo.coords.longitude,
        }
        getPlaceByGeocode({ location: coords })
      },
      () => {
        setError(t('findStore.unexpectedSearchError'))
      },
      {
        timeout: 10000,
        maximumAge: 10000,
      }
    )
  }, [getPlaceByGeocode, t])

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setValue(e.target.value)
    setPlaces(await retrievePlacePredictions(value))
  }

  return {
    onHandleSearch,
    getGeoposition,
    onChange,
    error,
    value,
    selectedPlace,
    places,
  }
}
