import React, { useMemo, useRef, useState } from 'react'
import {
  ContentBannerQueryList,
  ContentPaginationQueryList,
  WrapperQueryList,
} from './QueryList.style'
import ElementQueryList from './components/ElementQueryList'
import PaginationQueryList from './components/PaginationQueryList'
import { ICMCollection } from '../../../../../types/cmsPlacement/CMCollection'
import { ICMQueryList } from '../../../../../types/cmsPlacement/CMQueryList'
import { isCMChannel } from '../../../../../types/cmsPlacement/Placement'

const QueryList: React.FC<{ placement: ICMQueryList | ICMCollection }> = ({
  placement,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [page, setPage] = useState<number>(1)

  const teasableItems = placement?.teasableItems?.filter(isCMChannel) || []
  const totalCount = teasableItems.length
  const pageSize = 9

  const count = useMemo<number>(
    () => Math.ceil(totalCount / pageSize),
    [totalCount, pageSize]
  )

  const paginate = useMemo(() => {
    const start = (page - 1) * pageSize
    const end = page * pageSize

    return teasableItems.slice(start, end)
  }, [teasableItems, page, pageSize])

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)

    if (wrapperRef.current && wrapperRef.current.scrollIntoView) {
      wrapperRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <WrapperQueryList ref={wrapperRef}>
      <ContentBannerQueryList>
        {paginate.map((item) => (
          <React.Fragment key={item.id}>
            <ElementQueryList item={item} />
          </React.Fragment>
        ))}
      </ContentBannerQueryList>
      <ContentPaginationQueryList>
        <PaginationQueryList
          page={page}
          count={count}
          onChange={handleChange}
        />
      </ContentPaginationQueryList>
    </WrapperQueryList>
  )
}

export default QueryList
