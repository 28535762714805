import { styled } from '@mui/material/styles'
import { StyledTypography } from '@components/UI'
import { IconButton } from '@components/UI'
import { Z_INDEX_LV2 } from '../../constants/ui'

export const StyledSuggestedProductsWrapper = styled('section', {
  name: 'SuggestedProducts',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(8),
  alignItems: 'center',
  color: theme.palette.text.dark,
  lineHeight: 1.4,
  paddingBottom: theme.spacing(6),
}))

export const StyledSuggestedProductsTitle = styled(StyledTypography, {
  name: 'SuggestedProducts',
  slot: 'Title',
  shouldForwardProp: (prop) => prop !== 'productType',
})<{
  productType: string | null
}>(({ productType, theme }) => ({
  fontSize: theme.spacing(5),
  textTransform: 'uppercase',
  fontWeight: 600,
  lineHeight: 1.33,
  color: theme.palette.text.dark,

  [theme.breakpoints.up('sm')]: {
    fontSize:
      productType === 'contact-lenses' ? theme.spacing(5) : theme.spacing(6),
  },
}))

export const StyledSuggestedProductsText = styled(StyledTypography, {
  name: 'SuggestedProducts',
  slot: 'Text',
})(({ theme }) => ({
  fontSize: theme.spacing(4),
  textTransform: 'uppercase',
  fontWeight: 600,
  lineHeight: 1.33,
  color: theme.palette.text.dark,
}))

export const SliderContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'SliderContainer',
})(() => ({
  width: '100%',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const StyledSingleItem = styled('div', {
  name: 'PdpDetails',
  slot: 'SingleItemContainer',
})(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'unset',
    flexGrow: 0.3,
  },
}))

export const SliderNavigationContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'SliderNavigationContainer',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: Z_INDEX_LV2,
  minHeight: '40px',
  padding: theme.spacing(2.5, 5),
  width: '80px',
  borderTop: `2px solid ${theme.palette.text.dark}`,
}))

export const SliderNavigationButton = styled(IconButton, {
  name: 'PdpDetails',
  slot: 'SliderNavigationButton',
})(() => ({
  padding: 0,
}))
