import { styled } from '@mui/material/styles'
import { GridContainer, GridItem } from '../../../../UI'

export const StyledGridOfProductsContainer = styled(GridContainer, {
  name: 'GridOfProducts',
  slot: 'Container',
})(({ theme }) => ({
  padding: theme.spacing(0, 4),
  gap: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(8),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 16),
    gap: theme.spacing(2),
    'grid-template-columns': 'repeat(12, 1fr)',
  },
  a: {
    flexGrow: 0,
    whiteSpace: 'nowrap',
  },
}))

export const StyledGridOfProductsItem = styled(GridItem, {
  name: 'GridOfProducts',
  slot: 'Item',
})(({ theme }) => ({
  gridColumn: 'span 4',
  aspectRatio: 'initial',
  maxWidth: '100%',
  boxSizing: 'border-box',
  position: 'relative',
  backgroundColor: theme.palette.color.white.main,

  [theme.breakpoints.up('md')]: {
    gridColumn: 'span 3',
  },
}))
